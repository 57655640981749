// import { yup.object } from "yup";
import { kol, sena, selectSchema, amountSchema, mixedSchema } from "./details";
import * as yup from "yup";
import { get, isEqual } from "lodash";
// default details  for yup schemas

const registerSalesMaterialSchema = yup.object().shape({
  tovar: mixedSchema,
  edIzmId: mixedSchema,
  kol,
  tsena: amountSchema,
  tsenaSoSkidkoy: amountSchema,
  summa: amountSchema,
});

const orderRegisterSalesMaterial = yup.object().shape({
  tovarId: selectSchema,
  kol,
});

// required schema for material modals  related by material docs
const MaterialDetailsSchema = yup.object().shape({
  materialId: selectSchema,
  kol,
  sena: yup.number().min(0).required(),
});
const manualMaterialSchema = yup.object().shape({
  materialId: selectSchema,
  kol,
  tsena: amountSchema,
});
// required schema for products modals - tovar  related by product docs
const ProductDetailsSchema = yup.object().shape({
  tovarId: selectSchema,
  kol,
  // tsena: sena,
});

const RequiredSchema = yup.object().shape({
  kontragentId: selectSchema,
  dataIsp: yup.mixed().required("*"),
});

const semiProductSchema = yup.object().shape({
  kolPolufabrikat: amountSchema,
});

const releaseProductSchema = yup.object().shape({
  kolTovara: amountSchema,
});

const otherExpensesDetails = yup.object().shape({
  statyaRasxodaId: selectSchema,
  summa: amountSchema,
});

const regisCashDetailsSchema = yup.object().shape({
  tipPrixodaId: mixedSchema,
  // dogProdajaId: yup.string().nullable().when('tipPrixodaId', {
  //     is: true,
  //     then: yup.string().nullable().required(),
  //     otherwise: yup.string().nullable().notRequired(),
  // }),
  summa: amountSchema,
});

const shipmentProductSchema = yup.object().shape({
  kontragentId: mixedSchema,
});
const registerExpenseCashSchema = yup.object().shape({
  tipRasxodaId: mixedSchema,
  summa: amountSchema,
  kontragentId: mixedSchema,
});
const expenseSchemaOne = yup.object().shape({
  tipRasxodaId: mixedSchema,
  kontragentId: mixedSchema,
  summa: amountSchema,
});
const expenseSchemaTwo = yup.object().shape({
  tipRasxodaId: mixedSchema,
  kontragentId: mixedSchema,
  summa: amountSchema,
});
const expenseSchemaThere = yup.object().shape({
  tipRasxodaId: mixedSchema,
  voditeliId: mixedSchema,
  summa: amountSchema,
});
const expenseSchemaFour = yup.object().shape({
  tipRasxodaId: mixedSchema,
  dogPokupkaId: mixedSchema,
  kontragentId: mixedSchema,
  summa: amountSchema,
});
const expenseSchemaFive = yup.object().shape({
  tipRasxodaId: mixedSchema,
  territoriyaId: mixedSchema,
  statyaRasxodaId: mixedSchema,
  summa: amountSchema,
});
const expenseSchemaSix = yup.object().shape({
  tipRasxodaId: mixedSchema,
  kontragentId: mixedSchema,
  dogProdajaId: mixedSchema,
  summa: amountSchema,
});
const manualPostingSchema = yup.object().shape({
  debitSchet: mixedSchema,
  kreditSchet: mixedSchema,
  // kol: amountSchema,
  summa: amountSchema,
});

const manualPostingSchemaDetails = yup.object().shape({
  debitSchet: mixedSchema,
  kreditSchet: mixedSchema,
  summa: amountSchema,
  debitSubkonto1Id: yup.mixed().when("debitSchet", {
    is: (val: any) => !isEqual(get(val, "subkonto1", null), null),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  debitSubkonto2: yup.mixed().when("debitSchet", {
    is: (val: any) => !isEqual(get(val, "subkonto2", null), null),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  debitSubkonto3Id: yup.mixed().when("debitSchet", {
    is: (val: any) => !isEqual(get(val, "subkonto3", null), null),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),

  kreditSubkonto1Id: yup.mixed().when("kreditSchet", {
    is: (val: any) => !isEqual(get(val, "subkonto1", null), null),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  kreditSubkonto2Id: yup.mixed().when("kreditSchet", {
    is: (val: any) => !isEqual(get(val, "subkonto2", null), null),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  kreditSubkonto3Id: yup.mixed().when("kreditSchet", {
    is: (val: any) => !isEqual(get(val, "subkonto3", null), null),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),

  //   kol: amountSchema.when(["kreditSchet"], {
  //     is: (val: any) => !isEqual(get(val, "kol", null), null),
  //     then: (schema) => schema.required(),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
});

const substandardProductDetailsSchema = yup.object().shape({
  tipSpisaniyaId: mixedSchema,
  summa: amountSchema,
  zakazNaryadId: yup.mixed().when("tipSpisaniyaId", {
    is: (val: number) => [1, 3].includes(val),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  tovarId: yup.mixed().when("tipSpisaniyaId", {
    is: (val: number) => [1].includes(val),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  materialId: yup.mixed().when("tipSpisaniyaId", {
    is: (val: number) => [2].includes(val),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  polufabrikatId: yup.mixed().when("tipSpisaniyaId", {
    is: (val: number) => [3].includes(val),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  skladId: yup.mixed().when("tipSpisaniyaId", {
    is: (val: number) => [2, 3].includes(val),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  kol: amountSchema.when("tipSpisaniyaId", {
    is: (val: number) => [1, 2, 3].includes(val),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
});
const changeProductDetailsSchema = yup.object().shape({
  kol: yup.number().min(0.1).required().nullable(),
  tovarId: mixedSchema,
});
const changeSemiProductDetailsSchema = yup.object().shape({
  kol: yup.number().min(0.1).required(),
  polufabrikatId: mixedSchema,
});
export {
  expenseSchemaOne,
  expenseSchemaTwo,
  expenseSchemaThere,
  expenseSchemaFour,
  expenseSchemaFive,
  expenseSchemaSix,
  registerSalesMaterialSchema,
  orderRegisterSalesMaterial,
  MaterialDetailsSchema,
  ProductDetailsSchema,
  RequiredSchema,
  shipmentProductSchema,
  manualMaterialSchema,
  semiProductSchema,
  releaseProductSchema,
  otherExpensesDetails,
  regisCashDetailsSchema,
  registerExpenseCashSchema,
  manualPostingSchema,
  manualPostingSchemaDetails,
  substandardProductDetailsSchema,
  changeProductDetailsSchema,
  changeSemiProductDetailsSchema,
};
