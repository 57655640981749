import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";

const initialState: any = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    territoriyaId: null,
    kontragentId: null,
    sposobOplatiId: null,
    detalno: true,
  },
};

export const fetchRegisterInvoice = createAsyncThunk(
  "registerInvoice/fetchRegisterInvoice",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchRegisterInvoice(params);
      const respond = await request.data;
      return respond;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const registerInvoiceSlices = createSlice({
  name: "registerInvoice",
  initialState,
  reducers: {
    filterRegisterInvoice: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = {
        ...state.filter,
        [name]: value,
      };
    },
    clearRegisterInvoice: () => initialState,
    changeRegisterInvoice: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegisterInvoice.pending, (state: any) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchRegisterInvoice.fulfilled, (state: any, action: any) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchRegisterInvoice.rejected, (state: any, payload: any) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const { filterRegisterInvoice, clearRegisterInvoice, changeRegisterInvoice } = registerInvoiceSlices.actions;
export default registerInvoiceSlices.reducer;
