import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchDetailsCompany,
  handleClearFilter,
  handleDetailsCompanyFilter,
} from "app/slices/handbookSlices/detailsCompanySlices/detailsCompanySlices";
import { detailsCompanyDataType } from "app/slices/handbookSlices/detailsCompanySlices/type";
import {
  allDetailsCompanyData,
  allDetailsCompanyFilter,
} from "app/states/handbooks";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { detailsComponySchema } from "utilities/schema/spravochnikSchemas";
//  Реквизиты предприятия (компании)
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: detailsCompanyDataType;
}) => {
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateDetailsCompany({
          data: {
            ...values,
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewDetailsCompany({
          data: values,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
      resetForm()
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imya: get(details, "imya", ""),
      polnoeImya: get(details, "polnoeImya", ""),
      inn: get(details, "inn", ""),
      nds: get(details, "nds", ""),
      oked: get(details, "oked", ""),
      address: get(details, "address", ""),
      telefonNomer: get(details, "telefonNomer", ""),
      email: get(details, "email", ""),
      rukovoditel: get(details, "rukovoditel", ""),
      glavbuh: get(details, "glavbuh", ""),
    },
    onSubmit,
    validationSchema: detailsComponySchema,
  });
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Input
            label={"Наименование"}
            value={get(values, "imya", "")}
            handleInput={(e: string) => {
              setFieldValue("imya", e);
            }}
            required={true}
          />
          <Input
            label={"Полное наименование"}
            value={get(values, "polnoeImya", "")}
            handleInput={(e: string) => {
              setFieldValue("polnoeImya", e);
            }}
            required={true}
          />

          <Input
            label={"ИНН"}
            value={get(values, "inn", "")}
            handleInput={(e: string) => {
              setFieldValue("inn", e);
            }}
            required={true}
          />
          <Input
            label={"Код НДС"}
            value={get(values, "nds", "")}
            handleInput={(e: string) => {
              setFieldValue("nds", e);
            }}
            required={true}
          />

          <Input
            label={"Код ОКЭД"}
            value={get(values, "oked", "")}
            handleInput={(e: string) => {
              setFieldValue("oked", e);
            }}
            required={true}
          />

          <Input
            label={"Юридический адрес"}
            value={get(values, "address", "")}
            handleInput={(e: string) => {
              setFieldValue("address", e);
            }}
            required={true}
          />
          <Input
            label={"Телефоны"}
            value={get(values, "telefonNomer", "")}
            handleInput={(e: string) => {
              setFieldValue("telefonNomer", e);
            }}
            required={true}
          />

          <Input
            label={"Электронная почта"}
            value={get(values, "email", "")}
            handleInput={(e: string) => {
              setFieldValue("email", e);
            }}
            required={true}
          />
          <Input
            label={"ФИО руководителя"}
            value={get(values, "rukovoditel", "")}
            handleInput={(e: string) => {
              setFieldValue("rukovoditel", e);
            }}
            required={true}
          />
          <Input
            label={"ФИО главного бухгалтера"}
            value={get(values, "glavbuh", "")}
            handleInput={(e: string) => {
              setFieldValue("glavbuh", e);
            }}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const DetailsCompanyContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<detailsCompanyDataType | any>({});
  const data = useAppSelector(allDetailsCompanyData);
  const filter = useAppSelector(allDetailsCompanyFilter);
  let LOADING = get(data, "loading", true);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleDetailsCompanyFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchDetailsCompany({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(fetchDetailsCompany({ ...filter, search: "", InAscOrder: false }));
    dispatch(
      handleDetailsCompanyFilter({
        name: "search",
        value: "",
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    return () => {
      dispatch(handleClearFilter())
    }
  }, [])
  const fetchAllCompanyData = useCallback(() => {
    dispatch(fetchDetailsCompany({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: detailsCompanyDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteDetailsCompany(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllCompanyData();
      }
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "KOMPANIYA_CREATE",
                    page: "KOMPANIYA",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllCompanyData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименование",
                    "Полное наименования",
                    "ИНН",
                    "Код НДС",
                    "Код ОКЭД",
                    "Юридический адрес",
                    "Телефоны",
                    "Электронная почта",
                    "ФИО руководителя",
                    "ФИО главного бухгалтера",
                  ]}
                >
                  {isEqual(LOADING, false) ? (
                    <>
                      {!isEmpty(get(data, "companyData", [])) ? (
                        get(data, "companyData", []).map(
                          (item: detailsCompanyDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"KOMPANIYA"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imya", "-")}</td>
                              <td>{get(item, "polnoeImya", "-")}</td>
                              <td>{get(item, "inn", "-")}</td>
                              <td>{get(item, "nds", "-")}</td>
                              <td>{get(item, "oked", "-")}</td>
                              <td>{get(item, "address", "-")}</td>
                              <td>{get(item, "telefonNomer", "-")}</td>
                              <td>{get(item, "email", "-")}</td>
                              <td>{get(item, "rukovoditel", "-")}</td>
                              <td>{get(item, "glavbuh", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={13} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={10}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default DetailsCompanyContainer;
