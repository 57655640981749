import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";
import axios from "axios";

const initialState: InitialState = {
  data: { loading: false, driversData: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchDrivers = createAsyncThunk(
  "allDrivers/fetchDrivers",
  async (params: AllPaginationFilterType, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source()
      signal.addEventListener('abort', () => {
        soure.cancel()
      })
      const request = await HandBookServices.GetDrivers({ ...params, signal: soure.token });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const driversSlices = createSlice({
  name: "allDrivers",
  initialState,
  reducers: {
    handleDriversFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    handleClearFilter: (state) => {
      state.filter = initialState.filter
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDrivers.pending, (state) => {
      state.data = {
        loading: true,
        driversData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchDrivers.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        driversData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchDrivers.rejected, (state, payload) => {
      state.data = {
        loading: false,
        driversData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleDriversFilter, handleClearFilter } = driversSlices.actions;
export default driversSlices.reducer;
