import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    voditelId: null,
    detalno: false,
  }
}

export const fetchReportPurchase = createAsyncThunk(
  "reportPurchase/fetchReportPurchase",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchReportPurchaseTable(params);
      const respond = await request.data;
      return respond
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const reportPurchaseSlices = createSlice({
  name: "reportPurchase",
  initialState,
  reducers: {
    filterPurchaseTable: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = { ...state.filter, [name]: value };
    },
    clearPurchaseTable: () => initialState,
    changePurchaseTable: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportPurchase.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchReportPurchase.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchReportPurchase.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const {filterPurchaseTable, clearPurchaseTable, changePurchaseTable} = reportPurchaseSlices.actions;
export default reportPurchaseSlices.reducer;