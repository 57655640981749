import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import axios from "axios";

const initialState: InitialState = {
  contractStatProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      realizatsiyaGPDetails: [],
    },
  },
  filter: {
    kontragentId: null,
    tovarId: null,
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  salesProduct: {
    loading: false,
    productList: [],
    pagination: {},
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchSalesProduct = createAsyncThunk(
  "salesProduct/fetchSalesProduct",
  async (params: any, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        soure.cancel();
      });
      const request = await DocumentServices.FetchSalesProduct({
        ...params,
        cancelReq: soure.token,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const fetchNotFilledSalesProduct = createAsyncThunk(
  "salesProduct/fetchNotFilledSalesProduct",
  async (params, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchNotFilledSalesProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSalesProductsByTempId = createAsyncThunk(
  "salesProduct/fetchSalesProductsByTempId",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchNotFilledSalesProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSalesProductsById = createAsyncThunk(
  "salesProduct/fetchSalesProductsById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSalesProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSalesProductStat = createAsyncThunk(
  "salesProduct/fetchSalesProductStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSalesProductStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const salesProductSlices = createSlice({
  name: "salesProduct",
  initialState,
  reducers: {
    handleAddSubjectsSales: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          realizatsiyaGPDetails: !isEmpty(get(actions, "payload", []))
            ? get(actions, "payload", [])
            : [],
        },
      };
    },
    handleClearableFieldsItem: (state, action) => {
      if (!state.formData?.data?.realizatsiyaGPDetails) {
        return; // Exit early if realizatsiyaGPDetails is undefined
      }

      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          realizatsiyaGPDetails: state.formData.data.realizatsiyaGPDetails.map(
            (item: any) =>
              item.tovarId == get(action, "payload", null)
                ? { ...item, summa: 0, vsego: 0 }
                : item
          ),
        },
      };
    },
    editSubjectSales: (state, action) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          realizatsiyaGPDetails: state.formData.data.realizatsiyaGPDetails.map(
            (item: any) =>
              item.tovarId == get(action, "payload.id", null)
                ? get(action, "payload.data", null)
                : item
          ),
        },
      };
    },
    handleChangeItemSubjectsSalesProduct: (state, action) => {
      console.log(get(action, "payload.data", {}), "respons in backend");
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          realizatsiyaGPDetails: state.formData.data.realizatsiyaGPDetails.map(
            (item: any) =>
              item.tovarId == get(action, "payload.data.tovarId", null)
                ? get(action, "payload.data", {})
                : item
          ),
        },
      };
    },
    handleFilterChangeSalesProduct: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    handleClearFormDataSales: (state) => {
      state.formData = initialState.formData;
    },
    clearFilterDetails: (state) => {
      state.filter = initialState.filter;
    },
    clearSalesProduct: (state) => (state = initialState),
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSalesProduct.pending, (state) => {
      state.salesProduct = {
        loading: true,
      };
    });

    builder.addCase(fetchSalesProduct.fulfilled, (state, actions) => {
      state.salesProduct = {
        loading: false,
        productList: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchSalesProduct.rejected, (state, actions) => {
      state.salesProduct = {
        loading: false,
        productList: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    builder.addCase(fetchNotFilledSalesProduct.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(fetchNotFilledSalesProduct.fulfilled, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchNotFilledSalesProduct.rejected, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    //  fetch Temps
    builder.addCase(fetchSalesProductsByTempId.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchSalesProductsByTempId.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchSalesProductsByTempId.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    // fetch id
    builder.addCase(fetchSalesProductsById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchSalesProductsById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchSalesProductsById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });

    // fetching sale contracts statists
    builder.addCase(fetchSalesProductStat.pending, (state) => {
      state.contractStatProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchSalesProductStat.fulfilled, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchSalesProductStat.rejected, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleAddSubjectsSales,
  handleFilterChangeSalesProduct,
  handleClearFormDataSales,
  handleChangeItemSubjectsSalesProduct,
  clearFilterDetails,
  clearSalesProduct,
  editSubjectSales,
  handleClearableFieldsItem,
} = salesProductSlices.actions;
export default salesProductSlices.reducer;
