import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get, isEmpty } from "lodash";
import axios from "axios";

const initialState: InitialState = {
  data: { loading: false, dataList: [], pagination: {}, error: "" },
  dataOptions: {
    data: [],
  },
  getCounterpartById: {
    loading: false,
    data: {},
    error: "",
  },
  optionsVipsPf: {
    loading: false,
    data: [],
    error: "",
  },
  addressDto: {
    addresDostavkiDTOs: [
      {
        korotkoeImenaAddress: null,
        polnoeImenaAddress: null,
      },
    ],
  },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchAllCounterpart = createAsyncThunk(
  "allCounterpart/fetchAllCounterpart",
  async (params: any, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        soure.cancel();
      });
      const request = await HandBookServices.GetCounterpart({
        ...params,
        cancelToken: soure.token,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const fetchCounterpartVipuskPF = createAsyncThunk(
  "allCounterpart/fetchCounterpartVipuskPF",
  async (_, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        soure.cancel();
      });
      const request = await HandBookServices.GetCounterpart({
        filterPage: "VIPUSK_PF",
        cancelToken: soure.token,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const fetchOptionsCounterpart = createAsyncThunk(
  "allCounterpart/fetchOptionsCounterpart",
  async (params: any, thunkAPI) => {
    try {
      const request = await HandBookServices.GetCounterpart(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchCounterpartById = createAsyncThunk(
  "allCounterpart/fetchCounterpartById",
  async (params: any, thunkAPI) => {
    try {
      const request = await HandBookServices.GetCounterpartById(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const counterpartSlices = createSlice({
  name: "allCounterpart",
  initialState,
  reducers: {
    addnewDto: (state, action) => {
      state.addressDto = {
        ...state.addressDto,

        addresDostavkiDTOs: [
          ...state.addressDto.addresDostavkiDTOs,
          { ...action.payload },
        ],
      };
    },
    removeDto: (state) => {
      state.addressDto = {
        ...state.addressDto,

        addresDostavkiDTOs: [
          ...state.addressDto.addresDostavkiDTOs.splice(
            0,
            state.addressDto.addresDostavkiDTOs.length - 1
          ),
        ],
      };
    },
    handleAddNewDto: (state, actions) => {
      state.addressDto = {
        ...state.addressDto,

        addresDostavkiDTOs: !isEmpty(get(actions, "payload", {}))
          ? [
            ...state.addressDto.addresDostavkiDTOs.map(
              (item: any, index: number) =>
                index == actions.payload.id
                  ? {
                    ...item,
                    [get(actions, "payload.name", null)]: get(
                      actions,
                      "payload.value",
                      null
                    ),
                  }
                  : item
            ),
          ]
          : [{}],
      };
    },
    clearCounterPartAddress: (state) => {
      state.addressDto = {
        ...state.addressDto,
        addresDostavkiDTOs: [
          {
            korotkoeImenaAddress: null,
            polnoeImenaAddress: null,
          },
        ],
      };
    },

    handleCounterpartFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearContractorsState: () => initialState,
    clearContractorsData: (state) => {
      state.data = initialState.data;
    },
    clearFilterAllCounterparts: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCounterpart.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchAllCounterpart.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllCounterpart.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        pagination: {},
        error: payload.error.message,
      };
    });

    builder.addCase(fetchOptionsCounterpart.pending, (state) => {
      state.dataOptions = {
        data: [],
      };
    });
    builder.addCase(fetchOptionsCounterpart.fulfilled, (state, action) => {
      state.dataOptions = {
        data: get(action, "payload.data", []),
      };
    });
    builder.addCase(fetchOptionsCounterpart.rejected, (state, payload) => {
      state.dataOptions = {
        data: [],
      };
    });
    // counterparts
    builder.addCase(fetchCounterpartVipuskPF.pending, (state) => {
      state.optionsVipsPf.loading = true;
    });
    builder.addCase(fetchCounterpartVipuskPF.fulfilled, (state, action) => {
      state.optionsVipsPf = {
        data: get(action, "payload.data", []),
        loading: false,
        error: "",
      };
    });
    builder.addCase(fetchCounterpartVipuskPF.rejected, (state, payload) => {
      state.optionsVipsPf.loading = false;
    });
    // by id 
    builder.addCase(fetchCounterpartById.pending, (state) => {
      state.getCounterpartById.loading = true;
    });
    builder.addCase(fetchCounterpartById.fulfilled, (state, action) => {
      state.getCounterpartById = {
        data: get(action, "payload.data", []),
        loading: false,
        error: "",
      };
    });
    builder.addCase(fetchCounterpartById.rejected, (state, payload) => {
      state.getCounterpartById.loading = false;
    });
  },
});

export const {
  handleCounterpartFilter,
  handleAddNewDto,
  addnewDto,
  clearCounterPartAddress,
  removeDto,
  clearContractorsData,
  clearContractorsState,
  clearFilterAllCounterparts,
} = counterpartSlices.actions;
export default counterpartSlices.reducer;
