import { useAppDispatch, useAppSelector } from "app/hook";
import { handleFilterChangePurchases } from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import { fetchMenageAccount } from "app/slices/handbookSlices/menageAccountSlices/menageAccountSlices";
import {
  clearPivotTable,
  fetchPivotTable,
  fetchPivotTableStatistic,
  handleChangeFilterPivotTable,
} from "app/slices/registerSlices/pivotTableSlices/pivotTableSlices";
import { getDebutCreditData } from "app/states/handbooks/selectStates";
import {
  pivotTableData,
  pivotTableFilter,
  pivotTableStatic,
} from "app/states/registers";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  WindowHeight,
} from "components";
import { get, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { indexNumber } from "utilities/helpers";
// Сводная таблица
const PivotTableContainer = () => {
  const dispatch = useAppDispatch();
  const pivotTable = useAppSelector(pivotTableData);
  const filter = useAppSelector(pivotTableFilter);
  const debutCreditOptions = useAppSelector(getDebutCreditData);
  const LOADING_TABLE = get(pivotTable, "loading", []);

  const fetchPivotTableData = useCallback(() => {
    dispatch(fetchPivotTable({ ...filter, page: 0 }));
    dispatch(fetchPivotTableStatistic());
  }, [dispatch, filter]);
  const cntrCountStat = useAppSelector(pivotTableStatic);
  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchPivotTable({
        ...filter,
        debitSchet: null,
        kreditSchet: null,
        fromDate: null,
        toDate: null,
      })
    );
    dispatch(clearPivotTable());
  }, [dispatch, filter]);

  useEffect(() => {
    dispatch(fetchPivotTable({ ...filter }));
    dispatch(fetchMenageAccount({ all: true }));
    dispatch(fetchPivotTableStatistic());
  }, [filter.page, filter.size]);

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, []);

  return (
    <Row>
      <Col xs={12}>
        <Content className="full_height">
          <Row align="center" className="mb-16">
            <Col xs={10}>
              <ShowStatistics statistic={cntrCountStat} />
            </Col>
          </Row>

          <Row className={"py-16 border"}>
            <Col xs={8}>
              {/*  filter select */}
              <FlexBox gap={"10px"}>
                <BaseSelect
                  value={get(filter, "debitSchet", "")}
                  isSearchable
                  options={debutCreditOptions}
                  placeholder="Дебет счета"
                  handleChange={(e: string) => {
                    dispatch(
                      handleChangeFilterPivotTable({
                        name: "debitSchet",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  options={debutCreditOptions}
                  placeholder="Кредит счета"
                  isSearchable
                  value={get(filter, "kreditSchet", "")}
                  handleChange={(e: any) => {
                    dispatch(
                      handleChangeFilterPivotTable({
                        name: "kreditSchet",
                        value: e,
                      })
                    );
                  }}
                />

                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleChangeFilterPivotTable({
                        name: "fromDate",
                        value: moment(e).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleChangeFilterPivotTable({
                        name: "toDate",
                        value: moment(e).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"}>
              <Button
                primary
                className={"mr-16"}
                handleClick={fetchPivotTableData}
              >
                Применять
              </Button>
              <Button danger handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Дата",
                    "Тип документа",
                    "Дебет счет",
                    "Субконто1",
                    "Субконто2",
                    "Субконто3",
                    "Кредит счет",
                    "Субконто1",
                    "Субконто2",
                    "Субконто3",
                    "Количество",
                    "Сумма",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      {!isEmpty(get(pivotTable, "pivotData", [])) ? (
                        get(pivotTable, "pivotData", []).map((item, index) => (
                          <tr key={index + 1} className="hover_line">
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  pivotTable,
                                  "pagination.currentPageNumber",
                                  0
                                ),
                                pageSize: get(
                                  pivotTable,
                                  "pagination.pageSize",
                                  10
                                ),
                                index: index + 1,
                              })}
                            </td>
                            <td>
                              {moment(get(item, "dataDoc", "")).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>{get(item, "tipDocument", "")}</td>
                            <td>{get(item, "debitSchet", "")}</td>
                            <td>{get(item, "debitSubkonto1", "")}</td>
                            <td>{get(item, "debitSubkonto2", "")}</td>
                            <td>{get(item, "debitSubkonto3", "")}</td>
                            <td>{get(item, "kreditSchet", "")}</td>
                            <td>{get(item, "kreditSubkonto1", "")}</td>
                            <td>{get(item, "kreditSubkonto2", "")}</td>
                            <td>{get(item, "kreditSubkonto3", "...")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "kol", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summa", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <Nodata colLength={13} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "70px" : "70px"}
                          tblDTNumber={13}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(pivotTable, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-24">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(pivotTable, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleChangeFilterPivotTable({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(pivotTable, "pagination.currentPageNumber", 0)}
                  pageCount={get(pivotTable, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleChangeFilterPivotTable({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default PivotTableContainer;
