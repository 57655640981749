import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";
import EnumerationsServices from "services/apiServices/perechislenya";

const initialState: any = {
    data: { loading: false, data: [], pagination: {}, error: "" },
    filter: {
        page: 0,
        size: 10,
    },

};

export const fetchTypeWriteSubstandard = createAsyncThunk(
    "allTypeWriteSubstandard/fetchTypeWriteSubstandard",
    async (params: any, thunkAPI) => {
        try {
            const request = await EnumerationsServices.GetTipSpisanya(params);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const TypeWriteSubStandardSlices     = createSlice({
    name: "allTypeWriteSubstandard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTypeWriteSubstandard.pending, (state) => {
            state.data = {
                loading: true,
                data: [],
                pagination: {},
                error: "",
            };
        });
        builder.addCase(fetchTypeWriteSubstandard.fulfilled, (state, action) => {
            state.data = {
                loading: false,
                data: get(action, "payload.data", []),
                pagination: get(action, "payload.pagination", {}),
                error: "",
            };
        });
        builder.addCase(fetchTypeWriteSubstandard.rejected, (state, payload) => {
            state.data = {
                loading: false,
                data: [],
                pagination: {},
                error: payload.error.message,
            };
        });
    },
});

export default TypeWriteSubStandardSlices.reducer;
