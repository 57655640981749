import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterData,
  clearRegisteredOrders,
  fetchOrderRegister,
  fetchOrderRegisterStat,
  fetchTempsOrderRegisterOrder,
  handleClearOrderFormData,
  handleFilterChangeOrder,
} from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import { OrderRegisterData } from "app/slices/documentSlices/orderRegisterSlice/type";
import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";
import {
  orderRegisterFilter,
  orderRegisterList,
  orderRegisterStat,
  orderTempsRegisterList,
} from "app/states/documents";
import { getTypeProductData } from "app/states/handbooks/selectStates";
import delate from "assets/images/icons/delate.svg";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";

// Оформление Заказ-Наряда
enum STATUS {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CLOSED = "CLOSED",
}
interface stateType {
  id: string;
  otmVipolneniya: number;
}
const OrderRegisterContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [documentInfo, setDocumentInfo] = useState<stateType>({
    id: "",
    otmVipolneniya: 0,
  });
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_ORDER_REGISTER
  );
  const orderRegister = useAppSelector(orderRegisterList);
  const LOADING_TABLE = get(orderRegister, "loading", []);
  const cntrCountStat = useAppSelector(orderRegisterStat);
  const filter = useAppSelector(orderRegisterFilter);
  const orderTemps = useAppSelector(orderTempsRegisterList);
  const tovariiOptions = useAppSelector(getTypeProductData);
  const contractorDropdowns = useAppSelector(contractorOptions);
  let LOADING_KON = useAppSelector(loadingConterpart);
  const optionsStatus = [
    {
      label: "Новый",
      value: "NEW",
      color: "#ffffff",
    },
    {
      label: " В процессе",
      value: "IN_PROGRESS",
      color: "#fffb00",
    },
    {
      label: "Произведен",
      value: "COMPLETED",
      color: "#0095ff",
    },
    {
      label: "Выполнен",
      value: "CLOSED",
      color: "#13e413",
    },
  ];
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, otmVipolneniya }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, otmVipolneniya });
  };
  //  other fetches
  const fetchRigestSalesContracts = useCallback(
    () => dispatch(fetchOrderRegister(filter)),
    [dispatch, filter]
  );
  const handleClearFilter = useCallback(() => {
    dispatch(clearFilterData());
    dispatch(
      fetchOrderRegister({
        ...filter,
        fromDate: null,
        toDate: null,
        tovarId: null,
        znStatus: null,
        kontragentId: null,
      })
    );
  }, [dispatch, filter]);
  const fetchApi = useCallback(() => {
    dispatch(fetchOrderRegisterStat());
    dispatch(fetchTempsOrderRegisterOrder());
    dispatch(fetchAllCounterpart({ all: true }));
    dispatch(fetchTypeProduct({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    fetchRigestSalesContracts();
  }, [filter.page, filter.size]);
  //    navigation functions
  const navigateOrderRegisterForm = (params: {
    id: string | number;
    isTemp: boolean;
    complete: number;
  }) => {
    navigate(Documents.ADD_ORDER_REGISTER, {
      state: params,
    });
  };
  const handleAddButton = () => {
    dispatch(handleClearOrderFormData());
    dispatch(clearContractorsState());
    navigate(Documents.ADD_ORDER_REGISTER);
  };
  const statusChangeColor = (status: string) => {
    switch (status) {
      case STATUS.NEW:
        return "#ffffff";
      case STATUS.IN_PROGRESS:
        return "#fffb003b";
      case STATUS.COMPLETED:
        return "#0095ff44";
      case STATUS.CLOSED:
        return "#13e41336";
      default:
        return "#ffff";
    }
  };
  // delete
  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeleteOrderRegisterById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchOrderRegisterStat());
        dispatch(fetchOrderRegister(filter));
      }
    });
  };
  const handleTempButton = (id: string | null | number) => {
    DocumentServices.RemoveOrderRegisterById(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchOrderRegisterStat());
        dispatch(fetchTempsOrderRegisterOrder());
      }
    });
  };
  // compote Выполнено
  const comptedOrderFunction = useCallback((orderId: string) => {
    if (!isEmpty(orderId)) {
      DocumentServices.CompletedOrder(orderId).then((res) => {
        if (res.status == 200) {
          dispatch(fetchOrderRegister(filter));
          toast.success(MESSAGES.DONE);
        }
      });
    }
  }, []);
  useEffect(() => {
    fetchApi();
    return () => {
      dispatch(clearRegisteredOrders());
      dispatch(clearFilterData());
    };
  }, []);
  // for adding  new form
  if (isAddForm) {
    return <Outlet />;
  }
  // main content
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={cntrCountStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button
                danger
                handleClick={handleClearFilter}
                className={"mr-16"}
              >
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.DOG_PRODAJA}_CREATE`,
                    page: DOCUMENT_KEY.DOG_PRODAJA,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="250px"
                  isClearable
                  placeholder={"Контрагент"}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  isLoading={LOADING_KON}
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeOrder({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width="250px"
                  isClearable
                  options={tovariiOptions}
                  placeholder="Вид товара"
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeOrder({
                        name: "vidTovarId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "vidTovarId", null)}
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeOrder({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeOrder({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
                <BaseSelect
                  width="150px"
                  isClearable
                  colorable={true}
                  options={optionsStatus}
                  placeholder="Статус"
                  handleValueLabel={(e: any) => {
                    dispatch(
                      handleFilterChangeOrder({
                        name: "znStatus",
                        value: !!e ? e.value : null,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "znStatus", null)}
                />
              </FlexBox>
            </Col>
            <Col xs={2} className="text-right">
              <Button primary handleClick={fetchRigestSalesContracts}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateOrderRegisterForm}
                  deleteDocument={handleDeleteDocument}
                  changeItem={true}
                  orderItem={true}
                  deleteItem={true}
                  userPermission={"ZAKAZ_NARYAD"}
                  comptedOrderFunction={comptedOrderFunction}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Дата исполнения",
                    "Контрагент",
                    "Товар",
                    "Количество",
                    "Адрес доставки",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Договор",
                    "Способ доставки",
                    "Территория",
                    "Склад для списания материалов",
                    "Выполнение",
                    "Время записи",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      <>
                        {!isEmpty(get(orderTemps, "data", [])) &&
                          get(orderTemps, "data", []).map((item: any) => (
                            <tr
                              key={get(item, "id", 0)}
                              onClick={() =>
                                navigateOrderRegisterForm({
                                  isTemp: true,
                                  id: get(item, "id", ""),
                                  complete: 1, // default
                                })
                              }
                              className="cursor-pointer"
                            >
                              <td>
                                <div
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    handleTempButton(get(item, "id", null));
                                  }}
                                  className="delete_button"
                                >
                                  <ReactSVG
                                    src={delate}
                                    className="delete_button"
                                  />
                                </div>
                              </td>
                              <td>
                                {moment(get(item, "dateIsp", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "kontragentImya", "")}</td>
                              <td>{get(item, "imyaTovara", "")}</td>
                              <td>{get(item, "kol", "")}</td>
                              <td>{get(item, "imyaAddresaDostavki", "")}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDoc", "")}</td>
                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "dogProdajaNomer", "")}</td>
                              <td>{get(item, "imyaSposobDostavka", "")}</td>
                              <td>{get(item, "imyaTerritoriya", "")}</td>
                              <td>{get(item, "imyaSklada", "")}</td>
                              <td>
                                {get(item, "otmVipolneniya", "") == 1
                                  ? "да"
                                  : "нет"}
                              </td>
                              <td>
                                {!isEmpty(get(item, "createdAt", ""))
                                  ? moment(get(item, "createdAt", "")).format(
                                      "DD.MM.YYYY"
                                    )
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                      </>
                      {!isEmpty(get(orderRegister, "data", []))
                        ? get(orderRegister, "data", []).map(
                            (item: OrderRegisterData, index: number) => (
                              <tr
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    otmVipolneniya: get(
                                      item,
                                      "otmVipolneniya",
                                      0
                                    ),
                                  })
                                }
                                key={index + 1}
                                onClick={() =>
                                  navigateOrderRegisterForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                    complete: get(item, "otmVipolneniya", 0),
                                  })
                                }
                                className="cursor-pointer"
                                style={{
                                  minWidth: "55px",
                                  background: statusChangeColor(
                                    get(item, "znStatus", "")
                                  ),
                                }}
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      orderRegister,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      orderRegister,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>

                                <td>
                                  {moment(get(item, "dateIsp", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "kontragentImya", "")}</td>
                                <td>{get(item, "imyaTovara", "")}</td>
                                <td>{get(item, "kol", "")}</td>
                                <td>{get(item, "imyaAddresaDostavki", "")}</td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "dogProdajaNomer", "")}</td>
                                <td>{get(item, "imyaSposobDostavka", "")}</td>
                                <td>{get(item, "imyaTerritoriya", "")}</td>
                                <td>{get(item, "imyaSklada", "")}</td>
                                <td>
                                  {get(item, "otmVipolneniya", "") == 1
                                    ? "да"
                                    : "нет"}
                                </td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                              </tr>
                            )
                          )
                        : isEmpty(get(orderTemps, "data", [])) && (
                            <Nodata colLength={14} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={14}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(orderRegister, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(orderRegister, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChangeOrder({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    orderRegister,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(orderRegister, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChangeOrder({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default OrderRegisterContainer;
