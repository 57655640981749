import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchPaymentMethod,
  filterPaymentMethod,
} from "app/slices/enumerationsSlices/paymentMethodSlices/paymentMethodSlices";
import { PaymentMethodDataType } from "app/slices/enumerationsSlices/paymentMethodSlices/type";
import {
  paymentMethodData,
  paymentMethodFilter,
} from "app/states/enumerations";
import {
  ActionBase,
  BaseInput,
  BaseTable,
  Button,
  Content,
  Form,
  LoadingTable,
  Pagination,
  ShowOnPage,
  Title,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import EnumerationsServices from "services/apiServices/perechislenya";
import icon from "assets/images/icons/action_icon.svg";
import { indexNumber } from "utilities/helpers";
// Способ оплаты
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: // handleUpdateAction,
{
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const onSubmit = () => {
    try {
      EnumerationsServices.AddNewPaymentMethod({
        data: values,
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        if (res && res.data) {
          handleCloseModal();
          afterSubmitAction();
        }
      });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      sposobOplataName: "",
    },
    onSubmit,
  });

  return (
    <>
      <Col xs={12} className="mt-16 text-left mb-24">
        <Title sm bold>
          Способ оплаты
        </Title>
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <BaseInput
            width={"100%"}
            clear={get(values, "sposobOplataName", "")}
            handleInput={(e: Event) => {
              setFieldValue("sposobOplataName", e);
            }}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" green type="submit">
              Добавить
            </Button>
            <Button onClick={handleCloseModal} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const PaymentMethodContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector(paymentMethodData);
  let LOADING = get(data, "loading", false);
  const filter = useAppSelector(paymentMethodFilter);
  const handleFilter = useCallback(() => {
    dispatch(fetchPaymentMethod(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const fetchAllPaymentMethodData = useCallback(() => {
    dispatch(fetchPaymentMethod(filter));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Row style={{ minHeight: "100%" }}>
      <Col xs={12}>
        <Content className={"full_height"}>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllPaymentMethodData()}
            />
          </ReactModal>
          <Row>
            <Col>
              <BaseTable
                dark
                lg
                tableHeader={[
                  "№",
                  "Пользователь",
                  "Наименование статьи расхода",
                ]}
              >
                {isEqual(LOADING, false) ? (
                  <>
                    {!isEmpty(get(data, "data", [])) ? (
                      get(data, "data", []).map(
                        (item: PaymentMethodDataType, index: number) => (
                          <tr key={get(item, "id", 0)}>
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  data,
                                  "pagination.currentPageNumber",
                                  0
                                ),
                                pageSize: get(data, "pagination.pageSize", 10),
                                index: index + 1,
                              })}
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "sposobOplataName", "-")}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={3}>Нет данных</td>
                      </tr>
                    )}
                  </>
                ) : (
                  map([...new Array(5)], (_, index: number) => (
                    <tr key={index + 1}>
                      <LoadingTable
                        width={index % 2 == 0 ? "50px" : "70px"}
                        tblDTNumber={3}
                      />
                    </tr>
                  ))
                )}
              </BaseTable>
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default PaymentMethodContainer;
