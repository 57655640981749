import { Space, Spin } from "antd";

const LoadingAddPages = () => {
    return (
        <Space className="centrality">
            <Spin size="large" />
        </Space>
    );
};

export default LoadingAddPages;