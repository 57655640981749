import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
  data: { loading: false, dataList: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchAllDeliveryAddress = createAsyncThunk(
  "allDeliveryAddress/fetchAllDeliveryAddress",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetDeliveryAddress(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const deliveryAddressSlices = createSlice({
  name: "allDeliveryAddress",
  initialState,
  reducers: {
    handleDeliveryAddressFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearDeliveryAddress: (state) => {
      state.filter = initialState.filter
    },
    clearDeliveryAddressData: (state) => state = initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllDeliveryAddress.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchAllDeliveryAddress.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),

        error: "",
      };
    });
    builder.addCase(fetchAllDeliveryAddress.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleDeliveryAddressFilter, clearDeliveryAddress, clearDeliveryAddressData } = deliveryAddressSlices.actions;
export default deliveryAddressSlices.reducer;
