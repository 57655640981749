import { useAppDispatch, useAppSelector } from "app/hook";
import { handleAddMaterials } from "app/slices/handbookSlices/calculationSlices/calculationSlices";
import { CalculationDataType } from "app/slices/handbookSlices/calculationSlices/type";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import {
  addNewRowSemi,
  clearMaterialsSemiCalculation,
  fetchSemiCalculation,
  handleAddMaterialsSemi,
  handleSemiCalculationFilter,
  handleSemiClearFilter,
  removeRowMaterialSemi,
} from "app/slices/handbookSlices/semiCalculationSlice/semiCalculationSlice";
import { fetchSemiProductMaterial } from "app/slices/handbookSlices/semiProductSlice/semiProductSlice";
import { fetchUnitsMeasurement } from "app/slices/handbookSlices/unitsMeasurementSlices/unitsMeasurementSlices";
import {
  SemiProductDataOptions,
  semiCalculationData,
  semiCalculationFilter,
  semiCalculationMaterials,
} from "app/states/handbooks";
import {
  getMaterialData,
  getMaterialDataForCol,
  getUnitsMeasurementData,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import remove_red from "assets/images/icons/remove_red.svg";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormInput,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PROTECTED_TYPE } from "utilities/constants";
import { semiCalculationSchema } from "utilities/schema/spravochnikSchemas";
//  Калькуляция полуфабриката
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const dispatch = useAppDispatch();
  const getUnitsMeasurement = useAppSelector(getUnitsMeasurementData);
  const getMaterial = useAppSelector(getMaterialDataForCol);
  const getSemiMaterial = useAppSelector((store) =>
    SemiProductDataOptions(store, 1)
  );
  const materialsForm = useAppSelector(semiCalculationMaterials);
  const onSubmit = () => {
    try {
      HandBookServices.AddNewSemiCalculation({
        data: {
          polufabrikatId: get(values, "polufabrikatId", null),
          registerDate: get(values, "registerDate", null),
          kalkulyatsiyaPFDetailsList:
            get(
              materialsForm,
              "data.kalkulyatsiyaDetailsList[0].materialId",
              []
            ) === null
              ? null
              : get(materialsForm, "data.kalkulyatsiyaDetailsList", []),
        },
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        if (res.status == 200 && res.data) {
          handleCloseModal();
          afterSubmitAction();
          resetForm();
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      polufabrikatId: null,
      registerDate: new Date(),
    },
    onSubmit,
    validationSchema: semiCalculationSchema,
  });
  //  calculate price on changes
  const calculation = useCallback(
    (one: number, two: number, index: number) => {
      if (one || two || index) {
        dispatch(
          handleAddMaterialsSemi({ name: "kol", value: one + two, id: index })
        );
      }
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(fetchUnitsMeasurement({ all: true }));
    dispatch(fetchSemiProductMaterial({ all: true, tip: "PF" }));
    dispatch(fetchRawMaterial({ all: true, tip: "SM" }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"} style={{ paddingLeft: 0 }}>
          <FlexBox gap="10px">
            <Col>
              <FlexBox>
                <Title style={{ paddingRight: "24px" }}>
                  Наименование полуфабриката
                  <span className="required_point">*</span>
                </Title>
                <BaseSelect
                  id={"polufabrikatId"}
                  width="200px"
                  isSearchable
                  value={get(values, "polufabrikatId", "")}
                  options={getSemiMaterial}
                  handleChange={(e: any) => {
                    setFieldValue("polufabrikatId", e);
                  }}
                />
              </FlexBox>
            </Col>
            <Col>
              <FlexBox width="100%">
                <Title className="px-24">
                  Дата регистрации<span className="required_point">*</span>
                </Title>

                <DatePicker
                  width="150px"
                  id={"registerDate"}
                  value={get(values, "registerDate", "")}
                  minDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                  handleDate={(e: string) => {
                    setFieldValue("registerDate", e);
                  }}
                />
              </FlexBox>
            </Col>
          </FlexBox>
          <Row className="mt-24">
            <BaseTable
              tableHeader={[
                "Материал *",
                "Ед.изм *",
                "Кол-во по норме на единицу продукции *",
                " Кол-во отходов на единицу продукции *",
                "Кол-во всего *",
                "Целое *",
              ]}
            >
              {!isEmpty(
                get(materialsForm, "data.kalkulyatsiyaDetailsList", [])
              ) &&
                get(materialsForm, "data.kalkulyatsiyaDetailsList", []).map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index + 1}>
                        <td style={{ width: "200px" }}>
                          <BaseSelect
                            width={"200px"}
                            id={"materialId"}
                            value={get(item, "materialId", "")}
                            isSearchable
                            options={getMaterial}
                            defaultValue={{
                              label: get(item, "imyaMateriala", null),
                              value: get(item, "materialId", null),
                            }}
                            handleValueLabel={(e: any) => {
                              dispatch(
                                handleAddMaterialsSemi({
                                  name: "materialId",
                                  value: get(e, "value", null),
                                  id: index,
                                })
                              );
                              dispatch(
                                handleAddMaterialsSemi({
                                  name: "imyaMateriala",
                                  value: get(e, "label", null),
                                  id: index,
                                })
                              );
                              dispatch(
                                handleAddMaterialsSemi({
                                  name: "edIzmId",
                                  value: get(e, "edIzmId", null),
                                  id: index,
                                })
                              );
                            }}
                            // menuPosition={"static"}
                          />
                        </td>
                        <td style={{ width: "100px" }}>
                          <BaseSelect
                            width={"200px"}
                            id={"edIzmId"}
                            value={get(item, "edIzmId", "")}
                            options={getUnitsMeasurement}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <BaseMarkInput
                            value={get(item, "kolNorm", 0) || ""}
                            handleInput={(e: string) => {
                              dispatch(
                                handleAddMaterialsSemi({
                                  name: "kolNorm",
                                  value: e,
                                  id: index,
                                })
                              );
                              calculation(
                                Number(e),
                                Number(get(item, "kolOtx", 0) || ""),
                                index
                              );
                            }}
                          />
                        </td>
                        <td>
                          <BaseMarkInput
                            value={get(item, "kolOtx", 0) || ""}
                            handleInput={(e: string) => {
                              dispatch(
                                handleAddMaterialsSemi({
                                  name: "kolOtx",
                                  value: e,
                                  id: index,
                                })
                              );
                              calculation(
                                Number(get(item, "kolNorm", 0)),
                                Number(e),
                                index
                              );
                            }}
                          />
                        </td>
                        <td>
                          <BaseMarkInput
                            value={
                              Number(get(item, "kolNorm", 0)) +
                                Number(get(item, "kolOtx", 0)) || ""
                            }
                          />
                        </td>
                        <td style={{ width: "130px" }}>
                          <BaseSelect
                            id={"seloe"}
                            width="400px"
                            isSearched
                            value={get(item, "seloe", 0)}
                            options={[
                              { label: "Нет", value: 0 },
                              { label: "Да", value: 1 },
                            ]}
                            handleChange={(e: any) => {
                              dispatch(
                                handleAddMaterialsSemi({
                                  name: "seloe",
                                  value: e,
                                  id: index,
                                })
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
            </BaseTable>
          </Row>
          <Row justify="end" className="px-16">
            <ReactSVG
              src={add_green}
              className="svg_icon px-14"
              onClick={() =>
                dispatch(
                  addNewRowSemi({
                    materialId: null,
                    edIzmId: null,
                    kolNorm: 0,
                    kolOtx: 0,
                    kol: 0,
                    seloe: 0,
                  })
                )
              }
            />
            {get(materialsForm, "data.kalkulyatsiyaDetailsList", []).length >=
              2 && (
              <ReactSVG
                src={remove_red}
                className="svg_icon"
                onClick={() => dispatch(removeRowMaterialSemi())}
              />
            )}
          </Row>
          <Col
            xs={12}
            className="text-right mt-24 mb-16"
            style={{ paddingRight: 0 }}
          >
            <Button className="mr-8" type="submit" green loading={isSubmitting}>
              Добавить
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                dispatch(clearMaterialsSemiCalculation());
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const SemiCalculationContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(semiCalculationData);
  const filter = useAppSelector(semiCalculationFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleSemiCalculationFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchSemiCalculation({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(
      fetchSemiCalculation({ ...filter, search: "", inAscOrder: false })
    );
    dispatch(
      handleSemiCalculationFilter({
        name: "search",
        value: "",
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    return () => {
      dispatch(handleSemiClearFilter());
    };
  }, []);
  const fetchAllCalculationData = useCallback(() => {
    dispatch(fetchSemiCalculation({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    dispatch(clearMaterialsSemiCalculation());
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteSemiCalculation(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllCalculationData();
      }
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "KALKULYATSIYA_PF_CREATE",
                    page: "KALKULYATSIYA_PF",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                value={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal} width={"1200px"}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllCalculationData()}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименования полуфабриката",
                    "Дата регистрации",
                    "Материал",
                    "Единица измерения",
                    "Кол-во по норме на единицу продукции",
                    "Кол-во отходов на единицу продукции",
                    "Кол-во всего",
                    "Целое",
                  ]}
                >
                  {isEqual(LOADING, false) ? (
                    <>
                      {!isEmpty(get(data, "calculationData", [])) ? (
                        get(data, "calculationData", []).map(
                          (item: CalculationDataType, index: number) => (
                            <>
                              <tr key={item.id}>
                                <td>
                                  <ActionBase
                                    isShowUpdate={false}
                                    isVertical={true}
                                    pagePermission={"KALKULYATSIYA_PF"}
                                    handleUpdate={() => {}}
                                    handleDelete={() =>
                                      handleDelete(get(item, "id", null))
                                    }
                                  />
                                </td>
                                <td>{get(item, "id", 0)}</td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "imyaPolufabrikat", "-")}</td>
                                <td>
                                  {moment(get(item, "registerDate")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                              {get(item, "kalkulyatsiyaPFDetailsList", []).map(
                                (material: any, index: number) => (
                                  <tr key={index + 1}>
                                    <td></td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {get(material, "imyaMateriala", "-")}
                                    </td>
                                    <td>{get(material, "imyaEdIzm", "-")}</td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(material, "kolNorm", "-")}
                                        thousandSeparator
                                        decimalScale={2}
                                      />
                                    </td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(material, "kolOtx", "-")}
                                        thousandSeparator
                                        decimalScale={2}
                                      />
                                    </td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(material, "kol", "-")}
                                        thousandSeparator
                                        decimalScale={2}
                                      />
                                    </td>
                                    <td>
                                      {get(material, "seloe", "-") === 1
                                        ? "да"
                                        : "нет"}
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          )
                        )
                      ) : (
                        <Nodata colLength={11} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={11}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default SemiCalculationContainer;
