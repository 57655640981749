import { TableWrapper } from "./styles/style";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { NumericFormat } from "react-number-format";

const RegisterInvoicesTable = ({ data, click }: any) => {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth={"1400px"} className="mb-16">
          <thead>
            <tr>
              <th>№</th>
              <th>
                Номер счет
                <br /> фактуры
              </th>
              <th>
                Дата счет
                <br /> фактуры
              </th>
              <th style={{ width: "180px" }}>Заказчик</th>
              <th>Договор</th>
              <th>Способ оплаты</th>
              {click ? (
                <>
                  <th style={{ width: "180px" }}>Товар</th>
                  <th>Количество</th>
                </>
              ) : null}
              <th>Сумма</th>
              <th>НДС</th>
              <th>Всего</th>
            </tr>
          </thead>
          <tbody>
            {/* the map */}
            {!isEmpty(get(data, "dataList", [])) ? (
              get(data, "dataList", []).map((item: any, index: number) => (
                <>
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{get(item, "nomerDoc", "")}</td>
                    <td>
                      {moment(get(item, "dataDoc", "")).format("DD.MM.YYYY")}
                    </td>
                    <td>{get(item, "imyaKontragent", "")}</td>
                    <td>{get(item, "nomerDogProdaja", "")}</td>
                    <td>{get(item, "imyaSposobOplati", "")}</td>
                    {click ? (
                      <>
                        <th></th>
                        <th></th>
                      </>
                    ) : null}
                    <th>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "summa", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </th>
                    <th>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "nds", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </th>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "vsego", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  {click && !isEmpty(get(item, "details", []))
                    ? get(item, "details", []).map(
                      (product: any, index: number) => (
                        <tr
                          style={{ backgroundColor: "#f8f8f8" }}
                          key={index + 1}
                        >
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td>{get(product, "imyaTovar", "")}</td>
                          <td>{get(product, "kol", "")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(product, "summa", 0)}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(product, "nds", 0)}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                        </tr>
                      )
                    )
                    : ""}
                </>
              ))
            ) : (
              <tr>
                <td colSpan={11}>Виберите параметры</td>
              </tr>
            )}
            {/* the end */}
            {/* <tr>
              <td colSpan={10} style={{ textAlign: "start" }}>
                Итого:{" "}
              </td>

              <td>{get(data, "itogo", "")}</td>
            </tr> */}
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
};

export default RegisterInvoicesTable;
