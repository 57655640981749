import { memo, useEffect, useState } from "react";
import { InputWrapper, ShowPassword } from "./style";
import icon from "assets/images/icons/eye.svg"
import close from "assets/images/icons/close_eys.svg"
import { ReactSVG } from "react-svg";
const Input = ({
    clear = "",
    placeholder = "",
    disabled = false,
    type,
    password,
    handleInput = (e: any) => { },
    show = false,
    handleShowPassword = (e: any) => { },
    ...props
}: any) => {
    const [val, setVal] = useState<any>(clear);

    const handleChange = (e: any) => {
        setVal(e.target.value);
        handleInput(e.target.value);
    };

    useEffect(() => {
        if (clear.length == 0) {
            setVal(clear);
        }
    }, [clear]);
    return (
        <>
            <InputWrapper
                onWheel={(e: any) => { e.currentTarget.blur() }}
                value={val}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
                {...props}
            />
            {password &&
                <ShowPassword onClick={handleShowPassword} type="none">
                    {
                        show ? <ReactSVG src={close} /> :
                            <ReactSVG src={icon} />
                    }
                </ShowPassword>}
        </>
    );
};

export default memo(Input);
