import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchAllFizList } from "app/slices/handbookSlices/FizLitsSlices/fizLitsSlices";
import { fetchAllKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { fetchPhysPerson } from "app/slices/handbookSlices/physPersonSlices/physPersonSlices";
import { UsersDataType } from "app/slices/handbookSlices/usersSlices/type";
import {
  fetchUsers,
  handleClearFilter,
  handleUsersFilter,
} from "app/slices/handbookSlices/usersSlices/usersSlices";
import { allUsersData, allUsersFilter } from "app/states/handbooks";
import {
  getTypeFizLits,
  getWarHouseData,
  yurFizPersonData,
} from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BasePhoneInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  FormSelect,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import SearchableInput from "components/SearchableInput/searchableInput";
import { useFormik } from "formik";
import { m } from "framer-motion";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { userDTOSchema } from "utilities/schema/spravochnikSchemas";
//  Пользователи
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: UsersDataType;
}) => {
  const dispatch = useAppDispatch();
  const typePhysOptions = useAppSelector(getTypeFizLits);
  const getPhysPerson = useAppSelector((state) => yurFizPersonData(state, 2));

  const onSubmit = (values: any) => {
    const req = {
      ...values,
    };
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateUsers({
          data: {
            ...req,
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          setSubmitting(false);
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
            resetForm();
            setDataById({});
          }
        });
      } else {
        HandBookServices.AddNewUsers({
          data: req,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          setSubmitting(false);
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
            resetForm();
            setDataById({});
          }
        });
      }
    } catch (err) {
      setSubmitting(false);
      console.log("Error", err);
    }
  };
  const [dataById, setDataById] = useState<any>({});
  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      imya: String(get(details, "imya", "")),
      parol: get(details, "parol", ""),
      fizLitsaId: get(details, "fizLitsaId", null),
      tipiFizLitsaId: get(dataById, "tipiFizLitsaId", ""),
      imyaFizLitsa: get(dataById, "imyaFizLitsa", ""),
      telNomer: get(dataById, "telNomer", ""),
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: userDTOSchema,
  });
  const getbyIdsearch = useCallback(() => {
    if (get(details, "fizLitsaId", null)) {
      HandBookServices.GetPhysPersonById(get(details, "fizLitsaId", null)).then(
        (res) => {
          if (res && res.data && res.data.data)
            setDataById(get(res, "data.data", ""));
        }
      );
    }
  }, [get(details, "fizLitsaId", null)]);
  useEffect(() => {
    // searchByFizList(get(details, "imyaFizLitsa", ""));
    getbyIdsearch();
  }, [get(details, "fizLitsaId", null)]);
  useEffect(() => {
    dispatch(fetchPhysPerson({ all: true, polzovateli: true }));
    dispatch(fetchAllFizList({ all: true, polzovateli: true }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Input
            label={"Логин пользователя"}
            value={get(values, "imya", "")}
            handleInput={(e: string) => {
              setFieldValue("imya", e.replace(/\s/g, ""));
            }}
            required={true}
          />
          <Input
            label={"Пароль пользователя"}
            value={get(values, "parol", "")}
            handleInput={(e: string) => {
              setFieldValue("parol", e.replace(/\s/g, ""));
            }}
            error={errors ? errors.parol : ""}
            required={true}
          />
          {!get(details, "id", null) && (
            <>
              <Row align="center" className="mt-8">
                <Col xs={4}>
                  <Title>
                    ФИО Пользователя<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <SearchableInput
                    className="mt-16"
                    isSearchable
                    value={get(values, "imyaFizLitsa", "") || ""}
                    options={getPhysPerson}
                    defaultValue={{
                      label: get(values, "imyaFizLitsa", ""),
                      value: get(values, "fizLitsaId", ""),
                    }}
                    handleValueLabel={(e: any) => {
                      if (get(e, "createdBy", null)) {
                        if (!isEmpty(getPhysPerson)) {
                          setFieldValue("fizLitsaId", e.value);
                          setFieldValue("telNomer", e.telNomer);
                          setFieldValue("tipiFizLitsaId", e.tipiFizLitsaId);
                          setFieldValue("imyaFizLitsa", get(e, "label", ""));
                        }
                      } else {
                        setFieldValue("imyaFizLitsa", get(e, "label", ""));
                        setFieldValue("tipiFizLitsaId", null);
                        setFieldValue("telNomer", "");
                      }
                    }}
                  />
                </Col>
              </Row>
              <FormSelect
                className="mt-24"
                id={"tipiFizLitsaId"}
                isSearchable
                label={"Тип физического лица"}
                value={get(values, "tipiFizLitsaId", "")}
                options={typePhysOptions}
                handleValueLabel={(e: any) =>
                  setFieldValue("tipiFizLitsaId", e.value)
                }
                required={true}
              />
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Телефоны<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BasePhoneInput
                    value={get(values, "telNomer", "")}
                    handleInput={(e: string) => {
                      setFieldValue("telNomer", e);
                    }}
                    format="+998 (##) ### ## ##"
                    allowEmptyFormatting={true}
                    mask="_"
                    className="your-custom-class"
                  />
                </Col>
              </Row>
            </>
          )}
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green loading={isSubmitting}>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
                setFieldValue("fizLitsaId", "");
                setFieldValue("imyaFizLitsa", "");
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const UsersContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<UsersDataType | any>({});
  const data = useAppSelector(allUsersData);
  const filter = useAppSelector(allUsersFilter);
  let LOADING = get(data, "loading", true);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleUsersFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchUsers({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(fetchUsers({ ...filter, search: "", InAscOrder: false }));
    dispatch(
      handleUsersFilter({
        name: "search",
        value: "",
        InAscOrder: false,
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    return () => {
      dispatch(handleClearFilter());
    };
  }, []);
  const fetchAllUsersData = useCallback(() => {
    dispatch(fetchUsers({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: UsersDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);

  const handleDelete = (id: number | null) => {
    HandBookServices.DelenteUsers(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllUsersData();
      }
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "POLZOVATELI_CREATE",
                    page: "POLZOVATELI",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllUsersData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Дисплей ID",
                    "Пользователь",
                    "Логин",
                    "Пароль пользователя",
                    "ФИО Пользователя",
                  ]}
                >
                  {isEqual(LOADING, false) ? (
                    <>
                      {!isEmpty(get(data, "usersData", [])) ? (
                        get(data, "usersData", []).map(
                          (item: UsersDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"POLZOVATELI"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "displayId", "")}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imya", "-")}</td>
                              <td>{get(item, "parol", "-")}</td>
                              <td>{get(item, "imyaFizLitsa", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={7} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={7}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default UsersContainer;
