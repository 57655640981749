import request from "services/api";

class RegisterServices {
  static FetchPivotTable = (params: any) => {
    return request.get("/api/register/svodnaya/search", { params: params });
  };
  static FetchRemainTable = (params: any) => {
    return request.get("/api/register/ostatki", { params: params });
  };

  static FetchPivotTableStatistic = () =>{
    return request.get("/api/register/svodnaya/statistics")
  }
  static FetchRemainTableStatistic = () =>{
    return request.get("/api/register/ostatki/statistics")
  }
}

export default RegisterServices;
