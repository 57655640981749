import { memo } from "react";
import ReactPaginate from "react-paginate";
import { Props } from "./type";

const Pagination = ({ pageCount = 10, onPageChange, current }: Props) => {
    return (
        <>
            <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                pageCount={pageCount}
                onPageChange={onPageChange}
                pageRangeDisplayed={3}
                containerClassName={"pagination_container"}
                activeClassName={"pagination_active"}
                disabledClassName={"pagination_disabled"}
                forcePage={current}
            />
        </>
    );
};

export default memo(Pagination);
