import { useAppDispatch, useAppSelector } from "app/hook";
import { logOut } from "app/slices/authSlices/authSlices";
import {
  clearProfile,
  fetchProfileInfo,
} from "app/slices/profileSlices/profileSlices";
import { authData } from "app/states/auth";
import { Menu } from "components";
import { get, isEqual } from "lodash";
import { Fragment, useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Auth, Main } from "routers/paths";
import AuthServices from "services/apiServices/auth";
const MainLayout = () => {
  const dispatch = useAppDispatch();
  const userAuthData = useAppSelector(authData);
  const isAuthenticated = get(userAuthData, "isAuthenticated", false);
  const navigate = useNavigate();
  const handleLogout = () => {
    AuthServices.LogOut()
      .then((res) => {
        if (res.status === 200) {
          navigate(Main.LOGIN, { replace: true });
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProfileInfo()).then((res) => {
        if (
          isEqual(get(res, "meta.requestStatus", ""), "rejected") ||
          isEqual(get(res, "status", 0), 401)
        ) {
          // setLoading(false);
          dispatch(logOut());
          toast.warning("ошибка информации о пользователе");
          dispatch(clearProfile());
          handleLogout();
        }
      });
    }
  }, [dispatch, userAuthData]);

  return isAuthenticated ? (
    <Fragment>
      <Menu />
      <Container fluid>
        <Row debug style={{ paddingTop: "70px" }}>
          <Col xs={12}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </Fragment>
  ) : (
    <Navigate to={Auth.LOG_IN} replace />
  );
};

export default MainLayout;
