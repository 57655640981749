import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";
import HomeService from "services/apiServices/home";

const initialState: InitialState = {
  data: {
    loading: false,
    status: {},
    charts: {},
    error: "",
  },
};

export const fetchHomeChartData = createAsyncThunk(
  "allPivots/fetchHomeChartData",
  async (params: any, thunkAPI) => {
    try {
      const request = await HomeService.ChartStatusData(params);

      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchChartTime = createAsyncThunk(
  "allPivots/fetchChartTime",
  async (params: any, thunkAPI) => {
    try {
      const request = await HomeService.ChartStatusData(params);

      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const homeSlices = createSlice({
  name: "allPivots",
  initialState,
  reducers: {
    fetchChartData: (state, action) => {
      state.data = {
        loading: false,
        status: get(action, "payload.data.stats", []),
        charts: get(action, "payload.data.charts", []),
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHomeChartData.pending, (state) => {
      state.data = {
        loading: true,
        error: "",
      };
    });
    builder.addCase(fetchHomeChartData.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        status: get(action, "payload.data.stats", []),
        charts: get(action, "payload.data.charts", []),
        error: "",
      };
    });
    builder.addCase(fetchHomeChartData.rejected, (state, payload) => {
      state.data = {
        loading: false,
        error: payload.error.message,
      };
    });
  },
});
export const { fetchChartData } = homeSlices.actions;
export default homeSlices.reducer;
