import { combineReducers } from "@reduxjs/toolkit";
import registerSalesCntSlice from "./registerSalesCntSlice/registerSalesCntSlice";
import orderRegisterSlice from "./orderRegisterSlice/orderRegisterSlice";
import registerPurchasesSlice from "./registerPurchaseSlices/registerPurchaseSlices";
import receivedMaterialsSlice from "./receivedMaterialSlices/receivedMaterialSlices";
import releaseProductSlices from "./releaseProductSlice/releaseProductSlices";
import shipmentProductSlices from "./shipmentProductSlices/shipmentProductSlices";
import salesProductSlices from "./salesProductSlices/salesProductSlices";
import semiProductSlices from "./semiProductSlice/semiProductSlices";
import returnMaterialsSlice from "./returnMaterialSlices/returnMaterialSlices";
import movementMaterialSlices from "./movementMaterialSlices/movementMaterialSlices";
import returnProductSlices from "./returnProductsSlices/returnProductSlices";
import otherCostsSlice from "./otherProdCostSlice/otherProdCostSlice";
import returnProductBefRealSlices from "./returnProductBefRealSlices/returnProductBefRealSlices";
import registerParishCashSlices from "./registerParishCashSlices/registerParishCashSlices";
import arrivedCashesSlice from "./arrivedCashesSlice/arrivedCashesSlice";
import costsInCashSlice from "./costsInCashSlice/costsInCashSlice";
import manualMaterialSlices from "./manualMaterialSlices/manualMaterialSlices";
import registerBezNalichSlice from "./registerBezNalichSlice/registerBezNalichSlice";
import offsetAdvanceSlices from "./offsetAdvanceSlices/offsetAdvanceSlices";
import calculateProductSlices from "./calculateProductSlices/calculateProductSlices";
import closeMonthSlices from "./closeMonthSlices/closeMonthSlices";
import manualPostingSlice from "./manualPostingSlice/manualPostingSlice";
import returnConcreteDriverSlice from "./returnConcreteDriverSlice/returnConcreteDriverSlice";
import substandardProductSlices from "./substandardProductSlices/substandardProductSlices";
import changeProductSlice from "./changeProductSlices/changeProductSlice";
import changeSemiProductSlices from "./changeSemiProductSlices/changeSemiProductSlices";
import movingCashSlices from "./movingCashSlices/movingCashSlices";

const reducer = combineReducers({
    registerSalesContracts: registerSalesCntSlice,
    orderRegister: orderRegisterSlice,
    purchaseContracts: registerPurchasesSlice,
    receivedMaterials: receivedMaterialsSlice,
    semiProducts: semiProductSlices,
    releaseProducts: releaseProductSlices,
    returnProductBefReal: returnProductBefRealSlices,
    shipmentProducts: shipmentProductSlices,
    salesProducts: salesProductSlices,
    returnMaterials: returnMaterialsSlice,
    movementMaterials: movementMaterialSlices,
    manualMaterials: manualMaterialSlices,
    returnProducts: returnProductSlices,
    otherProdCosts: otherCostsSlice,
    registerParishCash: registerParishCashSlices,
    arrivedCashes: arrivedCashesSlice,
    costsInCash: costsInCashSlice,
    registerBezNalich: registerBezNalichSlice,
    offsetAdvance: offsetAdvanceSlices,
    calculateProduct: calculateProductSlices,
    closeMonth: closeMonthSlices,
    manualPosting: manualPostingSlice,
    returnConcreteDriver: returnConcreteDriverSlice,
    substandardProduct: substandardProductSlices,
    changeProduct:changeProductSlice,
    changeSemiProduct:changeSemiProductSlices,
    movingCash:movingCashSlices
});

export default reducer;
