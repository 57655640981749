import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchAllFizList } from "app/slices/handbookSlices/FizLitsSlices/fizLitsSlices";
import {
  fetchDrivers,
  handleClearFilter,
  handleDriversFilter,
} from "app/slices/handbookSlices/driversSlices/driversSlices";
import { DriversDataType } from "app/slices/handbookSlices/driversSlices/type";
import { fetchPhysPerson } from "app/slices/handbookSlices/physPersonSlices/physPersonSlices";
import { fetchTrucks } from "app/slices/handbookSlices/truckSlices/truckSlices";
import { allDrivers, allDriversFilter } from "app/states/handbooks";
import {
  getPhysPersonData,
  getTruckData,
  getTypeFizLits,
  yurFizPersonData,
} from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BasePhoneInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  FormSelect,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import { ReactModal } from "components";
import SearchableInput from "components/SearchableInput/searchableInput";
import { useFormik } from "formik";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { driverSchema } from "utilities/schema/spravochnikSchemas";
//   Водители
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: DriversDataType;
}) => {
  const dispatch = useAppDispatch();
  const getTrucks = useAppSelector(getTruckData);
  const typePhysOptions = useAppSelector(getTypeFizLits);
  const getPhysPerson = useAppSelector((state) => yurFizPersonData(state, 2));
  const onSubmit = (values: any) => {
    const res = {
      ...values,
      avtoId: get(values, "avtoId", null),
      tipiFizLitsaId: get(values, "tipiFizLitsaId", null),
    };
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateDrivers({
          data: { ...res, id: get(details, "id", null) },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            setSubmitting(false);
            handleCloseModal();
            afterSubmitAction();
            resetForm();
          }
        });
      } else {
        HandBookServices.AddNewDrivers({
          data: res,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            setSubmitting(false);
            handleCloseModal();
            afterSubmitAction();
            resetForm();
          }
        });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fizLitsaId: get(details, "fizLitsaId", null),
      imyaFizLitsa: get(details, "imya", ""),
      avtoId: get(details, "avtoId", ""),
      telNomer: get(details, "telNomer", ""),
      tipiFizLitsaId: get(details, "tipiFizLitsaId", ""),
      kommentariya: get(details, "kommentariya", ""),
    },
    onSubmit,
    validationSchema: driverSchema,
  });

  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center">
            <Col xs={4}>
              <Title>
                Наименование водителя<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <SearchableInput
                id={"imyaFizLitsa"}
                className="mt-16"
                isSearchable
                value={get(values, "imyaFizLitsa", "")}
                options={getPhysPerson}
                defaultValue={{
                  label: get(values, "imyaFizLitsa", ""),
                  value: get(values, "fixLitsaId", ""),
                }}
                handleValueLabel={(e: any) => {
                  if (get(e, "createdBy", null)) {
                    if (!isEmpty(getPhysPerson)) {
                      setFieldValue("fizLitsaId", e.value);
                      setFieldValue("telNomer", e.telNomer);
                      setFieldValue("tipiFizLitsaId", e.tipiFizLitsaId);
                      setFieldValue("imyaFizLitsa", get(e, "label", ""));
                    }
                  } else {
                    setFieldValue("imyaFizLitsa", get(e, "label", ""));
                    setFieldValue("tipiFizLitsaId", null);
                    setFieldValue("telNomer", "");
                  }
                }}
              />
            </Col>
          </Row>
          <FormSelect
            id={"avtoId"}
            label={"Автомобил"}
            isSearchable
            className="mt-24"
            value={get(values, "avtoId", "")}
            options={getTrucks}
            handleValueLabel={(e: any) => setFieldValue("avtoId", e.value)}
            required={true}
          />
          {!get(details, "id", null) && (
            <FormSelect
              id={"tipiFizLitsaId"}
              label={"Тип физического лица"}
              isSearchable
              value={get(values, "tipiFizLitsaId", "")}
              options={typePhysOptions}
              className="mt-24"
              handleValueLabel={(e: any) =>
                setFieldValue("tipiFizLitsaId", e.value)
              }
              required={true}
            />
          )}
          {!get(details, "id", null) && (
            <Row align="center" className="mt-24">
              <Col xs={4} style={{ paddingRight: 0 }}>
                <Title>
                  Телефоны<span className="required_point">*</span>
                </Title>
              </Col>
              <Col xs={8}>
                <BasePhoneInput
                  value={get(values, "telNomer", "")}
                  handleInput={(e: string) => {
                    setFieldValue("telNomer", e);
                  }}
                  format="+998 (##) ### ## ##"
                  allowEmptyFormatting={true}
                  mask="_"
                  className="your-custom-class"
                />
              </Col>
            </Row>
          )}
          <Input
            label={"Комментарии"}
            value={get(values, "kommentariya", "")}
            handleInput={(e: string) => {
              setFieldValue("kommentariya", e);
            }}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green loading={isSubmitting}>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const DriversContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<DriversDataType | any>({});
  const data = useAppSelector(allDrivers);
  const filter = useAppSelector(allDriversFilter);
  let LOADING = get(data, "loading", true);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleDriversFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchDrivers({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(fetchDrivers({ ...filter, search: "", InAscOrder: false }));
    dispatch(
      handleDriversFilter({
        name: "search",
        value: "",
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    return () => {
      dispatch(handleClearFilter());
    };
  }, []);
  const fetchAllDriversData = useCallback(() => {
    dispatch(fetchDrivers({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: DriversDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteDrivers(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllDriversData();
      }
    });
  };

  useEffect(() => {
    dispatch(fetchTrucks({ all: true }));
    dispatch(fetchPhysPerson({ all: true, voditeli: true }));
    dispatch(fetchAllFizList({ all: true, voditeli: true }));
  }, [dispatch]);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "VODITELI_CREATE",
                    page: "VODITELI",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllDriversData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименования  водителя",
                    "Автомобил",
                    "Комментарии",
                  ]}
                >
                  {isEqual(LOADING, false) ? (
                    <>
                      {!isEmpty(get(data, "driversData", [])) ? (
                        get(data, "driversData", []).map(
                          (item: DriversDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"VODITELI"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imya", "-")}</td>
                              <td>{get(item, "gosNomer", "-")}</td>
                              <td>{get(item, "kommentariya", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={6} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={6}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default DriversContainer;
