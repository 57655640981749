import styled from "styled-components";

const AuthStyledContainer = styled.div`
    min-height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export { AuthStyledContainer };
