import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: any = {
  filter: {
    territoriyaId: null,
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  statistics: {
    loading: false,
    data: {},
    error: "",
  },
  registerBezNalich: {
    loading: false,
    data: [],
    error: "",
  },
  registerBezNalichTemp: {
    loading: false,
    data: [],
    error: "",
  },
  details: {
    data: [],
  },
};

export const fetchAllRegisterBezNalich = createAsyncThunk(
  "registerBezNalich/fetchAllRegisterBezNalich",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.GetRegisterBezNal(params);
      const respond = await request.data;
      const { data, pagination } = await respond;
      return { data, pagination };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllRegisterBezNalichTemp = createAsyncThunk(
  "registerBezNalich/fetchAllRegisterBezNalichTemp",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetRegisterBezNalTemp();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllRegisterBezNalichStat = createAsyncThunk(
  "registerBezNalich/fetchAllRegisterBezNalichStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetRegisterBezNalStat();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const registerBezNalichSlice = createSlice({
  name: "registerBezNalich",
  initialState,
  reducers: {
    handleFilterChange: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },

    handleFormChange: (state, actions) => {
      state.addingForm = {
        ...state.addingForm,
        [actions.payload.name]: actions.payload.value,
      };
    },
    handleClearFormExpense: (state) => {
      state.details = initialState.details;
    },
    handleSubmitDetailExpenseCash: (state, action) => {
      state.details.data = get(action, "payload", []);
    },
    changeExpenseCashSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.details = {
          ...state.details,
          data: [...state.details.data, action.payload.data],
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.details = {
          ...state.details,
          data: state.details.data.map((item: any, index: number) =>
            index == id ? get(action, "payload.data", {}) : item
          ),
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.details = {
          ...state.details,
          data: state.details.data.filter(
            (item: any, index: number) => index !== id
          ),
        };
      } else {
        state.details = {
          ...state.details,
          data: [],
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllRegisterBezNalich.pending, (state) => {
      state.registerBezNalich = {
        loading: true,
      };
    });
    builder.addCase(fetchAllRegisterBezNalich.fulfilled, (state, actions) => {
      state.registerBezNalich = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllRegisterBezNalich.rejected, (state, actions) => {
      state.registerBezNalich = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });

    builder.addCase(fetchAllRegisterBezNalichTemp.pending, (state) => {
      state.registerBezNalichTemp = {
        loading: true,
      };
    });
    builder.addCase(
      fetchAllRegisterBezNalichTemp.fulfilled,
      (state, actions) => {
        state.registerBezNalichTemp = {
          loading: false,
          data: get(actions, "payload", {}),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchAllRegisterBezNalichTemp.rejected,
      (state, actions) => {
        state.registerBezNalichTemp = {
          loading: false,
          data: {},
          error: get(actions, "error.message", ""),
        };
      }
    );

    builder.addCase(fetchAllRegisterBezNalichStat.pending, (state) => {
      state.statistics = {
        loading: true,
      };
    });
    builder.addCase(
      fetchAllRegisterBezNalichStat.fulfilled,
      (state, actions) => {
        state.statistics = {
          loading: false,
          data: get(actions, "payload.data", {}),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchAllRegisterBezNalichStat.rejected,
      (state, actions) => {
        state.statistics = {
          loading: false,
          data: {},
          error: get(actions, "error.message", ""),
        };
      }
    );
  },
});

export const {
  handleFilterChange,
  handleFormChange,
  changeExpenseCashSubject,
  handleClearFormExpense,
  handleSubmitDetailExpenseCash,
} = registerBezNalichSlice.actions;
export default registerBezNalichSlice.reducer;
