import { Props, StyleProps } from "./type";
import { useState, useEffect, memo } from "react";
import { PickerWrapper } from "./style";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { ReactSVG } from "react-svg";
import calendarIcon from "assets/images/icons/calendar.svg";
import arrowIcon from "assets/images/icons/arrow-down.svg";

const days = ["Во", "По", "Вт", "Ср", "Че", "Пя", "Су"];
const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

const BaseDatePicker = ({
  defaultDate = null,
  value = null,
  placeholder = "",
  dateFormat = "dd/mm/yyyy",
  showTimeSelect = false,
  handleDate = (date) => date,
  id,
  width,
  openToDate,
  isClearable,
  disabled = false,
  defaultValue = null,
  minDate = null,
  maxDate = null,
  ref,
  ...props
}: Props) => {
  const [startDate, setStartDate] = useState<any>(null);
  const selectDate = (date: any) => {
    if (date) {
      setStartDate(date);
      handleDate(date);
    } else {
      handleDate(null);
      setStartDate("");
    }
  };
  // portal={document.getElementById('root')}
  useEffect(() => {
    if (value) {
      setStartDate(moment(value).toDate());
    } else {
      setStartDate(defaultValue);
    }
  }, [value]);

  const loc: any = {
    localize: {
      day: (n: any) => days[n],
      month: (n: any) => months[n],
    },
    formatLong: { date: () => dateFormat },
  };
  return (
    <PickerWrapper {...props} width={width}>
      <ReactSVG className={"calendar-icon"} src={calendarIcon} />
      <DatePicker
        ref={ref}
        autoComplete={"off"}
        locale={loc}
        onKeyDown={(e) => e.preventDefault()} // avoid typing manually
        id={id}
        selected={startDate}
        key={`unique_key__${value}`}
        showTimeSelect={showTimeSelect}
        openToDate={openToDate}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        onChange={(date) => selectDate(date)}
        wrapperClassName="datePicker"
        isClearable={!!startDate ? isClearable : false}
        portalId="datePicker-menu"
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
      />
      {disabled ? null : (
        <ReactSVG className={"arrow-icon mt-2 mr-2"} src={arrowIcon} />
      )}
    </PickerWrapper>
  );
};

export default memo(BaseDatePicker);
