import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, ReturnProductReal } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
  countStatProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      details: [],
    },
  },
  filter: {
    search: "",
    fromDate: null,
    toDate: null,
    kontragentId: null,
    tovarId: null,
    page: 0,
    size: 10,
  },
  returnProduct: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchReturnProduct = createAsyncThunk(
  "returnProduct/fetchReturnProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReturnProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchNotFilledReturnProduct = createAsyncThunk(
  "returnProduct/fetchNotFilledReturnProduct",
  async (params, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledReturnProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReturnProductByTempId = createAsyncThunk(
  "returnProduct/fetchReturnProductByTempId",
  async (id: string, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledReturnProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReturnProductById = createAsyncThunk(
  "returnProduct/fetchReturnProductById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReturnProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReturnProductStat = createAsyncThunk(
  "returnProduct/fetchReturnProductStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReturnProductStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const returnProductSlices = createSlice({
  name: "returnProduct",
  initialState,
  reducers: {
    addSubjectReturnProduct: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          details: !isEmpty(get(actions, "payload", {}))
            ? [...state.formData.data.details, actions.payload]
            : [],
        },
      };
    },
    movementReturnMaterialRealSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.map(
              (item: ReturnProductReal) =>
                item.tovarId == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.filter(
              (item: ReturnProductReal) => item.tovarId !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: [],
          },
        };
      }
    },
    filterChangeReturnProduct: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFormDataReturnProduct: (state) => {
      state.formData = initialState.formData;
    },
    clearFilterReturnProduct: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReturnProduct.pending, (state) => {
      state.returnProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchReturnProduct.fulfilled, (state, actions) => {
      state.returnProduct = {
        loading: false,
        data: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchReturnProduct.rejected, (state, actions) => {
      state.returnProduct = {
        loading: false,
        data: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    builder.addCase(fetchNotFilledReturnProduct.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(fetchNotFilledReturnProduct.fulfilled, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchNotFilledReturnProduct.rejected, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    //  fetch temp
    builder.addCase(fetchReturnProductByTempId.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchReturnProductByTempId.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchReturnProductByTempId.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    // fetch id
    builder.addCase(fetchReturnProductById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchReturnProductById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchReturnProductById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });

    // fetching statists
    builder.addCase(fetchReturnProductStat.pending, (state) => {
      state.countStatProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchReturnProductStat.fulfilled, (state, actions) => {
      state.countStatProduct = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchReturnProductStat.rejected, (state, actions) => {
      state.countStatProduct = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  addSubjectReturnProduct,
  filterChangeReturnProduct,
  clearFormDataReturnProduct,
  movementReturnMaterialRealSubject,
  clearFilterReturnProduct,
} = returnProductSlices.actions;
export default returnProductSlices.reducer;
