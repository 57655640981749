import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, otgruzkaGPDetailsType } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
  contractStatProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    data: {
      spisanieBrakDetails: [],
    },
  },
  filter: {
    tovarId: null,
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  substandard: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  subProduct: {
    loading: false,
    data: [],
    error: "",
  },
  statistics: {
    loading: false,
    data: {},
    error: "",
  },
  subTerritory: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchSubstandardProduct = createAsyncThunk(
  "substandardProduct/fetchSubstandardProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSubstandardProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSubstandardProductStatistics = createAsyncThunk(
  "substandardProduct/fetchSubstandardProductStatistics",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSubstandardStatistics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchTerritorySubstandards = createAsyncThunk(
  "substandardProduct/fetchTerritorySubstandards",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchTerritorySubstandard();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchTovarSubstandard = createAsyncThunk(
  "substandardProduct/fetchTovarSubstandard",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchProductSubstandard(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSubstandardProductById = createAsyncThunk(
  "substandardProduct/fetchSubstandardProductById",
  async (id: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSubstandardProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const substandardProductSlices = createSlice({
  name: "substandardProduct",
  initialState,
  reducers: {
    addSubstandardSub: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          spisanieBrakDetails: !isEmpty(get(actions, "payload", {}))
            ? actions.payload
            : [],
        },
      };
    },
    // movemnt
    movementSubstandardProduct: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            spisanieBrakDetails: !isEmpty(get(action, "payload.data", []))
              ? [
                  ...state.formData.data.spisanieBrakDetails,
                  action.payload.data,
                ]
              : [],
          },
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            spisanieBrakDetails: state.formData.data.spisanieBrakDetails.map(
              (item: any, index: number) =>
                index == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            spisanieBrakDetails: state.formData.data.spisanieBrakDetails.filter(
              (item: any, index: number) => index !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            spisanieBrakDetails: [],
          },
        };
      }
    },
    handleChangeItemSubjects: (state, action) => {
      const id = get(action, "payload.id", 0);
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          otgruzkaGPDetails: state.formData.data.otgruzkaGPDetails.map(
            (item: any, index: number) =>
              index == id
                ? {
                    ...item,
                    kol: !isEmpty(get(action, "payload.value", 0))
                      ? get(action, "payload.value", 0)
                      : 0,
                  }
                : item
          ),
        },
      };
    },
    filterChangeSubstandard: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearAllDataShipment: () => initialState,
    clearFormDataSubstandard: (state) => {
      state.formData = initialState.formData;
    },
    clearFilterSubProduct: (state) => {
      state.filter = initialState.filter;
    },
    clearSubProduct: (state) => {
      state.subProduct = state.subProduct;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSubstandardProduct.pending, (state) => {
      state.substandard = {
        loading: true,
      };
    });

    builder.addCase(fetchSubstandardProduct.fulfilled, (state, actions) => {
      state.substandard = {
        loading: false,
        data: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchSubstandardProduct.rejected, (state, actions) => {
      state.substandard = {
        loading: false,
        data: [],
        pagination: {},
        error: actions.error.message,
      };
    });
    // get tovar
    builder.addCase(fetchTovarSubstandard.pending, (state) => {
      state.subProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchTovarSubstandard.fulfilled, (state, actions) => {
      state.subProduct = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchTovarSubstandard.rejected, (state, actions) => {
      state.subProduct = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    // //  fetch territory
    builder.addCase(fetchTerritorySubstandards.pending, (state) => {
      state.subTerritory = {
        loading: true,
      };
    });
    builder.addCase(fetchTerritorySubstandards.fulfilled, (state, actions) => {
      state.subTerritory = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchTerritorySubstandards.rejected, (state, actions) => {
      state.subTerritory = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    //  fetch form
    builder.addCase(fetchSubstandardProductById.pending, (state) => {
      state.formData = {
        loading: true,
      };
    });
    builder.addCase(fetchSubstandardProductById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchSubstandardProductById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    // statistics
    builder.addCase(fetchSubstandardProductStatistics.pending, (state) => {
      state.statistics = {
        loading: true,
      };
    });
    builder.addCase(
      fetchSubstandardProductStatistics.fulfilled,
      (state, actions) => {
        state.statistics = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchSubstandardProductStatistics.rejected,
      (state, actions) => {
        state.statistics = {
          loading: false,
          data: [],
          error: actions.error.message,
        };
      }
    );
  },
});

export const {
  addSubstandardSub,
  filterChangeSubstandard,
  clearFormDataSubstandard,
  clearAllDataShipment,
  handleChangeItemSubjects,
  clearFilterSubProduct,
  clearSubProduct,
  movementSubstandardProduct,
} = substandardProductSlices.actions;
export default substandardProductSlices.reducer;
