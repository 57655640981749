import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchPriceMaterial,
  handlePriceClearFilter,
  handlePriceMaterialFilter,
} from "app/slices/handbookSlices/priceMaterialSlices/priceMaterialSlices";
import { PriceMaterialDataType } from "app/slices/handbookSlices/priceMaterialSlices/type";
import {
  allPriceMaterialData,
  allPriceMaterialFilter,
} from "app/states/handbooks";

import {
  BaseInput,
  BaseTable,
  Button,
  Content,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  WindowHeight,
} from "components";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";

//  Цены материалов
const MaterialPriceContainer = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(allPriceMaterialData);
  const filter = useAppSelector(allPriceMaterialFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handlePriceMaterialFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchPriceMaterial({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  const clearFilter = useCallback(() => {
    dispatch(fetchPriceMaterial({ ...filter, search: "", InAscOrder: false }));
    dispatch(
      handlePriceMaterialFilter({
        name: "search",
        value: "",
      })
    );
  }, [dispatch, filter]);
  useEffect(()=>{
    return ()=>{
      dispatch(handlePriceClearFilter())
    }
  },[])
  const fetchAllPriceMaterialData = useCallback(() => {
    dispatch(fetchPriceMaterial({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  useEffect(() => {
    fetchAllPriceMaterialData();
  }, [fetchAllPriceMaterialData]);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row className="mt-24"></Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "ID",
                    "Пользователь",
                    "Документ «Поступление сырья и материалов»",
                    "Наименования материала",
                    "Единица измерения",
                    "Цена",
                  ]}
                >
                  {isEqual(LOADING, false) ? (
                    <>
                      {!isEmpty(get(data, "priceMaterialData", [])) ? (
                        get(data, "priceMaterialData", []).map(
                          (item: PriceMaterialDataType, index: number) => (
                            <tr key={item.id}>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDocument", "-")}</td>
                              <td>{get(item, "imyaMateriala", "")}</td>
                              <td>{get(item, "imyaEdIzm", "-")}</td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "sena", "")}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={6} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={6}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default MaterialPriceContainer;
