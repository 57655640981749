import { useAppDispatch, useAppSelector } from "app/hook";
import { logIn } from "app/slices/authSlices/authSlices";
import { authData } from "app/states/auth";
import {
  AuthContainer,
  BaseInput,
  Content,
  FlexBox,
  LoginContainerWrapper,
  Title,
} from "components";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { get, isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Form, useNavigate } from "react-router-dom";
import bgImg from "assets/images/Frame.png";
import { LoginFormWrapper } from "components/LoginContainer/style";

const LoginContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const userAuthData = useAppSelector(authData);
  const isAuthenticated = get(userAuthData, "isAuthenticated", false);
  const [loading, setLoading] = useState(false);
  const onSubmit = (values: any) => {
    setLoading(true);
    dispatch(
      logIn({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: values,
      })
    ).then((res: any) => {
      if (isEqual(get(res, "meta.requestStatus", ""), "fulfilled")) {
        navigate(`/`, { replace: true });
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    });
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      login: "",
      parol: "",
    },
    enableReinitialize: true,
    onSubmit,
  });
  const checkUserIsAuth = useCallback(() => {
    if (isAuthenticated) {
      navigate(`/`, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    checkUserIsAuth();
  }, [checkUserIsAuth]);
  return (
    <Row className="full-height-row">
      <Col xs={6} style={{ padding: 0 }}>
        <LoginContainerWrapper imageUrl={bgImg} />
      </Col>
      <Col xs={6} style={{ padding: 0 }}>
        <LoginContainerWrapper bgColor={"#ffff"}>
          <Col xs={12}>
            <Title bold md>
              OLMOS BLOK
            </Title>
          </Col>
          <Form onSubmit={handleSubmit} className="mt-32">
            <LoginFormWrapper>
              <Title bold sm inter>
                Вход
              </Title>
              <Row className="mt-24">
                <Col xs={12}>
                  <Title mk width={"60vh"}>
                    Логин
                  </Title>
                </Col>
                <Col xs={12} className="mt-8">
                  <BaseInput
                    name={"login"}
                    width={"100%"}
                    value={get(values, "login", "")}
                    handleInput={(e: any) =>
                      setFieldValue("login", e.replace(/\s/g, ""))
                    }
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12}>
                  <Title mk>Пароль</Title>
                </Col>
                <Col xs={12} className="mt-8">
                  <FlexBox>
                    <BaseInput
                      name={"parol"}
                      width={"95%"}
                      password
                      value={get(values, "parol", "")}
                      type={showPassword ? "text" : "password"}
                      show={showPassword}
                      handleInput={(e: any) =>
                        setFieldValue("parol", e.replace(/\s/g, ""))
                      }
                      handleShowPassword={() => setShowPassword(!showPassword)}
                    />
                  </FlexBox>
                </Col>
                {/* <Col xs={12} className="mt-24">
                                    <Title mk className="green_link" >
                                        Забыли пароль?
                                    </Title>
                                </Col> */}
              </Row>
              <FlexBox className={"mt-32 w-100"} justify="center" column>
                <Button
                  type={"submit"}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                    gap: "10px",
                    height: "40px",
                  }}
                  severity="info"
                  loading={loading}
                  icon="pi pi-check"
                >
                  {!loading && `Войти`}
                </Button>
              </FlexBox>
            </LoginFormWrapper>
          </Form>
        </LoginContainerWrapper>
      </Col>
    </Row>
  );
};

export default LoginContainer;
