import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import ReportsServices from "services/apiServices/reports";
import moment from "moment";

const initialState: InitialState = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    kontragentId: null,
    dateIsp: null,
    sposobOplataId: null,
    sposobDostavkaId: null,
    withDetails: true,
  },
};

export const fetchRegisterSales = createAsyncThunk(
  "registerSalesTable/fetchRegisterSales",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchRegisterSalesTable(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const registerSalesSlices = createSlice({
  name: "registerSalesTable",
  initialState,
  reducers: {
    filterSalesTable: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;
      
      state.filter = {
        ...state.filter,
        [name]: value,
      };
    },
    clearSalesTable: () => initialState,
    changeSalesTable: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegisterSales.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchRegisterSales.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchRegisterSales.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});
export const { filterSalesTable, clearSalesTable, changeSalesTable } = registerSalesSlices.actions;
export default registerSalesSlices.reducer;
