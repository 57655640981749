import { Result } from "antd";
import { Button } from "components";
import { useNavigate } from "react-router-dom";

function NotFount() {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="К сожалению, страница,  которую вы посетили, не существует."
      extra={
        <Button primary handleClick={() => navigate(-1)}>
          Назад
        </Button>
      }
    />
  );
}

export default NotFount;
