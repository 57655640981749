import { useAppDispatch, useAppSelector } from "app/hook";
import { useEffect, useCallback } from "react";
import {
  clearFilterUserPerm,
  fetchUserRole,
  filterChangeUserRole,
} from "app/slices/usersSlices/userRoleSlices/userRoleSlices";
import { userRoleFilter, userRoleList } from "app/states/users";
import {
  ActionBase,
  BaseInput,
  BaseTable,
  Button,
  Content,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, isEqual, map } from "lodash";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Users } from "routers/paths";
import { MESSAGES, TABLE } from "utilities/constants";
import UsersServices from "services/apiServices/users";
import { toast } from "react-toastify";
import { logOut } from "app/slices/authSlices/authSlices";
import { profileData } from "app/states/profile";
import { indexNumber } from "utilities/helpers";

type NavigateProps = {
  id: string | number | null;
  roleId: string | number | null;
  polzovateliId: string | number | null;
  kassaList: Array<any> | [];
  territoriyaList: Array<any> | [];
};
const UserRoleContainer = () => {
  const profile = useAppSelector(profileData);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const filter = useAppSelector(userRoleFilter);
  const userRoleData = useAppSelector(userRoleList);
  const LOADING_TABLE = get(userRoleData, "loading", false);
  const isAdmin = get(profile, "profileData.id", []);

  const isAddForm = includes(
    get(location, "pathname", ""),
    Users.ADD_USER_ROLE
  );
  const handleAddButton = () => {
    navigate(Users.ADD_USER_ROLE);
  };
  const handleUpdateButton = (props: NavigateProps) => {
    navigate(Users.ADD_USER_ROLE, {
      state: props,
    });
  };

  const handleDeleteButton = (id: number | string | null) => {
    UsersServices.DeleteUserRole(id).then((res) => {
      if (res.status == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchUserRole(filter));
        if (isEqual(id, isAdmin)) {
          dispatch(logOut());
        }
      }
    });
  };

  const fetchUserRoleData = useCallback(() => {
    dispatch(fetchUserRole(filter));
  }, [dispatch, filter]);

  const handleClearSearch = useCallback(() => {
    dispatch(fetchUserRole({ ...filter, search: "" }));
  }, [filter]);
  useEffect(() => {
    fetchUserRoleData();
  }, [filter.page, filter.size]);
  useEffect(() => {
    return () => {
      dispatch(clearFilterUserPerm())
    }
  }, [])
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col className="text-right">
              <Button green handleClick={handleAddButton}>
                {TABLE.ADD}
              </Button>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={8}>
              <FlexBox gap={"10px"}>
                <BaseInput
                  placeholder="Поиск"
                  value={get(filter, "search", "")}
                  handleInput={(e: any) => {
                    dispatch(
                      filterChangeUserRole({
                        name: "search",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"}>
              <Button
                primary
                className={"mr-16"}
                handleClick={fetchUserRoleData}
              >
                {TABLE.APPLY}
              </Button>
              <Button danger handleClick={handleClearSearch}>
                {TABLE.RESET}
              </Button>
            </Col>
          </Row>
          {/* table */}
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Роль пользователя",
                    "Действие",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      {!isEmpty(get(userRoleData, "data", [])) ? (
                        get(userRoleData, "data", []).map(
                          (item, index: number) => (
                            <tr key={index + 1}>
                              <td>
                                {indexNumber({
                                  currentPage: get(
                                    userRoleData,
                                    "pagination.currentPageNumber",
                                    0
                                  ),
                                  pageSize: get(
                                    userRoleData,
                                    "pagination.pageSize",
                                    10
                                  ),
                                  index: index + 1,
                                })}
                              </td>
                              <td>{get(item, "imyaPolzovateli", "")}</td>
                              <td>{get(item, "roleName", "")}</td>
                              <td>
                                <ActionBase
                                  index={index}
                                  handleUpdate={handleUpdateButton}
                                  handleDelete={() =>
                                    handleDeleteButton(get(item, "id", null))
                                  }
                                  id={{
                                    id: get(item, "id", null),
                                    roleId: get(item, "roleId", ""),
                                    polzovateliId: get(
                                      item,
                                      "polzovateliId",
                                      null
                                    ),
                                    imyaPolzovateli: get(
                                      item,
                                      "imyaPolzovateli",
                                      null
                                    ),
                                    kassaList: get(item, "kassaList", []),
                                    territoriyaList: get(
                                      item,
                                      "territoriyaList",
                                      []
                                    ),
                                  }}
                                />
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={4} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={4}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(userRoleData, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(userRoleData, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      filterChangeUserRole({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(userRoleData, "pagination.currentPageNumber", 0)}
                  pageCount={get(userRoleData, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      filterChangeUserRole({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default UserRoleContainer;
