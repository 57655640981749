import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { ReactSVG } from "react-svg";
import { get, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "app/hook";
import { Col, Row } from "react-grid-system";
import { Main, Reports } from "routers/paths";
import { sideBarMenus } from "app/states/menu";
import menuuIcon from "assets/images/icons/menu.svg";
import closeMenu from "assets/images/icons/close_menu.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, FlexBox, Sidebar, SidebarItem } from "components";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchOwnTerritories } from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchTypeContract } from "app/slices/enumerationsSlices/typeContractSlices/typeContractSlices";
import { fetchPaymentMethod } from "app/slices/enumerationsSlices/paymentMethodSlices/paymentMethodSlices";
import { fetchDeliveryMethod } from "app/slices/enumerationsSlices/deliveryMethodSlices/deliveryMethodSlices";
import { fetchUnitsMeasurement } from "app/slices/handbookSlices/unitsMeasurementSlices/unitsMeasurementSlices";
import { fetchOwnKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { profileData } from "app/states/profile";
import { SidebarItemWrapper } from "components/SidebarItem/style";
import { Layout } from "antd";
import { MAIN_DEPARTMENT } from "routers/constants";
const ReportInnerLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const sideBar = useAppSelector((store) => sideBarMenus(store, Main.REPORTS));

  const pageListData = useAppSelector(profileData);

  const pageData = useMemo(
    () => get(pageListData, "profileData.permissionList", []),
    [pageListData]
  );

  const data = pageData.find(
    (item: any) => item.department === MAIN_DEPARTMENT.REPORTS
  );
  const permissionList = get(data, "pages", []).map((item: any) => item.page);
  // redirect to the first section of side bar menu
  const navigateToFirstSidebar = useCallback(() => {
    if (isEqual(get(location, "pathname", ""), `/${Main.REPORTS}`)) {
      const frstAllowedUrl = sideBar.find(
        (item: any) =>
          get(item, "page", false) === get(data, "pages[0].page", null)
      )?.id;
      if (frstAllowedUrl) {
        navigate(frstAllowedUrl, { replace: true });
      }
    }
  }, [location, navigate]);
  // sidebar
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;

  useEffect(() => {
    navigateToFirstSidebar();
  }, [navigateToFirstSidebar]);

  const handleFetchSelectorsOptions = useCallback(() => {
    // kontragents
    dispatch(fetchAllCounterpart({ all: true }));
    // usloviyaOplatas
    // sposobOplatas
    dispatch(fetchPaymentMethod({}));
    // tipDogovora
    dispatch(fetchTypeContract({}));
    // sposobDostavka
    dispatch(fetchDeliveryMethod({}));
    // edizimer
    dispatch(fetchUnitsMeasurement({}));
    // territory
    dispatch(fetchOwnTerritories({ all: true }));
    // tovar id
    dispatch(fetchProduct({ all: true }));
    // kassa
    dispatch(fetchOwnKassa({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    handleFetchSelectorsOptions();
  }, [handleFetchSelectorsOptions]);

  return (
    <Fragment>
      <Layout
        style={{
          padding: 0,
          fontFamily: "Arial, Helvetica, sans-serif",
          zIndex: 100,
          left: 0,
          width: "100%",
          position: "fixed",
        }}
      >
        <Row align="center">
          {!collapsed && (
            <Col xs={2.6} style={{ textAlign: "end", paddingRight: 0 }}>
              <ReactSVG
                src={closeMenu}
                className="mt-8 ml-8 cursor-pointer"
                onClick={() => setCollapsed(!collapsed)}
              />
            </Col>
          )}
          <Col xs={9.4}>
            <FlexBox>
              {collapsed && (
                <ReactSVG
                  src={menuuIcon}
                  className="svg_icon mr-16 ml-16"
                  onClick={() => setCollapsed(!collapsed)}
                />
              )}
              <Breadcrumb
                style={{ margin: "12px 0" }}
                permission={permissionList}
              />
            </FlexBox>
          </Col>
        </Row>
      </Layout>
      <Layout style={{ marginTop: "50px" }}>
        <Sider
          collapsible
          style={{
            background: "inherit",
            marginBottom: "10px",
          }}
          className="sidebar"
          trigger={null}
          width={"43vh"}
          collapsedWidth={0}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Sidebar
            className="component_animate"
            style={collapsed ? { display: "none" } : { display: "flex" }}
          >
            {sideBar
              .filter((item: any, index: any) => {
                if (permissionList.includes(item.page)) {
                  return item;
                }
              })
              .map((item: any) => (
                <SidebarItemWrapper key={item.id}>
                  <SidebarItem
                    key={item.id}
                    title={get(item, "name", "")}
                    url={get(item, "id", "")}
                    icon={get(item, "icon", "")}
                    stroke={get(item, "stroke", false)}
                    padding={"10px"}
                  />
                </SidebarItemWrapper>
              ))}
          </Sidebar>
        </Sider>
        <Content
          style={{
            padding: " 0",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Fragment>
  );
};

export default ReportInnerLayout;
