import { FlexBox, Title } from "components";
import { ReactSVG } from "react-svg";
import inbox from "assets/images/icons/inbox.svg";
import { Col, Row } from "react-grid-system";
const NotFound = () => {
  return (
    <Row justify="center" align="center">
      <Col xs={12} style={{ textAlign: "center", opacity: "0.6" }}>
        <ReactSVG
          src={inbox}
          style={{
            fontSize: "8vh",
            opacity: 0.5,
          }}
        />
        <Title mk>Не найдено</Title>
      </Col>
    </Row>
  );
};

export default NotFound;
