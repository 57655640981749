import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchRemainder } from "app/slices/documentSlices/arrivedCashesSlice/arrivedCashesSlice";
import {
  fetchAllCostsInCash,
  fetchAllCostsInCashStat,
} from "app/slices/documentSlices/costsInCashSlice/costsInCashSlice";
import { fetchPurchaseContracts } from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import { fetchSalesContracts } from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import { fetchTypeFlow } from "app/slices/enumerationsSlices/typeFlowSlices/typeFlowSlices";
import {
  clearContractorsData,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchExpanseItem } from "app/slices/handbookSlices/expenseItemSlices/expanseItemSlices";
import { fetchOwnKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import { costsInCash, costsInCashFilter } from "app/states/documents";
import {
  contractorOptions,
  getOnlyContractorIdOptions,
  loadingConterpart,
  optionsStatyaZatrata,
  purchaseContractOptionsById,
} from "app/states/documents/selectStates";
import { tipRasxodaOptions } from "app/states/enumerations/selectStates";
import {
  getDriversData,
  getKassaData,
  getTerritoriesData,
} from "app/states/handbooks/selectStates";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual, isObject } from "lodash";
import { useKassaSystem } from "modules/kassa/connacts";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { toast } from "react-toastify";
import DocumentServices from "services/apiServices/documents";
import { NumberDocType } from "types/documentsType";
import { CONTEXT, MESSAGES } from "utilities/constants";
enum REKVIZET_NAMES {
  VODITEL = "VODITEL",
  KONTR_AGENT = "KONTR_AGENT",
  DOGOVOR = "DOGOVOR",
  DOG_PRODAJ = "DOG_PRODAJ",
  TERRITORYA = "TERRITORYA",
  STATYA_RASXODA = "STATYA_RASXODA",
}
//  this file copyed file addCostCashContainer data
const useConnect = () => {
  const { hideAll } = useContextMenu();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<{
    id?: number | string | null;
    isEdit?: boolean;
  }>({ id: null, isEdit: false });
  const kassaOptions = useAppSelector(getKassaData);
  const typeRasxodaOptions = useAppSelector(tipRasxodaOptions);
  const kontragentOptions = useAppSelector(contractorOptions);
  const territory = useAppSelector(getTerritoriesData);
  const optionsStatyaZatrat = useAppSelector(optionsStatyaZatrata);
  // loading
  const allCostsInCash = useAppSelector(costsInCash);
  let LOADING = get(allCostsInCash, "loading", false);
  let LOADING_KONTRAGENT = useAppSelector(loadingConterpart);
  const { setIsAdding } = useKassaSystem();
  const getOrderDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.CostInCashNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
    });
  }, []);
  const onSubmit = (values: any) => {
    const params: any = {};
    forEach(values, (value, key) => {
      if (value) {
        if (key == "dataDoc" || !isObject(value)) {
          params[key] = value;
        } else {
          params[key] = get(value, "value", "");
        }
      }
    });
    try {
      DocumentServices.AddCostInCash({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...params,
          nomerDogProdaja: get(values, "dogProdajaId.label", null),
        },
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          dispatch(fetchAllCostsInCash(filter));
          dispatch(fetchAllCostsInCashStat());
          toast.success(MESSAGES.ADDED);
          setMaxAmount(null);
          getOrderDocNumber({});
          dispatch(fetchRemainder());
          resetForm();
          setIsAdding(false);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const initialValues = {
    nomerDoc: "",
    dataDoc: moment(new Date()).format("YYYY-MM-DD"),
    kassaId: "",
    tipRasxodaId: "",
    kontragentId: "",
    dogPokupkaId: "",
    dogProdajaId: "",
    voditeliId: "",
    territoriyaId: "",
    statyaRasxodaId: "",
    summa: "",
    summaInUZS: "",
    summaInUSD: "",
    kommentariya: "",
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const purchaseContractsOptions = useAppSelector((store) =>
    purchaseContractOptionsById(store, get(values, "kontragentId", ""))
  );
  const getSaleContracts = useAppSelector((store) =>
    getOnlyContractorIdOptions(store, get(values, "kontragentId.value", null))
  );
  const filter = useAppSelector(costsInCashFilter);
  const drivers = useAppSelector(getDriversData);
  const [maxAmount, setMaxAmount] = useState<any>(null);
  const handleUpdateDocument = useCallback(() => {
    setSubmitting(true);
    const params: any = {};
    forEach(values, (value, key) => {
      if (value) {
        if (key == "dataDoc" || !isObject(value)) {
          params[key] = value;
        } else {
          params[key] = get(value, "value", "");
        }
      }
    });
    try {
      DocumentServices.UpdateCostInCash({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...params,
          id: get(state, "id", null),
          kommentariya: get(values, "kommentariya", null),
        },
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          dispatch(fetchAllCostsInCash(filter));
          toast.success(MESSAGES.ADDED);
          resetForm();
          setSubmitting(false);
          dispatch(fetchRemainder());
          setState({ isEdit: false, id: null });
          setMaxAmount(null);
        }
      });
    } catch (err) {
      console.log("err", err);
      setSubmitting(false);
    }
  }, [dispatch, values]);

  const handleSetInitialValues = (data: any) => {
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kassaId", {
      value: get(data, "kassaId", ""),
      label: get(data, "imyaKassa", ""),
    });
    setFieldValue("tipRasxodaId", {
      value: get(data, "tipRasxodaId", 0),
      label: get(data, "imyaTipRasxoda", ""),
    });
    setFieldValue("kontragentId", {
      value: get(data, "kontragentId", ""),
      label: get(data, "imyaKontragent", ""),
    });
    setFieldValue("dogPokupkaId", {
      value: get(data, "dogPokupkaId", ""),
      label: get(data, "nomerDogPokupka", ""),
    });
    setFieldValue("voditeliId", {
      value: get(data, "voditeliId", ""),
      label: get(data, "imyaVoditeli", ""),
    });
    setFieldValue("territoriyaId", {
      value: get(data, "territoriyaId", ""),
      label: get(data, "imyaTerritoriya", ""),
    });
    setFieldValue("dogProdajaId", {
      value: get(data, "dogProdajaId", ""),
      label: get(data, "nomerDogProdaja", ""),
    });
    setFieldValue("statyaRasxodaId", {
      value: get(data, "statyaRasxodaId", ""),
      label: get(data, "imyaStatyaRasxoda", ""),
    });
    setFieldValue("summa", get(data, "summa", ""));
    setFieldValue("summaInUZS", get(data, "summaInUZS", ""));
    setFieldValue("summaInUSD", get(data, "summaInUSD", ""));

    setFieldValue("kommentariya", get(data, "kommentariya", ""));
  };

  const fetchFormSelectorOptions = useCallback(() => {
    dispatch(fetchOwnKassa({}));
    dispatch(fetchTypeFlow({}));
  }, []);

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "id", false)) {
        DocumentServices.FetchCostInCashById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              fetchFormSelectorOptions();
            }
          }
        );
      } else {
        DocumentServices.FetchCostInCashById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
            }
          }
        );
      }
    } else {
      getOrderDocNumber({});
      fetchFormSelectorOptions();
    }
  }, [state]);

  const fetchOrders = useCallback(() => {
    if (
      get(values, "tipRasxodaId.value", "") ||
      get(state, "isUpdate", false)
    ) {
      switch (get(values, "tipRasxodaId.value", "").toString()) {
        case "1":
          dispatch(clearContractorsData());
          dispatch(fetchAllCounterpart({ all: true, type: 2 }));
          break;
        case "2":
          dispatch(clearContractorsData());
          dispatch(fetchAllCounterpart({ all: true, type: 1 }));
          break;
        case "3":
          dispatch(fetchDrivers({ all: true, filterPage: "RASXOD_D" }));
          break;
        case "4":
          dispatch(clearContractorsData());
          dispatch(fetchAllCounterpart({ all: true, type: 4 }));
          break;
        case "5":
          dispatch(fetchExpanseItem({ all: true }));
          break;
        case "6":
          dispatch(clearContractorsData());
          dispatch(fetchAllCounterpart({ all: true, type: 3 }));
          break;
        default:
          break;
      }
    }
  }, [values.tipRasxodaId]);
  // this case on  tip rasxod and prixod fetching dogovors
  const fetchDogovors = useCallback(() => {
    if (
      String(get(values, "tipRasxodaId.value", "")) === "6" &&
      get(values, "kontragentId.value", null)
    ) {
      dispatch(
        fetchSalesContracts({
          all: true,
          filterPage: "RASXOD_D",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );
    }
    if (
      String(get(values, "tipRasxodaId.value", "")) === "4" &&
      get(values, "kontragentId.value", null)
    ) {
      dispatch(
        fetchPurchaseContracts({
          all: true,
          filter: "RASXOD_D",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );
    }
  }, [values.kontragentId, values.tipRasxodaId]);
  useEffect(() => {
    fetchDogovors();
  }, [fetchDogovors]);
  const checkIsAvailable = ({ tipRasxodaId, rekvizetName }: any) => {
    switch (rekvizetName) {
      case REKVIZET_NAMES.VODITEL:
        if (tipRasxodaId == 3) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.KONTR_AGENT:
        if (
          tipRasxodaId == 1 ||
          tipRasxodaId == 2 ||
          tipRasxodaId == 4 ||
          tipRasxodaId == 6
        ) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.DOGOVOR:
        if (tipRasxodaId == 4) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.TERRITORYA:
        if (tipRasxodaId == 5) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.STATYA_RASXODA:
        if (tipRasxodaId == 5) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.DOG_PRODAJ:
        if (tipRasxodaId == 6) {
          return true;
        }
        return false;
    }
  };

  const fetchValidationOfAmount = useCallback(() => {
    try {
      if (
        get(values, "kassaId.value", null) &&
        !isEqual(get(values, "tipRasxodaId.value", null), 3) &&
        get(values, "tipRasxodaId.value", null) &&
        get(values, "kontragentId.value", null)
      ) {
        DocumentServices.FetchMaxAmountOfCostInCash({
          kassaId: get(values, "kassaId.value", null),
          tipRasxodaId: get(values, "tipRasxodaId.value", null),
          dogPokupkaId: get(values, "dogPokupkaId.value", null),
          kontragentId: get(values, "kontragentId.value", null),
          dogProdajaId: get(values, "dogProdajaId.value", null),
          rkoId: get(state, "id", null),
        })
          .then(({ data }) => {
            setMaxAmount(data.data);
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    values.tipRasxodaId,
    values.dogPokupkaId,
    values.kontragentId,
    values.kassaId,
    values.dogProdajaId,
  ]);
  const getPriceWidthDrivers = useCallback(() => {
    if (
      isEqual(get(values, "tipRasxodaId.value", false), 3) &&
      get(values, "voditeliId.value", false)
    ) {
      const params = {
        voditeliId: get(values, "voditeliId.value", null),
        kassaId: get(values, "kassaId.value", null),
        tipRasxodaId: get(values, "tipRasxodaId.value", null),
        rkoId: get(state, "id", null),
      };
      DocumentServices.FetchMaxAmountOfCostInCash(params)
        .then(({ data }) => {
          if (data) setMaxAmount(data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [values.tipRasxodaId, values.voditeliId, state]);

  useEffect(() => {
    getPriceWidthDrivers();
  }, [getPriceWidthDrivers]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    fetchValidationOfAmount();
  }, [fetchValidationOfAmount]);

  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeleteCostInCashData(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchAllCostsInCash(filter));
        dispatch(fetchAllCostsInCashStat());
        dispatch(fetchRemainder());
      }
    });
  };
  // status
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRedordsCostInCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchRemainder());
        resetForm();
        setMaxAmount(null);
        dispatch(fetchAllCostsInCash(filter));
        getOrderDocNumber({});
      }
    });
  };
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRedordsCostInCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchRemainder());
        resetForm();
        setMaxAmount(null);
        dispatch(fetchAllCostsInCash(filter));
        getOrderDocNumber({});
      }
    });
  };
  return {
    values,
    allCostsInCash,
    maxAmount,
    drivers,
    kassaOptions,
    typeRasxodaOptions,
    kontragentOptions,
    optionsStatyaZatrat,
    // handle
    handleDeleteDocument,
    handleUndoRecords,
    handleDeleteRecords,
    getOrderDocNumber,
    // sub
    isSubmitting,
    handleUpdateDocument,
    resetForm,
    handleSubmit,
    setFieldValue,
    checkIsAvailable,
    setMaxAmount,
    purchaseContractsOptions,
    getSaleContracts,
    territory,
    LOADING,
    state,
    LOADING_KONTRAGENT,
    setState,
  };
};

export default useConnect;
