import { object, number, mixed, string } from "yup"

const kol = number().min(0).required();
const sena = number().min(0).required();
const amountSchema = number().min(0).required();
const mixedSchema = mixed().required("required");
const strSchema = string().required("required");
const selectSchema = object().shape({
    label: mixed().required(),
    value: mixed().required()
})
export { kol, sena, selectSchema, amountSchema, mixedSchema, strSchema }
