import FlexBox from "components/FlexBox/FlexBox";
import Text from "components/Text/Text";
import { get, memoize } from "lodash";
import { NumericFormat } from "react-number-format";
import { Props } from "./type";
const ShowStatistics = ({ statistic }: Props) => {
  return (
    <FlexBox gap={"16px"} align="center">
      <FlexBox align="center" style={{ textAlign: "center" }}>
        <Text gray norm className={"mr-16 truncate "}>
          Всего документов:
        </Text>
        <Text gray small className={"mr-16 "}>
          <NumericFormat
            displayType="text"
            value={get(statistic, "data.all", 0)}
            thousandSeparator
          />
        </Text>
      </FlexBox>
      <FlexBox align="center">
        <Text gray small className={"mr-16 truncate"}>
          Последние 24 часа:
        </Text>
        <Text gray small>
          <NumericFormat
            displayType="text"
            value={get(statistic, "data.last24Hours", 0)}
            thousandSeparator
          />
        </Text>
      </FlexBox>
    </FlexBox>
  );
};
export default memoize(ShowStatistics);
