import { createSelector } from "@reduxjs/toolkit";
import {
  selectOrderRegisterList,
  selectPurchaseContractList,
  selectReleaseProductOptions,
  selectSaleContractList,
  selectSaleDetailsProduct,
  selectSalesProductsList,
  selectShipmentProductList,
  selectSubProductData,
  selectSubTerritory,
} from "app/selectors/documents";
import {
  selectDeliveryMethod,
  selectPaymentMethod,
  selectTypeContract,
} from "app/selectors/enumerations/enumerationsSelectors";
import {
  selectCounterpart,
  selectExpanseItem,
  selectProduct,
  selectTermsPayment,
  selectUnitsMeasurement,
} from "app/selectors/handbooks/handbookSelectors";
import {
  OrderRegisterData,
  ZakazNaryadDetails,
} from "app/slices/documentSlices/orderRegisterSlice/type";
import { DeliveryMethodDataType } from "app/slices/enumerationsSlices/deliveryMethodSlices/type";
import { PaymentMethodDataType } from "app/slices/enumerationsSlices/paymentMethodSlices/type";
import { ContractDataType } from "app/slices/enumerationsSlices/typeContractSlices/type";
import { counterpartsDataType } from "app/slices/handbookSlices/counterpartSlices/type";
import { ProductDataType } from "app/slices/handbookSlices/productSlices/type";
import { UnitsMeasurementType } from "app/slices/handbookSlices/unitsMeasurementSlices/type";
import { get, isEmpty } from "lodash";

export let loadingConterpart = createSelector(
  selectCounterpart,
  (field) => field.loading
);
export const contractorOptions = createSelector(selectCounterpart, (dataList) =>
  get(dataList, "dataList", []).map((item: counterpartsDataType) => {
    return {
      label: get(item, "imyaKontragent", ""),
      value: get(item, "id", 0),
    };
  })
);
export const loadingDogovar = createSelector(
  selectSaleContractList,
  (data) => data.loading
);
export const getOnlyContractorIdOptions = createSelector(
  [selectSaleContractList, (store, id) => id],
  (data, id: string) => {
    if (id) {
      return get(data, "data", []).map((item: any) => ({
        label: get(item, "nomerDoc", ""),
        value: get(item, "id", null),
        dateIsp: get(item, "dataIsp", ""),
        kontragentId: get(item, "kontragentId", null),
        sposobDostavkaId: get(item, "sposobDostavkaId", ""),
        sposobDostavkaName: get(item, "sposobDostavkaName", ""),
        otmVipolneniya: get(item, "otmVipolneniya", 0),
        tipDogovoraId: get(item, "tipDogovoraId", null),
        stavkaNDSId: get(item, "stavkaNDSId", null),
        nomerZakazNaryad: get(item, "nomerZakazNaryad", ""),
        zakazNaryadId: get(item, "zakazNaryadId", null),
        skladGPId: {
          label: get(item, "skladGPId", null),
          value: get(item, "imyaSkladaGP", ""),
        },
      }));
    } else {
      return [];
    }
  }
);

export const orderRegisterOptions = createSelector(
  selectOrderRegisterList,
  (data) =>
    get(data, "data", []).map((item) => ({
      label: get(item, "nomerDoc", ""),
      value: get(item, "id", null),
      kontragentId: get(item, "kontragentId"),
      dogProdajaId: get(item, "dogProdajaId", null),
      imyaDogProdaja: get(item, "dogProdajaNomer", ""),
      territory: get(item, "territoriyaId", null),
      imyaTerritory: get(item, "imyaTerritoriya", ""),
      sposobDostavkaId: get(item, "sposobDostavkaId", null),
      addresaDostavkiId: get(item, "addresaDostavkiId", null),
      imyaAddresaDostavki: get(item, "imyaAddresaDostavki", ""),
      sposobOplataId: get(item, "sposobOplataId", null),
      imyaSposobOplata: get(item, "sposobOplataName", null),
      zakazNaryadDetails: get(item, "zakazNaryadTovars", []),
      skladGPId: get(item, "skladGPId", null),
      imyaSkladaGP: get(item, "imyaSkladaGP", null),
      imyaSklada: get(item, "imyaSklada", null),
      skladId: get(item, "skladId", null),
      tipDogovoraId: get(item, "tipDogovoraId", null),
      dogProdajaDataDog: get(item, "dogProdajaDataDog", null),
    }))
);
export let loadingOrderData = createSelector(
  selectOrderRegisterList,
  (data) => data.loading
);
export const orderRegisterOptionsById = createSelector(
  [selectOrderRegisterList, (store, id) => id],
  (data, id) => {
    if (!isEmpty(id)) {
      return get(data, "data", []).map((item) => ({
        label: get(item, "nomerDoc", ""),
        value: get(item, "id", null),
        kontragentId: get(item, "kontragentId"),
        dogProdajaId: get(item, "dogProdajaId", null),
        imyaDogProdaja: get(item, "dogProdajaNomer", ""),
        territory: get(item, "territoriyaId", null),
        imyaTerritory: get(item, "imyaTerritoriya", ""),
        sposobDostavkaId: get(item, "sposobDostavkaId", null),
        addresaDostavkiId: get(item, "addresaDostavkiId", null),
        sposobOplataId: get(item, "sposobOplataId", null),
        imyaSposobOplata: get(item, "sposobOplataName", null),
        zakazNaryadDetails: get(item, "zakazNaryadTovars", []),
        imyaSkladaGP: get(item, "imyaSkladaGP", ""),
        skladGPId: get(item, "skladGPId", ""),
      }));
    } else {
      return [];
    }
  }
);

export const getProductToRegisterOptions = createSelector(
  [selectOrderRegisterList, (store, id) => id],
  (data, id: string) => {
    if (id) {
      const register = get(data, "data", []).find(
        (item: OrderRegisterData) => item.id == id
      );
      return get(register, "zakazNaryadTovars", []).map(
        (item: ZakazNaryadDetails) => ({
          label: get(item, "imyaTovara", ""),
          value: get(item, "tovarId", null),
          edIzmId: get(item, "edIzmId", ""),
        })
      );
    } else {
      return [];
    }
  }
);

export const paymentMethodsOptions = createSelector(
  selectPaymentMethod,
  (data) =>
    get(data, "data", []).map((item: PaymentMethodDataType) => {
      return {
        label: get(item, "sposobOplataName", ""),
        value: get(item, "id", 0),
      };
    })
);

export const deliveryMethodsOptions = createSelector(
  selectDeliveryMethod,
  (data) =>
    get(data, "dataList", []).map((item: DeliveryMethodDataType) => {
      return {
        label: get(item, "sposobDostavkaName", ""),
        value: get(item, "id", 0),
      };
    })
);

export const typeContractsOptions = createSelector(selectTypeContract, (data) =>
  get(data, "dataList", []).map((item: ContractDataType) => {
    return {
      label: get(item, "tipDogovora", ""),
      value: get(item, "id", 0),
    };
  })
);
export let loadingProductOptions = createSelector(
  selectProduct,
  (data) => data.loading
);
export const productOptions = createSelector(selectProduct, (data) =>
  get(data, "productData", []).map((item: ProductDataType) => {
    return {
      label: get(item, "polnoeImya", ""),
      value: get(item, "id", 0),
      edIzmId: get(item, "edIzmId", ""),
      imyaEdIzm: get(item, "imyaEdIzm", ""),
      id: get(item, "id", ""),
    };
  })
);

export const unitsMeasurementOptions = createSelector(
  selectUnitsMeasurement,
  (unitsMeasurementData) =>
    get(unitsMeasurementData, "unitsMeasurementData", []).map(
      (item: UnitsMeasurementType) => {
        return {
          label: get(item, "imyaEdIzm", ""),
          value: get(item, "id", 0),
        };
      }
    )
);

export const termsOfPaymentOptions = createSelector(
  selectTermsPayment,
  (data) =>
    get(data, "dataList", []).map((item: PaymentMethodDataType) => {
      return {
        label: get(item, "imyaUsloviyaOplati", ""),
        value: get(item, "id", 0),
      };
    })
);

export const getSaleContractsSubjectData = createSelector(
  selectSaleContractList,
  (contractSbjs) =>
    get(contractSbjs, "data", []).map((item: any) => {
      return {
        label: get(item, "nomerDoc", ""),
        value: get(item, "id", null),
        dateIsp: get(item, "dataIsp", ""),
        kontragentId: get(item, "kontragentId", null),
        sposobDostavkaId: get(item, "sposobDostavkaId", ""),
        sposobDostavkaName: get(item, "sposobDostavkaName", ""),
        otmVipolneniya: get(item, "otmVipolneniya", 0),
        tipDogovoraId: get(item, "tipDogovoraId", null),
        stavkaNDSId: get(item, "stavkaNDSId", null),
        nomerZakazNaryad: get(item, "nomerZakazNaryad", ""),
        zakazNaryadId: get(item, "zakazNaryadId", null),
        skladGPId: {
          label: get(item, "skladGPId", null),
          value: get(item, "imyaSkladaGP", ""),
        },
      };
    })
);
export const loadingRegisterPruchase = createSelector(
  selectPurchaseContractList,
  (data) => get(data, "loading", false)
);
export const getPurchaseContractsSubjectData = createSelector(
  selectPurchaseContractList,
  (purchaseCnts) =>
    get(purchaseCnts, "data", []).map((item) => {
      return {
        label: get(item, "nomerDoc", ""),
        value: get(item, "id", null),
      };
    })
);

export const purchaseContractOptionsById = createSelector(
  [selectPurchaseContractList, (store, id) => id],
  (purchaseCnts, id) => {
    if (id) {
      return get(purchaseCnts, "data", []).map((item) => ({
        label: get(item, "nomerDoc", ""),
        value: get(item, "id", null),
      }));
    } else {
      return [];
    }
  }
);
export const loadingSalesDetails = createSelector(
  selectSaleDetailsProduct,
  (data) => get(data, "loading", false)
);
//  get sort tovar
export const getSortDetailsProduct = createSelector(
  [selectSaleDetailsProduct, selectProduct, (store, id) => id],
  (detailsProduct, productList, id) => {
    if (detailsProduct && productList && id) {
      switch (id) {
        case 1:
          const details = get(productList, "productData", []).map(
            (item: any) => ({
              label: get(item, "polnoeImya", ""),
              value: get(item, "id", null),
              edIzmId: get(item, "edIzmId", null),
              kol: get(item, "kol", null),
              imyaEdIzm: get(item, "imyaEdIzm", null),
            })
          );
          return details;
        case 2:
          const product = get(detailsProduct, "data", []).map((item: any) => ({
            label: get(item, "imyaTovara", ""),
            value: get(item, "tovarId", ""),
            edIzmId: get(item, "edIzmId", null),
            imyaEdIzm: get(item, "imyaEdIzm", null),
            kol: get(item, "kol", null),
            remainingKolichestva: get(item, "remainingKolichestva", ""),
          }));
          return product;
        default:
          return [];
      }
    }
  }
);
//  fetch sales product options

// statya zatrata
export const optionsStatyaZatrata = createSelector(
  selectExpanseItem,
  (data) => {
    return {
      list: get(data, "expanseItemData", []).map((item) => ({
        value: get(item, "id", ""),
        label: get(item, "imyaStRasxoda", ""),
      })),
      loading: get(data, "loading", false),
    };
  }
);
export let loadingSalesProductData = createSelector(
  selectSalesProductsList,
  (data) => get(data, "loading", false)
);
export const getSaleProductDataOptions = createSelector(
  selectSalesProductsList,
  (salesProduct) =>
    get(salesProduct, "productList", []).map((item: any) => {
      return {
        label: get(item, "nomerDoc", ""),
        value: get(item, "id", null),
        dateIsp: get(item, "dataIsp", ""),
        kontragentId: get(item, "kontragentId", null),
        dogProdajaId: {
          value: get(item, "dogProdajaId", ""),
          label: get(item, "nomerDogProdaja", ""),
        },
        tipDogovoraId: get(item, "tipDogovoraId", null),
        stavkaNDSId: {
          value: get(item, "stavkaNDSId", null),
          label: get(item, "stavkaNDSImya", ""),
        },
        zakazNaryadId: {
          value: get(item, "zakazNaryadId", null),
          label: get(item, "nomerZakazNaryad", ""),
        },
        sposobOplatiId: {
          value: get(item, "sposobOplatiId", null),
          label: get(item, "imyaSposobOplati", ""),
        },
        territoriyaId: {
          value: get(item, "territoriyaId", null),
          label: get(item, "imyaTerritoriya", ""),
        },
        skladGPId: {
          value: get(item, "skladGPId", null),
          label: get(item, "imyaSkladaGP", null),
        },
      };
    })
);
export const loadingShipmentData = createSelector(
  selectShipmentProductList,
  (data) => get(data, "loading", false)
);
export const getShipmentDataOptions = createSelector(
  selectShipmentProductList,
  (shipmentProduct) =>
    get(shipmentProduct, "productList", []).map((item: any) => {
      return {
        label: get(item, "nomerDoc", ""),
        value: get(item, "id", null),
        dateIsp: get(item, "dataIsp", ""),
        kontragentId: get(item, "kontragentId", null),
        dogProdajaId: {
          value: get(item, "dogProdajaId", ""),
          label: get(item, "nomerDogProdaja", ""),
        },
        tipDogovoraId: get(item, "tipDogovoraId", null),
        stavkaNDSId: {
          value: get(item, "stavkaNDSId", null),
          label: get(item, "stavkaNDSImya", ""),
        },
        zakazNaryadId: {
          value: get(item, "zakazNaryadId", null),
          label: get(item, "nomerZakazNaryad", ""),
        },
        sposobOplatiId: {
          value: get(item, "sposobOplatiId", null),
          label: get(item, "imyaSposobOplati", ""),
        },
        territoriyaId: {
          value: get(item, "territoriyaId", null),
          label: get(item, "imyaTerritorii", ""),
        },
        skladGPId: {
          value: get(item, "skladGPId", null),
          label: get(item, "imyaSklada", null),
        },
      };
    })
);

//  sub territory details
export const subTerritoryDetails = createSelector(
  [selectSubTerritory],
  (data) => {
    return {
      list: get(data, "data", []).map((item: any) => ({
        label: get(item, "territoriya", ""),
        value: get(item, "territoriyaId", null),
      })),
      loading: get(data, "loading", false),
    };
  }
);
export let loadingProeductSub = createSelector(
  [selectSubProductData],
  (data) => data.loading
);
//  sub product details
export const subProductDetails = createSelector(
  [selectSubProductData],
  (data) => {
    return get(data, "data", []).map((item: any) => ({
      value: get(item, "tovarId", null),
      label: get(item, "tovar", ""),
      maxKol: get(item, "kolichestvo", null),
      sebestTovar: get(item, "sebestoimost", null),
      territory: get(item, "territory", null),
    }));
  }
);
// release opitns prodcut
export const releseProductLoad = createSelector(
  selectReleaseProductOptions,
  (data) => data.loading
);
export const optionsReleseProduct = createSelector(
  selectReleaseProductOptions,
  (data) =>
    !isEmpty(get(data, "data", []))
      ? get(data, "data", []).map((item: any) => ({
          value: get(item, "tovarId", null),
          label: get(item, "imyaTovara", null),
          maxKol: get(item, "maxKol", 0),
          edIzmId: get(item, "edIzmId", null),
          imyaEdizm: get(item, "imyaEdIzm", ""),
        }))
      : []
);
