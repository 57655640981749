import {
  BaseInput,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Text,
  Title,
  Form,
  BaseTable,
  TextArea,
  FormButton,
  LoadingAddPages,
} from "components";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { Col, Row } from "react-grid-system";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hook";
import { offsetAdvanceFilter } from "app/states/documents";
import DocumentServices from "services/apiServices/documents";
import { toast } from "react-toastify";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import {
  fetchAllCloseMonth,
  fetchCloseMonthStat,
} from "app/slices/documentSlices/closeMonthSlices/closeMonthSlices";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import { NumberDocType } from "types/documentsType";
type CloseMonth = {
  numberDoc: string;
  dataDoc: string;
};
// Закрытие месяца −> Добавить
const AddCloseMonthContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const filter = useAppSelector(offsetAdvanceFilter);
  const [LOADING, setLoading] = useState(false);
  const [loadingFull, setLoadingFull] = useState(false);
  const [disable, setDisable] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const onSubmit = (values: CloseMonth) => {
    try {
      DocumentServices.AddCloseMonth({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: values,
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllCloseMonth(filter));
          dispatch(fetchCloseMonthStat());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
      setSubmitting(false);
    }
  };
  let initialValues: any = {
    nomerDoc: "",
    dataDoc: moment(new Date()).endOf("month").format("YYYY-MM-DD"),
    kommentariya: "",
  };
  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const handleFetchDetails = useCallback(() => {
    setLoadingFull(true);
    DocumentServices.FetchCloseMonthDetails(get(values, "dataDoc", null))
      .then(({ data }) => {
        setLoadingFull(false);
        setDetails(data.data);
      })
      .catch((err) => {
        setLoadingFull(false);
        console.log(err);
      });
  }, [values.dataDoc]);

  const handleSetInitialValues = (data: any) => {
    setDetails(get(data, "details", []));
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
  };
  const getDocNumber = useCallback((date: NumberDocType) => {
    try {
      DocumentServices.GetCloseMonthNumber(date)
        .then(({ data }) => {
          setFieldValue("nomerDoc", get(data, "data", ""));
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
    try {
      DocumentServices.FetchCloseMonthDate()
        .then(({ data }) => {
          setFieldValue("dataDoc", get(data, "data", ""));
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      setLoading(true);
      DocumentServices.FetchCloseMonthById(get(state, "id", ""))
        .then((res) => {
          setLoading(false);
          if (get(res, "status", "") == 200) {
            let respond = get(res, "data.data", {});
            handleSetInitialValues(respond);
          }
        })
        .catch((err) => console.log(err));
      setDisable(true);
    } else {
      getDocNumber({});
    }
  }, [dispatch, state]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  return (
    <Content className={"min_height"} style={{ position: "relative" }}>
      {!LOADING ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  {PAGE.REQ}
                </Title>
                <Button
                  green
                  handleClick={handleFetchDetails}
                  loading={loadingFull}
                  disabled={disable}
                >
                  {PAGE.FILL}
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", "")}
                          disabled={true}
                          dateFormat="dd.MM.yyyy"
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                disabled={disable}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>

          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  {PAGE.TABLE_PART} <span className="required_point">*</span>
                </Title>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={["№", "Дебет счет", "Кредит счет", "Сумма"]}
                >
                  {!isEmpty(details) ? (
                    details.map((item: any, index: number) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{get(item, "debitSchet", "")}</td>
                        <td>{get(item, "kreditSchet", 0)}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summa", 0)}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>{PAGE.NO_DATA}</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                            Печать
                        </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.ZAKR_MES}_CREATE`,
                    page: DOCUMENT_KEY.ZAKR_MES,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddCloseMonthContainer;
