import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterTerritories,
  fetchTerritories,
  handleTerritoriesFilter,
} from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import { TerritoriesDataType } from "app/slices/handbookSlices/territoriesSlices/type";
import {
  fetchSkladCM,
  fetchSkladGP,
  fetchWarHouse,
} from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { allTerritoriesData, allTerritoriesFilter } from "app/states/handbooks";
import {
  getSkladCM,
  getSkladGP,
  getWarHouseData,
} from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  FormSelect,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { territorySchema } from "utilities/schema/spravochnikSchemas";
// Территория
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: TerritoriesDataType;
}) => {
  const dispatch = useAppDispatch();
  const getSkladGPdata = useAppSelector(getSkladGP);
  const getSkladCMdata = useAppSelector(getSkladCM);
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateTerritories({
          data: {
            ...values,
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewTerritories({
          data: values,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
      resetForm();
    } catch (err) {
      console.log("Error", err);
    }
  };
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imyaTerritorii: get(details, "imyaTerritorii", ""),
      adresTerritorii: get(details, "adresTerritorii", ""),
      skladSMId: get(details, "skladSMId", null),
      skladGPId: get(details, "skladGPId", null),
    },
    onSubmit,
    validationSchema: territorySchema,
  });
  useEffect(() => {
    dispatch(fetchSkladGP({ territoriya: true }));
    dispatch(fetchSkladCM({ territoriya: true }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Input
            label={"Наименование Территория"}
            value={get(values, "imyaTerritorii", "")}
            handleInput={(e: string) => {
              setFieldValue("imyaTerritorii", e);
            }}
            required={true}
          />
          <Input
            label={"Адрес Территория"}
            value={get(values, "adresTerritorii", "")}
            handleInput={(e: string) => {
              setFieldValue("adresTerritorii", e);
            }}
            required={true}
          />
          <FormSelect
            className="mt-24"
            id={"skladSMId"}
            label={"Склад привязанный к цеху"}
            isSearchable
            value={get(values, "skladSMId", "")}
            options={getSkladCMdata}
            defaultValue={{
              label: get(details, "imyaSkladSM", ""),
              value: get(values, "skladSMId", ""),
            }}
            handleChange={(e: any) => {
              setFieldValue("skladSMId", e);
            }}
            required={true}
          />

          <FormSelect
            id={"skladGPId"}
            className="mt-24"
            defaultValue={{
              label: get(details, "imyaSkladGP", ""),
              value: get(values, "skladGPId", ""),
            }}
            label={"Склад ГП привязанный к цеху"}
            isSearchable
            value={get(values, "skladGPId", null)}
            options={getSkladGPdata}
            handleChange={(e: any) => {
              setFieldValue("skladGPId", e);
            }}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const TerritoriesContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<TerritoriesDataType | any>({});
  const data = useAppSelector(allTerritoriesData);
  const filter = useAppSelector(allTerritoriesFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleTerritoriesFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchTerritories({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  const clearFilter = useCallback(() => {
    dispatch(fetchTerritories({ ...filter, search: "", InAscOrder: false }));
    dispatch(clearFilterTerritories());
  }, [dispatch, filter]);

  const fetchAllTerritoriesData = useCallback(() => {
    dispatch(fetchTerritories({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: TerritoriesDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  useEffect(() => {
    return () => {
      clearFilter();
    };
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteTerritories(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllTerritoriesData();
      }
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "TERRITORIYA_CREATE",
                    page: "TERRITORIYA",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllTerritoriesData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименование Территория",
                    "Адрес Территория",
                    "Склад материлов",
                    "Склад ГП",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "territoriesData", [])) ? (
                        get(data, "territoriesData", []).map(
                          (item: TerritoriesDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"TERRITORIYA"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imyaTerritorii", "-")}</td>
                              <td>{get(item, "adresTerritorii", "-")}</td>
                              <td>{get(item, "imyaSkladSM", "-")}</td>
                              <td>{get(item, "imyaSkladGP", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={7} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={7}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default TerritoriesContainer;
