import request from "services/api";

class ReportsServices {
  static FetchRegisterSalesTable = (params: any) => {
    return request.get("/api/otchet/dog-prodaja", { params: params });
  };
  static FetchMaterialReportTable = (params: any) => {
    return request.get("/api/otchet/material", { params: params });
  };
  static FetchOperationalReportTable = (params: any) => {
    return request.get("/api/otchet/reestr-ostatok-material", {
      params: params,
    });
  };
  static FetchProcurementPlanTable = (params: any) => {
    return request.get("/api/otchet/plan-zakupok", { params: params });
  };
  static FetchReportReleasesTable = (params: any) => {
    return request.get("/api/otchet/vipusk-gp", { params: params });
  };
  static FetchAnalysisExecutionTable = (params: any) => {
    return request.get("/api/otchet/zakaz-naryad-analiz", { params: params });
  };
  static FetchCommodityReportTable = (params: any) => {
    return request.get("/api/otchet/tovara", { params: params });
  };
  static FetchBalanceSheetTable = (params: any) => {
    return request.get("/api/otchet/vedomost", { params: params });
  };
  static FetchRegisterCashReportTable = (params: any) => {
    return request.get("/api/otchet/pko", { params: params });
  };
  static FetchCashExpense = (params: any) => {
    return request.get("/api/otchet/rasxod-nalich", { params: params });
  };
  static FetchWorkOrder = (params: any) => {
    return request.get("/api/otchet/zakaz-naryad", { params: params });
  };
  static FetchRegisterInvoice = (params: any) => {
    return request.get("/api/otchet/schet-faktur", { params: params });
  };
  static FetchReportSemiProductsTable = (params: any) => {
    return request.get("/api/otchet/vipusk-pf", { params: params });
  };
  static FetchReportShipmentTable = (params: any) => {
    return request.get("/api/otchet/otgruzka-gp", { params: params });
  };
  static FetchReportFinishedProducts = (params: any) => {
    return request.get("/api/otchet/not-realizatsiya", { params: params });
  }
  static FetchReportSoldFinishedProducts = (params: any) => {
    return request.get("/api/otchet/realizatsiya", { params: params });
  }
  static FetchReportDefectsTable = (params: any) => {
    return request.get("/api/otchet/vozvrat-tovar", { params: params });
  };
  static FetchRegisterPurchasesTable = (params: any) => {
    return request.get("/api/otchet/dog-pokupka", { params: params });
  };
  static FetchReportDriverTable = (params: any) => {
    return request.get("/api/otchet/voditeli-gp", { params: params });
  };
  static FetchReportPurchaseTable = (params: any) => {
    return request.get("/api/otchet/priobrBeton", { params: params });
  };
  static FetchOperationalCashTable = (params: any) => {
    return request.get("/api/otchet/dvijeniyu-nalichnix", { params: params });
  };
  static FetchCashReportTable = (params: any) => {
    return request.get("/api/otchet/kassoviy", { params: params });
  };
  static FetchBuyerReport = (params: any) => {
    return request.get("/api/otchet/zadoljennost-pokupateley", {
      params: params,
    });
  };
  static FetchSupplierReport = (params: any) => {
    return request.get("/api/otchet/zadoljennost-postavshikam", {
      params: params,
    });
  };
  static FetchAccountableReport = (params: any) => {
    return request.get("/api/otchet/otchetUchot", { params: params });
  };
  static FetchReconciliationAct = (params: any) => {
    return request.get("/api/otchet/akt", { params: params });
  };
  static FetchReconciliationActDetails = (params: any) => {
    return request.get("/api/otchet/akt/details", { params: params });
  };
  static FetchDriversShipmentMaterial = (params: any) => {
    return request.get("/api/otchet/prixod-sm", { params: params });
  };
  static FetchOperationalProductBalance = (params: any) => {
    return request.get("/api/otchet/reestr-ostatok-tovar", { params: params });
  };
}

export default ReportsServices;
