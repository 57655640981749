import { useState, useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/hook";
import { getDriversData } from "app/states/handbooks/selectStates";
import { driverMaterialList, driversMaterialFilter } from "app/states/reports";

import { get, isEmpty, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import ReportInfoTitle from "../components/ReportInfoTitle";
import moment from "moment";
import TableContentLoader from "../louders/TableContentLoader";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
import {
  changeDriverMaterialTable,
  clearDriverMaterialTable,
  fetchDriversMaterial,
  filterDriverMaterialTable,
} from "app/slices/reportSlices/driversShipmentMaterialSlices/driversShipmentMaterialSlices";
import DriversMaterialReportTable from "../components/DrivresMaterialReportTable";

type ParamsType = {
  name?: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  voditel?: string;
}

// Отчет по выработке водителей при отгрузке сырья и материалов
function DriversMaterialReportsContainer() {
  const dispatch = useAppDispatch();

  // data
  const data = useAppSelector(driverMaterialList);
  const filter = useAppSelector(driversMaterialFilter);

  const driversDropdowns = useAppSelector(getDriversData);

  const [check, setCheck] = useState(false);
  const LOADING_TABLE = get(data, "loading", false);

  // details
  const [details, setDetails] = useState<ParamState>({
    voditel: "",
  });
  //excel download

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Отчет по выработке водителей при отгрузке сырья и материалов ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `${check ? "K1" : "C1"}`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "voditel", ""),
        label: `Водитель:  ${get(details, "voditel", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `Отчет по выработке водителей при отгрузке сырья и материалов ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(
          `A${1 + index + 1}`,
          `${check ? "K" + (1 + index + 1) : "C" + (1 + index + 1)}`
        );
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Водитель",
        key: "imyaVoditeli",
        width: 30,
      },
      {
        header: "Количество рейсов",
        key: "sumKols",
        width: 20,
      },
    ];
    const checkHeader = check
      ? tableHeader.splice(
          2,
          0,
          check && {
            header: "Склад",
            key: "sklad",
            width: 35,
          },
          check && {
            header: "Номер документа",
            key: "nomerDoc",
            width: 20,
          },
          check && {
            header: "Дата документа",
            key: "dataDoc",
            width: 20,
          },
          check && {
            header: "Контрагент",
            key: "zakazchik",
            width: 20,
          },
          check && {
            header: "Материал",
            key: "imyaMateriala",
            width: 25,
          },
          check && {
            header: "Ед.изм",
            key: "imyaEdIzm",
            width: 10,
          },
          check && {
            header: "Количество материалов",
            key: "kolMateriala",
            width: 25,
          },
          check && {
            header: "Автомобиль",
            key: "gosNomer",
            width: 20,
          }
        )
      : tableHeader;

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList", [])?.forEach((item: any, index: number) => {
      if (!check) {
        sheet.addRow({
          order: index + 1,
          imyaVoditeli: get(item, "imyaVoditeli", ""),
          sumKols: Number(get(item, "sumKols", "")),
        });
        counterRow++;
      } else {
        const rowdataList = sheet.addRow({
          order: index + 1,
          imyaVoditeli: get(item, "imyaVoditeli", "ㅤ"),
          sklad: get(item, "sklad", "ㅤ"),
          nomerDoc: get(item, "nomerDoc", "ㅤ"),
          dataDoc: get(item, "dataDoc", "ㅤ"),
          zakazchik: get(item, "zakazchik", "ㅤ"),
          imyaMateriala: get(item, "imyaMateriala", "ㅤ"),
          imyaEdIzm: get(item, "imyaEdIzm", "ㅤ"),
          kolMateriala: get(item, "kolMateriala", "ㅤ"),
          gosNomer: get(item, "gosNomer", "ㅤ"),
          sumKols: Number(get(item, "sumKols", 0)),
        });
        // rowdataList.height = 25;
        // rowdataList.alignment = { vertical: "middle", horizontal: "center" };
        counterRow += 1;
        get(item, "details", []).forEach((detail: any) => {
          const row = sheet.addRow({
            order: "ㅤ",
            imyaVoditeli: get(detail, "imyaVoditeli", "ㅤ"),
            sklad: get(detail, "sklad", "ㅤ"),
            nomerDoc: get(detail, "nomerDoc", "ㅤ"),
            dataDoc: get(detail, "dataDoc", "ㅤ"),
            zakazchik: get(detail, "zakazchik", "ㅤ"),
            imyaMateriala: get(detail, "imyaMateriala", "ㅤ"),
            imyaEdIzm: get(detail, "imyaEdIzm", "ㅤ"),
            kolMateriala: Number(get(detail, "kolMateriala", 0)),
            gosNomer: get(detail, "gosNomer", "ㅤ"),
            sumKols: Number(get(detail, "kolReys", 0)),
          });
          row.eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
            };
          });
          // row.height = 25;
          // row.alignment = { vertical: "middle", horizontal: "center" };
          counterRow++;
        });
      }
    });

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      `${check ? "K" : "C"}${counterRow + count + 1}`
    );
    // const startCell = worksheet.getCell('A1');
    // const endCell = worksheet.getCell('K17');

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const fetchReportDriverData = useCallback(() => {
    dispatch(fetchDriversMaterial(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearDriverMaterialTable());

    setDetails({
      voditel: "",
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeDriverMaterialTable(null));
    }

    dispatch(filterDriverMaterialTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchDrivers({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "fromDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "toDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <Title
                  xx
                  className="title_style"
                  mk
                  style={{ justifyContent: "left" }}
                >
                  Детально{" "}
                  <Checkbox
                    handleChange={() => {
                      handleChangeFilter({
                        name: "detalno",
                        value: !check,
                      });
                      setCheck(!check);
                    }}
                  />
                </Title>
              </FlexBox>
            </Col>

            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchReportDriverData}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  width={"200px"}
                  options={driversDropdowns}
                  isSearchable
                  placeholder="Водитель"
                  value={get(filter, "voditelId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.voditelId === e.value) return;

                    handleChangeFilter({
                      name: "voditelId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      voditel: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchDriversMaterial({
                        ...filter,
                        voditelId: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={
                      "Отчет по выработке водителей при отгрузке сырья и материалов"
                    }
                  />

                  {!isEmpty(get(data, "dataList", [])) ? (
                    <DriversMaterialReportTable data={data} click={check} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default DriversMaterialReportsContainer;
