import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import UsersServices from "services/apiServices/users";
import { get } from "lodash";

const initialState: InitialState = {
  data: {
    loading: false,
    data: [],
    error: "",
    pagination: {},
  },
  filter: {
    userId: "",
    category: "",
    action: "",
    page: 0,
    size: 10,
  },
  details: {
    loading: false,
    data: [],
  },
};

export const fetchLogUsers = createAsyncThunk(
  "logUsers/fetchLogUsers",
  async (params: any, thunkAPI) => {
    try {
      const respond = await UsersServices.FetchLogUsers(params);
      return respond.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const logSlices = createSlice({
  name: "logUsers",
  initialState,
  reducers: {
    filterChangeLogUsers: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },

    clearLogFilter: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogUsers.pending, (state) => {
      state.data = {
        ...state.data,
        loading: true,
      };
    });
    builder.addCase(fetchLogUsers.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        data: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
      };
    });
    builder.addCase(fetchLogUsers.rejected, (state, action) => {
      state.data = {
        ...state.data,
        loading: false,
        error: action.error.message,
      };
    });
  },
});
export const { filterChangeLogUsers, clearLogFilter } = logSlices.actions;
export default logSlices.reducer;
