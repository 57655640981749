import { Title } from "components";
import { Col, Row } from "react-grid-system";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { TableWrapper } from "../components/styles/style";

const TableContentLoader = ({ col, ...props }: any) => {
  return (
    <>
      <SkeletonTheme baseColor="#ececec" highlightColor="#f6f6f6" {...props}>
        <Row className="mt-16">
          <Col xs={12}>
            <Title bold className="mb-8">
              <Skeleton width="600px" height="24px" />
            </Title>
            <Title>
              <Skeleton width="450px" />
            </Title>
            <Title>
              <Skeleton width="500px" />
            </Title>
          </Col>
        </Row>
        <Row className="mt-16">
          <Col className="responsive_overflow">
            <TableWrapper style={{ borderColor: "#dedede" }}>
              <thead>
                <tr>
                  <th style={{ width: "50px", borderColor: "#dedede" }}>
                    <Skeleton />
                  </th>
                  <th style={{ width: "300px", borderColor: "#dedede" }}>
                    <Skeleton />
                  </th>
                  <th style={{ width: "250px", borderColor: "#dedede" }}>
                    <Skeleton />
                  </th>
                  <th style={{ width: "200px", borderColor: "#dedede" }}>
                    <Skeleton />
                  </th>
                  <th style={{ width: "200px", borderColor: "#dedede" }}>
                    <Skeleton />
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...new Array(col)].map((col, index) => (
                  <tr key={index + 1}>
                    <td style={{ borderColor: "#dedede" }}>
                      <Skeleton />
                    </td>
                    <td style={{ borderColor: "#dedede" }}>
                      <Skeleton />
                    </td>
                    <td style={{ borderColor: "#dedede" }}>
                      <Skeleton />
                    </td>
                    <td style={{ borderColor: "#dedede" }}>
                      <Skeleton />
                    </td>
                    <td style={{ borderColor: "#dedede" }}>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </SkeletonTheme>
    </>
  );
};

export default TableContentLoader;

// Array.apply(null, { length: 10 })
