import request from "services/api";

class AuthServices {
    static Login = (params: any) => {
        return request.post("/api/auth/login", { ...params });
    };
    static LogOut = ()=>{
        return request.post("/api/auth/logout")
    }
}

export default AuthServices;
