import { get, isEqual } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function DriversMaterialReportTable({ data, click }: any) {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper className="mb-16">
              <thead>
                <tr>
                  <th style={{ maxWidth: "10px" }}>№</th>
                  <th style={{ width: "200px" }}>Водитель</th>
                  {click ? (
                    <>
                      <th>Склад</th>
                      <th>Номер документа</th>
                      <th>Дата документа</th>
                      <th style={{ width: "200px" }}>Контрагент</th>
                      <th>Материал</th>
                      <th>Ед.изм</th>
                      <th>Количество материалов</th>
                      <th>Автомобиль</th>
                    </>
                  ) : null}
                  <th>Количество рейсов</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {get(data, "dataList", []) ? (
                    get(data, "dataList", []).map(
                      (item: any, index: number) => (
                        <>
                          <tr key={index + 1}>
                            <th>{index + 1}</th>
                            <td>{get(item, "imyaVoditeli", "-")}</td>

                            {click ? (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            ) : null}
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "sumKols", 0)}
                                thousandSeparator
                              />
                            </td>
                          </tr>

                          {click && get(item, "details", [])
                            ? get(item, "details", []).map(
                                (detail: any, index: number) => (
                                  <tr
                                    key={index + 1}
                                    style={{ backgroundColor: "#f4f4f4" }}
                                  >
                                    <td></td>
                                    <td className="min-w-80"></td>
                                    <td className="min-w-80">
                                      {get(detail, "sklad", "")}
                                    </td>
                                    <td>{get(detail, "nomerDoc", "")} </td>
                                    <td>
                                      {moment(
                                        get(detail, "dataDoc", "")
                                      ).format("DD.MM.YYYY")}
                                    </td>
                                    <td>{get(detail, "zakazchik", "")}</td>
                                    <td className="min-w-80">
                                      {get(detail, "imyaMateriala", "")}
                                    </td>
                                    <td>{get(detail, "imyaEdIzm", "")}</td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(detail, "kolMateriala", 0)}
                                        thousandSeparator
                                      />
                                    </td>
                                    <td>{get(detail, "gosNomer", "")}</td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(detail, "kolReys", 0)}
                                        thousandSeparator
                                      />
                                    </td>
                                  </tr>
                                )
                              )
                            : null}
                        </>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={10}>Виберите параметры</td>
                    </tr>
                  )}
                </>
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default DriversMaterialReportTable;
