import FlexBox from "components/FlexBox/FlexBox";
import { Props } from "components/MenuItem/type";
import Text from "components/Text/Text";
import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { SidebarItemWrapper } from "./style";
import { memo } from "react";
const SidebarItem = ({
    title,
    icon = "",
    url = "/",
    stroke = false,
    order,
    ...props
}: Props) => {

    return (
            <NavLink to={url} className={"menu-item"}>
                <FlexBox >
                    <div style={{ width: "20px" }}>
                        {icon ? (
                            <ReactSVG
                                src={icon}
                                className={stroke ? "menu-stock-icon" : "menu-icon svg_icon"}
                            />
                        ) : (
                            <Text bold>{order}.</Text>
                        )}
                    </div>
                    <Text dark className={"ml-16"} small >
                        {title}
                    </Text>
                </FlexBox>
            </NavLink>
    );
};

export default memo(SidebarItem);
