import { AccountableReportType } from "app/slices/reportSlices/accountableReportSlices/type";
import { Title } from "components";
import { get, isEmpty } from "lodash";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function AccountableReportTable({ data }: any) {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth="1400px" className="mb-16">
          <thead>
            <tr>
              <th rowSpan={2}>№</th>
              <th rowSpan={2} style={{ width: "180px" }}>
                Контрагент
              </th>
              <th colSpan={2}>Начальный остаток</th>
              <th colSpan={2}>Оборот</th>
              <th colSpan={2}>Конечный остаток</th>
            </tr>
            <tr>
              <th>Дебет</th>
              <th>Кредит</th>

              <th>Дебет</th>
              <th>Кредит</th>

              <th>Дебет</th>
              <th>Кредит</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(get(data, "dataList.list", [])) ? (
              get(data, "dataList.list", []).map(
                (item: AccountableReportType, index: number) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{get(item, "kontragentImya", "")}</td>

                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "nachalniyOstatokDebit", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "nachalniyOstatokKredit", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>

                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "oborotDebit", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "oborotKredit", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>

                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "konechniyOstatokDebit", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "konechniyOstatokKredit", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={9}>Виберите параметры</td>
              </tr>
            )}
            <tr className="black_td">
              <td colSpan={2} style={{ textAlign: "start" }}>
                <Title bold>Итого:</Title>
              </td>

              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.sumOfNachalniyOstatokDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.sumOfNachalniyOstatokKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>

              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.sumOfOborotDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.sumOfOborotKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>

              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.sumOfKonechniyOstatokDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.sumOfKonechniyOstatokKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
            </tr>
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
}

export default AccountableReportTable;
