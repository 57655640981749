import { Props } from "./type";
import Text from "components/Text/Text";
import { memo } from "react";
import { PageSelector, ShowPageWrapper } from "./style";

const PAGE_SIZES = [
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
];

const ShowOnPage = ({ pageSize, handlePageChange }: Props) => {
  return (
    <ShowPageWrapper>
      <Text className={"ml-8"} style={{ width: "60%" }} small>
        Показать
      </Text>
      <PageSelector
        value={pageSize}
        placeholder={"№"}
        menuPlacement={"top"}
        placement={"top"}
        handleChange={(e: any) => handlePageChange(e)}
        options={PAGE_SIZES}
      />
      <Text className={"ml-8"} style={{ width: "100%" }} small>
        на странице
      </Text>
    </ShowPageWrapper>
  );
};

export default memo(ShowOnPage);
