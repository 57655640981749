import { TableWrapper } from "./styles/style";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import { NumericFormat } from "react-number-format";
import moment from "moment";

interface ItemTypes {
  edIzm: string | null;
  imya: string | null;
  ostKol: number | null;
  ostSumma: number | null;
}
const AnalysisExecutionTable = ({ data, order, filter }: any) => {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper id="execution">
          <thead>
            <tr>
              <th rowSpan={2}>№</th>
              <th style={{ width: "30vh" }} rowSpan={2}>
                {" "}
                Наименование строк{" "}
              </th>
              <th style={{ width: "10vh" }} rowSpan={2}>
                Наименование ТМЦ
              </th>
              <th rowSpan={2}>Единица измерения</th>
              <th colSpan={2}>
                Остаток на{" "}
                {moment(get(filter, "fromDate", "")).format("DD.MM.YYYY")}
              </th>
            </tr>
            <tr>
              <th>Количество</th> <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(get(data, "analysisList", [])) ? (
              <>
                <>
                  <tr>
                    <td
                      rowSpan={get(data, "analysisList.AVANS", []).length + 1}
                    >
                      1
                    </td>
                    <td colSpan={3} className="text-left">
                      Остаток авансового платежа на начало дня{" "}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.AVANS", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={
                        get(data, "analysisList.POLUFABRIKAT", []).length + 1
                      }
                    >
                      2
                    </td>
                    <td colSpan={3} className="text-left">
                      Полуфабрикаты на складе{" "}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.POLUFABRIKAT", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={
                        get(data, "analysisList.GOTOVAYA_PRODUKTSIYA", [])
                          .length + 1
                      }
                    >
                      3
                    </td>
                    <td colSpan={3} className="text-left">
                      Готовая продукция на складе
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.GOTOVAYA_PRODUKTSIYA", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={
                        get(data, "analysisList.OTGRUZKA", []).length + 1
                      }
                    >
                      4
                    </td>
                    <td colSpan={3} className="text-left">
                      Отгружено готовой продукции
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.OTGRUZKA", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={
                        get(data, "analysisList.REALIZATSIYA", []).length + 1
                      }
                    >
                      5
                    </td>
                    <td colSpan={3} className="text-left">
                      Реализовано готовой продукции
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.REALIZATSIYA", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={get(data, "analysisList.VOZVRAT", []).length + 1}
                    >
                      6
                    </td>
                    <td colSpan={3} className="text-left">
                      Возврат отгруженного товара
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.VOZVRAT", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={
                        get(data, "analysisList.REALIZATSIYA2", []).length + 1
                      }
                    >
                      7
                    </td>
                    <td colSpan={3} className="text-left">
                      Реализовано готовой продукции
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.REALIZATSIYA2", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={
                        get(data, "analysisList.ZADOLJENNOST_ZN", []).length + 1
                      }
                    >
                      8
                    </td>
                    <td colSpan={3} className="text-left">
                      {" "}
                      Задолженность по Заказ-наряду {get(
                        order,
                        "nomerDoc",
                        ""
                      )}{" "}
                      от{" "}
                      {moment(get(order, "dataDoc", "")).format("DD.MM.YYYY")}{" "}
                    </td>

                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.ZADOLJENNOST_ZN", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
                <>
                  <tr>
                    <td
                      rowSpan={
                        get(data, "analysisList.ZADOLJENNOST", []).length + 1
                      }
                    >
                      9
                    </td>
                    <td colSpan={3} className="text-left">
                      Общая задолженность покупателя{" "}
                      <span className="bold_text">
                        {get(order, "kontragentImya", "")}
                      </span>{" "}
                      на конец дня
                    </td>

                    <td></td>
                    <td></td>
                  </tr>
                  {get(data, "analysisList.ZADOLJENNOST", []).map(
                    (item: ItemTypes, index: number) => (
                      <tr key={index + 1}>
                        <td></td>
                        <td>{get(item, "imya", "")}</td>
                        <td>{get(item, "edIzm", "-")}</td>
                        <td>{get(item, "ostKol", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "ostSumma", 0)}
                            thousandSeparator
                            decimalScale={2}
                            className="black_td"
                          />
                        </td>
                      </tr>
                    )
                  )}
                </>
              </>
            ) : (
              <tr>
                <td colSpan={6}>Виберите параметры</td>
              </tr>
            )}
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
};
export default AnalysisExecutionTable;
