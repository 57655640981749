import styled from "styled-components";

const NavBarContainer = styled.div`
    width: 100%;
    z-index: 100;
    position: fixed;
    background: #ffffff;
    padding: 12px 0;
`;

export { NavBarContainer };
