import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterLargePerson,
  fetchLargePerson,
  handleLargePersonFilter,
} from "app/slices/handbookSlices/largePersonSlices/largePersonSlices";
import { LargePersonDataType } from "app/slices/handbookSlices/largePersonSlices/type";
import { fetchAllYurList } from "app/slices/handbookSlices/yurLitsSlices/yurLitsSlices";
import { allLargePersonData, allLargePersonFilter } from "app/states/handbooks";
import { getTypeYurList } from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BasePhoneInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import _, { get, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { PatternFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { largePersonSchema } from "utilities/schema/spravochnikSchemas";
//  Юридические лица
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: LargePersonDataType;
}) => {
  const dispatch = useAppDispatch();
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateLargePerson({
          data: {
            ...values,
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            setSubmitting(false);
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewLargePerson({
          data: values,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            setSubmitting(false);
            handleCloseModal();
            afterSubmitAction();
            resetForm();
          }
        });
      }
    } catch (err) {
      setSubmitting(false);
      console.log("Error", err);
    }
  };
  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: get(details, "id", 0),
      imyaYurLitsa: get(details, "imyaYurLitsa", ""),
      polnoeImyaYurLitsa: get(details, "polnoeImyaYurLitsa", ""),
      tipYurLitsaId: get(details, "tipYurLitsaId", 1),
      inn: get(details, "inn", ""),
      nds: get(details, "nds", ""),
      oked: get(details, "oked", ""),
      address: get(details, "address", ""),
      telNomer: get(details, "telNomer", ""),
      dir: get(details, "dir", ""),
      glavbuh: get(details, "glavbuh", ""),
      kommentariya: get(details, "kommentariya", ""),
    },
    onSubmit,
    validationSchema: largePersonSchema,
  });
  const getOptionsYurList = useAppSelector(getTypeYurList);

  useEffect(() => {
    dispatch(fetchAllYurList({}));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center">
            <Col xs={4}>
              <Title>
                Наименование<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "imyaYurLitsa", "")}
                handleInput={(e: string) => {
                  setFieldValue("imyaYurLitsa", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Полное наименование <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "polnoeImyaYurLitsa", "")}
                handleInput={(e: string) => {
                  setFieldValue("polnoeImyaYurLitsa", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Тип юридического лица<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                placeholder="Тип юридического лица"
                id={"tipYurLitsaId"}
                isSearchable
                value={get(values, "tipYurLitsaId", 1)}
                options={getOptionsYurList}
                handleChange={(e: any) => {
                  setFieldValue("tipYurLitsaId", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                ИНН<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "inn", "")}
                handleInput={(e: string) => {
                  setFieldValue("inn", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Код НДС<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "nds", "")}
                handleInput={(e: string) => {
                  setFieldValue("nds", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Код ОКЭД</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "oked", "")}
                handleInput={(e: string) => {
                  setFieldValue("oked", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Адрес местонахождения<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "address", "")}
                handleInput={(e: string) => {
                  setFieldValue("address", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Телефоны<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              {/* <BasePhoneInput
                value={get(values, "telNomer", "")}
                handleInput={(e: string) => {
                  setFieldValue("telNomer", e);
                }}
                format=""
              /> */}
              <BasePhoneInput
                value={get(values, "telNomer", "")}
                handleInput={(e: string) => {
                  setFieldValue("telNomer", e);
                }}
                format="+998 (##) ### ## ##"
                allowEmptyFormatting={true}
                mask="_"
                className="your-custom-class"
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>ФИО руководителя юр.лица</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "dir", "")}
                handleInput={(e: string) => {
                  setFieldValue("dir", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>ФИО главного бухгалтера</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "glavbuh", "")}
                handleInput={(e: string) => {
                  setFieldValue("glavbuh", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Комментарии</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green loading={isSubmitting}>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const LegalPersonsContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(allLargePersonData);
  let LOADING = get(data, "loading", false);
  const filter = useAppSelector(allLargePersonFilter);

  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleLargePersonFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchLargePerson({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  const clearFilter = useCallback(() => {
    dispatch(fetchLargePerson({ ...filter, search: "", InAscOrder: false }));
    dispatch(clearFilterLargePerson());
  }, [dispatch, filter]);

  const fetchLargePersonData = useCallback(() => {
    dispatch(fetchLargePerson({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const [details, setDetails] = useState<LargePersonDataType | any>({});
  const handleOpenModal = useCallback((data: LargePersonDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteLargePerson(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchLargePersonData();
      }
    });
  };

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  useEffect(() => {
    return () => {
      clearFilter();
    };
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "YUR_LITSA_CREATE",
                    page: "YUR_LITSA",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                value={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger onClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchLargePersonData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименования",
                    "Полное наименования",
                    "Тип юридического лица",
                    "ИНН",
                    "Код НДС",
                    "Код ОКЭД",
                    "Адрес местонахождения",
                    "Телефоны",
                    "ФИО руководителя юрлица",
                    "ФИО главного бухгалтера",
                    "Комментарии",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "dataList", [])) ? (
                        get(data, "dataList", []).map(
                          (item: LargePersonDataType, index: number) => (
                            <tr key={get(item, "id", 0)}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"FIZ_LITSA"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imyaYurLitsa", "-")}</td>
                              <td>{get(item, "polnoeImyaYurLitsa", "-")}</td>
                              <td>{get(item, "imyaTipYurLitsa", "-")}</td>
                              <td>{get(item, "inn", "-")}</td>
                              <td>{get(item, "nds", "-")}</td>
                              <td>{get(item, "oked", "-")}</td>
                              <td>{get(item, "address", "-")}</td>
                              <td>
                                {
                                  <PatternFormat
                                    format="+### (##) ### ## ##"
                                    displayType="text"
                                    value={get(item, "telNomer", 0)}
                                  />
                                }
                              </td>
                              <td>{get(item, "dir", "-")}</td>
                              <td>{get(item, "glavbuh", "-")}</td>
                              <td>{get(item, "kommentariya", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={14} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={14}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default LegalPersonsContainer;
