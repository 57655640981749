import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFormDetailsOffset,
  fetchAllOffsetAdvances,
  handleFilterChangeOffset,
} from "app/slices/documentSlices/offsetAdvanceSlices/offsetAdvanceSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  offsetAdvanceFilter,
  offsetAdvanceList,
  offsetAdvanceStat,
} from "app/states/documents";
import { contractorOptions } from "app/states/documents/selectStates";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import { PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
//  Зачет аванса покупателей
const OffsetAdvanceBuyersContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_OFFSET_ADVANCE
  );
  const contractorDropdowns = useAppSelector(contractorOptions);
  const dataOffset = useAppSelector(offsetAdvanceList);
  const filter = useAppSelector(offsetAdvanceFilter);
  const statics = useAppSelector(offsetAdvanceStat);
  const LOADING_TABLE = get(dataOffset, "loading", []);
  const fetchOffsetFunction = useCallback(() => {
    dispatch(fetchAllOffsetAdvances(filter));
    dispatch(fetchAllCounterpart({ all: true }));
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchAllOffsetAdvances({ ...filter, fromDate: null, toDate: null })
    );
    dispatch(clearFormDetailsOffset());
  }, [filter]);

  const navigateToForm = (params: { id: string | number }) => {
    navigate(Documents.ADD_OFFSET_ADVANCE, {
      state: params,
    });
  };
  useEffect(() => {
    fetchOffsetFunction();
  }, [filter.page, filter.size]);

  const handleAddButton = () => {
    navigate(Documents.ADD_OFFSET_ADVANCE);
  };

  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={10}>
              <ShowStatistics statistic={statics} />
            </Col>
            <Col xs={2} style={{ textAlign: "end" }}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "ZACHET_AVANS_CREATE",
                    page: "ZACHET_AVANS",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={8}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  options={contractorDropdowns}
                  value={get(filter, "kontragentId", "")}
                  onClick={(e: string) => {
                    dispatch(
                      handleFilterChangeOffset({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                  placeholder={"Поиск по Контрагент"}
                />

                <DatePicker
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeOffset({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeOffset({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"}>
              <Button
                primary
                className={"mr-16"}
                handleClick={fetchOffsetFunction}
              >
                Применять
              </Button>
              <Button danger handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      {!isEmpty(get(dataOffset, "data", []))
                        ? get(dataOffset, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", "")}
                                onClick={() =>
                                  navigateToForm({
                                    id: get(item, "id", ""),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      dataOffset,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      dataOffset,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "-")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(dataOffset, "data", [])) && (
                            <Nodata colLength={8} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item: any, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={8}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(dataOffset, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(dataOffset, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChangeOffset({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(dataOffset, "pagination.currentPageNumber", 0)}
                  pageCount={get(dataOffset, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChangeOffset({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default OffsetAdvanceBuyersContainer;
