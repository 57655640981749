import { get, isEmpty } from "lodash";
import { TableWrapper } from "./styles/style";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { formatValueReport } from "utilities/helpers";

const CommodityReportsTable = ({ data }: any) => {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth="1400px" className="mb-16">
          <thead>
            <tr>
              <th rowSpan={2}>№</th>
              <th rowSpan={2} style={{ width: "30vh" }}>
                Товар
              </th>
              <th rowSpan={2} style={{ width: "10vh" }}>
                Единица измерения
              </th>
              <th colSpan={2}>Начальный остаток</th>
              <th colSpan={2}>Приход</th>
              <th colSpan={2}>Расход</th>
              <th colSpan={2}>Конечный остаток</th>
            </tr>
            <tr>
              <th>Количество</th>
              <th>Сумма</th>

              <th>Количество</th>
              <th>Сумма</th>

              <th>Количество</th>
              <th>Сумма</th>

              <th>Количество</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(get(data, "commodityList.list", [])) ? (
              get(data, "commodityList.list", []).map(
                (item: any, index: number) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{get(item, "imyaTovara", "")}</td>
                    <td>{get(item, "imyaEdIzm", "")}</td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "nachalniyOstatok.kolichestva", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>

                    <td>
                      <NumericFormat
                        displayType="text"
                        value={formatValueReport(
                          get(item, "nachalniyOstatok.summa", 0)
                        )}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "prixod.kolichestva", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={formatValueReport(get(item, "prixod.summa", 0))}
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "rasxod.kolichestva", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={formatValueReport(get(item, "rasxod.summa", 0))}
                        fixedDecimalScale
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "konechniyOstatok.kolichestva", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={formatValueReport(
                          get(item, "konechniyOstatok.summa", 0)
                        )}
                        fixedDecimalScale
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={11}>Виберите параметры</td>
              </tr>
            )}
            <tr className="black_td">
              <td colSpan={3} style={{ textAlign: "start" }}>
                Итого:{" "}
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.NachalniyKol", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.NachalniySum", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.PrixodKol", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.PrixodSum", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.RasxodKol", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.RasxodSum", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.KonechniyKol", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "commodityList.itogo.KonechniySum", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
            </tr>
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
};

export default CommodityReportsTable;
