import { Breadcrumb, FlexBox, Sidebar, SidebarItem } from "components";
import { get, isEqual } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Main } from "routers/paths";
import { sideBarMenus } from "app/states/menu";
import { useAppSelector } from "app/hook";
import { ReactSVG } from "react-svg";
import closeMenu from "assets/images/icons/close_menu.svg";
import menuuIcon from "assets/images/icons/menu.svg";
import { profileData } from "app/states/profile";
import { SidebarItemWrapper } from "components/SidebarItem/style";
import { Layout } from "antd";
import { MAIN_DEPARTMENT } from "routers/constants";
const HandbooksInnerLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const sideBar = useAppSelector((store) => sideBarMenus(store, Main.HANDBOOK));
  const pageListData = useAppSelector(profileData);

  const pageData = useMemo(
    () => get(pageListData, "profileData.permissionList", []),
    [pageListData]
  );

  const data = pageData.find(
    (item: any) => item.department === MAIN_DEPARTMENT.HANDBOOK
  );

  const navigateToFirstSidebar = useCallback(() => {
    if (isEqual(get(location, "pathname", ""), `/${Main.HANDBOOK}`)) {
      const frstAllowedUrl = sideBar.find(
        (item: any) =>
          get(item, "page", false) === get(data, "pages[0].page", null)
      )?.id;
      if (frstAllowedUrl) {
        navigate(frstAllowedUrl, { replace: true });
      }
    }
  }, [location, navigate]);
  //  sidebar
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;
  useEffect(() => {
    navigateToFirstSidebar();
  }, [navigateToFirstSidebar, sideBar]);

  //  checking only actions read
  const permissionList = get(data, "pages", []).map((item: any) => {
    if (get(item, "actions", []).includes(`${item.page}_READ`)) {
      return get(item, "page", "");
    }
  });

  return (
    <Fragment>
      <Layout
        style={{
          padding: 0,
          fontFamily: "Arial, Helvetica, sans-serif",
          zIndex: 100,
          left: 0,
          width: "100%",
          position: "fixed",
        }}
      >
        <Row align="center">
          {!collapsed && (
            <Col xs={2.6} style={{ textAlign: "end" }}>
              <ReactSVG
                src={closeMenu}
                className="cursor-pointer  mt-8"
                onClick={() => setCollapsed(!collapsed)}
              />
            </Col>
          )}
          <Col xs={9.4} style={{ paddingLeft: 0 }}>
            <FlexBox>
              {collapsed && (
                <ReactSVG
                  src={menuuIcon}
                  className="svg_icon mr-16 ml-24"
                  onClick={() => setCollapsed(!collapsed)}
                />
              )}
              <Breadcrumb style={{ margin: "12px 0" }} />
            </FlexBox>
          </Col>
        </Row>
      </Layout>
      <Layout className="mt-50">
        <Sider
          collapsible
          style={{
            background: "inherit",
            marginBottom: "10px",
          }}
          className="sidebar"
          trigger={null}
          width={"43vh"}
          collapsedWidth={0}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Sidebar
            className="component_animate"
            style={collapsed ? { display: "none" } : { display: "flex" }}
          >
            {sideBar
              ?.filter((item: any, index: number) => {
                if (permissionList.includes(item.page)) {
                  return item;
                }
              })
              .map((item: any, index: number) => {
                return (
                  <SidebarItemWrapper>
                    <SidebarItem
                      key={item.id}
                      title={get(item, "name", "")}
                      url={get(item, "id", "")}
                      order={index + 1}
                    />
                  </SidebarItemWrapper>
                );
              })}
          </Sidebar>
        </Sider>
        <Content
          style={{
            padding: " 0",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Fragment>
  );
};

export default HandbooksInnerLayout;
