import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { CONTEXT } from "utilities/constants";
type LoadingItemType = {
  recordLoading: boolean;
  undoLoading: boolean;
  deleteLoading: boolean;
};
const initialState: LoadingItemType = {
  recordLoading: false,
  undoLoading: false,
  deleteLoading: false,
};
const contextMenuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    handleContextLoading: (state, action) => {
      let isLoading = get(action, "payload.value", false);
      switch (action.payload.type) {
        case CONTEXT.RECORD:
          return {
            ...state,
            recordLoading: isLoading,
          };
        case CONTEXT.UNDO:
          return {
            ...state,
            undoLoading: isLoading,
          };
        case CONTEXT.DELETE:
          return {
            ...state,
            deleteLoading: isLoading,
          };
        default:
          return state;
      }
    },
  },
});
export const { handleContextLoading } = contextMenuSlice.actions;
export default contextMenuSlice.reducer;
