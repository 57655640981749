import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import axios from "axios";

const initialState: InitialState = {
  contractStatProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,

    data: {
      // vipuskPFDetailsList: [],
      // poFaktuDetailsList: [],
    },
  },
  filter: {
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  semiProduct: {
    loading: false,
    productList: [],
    pagination: {},
    error: "",
  },
  details: {
    loading: false,
    data: [],
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchSemiProduct = createAsyncThunk(
  "semiProduct/fetchSemiProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSemiProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchNotFilledSemiProduct = createAsyncThunk(
  "semiProduct/fetchNotFilledSemiProduct",
  async (params, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledSemiPutProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSemiProductsTempById = createAsyncThunk(
  "semiProduct/fetchSemiProductsTempById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchNotFilledSemiProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSemiProductsById = createAsyncThunk(
  "semiProduct/fetchSemiProductsById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSemiProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSemiProductStat = createAsyncThunk(
  "semiProduct/fetchSemiProductStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSemiProductStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSemiProductDetails = createAsyncThunk(
  "semiProduct/fetchSemiProductDetails",
  async (params: any, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        soure.cancel();
      });
      const request = await DocumentServices.GetSemiDetails({
        ...params,
        cancel: soure.token,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
const semiProductSlices = createSlice({
  name: "semiProduct",
  initialState,
  reducers: {
    addSubjectsSemi: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          vipuskPFDetailsList:
            get(actions, "payload.tipSpisaniyaId.value", null) === 1
              ? get(actions, "payload.data", [])
              : [],
        },
      };
    },
    addSubjectsSemiPofaktu: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          poFaktuDetailsList: !isEmpty(get(actions, "payload", []))
            ? get(actions, "payload", [])
            : state.formData.data.poFaktuDetailsList,
        },
      };
    },
    addSubjectsOnesSemi: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          poFaktuDetailsList: !isEmpty(get(actions, "payload", {}))
            ? [
                ...state.formData.data.poFaktuDetailsList,
                get(actions, "payload", {}),
              ]
            : [],
        },
      };
    },
    changeItemSubjectsSemi: (state, action) => {
      const id = get(action, "payload.id", 0);
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          poFaktuDetailsList: state.formData.data.poFaktuDetailsList.map(
            (item: any, index: number) =>
              index == id
                ? {
                    ...item,
                    [get(action, "payload.name", "")]: get(
                      action,
                      "payload.value",
                      0
                    ),
                  }
                : item
          ),
        },
      };
    },
    removeSubjectSemi: (state, action) => {
      const id = get(action, "payload.id", 0);
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          poFaktuDetailsList: state.formData.data.poFaktuDetailsList.filter(
            (item: any, index: any) => index !== id
          ),
        },
      };
    },
    filterChangeSemi: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearSearchFilterSemi: (state) => {
      state.filter = initialState.filter;
    },
    clearAllSemiData: (state) => {
      state.filter = initialState.filter;
    },
    clearDetailsSemi: (state) => {
      state.details = initialState.details;
    },
    clearFormSemiData: (state) => {
      state.formData = initialState.formData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSemiProduct.pending, (state) => {
      state.semiProduct = {
        loading: true,
      };
    });

    builder.addCase(fetchSemiProduct.fulfilled, (state, actions) => {
      state.semiProduct = {
        loading: false,
        productList: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchSemiProduct.rejected, (state, actions) => {
      state.semiProduct = {
        loading: false,
        productList: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    builder.addCase(fetchNotFilledSemiProduct.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(fetchNotFilledSemiProduct.fulfilled, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchNotFilledSemiProduct.rejected, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    //  fetch temp
    builder.addCase(fetchSemiProductsTempById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchSemiProductsTempById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchSemiProductsTempById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    //  fetch order id
    builder.addCase(fetchSemiProductsById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchSemiProductsById.fulfilled, (state, actions) => {
      console.log(get(actions, "payload.data", []), "data info");
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchSemiProductsById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    // fetching sale contracts statists
    builder.addCase(fetchSemiProductStat.pending, (state) => {
      state.contractStatProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchSemiProductStat.fulfilled, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchSemiProductStat.rejected, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
    // fetch details by prodcut id
    builder.addCase(fetchSemiProductDetails.pending, (state) => {
      state.details = {
        ...state.details,
        loading: true,
      };
    });
    builder.addCase(fetchSemiProductDetails.fulfilled, (state, actions) => {
      state.details = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchSemiProductDetails.rejected, (state, actions) => {
      state.details = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
  },
});

export const {
  addSubjectsSemi,
  addSubjectsSemiPofaktu,
  addSubjectsOnesSemi,
  changeItemSubjectsSemi,
  removeSubjectSemi,
  filterChangeSemi,
  clearAllSemiData,
  clearFormSemiData,
  clearSearchFilterSemi,
  clearDetailsSemi,
} = semiProductSlices.actions;
export default semiProductSlices.reducer;
