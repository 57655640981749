import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";
// fiz lits
const initialState: InitialState = {
  data: { loading: false, dataList: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchAllFizList = createAsyncThunk(
  "allFizList/fetchAllFizList",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetFizLits(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const fizListSlices = createSlice({
  name: "allFizList",
  initialState,
  reducers: {
    handleFizLitsFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearFilterFizLits: (state) => {
      state.filter = initialState.filter;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllFizList.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchAllFizList.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllFizList.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleFizLitsFilter, clearFilterFizLits } = fizListSlices.actions;
export default fizListSlices.reducer;
