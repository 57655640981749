import request from "services/api";
import {
  AddNewPaymentMethodTypes,
  AddNewDeliveryMethodTypes,
  AddNewIncomeTypes,
  AddNewTypeFlowTypes,
  AddNewTypeContractTypes,
  AddNewLegalPhysPersonTypes,
  AddNewTypeWriteOffTypes,
  DeletePaymentMethodTypes,
  AddNewRoundOrderTypes,
} from "types/perechislenyaType";
import { AllPaginationFilterType } from "types/spravochnikType";

class EnumerationsServices {
  // Способ оплаты
  static GetPaymentMethod = (params: AllPaginationFilterType) => {
    return request.get("/api/perechisleniya/sposob-oplati", { params });
  };
  static AddNewPaymentMethod = (params: AddNewPaymentMethodTypes) => {
    return request.post("/api/perechisleniya/sposob-oplati", { ...params });
  };

  static DeletePaymentMethod = (id: number) => {
    return request.delete(`/api/perechisleniya/sposob-oplati/${id}`);
  };
  // Способ доставки
  static GetDeliveryMethod = (params: AllPaginationFilterType) => {
    return request.get("/api/perechisleniya/sposob-dostavki", { params });
  };
  static AddNewDeliveryMethod = (params: AddNewDeliveryMethodTypes) => {
    return request.post("/api/perechisleniya/sposob-dostavki", { ...params });
  };
  // Тип прихода
  static GetIncome = (params: AllPaginationFilterType) => {
    return request.get("/api/perechisleniya/tipi-prixoda", { params });
  };
  static AddNewIncome = (params: AddNewIncomeTypes) => {
    return request.post("/api/perechisleniya/tipi-prixoda", { ...params });
  };

  // Тип расхода
  static GetTypeFlow = (params: AllPaginationFilterType) => {
    return request.get("/api/perechisleniya/tipi-rasxoda", { params });
  };
  static AddNewTypeFlow = (params: AddNewTypeFlowTypes) => {
    return request.post("/api/perechisleniya/tipi-rasxoda", { ...params });
  };
  // Тип списаня
  static GetTypeWriteOff = (params: AllPaginationFilterType) => {
    return request.get("/api/perechisleniya/tipi-spisaniya", { params });
  };
  static AddNewTypeWriteOff = (params: AddNewTypeWriteOffTypes) => {
    return request.post("/api/perechisleniya/tipi-spisaniya", { ...params });
  };
  //  Юр / Физ лицо
  static GetLegalPhysPerson = () => {
    return request.get("/api/perechisleniya/yur-fiz-litsa");
  };
  static AddNewLegalPhysPerson = (params: AddNewLegalPhysPersonTypes) => {
    return request.post("/api/perechisleniya/yur-fiz-litsa", { ...params });
  };
  // Тип договора
  static GetTypeContract = (params: AllPaginationFilterType) => {
    return request.get("/api/perechisleniya/tipi-dogovora", { params });
  };
  static AddNewTypeContract = (params: AddNewTypeContractTypes) => {
    return request.post("/api/perechisleniya/tipi-dogovora", { ...params });
  };
  //  Порядок округления
  static GetRoundOrder = (params: AllPaginationFilterType) => {
    return request.get("/api/perechisleniya/okrugleniye", { params });
  };
  static AddNewRoundOrder = (params: AddNewRoundOrderTypes) => {
    return request.post("/api/perechisleniya/okrugleniye", { ...params });
  };

  static GetRoundMethods = () => {
    return request.get("/api/perechisleniya/okrugleniye/metod-okruglineya");
  };

  //  new tip spisanya
  static GetTipSpisanya = (params: any) => {
    return request.get("/api/perechisleniya/tipi-spisaniya-nekonditsionnogo-tovara", { params })
  }
}
export default EnumerationsServices;
