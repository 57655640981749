import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, detailsManual } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
    statusMaterials: {
        loading: false,
        data: {},
        error: "",
    },
    formData: {
        loading: false,
        error: "",
        isTempActive: false,
        data: {
            spisanieSMDetailsList: [],
        },
    },
    filter: {
        territoriyaId: null,
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    manualMaterials: {
        loading: false,
        data: [],
        pagination: {},
        error: "",
    },
    notFilledList: {
        loading: false,
        data: [],
        error: "",
    },
};

export const fetchManualMaterials = createAsyncThunk(
    "manualMaterials/fetchManualMaterials",
    async (params: any, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchManualMaterials(params);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchNotFilledManualMaterials = createAsyncThunk(
    "manualMaterials/fetchNotFilledManualMaterials",
    async (params, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchNotFilledManualMaterials(
                params
            );
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchManualMaterialsByTempId = createAsyncThunk(
    "manualMaterials/fetchManualMaterialsByTempId",
    async (id: string, thunkAPI) => {
        try {
            const request =
                await DocumentServices.FetchNotFilledManualMaterialsById(id);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchManualMaterialsById = createAsyncThunk(
    "manualMaterials/fetchManualMaterialsById",
    async (id: string, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchManualMaterialsById(id);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchManualMaterialsStat = createAsyncThunk(
    "manualMaterials/fetchManualMaterialsStat",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchManualMaterialsStatics();
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const manualMaterialSlice = createSlice({
    name: "manualMaterials",
    initialState,
    reducers: {
        addSubjectsManual: (state, actions) => {
            state.formData = {
                ...state.formData,
                data: {
                    ...state.formData.data,
                    spisanieSMDetailsList: !isEmpty(get(actions, "payload", {}))
                        ? [...state.formData.data.spisanieSMDetailsList, actions.payload]
                        : [],
                },
            };
        },

        movementManualMaterialSub: (state = initialState, action) => {
            const id = get(action, "payload.id", null);
            if (action.payload.type === ACTION.ADD) {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        spisanieSMDetailsList: !isEmpty(get(action, "payload.data", []))
                            ? [
                                ...state.formData.data.spisanieSMDetailsList,
                                action.payload.data,
                            ]
                            : [],
                    },
                };
            } else if (action.payload.type === ACTION.UPDATE) {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        spisanieSMDetailsList: state.formData.data.spisanieSMDetailsList.map(
                            (item: detailsManual) =>
                                item.materialId == id
                                    ?
                                    get(action, "payload.data", {})
                                    : item
                        ),
                    },
                };
            } else if (action.payload.type === ACTION.DELETE) {

                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        spisanieSMDetailsList: state.formData.data.spisanieSMDetailsList.filter(
                            (item: detailsManual) => item.materialId !== id
                        ),
                    },
                };
            }
            else {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        spisanieSMDetailsList: [],
                    },
                };
            }
        },
        filterChangeManualMaterials: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
        clearFormDataManual: (state) => { state.formData = initialState.formData },
        clearAllManualMaterialDetails: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchManualMaterials.pending, (state) => {
            state.manualMaterials = {
                loading: true,
            };
        });
        builder.addCase(fetchManualMaterials.fulfilled, (state, actions) => {
            state.manualMaterials = {
                loading: false,
                data: get(actions, "payload.data", []),
                pagination: get(actions, "payload.pagination", {}),
                error: "",
            };
        });
        builder.addCase(fetchManualMaterials.rejected, (state, actions) => {
            state.manualMaterials = {
                loading: false,
                data: [],
                pagination: {},
                error: actions.error.message,
            };
        });

        builder.addCase(fetchNotFilledManualMaterials.pending, (state) => {
            state.notFilledList = {
                loading: true,
            };
        });
        builder.addCase(
            fetchNotFilledManualMaterials.fulfilled,
            (state, actions) => {
                state.notFilledList = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchNotFilledManualMaterials.rejected,
            (state, actions) => {
                state.notFilledList = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                };
            }
        );
        //  fetch temp
        builder.addCase(fetchManualMaterialsByTempId.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(
            fetchManualMaterialsByTempId.fulfilled,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                    isTempActive: true,
                };
            }
        );
        builder.addCase(
            fetchManualMaterialsByTempId.rejected,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                    isTempActive: false,
                };
            }
        );
        // fetch id
        builder.addCase(fetchManualMaterialsById.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(fetchManualMaterialsById.fulfilled, (state, actions) => {
            state.formData = {
                loading: false,
                data: get(actions, "payload.data", []),
                error: "",
                isTempActive: true,
            };
        });
        builder.addCase(fetchManualMaterialsById.rejected, (state, actions) => {
            state.formData = {
                loading: false,
                data: [],
                error: actions.error.message,
                isTempActive: false,
            };
        });

        // fetching sale contracts statists
        builder.addCase(fetchManualMaterialsStat.pending, (state) => {
            state.statusMaterials = {
                loading: true,
            };
        });
        builder.addCase(fetchManualMaterialsStat.fulfilled, (state, actions) => {
            state.statusMaterials = {
                loading: false,
                data: get(actions, "payload.data", {}),
                error: "",
            };
        });
        builder.addCase(fetchManualMaterialsStat.rejected, (state, actions) => {
            state.statusMaterials = {
                loading: false,
                data: {},
                error: actions.error.message,
            };
        });
    },
});

export const {
    addSubjectsManual,
    filterChangeManualMaterials,
    clearFormDataManual,
    movementManualMaterialSub,
    clearAllManualMaterialDetails,
} = manualMaterialSlice.actions;
export default manualMaterialSlice.reducer;
