import styled from "styled-components";
import { css } from "styled-components";
import { StyledProps } from "./type";

const TitleWrapper = styled.h2`
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 500;
  padding: ${({ padding }: StyledProps) => padding || "0px"};
  margin: ${({ margin }: StyledProps) => margin || "0px"};
  ${({ lg }: StyledProps) =>
    lg &&
    css`
      font-size: 32px;
    `};
  ${({ md }: StyledProps) =>
    md &&
    css`
      font-size: 24px;
    `};
  ${({ sm }: StyledProps) =>
    sm &&
    css`
      font-size: 20px;
    `};
    ${({ nm }: StyledProps) =>
    nm &&
    css`
      font-size: 18px;
    `};
  ${({ mk }: StyledProps) =>
    mk &&
    css`
      font-size: 14px;
    `};
  ${({ gray }: StyledProps) =>
    gray &&
    css`
      color: #585757;
    `};
  ${({ bold }: StyledProps) =>
    bold &&
    css`
      font-weight: 700;
    `}

${({ inter }: StyledProps) =>
    inter &&
    css`
      font-family: "Inter";
    `}
  @media (max-width: 1600px) {
    /* font-size: 18px; */
  }
`;

export { TitleWrapper };
