import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { steps } from "framer-motion";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: any = {
  filter: {
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  data: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    data: {
      details: [],
    },
  },
  statistic: {
    loading: false,
    data: {},
    error: "",
  },
  notFilledList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchChangeProduct = createAsyncThunk(
  "changeProduct/fetchChangeProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeProduct(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchChangeProductById = createAsyncThunk(
  "changeProduct/fetchChangeProductById",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeProductById(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchTempChangeProduct = createAsyncThunk(
  "changeProduct/fetchTempChangeProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeProductTemp(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchTempChangeProductById = createAsyncThunk(
  "changeProduct/fetchTempChangeProductById",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeProductTempById(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchChangeProductStat = createAsyncThunk(
  "changeProduct/fetchChangeProductStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeProductStat();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const changeProduct = createSlice({
  name: "changeProduct",
  initialState,
  reducers: {
    filterChangeProduct: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFormChangeProduct: (state) => {
      state.formData = initialState.formData;
    },

    movementChangeProductSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: !isEmpty(get(action, "payload.data", []))
              ? [...state.formData.data.details, action.payload.data]
              : [],
          },
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.map((item: any) =>
              item.tovarId == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.filter(
              (item: any) => item.tovarId !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: [],
          },
        };
      }
    },
    clearFilterChangeProduct: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChangeProduct.pending, (state) => {
      state.data = {
        loading: true,
      };
    });
    builder.addCase(fetchChangeProduct.fulfilled, (state, actions) => {
      state.data = {
        loading: false,
        data: get(actions, "payload.data", {}),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchChangeProduct.rejected, (state, actions) => {
      state.data = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    //  fetching by id
    builder.addCase(fetchChangeProductById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchChangeProductById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchChangeProductById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: {},
        error: "",
        isTempActive: false,
      };
    });
    // fetch temp by id
    builder.addCase(fetchTempChangeProductById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchTempChangeProductById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchTempChangeProductById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: {},
        error: "",
        isTempActive: false,
      };
    });
    // temp
    builder.addCase(fetchTempChangeProduct.pending, (state) => {
      state.notFilledList = {
        loading: true,
      };
    });
    builder.addCase(fetchTempChangeProduct.fulfilled, (state, actions) => {
      state.notFilledList = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchTempChangeProduct.rejected, (state, actions) => {
      state.notFilledList = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
    // statistics
    builder.addCase(fetchChangeProductStat.pending, (state) => {
      state.statistic = {
        loading: true,
      };
    });
    builder.addCase(fetchChangeProductStat.fulfilled, (state, actions) => {
      state.statistic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchChangeProductStat.rejected, (state, actions) => {
      state.statistic = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  filterChangeProduct,
  movementChangeProductSubject,
  clearFormChangeProduct,
  clearFilterChangeProduct,
} = changeProduct.actions;
export default changeProduct.reducer;
