import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";

const initialState: any = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    territoriyaId: null,
    kontragentId: null,
    dataIsp: null,
  },
};

export const fetchWorkOrder = createAsyncThunk(
  "registerWorkOrder/fetchWorkOrder",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchWorkOrder(params);
      const respond = await request.data;
      return respond;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const registerWorkOrderSlices = createSlice({
  name: "registerWorkOrder",
  initialState,
  reducers: {
    filterWorkOrder: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = {
        ...state.filter,
        [name]: value,
      };
    },
    clearWorkOrder: () => initialState,
    changeWorkOrder: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkOrder.pending, (state: any) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchWorkOrder.fulfilled, (state: any, action: any) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchWorkOrder.rejected, (state: any, payload: any) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const { filterWorkOrder, clearWorkOrder, changeWorkOrder } = registerWorkOrderSlices.actions;
export default registerWorkOrderSlices.reducer;
