import { useAppDispatch, useAppSelector } from "app/hook";

import { roundOrderData, roundOrderFilter } from "app/states/enumerations";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  LoadingTable,
  Nodata,
  Title,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import EnumerationsServices from "services/apiServices/perechislenya";
import icon from "assets/images/icons/action_icon.svg";
import {
  fetchRoundOrder,
  fetchRoundOrderMethods,
} from "app/slices/enumerationsSlices/roundOrderSlices/roundOrderSlices";
import { RoundOrderDataType } from "app/slices/enumerationsSlices/roundOrderSlices/type";
import {
  getRoundOrderDoData,
  getRoundOrderMethodsData,
} from "app/states/enumerations/selectStates";
import { indexNumber } from "utilities/helpers";

//  Тип договора
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const dispatch = useAppDispatch();
  const methods = useAppSelector(getRoundOrderMethodsData);
  const roundBefore = useAppSelector(getRoundOrderDoData);

  const getRoundMethods = useCallback(async () => {
    try {
      const mater = await EnumerationsServices.GetRoundMethods();
    } catch (error) {
      console.log(error);
    }
  }, []);
  const onSubmit = () => {
    try {
      EnumerationsServices.AddNewRoundOrder({
        data: values,
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        if (res && res.data) {
          handleCloseModal();
          afterSubmitAction();
        }
      });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      okruglineyaMetod: "",
      imyaOkruglineya: "",
      okruglyatDo: null,
    },
    onSubmit,
  });
  useEffect(() => {
    dispatch(fetchRoundOrderMethods());
  }, [dispatch]);
  useEffect(() => {
    getRoundMethods();
  }, [getRoundMethods]);
  return (
    <>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Наименования</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                id={"imyaOkruglineya"}
                value={get(values, "imyaOkruglineya", "")}
                handleInput={(e: string) => setFieldValue("imyaOkruglineya", e)}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4}>
              <Title>Округлять до</Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"okruglyatDo"}
                isSearched
                value={get(values, "okruglyatDo", "")}
                options={roundBefore}
                handleChange={(e: any) => {
                  setFieldValue("okruglyatDo", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4}>
              <Title>Метод округления</Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"okruglineyaMetod"}
                isSearched
                value={get(values, "okruglineyaMetod", "")}
                options={methods}
                handleChange={(e: any) => {
                  setFieldValue("okruglineyaMetod", e);
                }}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              Добавить
            </Button>
            <Button onClick={handleCloseModal} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const RoundOrderContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(roundOrderData);
  const filter = useAppSelector(roundOrderFilter);
  let LOADING = get(data, "loading", false);
  const handleFilter = useCallback(() => {
    dispatch(fetchRoundOrder(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const fetchAllRounds = useCallback(() => {
    dispatch(fetchRoundOrder(filter));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  return (
    <Row style={{ minHeight: "100%" }}>
      <Col xs={12}>
        <Content className={"full_height"}>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllRounds()}
            />
          </ReactModal>
          <Row>
            <Col>
              <BaseTable
                dark
                lg
                tableHeader={[
                  "№",
                  "Пользователь",
                  "Наименования",
                  "Округлять до",
                ]}
              >
                {isEqual(LOADING, false) ? (
                  <>
                    {!isEmpty(get(data, "dataList", [])) ? (
                      get(data, "dataList", []).map(
                        (item: RoundOrderDataType, index: number) => (
                          <tr key={get(item, "id", 0)}>
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  data,
                                  "pagination.currentPageNumber",
                                  1
                                ),
                                pageSize: get(data, "pagination.pageSize", 10),
                                index: index + 1,
                              })}
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "imyaOkruglineya", "-")}</td>
                            <td>{get(item, "okruglyatDo", "-")}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <Nodata colLength={4} />
                    )}
                  </>
                ) : (
                  map([...new Array(5)], (_, index: number) => (
                    <tr key={index + 1}>
                      <LoadingTable
                        width={index % 2 == 0 ? "50px" : "70px"}
                        tblDTNumber={4}
                      />
                    </tr>
                  ))
                )}
              </BaseTable>
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default RoundOrderContainer;
