import { memo } from "react";
import { ReactSVG } from "react-svg";
import { Title } from "components"
import inbox from "assets/images/icons/inbox.svg"
const Nodata = ({ colLength, ...props }: { colLength: number }) => {
    return (
        <tr>
            <td colSpan={colLength} style={{ opacity: 0.6 , border:"none"}}>
                <ReactSVG src={inbox}
                    style={{
                        fontSize: "8vh",
                        opacity: 0.5
                    }}
                />
                <Title mk>Нет данных</Title>
            </td>
        </tr>
    );
};

export default memo(Nodata);
