import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterDetails,
  clearFormDataReturnMaterials,
  fetchNotFilledReturnMaterials,
  fetchReturnMaterials,
  fetchReturnMaterialsStat,
  filterChangeReturnMaterials,
} from "app/slices/documentSlices/returnMaterialSlices/returnMaterialSlices";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchOwnSkladCM } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  returnMaterialsFilter,
  returnMaterialsList,
  saleContractStatReturnMaterials,
  saleTempReturnMaterialsList,
} from "app/states/documents";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import { getSkladCM } from "app/states/handbooks/selectStates";
import delate from "assets/images/icons/delate.svg";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
// Возврат сырья и материалов
const ReturnMaterialsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_RETURN_MATERIAL
  );
  const returnMaterialData = useAppSelector(returnMaterialsList);
  const tempReturnMaterial = useAppSelector(saleTempReturnMaterialsList);
  const filter = useAppSelector(returnMaterialsFilter);
  const countStat = useAppSelector(saleContractStatReturnMaterials);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const LOADING_TABLE = get(returnMaterialData, "loading", []);
  const skladOptions = useAppSelector(getSkladCM);
  let loadingKontragent = useAppSelector(loadingConterpart);
  const fetchReturnMaterialData = useCallback(() => {
    dispatch(fetchReturnMaterials(filter));
    dispatch(fetchReturnMaterialsStat());
    dispatch(fetchOwnSkladCM({}));
  }, [dispatch, filter]);

  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchReturnMaterials({
        ...filter,
        skladId: null,
        fromDate: null,
        toDate: null,
        kontragentId: null,
      })
    );
    dispatch(clearFilterDetails());
  }, [dispatch, filter]);

  const fetchNotFilledReturnMaterialsData = useCallback(() => {
    dispatch(fetchNotFilledReturnMaterials());
    dispatch(fetchReturnMaterialsStat());
  }, [dispatch]);

  const navigateForm = (params: { id: string | number; isTemp: boolean }) => {
    navigate(Documents.ADD_RETURN_MATERIAL, {
      state: params,
    });
  };

  const handleAddButton = () => {
    dispatch(clearFormDataReturnMaterials());
    dispatch(clearContractorsState());
    navigate(Documents.ADD_RETURN_MATERIAL);
  };

  const handleTempButton = (id: string | null) => {
    DocumentServices.RemoveTempsReturnMaterials(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchReturnMaterialData();
        fetchNotFilledReturnMaterialsData();
      }
    });
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteReturnMaterials(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchReturnMaterials(filter));
        dispatch(fetchReturnMaterialsStat());
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsReturnMaterials(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchReturnMaterials(filter));
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsReturnMaterials(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchReturnMaterials(filter));
      }
    });
  };
  useEffect(() => {
    fetchNotFilledReturnMaterialsData();
  }, [fetchNotFilledReturnMaterialsData]);
  useEffect(() => {
    dispatch(fetchAllCounterpart({ all: true }));
    return () => {
      handleClearFilter();
    };
  }, []);
  useEffect(() => {
    fetchReturnMaterialData();
  }, [filter.page, filter.size]);

  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={countStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button
                className={"mr-16"}
                danger
                handleClick={handleClearFilter}
              >
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "VOZVRAT_SM_CREATE",
                    page: "VOZVRAT_SM",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="200px"
                  isClearable
                  isLoading={loadingKontragent}
                  placeholder={"Контрагент"}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeReturnMaterials({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width="200px"
                  isClearable
                  options={skladOptions}
                  placeholder="Склад"
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeReturnMaterials({
                        name: "skladId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "skladId", null)}
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeReturnMaterials({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeReturnMaterials({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={2}>
              <Button primary handleClick={fetchReturnMaterialData}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Склад сырья иматериалов",
                    "Контрагент",
                    "Договор",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      <ContextMenu
                        handleNavigate={navigateForm}
                        deleteDocument={handleDeleteButton}
                        deleteItem={true}
                        markItem={true}
                        uncheckItem={true}
                        userPermission={"VOZVRAT_SM"}
                        handleDeleteRecords={handleDeleteRecords}
                        handleUndoRecords={handleUndoRecords}
                        documentInfo={documentInfo}
                      />
                      {!isEmpty(get(tempReturnMaterial, "data", [])) &&
                        get(tempReturnMaterial, "data", []).map(
                          (item: any, index: number) => (
                            <tr
                              key={get(item, "id", index + 1)}
                              onClick={() =>
                                navigateForm({
                                  isTemp: true,
                                  id: get(item, "id", ""),
                                })
                              }
                              className="cursor-pointer"
                            >
                              <td>
                                <div
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    handleTempButton(get(item, "id", null));
                                  }}
                                  className="delete_button"
                                >
                                  <ReactSVG
                                    src={delate}
                                    className="delete_button"
                                  />
                                </div>
                              </td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDoc", "-")}</td>
                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "imyaSklada", "")}</td>
                              <td>{get(item, "imyaKontragent", "")}</td>
                              <td>{get(item, "nomerDogPokupka")}</td>
                              <td>
                                {!isEmpty(get(item, "createdAt", ""))
                                  ? moment(get(item, "createdAt", "")).format(
                                      "DD.MM.YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td>{get(item, "status", "-")}</td>
                            </tr>
                          )
                        )}

                      {!isEmpty(get(returnMaterialData, "data", []))
                        ? get(returnMaterialData, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", "")}
                                onClick={() =>
                                  navigateForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      returnMaterialData,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      returnMaterialData,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "-")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaSklada", "")}</td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "nomerDogPokupka")}</td>
                                <td>
                                  {moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(tempReturnMaterial, "data", [])) && (
                            <Nodata colLength={11} />
                          )}
                    </>
                  ) : (
                    map([...new Array(9)], (item, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={9}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(returnMaterialData, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(returnMaterialData, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      filterChangeReturnMaterials({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    returnMaterialData,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(
                    returnMaterialData,
                    "pagination.totalPages",
                    0
                  )}
                  onPageChange={({ selected }) =>
                    dispatch(
                      filterChangeReturnMaterials({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default ReturnMaterialsContainer;
