import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    vidTovaraId: null,
    kontragentId: null,
    detalno: true,
    skladId: null,
  },
};

export const fetchOperationalBalance = createAsyncThunk(
  "operationalBalanceSlices/fetchOperationalBalance",
  async (params: any, thunkAPI) => {
    try {
      const request =
        await ReportsServices.FetchOperationalProductBalance(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const operationalProductBalanceSlices = createSlice({
  name: "operationalBalanceSlices",
  initialState,
  reducers: {
    filterOperationalBalanceTable: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = { ...state.filter, [name]: value };
    },
    clearOperationalBalanceTable: () => initialState,
    changeOperationalBalanceTable: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperationalBalance.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchOperationalBalance.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchOperationalBalance.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const {
  filterOperationalBalanceTable,
  clearOperationalBalanceTable,
  changeOperationalBalanceTable,
} = operationalProductBalanceSlices.actions;
export default operationalProductBalanceSlices.reducer;
