import { createSelector } from "@reduxjs/toolkit";
import { selectProfileData } from "app/selectors/profile";
import { get } from "lodash";

export const profileData = createSelector(selectProfileData, (data) => data);

export const departmentList = createSelector(
    selectProfileData,
    (data) => 
    get(data, "profileData.permissionList", []).map((item: any) => (get(item, "department", "")))
);
export const pagesList = createSelector(
    selectProfileData,
    (data) => get(data, "profileData", []))

export const dogPermissions = createSelector(
    selectProfileData,
    (data)=> get(data, "profileData.permissionList", [])
)