import { RootState } from "app/store";

export const selectPaymentMethod = (store: RootState) =>
  store.enumeration.paymentMethod.data;
export const selectPaymentMethodFilter = (store: RootState) =>
  store.enumeration.paymentMethod.filter;

export const selectDeliveryMethod = (store: RootState) =>
  store.enumeration.deliveryMethod.data;
export const selectDeliveryMethodFilter = (store: RootState) =>
  store.enumeration.deliveryMethod.filter;

export const selectIncome = (store: RootState) => store.enumeration.income.data;
export const selectIncomeFilter = (store: RootState) =>
  store.enumeration.income.filter;

export const selectTypeFlow = (store: RootState) =>
  store.enumeration.typeFlow.data;
export const selectTypeFlowFilter = (store: RootState) =>
  store.enumeration.typeFlow.filter;

export const selectTypeWriteOff = (store: RootState) =>
  store.enumeration.typeWriteOff.data;
export const selectTypeWriteOffFilter = (store: RootState) =>
  store.enumeration.typeWriteOff.filter;

export const selectLegalPhysPerson = (store: RootState) =>
  store.enumeration.legalPhysPerson.data;
export const selectLegalPhysPersonFilter = (store: RootState) =>
  store.enumeration.legalPhysPerson.filter;

export const selectTypeContract = (store: RootState) =>
  store.enumeration.typeContract.data;
export const selectTypeContractFilter = (store: RootState) =>
  store.enumeration.typeContract.filter;

export const selectRoundOrder = (store: RootState) =>
  store.enumeration.roundOrder.data;
export const selectRoundOrderFilter = (store: RootState) =>
  store.enumeration.roundOrder.filter;

export const selectTypeSubstandard = (store: RootState) =>
  store.enumeration.typeSubstandard.data;
export const selectTypeSubstandardFilter = (store: RootState) =>
  store.enumeration.typeSubstandard.filter;

export const selectRoundOrderMethods = (store: RootState) =>
  store.enumeration.roundOrder.methods;