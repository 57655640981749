import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { Col, Row } from "react-grid-system";
import { OperationalReportTable } from "../components";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  operationalReportFilter,
  operationalReportList,
} from "app/states/reports";
import { useCallback, useEffect, useState } from "react";
import {
  changeOperanationalTable,
  clearOperationalTable,
  fetchOperationalReport,
  filterOperationalTable,
} from "app/slices/reportSlices/operationalReportSlices/operationalReportSlices";
import { getSkladCM, getWarHouseData } from "app/states/handbooks/selectStates";
import moment from "moment";
import { fetchOwnSkladCM } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { get, isEmpty, isEqual } from "lodash";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
import { contractorOptions } from "app/states/documents/selectStates";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};
interface ParamState {
  sklad?: string | null;
  kontragent?: string | number | null;
  tipMaterial?: string | null;
}
// Оперативный отчет по остаткам сырья и материалов
const OperationalReportsContainer = () => {
  const dispatch = useAppDispatch();
  // data
  const data = useAppSelector(operationalReportList);
  const filter = useAppSelector(operationalReportFilter);
  const getWareHouse = useAppSelector(getSkladCM);
  const LOADING_TABLE = get(data, "loading", false);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const [check, setCheck] = useState(false);
  const fetchAllOperationalReports = useCallback(() => {
    if (filter.skladId) dispatch(fetchOperationalReport(filter));
  }, [dispatch, filter]);
  const [details, setDetails] = useState<ParamState>({
    kontragent: null,
    sklad: null,
    tipMaterial: null,
  });
  const handleClearFilter = () => {
    setCheck(false);
    dispatch(clearOperationalTable());
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeOperanationalTable(null));
    }

    dispatch(filterOperationalTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnSkladCM({}));
    dispatch(fetchAllCounterpart({ all: true }));
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Оперативный отчет по остаткам сырья и материалов ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", check ? "H1" : `E1`);
    sheet.getCell(
      "A1"
    ).value = `Оперативный отчет по остаткам сырья и материалов на ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    const arr = [
      {
        label: "Тип материал: " + get(details, "tipMaterial", null),
        value: get(details, "tipMaterial", null),
      },
      {
        label: "Заказчик: " + get(details, "kontragent", null),
        value: get(details, "kontragent", null),
      },
      {
        label: "Склад: " + get(details, "sklad", null),
        value: get(details, "sklad", null),
      },
    ];
    let count = 1;
    arr.forEach((val: any, index: number) => {
      if (val.value) {
        sheet.mergeCells(
          `A${count + 1}`,
          check ? `H${1 + count}` : `E${1 + count}`
        );
        sheet.getCell(`A${1 + count}`).value = val.label;
        count++;
      }
    });
    if (!check) {
      sheet.getRow(1).height = 20;
      sheet.mergeCells(`A${count + 2}`, `A${count + 3}`);
      sheet.getCell(`A${count + 2}`).value = "№";
      sheet.mergeCells(`B${count + 2}`, `B${count + 3}`);
      sheet.getCell(`B${count + 2}`).value = "Материал";
      sheet.mergeCells(`C${count + 2}`, `C${count + 3}`);
      sheet.getCell(`C${count + 2}`).value = "Единица измерения";
      sheet.mergeCells(`D${count + 2}`, `E${count + 2}`);
      sheet.getCell(`D${count + 2}`).value = `Остаток на ${get(
        filter,
        "toDate",
        ""
      )}`;
      sheet.getCell(`D${count + 3}`).value = "Количество";
      sheet.getCell(`E${count + 3}`).value = "Сумма";
    } else {
      sheet.getRow(1).height = 20;
      sheet.mergeCells(`A${count + 2}`, `A${count + 3}`);
      sheet.getCell(`A${count + 2}`).value = "№";
      sheet.mergeCells(`B${count + 2}`, `B${count + 3}`);
      sheet.getCell(`B${count + 2}`).value = "Материал";
      sheet.mergeCells(`C${count + 2}`, `C${count + 3}`);
      sheet.getCell(`C${count + 2}`).value = "Единица измерения";
      sheet.mergeCells(`D${count + 2}`, `D${count + 3}`);
      sheet.getCell(`D${count + 2}`).value = "Заказчик";
      sheet.mergeCells(`E${count + 2}`, `E${count + 3}`);
      sheet.getCell(`E${count + 2}`).value = "Заказ-наряд";
      sheet.mergeCells(`F${count + 2}`, `F${count + 3}`);
      sheet.getCell(`F${count + 2}`).value = "Договор";
      sheet.mergeCells(`G${count + 2}`, `H${count + 2}`);
      sheet.getCell(`G${count + 2}`).value = `Остаток на ${get(
        filter,
        "toDate",
        ""
      )}`;
      sheet.getCell(`G${count + 3}`).value = "Количество";
      sheet.getCell(`H${count + 3}`).value = "Сумма";
    }

    const tableHeader = [
      {
        header: "Код",
        key: "index",
        width: 5,
      },
      {
        header: "Наименованиесчета",
        key: "imyaMaterial",
        width: 60,
      },
      {
        header: "Дебет",
        key: "imyaEdIzm",
        width: 30,
      },
      {
        header: "Kredit",
        key: "ostatokKolichestva",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "ostatokSumma",
        width: 30,
      },
    ];

    if (check) {
      tableHeader.splice(
        3,
        0,
        {
          header: "Заказчик",
          key: "kontragentImya",
          width: 30,
        },
        {
          header: "Заказ-наряд",
          key: "nomerZakazNaryad",
          width: 30,
        },
        {
          header: "Договор",
          key: "nomerDogProdaja",
          width: 30,
        }
      );
    }

    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    // sheet.getRow(5).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "operationalList.list", [])?.forEach((item, index) => {
      const headerRow1 = sheet.getRow(index + 4);

      // headerRow1.height = 25;
      // headerRow1.alignment = { vertical: "middle", horizontal: "center" };
      if (!check) {
        sheet.addRow({
          index: index + 1,
          imyaScheta: get(item, "imyaScheta", ""),
          imyaMaterial: get(item, "imyaMaterial", ""),
          imyaEdIzm: get(item, "imyaEdIzm", ""),
          ostatokKolichestva: Number(get(item, "ostatok.kolichestva", 0)),
          ostatokSumma: Number(get(item, "ostatok.summa", 0)),
        });
        counterRow++;
      } else {
        sheet.addRow({
          index: index + 1,
          imyaScheta: get(item, "imyaScheta", ""),
          imyaMaterial: get(item, "imyaMaterial", ""),
          imyaEdIzm: get(item, "imyaEdIzm", ""),
          kontragentImya: "ㅤ",
          nomerZakazNaryad: "ㅤ",
          nomerDogProdaja: "ㅤ",
          ostatokKolichestva: Number(get(item, "ostatok.kolichestva", 0)),
          ostatokSumma: Number(get(item, "ostatok.summa", 0)),
        });
        counterRow++;
        get(item, "details", []).forEach((detail: any) => {
          const row = sheet.addRow({
            index: "ㅤ",
            imyaScheta: get(detail, "imyaScheta", ""),
            imyaMaterial: get(detail, "imyaMaterial", ""),
            imyaEdIzm: get(detail, "imyaEdIzm", ""),
            kontragentImya: get(detail, "kontragentImya", "ㅤ"),
            nomerZakazNaryad: get(detail, "nomerZakazNaryad", "ㅤ"),
            nomerDogProdaja: get(detail, "nomerDogProdaja", "ㅤ"),
            ostatokKolichestva: Number(get(detail, "kolichestva", 0)),
            ostatokSumma: Number(get(detail, "summa", 0)),
          });
          counterRow++;
          row.eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
            };
          });
        });
      }
    });
    sheet.mergeCells(
      `A${counterRow + count + 3}`,
      check ? `F${counterRow + count + 3}` : `C${counterRow + count + 3}`
    );
    sheet.getCell(`A${counterRow + count + 3}`).value = "Итого:";
    sheet.getCell(
      check ? `G${counterRow + count + 3}` : `D${counterRow + count + 3}`
    ).value = Number(get(data, "operationalList.itogo.OstatokKol", 0));
    sheet.getCell(
      check ? `H${counterRow + count + 3}` : `E${counterRow + count + 3}`
    ).value = Number(get(data, "operationalList.itogo.OstatokSum", ""));

    //  sheet.getRow(counterRow+3).height = 25;
    //  sheet.getRow(counterRow+3).alignment = { vertical: "middle", horizontal: "center" };
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),
      `A${counterRow + count + 3}`,
      `E${counterRow + count + 3}`,
      `D${counterRow + count + 3}`,
      `H${counterRow + count + 3}`,
      `G${counterRow + count + 3}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      check ? `H${counterRow + count + 3}` : `E${counterRow + count + 3}`
    );
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach((item: any) => {
      sheet.getCell(item).font = {
        size: 11,
        bold: true,
      };
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <BaseSelect
                  width={"250px"}
                  options={getWareHouse}
                  placeholder="Склад"
                  value={get(filter, "skladId", "")}
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "skladId",
                      value: e.value,
                      clear: true,
                    });
                    setDetails({ ...details, sklad: e.label });
                  }}
                />

                <DatePicker
                  width="150px"
                  placeholder="Дата"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "operationalList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllOperationalReports}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width={"250px"}
                  options={[
                    { label: "Сырье и материалы", value: "SM" },
                    { label: "Полуфабрикаты", value: "PF" },
                  ]}
                  placeholder="Тип"
                  value={get(filter, "tipMaterial", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.tipMaterial === e.value) {
                      return;
                    }
                    if (e.value == "SM") {
                      setCheck(false);
                      setDetails({ ...details, kontragent: "" });
                    }
                    setDetails({ ...details, tipMaterial: e.label });
                    handleChangeFilter({
                      name: "tipMaterial",
                      value: e.value,
                    });
                    if (data.operationalList === null) return;
                    dispatch(
                      fetchOperationalReport({
                        ...filter,
                        tipMaterial: e.value,
                      })
                    );
                  }}
                />
                {isEqual(get(filter, "tipMaterial", null), "PF") && (
                  <>
                    <BaseSelect
                      width={"250px"}
                      placeholder={"Контрагент"}
                      value={get(filter, "kontragentId", "")}
                      options={contractorDropdowns}
                      handleValueLabel={(e: any) => {
                        if (filter.kontragentId === e.value) return;

                        handleChangeFilter({
                          name: "kontragentId",
                          value: e.value,
                        });

                        setDetails({
                          ...details,
                          kontragent: get(e, "label", ""),
                        });

                        if (data.operationalList === null) return;
                        dispatch(
                          fetchOperationalReport({
                            ...filter,
                            kontragentId: e.value,
                          })
                        );
                      }}
                    />
                    <Title
                      xx
                      className="title_style"
                      mk
                      style={{ justifyContent: "left" }}
                    >
                      Детально{" "}
                      <Checkbox
                        handleChange={() => {
                          handleChangeFilter({
                            name: "detalno",
                            value: !check,
                          });
                          setCheck(!check);
                        }}
                      />
                    </Title>
                  </>
                )}
              </FlexBox>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>

          {!LOADING_TABLE ? (
            <>
              {data.operationalList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={
                      "Оперативный отчет по остаткам сырья и материалов"
                    }
                  />

                  {!isEmpty(get(data, "operationalList.list", [])) ? (
                    <OperationalReportTable
                      data={data}
                      click={check}
                      filter={filter}
                      toDate={get(filter, "toDate", "")}
                    />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default OperationalReportsContainer;
