import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterBankAccounts,
  fetchAllBanksAccounts,
  handleChangeBankAccounts,
} from "app/slices/handbookSlices/bankAccountsSlice/bankAccountsSlice";
import { BankAccountDataType } from "app/slices/handbookSlices/bankAccountsSlice/type";
import {
  fetchAllBanks,
  handleChangeFilter,
} from "app/slices/handbookSlices/bankSlices/bankSlices";
import {
  allBanksAccountsData,
  allBanksAccountsFilter,
} from "app/states/handbooks";
import { getallBanks } from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BaseTable,
  Button,
  Content,
  Form,
  FormSelect,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";

import { NumericFormat, PatternFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { PROTECTED_TYPE, MESSAGES, PAGE } from "utilities/constants";
import { bankAccountsSchema } from "utilities/schema/spravochnikSchemas";
// Банковские счета
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: BankAccountDataType;
}) => {
  const allBanks = useAppSelector(getallBanks);
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateBankAccounts({
          data: {
            ...values,
            dataOtkrScheta: moment(
              get(values, "dataOtkrScheta", new Date())
            ).format("DD.MM.YYYY"),
            dataZakrScheta: moment(
              get(values, "dataZakrScheta", new Date())
            ).format("DD.MM.YYYY"),
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          setSubmitting(false);
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewBankAccounts({
          data: {
            ...values,
            dataOtkrScheta: moment(
              get(values, "dataOtkrScheta", new Date())
            ).format("DD.MM.YYYY"),
            dataZakrScheta: moment(
              get(values, "dataZakrScheta", new Date())
            ).format("DD.MM.YYYY"),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          setSubmitting(false);
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
      resetForm();
    } catch (error) {
      setSubmitting(false);
      console.log("error", error);
    }
  };
  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imya: get(details, "imya", ""),
      nomerScheta: get(details, "nomerScheta", ""),
      bankImya: get(details, "imyaBanka", ""),
      bankId: get(details, "bankId", null),
    },
    validationSchema: bankAccountsSchema,
    onSubmit,
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchAllBanks({ all: true }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Input
            label={"Наименование счета"}
            value={get(values, "imya", null)}
            handleInput={(e: string) => {
              setFieldValue("imya", e);
            }}
            required={true}
          />
          <Input
            label={"Номер счета"}
            value={get(values, "nomerScheta", null)}
            handleInput={(e: string) => {
              setFieldValue("nomerScheta", e);
            }}
            required={true}
          />
          <FormSelect
            id={"bankId"}
            label={"МФО"}
            className={"mt-24"}
            isSearchable
            value={get(values, "bankId", "")}
            options={allBanks}
            handleValueLabel={(e: any) => {
              setFieldValue("bankId", get(e, "value", null));
              setFieldValue("bankImya", get(e, "imya", ""));
            }}
            required={true}
          />
          <Input
            label={"Наименование банка"}
            value={get(values, "bankImya", null)}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green loading={isSubmitting}>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const BankAccountsContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const filter = useAppSelector(allBanksAccountsFilter);
  const data = useAppSelector(allBanksAccountsData);
  let LOADING = get(data, "loading", false);
  const [details, setDetails] = useState<BankAccountDataType>({});
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleChangeBankAccounts({
          name,
          value,
        })
      );
    },
    [dispatch]
  );
  const handleFilter = useCallback(() => {
    dispatch(fetchAllBanksAccounts({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  const clearFilter = useCallback(() => {
    dispatch(
      fetchAllBanksAccounts({ ...filter, search: "", InAscOrder: false })
    );
    dispatch(clearFilterBankAccounts());
  }, [dispatch, filter]);

  const fetchAllBanksData = useCallback(() => {
    dispatch(fetchAllBanksAccounts({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setDetails({});
  }, []);

  const handleOpenModal = useCallback((data: BankAccountDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteBankAccounts(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllBanksData();
      }
    });
  };

  useEffect(() => {
    return () => {
      clearFilter();
    };
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "BANK_SCHET_CREATE",
                    page: "BANK_SCHET",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "") || ""}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllBanksData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименование счета",
                    "Номер счета",

                    "Наименование банка",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "bankAccounts", [])) ? (
                        get(data, "bankAccounts", []).map(
                          (item: BankAccountDataType, index: number) => (
                            <tr key={get(item, "id")}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"BANK_SCHET"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imya", "-")}</td>
                              <td>
                                {
                                  <PatternFormat
                                    format="####  #### ### ####"
                                    displayType="text"
                                    value={get(item, "nomerScheta", 0)}
                                  />
                                }
                              </td>
                              <td>{get(item, "imyaBanka", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={6} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={6}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e: string) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default BankAccountsContainer;
