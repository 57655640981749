import { useAppDispatch, useAppSelector } from "app/hook"
import { useCallback, useEffect } from "react"
import { typeRoleFilter, typeRoleList } from "app/states/users"
import { ActionBase, BaseInput, BaseTable, Button, Content, FlexBox, LoadingTable, Nodata, Pagination, ShowOnPage, WindowHeight } from "components"
import { get, includes, isEmpty, map } from "lodash"
import { Col, Row } from "react-grid-system"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Users } from "routers/paths"
import { MESSAGES, TABLE } from "utilities/constants"
import { clearFilterTypeRole, clearTypeData, fetchTypeRole, filterChangeTypeRole } from "app/slices/usersSlices/typeRoleSlices/typeRoleSlices"
import UsersServices from "services/apiServices/users"
import { toast } from "react-toastify"
import { indexNumber } from "utilities/helpers"

const TypeRoleContainer = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const typeRoleData = useAppSelector(typeRoleList)
    const filter = useAppSelector(typeRoleFilter)
    const LOADING_TABLE = get(typeRoleData, "loading", false)
    const isAddForm = includes(
        get(location, "pathname", ""),
        Users.ADD_TYPE_ROLE
    );
    const handleAddButton = () => {
        navigate(Users.ADD_TYPE_ROLE)
    }
    const handleUpdateButton = (props: any) => {
        navigate(Users.ADD_TYPE_ROLE, {
            state: { id: props.id, name: props.name }
        });
    }

    const handleDeleteButton = (id: number | string | null) => {
        UsersServices.DeleteTypeRole(id).then((res) => {
            if (res.status == 200) {
                toast.success(MESSAGES.DELETED)
                dispatch(fetchTypeRole(filter))
            }
        })
    }
    const handleClearSearch = useCallback(() => {
        dispatch(fetchTypeRole({ ...filter, search: "" }))
        dispatch(clearTypeData())
    }, [filter])

    const fetchTypeRoleData = useCallback(
        () => {
            dispatch(fetchTypeRole(filter))

        }, [dispatch, filter])

    useEffect(() => {
        fetchTypeRoleData()
    }, [filter.page, filter.size])
    useEffect(() => {
        return () => {
            dispatch(clearFilterTypeRole())
        }
    }, [])
    if (isAddForm) {
        return <Outlet />;
    }
    return (
        <Row>
            <Col xs={12}>
                <Content className={"full_height"}>
                    <Row align="center" className="mb-16">
                        <Col className="text-right">
                            <Button green handleClick={handleAddButton}>{TABLE.ADD}</Button>
                        </Col>
                    </Row>
                    <Row className={"py-16 border"}>
                        <Col xs={8}>
                            <FlexBox gap={"10px"}>
                                <BaseInput
                                    placeholder="Поиск"
                                    handleInput={(e: any) => {
                                        dispatch(
                                            filterChangeTypeRole({
                                                name: "search",
                                                value: e,
                                            })
                                        )
                                    }}
                                    isSearchable
                                    value={get(filter, "search", "")}
                                />
                            </FlexBox>
                        </Col>
                        <Col className={"text-right"}>
                            <Button primary className={"mr-16"}
                                handleClick={fetchTypeRoleData}
                            >
                                {TABLE.APPLY}
                            </Button>
                            <Button danger
                                handleClick={handleClearSearch}
                            >{TABLE.RESET}</Button>
                        </Col>
                    </Row>
                    {/* table */}
                    <Row>
                        <Col>
                            <WindowHeight>
                                <BaseTable className="table_scroll" tableHeader={[
                                    "№",
                                    "Пользователь",
                                    "Тип роли",
                                    "Действие"
                                ]}>
                                    {
                                        !LOADING_TABLE ?
                                            <>
                                                {
                                                    !isEmpty(get(typeRoleData, "data", [])) ? (
                                                        get(typeRoleData, "data", []).map((item, index: number) => (
                                                            <tr key={get(item, "id", null)}>
                                                                <td>{indexNumber({
                                                                    currentPage: get(typeRoleData, "pagination.currentPageNumber", 0),
                                                                    pageSize: get(typeRoleData, "pagination.pageSize", 10),
                                                                    index: index + 1
                                                                })}</td>
                                                                <td>{get(item, "createdBy", "-")}</td>
                                                                <td>{get(item, "roleName", "-")}</td>
                                                                <td>
                                                                    <ActionBase
                                                                        isShowDelete={get(item, "deletable", false)}
                                                                        handleUpdate={
                                                                            handleUpdateButton
                                                                        }
                                                                        handleDelete={() => handleDeleteButton(get(item, "id", null))}
                                                                        id={{ id: get(item, "id", null), name: get(item, "roleName", "") }}
                                                                    /></td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <Nodata colLength={4} />
                                                    )
                                                }
                                            </> :
                                            map([...new Array(10)], (item, index) => (
                                                <tr key={index + 1}>
                                                    <LoadingTable
                                                        width={
                                                            index % 2 == 0 ? "50px" : "70px"
                                                        }
                                                        tblDTNumber={4}
                                                    />
                                                </tr>
                                            ))
                                    }
                                </BaseTable>
                            </WindowHeight>

                        </Col>
                    </Row>
                    {/* {get(typeRoleData, "pagination.totalPages", 0) > 0 && ( */}
                    <Row className="mt-16">
                        <Col xs={6}>
                            <ShowOnPage

                                pageSize={get(
                                    typeRoleData,
                                    "pagination.pageSize",
                                    10
                                )}
                                handlePageChange={(e) =>
                                    dispatch(
                                        filterChangeTypeRole({
                                            name: "size",
                                            value: e,
                                        })
                                    )
                                }
                            />
                        </Col>
                        <Col xs={6}>
                            <Pagination
                                current={get(
                                    typeRoleData,
                                    "pagination.currentPageNumber",
                                    0
                                )}
                                pageCount={get(
                                    typeRoleData,
                                    "pagination.totalPages",
                                    0
                                )}
                                onPageChange={({ selected }) =>
                                    dispatch(
                                        filterChangeTypeRole({
                                            name: "page",
                                            value: selected,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    {/* )} */}
                </Content >
            </Col >
        </Row >
    )
}

export default TypeRoleContainer