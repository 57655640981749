import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterOtherCost,
  clearOtherExpenses,
  fetchAllOtherProdCosts,
  fetchAllOtherProdCostsStat,
  fetchAllOtherProdCostsTemp,
  handleFilterChange,
} from "app/slices/documentSlices/otherProdCostSlice/otherProdCostSlice";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  otherCostFilter,
  otherCostStatic,
  otherCostsTemps,
  otherProdCosts,
} from "app/states/documents";
import { getTerritoriesData } from "app/states/handbooks/selectStates";
import delate from "assets/images/icons/delate.svg";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";

// Ввод прочих производственных расходов

const OtherExpensesContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(otherCostFilter);
  const otherProdCost = useAppSelector(otherProdCosts);
  const statistic = useAppSelector(otherCostStatic);
  const otherCostsTemp = useAppSelector(otherCostsTemps);
  const territoriesOption = useAppSelector(getTerritoriesData);
  const LOADING_TABLE = get(otherProdCost, "loading", []);

  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_OTHER_EXPENSES
  );
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchOtherProdCosts = useCallback(() => {
    dispatch(fetchAllOtherProdCosts(filter));
    dispatch(fetchAllOtherProdCostsStat());
  }, [dispatch, filter]);

  const fetchOtherProdCostsTemp = useCallback(() => {
    dispatch(fetchAllOtherProdCostsTemp());
  }, [dispatch]);

  const clearFilter = useCallback(() => {
    dispatch(clearFilterOtherCost());
    fetchOtherProdCosts();
  }, [dispatch, filter]);

  const handleTempRemove = (id: string | null) => {
    DocumentServices.RemoveOtherCostTemp(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchOtherProdCostsTemp();
      }
    });
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.RemoveDeleteCost(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchOtherProdCosts();
      }
    });
  };
  const navigateOtherProdCost = (param: { id: string; isTemp: boolean }) => {
    navigate(Documents.ADD_OTHER_EXPENSES, {
      state: param,
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsOtherProdCostById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllOtherProdCosts(filter));
        dispatch(fetchAllOtherProdCostsStat());
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsOtherProdCostById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllOtherProdCosts(filter));
        dispatch(fetchAllOtherProdCostsStat());
      }
    });
  };
  useEffect(() => {
    fetchOtherProdCosts();
  }, []);

  useEffect(() => {
    fetchOtherProdCostsTemp();
    return () => {
      clearFilter();
    };
  }, []);
  const handleAddButton = () => {
    dispatch(clearOtherExpenses());
    navigate(Documents.ADD_OTHER_EXPENSES);
  };

  // for adding  new form
  if (isAddForm) {
    return <Outlet />;
  }
  // main content
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={statistic} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button className={"mr-16"} danger handleClick={clearFilter}>
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "PROCHI_RASXOD_CREATE",
                    page: "PROCHI_RASXOD",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="200px"
                  isClearable
                  options={territoriesOption}
                  placeholder="Территория"
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "territoriyaId",
                        value: e,
                      })
                    );
                  }}
                  value={get(filter, "territoriyaId", null)}
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className={"text-right"}>
              <Button primary handleClick={fetchOtherProdCosts}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Территория",
                    "Время записи",
                    "Статус",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      <ContextMenu
                        handleNavigate={navigateOtherProdCost}
                        deleteDocument={handleDeleteButton}
                        deleteItem={true}
                        markItem={true}
                        uncheckItem={true}
                        userPermission={"RUCHNOY_SPISANIYA"}
                        handleDeleteRecords={handleDeleteRecords}
                        handleUndoRecords={handleUndoRecords}
                        documentInfo={documentInfo}
                      />
                      {/* otherCostsTemp */}
                      {!isEmpty(get(otherCostsTemp, "data.data", [])) &&
                        get(otherCostsTemp, "data.data", []).map(
                          (item: any, index: any) => (
                            <tr
                              onClick={() =>
                                navigateOtherProdCost({
                                  isTemp: true,
                                  id: get(item, "id", ""),
                                })
                              }
                              className="cursor-pointer"
                              key={index + 1}
                            >
                              <td>
                                <div
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    handleTempRemove(get(item, "id", null));
                                  }}
                                >
                                  <ReactSVG
                                    src={delate}
                                    className={"delete_button svg_icon"}
                                  />
                                </div>
                              </td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDoc", "")}</td>
                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "imyaTerritorii", "")}</td>
                              <td>-</td>
                              <td>{get(item, "status", "-")}</td>
                            </tr>
                          )
                        )}
                      {!isEmpty(get(otherProdCost, "data.data", []))
                        ? get(otherProdCost, "data.data", []).map(
                            (item: any, index: any) => (
                              <tr
                                key={index + 1}
                                onClick={() =>
                                  navigateOtherProdCost({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      otherProdCost,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      otherProdCost,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>

                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaTerritorii", "")}</td>
                                <td>
                                  {moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(otherCostsTemp, "data.data", [])) && (
                            <Nodata colLength={10} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={10}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(otherProdCost, "data.pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    handleFilterChange({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(
                  otherProdCost,
                  "data.pagination.currentPageNumber"
                )}
                pageCount={get(otherProdCost, "data.pagination.totalPages", 1)}
                onPageChange={({ selected }) =>
                  dispatch(
                    handleFilterChange({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default OtherExpensesContainer;
