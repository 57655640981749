import React, { useState, useCallback, useEffect } from "react";

import { get, isEmpty, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "app/hook";
import { getCounterpartData } from "app/states/handbooks/selectStates";
import { getPaymentMethodData } from "app/states/enumerations/selectStates";
import {
  registerPurchasesList,
  registerPurchasesFilter,
} from "app/states/reports";
import ExcelJS from "exceljs";
import { Col, Row } from "react-grid-system";
import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import moment from "moment";
import ReportInfoTitle from "../components/ReportInfoTitle";
import RegisterPurchasesMaterialsTable from "../components/RegisterPurchasesMaterialsTable";
import TableContentLoader from "../louders/TableContentLoader";
import {
  changePurchasesTable,
  clearPurchasesTable,
  fetchRegisterPurchases,
  filterPurchasesTable,
} from "app/slices/reportSlices/registerPurchaseSlices/registerPurchaseSlices";
import NotFound from "../components/NotFound";

type ParamsType = {
  name?: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  kontragent?: string;
  sposobOplata?: string;
}

// Реестр договоров на покупку сырья и материалов

function RegisterPurchasesContainer() {
  const dispatch = useAppDispatch();

  // data
  const data = useAppSelector(registerPurchasesList);
  const filter = useAppSelector(registerPurchasesFilter);

  const paymentDropdowns = useAppSelector(getPaymentMethodData);
  const counterpartDropdowns = useAppSelector(getCounterpartData);

  const LOADING_TABLE = get(data, "loading", []);

  // details
  const [details, setDetails] = useState<ParamState>({
    kontragent: "",
    sposobOplata: "",
  });

  const fetchReportShipmentData = useCallback(() => {
    dispatch(fetchRegisterPurchases(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearPurchasesTable());
    setDetails({ kontragent: "", sposobOplata: "" });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changePurchasesTable(null));
    }

    dispatch(filterPurchasesTable({ ...filter, name, value }));
  };

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Реестр договоров на покупку сырья и материалов ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `I1`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "kontragent", ""),
        label: `Заказчик: ${get(details, "kontragent", "")}`,
      },
      {
        value: get(details, "sposobOplata", ""),
        label: `Способ оплаты: ${get(details, "sposobOplata", "")}`,
      },
      {
        value: get(details, "sposobDastafki", ""),
        label: `Способ доставки: ${get(details, "sposobDastafki", "")}`,
      },
      {
        value: get(details, "dateIsp", ""),
        label: `Дата исполнения договора: ${get(details, "dateIsp", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `Реестр договоров на покупку сырья и материалов с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(`A${1 + index + 1}`, `I${1 + index + 1}`);
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Номер договора",
        key: "nomerDoc",
        width: 30,
      },
      {
        header: "Дата договора",
        key: "dataDoc",
        width: 25,
      },
      {
        header: "Заказчик",
        key: "imyaKontragent",
        width: 35,
      },
      {
        header: "Дата испол",
        key: "dataIsp",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "sposobOplataName",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "imyaUsloviyaOplati",
        width: 20,
      },
      {
        header: "Способ доставки",
        key: "sposobDostavkaName",
        width: 30,
      },
      {
        header: "Сумма",
        key: "summa",
        width: 20,
      },
    ];
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList.list", [])?.forEach((item, index) => {
      sheet.addRow({
        order: index + 1,
        nomerDoc: get(item, "nomerDoc", ""),
        dataDoc: get(item, "dataDoc", ""),
        imyaKontragent: get(item, "imyaKontragent", ""),
        dataIsp: get(item, "dataIsp", ""),
        sposobOplataName: get(item, "sposobOplataName", ""),
        imyaUsloviyaOplati: get(item, "imyaUsloviyaOplati", ""),
        sposobDostavkaName: get(item, "sposobDostavkaName", ""),
        summa: get(item, "summaDog", "") ? Number(get(item, "summaDog", 0)) : 0,
      });
      counterRow++;
    });
    sheet.mergeCells(
      `A${counterRow + count + 2}`,
      `H${counterRow + count + 2}`
    );
    sheet.getCell(`A${counterRow + count + 2}`).value = "Итого:";
    sheet.getCell(`I${counterRow + count + 2}`).value = Number(
      get(data, "dataList.itogo.summa", 0)
    );

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`I${counterRow + count + 2}`);
    // const startCell = worksheet.getCell('A1');
    // const endCell = worksheet.getCell('K17');

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "fromDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "toDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>

            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchReportShipmentData}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  width={"200px"}
                  options={counterpartDropdowns}
                  placeholder="Заказчик"
                  value={get(filter, "kontragentId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.kontragentId === e.value) return;

                    handleChangeFilter({
                      name: "kontragentId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      kontragent: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchRegisterPurchases({
                        ...filter,
                        kontragentId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width={"200px"}
                  options={paymentDropdowns}
                  placeholder="Способ оплаты"
                  value={get(filter, "sposobOplataId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.sposobOplataId === e.value) return;

                    handleChangeFilter({
                      name: "sposobOplataId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      sposobOplata: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchRegisterPurchases({
                        ...filter,
                        sposobOplataId: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Реестр договоров на покупку сырья и материалов"}
                  />

                  {!isEmpty(get(data, "dataList.list", [])) ? (
                    <RegisterPurchasesMaterialsTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default RegisterPurchasesContainer;
