import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  clearDeliveryAddress,
  fetchAllDeliveryAddress,
  handleDeliveryAddressFilter,
} from "app/slices/handbookSlices/deleveryAddressSlices/deliveryAddressSlices";
import { DeliveryAddressDataType } from "app/slices/handbookSlices/deleveryAddressSlices/type";
import { contractorOptions } from "app/states/documents/selectStates";
import {
  allDeliveryAddressData,
  allDeliveryAddressFilter,
} from "app/states/handbooks";
import { getCounterpartData } from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  FormSelect,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { deliveryAddress } from "utilities/schema/spravochnikSchemas";
// Адреса доставки
export const AddAddressModal = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  kontragentIdCore,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  kontragentIdCore?: string | null;
  details?: DeliveryAddressDataType;
}) => {
  const onSubmit = () => {
    try {
      if (details?.id) {
        HandBookServices.UpdateDeliveryAddress({
          data: { ...values, id: get(details, "id", null) },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewDeliveryAddress({
          data: values,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
      resetForm();
    } catch (err) {
      console.log("Error", err);
    }
  };
  const dispatch = useAppDispatch();
  const getCounterparts = useAppSelector(getCounterpartData);
  const handleClearClose = () => {
    resetForm();
    handleCloseModal();
  };
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      korotkoeImenaAddress: get(details, "korotkoeImenaAddress", ""),
      polnoeImenaAddress: get(details, "polnoeImenaAddress", null),
      kontragentId: get(details, "kontragentId", kontragentIdCore),
    },
    enableReinitialize: true,
    validationSchema: deliveryAddress,
    onSubmit,
  });
  useEffect(() => {
    dispatch(fetchAllCounterpart({ all: true }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            label={"Контрагенте"}
            id={"kontragentId"}
            isSearchable
            value={get(values, "kontragentId", "")}
            options={getCounterparts}
            isDisabled={kontragentIdCore}
            handleChange={(e: any) => {
              setFieldValue("kontragentId", e);
            }}
            required={true}
          />
          <Input
            label={"Короткое наименование адреса"}
            value={get(values, "korotkoeImenaAddress", "") || ""}
            required={true}
            handleInput={(e: string) => {
              setFieldValue("korotkoeImenaAddress", e);
            }}
          />
          <Input
            label={"Полное наименование адреса"}
            value={get(values, "polnoeImenaAddress", "") || ""}
            handleInput={(e: string) => {
              setFieldValue("polnoeImenaAddress", e);
            }}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button onClick={handleClearClose} danger>
              {PAGE.CLOSE}
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const DeliveryAddressesContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const contractorDropdowns = useAppSelector(contractorOptions);
  const data = useAppSelector(allDeliveryAddressData);
  let LOADING = get(data, "loading", false);
  const filter = useAppSelector(allDeliveryAddressFilter);
  const [dietails, setDietails] = useState<DeliveryAddressDataType | {}>({});
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleDeliveryAddressFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchAllDeliveryAddress({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  const clearFilter = useCallback(() => {
    dispatch(
      fetchAllDeliveryAddress({
        ...filter,
        kontragentId: null,
        InAscOrder: false,
      })
    );
    dispatch(clearDeliveryAddress());
  }, [dispatch, filter]);

  const fetchDeliveryAddressData = useCallback(() => {
    dispatch(fetchAllDeliveryAddress({ ...filter, InAscOrder: false }));
  }, [dispatch]);
  // delete
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteDeliveryAddress(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        handleFilter();
      }
    });
  };
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: DeliveryAddressDataType) => {
    setDietails(data);
    setOpenModal(true);
  }, []);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  useEffect(() => {
    dispatch(fetchAllCounterpart({ all: true }));
    return () => {
      clearFilter();
    };
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "ADDRESS_DOSTAVKI_CREATE",
                    page: "ADDRESS_DOSTAVKI",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={6}>
              <BaseSelect
                placeholder={"Поиск по Контрагент"}
                value={get(filter, "kontragentId", "")}
                width={"250px"}
                options={contractorDropdowns}
                handleChange={(e: any) => {
                  dispatchFilter({
                    name: "kontragentId",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"} xs={6}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddAddressModal
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchDeliveryAddressData()}
              details={dietails}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Контрагент",
                    "Короткое наименование адреса",
                    "Полное наименование адреса",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "dataList", [])) ? (
                        get(data, "dataList", []).map(
                          (item: DeliveryAddressDataType) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"ADDRESS_DOSTAVKI"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imyaKontragent", "-")}</td>
                              <td>{get(item, "korotkoeImenaAddress", "-")}</td>
                              <td>{get(item, "polnoeImenaAddress", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={6} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={6}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default DeliveryAddressesContainer;
