import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, ReturnProductBefReal } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
  countStatProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      details: [],
    },
  },
  productOptions: {
    loading: false,
    data: [],
    error: ""
  },
  filter: {
    fromDate: null,
    toDate: null,
    tovarId: null,
    kontragentId: null,
    page: 0,
    size: 10,
  },
  returnProduct: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  notFilledList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchReturnProductBefReal = createAsyncThunk(
  "returnProductBefReal/fetchReturnProductBefReal",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReturnProductBefReal(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchNotFilledReturnProductBefReal = createAsyncThunk(
  "returnProductBefReal/fetchNotFilledReturnProductBefReal",
  async (params, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledReturnProductBefReal(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReturnProductBefRealByTempId = createAsyncThunk(
  "returnProductBefReal/fetchReturnProductBefRealByTempId",
  async (id: string, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledReturnProductBefRealById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReturnProductBefRealById = createAsyncThunk(
  "returnProductBefReal/fetchReturnProductBefRealById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReturnProductBefRealById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReturnProductBefRealStat = createAsyncThunk(
  "returnProductBefReal/fetchReturnProductBefRealStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReturnProductBefRealStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchProductBefRealOptions = createAsyncThunk(
  "returnProductBefReal/fetchProductBefRealOptions",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReturnProductBefRealStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const returnProductBefRealSlices = createSlice({
  name: "returnProductBefReal",
  initialState,
  reducers: {
    addSubjectReturnProductBefReal: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          details: !isEmpty(get(actions, "payload", {}))
            ? [...state.formData.data.details, actions.payload]
            : [],
        },
      };
    },

    movementReturnMaterialBefRealSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.map(
              (item: ReturnProductBefReal) =>
                item.tovarId == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.filter(
              (item: ReturnProductBefReal) => item.tovarId !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: [],
          },
        };
      }
    },
    filterChangeReturnProductBefReal: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFilterBefReal: (state) => {
      state.filter = initialState.filter;
    },
    clearFormDataReturnProductBefReal: () => initialState,
    clearAllReturnProductBefReal: (state) => {
      state.formData = initialState.formData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReturnProductBefReal.pending, (state) => {
      state.returnProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchReturnProductBefReal.fulfilled, (state, actions) => {
      state.returnProduct = {
        loading: false,
        data: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchReturnProductBefReal.rejected, (state, actions) => {
      state.returnProduct = {
        loading: false,
        data: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    builder.addCase(fetchNotFilledReturnProductBefReal.pending, (state) => {
      state.notFilledList = {
        loading: true,
      };
    });
    builder.addCase(
      fetchNotFilledReturnProductBefReal.fulfilled,
      (state, actions) => {
        state.notFilledList = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchNotFilledReturnProductBefReal.rejected,
      (state, actions) => {
        state.notFilledList = {
          loading: false,
          data: [],
          error: actions.error.message,
        };
      }
    );
    //  fetch temp
    builder.addCase(fetchReturnProductBefRealByTempId.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(
      fetchReturnProductBefRealByTempId.fulfilled,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
          isTempActive: true,
        };
      }
    );
    builder.addCase(
      fetchReturnProductBefRealByTempId.rejected,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: [],
          error: actions.error.message,
          isTempActive: false,
        };
      }
    );
    // fetch id
    builder.addCase(fetchReturnProductBefRealById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(
      fetchReturnProductBefRealById.fulfilled,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
          isTempActive: true,
        };
      }
    );
    builder.addCase(
      fetchReturnProductBefRealById.rejected,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: [],
          error: actions.error.message,
          isTempActive: false,
        };
      }
    );

    // fetching statists
    builder.addCase(fetchReturnProductBefRealStat.pending, (state) => {
      state.countStatProduct = {
        loading: true,
      };
    });
    builder.addCase(
      fetchReturnProductBefRealStat.fulfilled,
      (state, actions) => {
        state.countStatProduct = {
          loading: false,
          data: get(actions, "payload.data", {}),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchReturnProductBefRealStat.rejected,
      (state, actions) => {
        state.countStatProduct = {
          loading: false,
          data: {},
          error: actions.error.message,
        };
      }
    );
    // fetch product options
    builder.addCase(fetchProductBefRealOptions.pending, (state) => {
      state.countStatProduct = {
        loading: true,
      };
    });
    builder.addCase(
      fetchProductBefRealOptions.fulfilled,
      (state, actions) => {
        state.countStatProduct = {
          loading: false,
          data: get(actions, "payload.data", {}),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchProductBefRealOptions.rejected,
      (state, actions) => {
        state.countStatProduct = {
          loading: false,
          data: [],
          error: actions.error.message,
        };
      }
    );
  },
});

export const {
  addSubjectReturnProductBefReal,
  filterChangeReturnProductBefReal,
  clearFormDataReturnProductBefReal,
  clearAllReturnProductBefReal,
  movementReturnMaterialBefRealSubject,
  clearFilterBefReal,
} = returnProductBefRealSlices.actions;
export default returnProductBefRealSlices.reducer;
