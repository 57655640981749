import styled from "styled-components";

export const HomeWrapper = styled.div`
  .active_line {
    padding: 10px;
    border-radius: 10px;

    &:hover {
      background: linear-gradient(
        rgba(211, 211, 211, 1) 0%,
        rgba(225, 225, 225, 0.34226190476190477) 0%
      );
    }
  }
  .clicked {
    padding: 10px;
    border-radius: 10px;
    background: linear-gradient(
      rgba(211, 211, 211, 1) 0%,
      rgba(225, 225, 225, 0.34226190476190477) 0%
    );
  }
`;
