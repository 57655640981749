import { map } from "lodash";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LoadingTable = ({ width, tblDTNumber, ...props }: any) => {
  const arr = new Array(tblDTNumber);
  return (
    <>
      <SkeletonTheme
        baseColor="#ededed"
        highlightColor="#f6f6f6"
        {...props}
        style={{ zIndex: 1 }}
      >
        {map([...arr], (_, index) => (
          <td key={index + 1} className="p-16">
            <Skeleton height="12px" width={width} className="my-4 px-10" />
          </td>
        ))}
      </SkeletonTheme>
    </>
  );
};

export default LoadingTable;
