import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { Col, Row } from "react-grid-system";
import { AnalysisExecutionPagesTable } from "modules/reports/components";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  analysisExecutionFilter,
  analysisExecutionList,
} from "app/states/reports";
import { useCallback, useEffect, useState } from "react";
import {
  changeAnalysisExecution,
  clearAnalysisExecution,
  clearFilterFromDate,
  fetchAnalysisExecution,
  filterAnalysisExecution,
} from "app/slices/reportSlices/analysisExecutionSlices/analysisExecutionSlices";
import {
  contractorOptions,
  orderRegisterOptions,
} from "app/states/documents/selectStates";
import { fetchOrderRegister } from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import moment from "moment";
import { get, isEmpty, isEqual } from "lodash";
import { orderRegisterList } from "app/states/documents";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";

type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

// Оперативный отчет по анализу выполнения Заказ-наряда
const AnalysisExecutionContainer = () => {
  const dispatch = useAppDispatch();

  // data
  const data = useAppSelector(analysisExecutionList);
  const filter = useAppSelector(analysisExecutionFilter);
  const orderRegister = useAppSelector(orderRegisterOptions);
  const dataAllOrders = useAppSelector(orderRegisterList);
  const LOADING_TABLE = get(data, "loading", false);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const [order, setOrder] = useState<any>();
  const fetchAllAnalysisExecutions = useCallback(() => {
    if (filter.zakazNaryadId) {
      dispatch(fetchAnalysisExecution(filter));
    }

    findDataOrder();
  }, [dispatch, filter]);

  const handleClearFilter = () => {
    dispatch(clearAnalysisExecution());
  };
  const findDataOrder = useCallback(() => {
    if (get(filter, "zakazNaryadId", null)) {
      const isFindOrder: any = get(dataAllOrders, "data", []).find(
        (item: any) =>
          get(item, "id", null) == get(filter, "zakazNaryadId", null)
      );

      setOrder({
        nomerDoc: get(isFindOrder, "nomerDoc", ""),
        kontragentImya: get(isFindOrder, "kontragentImya", ""),
        dataDoc: get(isFindOrder, "dataDoc", ""),
        dateIsp: get(isFindOrder, "dateIsp", ""),
        imyaTerritoriya: get(isFindOrder, "imyaTerritoriya", ""),
        imyaSposobDostavka: get(isFindOrder, "imyaSposobDostavka", ""),
        sposobOplataName: get(isFindOrder, "sposobOplataName", ""),
        otmVipolneniya: get(isFindOrder, "otmVipolneniya", 0),
        ...isFindOrder,
      });
    }
  }, [filter, filter.kontragentId, filter.zakazNaryadId]);
  useEffect(() => {
    findDataOrder();
    // dispatch(clearFilterFromDate());
    if (get(filter, "kontragentId", "")) {
      dispatch(
        fetchOrderRegister({
          all: true,
          isInAscOrder: false,
          kontragentId: get(filter, "kontragentId", ""),
        })
      );
    }
  }, [filter.zakazNaryadId, filter.kontragentId]);
  // excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Оперативный отчет по анализу выполнения Заказ-наряда ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `F1`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };
    const detailsArray = [
      {
        value: get(order, "kontragentImya", ""),
        label: `Покупатель: ${get(order, "kontragentImya", "")}`,
      },
      {
        value: get(order, "nomerDoc", ""),
        label: `Заказ-наряд: ${get(order, "nomerDoc", "")}`,
      },
      {
        value: get(order, "imyaTerritoriya", ""),
        label: `Территория: ${get(order, "imyaTerritoriya", "")}`,
      },
      {
        value: get(order, "dateIsp", ""),
        label: `Дата исполнения: ${get(order, "dateIsp", "")}`,
      },
      {
        value: get(order, "imyaSposobDostavka", ""),
        label: `Способ доставки: ${get(order, "imyaSposobDostavka", "")}`,
      },
      {
        value: "123",
        label: `Статус выполнения: ${
          get(order, "otmVipolneniya", "") ? "Да" : "Нет"
        }`,
      },
    ];
    const zakazNaryat = [
      "Остаток авансового платежа на начало дня",
      "Полуфабрикаты на складе",
      "Готовая продукция на складе",
      "Отгружено готовой продукции",
      "Реализовано готовой продукции",
      "Возврат отгруженного товара",
      "Реализовано готовой продукции",
      `Задолженность по Заказ-наряду ${get(order, "nomerDoc", "")} от ${moment(
        get(order, "dataDoc", "")
      ).format("DD.MM.YYYY")}`,
      "	Общая задолженность покупателя Остатка Каракамиш на конец дня",
    ];
    // names of companies
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `Оперативный отчет по анализу выполнения Заказ-наряда с ${moment(
      get(order, "dataDoc", 0)
    ).format("DD.MM.YYYY")}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(`A${1 + index + 1}`, `${"F" + (1 + index + 1)}`);
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    sheet.mergeCells("A9", "A10");
    sheet.getCell("A9").value = "№";
    sheet.mergeCells("B9", "B10");
    sheet.getCell("B9").value = "Наименование строк";
    sheet.mergeCells("C9", "C10");
    sheet.getCell("C9").value = "Наименование ТМЦ";
    sheet.mergeCells("D9", "D10");
    sheet.getCell("D9").value = "Единица измерения";
    sheet.mergeCells("E9", "F9");
    sheet.getCell("E9").value = `Остаток на  ${get(filter, "fromDate", "")}`;
    sheet.getCell("E10").value = "Количество";
    sheet.getCell("F10").value = "Сумма";
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    const headerRow1 = sheet.getRow(count + 3);
    headerRow1.height = 25;
    for (let columnNumber = 1; columnNumber <= 6; columnNumber++) {
      sheet.getColumn(columnNumber).width = 30; // O'zgartirish kerak kenglikni
    }
    headerRow1.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;

    const arr1 = 12 + get(data, "analysisList.AVANS", "").length;
    const arr2 = get(data, "analysisList.POLUFABRIKAT", "").length;
    const arr3 = get(data, "analysisList.GOTOVAYA_PRODUKTSIYA", "").length;
    const arr4 = get(data, "analysisList.OTGRUZKA", "").length;
    const arr5 = get(data, "analysisList.REALIZATSIYA", "").length;
    const arr6 = get(data, "analysisList.VOZVRAT", "").length;
    const arr7 = get(data, "analysisList.REALIZATSIYA2", "").length;
    const arr8 = get(data, "analysisList.ZADOLJENNOST_ZN", "").length;
    const arr9 = get(data, "analysisList.ZADOLJENNOST", "").length;
    sheet.mergeCells("A11", `A${12}`);
    sheet.getCell("A11").value = "1";
    sheet.mergeCells("B11", "D11");
    sheet.getCell("B11").value = zakazNaryat[0];
    get(data, "analysisList.AVANS", [])?.forEach((item: any, index: number) => {
      sheet.getCell(`C${arr1 + index - 1}`).value = get(item, "imya", "");
      sheet.getCell(`D${arr1 + index - 1}`).value = get(item, "edIzm", "");
      sheet.getCell(`E${arr1 + index - 1}`).value = Number(
        get(item, "ostKol", 0)
      );
      sheet.getCell(`F${arr1 + index - 1}`).value = Number(
        get(item, "ostSumma", 0)
      );
    });
    sheet.mergeCells(`A${arr1}`, `A${arr1 + arr2}`);
    sheet.getCell(`A${arr1}`).value = "2";
    sheet.mergeCells(`B${arr1}`, `D${arr1}`);
    sheet.getCell(`B${arr1}`).value = zakazNaryat[1];
    get(data, "analysisList.POLUFABRIKAT", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(`C${arr1 + index + 1}`).value = get(item, "imya", "");
        sheet.getCell(`D${arr1 + index + 1}`).value = get(item, "edIzm", "");
        sheet.getCell(`E${arr1 + index + 1}`).value = Number(
          get(item, "ostKol", "")
        );
        sheet.getCell(`F${arr1 + index + 1}`).value = Number(
          get(item, "ostSumma", 0)
        );
      }
    );
    sheet.mergeCells(`A${arr1 + arr2 + 1}`, `A${arr1 + arr2 + 1 + arr3}`);
    sheet.getCell(`A${arr1 + arr2 + 1}`).value = "3";
    sheet.mergeCells(`B${arr1 + arr2 + 1}`, `D${arr1 + arr2 + 1}`);
    sheet.getCell(`C${arr1 + arr2 + 1}`).value = zakazNaryat[2];
    get(data, "analysisList.GOTOVAYA_PRODUKTSIYA", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(`C${arr1 + arr2 + index + 2}`).value = get(
          item,
          "imya",
          ""
        );
        sheet.getCell(`D${arr1 + arr2 + index + 2}`).value = get(
          item,
          "edIzm",
          ""
        );
        sheet.getCell(`E${arr1 + arr2 + index + 2}`).value = Number(
          get(item, "ostKol", 0)
        );
        sheet.getCell(`F${arr1 + arr2 + index + 2}`).value = Number(
          get(item, "ostSumma", 0)
        );
      }
    );
    sheet.mergeCells(
      `A${arr1 + arr2 + arr3 + 2}`,
      `A${arr1 + arr2 + 2 + arr3 + arr4}`
    );
    sheet.getCell(`A${arr1 + arr2 + arr3 + 2}`).value = "4";
    sheet.mergeCells(
      `B${arr1 + arr3 + arr2 + 2}`,
      `D${arr1 + arr2 + arr3 + 2}`
    );
    sheet.getCell(`B${arr1 + arr2 + arr3 + 2}`).value = zakazNaryat[3];
    get(data, "analysisList.OTGRUZKA", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(`C${arr1 + arr2 + arr3 + index + 3}`).value = get(
          item,
          "imya",
          ""
        );
        sheet.getCell(`D${arr1 + arr2 + arr3 + index + 3}`).value = get(
          item,
          "edIzm",
          ""
        );
        sheet.getCell(`E${arr1 + arr2 + arr3 + index + 3}`).value = Number(
          get(item, "ostKol", 0)
        );
        sheet.getCell(`F${arr1 + arr2 + arr3 + index + 3}`).value = Number(
          get(item, "ostSumma", 0)
        );
      }
    );
    sheet.mergeCells(
      `A${arr1 + arr2 + arr3 + arr4 + 3}`,
      `A${arr1 + arr2 + 3 + arr3 + arr5 + arr4}`
    );
    sheet.getCell(`A${arr1 + arr2 + arr3 + arr4 + 3}`).value = "5";
    sheet.mergeCells(
      `B${arr1 + arr3 + arr4 + arr2 + 3}`,
      `D${arr1 + arr2 + arr4 + arr3 + 3}`
    );
    sheet.getCell(`B${arr1 + arr2 + arr3 + arr4 + 3}`).value = zakazNaryat[4];
    get(data, "analysisList.REALIZATSIYA", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(`C${arr1 + arr2 + arr3 + arr4 + index + 4}`).value = get(
          item,
          "imya",
          ""
        );
        sheet.getCell(`D${arr1 + arr2 + arr3 + arr4 + index + 4}`).value = get(
          item,
          "edIzm",
          ""
        );
        sheet.getCell(`E${arr1 + arr2 + arr3 + arr4 + index + 4}`).value =
          Number(get(item, "ostKol", 0));
        sheet.getCell(`F${arr1 + arr2 + arr3 + arr4 + index + 4}`).value =
          Number(get(item, "ostSumma", 0));
      }
    );
    sheet.mergeCells(
      `A${arr1 + arr2 + arr3 + arr4 + arr5 + 4}`,
      `A${arr1 + arr2 + 4 + arr3 + arr5 + arr6 + arr4}`
    );
    sheet.getCell(`A${arr1 + arr2 + arr3 + arr4 + arr5 + 4}`).value = "6";
    sheet.mergeCells(
      `B${arr1 + arr3 + arr4 + arr2 + arr5 + 4}`,
      `D${arr1 + arr2 + arr4 + arr5 + arr3 + 4}`
    );
    sheet.getCell(`B${arr1 + arr2 + arr3 + arr4 + arr5 + 4}`).value =
      zakazNaryat[5];
    get(data, "analysisList.VOZVRAT", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(
          `C${arr1 + arr2 + arr3 + arr4 + arr5 + index + 5}`
        ).value = get(item, "imya", "");
        sheet.getCell(
          `D${arr1 + arr2 + arr3 + arr4 + arr5 + index + 5}`
        ).value = get(item, "edIzm", "");
        sheet.getCell(
          `E${arr1 + arr2 + arr3 + arr4 + arr5 + index + 5}`
        ).value = Number(get(item, "ostKol", 0));
        sheet.getCell(
          `F${arr1 + arr2 + arr3 + arr4 + arr5 + index + 5}`
        ).value = Number(get(item, "ostSumma", ""));
      }
    );
    sheet.mergeCells(
      `A${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + 5}`,
      `A${arr1 + arr2 + 5 + arr3 + arr5 + arr6 + arr4 + arr7}`
    );
    sheet.getCell(`A${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + 5}`).value =
      "7";
    sheet.mergeCells(
      `B${arr1 + arr3 + arr4 + arr2 + arr5 + arr6 + 5}`,
      `D${arr1 + arr2 + arr4 + arr5 + arr3 + arr6 + 5}`
    );
    sheet.getCell(`B${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + 5}`).value =
      zakazNaryat[6];
    get(data, "analysisList.REALIZATSIYA2", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(
          `C${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + index + 6}`
        ).value = get(item, "imya", "");
        sheet.getCell(
          `D${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + index + 6}`
        ).value = get(item, "edIzm", "");
        sheet.getCell(
          `E${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + index + 6}`
        ).value = Number(get(item, "ostKol", 0));
        sheet.getCell(
          `F${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + index + 6}`
        ).value = Number(get(item, "ostSumma", 0));
      }
    );
    sheet.mergeCells(
      `A${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + arr7 + 6}`,
      `A${arr1 + arr2 + 6 + arr3 + arr5 + arr6 + arr4 + arr7 + arr8}`
    );
    sheet.getCell(
      `A${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + arr7 + 6}`
    ).value = "8";
    sheet.mergeCells(
      `B${arr1 + arr3 + arr4 + arr2 + arr5 + arr6 + arr7 + 6}`,
      `D${arr1 + arr2 + arr4 + arr5 + arr3 + arr6 + arr7 + 6}`
    );
    sheet.getCell(
      `B${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + arr7 + 6}`
    ).value = zakazNaryat[7];
    get(data, "analysisList.ZADOLJENNOST_ZN", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(
          `C${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + index + 7}`
        ).value = get(item, "imya", "");
        sheet.getCell(
          `D${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + index + 7}`
        ).value = get(item, "edIzm", "");
        sheet.getCell(
          `E${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + index + 7}`
        ).value = Number(get(item, "ostKol", 0));
        sheet.getCell(
          `F${arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + index + 7}`
        ).value = Number(get(item, "ostSumma", 0));
      }
    );
    sheet.mergeCells(
      `A${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + arr7 + arr8 + 7}`,
      `A${arr1 + arr2 + 7 + arr3 + arr5 + arr6 + arr4 + arr7 + arr8 + arr9}`
    );
    sheet.getCell(
      `A${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + arr7 + arr8 + 7}`
    ).value = "9";
    sheet.mergeCells(
      `B${arr1 + arr3 + arr4 + arr2 + arr5 + arr7 + arr6 + arr8 + 7}`,
      `D${arr1 + arr2 + arr4 + arr5 + arr3 + arr6 + arr7 + arr8 + 7}`
    );
    sheet.getCell(
      `B${arr1 + arr2 + arr3 + arr4 + arr5 + arr6 + arr7 + arr8 + 7}`
    ).value = zakazNaryat[8];
    get(data, "analysisList.ZADOLJENNOST", [])?.forEach(
      (item: any, index: number) => {
        sheet.getCell(
          `C${
            arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + arr8 + index + 8
          }`
        ).value = get(item, "imya", "");
        sheet.getCell(
          `D${
            arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + arr8 + index + 8
          }`
        ).value = get(item, "edIzm", "");
        sheet.getCell(
          `E${
            arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + arr8 + index + 8
          }`
        ).value = Number(get(item, "ostKol", 0));
        sheet.getCell(
          `F${
            arr1 + arr2 + arr3 + arr4 + arr6 + arr5 + arr7 + arr8 + index + 8
          }`
        ).value = Number(get(item, "ostSumma", 0));
      }
    );
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "E" + (count + 3),
      "F" + (count + 3),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      `F${arr2 + arr1 + arr3 + arr4 + arr5 + arr6 + arr7 + arr8 + arr9 + 7}`
    );

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
        //
      }
      sheet.getCell(row, 1).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeAnalysisExecution(null));
    }

    dispatch(filterAnalysisExecution({ ...filter, name, value }));
  };

  useEffect(() => {
    findDataOrder();
    dispatch(fetchAllCounterpart({ filterPage: "ZN_ANALIZ", all: true }));
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width={"250px"}
                  placeholder={"Поиск по Контрагент"}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  handleChange={(e: any) => {
                    if (!isEqual(get(filter, "kontragentId", ""), e)) {
                      handleChangeFilter({
                        name: "zakazNaryadId",
                        value: null,
                        clear: true,
                      });
                    }
                    handleChangeFilter({
                      name: "kontragentId",
                      value: e,
                    });
                  }}
                />
                <BaseSelect
                  options={
                    get(filter, "kontragentId", false) ? orderRegister : []
                  }
                  placeholder="Заказ-Наряд"
                  value={get(filter, "zakazNaryadId", "")}
                  handleChange={(e: string) => {
                    handleChangeFilter({
                      name: "zakazNaryadId",
                      value: e,
                      clear: true,
                    });
                  }}
                />

                <DatePicker
                  placeholder="Дата"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "fromDate", "")}
                  minDate={moment(get(order, "dataDoc", "")).toDate()}
                  maxDate={new Date()}
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "analysisList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllAnalysisExecutions}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.analysisList !== null ? (
                <>
                  {!isEmpty(order) ? (
                    <Row className="mt-16">
                      <Col xs={6}>
                        <Title bold className="mb-8">
                          Оперативный отчет по анализу выполнения Заказ-наряда
                          на{" "}
                          {moment(get(order, "dataDoc", "")).format(
                            "DD.MM.YYYY"
                          )}
                        </Title>
                        <Title>Покупатель: {order.kontragentImya}</Title>
                        <Title>Заказ-наряд: {order.nomerDoc}</Title>
                        <Title>Территория: {order.imyaTerritoriya}</Title>
                        {/* <Title>Продукция: {order.sposobDastafki}</Title> */}
                        <Title>
                          Дата исполнения:{" "}
                          {moment(get(order, "dateIsp", "")).format(
                            "DD.MM.YYYY"
                          )}
                        </Title>
                        <Title>
                          Способ доставки: {order.imyaSposobDostavka}
                        </Title>
                        <Title>
                          Статус выполнения:{" "}
                          {isEqual(order.otmVipolneniya, 0) ? "Нет" : "Да"}
                        </Title>
                      </Col>
                    </Row>
                  ) : null}

                  {!isEmpty(get(data, "analysisList", [])) ? (
                    <AnalysisExecutionPagesTable
                      data={data}
                      order={order}
                      filter={filter}
                    />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default AnalysisExecutionContainer;
