import { get } from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function ReportOnDefectsTable({ data, click }: any) {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper className="mb-16">
              <thead>
                <tr>
                  <th>№</th>
                  <th style={{ minWidth: "200px" }}>Товар</th>
                  {click ? (
                    <>
                      <th>Номер документа</th>
                      <th>Дата документа</th>
                      <th>Заказчик</th>
                      <th>Договор</th>
                      {/* <th>Способ оплаты</th> */}
                    </>
                  ) : null}
                  <th style={{ width: "100px" }}>Количество</th>
                  <th style={{ width: "100px" }}>Сумма</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {get(data, "defectsList", []) ? (
                    get(data, "defectsList", []).map(
                      (item: any, index: number) => (
                        <>
                          <tr key={index + 1}>
                            <th>{index + 1}</th>
                            <td style={{ textAlign: "left" }}>
                              {get(item, "imyaTovara", "")}
                            </td>
                            {click ? (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            ) : null}
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "kol", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summa", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                          </tr>

                          {click && get(item, "details", [])
                            ? get(item, "details", []).map(
                              (details: any, index: number) => (
                                <tr
                                  key={index + 1}
                                  style={{ backgroundColor: "#f4f4f4" }}
                                >
                                  <td></td>
                                  <td></td>
                                  <td>{get(details, "nomerDoc", "")}</td>
                                  <td>
                                    {moment(
                                      get(details, "dataDoc", "")
                                    ).format("DD.MM.YYYY")}
                                  </td>
                                  <td>
                                    {get(details, "imyaKontragent", "")}
                                  </td>
                                  <td>{get(details, "nomerDogovor", "")}</td>
                                  <td>
                                    <NumericFormat
                                      displayType="text"
                                      value={get(details, "kol", 0)}
                                      thousandSeparator
                                      decimalScale={2}
                                    />
                                  </td>
                                  <td>
                                    <NumericFormat
                                      displayType="text"
                                      value={get(details, "summa", 0)}
                                      thousandSeparator
                                      decimalScale={2}
                                    />
                                  </td>
                                </tr>
                              )
                            )
                            : null}
                        </>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={11}>Виберите параметры</td>
                    </tr>
                  )}
                </>
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ReportOnDefectsTable;
