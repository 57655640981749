import { get, isEmpty } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";
import { COMPONY_NAME } from "utilities/constants";

function ReconciliationActTable({ data, filter }: any) {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper className="mb-16">
          <thead>
            <tr>
              <th rowSpan={2} style={{ width: "100px" }}>
                Дата
              </th>
              <th rowSpan={2} style={{ width: "180px" }}>
                Операции
              </th>
              <th colSpan={2}>{COMPONY_NAME}</th>
              <th colSpan={2}>{get(filter, "imyaKontragent", "")}</th>
            </tr>
            <tr>
              <th>Дебет</th>
              <th>Кредит</th>

              <th>Дебет</th>
              <th>Кредит</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                Сальдо на{" "}
                {moment(get(filter, "fromDate", "")).format("DD.MM.YYYY")}
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataNachalaDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataNachalaKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataNachalaKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataNachalaDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
            </tr>
            {!isEmpty(get(data, "dataList.details", []))
              ? get(data, "dataList.details", []).map(
                  (detail: any, index: number) => (
                    <tr key={index + 1} style={{ backgroundColor: "#f4f4f4" }}>
                      <td>
                        {moment(get(detail, "date", "")).format("DD.MM.YYYY")}
                      </td>
                      <td>{get(detail, "description", "")}</td>

                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(detail, "firmDebit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(detail, "firmKredit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>

                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(detail, "kontragentDebit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(detail, "kontragentKredit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                    </tr>
                  )
                )
              : null}
            <tr>
              <td></td>
              <td>Обороты за период:</td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.oborotZaPeriodDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.oborotZaPeriodKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.oborotZaPeriodKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.oborotZaPeriodDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                Сальдо на{" "}
                {moment(get(filter, "toDate", "")).format("DD.MM.YYYY")}
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataKonetsDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataKonetsKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataKonetsKredit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.saldoNaDataKonetsDebit", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
            </tr>
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
}

export default ReconciliationActTable;
