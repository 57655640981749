import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: any = {
  filter: {
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  data: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    data: {
      details: [],
    },
  },
  statistic: {
    loading: false,
    data: {},
    error: "",
  },
  notFilledList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchChangeSemiProduct = createAsyncThunk(
  "changeSemiProduct/fetchChangeSemiProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeSemiProduct(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchChangeSemiProductById = createAsyncThunk(
  "changeSemiProduct/fetchChangeSemiProductById",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeSemiProductById(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchTempChangeSemiProduct = createAsyncThunk(
  "changeSemiProduct/fetchTempChangeSemiProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeSemiProductTemp(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchTempChangeSemiProductById = createAsyncThunk(
  "changeSemiProduct/fetchTempChangeSemiProductById",
  async (params: any, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchChangeSemiProductTempById(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchChangeSemiProductStat = createAsyncThunk(
  "changeSemiProduct/fetchChangeSemiProductStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchChangeSemiProductStat();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const changeSemiProduct = createSlice({
  name: "changeSemiProduct",
  initialState,
  reducers: {
    filterChangeSemiProduct: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFilterSemiProduct: (state) => {
      state.filter = initialState.filter;
    },
    clearFormChangeSemiProduct: (state) => {
      state.formData = initialState.formData;
    },

    movementChangeSemiProductSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: !isEmpty(get(action, "payload.data", []))
              ? [...state.formData.data.details, action.payload.data]
              : [],
          },
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.map((item: any) =>
              item.polufabrikatId == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: state.formData.data.details.filter(
              (item: any) => item.polufabrikatId !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            details: [],
          },
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChangeSemiProduct.pending, (state) => {
      state.data = {
        loading: true,
      };
    });
    builder.addCase(fetchChangeSemiProduct.fulfilled, (state, actions) => {
      state.data = {
        loading: false,
        data: get(actions, "payload.data", {}),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchChangeSemiProduct.rejected, (state, actions) => {
      state.data = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    //  fetching by id
    builder.addCase(fetchChangeSemiProductById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchChangeSemiProductById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchChangeSemiProductById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: {},
        error: "",
        isTempActive: false,
      };
    });
    // fetch temp by id
    builder.addCase(fetchTempChangeSemiProductById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(
      fetchTempChangeSemiProductById.fulfilled,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
          isTempActive: true,
        };
      }
    );
    builder.addCase(
      fetchTempChangeSemiProductById.rejected,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: {},
          error: "",
          isTempActive: false,
        };
      }
    );
    // temp
    builder.addCase(fetchTempChangeSemiProduct.pending, (state) => {
      state.notFilledList = {
        loading: true,
      };
    });
    builder.addCase(fetchTempChangeSemiProduct.fulfilled, (state, actions) => {
      state.notFilledList = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchTempChangeSemiProduct.rejected, (state, actions) => {
      state.notFilledList = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
    // statistics
    builder.addCase(fetchChangeSemiProductStat.pending, (state) => {
      state.statistic = {
        loading: true,
      };
    });
    builder.addCase(fetchChangeSemiProductStat.fulfilled, (state, actions) => {
      state.statistic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchChangeSemiProductStat.rejected, (state, actions) => {
      state.statistic = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  filterChangeSemiProduct,
  movementChangeSemiProductSubject,
  clearFormChangeSemiProduct,
  clearFilterSemiProduct,
} = changeSemiProduct.actions;
export default changeSemiProduct.reducer;
