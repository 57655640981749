import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchLegalPhysPerson } from "app/slices/enumerationsSlices/legalPhysPersonSlices/legalPhysPersonSlices";
import {
  addnewDto,
  clearCounterPartAddress,
  clearFilterAllCounterparts,
  fetchAllCounterpart,
  fetchCounterpartById,
  handleAddNewDto,
  handleCounterpartFilter,
  removeDto,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { counterpartsDataType } from "app/slices/handbookSlices/counterpartSlices/type";
import { fetchLargePerson } from "app/slices/handbookSlices/largePersonSlices/largePersonSlices";
import { fetchPhysPerson } from "app/slices/handbookSlices/physPersonSlices/physPersonSlices";
import { getYurFizData } from "app/states/enumerations/selectStates";
import {
  allCounterpartData,
  allCounterpartFilter,
  counterpartDto,
} from "app/states/handbooks";
import {
  getCounterpartData,
  getCounterpartDataById,
  getTypeFizLits,
  getTypeYurList,
  getallBanks,
  yurFizPersonData,
} from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BasePhoneInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  LoadingAddPages,
  LoadingDetailInfo,
  LoadingTable,
  Nodata,
  Pagination,
  SearchableInput,
  ShowOnPage,
  Tabs,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { TabPanelWrapper } from "components/Tabs/style";
import { useFormik } from "formik";
import _, { get, isEmpty, isEqual, map, set } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import HandBookServices from "services/apiServices/spravochnik";
import add_green from "assets/images/icons/add_green.svg";
import remove_red from "assets/images/icons/remove_red.svg";
import { fetchAllYurList } from "app/slices/handbookSlices/yurLitsSlices/yurLitsSlices";
import { fetchAllBanks } from "app/slices/handbookSlices/bankSlices/bankSlices";
import { toast } from "react-toastify";
import { counterPartSchema } from "utilities/schema/spravochnikSchemas";
import { fetchAllFizList } from "app/slices/handbookSlices/FizLitsSlices/fizLitsSlices";
import { PatternFormat } from "react-number-format";
import DocumentServices from "services/apiServices/documents";
import HasAccess from "routers/HasAccess";
import { MESSAGES, PROTECTED_TYPE } from "utilities/constants";
import { Spin } from "antd";
//  Контрагенты
export const AddNewCounterpartModal = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const dispatch = useAppDispatch();
  const tipYurFizPersonId = useAppSelector(getYurFizData);
  const getOptionsYurList = useAppSelector(getTypeYurList);
  const typePhysOptions = useAppSelector(getTypeFizLits);
  const counterpartsDtoData = useAppSelector(counterpartDto);
  const getAllBanks = useAppSelector(getallBanks);
  const [checkId, setYurFIzCheckId] = useState(1);
  // fetch person by tip id
  const yurPerson = useAppSelector((state) => yurFizPersonData(state, 1));
  const physPerson = useAppSelector((state) => yurFizPersonData(state, 2));
  const [bankParams, setBankParams] = useState({ schot: false, mfo: false });
  const clearAddress = () => {
    dispatch(clearCounterPartAddress());
  };
  const onSubmit = () => {
    try {
      HandBookServices.AddNewCounterpart({
        data: {
          ...values,
          yurLitsaDTO: isEqual(get(values, "tipYurFizLitsaId", ""), 1)
            ? get(values, "yurLitsaDTO", null)
            : null,
          fizLitsaDTO: isEqual(get(values, "tipYurFizLitsaId", ""), 2)
            ? get(values, "fizLitsaDTO", null)
            : null,
          bankSchetDTO:
            get(values, "bankSchetDTO.bankId", null) ||
              get(values, "bankSchetDTO.nomerScheta")
              ? get(values, "bankSchetDTO", "")
              : null,
          addresDostavkiDTOs: get(
            counterpartsDtoData,
            "addresDostavkiDTOs",
            []
          ),
        },
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        setSubmitting(false);
        if (res.status == 200) {
          handleCloseModal();
          setActive(0);
          afterSubmitAction();
          dispatch(fetchPhysPerson({ kontragent: true, all: true }));
          dispatch(fetchLargePerson({ kontragent: true, all: true }));
          // dispatch(fetchAllCounterpart({ all: true }));
          dispatch(handleAddNewDto([]));
          toast.success(MESSAGES.ADDED);
          resetForm();
        } else {
          resetForm();
        }
        resetForm();
        clearAddress();
      });
    } catch (err) {
      setSubmitting(false);
      console.log("Error", err);
    }
  };
  const {
    values,
    setValues,
    handleSubmit,
    resetForm,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      tipYurFizLitsaId: 1,
      bankImya: null,
      fizLitsaDTO: {
        id: null,
        imyaFizLitsa: null,
        polnoeImyaFizLitsa: null,
        telNomer: null,
        tipiFizLitsaId: 1,
        kommentariya: null,
        createdBy: null,
      },

      yurLitsaDTO: {
        address: null,
        inn: null,
        nds: null,
        dir: null,
        email: null,
        oked: null,
        glavbuh: null,
        imyaYurLitsa: null,
        polnoeImyaYurLitsa: null,
        telNomer: null,
        tipYurLitsaId: 1,
        kommentariya: null,
      },
      addresDostavkiDTOs: [],
      bankSchetDTO: {
        imyaBankScheta: "Основной",
        nomerScheta: null,
        bankId: null,
      },
    },
    onSubmit,
    validationSchema:
      bankParams.mfo || bankParams.schot ? counterPartSchema : null,
  });
  // const handleChangeAddress = useCallback(({ name, value, id }: { name: string, value: string, id: number | string }) => {
  //   setValues({
  //     ...values,
  //     addresDostavkiDTOs: get(values, 'addresDostavkiDTOs', [])?.map((item: any, index: number | string) => {
  //       if (index == id) {
  //         return { ...item, [name]: value }
  //       } else {
  //         return item
  //       }
  //     })
  //   })
  // }, [values, setValues]);
  const [active, setActive] = useState(0);

  const funActive = (id: number) => {
    setActive(id);
  };
  const handleChangeDispatch = (event: any) => {
    dispatch(handleAddNewDto(event));
  };
  const addNewDtoForm = () => {
    dispatch(
      addnewDto({
        korotkoeImenaAddress: null,
        polnoeImenaAddress: null,
      })
    );
  };

  const removeDtoForm = () => {
    dispatch(removeDto());
  };

  const collDetails = useCallback(() => {
    dispatch(fetchLegalPhysPerson());
    dispatch(fetchLargePerson({ kontragent: true, all: true }));
    dispatch(fetchPhysPerson({ kontragent: true, all: true }));
    dispatch(fetchAllYurList({ all: true }));
    dispatch(fetchAllBanks({ all: true }));
    dispatch(fetchAllFizList({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    collDetails();
  }, [collDetails]);

  useEffect(() => {
    if (!isEqual(get(values, "tipYurFizLitsaId", ""), 1)) {
      dispatch(fetchPhysPerson({ kontragent: true, all: true }));
    } else {
      dispatch(fetchLargePerson({ kontragent: true, all: true }));
    }
  }, [values.tipYurFizLitsaId]);
  useEffect(() => {
    return () => {
      dispatch(fetchAllCounterpart({ all: true }));
    };
  }, []);
  console.log("active", active)
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>

      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>

          <Tabs
            tableHeader={["Главное", "Адреса", "Банк"]}
            active={active}
            setActive={setActive}
          >
            <Col
              style={{
                height: "50vh",
                overflow: "auto",
                paddingBottom: "10px",
              }}
            >
              {active == 0 && <TabPanelWrapper forceRender={active == 0} >
                <Row align="center">
                  <Col xs={4}>
                    <Title>
                      Вид контрагента <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"tipYurFizLitsaId"}
                      isSearchable
                      value={get(values, "tipYurFizLitsaId", "")}
                      options={tipYurFizPersonId}
                      handleChange={(e: string) => {
                        setFieldValue("tipYurFizLitsaId", e);
                        setYurFIzCheckId(Number(e));
                        if (!isEqual(e, get(values, "tipYurFizLitsaId", ""))) {
                          setFieldValue("fizLitsaDTO.polnoeImyaFizLitsa", "");

                          setFieldValue("fizLitsaDTO.id", "");
                          setFieldValue("fizLitsaDTO.polnoeImyaFizLitsa", "");
                          setFieldValue("fizLitsaDTO.telNomer", "");
                          setFieldValue("fizLitsaDTO.tipiFizLitsaId", "");
                          // yur
                          setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", "");
                          setFieldValue("yurLitsaDTO.id", "");
                          setFieldValue("yurLitsaDTO.imyaYurLitsa", "");
                          setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", "");
                          setFieldValue("yurLitsaDTO.inn", "");
                          setFieldValue("yurLitsaDTO.nds", "");
                          setFieldValue("yurLitsaDTO.oked", "");
                          setFieldValue("yurLitsaDTO.telNomer", "");
                          setFieldValue("yurLitsaDTO.dir", "");
                          setFieldValue("yurLitsaDTO.glavbuh", "");
                          setFieldValue("yurLitsaDTO.kommentariya", "");
                          setFieldValue("yurLitsaDTO.address", "");
                          setFieldValue("yurLitsaDTO.imyaYurLitsa", "");
                          setFieldValue("yurLitsaDTO.email", "");
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Наименование контрагента
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                      <>
                        <SearchableInput
                          id={"imyaYurLitsa"}
                          menuPortalTarget={document.body}
                          className="mt-16"

                          isSearchable
                          value={get(values, "yurLitsaDTO.imyaYurLitsa", 1)}
                          options={yurPerson}
                          onClick={(e: any) => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth' // Smooth scrolling effect
                            })
                          }}
                          defaultValue={{
                            label: get(values, "yurLitsaDTO.imyaYurLitsa"),
                            value: get(values, "yurLitsaDTO.id"),
                          }}
                          handleValueLabel={(e: any) => {
                            if (!isEmpty(e)) {
                              setFieldValue(
                                "yurLitsaDTO.imyaYurLitsa",
                                e.label
                              );
                              if (!isEqual(e.value, e.label)) {
                                setFieldValue("yurLitsaDTO.id", e.value);
                                setFieldValue(
                                  "yurLitsaDTO.imyaYurLitsa",
                                  e.label
                                );
                                setFieldValue(
                                  "yurLitsaDTO.polnoeImyaYurLitsa",
                                  get(e, "fullName", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.inn",
                                  get(e, "inn", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.nds",
                                  get(e, "nds", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.oked",
                                  get(e, "oked", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.telNomer",
                                  get(e, "telNomer", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.dir",
                                  get(e, "dir", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.glavbuh",
                                  get(e, "glavbuh", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.kommentariya",
                                  get(e, "kommentariya", null)
                                );
                                setFieldValue(
                                  "yurLitsaDTO.address",
                                  get(e, "address", null)
                                );
                              }
                            }
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <SearchableInput
                          id={"imyaFizLitsa"}

                          menuPortalTarget={document.body}
                          className="mt-16"
                          isSearchable
                          value={get(values, "fizLitsaDTO.imyaFizLitsa", 1)}
                          defaultValue={{
                            label: get(values, "fizLitsaDTO.imyaFizLitsa"),
                            value: get(values, "fizLitsaDTO.id"),
                          }}
                          options={physPerson}
                          onClick={(e: any) => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth' // Smooth scrolling effect
                            })
                          }}
                          handleValueLabel={(e: any) => {
                            console.log(e, "elevent");
                            if (!isEmpty(e)) {
                              setFieldValue(
                                "fizLitsaDTO.imyaFizLitsa",
                                e.label
                              );
                              if (!isEqual(e.value, e.label)) {
                                setFieldValue(
                                  "fizLitsaDTO.imyaFizLitsa",
                                  e.label
                                );
                                setFieldValue("fizLitsaDTO.id", e.value);
                                setFieldValue(
                                  "fizLitsaDTO.polnoeImyaFizLitsa",
                                  e.polnoeImyaFizLitsa
                                );
                                setFieldValue(
                                  "fizLitsaDTO.telNomer",
                                  e.telNomer
                                );
                                setFieldValue(
                                  "fizLitsaDTO.tipiFizLitsaId",
                                  e.tipiFizLitsaId
                                );
                                setFieldValue(
                                  "fizLitsaDTO.createdBy",
                                  e.createdBy
                                );
                              }
                            }
                          }}
                        />
                      </>
                    )}
                  </Col>
                </Row>
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Полное наименование
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                      <BaseInput
                        value={
                          get(values, "yurLitsaDTO.polnoeImyaYurLitsa", "") ||
                          ""
                        }
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", e);
                        }}
                      />
                    ) : (
                      <BaseInput
                        value={
                          get(values, "fizLitsaDTO.polnoeImyaFizLitsa", "") ||
                          ""
                        }
                        handleInput={(e: string) => {
                          setFieldValue("fizLitsaDTO.polnoeImyaFizLitsa", e);
                        }}
                      />
                    )}
                  </Col>
                </Row>

                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Тип лица <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                      <BaseSelect
                        id={"tipYurLitsaId"}
                        isSearchable
                        value={
                          get(values, "yurLitsaDTO.tipYurLitsaId", 1) || ""
                        }
                        options={getOptionsYurList}
                        handleChange={(e: any) => {
                          if (e === null) {
                            setFieldValue("yurLitsaDTO.tipYurLitsaId", 1);
                          }
                          setFieldValue("yurLitsaDTO.tipYurLitsaId", e);
                        }}
                      />
                    ) : (
                      <BaseSelect
                        id={"tipiFizLitsaId"}
                        isSearchable
                        value={
                          get(values, "fizLitsaDTO.tipiFizLitsaId", 1) || ""
                        }
                        options={typePhysOptions.filter(
                          (val: any) => val.value !== 2
                        )}
                        handleChange={(e: any) => {
                          if (e === null) {
                            setFieldValue("fizLitsaDTO.tipiFizLitsaId", 1);
                          }
                          setFieldValue("fizLitsaDTO.tipiFizLitsaId", e);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>
                        ИНН<span className="required_point">*</span>
                      </Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={get(values, "yurLitsaDTO.inn", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.inn", e);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>Код НДС</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={get(values, "yurLitsaDTO.nds", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.nds", e);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>Код ОКЭД</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={get(values, "yurLitsaDTO.oked", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.oked", e);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Телефоны<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                      // <BaseInput
                      //   value={get(values, "yurLitsaDTO.telNomer", "") || ""}
                      //   handleInput={(e: string) => {
                      //     setFieldValue("yurLitsaDTO.telNomer", e);
                      //   }}

                      // />
                      <BasePhoneInput
                        value={get(values, "yurLitsaDTO.telNomer", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.telNomer", e);
                        }}
                        format="+998 (##) ### ## ##"
                        allowEmptyFormatting={true}
                        mask="_"
                        className="your-custom-class"
                      />
                    ) : (
                      <BasePhoneInput
                        value={get(values, "fizLitsaDTO.telNomer", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("fizLitsaDTO.telNomer", e);
                        }}
                        format="+998 (##) ### ## ##"
                        allowEmptyFormatting={true}
                        mask="_"
                        className="your-custom-class"
                      />
                      // <BaseInput
                      //   value={get(values, "fizLitsaDTO.telNomer", "") || ""}
                      //   handleInput={(e: string) => {
                      //     setFieldValue("fizLitsaDTO.telNomer", e);
                      //   }}
                      // />
                    )}
                  </Col>
                </Row>
                {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>Электронная почта</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={get(values, "yurLitsaDTO.email", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.email", e);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>ФИО руководителя юр.лица</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={get(values, "yurLitsaDTO.dir", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.dir", e);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>ФИО главного бухгалтера</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={get(values, "yurLitsaDTO.glavbuh", "") || ""}
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.glavbuh", e);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>Коментарии</Title>
                  </Col>
                  <Col xs={8}>
                    {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                      <BaseInput
                        value={
                          get(values, "yurLitsaDTO.kommentariya", "") || ""
                        }
                        handleInput={(e: string) => {
                          setFieldValue("yurLitsaDTO.kommentariya", e);
                        }}
                      />
                    ) : (
                      <BaseInput
                        value={
                          get(values, "fizLitsaDTO.kommentariya", "") || ""
                        }
                        handleInput={(e: string) => {
                          setFieldValue("fizLitsaDTO.kommentariya", e);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </TabPanelWrapper>}

              {/*  address */}
              {active == 1 && <>
                <TabPanelWrapper ></TabPanelWrapper>
                <TabPanelWrapper forceRender={active == 1} >
                  {!isEmpty(get(counterpartsDtoData, "addresDostavkiDTOs", [])) &&
                    get(counterpartsDtoData, "addresDostavkiDTOs", []).map(
                      (item: any, index: number) => (
                        <div key={index}>
                          <Row align="center" className="mt-24">
                            <Col xs={4} style={{ paddingRight: 0 }}>
                              <Title>
                                Полное наименование адреса доставки{" "}
                                {index === 0 && (
                                  <span className="required_point">*</span>
                                )}
                              </Title>
                            </Col>
                            <Col xs={8}>
                              <BaseInput
                                value={get(item, "polnoeImenaAddress", "") || " "}
                                handleInput={(e: string) =>
                                  handleChangeDispatch({
                                    name: "polnoeImenaAddress",
                                    value: e,
                                    id: index,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <Row align="center" className="mt-24">
                            <Col xs={4} style={{ paddingRight: 0 }}>
                              <Title>Короткое наименование адреса доставки</Title>
                            </Col>
                            <Col xs={8}>
                              <BaseInput
                                value={
                                  get(item, "korotkoeImenaAddress", "") || ""
                                }
                                handleInput={(e: string) =>
                                  handleChangeDispatch({
                                    name: "korotkoeImenaAddress",
                                    value: e,
                                    id: index,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
                  {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                    <Row align="center" className="mt-24">
                      <Col xs={4} style={{ paddingRight: 0 }}>
                        <Title>Адрес местонахождения</Title>
                      </Col>
                      <Col xs={8}>
                        <BaseInput
                          value={get(values, "yurLitsaDTO.address", "") || ""}
                          handleInput={(e: string) => {
                            setFieldValue("yurLitsaDTO.address", e);
                          }}
                        />
                      </Col>
                    </Row>
                  )}

                  <Row className="mt-16" justify="end">
                    <ReactSVG
                      src={add_green}
                      className="svg_icon"
                      onClick={addNewDtoForm}
                    />
                    {get(counterpartsDtoData, "addresDostavkiDTOs", []).length >=
                      2 && (
                        <ReactSVG
                          src={remove_red}
                          className="svg_icon"
                          onClick={removeDtoForm}
                        />
                      )}
                  </Row>
                </TabPanelWrapper></>}
              {/* banks */}
              {active == 2 && <>
                <TabPanelWrapper ></TabPanelWrapper>
                <TabPanelWrapper ></TabPanelWrapper>
                <TabPanelWrapper forceRender={active == 2} >
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>Наименование счета</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={
                          get(values, "bankSchetDTO.imyaBankScheta", "") || ""
                        }
                        handleInput={(e: string) => {
                          setFieldValue("bankSchetDTO.imyaBankScheta", e);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>Номер счета</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        value={get(values, "bankSchetDTO.nomerScheta", "") || ""}
                        handleInput={(e: string) => {
                          setBankParams({ ...bankParams, schot: true });
                          setFieldValue("bankSchetDTO.nomerScheta", e);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>МФО</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseSelect
                        id={"bankId"}
                        isSearchable
                        value={get(values, "bankSchetDTO.bankId", "")}
                        options={getAllBanks}
                        handleValueLabel={(e: any) => {
                          setFieldValue(
                            "bankSchetDTO.bankId",
                            get(e, "value", null)
                          );
                          setBankParams({ ...bankParams, mfo: true });

                          setFieldValue("bankImya", get(e, "imya", ""));
                        }}
                      />
                    </Col>
                  </Row>
                  <Row align="center" className="mt-24">
                    <Col xs={4} style={{ paddingRight: 0 }}>
                      <Title>Наименование банка</Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput value={get(values, "bankImya", "") || ""} />
                    </Col>
                  </Row>
                </TabPanelWrapper></>}
            </Col>
          </Tabs>

          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green loading={isSubmitting}>
              Добавить
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
                clearAddress();
                setActive(0);
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
export const UpdateCounterpartModal = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  updateModal,
  details
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  updateModal?: boolean;
  details?: any;
}) => {
  const data = useAppSelector(getCounterpartDataById);
  const dispatch = useAppDispatch();
  const tipYurFizPersonId = useAppSelector(getYurFizData);
  const getOptionsYurList = useAppSelector(getTypeYurList);
  const typePhysOptions = useAppSelector(getTypeFizLits);
  // fetch person by tip id
  const yurPerson = useAppSelector((state) => yurFizPersonData(state, 1));
  const physPerson = useAppSelector((state) => yurFizPersonData(state, 2));
  const clearAddress = () => {
    dispatch(clearCounterPartAddress());
  };
  const onSubmit = () => {

    try {
      HandBookServices.UpdateCounterpart({
        id: details.id, params: {
          data: {
            ...values,
            yurLitsaDTO: isEqual(get(values, "tipYurFizLitsaId", ""), 1)
              ? get(values, "yurLitsaDTO", null)
              : null,
            fizLitsaDTO: isEqual(get(values, "tipYurFizLitsaId", ""), 2)
              ? get(values, "fizLitsaDTO", null)
              : null,
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }
      }).then((res) => {
        setSubmitting(false);
        if (res.status == 200) {
          handleCloseModal();
          afterSubmitAction();
          // dispatch(fetchAllCounterpart({ all: true }));
          dispatch(handleAddNewDto([]));
          toast.success(MESSAGES.CHANGED);
          dispatch(fetchPhysPerson({ kontragent: true, all: true }));
          dispatch(fetchLargePerson({ kontragent: true, all: true }));
          resetForm();
        } else {
          resetForm();
        }
        resetForm();
        clearAddress();
      });
    } catch (err) {
      setSubmitting(false);
      console.log("Error", err);
    }
  };
  useEffect(() => {
    if (get(details, "id", null)) {
      dispatch(fetchCounterpartById(get(details, "id", null)));
    }
  }, [details, updateModal])

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      tipYurFizLitsaId: details.tipYurFizLitsaId,
      // id: details.id,
      bankImya: null,
      fizLitsaDTO: {
        id: get(data, "data.fizLitsaDTO.id", null),
        imyaFizLitsa: get(data, "data.fizLitsaDTO.imyaFizLitsa", null),
        polnoeImyaFizLitsa: get(data, "data.fizLitsaDTO.polnoeImyaFizLitsa", null),
        telNomer: get(data, "data.fizLitsaDTO.telNomer", null),
        tipiFizLitsaId: 1,
        kommentariya: get(data, "data.fizLitsaDTO.kommentariya", null),
        createdBy: get(data, "data.fizLitsaDTO.createdBy", null),
      },

      yurLitsaDTO: {
        id: get(data, "data.yurLitsaDTO.id", null),
        address: get(data, "data.yurLitsaDTO.address", null),
        inn: get(data, "data.yurLitsaDTO.inn", null),
        nds: get(data, "data.yurLitsaDTO.nds", null),
        dir: get(data, "data.yurLitsaDTO.dir", null),
        email: get(data, "data.yurLitsaDTO.email", null),
        oked: get(data, "data.yurLitsaDTO.oked", null),
        glavbuh: get(data, "data.yurLitsaDTO.glavbuh", null),
        imyaYurLitsa: get(data, "data.yurLitsaDTO.imyaYurLitsa", null),
        polnoeImyaYurLitsa: get(data, "data.yurLitsaDTO.polnoeImyaYurLitsa", null),
        telNomer: get(data, "data.yurLitsaDTO.telNomer", null),
        tipYurLitsaId: 1,
        kommentariya: get(data, "data.yurLitsaDTO.kommentariya", null),
      }
    },
    onSubmit,
    validationSchema: null,
  });




  useEffect(() => {
    if (!isEqual(get(values, "tipYurFizLitsaId", ""), 1)) {
      dispatch(fetchPhysPerson({ kontragent: true, all: true }));
    } else {
      dispatch(fetchLargePerson({ kontragent: true, all: true }));
    }

  }, [values.tipYurFizLitsaId]);


  const collDetails = useCallback(() => {
    dispatch(fetchLegalPhysPerson());
    // dispatch(fetchLargePerson({ kontragent: true }));
    dispatch(fetchPhysPerson({ kontragent: true, all: true }));
    dispatch(fetchLargePerson({ kontragent: true, all: true }));
    dispatch(fetchAllYurList({ all: true }));
    dispatch(fetchAllBanks({ all: true }));
    dispatch(fetchAllFizList({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    collDetails();
  }, [collDetails]);

  // useEffect(() => {
  //   if (!isEqual(get(values, "tipYurFizLitsaId", ""), 1)) {
  //     dispatch(fetchPhysPerson({ kontragent: true }));
  //   }
  // }, [values.tipYurFizLitsaId]);
  // useEffect(() => {
  //   return () => {
  //     dispatch(fetchAllCounterpart({ all: true }));
  //   };
  // }, []);
  return (
    <>
      <Col xs={12} className="mt-24 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      {get(data, 'loading', false) ? <LoadingAddPages /> :
        <Form onSubmit={handleSubmit} className='pt-16' align={"center"}>
          <Col xs={12} className={"px-24 mt-16"}>
            <Col
              style={{
                height: "50vh",
                overflow: "auto",
                paddingBottom: "10px",
              }}
            >
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Вид контрагента <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tipYurFizLitsaId"}
                    isSearchable
                    value={get(values, "tipYurFizLitsaId", "")}
                    options={tipYurFizPersonId}
                    handleChange={(e: string) => {
                      setFieldValue("tipYurFizLitsaId", e);
                      if (isEqual(e, 1)) {
                        // yur
                        setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", values.fizLitsaDTO?.polnoeImyaFizLitsa);
                        setFieldValue("yurLitsaDTO.id", null);
                        setFieldValue("yurLitsaDTO.imyaYurLitsa", values.fizLitsaDTO?.imyaFizLitsa);
                        setFieldValue("yurLitsaDTO.telNomer", values.fizLitsaDTO?.telNomer);
                        setFieldValue("yurLitsaDTO.kommentariya", values.fizLitsaDTO?.kommentariya);
                        setFieldValue("yurLitsaDTO.tipYurLitsaId", 1);
                        //  fiz 
                        setFieldValue("fizLitsaDTO.polnoeImyaYurLitsa", '');
                        setFieldValue("fizLitsaDTO.id", null);
                        setFieldValue("fizLitsaDTO.imyaYurLitsa", '');
                        setFieldValue("fizLitsaDTO.telNomer", '');
                        setFieldValue("fizLitsaDTO.tipYurLitsaId", null);
                        setFieldValue("fizLitsaDTO.kommentariya", '');
                      } else {
                        // fiz
                        setFieldValue("fizLitsaDTO.imyaFizLitsa", values.yurLitsaDTO?.imyaYurLitsa);
                        setFieldValue("fizLitsaDTO.id", null);
                        setFieldValue("fizLitsaDTO.polnoeImyaFizLitsa", values.yurLitsaDTO?.polnoeImyaYurLitsa);
                        setFieldValue("fizLitsaDTO.telNomer", values.yurLitsaDTO?.telNomer);
                        setFieldValue("fizLitsaDTO.tipYurLitsaId", 2);
                        setFieldValue("fizLitsaDTO.kommentariya", values.yurLitsaDTO?.kommentariya);
                        // yur
                        setFieldValue("yurLitsaDTO.imyaYurLitsa", '');
                        setFieldValue("yurLitsaDTO.id", null);
                        setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", '');
                        setFieldValue("yurLitsaDTO.telNomer", '');
                        setFieldValue("yurLitsaDTO.tipYurLitsaId", null);
                        setFieldValue("yurLitsaDTO.kommentariya", '');
                      }

                      if (!isEqual(e, get(values, "tipYurFizLitsaId", ""))) {
                        // setFieldValue("fizLitsaDTO.polnoeImyaFizLitsa", values.yurLitsaDTO?.polnoeImyaYurLitsa);

                        // setFieldValue("fizLitsaDTO.id", values.yurLitsaDTO?.id);
                        // setFieldValue("fizLitsaDTO.polnoeImyaFizLitsa", values.yurLitsaDTO?.polnoeImyaYurLitsa);
                        // setFieldValue("fizLitsaDTO.telNomer", values.yurLitsaDTO?.telNomer);
                        // setFieldValue("fizLitsaDTO.tipiFizLitsaId", values.yurLitsaDTO?.tipYurLitsaId);
                        // yur
                        // setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", values.fizLitsaDTO?.polnoeImyaFizLitsa);
                        // setFieldValue("yurLitsaDTO.id", values.fizLitsaDTO?.id);
                        // setFieldValue("yurLitsaDTO.imyaYurLitsa", values.fizLitsaDTO?.polnoeImyaFizLitsa);
                        // setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", values.fizLitsaDTO?.polnoeImyaFizLitsa);
                        // setFieldValue("yurLitsaDTO.inn", "");
                        // setFieldValue("yurLitsaDTO.nds", "");
                        // setFieldValue("yurLitsaDTO.oked", "");
                        // setFieldValue("yurLitsaDTO.telNomer", values.fizLitsaDTO?.telNomer);
                        // setFieldValue("yurLitsaDTO.dir", "");
                        // setFieldValue("yurLitsaDTO.glavbuh", "");
                        // setFieldValue("yurLitsaDTO.kommentariya", "");
                        // setFieldValue("yurLitsaDTO.address", "");
                        // setFieldValue("yurLitsaDTO.imyaYurLitsa", values.fizLitsaDTO?.polnoeImyaFizLitsa);
                        // setFieldValue("yurLitsaDTO.email", "");
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Наименование контрагента
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                    <>
                      <SearchableInput
                        id={"imyaYurLitsa"}
                        menuPortalTarget={document.body}
                        className="mt-16"
                        isSearchable
                        value={get(values, "yurLitsaDTO.imyaYurLitsa", 1)}
                        options={yurPerson}
                        // onChange={(e: any) => {
                        //   searchByYurList(e);
                        // }}
                        defaultValue={{
                          label: get(values, "yurLitsaDTO.imyaYurLitsa"),
                          value: get(values, "yurLitsaDTO.id"),
                        }}
                        handleValueLabel={(e: any) => {
                          if (!isEmpty(e)) {
                            setFieldValue(
                              "yurLitsaDTO.imyaYurLitsa",
                              e.label
                            );
                            if (!isEqual(e.value, e.label)) {
                              setFieldValue("yurLitsaDTO.id", e.value);
                              setFieldValue(
                                "yurLitsaDTO.imyaYurLitsa",
                                e.label
                              );
                              setFieldValue(
                                "yurLitsaDTO.polnoeImyaYurLitsa",
                                get(e, "fullName", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.inn",
                                get(e, "inn", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.nds",
                                get(e, "nds", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.oked",
                                get(e, "oked", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.telNomer",
                                get(e, "telNomer", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.dir",
                                get(e, "dir", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.glavbuh",
                                get(e, "glavbuh", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.kommentariya",
                                get(e, "kommentariya", null)
                              );
                              setFieldValue(
                                "yurLitsaDTO.address",
                                get(e, "address", null)
                              );
                            }
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <SearchableInput
                        id={"imyaFizLitsa"}
                        menuPortalTarget={document.body}
                        className="mt-16"
                        isSearchable
                        value={get(values, "fizLitsaDTO.imyaFizLitsa", 1)}
                        defaultValue={{
                          label: get(
                            values,
                            "fizLitsaDTO.imyaFizLitsa",
                            null
                          ),
                          value: get(values, "fizLitsaDTO.id", null),
                        }}
                        options={physPerson}
                        // onChange={(e: any) => {
                        //   searchByFizList(e);
                        // }}
                        handleValueLabel={(e: any) => {
                          if (!isEmpty(e)) {
                            setFieldValue(
                              "fizLitsaDTO.imyaFizLitsa",
                              e.label
                            );
                            if (!isEqual(e.value, e.label)) {
                              setFieldValue(
                                "fizLitsaDTO.imyaFizLitsa",
                                e.label
                              );
                              setFieldValue("fizLitsaDTO.id", e.value);
                              setFieldValue(
                                "fizLitsaDTO.polnoeImyaFizLitsa",
                                e.polnoeImyaFizLitsa
                              );
                              setFieldValue(
                                "fizLitsaDTO.telNomer",
                                e.telNomer
                              );
                              setFieldValue(
                                "fizLitsaDTO.tipiFizLitsaId",
                                e.tipiFizLitsaId
                              );
                              setFieldValue(
                                "fizLitsaDTO.createdBy",
                                e.createdBy
                              );
                            }
                          }
                        }}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Полное наименование
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                    <BaseInput
                      value={
                        get(values, "yurLitsaDTO.polnoeImyaYurLitsa", "") ||
                        ""
                      }
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.polnoeImyaYurLitsa", e);
                      }}
                    />
                  ) : (
                    <BaseInput
                      value={
                        get(values, "fizLitsaDTO.polnoeImyaFizLitsa", "") ||
                        ""
                      }
                      handleInput={(e: string) => {
                        setFieldValue("fizLitsaDTO.polnoeImyaFizLitsa", e);
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Тип лица <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                    <BaseSelect
                      id={"tipYurLitsaId"}
                      isSearchable
                      value={
                        get(values, "yurLitsaDTO.tipYurLitsaId", 1) || ""
                      }
                      options={getOptionsYurList}
                      handleChange={(e: any) => {
                        if (e === null) {
                          setFieldValue("yurLitsaDTO.tipYurLitsaId", 1);
                        }
                        setFieldValue("yurLitsaDTO.tipYurLitsaId", e);
                      }}
                    />
                  ) : (
                    <BaseSelect
                      id={"tipiFizLitsaId"}
                      isSearchable
                      value={
                        get(values, "fizLitsaDTO.tipiFizLitsaId", 1) || ""
                      }
                      options={typePhysOptions.filter(
                        (val: any) => val.value !== 2
                      )}
                      handleChange={(e: any) => {
                        if (e === null) {
                          setFieldValue("fizLitsaDTO.tipiFizLitsaId", 1);
                        }
                        setFieldValue("fizLitsaDTO.tipiFizLitsaId", e);
                      }}
                    />
                  )}
                </Col>
              </Row>
              {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      ИНН<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseInput
                      value={get(values, "yurLitsaDTO.inn", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.inn", e);
                      }}
                    />
                  </Col>
                </Row>
              )}
              {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>Код НДС</Title>
                  </Col>
                  <Col xs={8}>
                    <BaseInput
                      value={get(values, "yurLitsaDTO.nds", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.nds", e);
                      }}
                    />
                  </Col>
                </Row>
              )}
              {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>Код ОКЭД</Title>
                  </Col>
                  <Col xs={8}>
                    <BaseInput
                      value={get(values, "yurLitsaDTO.oked", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.oked", e);
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Телефоны<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                    // <BaseInput
                    //   value={get(values, "yurLitsaDTO.telNomer", "") || ""}
                    //   handleInput={(e: string) => {
                    //     setFieldValue("yurLitsaDTO.telNomer", e);
                    //   }}

                    // />
                    <BasePhoneInput
                      value={get(values, "yurLitsaDTO.telNomer", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.telNomer", e);
                      }}
                      format="+998 (##) ### ## ##"
                      allowEmptyFormatting={true}
                      mask="_"
                      className="your-custom-class"
                    />
                  ) : (
                    <BasePhoneInput
                      value={get(values, "fizLitsaDTO.telNomer", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("fizLitsaDTO.telNomer", e);
                      }}
                      format="+998 (##) ### ## ##"
                      allowEmptyFormatting={true}
                      mask="_"
                      className="your-custom-class"
                    />
                    // <BaseInput
                    //   value={get(values, "fizLitsaDTO.telNomer", "") || ""}
                    //   handleInput={(e: string) => {
                    //     setFieldValue("fizLitsaDTO.telNomer", e);
                    //   }}
                    // />
                  )}
                </Col>
              </Row>
              {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>Электронная почта</Title>
                  </Col>
                  <Col xs={8}>
                    <BaseInput
                      value={get(values, "yurLitsaDTO.email", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.email", e);
                      }}
                    />
                  </Col>
                </Row>
              )}
              {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>ФИО руководителя юр.лица</Title>
                  </Col>
                  <Col xs={8}>
                    <BaseInput
                      value={get(values, "yurLitsaDTO.dir", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.dir", e);
                      }}
                    />
                  </Col>
                </Row>
              )}
              {isEqual(get(values, "tipYurFizLitsaId", ""), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>ФИО главного бухгалтера</Title>
                  </Col>
                  <Col xs={8}>
                    <BaseInput
                      value={get(values, "yurLitsaDTO.glavbuh", "") || ""}
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.glavbuh", e);
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>Коментарии</Title>
                </Col>
                <Col xs={8}>
                  {isEqual(get(values, "tipYurFizLitsaId", ""), 1) ? (
                    <BaseInput
                      value={
                        get(values, "yurLitsaDTO.kommentariya", "") || ""
                      }
                      handleInput={(e: string) => {
                        setFieldValue("yurLitsaDTO.kommentariya", e);
                      }}
                    />
                  ) : (
                    <BaseInput
                      value={
                        get(values, "fizLitsaDTO.kommentariya", "") || ""
                      }
                      handleInput={(e: string) => {
                        setFieldValue("fizLitsaDTO.kommentariya", e);
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="text-right mt-24 mb-16">
              <Button className="mr-8" type="submit" green loading={isSubmitting}>
                Изменить
              </Button>
              <Button
                onClick={() => {
                  handleCloseModal();
                  resetForm();
                  clearAddress();
                }}
                danger
              >
                Закрыть
              </Button>
            </Col>
          </Col>
        </Form>}
    </>
  );
};

const CounterpartsContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [details, setDetails] = useState<any>({})
  const [updateModal, setUpdateModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(allCounterpartData);
  const filter = useAppSelector(allCounterpartFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleCounterpartFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchAllCounterpart({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);
  //  for add
  // const cllDetails = useCallback(() => {
  //   dispatch(fetchLegalPhysPerson());
  //   dispatch(fetchLargePerson({ kontragent: true }));
  //   dispatch(fetchAllYurList({ all: true }));
  //   dispatch(fetchAllBanks({ all: true }));
  //   dispatch(fetchAllFizList({ all: true }));
  // }, [dispatch]);

  const clearFilter = useCallback(() => {
    dispatch(fetchAllCounterpart({ ...filter, search: "", InAscOrder: false }));
    dispatch(clearFilterAllCounterparts());
  }, [dispatch, filter]);

  const fetchCounterpartData = useCallback(() => {
    dispatch(fetchAllCounterpart({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  useEffect(() => {
    // cllDetails();
    return () => {
      clearFilter();
    };
  }, []);

  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteCounterpart(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchPhysPerson({ kontragent: true, all: true }));
        dispatch(fetchLargePerson({ kontragent: true, all: true }));
        handleFilter();
      }
    });
  };
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  const handleUpdateCloseModal = useCallback(() => {
    setUpdateModal(false);
  }, []);

  const handleUpdateOpenModal = useCallback(({ item }: any) => {
    setDetails(item);
    setUpdateModal(true);
  }, []);

  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "KONTRAGENT_CREATE",
                    page: "KONTRAGENT",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                value={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger onClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewCounterpartModal
              handleCloseModal={handleCloseModal}
              // openModal={openModal}
              afterSubmitAction={() => fetchCounterpartData()}
            />
          </ReactModal>
          <ReactModal isOpen={updateModal}>
            <UpdateCounterpartModal
              handleCloseModal={handleUpdateCloseModal}
              details={details}
              updateModal={updateModal}
              afterSubmitAction={() => fetchCounterpartData()}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Вид контрагента",
                    "Наименования",
                    "Основной счет",
                    "Телефон номер",
                    "Коментарии",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "dataList", [])) ? (
                        get(data, "dataList", []).map(
                          (item: counterpartsDataType) => (
                            <tr key={get(item, "id", 0)}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"KONTRAGENT"}
                                  // isShowUpdate={true}
                                  handleUpdate={() => handleUpdateOpenModal({ item })}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "tipKontragenta", "-")}</td>
                              <td>{get(item, "imyaKontragent", "-")}</td>
                              <td>
                                {get(item, "nomerScheta", "-")?.replace(
                                  /(\d{4})/g,
                                  "$1 "
                                )}
                              </td>
                              <td>
                                {
                                  <PatternFormat
                                    format="+### (##) ### ## ##"
                                    displayType="text"
                                    value={get(item, "telNomer", 0)}
                                  />
                                }
                              </td>
                              <td>{get(item, "kommentariya", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={8} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={8}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default CounterpartsContainer;
