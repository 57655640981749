import React, { useEffect, useState } from "react";
import { Props } from "./type";
import { StyledBaseInput } from "./style";
const BasePhoneInput: React.FC<Props> = ({
    clear = "",
    placeholder = "",
    disabled = false,
    handleInput = () => { },
    ...props
}) => {
    const [val, setVal] = useState(clear);

    const handleChange = (e: any) => {
        setVal(e.target.value);
        handleInput(e.target.value);
    };

    useEffect(() => {
        if (clear.length == 0) {
            setVal(clear);
        }
    }, [clear]);
    return (
        // <div style={{ position: "relative" }}>
            <StyledBaseInput
                value={val}
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
                {...props}
            />
        // </div>
    );
};

export default BasePhoneInput;
