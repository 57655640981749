import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import {
  changePurchaseTable,
  clearPurchaseTable,
  fetchReportPurchase,
  filterPurchaseTable,
} from "app/slices/reportSlices/reportPurchaseSlices/reportPurchaseSlices";
import { getDriversData } from "app/states/handbooks/selectStates";
import { reportPurchaseFilter, reportPurchaseList } from "app/states/reports";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import React, { useState, useCallback, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import ReportInfoTitle from "../components/ReportInfoTitle";
import ReportOnPurchaseTable from "../components/ReportOnPurchaseTable";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name?: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  voditel?: string;
}

// Отчет по приобретенному у водителей товарному бетону

function ReportOnPurchaseContainer() {
  const dispatch = useAppDispatch();

  const data = useAppSelector(reportPurchaseList);
  const filter = useAppSelector(reportPurchaseFilter);

  const driversDropdowns = useAppSelector(getDriversData);

  const LOADING_TABLE = get(data, "loading", false);

  // details
  const [details, setDetails] = useState<ParamState>({
    voditel: "",
  });
  //excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Отчет по приобретенному у водителей товарному бетону ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `K1`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "voditel", ""),
        label: `Водитель: ${get(details, "voditel", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `Отчет по приобретенному у водителей товарному бетону с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(`A${1 + index + 1}`, `${"K" + (1 + index + 1)}`);
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Водитель",
        key: "imyaVoditeli",
        width: 30,
      },
      {
        header: "Товар",
        key: "imyaTovar",
        width: 25,
      },
      {
        header: "Ед.изм-я",
        key: "imyaEdIzm",
        width: 35,
      },
      {
        header: "Номер документа",
        key: "nomerDoc",
        width: 30,
      },
      {
        header: "Дата документа",
        key: "dataDoc",
        width: 30,
      },
      {
        header: "Территория",
        key: "imyaTerritoriya",
        width: 30,
      },
      {
        header: "Склад ГП",
        key: "imyaSkladGP",
        width: 30,
      },
      {
        header: "Автомобиль",
        key: "avtomobil",
        width: 35,
      },
      {
        header: "	Количество",
        key: "totalKol",
        width: 30,
      },
      {
        header: "Территория",
        key: "totalSumma",
        width: 30,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;

    get(data, "dataList", [])?.forEach((item: any, index: number) => {
      const row = sheet.addRow({
        order: index + 1,
        imyaVoditeli: get(item, "imyaVoditeli", ""),
        imyaTovar: get(item, "imyaTovar", ""),
        imyaEdIzm: get(item, "imyaEdIzm", ""),
        nomerDoc: get(item, "nomerDoc", ""),
        dataDoc: get(item, "dataDoc", ""),
        imyaTerritoriya: get(item, "imyaTerritoriya", ""),
        imyaSkladGP: get(item, "imyaSkladGP", ""),
        avtomobil: get(item, "avtomobil", ""),
        totalKol: Number(get(item, "kol", 0)),
        totalSumma: Number(get(item, "summa", 0)),
      });
      // row.height = 25;
      // row.alignment = { vertical: "middle", horizontal: "center" };
      counterRow += 1;
      get(item, "tovars", []).forEach((tovar: any) => {
        const row1 = sheet.addRow({
          order: "ㅤ",
          imyaVoditeli: get(tovar, "imyaVoditeli", ""),
          imyaTovar: get(tovar, "imyaTovar", ""),
          imyaEdIzm: get(tovar, "imyaEdIzm", ""),
          nomerDoc: get(tovar, "nomerDoc", ""),
          dataDoc: get(tovar, "dataDoc", ""),
          imyaTerritoriya: get(tovar, "imyaTerritoriya", ""),
          imyaSkladGP: get(tovar, "imyaSkladGP", ""),
          avtomobil: get(tovar, "avtomobil", ""),
          totalKol: Number(get(tovar, "kol", 0)),
          totalSumma: Number(get(tovar, "summa", 0)),
        });
        row1.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
          };
        });
        // row1.height = 25;
        // row1.alignment = { vertical: "middle", horizontal: "center" };
        counterRow++;
        get(tovar, "details", [])?.forEach((detail: any) => {
          const row2 = sheet.addRow({
            order: "ㅤ",
            imyaVoditeli: get(detail, "imyaVoditeli", ""),
            imyaTovar: get(detail, "imyaTovar", ""),
            imyaEdIzm: get(detail, "imyaEdIzm", ""),
            nomerDoc: get(detail, "nomerDoc", ""),
            dataDoc: get(detail, "dataDoc", ""),
            imyaTerritoriya: get(detail, "imyaTerritoriya", ""),
            imyaSkladGP: get(detail, "imyaSkladGP", ""),
            avtomobil: get(detail, "avtomobil", ""),
            totalKol: Number(get(detail, "totalKol", 0)),
            totalSumma: Number(get(detail, "totalSumma", 0)),
          });
          // row2.height = 30;
          // row2.alignment = { vertical: "middle", horizontal: "center" };
          counterRow += 1;
        });
      });
    });
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`K${counterRow + count + 1}`);
    // const startCell = worksheet.getCell('A1');
    // const endCell = worksheet.getCell('K17');

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const fetchReporPurchaseData = useCallback(() => {
    dispatch(fetchReportPurchase(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearPurchaseTable());

    setDetails({
      voditel: "",
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changePurchaseTable(null));
    }

    dispatch(filterPurchaseTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchDrivers({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "fromDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "toDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>

            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchReporPurchaseData}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  width={"200px"}
                  options={driversDropdowns}
                  placeholder="Водитель"
                  value={get(filter, "voditelId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.voditelId === e.value) return;

                    handleChangeFilter({
                      name: "voditelId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      voditel: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchReportPurchase({
                        ...filter,
                        voditelId: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={
                      "Отчет по приобретенному у водителей товарному бетону"
                    }
                  />

                  {!isEmpty(get(data, "dataList", [])) ? (
                    <ReportOnPurchaseTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default ReportOnPurchaseContainer;
