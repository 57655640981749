import { combineReducers } from "@reduxjs/toolkit";
import deliveryMethodSlices from "./deliveryMethodSlices/deliveryMethodSlices";
import paymentMethodSlices from "./paymentMethodSlices/paymentMethodSlices";
import incomeSlices from "./incomeSlices/incomeSlices";
import typeFlowSlices from "./typeFlowSlices/typeFlowSlices";
import typeContractSlices from "./typeContractSlices/typeContractSlices";
import legalPhysPersonSlices from "./legalPhysPersonSlices/legalPhysPersonSlices";
import typeWriteOffSlices from "./typeWriteOffSlices/typeWriteOffSlices";
import roundOrderSlices from "./roundOrderSlices/roundOrderSlices";
import TypeWriteSubStandardSlices from "./typeWriteSubstandard/typeWriteSubstandard"
const reducer = combineReducers({
  paymentMethod: paymentMethodSlices,
  deliveryMethod: deliveryMethodSlices,
  income:incomeSlices,
  typeFlow:typeFlowSlices,
  typeWriteOff:typeWriteOffSlices,
  legalPhysPerson:legalPhysPersonSlices,
  typeContract:typeContractSlices,
  roundOrder:roundOrderSlices,
  typeSubstandard:TypeWriteSubStandardSlices

});

export default reducer;
