import { combineReducers } from "@reduxjs/toolkit";
import pivotTableSlices from "./pivotTableSlices/pivotTableSlices";
import remainTableSlices from "./remaonTableSlices/remainTableSlices";

const reducers = combineReducers(
    {
    pivotTable:pivotTableSlices,
    remainTable:remainTableSlices
    }
)
export default reducers