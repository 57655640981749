import { TableWrapper } from "./styles/style";
import { get, isEmpty } from "lodash";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import moment from "moment";

function ReportSemiProductsTable({ data, click, typeDate }: any) {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper minWidth={"1200px"} className="mb-16">
              <thead>
                <tr>
                  <th style={{ width: "120px" }}>Дата</th>
                  <th>Полуфабрикат</th>
                  <th style={{ width: "120px" }}>Заказ-Наряд</th>
                  <th>Единица измерения</th>
                  <th>Количество</th>
                  {click ? (
                    <>
                      <th>Материал</th>
                      <th>Единица измерения</th>
                      <th>Количество материала</th>
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {get(data, "dataList", []).map((item: any, index: number) => (
                  <>
                    <tr key={index + 1}>
                      <td>
                        {typeDate === "MONTHLY"
                          ? get(item, "date", "")
                          : moment(get(item, "date", "")).format("DD.MM.YYYY")}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {click ? (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      ) : null}
                    </tr>
                    {!isEmpty(get(item, "list", []))
                      ? get(item, "list", []).map(
                        (item: any, index: number) => (
                          <>
                            <tr
                              key={index + 1}
                              style={{ backgroundColor: "#f9f9f9" }}
                            >
                              <td></td>
                              <td>{get(item, "imyaPolufabrikat", "")}</td>
                              <td>{get(item, "nomerZakazNaryad", "")}</td>
                              <td>{get(item, "imyaEdIzm", "")}</td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "kolTovara", 0)}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              {click ? (
                                <>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </>
                              ) : null}
                            </tr>
                            {click && !isEmpty(get(item, "details", []))
                              ? get(item, "details", []).map(
                                (item: any, index: number) => (
                                  <tr key={index + 1}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{get(item, "imyaMaterial", "")}</td>
                                    <td>{get(item, "imyaEdIzm", "")}</td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(item, "kol", 0)}
                                        thousandSeparator
                                        decimalScale={2}
                                      />
                                    </td>
                                  </tr>
                                )
                              )
                              : null}
                          </>
                        )
                      )
                      : null}
                  </>
                ))}
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ReportSemiProductsTable;
