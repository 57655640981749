import styled, { css } from "styled-components";
import { StyleProps } from "./type";
import Button from "components/Button/Button";

const SelectWrapper = styled.div<StyleProps>`
  width: 100%;
  margin: ${({ margin }: StyleProps) => margin || "0px"};
  max-width: ${({ width }: StyleProps) => width || "320px"};
  font-size: 14px;
  color: #1c1c1c;
  font-weight: 500;
  cursor: pointer;
  .select-arrow {
    margin-right: 18px;
    margin-left: 5px;
  }
  ${({ sm }: StyleProps) =>
    sm &&
    css`
      font-size: 13px;
    `};
`;

const customStyles = ({ sm, menuPosition }: StyleProps) => ({
  control: (base: any) => ({
    ...base,
    color: "black !important",
    background: "#fff",
    borderRadius: "8px",
    minHeight: sm ? "28px" : "36px",
    opacity: 1,
    border: "1px solid hsl(0, 0%, 80%)",
    // zIndex: 9999,
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.06)",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "black",
  }),
  optionColor: (state: any) => ({
    width: "20px",
    height: "20px",
    border: "1px solid #ccc",
    backgroundColor: state.data.color,
  }),
  controlColor: (state: string) => ({
    width: "20px",
    height: "20px",
    border: "1px solid #ccc",
    marginLeft: "10px",
    backgroundColor: state ? state : "white",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    position: menuPosition,
    zIndex: 100,
  }),
});

const MenuButton = styled(Button).attrs({
  width: "100%",
})`
  border: 1px solid rgba(232, 232, 232, 1);
  box-shadow: none;
  background-color: inherit;
  border-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  z-index: 1;
  color: #000;
  font-size: 12px;
  font-weight: 600;
`;

export { customStyles, SelectWrapper, MenuButton };
