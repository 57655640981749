import { useAppSelector } from "app/hook";
import { breadcrumbsURLs } from "app/states/menu";
import FlexBox from "components/FlexBox/FlexBox";
import Text from "components/Text/Text";
import Title from "components/Title/Title";
import { useLocation, Link } from "react-router-dom";
import { memo } from "react";

const Breadcrumbs = ({ only = false, ...props }: any) => {
  const location = useLocation();
  const urls = useAppSelector(breadcrumbsURLs);
  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index) => {
      currentLink += `/${crumb}`;
      const parentCrumb = urls.find(
        (item: { id: string; name: string }) => item.id == crumb
      )?.name;

      return (
        <div className="crumb" key={crumb} style={{ zIndex: 0 }}>
          {index < 2 && (
            <Link to={currentLink}>
              <Title style={{ fontSize: "20px" }}>
                {parentCrumb}
                {only ? (
                  <Text medium xxl dark className={"mr-8 ml-8"}>
                    {""}
                  </Text>
                ) : (
                  <Text medium xxl dark className={"mr-8 ml-8"}>
                    {index < 1 ? "−>" : ""}
                  </Text>
                )}
              </Title>
            </Link>
          )}
        </div>
      );
    });

  return (
    <div className="breadcrumbs" {...props}>
      <FlexBox>{crumbs}</FlexBox>
    </div>
  );
};

export default memo(Breadcrumbs);
