import styled from "styled-components";
import { StyleProps } from "./type";

const MenuItemWrapper = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    height: 50px;

    .menu-item {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        &.active {
            .menu-icon {
                svg {
                    path {
                        fill: #0085ff;
                    }
                }
            }

            span {
                color: #0085ff;
            }
        }

        &:hover {
            .menu-icon {
                svg {
                    path {
                        fill: #0085ff;
                    }
                }
            }

            span {
                color: #0085ff;
            }
        }
    }
`;

export { MenuItemWrapper };
