import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFormDataMovement,
  clearMovementMaterial,
  fetchMovementMaterials,
  fetchMovementMaterialsStat,
  fetchNotFilledMovementMaterials,
  filterChangeMovementMaterials,
} from "app/slices/documentSlices/movementMaterialSlices/movementMaterialSlices";
import { fetchOwnSkladCM } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  movementMaterialsFilter,
  movementMaterialsList,
  saleContractStatMovementMaterials,
  saleTempMovementMaterialsList,
} from "app/states/documents";
import { getSkladCM } from "app/states/handbooks/selectStates";
import delate from "assets/images/icons/delate.svg";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";

// Перемещение сырья и материалов
const MovementMaterialContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_MOVEMENT_MATERIAL
  );

  const movementMaterial = useAppSelector(movementMaterialsList);
  const tempsMovementMaterial = useAppSelector(saleTempMovementMaterialsList);
  const filter = useAppSelector(movementMaterialsFilter);
  const countStat = useAppSelector(saleContractStatMovementMaterials);
  const LOADING_TABLE = get(movementMaterial, "loading", []);
  const skladOptions = useAppSelector(getSkladCM);
  const fetchMovementMaterialsFunction = useCallback(() => {
    dispatch(fetchMovementMaterials(filter));
    dispatch(fetchOwnSkladCM({}));
  }, [dispatch, filter]);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchMovementMaterials({
        ...filter,
        skladOtgruzkaId: null,
        skladPrixodId: null,
        fromDate: null,
        toDate: null,
      })
    );
    dispatch(clearFormDataMovement());
  }, [filter]);

  const fetchNotFillMovementMaterialFunction = useCallback(() => {
    dispatch(fetchNotFilledMovementMaterials());
    dispatch(fetchMovementMaterialsStat());
  }, [dispatch]);

  const navigateForm = (params: { id: string | number; isTemp: boolean }) => {
    navigate(Documents.ADD_MOVEMENT_MATERIAL, {
      state: params,
    });
  };

  const handleAddButton = () => {
    dispatch(clearMovementMaterial());
    navigate(Documents.ADD_MOVEMENT_MATERIAL);
  };

  useEffect(() => {
    fetchNotFillMovementMaterialFunction();
  }, [fetchNotFillMovementMaterialFunction]);

  useEffect(() => {
    fetchMovementMaterialsFunction();
  }, [filter.page, filter.size]);

  const handleTempButton = (id: string | null) => {
    DocumentServices.RemoveTempsMovementMaterials(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchNotFillMovementMaterialFunction();
      }
    });
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DelelteMovementMaterials(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchMovementMaterials(filter));
        dispatch(fetchMovementMaterialsStat());
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsMovementMaterials(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchMovementMaterials(filter));
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DelelteRecordsMovementMaterials(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchMovementMaterials(filter));
      }
    });
  };
  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, []);
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={countStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger handleClick={handleClearFilter} className="mr-16">
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "PEREMESH_SM_CREATE",
                    page: "PEREMESH_SM",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  options={skladOptions}
                  placeholder="Склад отгрузки"
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeMovementMaterials({
                        name: "skladOtgruzkaId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  width="250px"
                  value={get(filter, "skladOtgruzkaId", null)}
                />
                <BaseSelect
                  isClearable
                  options={skladOptions}
                  placeholder="Склад оприходования"
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeMovementMaterials({
                        name: "skladPrixodId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "skladPrixodId", null)}
                  width={"250px"}
                />
                <DatePicker
                  isClearable
                  placeholder="Дата начало"
                  width="200px"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeMovementMaterials({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  isClearable
                  width="200px"
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeMovementMaterials({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={2}>
              <Button primary handleClick={fetchMovementMaterialsFunction}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Склад отгрузки",
                    "Склад оприходования",
                    "Мат.отв.лицо склада отгрузки",
                    "Мат.отв.лицо склада оприходования",
                    "Время записи",
                    "Статус",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      <ContextMenu
                        handleNavigate={navigateForm}
                        deleteDocument={handleDeleteButton}
                        deleteItem={true}
                        markItem={true}
                        uncheckItem={true}
                        userPermission={"PEREMESH_SM"}
                        handleDeleteRecords={handleDeleteRecords}
                        handleUndoRecords={handleUndoRecords}
                        documentInfo={documentInfo}
                      />

                      {!isEmpty(get(tempsMovementMaterial, "data", [])) &&
                        get(tempsMovementMaterial, "data", []).map((item) => (
                          <tr
                            key={get(item, "id", "")}
                            onClick={() =>
                              navigateForm({
                                isTemp: true,
                                id: get(item, "id", ""),
                              })
                            }
                            className="cursor-pointer"
                          >
                            <td>
                              <div
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  handleTempButton(get(item, "id", null));
                                }}
                                className="delete_button"
                              >
                                <ReactSVG
                                  src={delate}
                                  className="delete_button"
                                />
                              </div>
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "nomerDoc", "-")}</td>
                            <td>
                              {moment(get(item, "dataDoc", "")).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>{get(item, "imyaSkladOtgruzka", "")}</td>
                            <td>{get(item, "imyaSkladPrixod", "")}</td>
                            <td>{get(item, "litsoOtgruzka", "")}</td>
                            <td>{get(item, "litsoPrixod", "")}</td>
                            <td>
                              {!isEmpty(get(item, "createdAt", ""))
                                ? moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>{get(item, "status", "-")}</td>
                          </tr>
                        ))}

                      {!isEmpty(get(movementMaterial, "data", []))
                        ? get(movementMaterial, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", "")}
                                onClick={() =>
                                  navigateForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      movementMaterial,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      movementMaterial,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>

                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "-")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaSkladOtgruzka", "")}</td>
                                <td>{get(item, "imyaSkladPrixod", "")}</td>
                                <td>{get(item, "litsoOtgruzka", "")}</td>
                                <td>{get(item, "litsoPrixod", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : ""}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(tempsMovementMaterial, "data", [])) && (
                            <Nodata colLength={11} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={11}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(movementMaterial, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    filterChangeMovementMaterials({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(
                  movementMaterial,
                  "pagination.currentPageNumber",
                  0
                )}
                pageCount={get(movementMaterial, "pagination.totalPages", 1)}
                onPageChange={({ selected }) =>
                  dispatch(
                    filterChangeMovementMaterials({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default MovementMaterialContainer;
