import { combineReducers } from "@reduxjs/toolkit";
import userRoleSlices from "./userRoleSlices/userRoleSlices";
import typeRoleSlices from "./typeRoleSlices/typeRoleSlices";
import logUsersSlices from "./logUsersSlices/logUsersSlices";

const reducers = combineReducers({
  userRole: userRoleSlices,
  typeRole: typeRoleSlices,
  logUsers: logUsersSlices,
});

export default reducers;
