import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchOwnTerritories,
  fetchTerritories,
} from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import {
  changeReportSemiProduct,
  clearReportSemiProduct,
  fetchReportSemiProduct,
  filterReportSemiProduct,
} from "app/slices/reportSlices/reportSemiProductSlices/reportSemiProductSlices";
import { getTerritoriesData } from "app/states/handbooks/selectStates";
import {
  reportSemiProductsFilter,
  reportSemiProductsList,
} from "app/states/reports";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import React, { useEffect, useCallback, useState } from "react";
import { Col, Row } from "react-grid-system";
import { ReportSemiProductsTable } from "../components";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";

type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

interface ParamsState {
  territoriya: string;
}

// Отчет по выпуску полуфабрикатов
function ReportSemiProductsContainer() {
  const dispatch = useAppDispatch();
  const [check, setCheck] = useState(false);

  // details
  const [details, setDetails] = useState<ParamsState>({
    territoriya: "",
  });

  // data
  const filter = useAppSelector(reportSemiProductsFilter);
  const data = useAppSelector(reportSemiProductsList);
  const LOADING_TABLE = get(data, "loading", false);

  const territoryData = useAppSelector(getTerritoriesData);

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Отчет по выпуску полуфабрикатов ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `${check ? "H1" : "E1"}`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    const detailsArray = [
      {
        value: get(details, "territoriya", ""),
        label: `Tерритория: ${get(details, "territoriya", "")}`,
      },
    ];
    let count = 1;
    sheet.getCell("A1").value = `Отчет по выпуску полуфабрикатов с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(
          `A${1 + index + 1}`,
          `${check ? "H" + (1 + index + 1) : "E" + (1 + index + 1)}`
        );
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "Дата",
        key: "date",
        width: 20,
      },
      {
        header: "Полуфабрикат",
        key: "imyaPolufabrikat",
        width: 30,
      },
      {
        header: "Заказ-Наряд",
        key: "nomerZakazNaryad",
        width: 25,
      },
      {
        header: "Единица измерения",
        key: "imyaEdIzm",
        width: 35,
      },
      {
        header: "Количество",
        key: "kolTovara",
        width: 30,
      },
    ];
    const newTableHeader = check
      ? tableHeader.splice(
          5,
          0,
          check && {
            header: "Материал",
            key: "imyaMaterial",
            width: 25,
          },
          check && {
            header: "Единица измерения",
            key: "imyaEdIzmdDe",
            width: 25,
          },
          check && {
            header: "Количество материала",
            key: "kol",
            width: 25,
          }
        )
      : tableHeader;
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;

    get(data, "dataList", [])?.forEach((item: any) => {
      const date = !check
        ? sheet.addRow({
            date: get(item, "date", ""),
            imyaPolufabrikat: "ㅤ",
            nomerZakazNaryad: "ㅤ",
            imyaEdIzm: "ㅤ",
            kolTovara: "ㅤ",
          })
        : sheet.addRow({
            date: get(item, "date", ""),
            imyaPolufabrikat: "ㅤ",
            nomerZakazNaryad: "ㅤ",
            imyaEdIzm: "ㅤ",
            kolTovara: "ㅤ",
            imyaMaterial: "ㅤ",
            imyaEdIzmdDe: "ㅤ",
            kol: "ㅤ",
          });
      counterRow++;
      // date.height = 25;
      // date.alignment = { vertical: "middle", horizontal: "center" };
      get(item, "list", [])?.forEach((element: any) => {
        if (!check) {
          const ischeck = sheet.addRow({
            date: "ㅤ",
            imyaPolufabrikat: get(element, "imyaPolufabrikat", ""),
            nomerZakazNaryad: get(element, "nomerZakazNaryad", ""),
            imyaEdIzm: get(element, "imyaEdIzm", ""),
            kolTovara: get(element, "kolTovara", ""),
          });
          // ischeck.height = 25;
          // ischeck.alignment = { vertical: "middle", horizontal: "center" };
          counterRow++;
        } else {
          const row = sheet.addRow({
            date: "ㅤ",
            imyaPolufabrikat: get(element, "imyaPolufabrikat", ""),
            nomerZakazNaryad: get(element, "nomerZakazNaryad", ""),
            imyaEdIzm: get(element, "imyaEdIzm", ""),
            kolTovara: Number(get(element, "kolTovara", "")),
            imyaMaterial: "ㅤ",
            imyaEdIzmdDe: "ㅤ",
            kol: "ㅤ",
          });
          // row.height = 25;
          // row.alignment = { vertical: "middle", horizontal: "center" };
          row.eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
            };
          });
          counterRow += 1;
          get(element, "details", []).forEach((detail: any) => {
            const details = sheet.addRow({
              date: "ㅤ",
              imyaPolufabrikat: "ㅤ",
              nomerZakazNaryad: "ㅤ",
              imyaEdIzm: "ㅤ",
              kolTovara: "ㅤ",
              imyaMaterial: get(detail, "imyaMaterial", ""),
              imyaEdIzmdDe: get(detail, "imyaEdIzm", ""),
              kol: Number(get(detail, "kol", "")),
            });
            // details.height = 25;
            // details.alignment = { vertical: "middle", horizontal: "center" };
            counterRow++;
          });
        }
      });
    });

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      `${check ? "H" : "E"}${counterRow + count + 1}`
    );
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  // filter
  const fetchAllReportSemiProducts = useCallback(() => {
    dispatch(fetchReportSemiProduct(filter));
  }, [filter, dispatch]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearReportSemiProduct());

    setDetails({
      territoriya: "",
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeReportSemiProduct(null));
    }

    dispatch(filterReportSemiProduct({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnTerritories({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "fromDate", null)}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "toDate", null)}
                  dateFormat="dd.MM.yyyy"
                />

                <BaseSelect
                  options={[
                    { value: "DAILY", label: "Ежидневно" },
                    { value: "MONTHLY", label: "Ежемесячные" },
                  ]}
                  value={get(filter, "period", "")}
                  placeholder="Периодичность"
                  handleChange={(e: string) => {
                    handleChangeFilter({
                      name: "period",
                      value: e,
                      clear: true,
                    });
                  }}
                />

                <Title
                  className="title_style"
                  mk
                  style={{ maxWidth: "140px", justifyContent: "left" }}
                >
                  Учет затрат{" "}
                  <Checkbox
                    handleChange={() => {
                      setCheck(!check);
                      handleChangeFilter({
                        name: "detalno",
                        value: !check,
                      });
                    }}
                  />
                </Title>
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllReportSemiProducts}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={9} className="mt-16">
              <FlexBox>
                <BaseSelect
                  name="territoriyaId"
                  width={"200px"}
                  options={territoryData}
                  placeholder="Tерритория"
                  value={get(filter, "territoriyaId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.territoriyaId === e.value) return;

                    handleChangeFilter({
                      name: "territoriyaId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      territoriya: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchReportSemiProduct({
                        ...filter,
                        territoriyaId: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Отчет по выпуску полуфабрикатов"}
                  />
                  {!isEmpty(get(data, "dataList", [])) ? (
                    <ReportSemiProductsTable
                      data={data}
                      click={check}
                      typeDate={filter.period}
                    />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : (
                <Row justify="center" className="mt-24">
                  {/* <Title align="center">Нет данных</Title> */}
                </Row>
              )}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default ReportSemiProductsContainer;
