import { TableWrapper } from "./styles/style";
import React from "react";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import { NumericFormat } from "react-number-format";
import moment from "moment";

const RegisterCashReceiptsTable = ({ data }: any) => {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper minWidth="1400px" className="mb-16">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Номер документа</th>
                  <th>Дата документа</th>
                  <th style={{ width: "30vh" }}>Контрагент</th>
                  <th>Договор</th>
                  <th>Тип прихода</th>
                  <th>Сумма</th>
                  <th>Комментарий</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(get(data, "dataList.pkoDTOList", [])) ? (
                  get(data, "dataList.pkoDTOList", []).map(
                    (item: any, index: number) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{get(item, "nomerDoc", "")}</td>
                        <td>
                          {moment(get(item, "dataDoc", "")).format(
                            "DD.MM.YYYY"
                          )}
                        </td>
                        <td>{get(item, "imyaKontragent", "")}</td>
                        <td>{get(item, "nomerDogProdaja", "")}</td>
                        <td>{get(item, "imyaTipPrixoda", "")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summa", "")}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td className="min-w-80"> {get(item, "kommentariya", "")}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={11}>Виберите параметры</td>
                  </tr>
                )}
                <tr className="black_td">
                  <td colSpan={6} style={{ textAlign: "start" }}>
                    Итого:{" "}
                  </td>
                  <td>
                    <NumericFormat
                      displayType="text"
                      value={get(data, "dataList.itogoSumma", "")}
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td>
                </tr>
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RegisterCashReceiptsTable;
