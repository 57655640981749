import { createGlobalStyle } from "styled-components";
import "react-contexify/dist/ReactContexify.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import "react-loading-skeleton/dist/skeleton.css";
export default createGlobalStyle`
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
}
/* modal menage style */
.ant-modal-content{
  padding: 0 !important;
}
.header-cell{
  text-align: center !important;
  /* padding: 10px !important; */
}
.editable-row{
  text-align: center;
  font-size: 12px !important;
  padding: 5px !important;
  margin: 0 !important;
}
.ant-modal-body{
  
  height: ${({ height }: any) => height || "100%"};
   min-height: ${({ minHeight }: any) => minHeight || ""}; 
  ::-webkit-scrollbar {
    width: 0.5em;
    margin: 0.1em;
    border-radius: 50px;
    padding-right: 60px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    outline: 1px solid rgba(0, 0, 0, 0.3);
  }
  @media (max-height: 100px) {
    .ant-modal-content{
      margin-top:15vh;
    }
}
  }
.truncate{
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
  }
/* end modal style */
.p-multiselect.p-inputwrapper:hover {
  border:1px solid hsl(0, 0%, 70%); 
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  box-sizing:"border-box"
}
.react-datepicker-popper {
  z-index: 9999;
}
.p-multiselect.p-inputwrapper:focus {
  border:1px solid hsl(0, 0%, 70%); 
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  box-sizing:"border-box"
}
.p-checkbox{
  margin-right:10px;
}

.overflow_hidden {
  overflow:hidden;
}
.full_height{
  min-height: calc(100vh - 10rem);
  max-height: calc(110vh - 10rem);
}

body {
  font-weight: 400;
  font-size: 16px;
  color: #585757;
  background: #F7F7F7;
  line-height: 1.25;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}

.hovered {
  font-weight: 400;
  font-size: 16px;
  color: #585757;
  background: #F7F7F7;
  line-height: 1.25;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  overflow: visible;
}
.overly{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);  
  z-index: 999; 
  pointer-events:auto;
}
.card{
  width: 100%;
  border: 1px solid black;
  border-radius: 20px;
  height: 100px;
}
.height_control {
  overflow: auto;
  max-height: calc(99vh - 9rem);
 
}

.bottom_position {
  position: fixed !important;
  bottom: 10px !important;
  right:5vh !important;
}

.black_td{
  font-weight: bold;
  td{
    font-weight: bold;
    font-size: 14px;
  }
}
.centrality{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 30vh;
}
.full-height-row {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/* .ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden; 
} */
.pagination_container{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    li{
      display: flex;
        margin-right: 8px;
        cursor: pointer;
        &:last-child{
            margin-right: 0;
        }
        a{
            text-decoration: none;
            padding: 8px 12px;
            background-color: #fff;
            border: 1px solid #E8E8E8;
            color: #1C1C1C;
            border-radius: 8px;
        }
        &.pagination_active{
          a{
              background-color: #0085FF;
              border-color: #0085FF;
              color: #fff;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08);
          }
        }
        &.pagination_disabled{
            a {
                color: #969696;
                cursor: not-allowed;
            }
        }
    }
}
.min-w-50{
  min-width:150px !important;
}
.min-w-80{
  min-width:180px !important;
}
.max-w-20{
  width:100%;
  text-overflow: ellipsis !important;
  min-width:100px !important;
  max-width:200px !important;
  max-height:200px !important;
  line-height:1 !important;
  /* white-space:normal !important; */
  overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

}
.br-20{
  border-radius: 20px !important;
}
.p-30{
  padding: 5px 15px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mr-128 {
  margin-right: 128px !important;
}

.mr-256 {
  margin-right: 256px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mb-256 {
  margin-bottom: 256px !important;
}

.mb-128 {
  margin-bottom: 128px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-4 {
  margin-top: 4px !important;
}


.mt-128 {
  margin-top: 128px !important;
}

.mt-64 {
  margin-top: 64px !important;
}
.mt-50{
  margin-top: 50px !important;
}
.mt-48 {
  margin-top: 48px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-24 {
  margin-top: 24px !important;
}
.mt-16{
  margin-top: 16px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}
.pt-60{
  padding-top: 60px;
}
.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.px-16{
  padding-left: 14px!important;
  padding-right: 14px!important;
}

.px-16{
  padding-left: 16px!important;
  padding-right: 16px!important;
}
.px-24{
  padding-left: 24px!important;
  padding-right: 24px!important;
}
.px-84{
  padding-left: 84px!important;
  padding-right: 84px!important;
}
.px-100{
  padding-left: 100px!important;
  padding-right: 100px!important;
}
.p-32 {
  padding: 32px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-32 {
  padding-left: 32px !important;
}
.pr-0{
  padding-right:0px ;
}
.pr-8 {
  padding-right: 8px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-64 {
padding-right: 64px !important;
}

.pt-62{
  padding-top:62px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 32px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.fixed{
  position: fixed !important;
}

.display_flex_center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.link_color {
  color: #4582F0;
  font-family: 'Inter';
  font-style: normal;
  text-decoration-line: underline;
}
.min_height {
  min-height: calc(100vh - 10rem);
}
.bold_text {
font-family: 'Inter';
font-style: normal;
font-weight: 500 !important;
font-size: 15px !important;
line-height: 24px !important;
text-align: center;
color: #1E1E1E !important;
}
.svg_trans {
  transform: rotate(90deg);
}
.unstyle_anchor_tag {
    text-decoration: none;
    color: inherit;
}

.hover_line {
  &:hover{
  background-color: #f6f6f8;

}
}

.sidebar_bottom {
   width: 100%;
   border-top: solid 0.3px #969696;
   min-height: 5vh;
}
.table_scroll {
  table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  overflow-y: auto ;
}
thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;

}

}
.green_link {
  color: #1849A9;
  font-style: normal;
  font-weight: 400;
}

.link_size-12 {
  font-size: 12px;
}

.link_size-14 {
  font-size: 14px;
}

.link_size-16 {
  font-size: 16px;
}

.link_size-24 {
  font-size: 24px;
}

.w-100{
  width: 100%;
}

.p-16 {
  padding: 16px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.p-50 {
  padding: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mr-24{
  margin-right: 24px !important;
}

.mr-32{
  margin-right: 32px !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-end {
  text-align: end !important;
}

.ml-128 {
  margin-left: 128px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
};

.border {
  border-top: 0.5px solid #E8E8E8 !important;
  border-bottom: 0.5px solid #E8E8E8 !important; 
  border-right:0px solid  !important;
  border-left:0px solid  !important;

}
.minWidth-tdPhone {
  min-width: 180px;
}

.visibility-hidden {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.list-style-type {
  list-style-type: none;
}

.react-datepicker{
  display: flex;
}
.scroll_style {
  overflow-y: auto;
  ::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent; 
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1); 
  border-radius: 1em;
}
::-webkit-scrollbar {
  height: 8px;
  border-radius: 1em;
}
::-webkit-scrollbar-thumb:hover {
  background-color:  rgba(0, 0, 0, 0.2);
}
}
.responsive_overflow{
  overflow: auto;
  width: 100%;
  &:hover{
    cursor: grab;
  }
  &::-webkit-scrollbar {
        cursor: grab;
        width: 100%;
        height: 8px;
        margin: 0.1em;
        border-radius: 50px;
        padding-right: 60px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        outline: 1px solid rgba(0, 0, 0, 0.1);
    }
}
.base-input{
  box-shadow: 0px 1px 1px rgb(0 0 0 / 6%);
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  padding: 8px 12px;
  outline: none;
  color: #1C1C1C;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
}
.reset__link {
  color: #969696;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: #0085FF;
  }
}
.datePicker-menu {
 z-index: 1000;
}
.d-none{
  display: none;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}
.pg-detail hr{
  border-bottom: 1px solid #E8E8E8 !important;
}

.tab_color {
  outline: none;
    color: #0085ff;
    border-bottom: 2px solid #0085ff;
}
.editorClassName {
  width: 100%;
  height: 614px;
  border: 1px solid #E8E8E8;
}
.material-color {
   
    td{
    color:#969696;
    font-size: 10px;
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
}

.eye_icon {
  cursor: pointer;
  z-index: 999;
}
.delete_button {
  cursor: pointer;
  z-index: 999;
  width: 100%;
  svg {             
    width:10px;
     path {
        fill:#d42a1e !important;
          stroke-dashoffset: 0;
            stroke-dasharray: 700;
                    }
                    span{
                      font-size: 10px;
                    }
                }
}
.svg_black{
  svg {             
     path {
        fill:#000000 !important;
          stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
          
     }
}
.action_button{
  width:100%;
  display: flex;
  font-size: 16px;
  font-weight:500;
  gap:5px;
  padding:6px;  
  text-align:center;
  cursor: pointer;
}
.form_button{
  border: none;
  outline: none;
  text-decoration: none;
  border-radius: 8px;
  background-color: #0085ff;
  text-align: center;
  align-items: center;
  color:"#ffffff";
  cursor: pointer;
   font-family: Arial, Helvetica, sans-serif;
   font-size: 16px;
}
hr {
  border: 0.5px solid #E8E8E8 !important;
}

@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
  .print{
    width: 1800px !important;
    background-color:red;
    max-width: unset !important;
    flex: 0 0 100% !important;
  }
  .pg-detail{
    box-shadow: unset;
  }
}
/*  animations sidebar  */
@keyframes slide-in {
  0% { opacity: 0; transform: translateX(-100%); }
  100% { opacity: 1; transform: translateX(0); }
}

.component_animate {
  animation: slide-in 0.2s ease; 
}

.component_animate_b {
  animation: slide-in 0.1s ease; 
}
.primary {
  color: #2050F5
}

.danger {
  color: #D80027;
}

.done_icon{
  border-radius: 50%;
  margin-right:10%;
  float: right;
  z-index: 100;
  text-align: right;
  align-items: center;
  width: 6px;
  height: 6px;
  transform: translate(-50%, -50%);
  align-items: center;
  background-color:#f34079 ;
}

.required_point {
  color:red;
  font-size:20px;
  position: absolute;
  margin-left: 8px;
}
.required {
  color:red;
  font-size: 12px;
  position: absolute;
  text-align: start;
}

.title_style {
  display: flex;
  width:100%;
  height: 35px;
  text-align: center;
  justify-content: center;
  max-width: 130px;
  gap:20px;
  border: 1px solid hsl(0, 0%, 80%);
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
   padding: 8px 12px;
  border-radius: 8px;
}
.temps_design{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  background-image: linear-gradient(135deg, #f34079 40%, #fc894d);
  border: 0;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  span {
  transition: all 200ms;
}

  &:hover span {
  transform: scale(1.3);
  opacity: 0.9;
}
}

.border {
  border: 1px solid black;
}

.light-border {
  border: 1px solid #e8e8e8;
}

.pagination_position {
  position: absolute;
  bottom: 16px;
  width: 100%;
}

.mt-12 {
  margin-top: 12px !important;
}

a {
  text-decoration: none;
}
.td_action {
  max-width: 10px;
  padding-left: 0px !important;
}
.context_item {
  &:hover{
    svg{
      path {
           font-size: 5px;
           stroke: #ffffff;
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
    }
    
  }
}
.change_icon_color_stroke{
  &:hover,&:active{
    svg{
      path {
           font-size: 5px;
           stroke: #ffffff;
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
    }
    
  }
}
.change_icon_color_fill{
  &:hover,&:active{
    svg{
 
      path {
        background-color: #1C1C1C;
        fill: #ffffff;
           font-size: 5px;
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
    }
    
  }
}
.form-input-date_picker {
  width: 100% !important;
  max-width: 350px !important;
}

.logout_icon {
  svg{
     width: 55px;
    font-size: 5px;
   }
}

.carousel-button {
  /* position: fixed; */
  position: absolute;
  /* top: 40%; */
  bottom:100% ;
  transform: translateY(-500%);
  background-color: transparent;
  border: none;
  /* width: 100px; */
  font-size: 10px;
  text-align: center;
  align-items: center;
  color: #1890ff;
}

.carousel-button-left {
  left: 10px;
  align-items: center;
}

.carousel-button-right {
  right: 10px;
  align-items: center;
}

.svg_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding:5px;
    font-size:16px;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.svg_icon_context {
  display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size:12px;
    padding:5px;
    svg {
      font-size: 5px;
      width: 16px;
      pointer-events: auto !important;
      opacity: 1;
      display: flex;
          path {
           font-size: 5px;
          
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
        }
    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.svg_18{
  svg{
    width:20px;
  }
  
}


`;
