import { Button, FlexBox, Logo, ReactModal, Title } from "components";
import Text from "components/Text/Text";
import { Props } from "./type";
import { Avatar, ProfileContainer } from "./style";
import { ReactSVG } from "react-svg";
import logout from "assets/images/icons/logout.svg";
import { memo, useCallback, useMemo, useState } from "react";
import avatar from "assets/images/ic_person.png";
import { useAppDispatch, useAppSelector } from "app/hook";
import { logOut } from "app/slices/authSlices/authSlices";
import quizIcon from "assets/images/icons/quiz.svg";
import { profileData } from "app/states/profile";
import { get } from "lodash";
import { Col } from "react-grid-system";
import { clearProfile } from "app/slices/profileSlices/profileSlices";
import AuthServices from "services/apiServices/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { Main } from "routers/paths";
import { Row } from "antd";
// Logout
const Logout = ({ handleCloseModal }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    AuthServices.LogOut()
      .then((res) => {
        if (res.status === 200) {
          dispatch(logOut());
          dispatch(clearProfile());
          navigate(Main.LOGIN, { replace: true });
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <Col xs={12} className="mt-16 text-left px-24">
        <Title sm bold>
          Внимание
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <FlexBox gap={"25px"} justify="center" className={"my-16"}>
        {/* <ReactSVG src={quizIcon} className="logout_icon" /> */}
        <Title nm>Вы действительно хотите выйти из системы ?</Title>
      </FlexBox>
      <Col xs={12} className="text-right py-16 px-24">
        <Button className="mr-8" handleClick={handleLogout} green>
          Да
        </Button>
        <Button onClick={handleCloseModal} danger>
          Нет
        </Button>
      </Col>
    </>
  );
};

const Profile = ({ userPic = "" }: Props) => {
  const profile = useAppSelector(profileData);
  const [openModal, setOpenModal] = useState(false);

  const userName = useMemo(
    () => get(profile, "profileData.imyaPolzovateli", []),
    [profile]
  );

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  return (
    <ProfileContainer>
      <ReactModal isOpen={openModal}>
        <Logout handleCloseModal={handleCloseModal} />
      </ReactModal>
      <FlexBox justify={"space-between"} style={{ gap: "10px" }}>
        <div>
          <Avatar src={avatar} />
        </div>
        <div>
          <Text medium dark>
            {userName}
          </Text>
        </div>
        {/* <ReactSVG src={dropDownIcon} className={"svg_icon ml-8"} /> */}
        <div onClick={() => setOpenModal(true)} className="ml-8">
          <ReactSVG src={logout} className={"svg_icon"} />
        </div>
      </FlexBox>
    </ProfileContainer>
  );
};

export default memo(Profile);
