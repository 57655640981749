import { createSelector } from "@reduxjs/toolkit";
import {
  selectDeliveryMethod,
  selectDeliveryMethodFilter,
  selectIncome,
  selectIncomeFilter,
  selectLegalPhysPerson,
  selectLegalPhysPersonFilter,
  selectPaymentMethod,
  selectPaymentMethodFilter,
  selectRoundOrder,
  selectRoundOrderFilter,
  selectRoundOrderMethods,
  selectTypeContract,
  selectTypeContractFilter,
  selectTypeFlow,
  selectTypeFlowFilter,
  selectTypeSubstandard,
  selectTypeSubstandardFilter,
  selectTypeWriteOff,
  selectTypeWriteOffFilter,
} from "app/selectors/enumerations/enumerationsSelectors";
// payment methods
export const paymentMethodData = createSelector(
  selectPaymentMethod,
  (data) => data
);
export const paymentMethodFilter = createSelector(
  selectPaymentMethodFilter,
  (filter) => filter
);
// delivery method
export const deliveryMethodData = createSelector(
  selectDeliveryMethod,
  (data) => data
);
export const deliveryMethodFilter = createSelector(
  selectDeliveryMethodFilter,
  (filter) => filter
);
// type income
export const incomeData = createSelector(selectIncome, (data) => data);
export const incomeFilter = createSelector(
  selectIncomeFilter,
  (filter) => filter
);
// type Flow
export const typeFlowData = createSelector(selectTypeFlow, (data) => data);
export const typeFlowFilter = createSelector(
  selectTypeFlowFilter,
  (filter) => filter
);
// type Writeoff
export const typeWriteOffData = createSelector(
  selectTypeWriteOff,
  (data) => data
);
export const typeWriteOffFilter = createSelector(
  selectTypeWriteOffFilter,
  (filter) => filter
);
// legal phys person
export const legalPhysPersonData = createSelector(
  selectLegalPhysPerson,
  (data) => data
);
export const legalPhysPersonFilter = createSelector(
  selectLegalPhysPersonFilter,
  (filter) => filter
);
// type contract
export const typeContractData = createSelector(
  selectTypeContract,
  (data) => data
);
export const typeContractFilter = createSelector(
  selectTypeContractFilter,
  (filter) => filter
);
// round order
export const roundOrderData = createSelector(
  selectRoundOrder,
  (data) => data
);
export const roundOrderFilter = createSelector(
  selectRoundOrderFilter,
  (filter) => filter
);

export const roundOrderMethods = createSelector(
  selectRoundOrderMethods,
  (data) => data
);

export const typeWriteSubstandardFilter = createSelector(
  selectTypeSubstandardFilter,
  (filter) => filter
);

export const typeWriteSubstandard = createSelector(
  selectTypeSubstandard,
  (data) => data
);
