import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
    data: { loading: false, productData: [], pagination: {}, error: "" },
    filter: {
        page: 0,
        size: 10,
        tip:"PF"
    },
};

export const fetchSemiProductMaterial = createAsyncThunk(
    "allProduct/fetchSemiProductMaterial",
    async (params:any, thunkAPI) => {
        try {
            const request = await HandBookServices.GetRawMaterial(params);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const semiProductSlices = createSlice({
    name: "allProduct",
    initialState,
    reducers: {
        handleProductFilter: (state, action) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSemiProductMaterial.pending, (state) => {
            state.data = {
                loading: true,
                productData: [],
                pagination: {},
                error: "",
            };
        });
        builder.addCase(fetchSemiProductMaterial.fulfilled, (state, action) => {
            state.data = {
                loading: false,
                productData: get(action, "payload.data", []),
                pagination: get(action, "payload.pagination", {}),
                error: "",
            };
        });
        builder.addCase(fetchSemiProductMaterial.rejected, (state, payload) => {
            state.data = {
                loading: false,
                productData: [],
                pagination: {},
                error: payload.error.message,
            };
        });
    },
});

export const { handleProductFilter } = semiProductSlices.actions;
export default semiProductSlices.reducer;
