import { combineReducers } from "@reduxjs/toolkit";
import registerSalesSlices from "./registerSalesSlices/registerSalesSlices";
import materialReportSlices from "./materialReportSlices/materialReportSlices";
import operationalReportSlices from "./operationalReportSlices/operationalReportSlices";
import procurementPlanSlices from "./procurementPlanSlices/procurementPlanSlices";
import reportReleasesSlices from "./reportReleasesSlices/reportReleasesSlices";
import analysisExecutionSlices from "./analysisExecutionSlices/analysisExecutionSlices";
import commodityReportSlices from "./commodityReportSlices/commodityReportSlices";
import balanceSheetSlices from "./blancaSheetSlices/blancaSheetSlices";
import registerCashReceiptsSlices from "./registerCashReceiptsSlices/registerCashReceiptsSlices";
import registerCashExpenseSlices from "./registerCashExpenseSlices/registerCashExpenseSlices";
import workOrderRegisterSlices from "./workOrderRegisterSlices/workOrderRegisterSlices";
import registerInvoiceSlices from "./registerInvoiceSlices/registerInvoiceSlices";
import reportSemiProductSlices from "./reportSemiProductSlices/reportSemiProductSlices";
import reportShipmentSlices from "./reportShipmentSlices/reportShipmentSlices";
import reportDefectsSlices from "./reportDefectsSlices/reportDefectsSlices";
import registerPurchasesSlices from "./registerPurchaseSlices/registerPurchaseSlices";
import reportDriverSlices from "./reportDriverSlices/reportDriverSlices";
import reportPurchaseSlices from "./reportPurchaseSlices/reportPurchaseSlices";
import operationalCashReportSlices from "./operationalCashReportSlices/operationalCashReportSlices";
import cashReportSlices from "./cashReportSlices/cashReportSlices";
import buyerReportSlices from "./buyerReportSlices/buyerReportSlices";
import supplierReportSlices from "./supplierReportSlices/supplierReportSlices";
import accountableReportSlices from "./accountableReportSlices/accountableReportSlices";
import reconciliationActSlices from "./reconciliationActSlices/reconciliationActSlices";
import driversShipmentMaterialSlices from "./driversShipmentMaterialSlices/driversShipmentMaterialSlices";
import operationalProductBalanceSlices from "./operationalProductBalanceSlices/operationalProductBalanceSlices";
import reportFinishedSlices from "./reportFinishedSlices/reportFinishedSlices";
import reportSoldFinishedSlices from "./reportSoldFinishedSlices/reportSoldFinishedSlices";

const reducer = combineReducers({
  registerSalesTable: registerSalesSlices,
  registerCashTable: registerCashReceiptsSlices,
  registerCashExpense: registerCashExpenseSlices,
  materialReportTable: materialReportSlices,
  operationalTable: operationalReportSlices,
  procurementTable: procurementPlanSlices,
  reportReleasesTable: reportReleasesSlices,
  analysisExecutionTable: analysisExecutionSlices,
  commodityReportTable: commodityReportSlices,
  balanceSheetTable: balanceSheetSlices,
  workOrderRegister: workOrderRegisterSlices,
  registerInvoice: registerInvoiceSlices,
  reportSemiProduct: reportSemiProductSlices,
  reportShipmentTable: reportShipmentSlices,
  reportDefectsTable: reportDefectsSlices,
  registerPurchasesTable: registerPurchasesSlices,
  reportDriverTable: reportDriverSlices,
  reportPurchaseTable: reportPurchaseSlices,
  operationalCashTable: operationalCashReportSlices,
  cashReportTable: cashReportSlices,
  buyerReportTable: buyerReportSlices,
  supplierReportTable: supplierReportSlices,
  accountableReportTable: accountableReportSlices,
  reconciliationActTable: reconciliationActSlices,
  driversShipmentMaterialTable: driversShipmentMaterialSlices,
  operationalBalance: operationalProductBalanceSlices,
  reportFinishedTable: reportFinishedSlices,
  reportSoldFinishedTable: reportSoldFinishedSlices,
});

export default reducer;
