import { RootState } from "app/store";

export const selectPivotTableStatic = (store:RootState) => store.registers.pivotTable.pivotStatic

export const selectPivotTable = (store: RootState) =>
  store.registers.pivotTable.data;
export const selectPivotTableFilter = (store: RootState) =>
  store.registers.pivotTable.filter;

export const selectRemainTable = (store: RootState) =>
  store.registers.remainTable.data;
export const selectRemainTableFilter = (store: RootState) =>
  store.registers.remainTable.filter;
export const selectRemainTableStatic = (store: RootState) => 
 store.registers.remainTable.remainStatic