import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import AuthServices from "services/apiServices/auth";

const initialState: any = {
    loading: false,
    isAuthenticated: false,
    token: "",
    userData: {},
    error: "",
};

export const logIn = createAsyncThunk(
    "auth/logIn",
    async (params: any, thunkAPI) => {
        try {
            const request = await AuthServices.Login(params);
            if (get(request, "status", "") != 200) {
                return thunkAPI.rejectWithValue(get(request, "message", ""));
            }
            const respond = await request.data;
            return respond;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

const authSlices = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logOut: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(logIn.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(logIn.fulfilled, (state, actions) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.token = get(actions, "payload.data", "");
            state.userData = get(actions, "payload", "");
        });
        builder.addCase(logIn.rejected, (state, actions) => {
            state.isAuthenticated = false;
            state.loading = false;
            state.token = "";
            state.userData = {};
            state.error = get(actions, "error.message", "");
        });
    },
});

export const { logOut } = authSlices.actions;
export default authSlices.reducer;
