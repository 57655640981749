import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchMenageAccount,
  handleMenageAccountsFilter,
  handleClearFilter
} from "app/slices/handbookSlices/menageAccountSlices/menageAccountSlices";
import { MenageAccountDataType } from "app/slices/handbookSlices/menageAccountSlices/type";
import {
  allMenageAccountData,
  allMenageAccountFilter,
} from "app/states/handbooks";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import HandBookServices from "services/apiServices/spravochnik";
//  Счета управленческого учета
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const onSubmit = () => {
    try {
      HandBookServices.AddNewMenageAccounts({
        data: values,
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        if (res && res.data) {
          handleCloseModal();
          afterSubmitAction();
        }
      });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      nomerScheta: "",
      groupId: 0,
      imya: "",
      kol: false,
      subkonto1Id: 0,
      subkonto2Id: null,
      subkonto3Id: null,
    },
    onSubmit,
  });
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center">
            <Col xs={4}>
              <Title>Номер счета</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                clear={get(values, "conditions", "")}
                handleInput={(e: string) => {
                  setFieldValue("conditions", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>ID группы</Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"payment"}
                isSearchable
                value={get(values, "payment", "")}
                options={[
                  {
                    value: "PAYMENT",
                    label: "Payment",
                  },
                ]}
                handleChange={(e: any) => {
                  setFieldValue("payment", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Наименование счета</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                clear={get(values, "conditions", "")}
                handleInput={(e: string) => {
                  setFieldValue("conditions", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Наименование группы</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                clear={get(values, "conditions", "")}
                handleInput={(e: string) => {
                  setFieldValue("conditions", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Kоличественный учет</Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"payment"}
                isSearchable
                value={get(values, "payment", "")}
                options={[
                  {
                    value: "PAYMENT",
                    label: "Payment",
                  },
                ]}
                handleChange={(e: any) => {
                  setFieldValue("payment", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Cубконто_1</Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"payment"}
                isSearchable
                value={get(values, "payment", "")}
                options={[
                  {
                    value: "PAYMENT",
                    label: "Payment",
                  },
                ]}
                handleChange={(e: any) => {
                  setFieldValue("payment", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Cубконто_2</Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"payment"}
                isSearchable
                value={get(values, "payment", "")}
                options={[
                  {
                    value: "PAYMENT",
                    label: "Payment",
                  },
                ]}
                handleChange={(e: any) => {
                  setFieldValue("payment", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>Cубконто_3</Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"payment"}
                isSearchable
                value={get(values, "payment", "")}
                options={[
                  {
                    value: "PAYMENT",
                    label: "Payment",
                  },
                ]}
                handleChange={(e: any) => {
                  setFieldValue("payment", e);
                }}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" onClick={handleCloseModal} green>
              Добавить
            </Button>
            <Button onClick={handleCloseModal} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const ManagementAccounts = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(allMenageAccountData);
  const filter = useAppSelector(allMenageAccountFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleMenageAccountsFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchMenageAccount(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(fetchMenageAccount({ ...filter, search: "" }));
    dispatch(
      handleMenageAccountsFilter({
        name: "search",
        value: "",
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    return () => {
      dispatch(handleClearFilter());
    }
  }, [])
  const fetchAllMenageAccountsData = useCallback(() => {
    dispatch(fetchMenageAccount(filter));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  return (
    <Row>
      <Col xs={12} style={{ minHeight: "50vh" }}>
        <Content className="full_height">
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllMenageAccountsData()}
            />
          </ReactModal>
          <Row>
            <Col >
              <WindowHeight >
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "ID",
                    "Пользователь",
                    "Счет",
                    "Счет группы",
                    "Наименование счета",
                    "Наименование группы",
                    "Kоличественный учет",
                    "Cубконто_1",
                    "Cубконто_2",
                    "Cубконто_3",
                  ]}
                >
                  {isEqual(LOADING, false) ? (
                    <>
                      {!isEmpty(get(data, "menageAccountData", [])) ? (
                        get(data, "menageAccountData", []).map(
                          (item: MenageAccountDataType) => (
                            <tr key={item.id}>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerScheta", "-")}</td>
                              <td>{get(item, "nomerSchetaGruppi", "-")}</td>
                              <td>{get(item, "imya", "-")}</td>
                              <td>{get(item, "imyaGruppi", "-")}</td>
                              <td>
                                {isEqual(get(item, "kol", false), true) && "+"}
                              </td>
                              <td>{get(item, "imyaSubkonto1", "-")}</td>
                              <td>{get(item, "imyaSubkonto2", "-")}</td>
                              <td>{get(item, "imyaSubkonto3", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={10} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={10}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default ManagementAccounts;
