import { memo } from "react";
import { Props } from "./type";
import { Button } from "antd";
const FormButton = ({
  disabled = false,
  handleClick = () => {},
  children,
  green = false,
  color = "",
  loading = false,
  ...props
}: Props) => {
  return (
    <>
      {disabled ? (
        ""
      ) : (
        <Button
          style={{ background: color }}
          loading={loading}
          type="primary"
          onClick={handleClick}
          className="mr-16 form_button"
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default memo(FormButton);
