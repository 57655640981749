import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, PurchaseTableDetails } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";
import axios from "axios";
const initialState: InitialState = {
    purchaseCnts: {
        loading: false,
        data: [],
        error: "",
    },
    notFilledPurchaseList: {
        loading: false,
        data: [],
        error: "",
    },
    filter: {
        search: "",
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    formData: {
        loading: false,
        error: "",
        isTempActive: false,
        data: {
            details: [],
        },
    },
    purchaseContractStat: {
        loading: false,
        data: {},
        error: "",
    },
};

export const fetchPurchaseContracts = createAsyncThunk(
    "registerPurchase/fetchPurchaseContracts",
    async (params: any, { rejectWithValue, signal }) => {
        try {
            const soure = axios.CancelToken.source()
            signal.addEventListener('abort', () => {
                soure.cancel()
            })
            const request = await DocumentServices.FetchPurchases({ ...params, cancelToken: soure.token, });
            const respond = await request.data;
            return respond;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const fetchNotFilledPurchaseContracts = createAsyncThunk(
    "registerPurchase/fetchNotFilledPurchaseContracts",
    async (params, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchNotFilledPurchases(
                params
            );
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchPurchaseContractByTempId = createAsyncThunk(
    "registerPurchase/fetchPurchaseContractByTempId",
    async (id: string, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchNotFilledPurchaseById(
                id
            );
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchPurchaseContractById = createAsyncThunk(
    "registerPurchase/fetchPurchaseContractById",
    async (id: string, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchPurchasesById(id);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchPurchaseContractsStatistics = createAsyncThunk(
    "registerPurchase/fetchPurchaseContractsStatistics",
    async (_, thunkAPI) => {
        try {
            const request =
                await DocumentServices.FetchPurchaseContractsStatistics();
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const registerPurchasesSlice = createSlice({
    name: "registerPurchase",
    initialState,
    reducers: {
        addSubjectPurchase: (state, action) => {
            state.formData = {
                ...state.formData,
                data: {
                    ...state.formData.data,
                    details: !isEmpty(get(action, "payload", {}))
                        ? [...state.formData.data.details, action.payload]
                        : [],
                },
            };
        },

        movementPurchaseSubject: (state, action) => {
            const id = get(action, "payload.id", null);
            switch (action.payload.type) {
                case ACTION.ADD:
                    state.formData = {
                        ...state.formData,
                        data: {
                            ...state.formData.data,
                            details: !isEmpty(get(action, "payload.data", []))
                                ? [
                                    ...state.formData.data.details,
                                    action.payload.data,
                                ]
                                : [],
                        },
                    };
                    break;
                case ACTION.UPDATE:
                    state.formData = {
                        ...state.formData,
                        data: {
                            ...state.formData.data,
                            details: state.formData.data.details.map(
                                (item: PurchaseTableDetails) =>
                                    item.materialId == id
                                        ?
                                        get(action, "payload.data", {})
                                        : item
                            ),
                        },
                    };
                    break;
                case ACTION.DELETE:
                    state.formData = {
                        ...state.formData,
                        data: {
                            ...state.formData.data,
                            details: state.formData.data.details.filter(
                                (item: PurchaseTableDetails) => item.materialId !== id
                            ),
                        },
                    };
                    break;
                default:
                    break;
            }
        },
        // change material VAT price and VAT when change select 
        changeVATPrice: (state, action) => {
            state.formData = {
                ...state.formData,
                data: {
                    ...state.formData.data,
                    details: state.formData.data.details.map(
                        (item: any, index: number) =>
                            index === action.payload.id ? {
                                ...item,
                                summaNDS: get(
                                    action,
                                    "payload.value",
                                    0
                                ),
                                nds: get(action, "payload.nds", 0),
                                vsego: get(action, "payload.vsego", 0)
                            } : item
                    ),
                },
            };
        },

        handleFilterChangePurchases: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },

        handleClearFormData: (state) => {
            state.formData = initialState.formData;
        },

        clearPurchaseProductsState: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPurchaseContracts.pending, (state) => {
            state.purchaseCnts = {
                loading: true,
            };
        });
        builder.addCase(fetchPurchaseContracts.fulfilled, (state, actions) => {
            state.purchaseCnts = {
                loading: false,
                data: get(actions, "payload.data", []),
                pagination: get(actions, "payload.pagination", {}),
                error: "",
            };
        });
        builder.addCase(fetchPurchaseContracts.rejected, (state, actions) => {
            state.purchaseCnts = {
                loading: false,
                data: [],
                pagination: {},
                error: actions.error.message,
            };
        });

        // fetching not full filled contracts
        builder.addCase(fetchNotFilledPurchaseContracts.pending, (state) => {
            state.notFilledPurchaseList = {
                loading: true,
            };
        });
        builder.addCase(
            fetchNotFilledPurchaseContracts.fulfilled,
            (state, actions) => {
                state.notFilledPurchaseList = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchNotFilledPurchaseContracts.rejected,
            (state, actions) => {
                state.notFilledPurchaseList = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                };
            }
        );

        // fetching not full filled contract by id
        builder.addCase(fetchPurchaseContractByTempId.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(
            fetchPurchaseContractByTempId.fulfilled,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                    isTempActive: true,
                };
            }
        );
        builder.addCase(
            fetchPurchaseContractByTempId.rejected,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                    isTempActive: false,
                };
            }
        );
        // by id
        builder.addCase(fetchPurchaseContractById.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(
            fetchPurchaseContractById.fulfilled,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                    isTempActive: true,
                };
            }
        );
        builder.addCase(
            fetchPurchaseContractById.rejected,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                    isTempActive: false,
                };
            }
        );

        // fetching purchase contracts statists
        builder.addCase(fetchPurchaseContractsStatistics.pending, (state) => {
            state.purchaseContractStat = {
                loading: true,
            };
        });
        builder.addCase(
            fetchPurchaseContractsStatistics.fulfilled,
            (state, actions) => {
                state.purchaseContractStat = {
                    loading: false,
                    data: get(actions, "payload.data", {}),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchPurchaseContractsStatistics.rejected,
            (state, actions) => {
                state.purchaseContractStat = {
                    loading: false,
                    data: {},
                    error: actions.error.message,
                };
            }
        );
    },
});

export const {
    addSubjectPurchase,
    handleFilterChangePurchases,
    handleClearFormData,
    clearPurchaseProductsState,
    movementPurchaseSubject,
    changeVATPrice,
} = registerPurchasesSlice.actions;
export default registerPurchasesSlice.reducer;
