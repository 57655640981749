import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, otgruzkaGPDetailsType } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
  contractStatProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      otgruzkaGPDetails: [],
    },
  },
  filter: {
    fromDate: null,
    toDate: null,
    vidTovaraId: "",
    page: 0,
    size: 10,
  },
  shipmentProduct: {
    loading: false,
    productList: [],
    pagination: {},
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchShipmentProduct = createAsyncThunk(
  "shipmentProduct/fetchShipmentProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchShipmentProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchNotFilledShipmentProduct = createAsyncThunk(
  "shipmentProduct/fetchNotFilledShipmentProduct",
  async (params, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledShipmentProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchShipmentProductsTempById = createAsyncThunk(
  "shipmentProduct/fetchShipmentProductsTempById",
  async (id: string, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledShipmentProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchShipmentProductsById = createAsyncThunk(
  "shipmentProduct/fetchShipmentProductsById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchShipmentProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchShipmentProductStat = createAsyncThunk(
  "shipmentProduct/fetchShipmentProductStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchShipmentProductStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const shipmentProductSlices = createSlice({
  name: "shipmentProduct",
  initialState,
  reducers: {
    handleAddSubjectsShipment: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          otgruzkaGPDetails: !isEmpty(get(actions, "payload", {}))
            ? actions.payload
            : [],
        },
      };
    },
    // movemnt
    movementShipmentSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            otgruzkaGPDetails: !isEmpty(get(action, "payload.data", []))
              ? [...state.formData.data.otgruzkaGPDetails, action.payload.data]
              : [],
          },
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            otgruzkaGPDetails: state.formData.data.otgruzkaGPDetails.map(
              (item: otgruzkaGPDetailsType) =>
                item.tovarId == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            otgruzkaGPDetails: state.formData.data.otgruzkaGPDetails.filter(
              (item: otgruzkaGPDetailsType) => item.tovarId !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            otgruzkaGPDetails: [],
          },
        };
      }
    },
    handleChangeItemSubjects: (state, action) => {
      const id = get(action, "payload.id", 0);
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          otgruzkaGPDetails: state.formData.data.otgruzkaGPDetails.map(
            (item: any, index: number) =>
              index == id
                ? {
                    ...item,
                    kol: get(action, "payload.value", 0),
                  }
                : item
          ),
        },
      };
    },
    handleFilterChangeShipment: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearAllDataShipment: () => initialState,
    clearFormDataShipment: (state) => {
      state.formData = initialState.formData;
    },
    clearFormDataShipmentProduct: (state) => {
      state.shipmentProduct = initialState.shipmentProduct;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchShipmentProduct.pending, (state) => {
      state.shipmentProduct = {
        loading: true,
      };
    });

    builder.addCase(fetchShipmentProduct.fulfilled, (state, actions) => {
      state.shipmentProduct = {
        loading: false,
        productList: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchShipmentProduct.rejected, (state, actions) => {
      state.shipmentProduct = {
        loading: false,
        productList: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    builder.addCase(fetchNotFilledShipmentProduct.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(
      fetchNotFilledShipmentProduct.fulfilled,
      (state, actions) => {
        state.notFilledCntrList = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchNotFilledShipmentProduct.rejected,
      (state, actions) => {
        state.notFilledCntrList = {
          loading: false,
          data: [],
          error: actions.error.message,
        };
      }
    );
    //  fetch temps
    builder.addCase(fetchShipmentProductsTempById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(
      fetchShipmentProductsTempById.fulfilled,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
          isTempActive: true,
        };
      }
    );
    builder.addCase(
      fetchShipmentProductsTempById.rejected,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: [],
          error: actions.error.message,
          isTempActive: false,
        };
      }
    );
    //  fetch detals
    builder.addCase(fetchShipmentProductsById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchShipmentProductsById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchShipmentProductsById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });

    // fetching sale contracts statists
    builder.addCase(fetchShipmentProductStat.pending, (state) => {
      state.contractStatProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchShipmentProductStat.fulfilled, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchShipmentProductStat.rejected, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleAddSubjectsShipment,
  handleFilterChangeShipment,
  clearFormDataShipment,
  clearAllDataShipment,
  clearFormDataShipmentProduct,
  handleChangeItemSubjects,
  movementShipmentSubject,
} = shipmentProductSlices.actions;
export default shipmentProductSlices.reducer;
