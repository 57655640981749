import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterDetails,
  fetchNotFilledSalesProduct,
  fetchSalesProduct,
  fetchSalesProductStat,
  handleClearFormDataSales,
  handleFilterChangeSalesProduct,
} from "app/slices/documentSlices/salesProductSlices/salesProductSlices";
import {
  saleTempsSalesProductList,
  salesProductFilter,
  salesProductList,
  salesProductStat,
} from "app/states/documents";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Documents } from "routers/paths";
import delate from "assets/images/icons/delate.svg";
import { ReactSVG } from "react-svg";
import DocumentServices from "services/apiServices/documents";
import { toast } from "react-toastify";
import { getProductData } from "app/states/handbooks/selectStates";
import HasAccess from "routers/HasAccess";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";

// Реализация готовой продукции
const SalesProductsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_SALES_PRODUCT
  );
  const dispatch = useAppDispatch();
  const salesProducts = useAppSelector(salesProductList);
  const filter = useAppSelector(salesProductFilter);
  const salesTempsContract = useAppSelector(saleTempsSalesProductList);
  const shipmentStat = useAppSelector(salesProductStat);
  const LOADING_TABLE = get(salesProducts, "loading", []);
  const tovariiOptions = useAppSelector(getProductData);
  let loadingKontragent = useAppSelector(loadingConterpart);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchAllSalesProducts = useCallback(() => {
    dispatch(fetchSalesProduct(filter));
    dispatch(fetchSalesProductStat());
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchSalesProduct({
        ...filter,
        kontragentId: null,
        fromDate: null,
        toDate: null,
        tovarId: null,
      })
    );
    dispatch(clearFilterDetails());
  }, [dispatch, filter]);

  const fetchSalesNotFilledContracts = useCallback(() => {
    dispatch(fetchNotFilledSalesProduct());
    dispatch(fetchSalesProductStat());
  }, [dispatch]);

  const navigateSaleContractForm = (param: { id: string; isTemp: boolean }) => {
    navigate(Documents.ADD_SALES_PRODUCT, {
      state: param,
    });
  };

  const handleAddButton = () => {
    dispatch(handleClearFormDataSales());
    navigate(Documents.ADD_SALES_PRODUCT);
  };

  const handleTempButton = (id: string) => {
    DocumentServices.RemoveSalesTempContractById(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchSalesNotFilledContracts();
      }
    });
  };

  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteSalesProductById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllSalesProducts();
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsSalesProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllSalesProducts();
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsSalesProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllSalesProducts();
      }
    });
  };
  useEffect(() => {
    fetchSalesNotFilledContracts();
    dispatch(fetchAllCounterpart({ all: true }));
    return () => {
      dispatch(clearFilterDetails());
    };
  }, [fetchSalesNotFilledContracts]);

  useEffect(() => {
    fetchAllSalesProducts();
  }, [filter.page, filter.size]);

  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={shipmentStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger handleClick={handleClearFilter} className="mr-16">
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "REALIZATSIYA_GP_CREATE",
                    page: "REALIZATSIYA_GP",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={9}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  placeholder={"Контрагент"}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  isLoading={loadingKontragent}
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeSalesProduct({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  isClearable
                  options={tovariiOptions}
                  placeholder="Товар"
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeSalesProduct({
                        name: "tovarId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "tovarId", null)}
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeSalesProduct({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeSalesProduct({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={3}>
              <Button primary handleClick={fetchAllSalesProducts}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Заказ-наряд",
                    "Контрагент",
                    "Договор",
                    "Способ оплаты",
                    "Територя",
                    "Сумма",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      <ContextMenu
                        handleNavigate={navigateSaleContractForm}
                        deleteDocument={handleDeleteButton}
                        deleteItem={true}
                        markItem={true}
                        uncheckItem={true}
                        userPermission={"REALIZATSIYA_GP"}
                        handleDeleteRecords={handleDeleteRecords}
                        handleUndoRecords={handleUndoRecords}
                        documentInfo={documentInfo}
                      />
                      {!isEmpty(get(salesTempsContract, "data", [])) &&
                        get(salesTempsContract, "data", []).map((item: any) => (
                          <tr
                            key={get(item, "id", 0)}
                            onClick={() =>
                              navigateSaleContractForm({
                                isTemp: true,
                                id: get(item, "id", null),
                              })
                            }
                            className="cursor-pointer"
                          >
                            <td>
                              <div
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  handleTempButton(get(item, "id", null));
                                }}
                                className="delete_button"
                              >
                                <ReactSVG
                                  src={delate}
                                  className="delete_button"
                                />
                              </div>
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "nomerDoc", "")}</td>
                            <td>
                              {!isEmpty(get(item, "dataDoc", ""))
                                ? moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>{get(item, "nomerZakazNaryad", "")}</td>
                            <td>{get(item, "imyaKontragent", "")}</td>
                            <td>{get(item, "nomerDogProdaja", "")}</td>
                            <td>{get(item, "imyaSposobOplati", "")}</td>
                            <td>{get(item, "imyaTerritoriya", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "vsegoSumma", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              {!isEmpty(get(item, "createdAt", ""))
                                ? moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>{get(item, "status", "-")}</td>
                          </tr>
                        ))}

                      {!isEmpty(get(salesProducts, "productList", []))
                        ? get(salesProducts, "productList", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", index + 1)}
                                onClick={() =>
                                  navigateSaleContractForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      salesProducts,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      salesProducts,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "dataDoc", ""))
                                    ? moment(get(item, "dataDoc", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "nomerZakazNaryad", "")}</td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "nomerDogProdaja", "")}</td>
                                <td>{get(item, "imyaSposobOplati", "")}</td>
                                <td>{get(item, "imyaTerritoriya", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "vsegoSumma", 0)}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(salesTempsContract, "data", [])) && (
                            <Nodata colLength={11} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={11}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(salesProducts, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(salesProducts, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChangeSalesProduct({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    salesProducts,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(salesProducts, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChangeSalesProduct({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default SalesProductsContainer;
