import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState, FilterType } from "./type";
import { get } from "lodash";
import RegisterServices from "services/apiServices/registers";

const initialState: InitialState = {
  remainStatic: {
    loading: false,
    data: {},
    error: "",
  },
  data: { loading: false, remainderData: [], pagination: {}, error: "" },
  filter: {
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
};

export const fetchRemainTable = createAsyncThunk(
  "allRemainderData/fetchRemainTable",
  async (params: FilterType, thunkAPI) => {
    try {
      const request = await RegisterServices.FetchRemainTable(params);

      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchRemainTableStatistic = createAsyncThunk(
  "allRemainderData/fetchRemainTableStatistic",
  async (_, thunkAPI) => {
    try {
      const request = await RegisterServices.FetchRemainTableStatistic();

      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const remainTableSlices = createSlice({
  name: "allRemainderData",
  initialState,
  reducers: {
    handleChangeFilterRemainTable: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearRemainTable: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRemainTable.pending, (state) => {
      state.data = {
        loading: true,
        remainderData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchRemainTable.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        remainderData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),

        error: "",
      };
    });
    builder.addCase(fetchRemainTable.rejected, (state, payload) => {
      state.data = {
        loading: false,
        remainderData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
    // statistics
    builder.addCase(fetchRemainTableStatistic.pending, (state) => {
      state.remainStatic = {
        loading: true,
      };
    });
    builder.addCase(fetchRemainTableStatistic.fulfilled, (state, actions) => {
      state.remainStatic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchRemainTableStatistic.rejected, (state, actions) => {
      state.remainStatic = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const { handleChangeFilterRemainTable, clearRemainTable } =
  remainTableSlices.actions;
export default remainTableSlices.reducer;
