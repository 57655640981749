import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
  data: {
    loading: false,
    kassaList: [],
    pagination: {},
    error: "",
  },
  allKassa: {
    loading: false,
    data: [],
    error: "",
  },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchAllKassa = createAsyncThunk(
  "allKassa/fetchAllKassa",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetAllKassa(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchOwnKassa = createAsyncThunk(
  "allKassa/fetchOwnKassa",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetOwnKassa(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const KassaSlice = createSlice({
  name: "allKassa",
  initialState,
  reducers: {
    handleChangeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearFilterKassa: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllKassa.pending, (state) => {
      state.allKassa = {
        loading: true,
        data: [],
        error: "",
      };
    });
    builder.addCase(fetchAllKassa.fulfilled, (state, action) => {
      state.allKassa = {
        loading: false,
        data: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchAllKassa.rejected, (state, payload) => {
      state.allKassa = {
        loading: false,
        data: [],
        error: payload.error.message,
      };
    });
    // own
    builder.addCase(fetchOwnKassa.pending, (state) => {
      state.data = {
        loading: true,
        kassaList: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchOwnKassa.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        kassaList: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchOwnKassa.rejected, (state, payload) => {
      state.data = {
        loading: false,
        kassaList: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});
export const { handleChangeFilter, clearFilterKassa } = KassaSlice.actions;
export default KassaSlice.reducer;
