import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import UsersServices from "services/apiServices/users";
import { get } from "lodash";

const initialState: InitialState = {
  data: {
    loading: false,
    data: [],
    error: "",
    pagination: {},
  },
  filter: {
    search: "",
    page: 0,
    size: 10,
  },
  permissions: {
    loading: false,
    permissionsData: [],
    error: "",
  },
  details: {
    permissionIds: [],
  },
};

export const fetchTypeRole = createAsyncThunk(
  "typeRole/fetchTypeRole",
  async (params: any, thunkAPI) => {
    try {
      const respond = await UsersServices.FetchTypeRole(params);
      return respond.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchTypeRolePermissions = createAsyncThunk(
  "typeRole/fetchTypeRolePermissions",
  async (params: any, thunkAPI) => {
    try {
      const respond = await UsersServices.FetchPermissions(params);
      return respond.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const typeRoleSlices = createSlice({
  name: "typeRole",
  initialState,
  reducers: {
    clearFilterTypeRole: (state) => {
      state.filter = initialState.filter
    },
    filterChangeTypeRole: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },

    handlePermissionDetails: (state, actions) => {
      state.details = {
        ...state.details,
        permissionIds: [...state.details.permissionIds, actions.payload.id],
      };
    },
    handleRemoveDetails: (state, actions) => {
      state.details = {
        ...state.details,
        permissionIds: state.details.permissionIds.filter(
          (item: any) => item !== actions.payload.id
        ),
      };
    },
    clearTypeDetails: (state) => {
      state.details.permissionIds = [];
      state.permissions = initialState.permissions
    },
    clearTypeData: () => initialState,
    handleDetails: (state, action) => {
      const { outIndex1, middleIndex1, id, check } = action.payload;

      state.permissions = {
        ...state.permissions,
        permissionsData: state?.permissions?.permissionsData?.map(
          (outItem: any, outIndex) =>
            outIndex === outIndex1
              ? {
                ...outItem,
                pages: outItem?.pages?.map(
                  (pageItem: any, middleIndex: number) =>
                    middleIndex === middleIndex1
                      ? {
                        ...pageItem,
                        actions: pageItem.actions.map(
                          (item: any, index: number) =>
                            item.permissionId === id
                              ? {
                                ...item,
                                checked: check,
                              }
                              : item
                        ),
                      }
                      : pageItem
                ),
              }
              : outItem
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTypeRole.pending, (state) => {
      state.data = {
        ...state.data,
        loading: true,
      };
    });
    builder.addCase(fetchTypeRole.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        data: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
      };
    });
    builder.addCase(fetchTypeRole.rejected, (state, action) => {
      state.data = {
        ...state.data,
        loading: false,
        error: action.error.message,
      };
    });
    // permission check
    builder.addCase(fetchTypeRolePermissions.pending, (state) => {
      state.permissions = {
        ...state.permissions,
        loading: true,
      };
    });
    builder.addCase(fetchTypeRolePermissions.fulfilled, (state, actions) => {
      state.permissions = {
        loading: false,
        permissionsData: get(actions, "payload.data", []),
      };
    });
    builder.addCase(fetchTypeRolePermissions.rejected, (state, actions) => {
      state.permissions = {
        loading: false,
        permissionsData: [],
        error: actions.error.message,
      };
    });
  },
});
export const {
  filterChangeTypeRole,
  handlePermissionDetails,
  handleRemoveDetails,
  clearTypeDetails,
  handleDetails,
  clearTypeData,
  clearFilterTypeRole
} = typeRoleSlices.actions;
export default typeRoleSlices.reducer;
