import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
  data: { loading: false, menageAccountData: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchMenageAccount = createAsyncThunk(
  "allMenageAccount/fetchMenageAccount",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetMenageAccounts(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchAccounts = createAsyncThunk(
  "allManagementAccounts/fetchAccounts",
  async (_, thunkAPI) => {
    try {
      const request = await HandBookServices.FetchAccounts()
      return request.data
    } catch (err) {
      thunkAPI.rejectWithValue(err);

    }
  }
)
const menageAccount = createSlice({
  name: "allMenageAccount",
  initialState,
  reducers: {
    handleMenageAccountsFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    handleClearFilter:(state)=>{
      state.filter = initialState.filter
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMenageAccount.pending, (state) => {
      state.data = {
        loading: true,
        menageAccountData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchMenageAccount.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        menageAccountData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchMenageAccount.rejected, (state, payload) => {
      state.data = {
        loading: false,
        menageAccountData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleMenageAccountsFilter,handleClearFilter } = menageAccount.actions;
export default menageAccount.reducer;
