import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";

const initialState: any = {
  statistic: {
    loading: false,
    data: {},
    error: "",
  },
  filter: {
    kontragentId: null,
    fromDate: null,
    toDate: null,
    tipPrixodaId: null,
    page: 0,
    size: 10,
  },
  cashes: {
    loading: false,
    data: [],
    error: "",
  },
  remainder: {
    loading: false,
    data: [],
    error: "",
  },
  cashesTemp: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchAllArrivedCashes = createAsyncThunk(
  "arrivedCashes/fetchAllArrivedCashes",

  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.GetArrivalCashes(params);
      const respond = await request.data;
      const { data, pagination } = await respond;
      return { data, pagination };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllArrivedCashesTemp = createAsyncThunk(
  "arrivedCashes/fetchAllArrivedCashesTemp",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetArrivalCashesTemp();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllArrivedCashesStat = createAsyncThunk(
  "arrivedCashes/fetchAllArrivedCashesStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetArrivalCashesStat();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchRemainder = createAsyncThunk(
  "arrivedCashes/fetchRemainder",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchRemainder();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const arrivedCashesSlice = createSlice({
  name: "arrivedCashes",
  initialState,
  reducers: {
    handleFilterARrived: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFilterArrvid: (state) => {
      state.filter = {
        kontragentId: null,
        fromDate: null,
        toDate: null,
        tipPrixodaId: null,
        page: 0,
        size: 10,
      };
    },
    resetArrived: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllArrivedCashes.pending, (state) => {
      state.cashes = {
        loading: true,
      };
    });
    builder.addCase(fetchAllArrivedCashes.fulfilled, (state, actions) => {
      state.cashes = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllArrivedCashes.rejected, (state, actions) => {
      state.cashes = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });

    builder.addCase(fetchAllArrivedCashesTemp.pending, (state) => {
      state.cashesTemp = {
        loading: true,
      };
    });
    builder.addCase(fetchAllArrivedCashesTemp.fulfilled, (state, actions) => {
      state.cashesTemp = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllArrivedCashesTemp.rejected, (state, actions) => {
      state.cashesTemp = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    // static
    builder.addCase(fetchAllArrivedCashesStat.pending, (state) => {
      state.statistic = {
        loading: true,
      };
    });
    builder.addCase(fetchAllArrivedCashesStat.fulfilled, (state, actions) => {
      state.statistic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllArrivedCashesStat.rejected, (state, actions) => {
      state.statistic = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    // fetchReminder
    builder.addCase(fetchRemainder.pending, (state) => {
      state.remainder = {
        loading: true,
      };
    });
    builder.addCase(fetchRemainder.fulfilled, (state, actions) => {
      state.remainder = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchRemainder.rejected, (state, actions) => {
      state.remainder = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
  },
});

export const { handleFilterARrived, resetArrived, clearFilterArrvid } =
  arrivedCashesSlice.actions;
export default arrivedCashesSlice.reducer;
