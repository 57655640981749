import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterCloseMonth,
  fetchAllCloseMonth,
  fetchCloseMonthStat,
  filterChangeCloseMonth,
} from "app/slices/documentSlices/closeMonthSlices/closeMonthSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  closeMonthFilter,
  closeMonthList,
  closeMonthStat,
} from "app/states/documents";
import {
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import { PAGE } from "utilities/constants";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";

// Закрытие месяца
const CloseMonthContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_CLOSE_MONTH
  );
  const closeMonth = useAppSelector(closeMonthList);
  const filter = useAppSelector(closeMonthFilter);
  const statics = useAppSelector(closeMonthStat);
  let LOADING_TABLE = get(closeMonth, "loading", []);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  // other fetches
  const fetchCloseMonthFunction = useCallback(() => {
    dispatch(fetchAllCloseMonth(filter));
    dispatch(fetchCloseMonthStat());
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(clearFilterCloseMonth());
    dispatch(filterChangeCloseMonth(filter));
  }, [dispatch, filter]);

  const navigateToForm = (params: { id: string | number }) => {
    navigate(Documents.ADD_CLOSE_MONTH, {
      state: params,
    });
  };

  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeleteCloseMonthById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchCloseMonthFunction();
        dispatch(fetchCloseMonthStat());
      }
    });
  };

  // status
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsCloseMonth(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchCloseMonthFunction();
        dispatch(fetchCloseMonthStat());
      }
    });
  };
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsCloseMonth(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchCloseMonthFunction();
        dispatch(fetchCloseMonthStat());
      }
    });
  };
  useEffect(() => {
    fetchCloseMonthFunction();
  }, [filter.page, filter.size]);

  const handleAddButton = () => {
    navigate(Documents.ADD_CLOSE_MONTH);
  };

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, []);
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={statics} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button className="mr-16" danger handleClick={handleClearFilter}>
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "ZAKR_MES_CREATE",
                    page: "ZAKR_MES",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  isClearable
                  placeholder="Дата начало"
                  width={"200px"}
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeCloseMonth({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  isClearable
                  width={"200px"}
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeCloseMonth({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className={"text-right"}>
              <Button primary handleClick={fetchCloseMonthFunction}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateToForm}
                  deleteDocument={handleDeleteDocument}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  deleteItem={true}
                  userPermission={"ZAKR_MES"}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    !isEmpty(get(closeMonth, "data", [])) ? (
                      get(closeMonth, "data", []).map(
                        (item: any, index: number) => (
                          <tr
                            key={get(item, "id", "")}
                            onClick={() =>
                              navigateToForm({
                                id: get(item, "id", ""),
                              })
                            }
                            onContextMenu={(e) =>
                              displayMenu({
                                e,
                                id: get(item, "id", ""),
                                status: get(item, "status", 0),
                              })
                            }
                            className="cursor-pointer"
                          >
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  closeMonth,
                                  "pagination.currentPageNumber",
                                  0
                                ),
                                pageSize: get(
                                  closeMonth,
                                  "pagination.pageSize",
                                  10
                                ),
                                index: index + 1,
                              })}
                            </td>

                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "nomerDoc", "-")}</td>
                            <td>
                              {moment(get(item, "dataDoc", "")).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>
                              {moment(get(item, "createdAt", "")).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>{get(item, "status", "-")}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <Nodata colLength={7} />
                    )
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={6}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(closeMonth, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    filterChangeCloseMonth({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(closeMonth, "pagination.currentPageNumber", 0)}
                pageCount={get(closeMonth, "pagination.totalPages", 1)}
                onPageChange={({ selected }) =>
                  dispatch(
                    filterChangeCloseMonth({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default CloseMonthContainer;
