import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";
import EnumerationsServices from "services/apiServices/perechislenya";

const initialState: InitialState = {
  data: { loading: false, dataList: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchDeliveryMethod = createAsyncThunk(
  "deliveryMethod/fetchDeliveryMethod",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await EnumerationsServices.GetDeliveryMethod(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const DeliveryMethodSlices= createSlice({
  name: "deliveryMethod",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeliveryMethod.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchDeliveryMethod.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchDeliveryMethod.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export default DeliveryMethodSlices.reducer;
