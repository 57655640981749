import { memo, useEffect, useState } from "react";
import { FormInputWrapper, InputWrapper } from "./style";
import { Col, Row } from "react-grid-system";
import Title from "components/Title/Title";

const FormInput = ({
    clear = "",
    placeholder = "",
    disabled = false,
    handleInput = (e: any) => { },
    required = false,
    label = "",
    ...props

}: any) => {
    const [val, setVal] = useState<any>(clear);

    const handleChange = (e: any) => {
        setVal(e.target.value);
        handleInput(e.target.value);
    };

    useEffect(() => {
        if (clear.length == 0) {
            setVal(clear);
        }
    }, [clear]);
    return (
        <FormInputWrapper className="mt-24">
            <Row align={"center"} justify={"end"}>
                <Col xs={4}>
                    <Title>{label}
                        {required ?
                            <span className="required_point">*</span> : ""
                        }
                    </Title>
                </Col>
                <Col xs={8}>
                    <InputWrapper
                        value={val}
                        placeholder={placeholder}
                        disabled={disabled}
                        thousandSeparator=","
                        onChange={handleChange}
                        decimalScale={2}
                        {...props}
                    />
                </Col>
            </Row>
        </FormInputWrapper>
    );
};

export default memo(FormInput);
