import { useAppDispatch, useAppSelector } from "app/hook";
import { prixodDetails } from "app/slices/documentSlices/receivedMaterialSlices/type";
import {
  addSubjectReturnProductBefReal,
  fetchNotFilledReturnProductBefReal,
  fetchReturnProductBefReal,
  fetchReturnProductBefRealById,
  fetchReturnProductBefRealByTempId,
  fetchReturnProductBefRealStat,
  movementReturnMaterialBefRealSubject,
} from "app/slices/documentSlices/returnProductBefRealSlices/returnProductBefRealSlices";
import {
  clearFormDataShipmentProduct,
  fetchShipmentProduct,
} from "app/slices/documentSlices/shipmentProductSlices/shipmentProductSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  returnProductBefRealFilter,
  returnProductBefRealForm,
} from "app/states/documents";
import {
  getShipmentDataOptions,
  loadingConterpart,
  loadingShipmentData,
} from "app/states/documents/selectStates";
import { getCounterpartData } from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { NumberDocType, ProductDetailsTable } from "types/documentsType";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import { ProductDetailsSchema } from "utilities/schema/document/documentSchemas";

//  Добавить Перемещение сырья и материалов
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  documentId,
  dataTable,
  id,
  docOsnovanieId,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  documentId: string | null;
  dataTable: Array<ProductDetailsTable>;
  id: number | null | undefined;
  docOsnovanieId: string;
}) => {
  const dispatch = useAppDispatch();
  const [productOption, setProductOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [priceLoading, setPriceLoading] = useState<boolean>(false);
  const details = dataTable?.find((value) => value.tovarId === id);
  const onSubmit = (values: any) => {
    const res = {
      ...values,
      tovar: get(values, "tovarId", null),
      tovarId: get(values, "tovarId.value", null),
      imyaTovar: get(values, "tovarId.label", ""),
    };
    if (isEmpty(details)) {
      dispatch(addSubjectReturnProductBefReal(res));
    } else {
      dispatch(
        movementReturnMaterialBefRealSubject({
          data: res,
          type: ACTION.UPDATE,
          id,
        })
      );
    }
    resetForm();
    handleCloseModal();
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imyaEdIzm: get(details, "imyaEdIzm", null),
      tovarId: {
        value: get(details, "tovarId", null),
        label: get(details, "imyaTovar", null),
      },
      edIzmId: get(details, "edIzmId", null),
      kol: get(details, "kol", null),
      maxKol: get(details, "maxKol", 0),
      // tsena: get(details, "tsena", 0),
      summa: get(details, "summa", 0),
    },
    validationSchema: ProductDetailsSchema,
    onSubmit,
  });

  const fetchProductPrice = useCallback(() => {
    try {
      if (documentId) {
        setLoading(true);
        DocumentServices.FetchReturnProductBefRealDetails(documentId).then(
          (res) => {
            if (res.status === 200) {
              setLoading(false);
              const listProduct = get(res, "data.data", []).map(
                (item: any) => ({
                  label: item.imyaTovar,
                  value: item.tovarId,
                  imyaEdIzm: item.imyaEdIzm,
                  edIzmId: item.edIzmId,
                  kol: item.kol,
                  sena: item.tsena,
                })
              );
              setProductOptions(listProduct);
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [documentId, values.tovarId]);
  const fetchPrice = useCallback(() => {
    if (
      get(values, "kol", null) &&
      get(values, "tovarId.value", null) &&
      docOsnovanieId
    ) {
      setPriceLoading(true);
      DocumentServices.CalculatetheSum({
        kol: get(values, "kol", null),
        tovarId: get(values, "tovarId.value", ""),
        docOsnovanieId: docOsnovanieId,
      }).then((res) => {
        setPriceLoading(false);
        if (res.data.data) {
          setFieldValue("summa", res.data.data);
        }
      });
    }
  }, [
    get(values, "kol", null),
    get(values, "tovarId.value", ""),
    setFieldValue,
    docOsnovanieId,
  ]);
  useEffect(() => {
    if (!priceLoading) {
      handleSubmit();
    }
  }, [
    get(values, "summa", null),
    get(values, "tovarId.value", ""),
    setFieldValue,
    docOsnovanieId,
  ]);
  useEffect(() => {
    fetchProductPrice();
    return () => {
      setProductOptions([]);
    };
  }, [documentId]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            Ввод данных
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            label={"Товар"}
            required={true}
            id={"tovarId"}
            isSearchable
            isLoading={loading}
            defaultValue={get(values, "tovarId", {})}
            value={get(values, "tovarId.value", "")}
            options={removeValueOption({
              currentOption: productOption,
              removeValueList: dataTable,
              valueName: "tovarId",
              isUpdate: get(details, "tovar", null),
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("tovarId", e);
              setFieldValue("edIzmId", e.edIzmId);
              setFieldValue("imyaEdIzm", e.imyaEdIzm);
              setFieldValue("maxKol", e.kol);
              setFieldValue("tsena", e.sena);
            }}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                placeholder={`до: ${textFormatter(Number(values.maxKol))}`}
                value={get(values, "kol", "") || " "}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                min={1}
                maxLength={40}
              />
              {Number(get(values, "maxKol", 0)) <
                Number(get(values, "kol", 0)) && (
                <Text xs danger>
                  ограничение количество до:
                  {textFormatter(Number(values.maxKol))}
                </Text>
              )}
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <FormButton
              handleClick={() => fetchPrice()}
              color={"#00c838"}
              loading={priceLoading}
              disabled={
                Number(get(values, "maxKol", 0)) >=
                Number(get(values, "kol", 0))
                  ? false
                  : true
              }
            >
              {get(details, "edIzmId", null) ? PAGE.CHANGE : PAGE.ADD}
            </FormButton>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              {PAGE.CLOSE}
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddReturnProductBefRealContainer = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const filter = useAppSelector(returnProductBefRealFilter);
  const returnProduct = useAppSelector(returnProductBefRealForm);
  const counterparts = useAppSelector(getCounterpartData);
  const getShipmentOptions = useAppSelector(getShipmentDataOptions);
  const [disable, setDisable] = useState(false);
  const [productId, setProduct] = useState<number | null | undefined>(null);
  // loadings
  let loadingShipment = useAppSelector(loadingShipmentData);
  let loadingKontragent = useAppSelector(loadingConterpart);
  let LOADING = get(returnProduct, "loading", false);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id?: number | null | undefined) => {
    setOpenModal(true);
    setProduct(id);
  }, []);

  const onSubmit = (values: any) => {
    const subjects = get(returnProduct, "data.details", []).map(
      (item: prixodDetails) => ({
        tovarId: get(item, "tovarId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        tsena: get(item, "tsena", ""),
      })
    );

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.AddReturnProductBefReal({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res: any) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchReturnProductBefReal(filter));
          dispatch(fetchReturnProductBefRealStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveTempsReturnProductBefReal(
              get(state, "id", "")
            );
          }
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: get(returnProduct, "data.formIntVls", {}),
      onSubmit,
    });
  const saveNotFullFilledForm = useCallback(() => {
    const subjects = get(returnProduct, "data.details", []).map(
      (item: prixodDetails) => ({
        tovarId: get(item, "materialId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        tsena: get(item, "tsena", ""),
      })
    );

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc"].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddNotFilledReturnProductBefReal({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledReturnProductBefReal());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values, returnProduct]);

  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.GetReturnProductBefRealNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
    });
  }, []);
  const getSearchByContract = useCallback(() => {
    if (get(values, "kontragentId.value", null)) {
      dispatch(
        fetchShipmentProduct({
          all: true,
          kontragentId: get(values, "kontragentId.value", null),
          filterPage: "VOZVRAT_TOV_OGP",
        })
      );
    }
  }, [values.kontragentId]);

  const handleClickTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchReturnProductBefRealByTempId(get(state, "id", "")));
      } else {
        dispatch(fetchReturnProductBefRealById(get(state, "id", "")));
        setDisable(true);
      }
    } else {
      getDocNumber({});
      dispatch(
        fetchAllCounterpart({ all: true, filterPage: "VOZVRAT_TOV_OGP" })
      );
    }
  }, [dispatch, state]);
  useEffect(() => {
    getSearchByContract();
  }, [values.kontragentId]);

  useEffect(() => {
    handleClickTemp();
  }, [handleClickTemp]);
  useEffect(() => {
    return () => {
      dispatch(clearFormDataShipmentProduct());
    };
  }, []);
  return (
    <Content className={"min_height"}>
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          documentId={get(values, "docOsnovanieId.value", "")}
          dataTable={get(returnProduct, "data.details", [])}
          id={productId}
          docOsnovanieId={get(values, "docOsnovanieId.value", "")}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox
                bold
                sm
                justify={"space-between"}
                style={{ width: "100%" }}
              >
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row justify="start">
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          maxDate={new Date()}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    isSearchable
                    id={"kontragentId"}
                    isLoading={loadingKontragent}
                    value={get(values, "kontragentId.value", "")}
                    defaultValue={get(values, "kontragentId", "")}
                    options={counterparts}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);
                      if (!isEqual(e, get(values, "kontragentId", false))) {
                        setFieldValue("docOsnovanieId", null);
                        setFieldValue("dogProdajaId", null);
                        setFieldValue("zakazNaryadId", null);
                        setFieldValue("territoriyaId", null);
                        setFieldValue("skladGPId", null);
                        dispatch(clearFormDataShipmentProduct());
                        dispatch(addSubjectReturnProductBefReal(null));
                      }
                    }}
                    isDisabled={disable || loadingShipment}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Док. основания для возврата товара
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"docOsnovanieId"}
                    value={get(values, "docOsnovanieId.value", "")}
                    defaultValue={get(values, "docOsnovanieId", {})}
                    isLoading={loadingShipment}
                    options={getShipmentOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("docOsnovanieId", e);
                      setFieldValue("dogProdajaId", e.dogProdajaId);
                      setFieldValue("zakazNaryadId", e.zakazNaryadId);
                      setFieldValue("territoriyaId", e.territoriyaId);
                      setFieldValue("skladGPId", e.skladGPId);
                      if (!isEqual(e, get(values, "docOsnovanieId", false))) {
                        dispatch(addSubjectReturnProductBefReal(null));
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Заказ-наряд<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "zakazNaryadId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Договор<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "dogProdajaId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Территория<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "territoriyaId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад готовой продукции
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "skladGPId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-48" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {!isEmpty(get(values, "docOsnovanieId.value", false)) &&
                  isEmpty(get(state, "id", null)) && (
                    <ReactSVG
                      src={add_green}
                      className="svg_icon"
                      onClick={() => handleOpenModal(null)}
                    />
                  )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Товар",
                    "Ед. изм",
                    "Количество",
                    "Сумма",
                    "",
                  ]}
                >
                  {!isEmpty(get(returnProduct, "data.details", [])) ? (
                    get(returnProduct, "data.details", []).map(
                      (item: prixodDetails, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaTovar", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <ActionBase
                              disable={disable}
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  movementReturnMaterialBefRealSubject({
                                    id: get(item, "tovarId", null),
                                    type: ACTION.DELETE,
                                  })
                                )
                              }
                              id={get(item, "tovarId", null)}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={8}> Нет данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                Печать
              </Button> */}
              {/* {!isSubmitting && ( */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.VOZVRAT_TOV_OGP}_CREATE`,
                    page: DOCUMENT_KEY.VOZVRAT_TOV_OGP,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              {/* )} */}
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddReturnProductBefRealContainer;
