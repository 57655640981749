import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { get } from "lodash";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { textFormatter } from "utilities/helpers";
interface PropsType {
  item: any;
  limit: number;
  handleChangeKol: (e: any) => void;
}
function TableInputDebounce({ item, limit, handleChangeKol }: PropsType) {
  const [text, setText] = useState(get(item, "kol", "") || "");
  const [debouncedText] = useDebounce(text, 1000);
  return (
    <BaseMarkInput
      placeholder={`до: ${textFormatter(limit)}`}
      value={get(item, "kol", "") || ""}
      handleInput={(e: any) => {
        setText(e);
        if (debouncedText) {
          handleChangeKol({
            event: debouncedText,
            tovarId: get(item, "tovarId", null),
          });
        }
      }}
      maxLength={40}
    />
  );
}

export default TableInputDebounce;
