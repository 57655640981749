import { memo } from "react";
import {
    CheckBoxContainer,
    CheckBoxInput,
    CheckBoxLabel,
    CustomCheckBox,
} from "./style";
import { Props } from "./type";

const Checkbox: React.FC<Props> = ({
    label,
    defaultChecked = false,
    handleChange = () => {},
    checked,
    name,
    value,
    ...props
}) => {
    return (
        <CheckBoxContainer {...props}>
            <CheckBoxLabel value={value}>
                {label}
                <CheckBoxInput
                    name={name}
                    value={value}
                    checked={value}
                    onChange={handleChange}
                />
                <CustomCheckBox></CustomCheckBox>
            </CheckBoxLabel>
        </CheckBoxContainer>
    );
};

export default memo(Checkbox);
// <Checkbox handleChange={() => setIn(!set)} value={set} />
