import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchPivotTableStatistic } from "app/slices/registerSlices/pivotTableSlices/pivotTableSlices";
import { fetchRemainTableStatistic } from "app/slices/registerSlices/remaonTableSlices/remainTableSlices";
import { sideBarMenus } from "app/states/menu";
import closeMenu from "assets/images/icons/close_menu.svg";
import menuuIcon from "assets/images/icons/menu.svg";
import { Breadcrumb, FlexBox, Sidebar, SidebarItem } from "components";
import { SidebarItemWrapper } from "components/SidebarItem/style";
import { get, isEqual } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Main, Registers } from "routers/paths";
import { Layout } from "antd";
const RegisterInnerLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sideBar = useAppSelector((store) =>
    sideBarMenus(store, Main.REGISTERS)
  );
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;
  // redirect to the first section of side bar menu
  const navigateToFirstSidebar = useCallback(() => {
    if (isEqual(get(location, "pathname", ""), `/${Main.REGISTERS}`)) {
      navigate(Registers.PIVOT_TABLE, { replace: true });
    }
  }, [location, navigate]);

  const getAllFetchRegister = useCallback(() => {
    dispatch(fetchPivotTableStatistic());
    dispatch(fetchRemainTableStatistic());
  }, [dispatch]);

  useEffect(() => {
    navigateToFirstSidebar();
  }, [navigateToFirstSidebar]);
  useEffect(() => {
    getAllFetchRegister();
  }, [getAllFetchRegister]);
  return (
    <Fragment>
      <Layout
        style={{
          padding: 0,
          fontFamily: "Arial, Helvetica, sans-serif",
          zIndex: 100,
          left: 0,
          width: "100%",
          position: "fixed",
        }}
      >
        <Row align="center">
          {!collapsed && (
            <Col xs={2.6} style={{ textAlign: "end" }}>
              <ReactSVG
                src={closeMenu}
                className="cursor-pointer  mt-8 ml-8"
                onClick={() => setCollapsed(!collapsed)}
              />
            </Col>
          )}
          <Col xs={9.4} style={{ paddingLeft: 0 }}>
            <FlexBox>
              {collapsed && (
                <ReactSVG
                  src={menuuIcon}
                  className="svg_icon mr-16 ml-24"
                  onClick={() => setCollapsed(!collapsed)}
                />
              )}
              <Breadcrumb style={{ margin: "12px 0" }} />
            </FlexBox>
          </Col>
        </Row>
      </Layout>
      <Layout className="mt-50">
        <Sider
          collapsible
          style={{
            background: "inherit",
            marginBottom: "10px",
          }}
          className="sidebar"
          trigger={null}
          width={"43vh"}
          collapsedWidth={0}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Sidebar
            className="component_animate"
            style={collapsed ? { display: "none" } : { display: "flex" }}
          >
            {sideBar?.map((item: any) => (
              <SidebarItemWrapper key={item.id}>
                <SidebarItem
                  key={item.id}
                  title={get(item, "name", "")}
                  url={get(item, "id", "")}
                  icon={get(item, "icon", "")}
                />
              </SidebarItemWrapper>
            ))}
          </Sidebar>
        </Sider>
        <Content
          style={{
            padding: " 0",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Fragment>
  );
};

export default RegisterInnerLayout;
