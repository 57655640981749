import { useAppSelector } from "app/hook";
import { authData } from "app/states/auth";
import { AuthContainer } from "components";
import { get } from "lodash";
import { Fragment } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Navigate, Outlet } from "react-router-dom";
import { Main } from "routers/paths";

const AuthLayout = () => {
  const userAuthData = useAppSelector(authData);
  const isAuthenticated = get(userAuthData, "isAuthenticated", false);

  return !isAuthenticated ? (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </Fragment>
  ) : (
    <Navigate to={Main.HOME} replace />
  );
};

export default AuthLayout;
