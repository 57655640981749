import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import moment from "moment";
import { get, includes, isEmpty, map } from "lodash";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hook";
import HasAccess from "routers/HasAccess";
import {
  fetchNotFilledSalesContracts,
  fetchSaleContractsStat,
  fetchSalesContracts,
  handleClearFormData,
  handleClearDetailsFilter,
  handleFilterChange,
} from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import {
  saleContractFilter,
  saleContractList,
  saleContractStat,
  saleTempsContractList,
} from "app/states/documents";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  WindowHeight,
} from "components";
import { Documents } from "routers/paths";
import delate from "assets/images/icons/delate.svg";
import { ReactSVG } from "react-svg";
import DocumentServices from "services/apiServices/documents";
import { toast } from "react-toastify";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
// Регистрация договоров на продажу

const RegistrationSalesContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_REGIST_SALES
  );
  const [documentInfo, setDocumentInfo] = useState({ id: "" });
  const salesContracts = useAppSelector(saleContractList);
  const saleTempsContracts = useAppSelector(saleTempsContractList);
  const filter = useAppSelector(saleContractFilter);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const cntrCountStat = useAppSelector(saleContractStat);
  const LOADING_TABLE = get(salesContracts, "loading", []);
  let LOADING_KON = useAppSelector(loadingConterpart);
  //  context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id });
  };

  const fetchRigestSalesContracts = useCallback(() => {
    dispatch(fetchSalesContracts(filter));
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchSalesContracts({
        ...filter,
        fromDate: null,
        toDate: null,
        kontragentId: null,
      })
    );
    dispatch(handleClearDetailsFilter());
  }, [filter]);

  useEffect(() => {
    fetchRigestSalesContracts();
  }, [filter.page, filter.size]);

  const fetchSalesNotFilledContracts = useCallback(() => {
    dispatch(fetchNotFilledSalesContracts());
    dispatch(fetchSaleContractsStat());
  }, [dispatch]);

  useEffect(() => {
    fetchSalesNotFilledContracts();
  }, [fetchSalesNotFilledContracts]);

  const navigateSaleContractForm = (param: { id: string; isTemp: boolean }) => {
    navigate(Documents.ADD_REGIST_SALES, {
      state: param,
    });
  };

  const handleAddButton = () => {
    dispatch(handleClearFormData());
    navigate(Documents.ADD_REGIST_SALES);
  };
  const deleteDocument = (id: string | null) => {
    DocumentServices.DeleteSalesContractById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
        hideAll();
        dispatch(fetchSalesContracts(filter));
        dispatch(fetchSaleContractsStat());
      }
    });
  };
  const handleTempButton = (id: string | null) => {
    DocumentServices.RemoveSalesTempContractById(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchSalesNotFilledContracts();
      }
    });
  };
  useEffect(() => {
    dispatch(fetchAllCounterpart({ all: true }));
    return () => {
      dispatch(handleClearDetailsFilter());
    };
  }, []);
  // for adding  new form
  if (isAddForm) {
    return <Outlet />;
  }
  // main content
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={cntrCountStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger handleClick={handleClearFilter} className="mr-16">
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "DOG_PRODAJA_CREATE",
                    page: "DOG_PRODAJA",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="200px"
                  placeholder={"Контрагент"}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  isLoading={LOADING_KON}
                  isClearable
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right pr-0"} xs={2}>
              <Button
                primary
                className={"mr-16"}
                handleClick={fetchRigestSalesContracts}
              >
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateSaleContractForm}
                  deleteDocument={deleteDocument}
                  changeItem={true}
                  deleteItem={true}
                  documentInfo={documentInfo}
                  userPermission={"DOG_PRODAJA"}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Дата исполнения договора",
                    "Контрагент",
                    "Товар",
                    "Количество",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Тип договора",
                    "Способ оплаты",
                    "Условия оплаты",
                    "Способ доставки",
                    "Время записи",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      <>
                        {!isEmpty(get(saleTempsContracts, "data", [])) &&
                          get(saleTempsContracts, "data", []).map(
                            (item: any) => (
                              <tr
                                key={get(item, "id", 0)}
                                onClick={() => {
                                  navigateSaleContractForm({
                                    isTemp: true,
                                    id: get(item, "id", ""),
                                  });
                                }}
                                className="cursor-pointer"
                              >
                                <td>
                                  <div
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      handleTempButton(get(item, "id", null));
                                    }}
                                  >
                                    <ReactSVG
                                      src={delate}
                                      className={"delete_button svg_icon"}
                                    />
                                  </div>
                                </td>
                                <td>
                                  {moment(get(item, "dataIsp", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>
                                  {isEmpty(get(item, "imyaTovara", null))
                                    ? "-"
                                    : get(item, "imyaTovara", "-")}
                                </td>
                                <td>{get(item, "kol", "-")}</td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "dataDoc", ""))
                                    ? moment(get(item, "dataDoc", "")).format(
                                      "DD.MM.YYYY"
                                    )
                                    : "-"}
                                </td>
                                <td>{get(item, "imyaTipDogovora", "")}</td>

                                <td>{get(item, "sposobOplataName", "")}</td>
                                <td>{get(item, "imyaUsloviyaOplati", "-")}</td>
                                <td>{get(item, "sposobDostavkaName", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                      "DD.MM.YYYY"
                                    )
                                    : "-"}
                                </td>
                              </tr>
                            )
                          )}
                      </>
                      {!isEmpty(get(salesContracts, "data", []))
                        ? get(salesContracts, "data", []).map(
                          (item: any, index: number) => (
                            <tr
                              onContextMenu={(e) =>
                                displayMenu({
                                  e,
                                  id: get(item, "id", ""),
                                })
                              }
                              key={index + 1}
                              onClick={() =>
                                navigateSaleContractForm({
                                  isTemp: false,
                                  id: get(item, "id", ""),
                                })
                              }
                              className="cursor-pointer"
                            >
                              <td>
                                {indexNumber({
                                  currentPage: get(
                                    salesContracts,
                                    "pagination.currentPageNumber",
                                    0
                                  ),
                                  pageSize: get(
                                    salesContracts,
                                    "pagination.pageSize",
                                    10
                                  ),
                                  index: index + 1,
                                })}
                              </td>
                              <td>
                                {moment(get(item, "dataIsp", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "imyaKontragent", "-")}</td>
                              <td>
                                {isEmpty(get(item, "imyaTovara", null))
                                  ? "-"
                                  : get(item, "imyaTovara", "-")}
                              </td>
                              <td>
                                {get(item, "kol", null)
                                  ? get(item, "kol", null)
                                  : "-"}
                              </td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDoc", "")}</td>
                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "imyaTipDogovora", "")}</td>

                              <td>{get(item, "sposobOplataName", "")}</td>
                              <td>{get(item, "imyaUsloviyaOplati", "-")}</td>
                              <td>{get(item, "sposobDostavkaName", "")}</td>
                              <td>
                                {moment(get(item, "createdAt", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                            </tr>
                          )
                        )
                        : isEmpty(get(saleTempsContracts, "data", [])) && (
                          <Nodata colLength={13} />
                        )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={13}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(salesContracts, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(salesContracts, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChange({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>

              <Col xs={6}>
                <Pagination
                  current={get(
                    salesContracts,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(salesContracts, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChange({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default RegistrationSalesContainer;
