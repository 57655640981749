import { SupplierReportType } from "app/slices/reportSlices/supplierReportSlices/type";
import { Title } from "components";
import { get, isEmpty } from "lodash";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function SuppliersReportTable({ data }: any) {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth="1400px" className="mb-16">
          <thead>
            <tr>
              <th rowSpan={2}>№</th>
              <th rowSpan={2} style={{ width: "180px" }}>
                Контрагент
              </th>
              <th rowSpan={2} style={{ width: "100px" }}>
                Договор
              </th>
              <th colSpan={2}>Начальный остаток</th>
              <th colSpan={2}>Оборот</th>
              <th colSpan={2}>Конечный остаток</th>
            </tr>
            <tr>
              <th>Дебет</th>
              <th>Кредит</th>

              <th>Дебет</th>
              <th>Кредит</th>

              <th>Дебет</th>
              <th>Кредит</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(get(data, "dataList.list", [])) ? (
              get(data, "dataList.list", []).map(
                (item: SupplierReportType, index: number) => (
                  <>
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{get(item, "imyaKontragent", "")}</td>
                      <td></td>

                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(item, "totalNachalniyOstatok.debit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(item, "totalNachalniyOstatok.kredit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>

                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(item, "totalOborot.debit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(item, "totalOborot.kredit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>

                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(item, "totalKonechniyOstatok.debit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(item, "totalKonechniyOstatok.kredit", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                    </tr>
                    {!isEmpty(get(item, "details", []))
                      ? get(item, "details", []).map(
                        (detail: any, index: number) => (
                          <tr
                            key={index + 1}
                            style={{ backgroundColor: "#f4f4f4" }}
                          >
                            <td></td>
                            <td></td>
                            <td>{get(detail, "nomerDogPokupka", "")}</td>

                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(
                                  detail,
                                  "nachalniyOstatok.debit",
                                  0
                                )}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(
                                  detail,
                                  "nachalniyOstatok.kredit",
                                  0
                                )}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>

                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(detail, "oborot.debit", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(detail, "oborot.kredit", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>

                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(
                                  detail,
                                  "konechniyOstatok.debit",
                                  0
                                )}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(
                                  detail,
                                  "konechniyOstatok.kredit",
                                  0
                                )}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                          </tr>
                        )
                      )
                      : null}
                  </>
                )
              )
            ) : (
              <tr>
                <td colSpan={10}>Виберите параметры</td>
              </tr>
            )}
            {!isEmpty(get(data, "dataList.itogo", false)) ? (
              <tr className="black_td">
                <td colSpan={3} style={{ textAlign: "start" }}>
                  <Title bold>Итого:</Title>
                </td>

                <td>
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.NachalniyDebit", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
                <td>
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.NachalniyKredit", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>

                <td>
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.OborotDebit", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
                <td>
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.OborotKredit", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>

                <td>
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.KonechniyDebit", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
                <td>
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.KonechniyKredit", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
              </tr>
            ) : null}
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
}

export default SuppliersReportTable;
