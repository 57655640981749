//get Yur Fiz Person data

import { createSelector } from "@reduxjs/toolkit";
import {
  selectIncome,
  selectLegalPhysPerson,
  selectPaymentMethod,
  selectRoundOrder,
  selectRoundOrderMethods,
  selectTypeFlow,
  selectTypeSubstandard,
  selectTypeWriteOff,
} from "app/selectors/enumerations/enumerationsSelectors";
import { LegalPhysPersonDataType } from "app/slices/enumerationsSlices/legalPhysPersonSlices/type";
import { RoundOrderDataType } from "app/slices/enumerationsSlices/roundOrderSlices/type";
import { get } from "lodash";

export const getYurFizData = createSelector(selectLegalPhysPerson, (data) =>
  get(data, "dataList", []).map((item: LegalPhysPersonDataType) => {
    return {
      label: get(item, "tipYurFizLitsa", ""),
      value: get(item, "id", 0),
    };
  })
);

export const getRoundOrderData = createSelector(selectRoundOrder, (data) =>
  get(data, "dataList", []).map((item: RoundOrderDataType) => {
    return {
      label: get(item, "imyaOkruglineya", ""),
      value: get(item, "id", null),
    };
  })
);

export const getRoundOrderMethodsData = createSelector(
  selectRoundOrderMethods,
  (data) =>
    get(data, "data.okruglineyaMetod", []).map((item) => {
      return {
        label: get(item, "imyaOkruglineyaMetod", ""),
        value: get(item, "okruglineyaMetod", null),
      };
    })
);

export const getRoundOrderDoData = createSelector(
  selectRoundOrderMethods,
  (data) =>
    get(data, "data.okruglineyaDo", []).map((item) => {
      return {
        label: item,
        value: item,
      };
    })
);

export const getTypeWriteData = createSelector(selectTypeWriteOff, (data) =>
  get(data, "dataList", []).map((item) => ({
    label: get(item, "tipSpisaniya", ""),
    value: get(item, "id", null),
  }))
);

export const getPaymentMethodData = createSelector(
  selectPaymentMethod,
  (data) =>
    get(data, "data", []).map((item) => ({
      label: get(item, "sposobOplataName", ""),
      value: get(item, "id", null),
    }))
);

export const tipPrixodaOptions = createSelector(selectIncome, (data) =>
  get(data, "dataList", []).map((item) => ({
    label: get(item, "tipPrixoda", ""),
    value: get(item, "id", null),
  }))
);

export const tipRasxodaOptions = createSelector(selectTypeFlow, (data) =>
  get(data, "dataList", []).map((item) => ({
    label: get(item, "tipRasxoda", ""),
    value: get(item, "id", null),
  }))
);

export const getIncomeOptionsData = createSelector(selectIncome, (data) =>
  get(data, "dataList", []).map((item) => ({
    label: get(item, "tipPrixoda", ""),
    value: get(item, "id", null),
  }))
);

export const getTypeSubstandard = createSelector(
  selectTypeSubstandard,
  (data) => {
    return {
      list: get(data, "data", []).map((item: any) => ({
        label: get(item, "naimenovaniya", ""),
        value: get(item, "id", null),
      })),
      loading: get(data, "loading", false),
    };
  }
);
