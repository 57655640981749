import { Card, MenuProps, Progress, Tag, Tooltip } from "antd";
import { DatePicker, FlexBox, Text, Title } from "components";
import { get, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import { ReactSVG } from "react-svg";
import { Cell, Pie, PieChart } from "recharts";
import { PRODUCT_STATS } from "utilities/constants";
import { textFormatter } from "utilities/helpers";
import setting from "assets/images/icons/settings.svg";
type DetailsType = {
  ostalos: number;
  otgruzka: number;
  prosent: number;
  zakaz: number;
  vidTovaraId: string;
  imya: string;
};
interface PropsType {
  details: DetailsType;
  setSidebar?: any;
  index: number;
  key: string | number;
  setCheckedProduct: any;
  changeDetails?: Array<DetailsType> | [];
  colors: Array<string>;
}

const styleItem = {
  width: "200px",
};

const StatusChart = ({
  details,
  index,
  colors,
  setSidebar,
  key,
  setCheckedProduct,
}: PropsType) => {
  const RADIAN = Math.PI / 180;
  const data = [
    { name: "A", value: get(details, "prosent", 0), color: "#5AB5FF" },
    {
      name: "B",
      value: get(details, "ostatkaInSkladProsent", 0),
      color: "#92DE8C",
    },
    {
      name: "B",
      value: get(details, "ostalosProsent", 0),
      color: "#FFE27D",
    },
  ];
  const noData = [{ name: "A", value: 1, color: "#faf8f8f0f" }];
  const iR = 150;
  const oR = 230;
  const handleSetting = ({ itemDetail }: any) => {
    if (itemDetail) {
      setCheckedProduct(itemDetail);
      setSidebar(true);
    }
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: "30px" }}
      >
        {value > 0 ? `${value.toFixed(0)}%` : ""}
      </text>
    );
  };
  const noDataCustumize = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        textAnchor="middle"
        dominantBaseline="central"
        fill={"#dfdfe4"}
      ></text>
    );
  };
  return (
    <div key={key}>
      <Card
        style={{
          borderRadius: "20px",
          padding: 0,
          margin: "5px",
          paddingBlock: "0px",
        }}
      >
        <FlexBox
          justify={"space-between"}
          align="center"
          style={{ width: "100%" }}
        >
          <FlexBox>
            <div>
              <Text>Объем заказа</Text>
              <br />
              <h3>
                {textFormatter(get(details, "zakaz", 0))}{" "}
                {isEqual(get(details, "imya", ""), "Бетон")
                  ? PRODUCT_STATS.MS
                  : PRODUCT_STATS.SHT}{" "}
              </h3>
            </div>
          </FlexBox>
          <div>
            <FlexBox gap={"5px"}>
              <div style={{ textAlign: "center" }}>
                <FlexBox gap={"5px"}>
                  <div
                    style={{
                      backgroundColor: "#5AB5FF",
                      minWidth: "15px",
                      minHeight: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <Text>Отгружено</Text>
                </FlexBox>
                <h3>
                  {textFormatter(get(details, "otgruzka", 0))}{" "}
                  {isEqual(get(details, "imya", ""), "Бетон")
                    ? PRODUCT_STATS.MS
                    : PRODUCT_STATS.SHT}
                </h3>
              </div>
              <div className="ml-24" style={{ textAlign: "center" }}>
                <FlexBox gap={"5px"}>
                  <div
                    style={{
                      backgroundColor: "#92DE8C",
                      minWidth: "15px",
                      minHeight: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <Text>На складе</Text>
                </FlexBox>
                <h3>
                  {textFormatter(get(details, "ostatkaInSklad", 0))}{" "}
                  {isEqual(get(details, "imya", ""), "Бетон")
                    ? PRODUCT_STATS.MS
                    : PRODUCT_STATS.SHT}{" "}
                </h3>
              </div>
              <div className="ml-24" style={{ textAlign: "center" }}>
                <FlexBox gap={"5px"}>
                  <div
                    style={{
                      backgroundColor: "#FFE27D",
                      minWidth: "15px",
                      minHeight: "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <Text>Осталось</Text>
                </FlexBox>
                <h3>
                  {textFormatter(get(details, "ostalos", 0))}{" "}
                  {isEqual(get(details, "imya", ""), "Бетон")
                    ? PRODUCT_STATS.MS
                    : PRODUCT_STATS.SHT}
                </h3>
              </div>
            </FlexBox>
          </div>
        </FlexBox>
        <hr className="mt-16" />
        {/* chart */}
        <Row align="center">
          <Col xs={12} style={{ paddingTop: 0, textAlign: "center" }}>
            <div
              style={{
                width: "100%",
                maxWidth: "600px",
                maxHeight: "600px",
                margin: "0 auto",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {get(details, "zakaz", 0) > 0 ? (
                <PieChart
                  width={600}
                  height={300}
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    width={"100%"}
                    endAngle={0}
                    data={data}
                    cy={"90%"}
                    cx={"50%"}
                    innerRadius={iR}
                    outerRadius={oR}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#a5a5ad"
                    stroke="none"
                    isAnimationActive={false}
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              ) : (
                <PieChart
                  width={600}
                  height={300}
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    width={"100%"}
                    endAngle={0}
                    data={noData}
                    cy={"90%"}
                    cx={"50%"}
                    innerRadius={iR}
                    outerRadius={oR}
                    labelLine={false}
                    label={noDataCustumize}
                    fill="#dfdfe4"
                    stroke="none"
                    isAnimationActive={false}
                  >
                    {noData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={"#dfdfe4"} />
                    ))}
                  </Pie>
                </PieChart>
              )}
            </div>
            {/* <ResponsiveContainer width="100%" maxHeight={400} height={250}>
              <RadialBarChart
                startAngle={180}
                endAngle={0}
               
                cy={250}
                innerRadius="80%"
                outerRadius="180%"
                barSize={100}
                margin={{ top: 10 }}
                data={data}
              >
                <RadialBar
                  background
                  dataKey="uv"
                  legendType="none"
                  fill="#ffff"
                  type="monotone"
                  cornerRadius="10"
                  isAnimationActive={false}
                />
                <Legend
                  iconSize={100}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={style}
                />
              </RadialBarChart>
            </ResponsiveContainer>
            <h2
              style={{
                width: "100%",
                textAlign: "center",
                left: 0,
                top: 160,
                margin: "auto",
                fontSize: 60,
                fontWeight: "bold",
                position: "absolute",
              }}
            >
              {Number(get(details, "prosent", 0)).toFixed(1)}%
            </h2> */}
          </Col>
        </Row>
        {/* end chart */}
        <FlexBox justify={"space-between"} align="center">
          <Title className="mt-16">{get(details, "imya", "")}</Title>
          <div onClick={() => handleSetting({ itemDetail: details })}>
            <ReactSVG
              src={setting}
              className={"svg_icon mt-16 link_size-14 svg_18"}
            />
          </div>
        </FlexBox>
      </Card>
    </div>
  );
};

export default StatusChart;
