import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";
import axios from "axios";

const initialState: InitialState = {
  data: { loading: false, truckData: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchTrucks = createAsyncThunk(
  "allTrucks/fetchTrucks",
  async (params: AllPaginationFilterType, { rejectWithValue, signal }) => {
    const soure = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      soure.cancel()
    })
    try {
      const request = await HandBookServices.GetTruck({ ...params, signal: soure.token });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const truckSlices = createSlice({
  name: "allTrucks",
  initialState,
  reducers: {
    handleTruckFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    handleClearFilter: (state) => {
      state.filter = initialState.filter
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrucks.pending, (state) => {
      state.data = {
        loading: true,
        truckData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchTrucks.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        truckData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchTrucks.rejected, (state, payload) => {
      state.data = {
        loading: false,
        truckData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleTruckFilter, handleClearFilter } = truckSlices.actions;
export default truckSlices.reducer;
