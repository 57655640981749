import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  fetchOwnTerritories,
  fetchTerritories,
} from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import {
  changeRegisterInvoice,
  clearRegisterInvoice,
  fetchRegisterInvoice,
  filterRegisterInvoice,
} from "app/slices/reportSlices/registerInvoiceSlices/registerInvoiceSlices";
import { contractorOptions } from "app/states/documents/selectStates";
import { getPaymentMethodData } from "app/states/enumerations/selectStates";
import { getTerritoriesData } from "app/states/handbooks/selectStates";
import { invoicesFilter, invoicesList } from "app/states/reports";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { RegisterInvoicesTable } from "../components";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  territoriya?: string;
  kontragent?: string;
  sposobOplata?: string;
}

// Реестр счетов фактур
const RegisterInvoicesContainer = () => {
  const dispatch = useAppDispatch();
  const [check, setCheck] = useState(false);
  const [details, setDetails] = useState<ParamState>({
    territoriya: "",
    kontragent: "",
    sposobOplata: "",
  });

  // data
  const filter = useAppSelector(invoicesFilter);
  const data = useAppSelector(invoicesList);
  const LOADING_TABLE = get(data, "loading", false);

  const contractorData = useAppSelector(contractorOptions);
  const territoryData = useAppSelector(getTerritoriesData);
  const paymentMetData = useAppSelector(getPaymentMethodData);

  // filter data
  const fetchInvoiseDate = useCallback(() => {
    dispatch(fetchRegisterInvoice(filter));
  }, [dispatch, filter]);

  // clear filter
  const handleClearFilter = () => {
    dispatch(clearRegisterInvoice());

    setDetails({
      territoriya: "",
      kontragent: "",
      sposobOplata: "",
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeRegisterInvoice(null));
    }

    dispatch(filterRegisterInvoice({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnTerritories({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchOwnTerritories({ all: true }));
    dispatch(fetchAllCounterpart({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Реестр счетов фактур ${moment(new Date()).format(
      "YYYY-MM-DD"
    )}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `${check ? "K1" : "I1"}`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "territoriya", ""),
        label: `Tерритория: ${get(details, "territoriya", "")}`,
      },
      {
        value: get(details, "kontragent", ""),
        label: `Заказчик: ${get(details, "kontragent", "")}`,
      },
      {
        value: get(details, "sposobOplata", ""),
        label: `Способ оплаты: ${get(details, "sposobOplata", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell("A1").value = `Реестр счетов фактур с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(
          `A${1 + index + 1}`,
          `${check ? "K" + (1 + index + 1) : "I" + (1 + index + 1)}`
        );
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Номер счетфактуры",
        key: "nomerDoc",
        width: 30,
      },
      {
        header: "Дата счет фактуры",
        key: "dataDoc",
        width: 25,
      },
      {
        header: "Заказчик",
        key: "imyaKontragent",
        width: 35,
      },
      {
        header: "Договор",
        key: "nomerDogProdaja",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "imyaSposobOplati",
        width: 30,
      },

      {
        header: "Сумма",
        key: "summa",
        width: 20,
      },
      {
        header: "НДС",
        key: "nds",
        width: 20,
      },
      {
        header: "Всего",
        key: "vsego",
        width: 30,
      },
    ];
    const newTableHeader = check
      ? tableHeader.splice(
          6,
          0,
          check && {
            header: "Товар",
            key: "imyaTovar",
            width: 25,
          },
          check && {
            header: "Количество",
            key: "kol",
            width: 25,
          }
        )
      : tableHeader;
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList", [])?.forEach((item: any, index: number) => {
      if (!check) {
        sheet.addRow({
          order: index + 1,
          nomerDoc: get(item, "nomerDoc", ""),
          dataDoc: get(item, "dataDoc", ""),
          imyaKontragent: get(item, "imyaKontragent", ""),
          nomerDogProdaja: get(item, "nomerDogProdaja", ""),
          imyaSposobOplati: get(item, "imyaSposobOplati", ""),
          summa: Number(get(item, "summa", "")),
          nds: Number(get(item, "nds", "")),
          vsego: Number(get(item, "vsego", "")),
        });
        counterRow++;
      } else {
        sheet.addRow({
          order: index + 1,
          nomerDoc: get(item, "nomerDoc", ""),
          dataDoc: get(item, "dataDoc", ""),
          imyaKontragent: get(item, "imyaKontragent", ""),
          nomerDogProdaja: get(item, "nomerDogProdaja", ""),
          imyaSposobOplati: get(item, "imyaSposobOplati", ""),
          summa: Number(get(item, "summa", 0)),
          nds: Number(get(item, "nds", 0)),
          vsego: Number(get(item, "vsego", 0)),
        });
        counterRow += 1;
        get(item, "details", []).forEach((detail: any) => {
          const row = sheet.addRow({
            order: "ㅤ",
            nomerDoc: "ㅤ",
            dataDoc: "ㅤ",
            imyaKontragent: "ㅤ",
            nomerDogProdaja: "ㅤ",
            imyaSposobOplati: "ㅤ",
            imyaTovar: get(detail, "imyaTovar", ""),
            kol: Number(get(detail, "kol", "")),
            summa: Number(get(detail, "summa", "")),
            nds: Number(get(detail, "nds", "")),
            vsego: "ㅤ",
          });
          row.eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
            };
          });
          counterRow++;
        });
      }
    });

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      `${check ? "K" : "I"}${counterRow + count + 1}`
    );
    // const startCell = worksheet.getCell('A1');
    // const endCell = worksheet.getCell('K17');

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  placeholder="Дата начало"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "fromDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
                <DatePicker
                  placeholder="Дата конец"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
                <Title
                  xx
                  className="title_style"
                  mk
                  style={{ justifyContent: "left" }}
                >
                  Детально{" "}
                  <Checkbox
                    handleChange={() => {
                      setCheck(!check);
                      handleChangeFilter({
                        name: "detalno",
                        value: !check,
                      });
                    }}
                  />
                </Title>
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchInvoiseDate}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={9} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  options={territoryData}
                  placeholder="Tерритория"
                  value={get(filter, "territoriyaId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.territoriyaId === e.value) return;

                    handleChangeFilter({
                      name: "territoriyaId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      territoriya: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchRegisterInvoice({
                        ...filter,
                        territoriyaId: e.value,
                      })
                    );
                  }}
                />

                <BaseSelect
                  options={contractorData}
                  placeholder="Заказчик"
                  value={get(filter, "kontragentId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.kontragentId === e.value) return;

                    handleChangeFilter({
                      name: "kontragentId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      kontragent: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchRegisterInvoice({
                        ...filter,
                        kontragentId: e.value,
                      })
                    );
                  }}
                />

                <BaseSelect
                  options={paymentMetData}
                  placeholder="Способ оплаты"
                  value={get(filter, "sposobOplatiId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.sposobOplatiId === e.value) return;

                    handleChangeFilter({
                      name: "sposobOplatiId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      sposobOplata: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchRegisterInvoice({
                        ...filter,
                        sposobOplatiId: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Реестр счетов фактур"}
                  />
                  {!isEmpty(get(data, "dataList", [])) ? (
                    <RegisterInvoicesTable data={data} click={check} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default RegisterInvoicesContainer;
