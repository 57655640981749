import { Title } from "components";
import { get } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";

type ParamsType = {
  filter?: {
    fromDate?: Date | string | number | null;
    toDate?: Date | string | number | null;
    dateIsp?: Date | string | number | null;
    tipMaterial?: string | number | null;
  };
  details?: {
    kontragent?: string | number | null;
    sposobOplata?: string | null;
    sposobDastafki?: string | null;
    territoriya?: string | null;
    sklad?: string | null;
    kassa?: string | null;
    tipTovara?: string | null;
    voditel?: string | null;
    vidTovaraId?: string | number | null;
    tipLitsaId?: string | number | null;
  };
  titleTxt: string;
};

function ReportInfoTitle({ filter, details, titleTxt = "-" }: ParamsType) {
  return (
    <Row className="mt-16">
      <Col xs={12}>
        {filter?.toDate && filter?.fromDate ? (
          <Title bold className="mb-8">
            {titleTxt} с{" "}
            {moment(get(filter, "fromDate", 0)).format("DD.MM.YYYY")} по{" "}
            {moment(get(filter, "toDate", 0)).format("DD.MM.YYYY")}.
          </Title>
        ) : (
          <Title bold className="mb-8">
            {titleTxt} на{" "}
            {moment(filter?.fromDate || filter?.toDate).format("DD.MM.YYYY")}.
          </Title>
        )}
        {filter?.tipMaterial && (
          <Title>Тип материал: {filter?.tipMaterial}</Title>
        )}
        {details?.kontragent && <Title>Контрагент: {details.kontragent}</Title>}

        {details?.tipTovara && (
          <Title>Вид товара: {details.tipTovara}</Title>
        )}
        {details?.vidTovaraId && (
          <Title>Вид товар: {details.vidTovaraId}</Title>
        )}
        {details?.territoriya && (
          <Title>Tерритория: {details.territoriya}</Title>
        )}
        {filter?.dateIsp && (
          <Title>
            Дата исполнения договора:{" "}
            {moment(filter.dateIsp).format("DD.MM.YYYY")}
          </Title>
        )}
        {details?.sposobOplata && (
          <Title>Способ оплаты: {details.sposobOplata}</Title>
        )}
        {details?.sposobDastafki && (
          <Title>Способ доставки: {details.sposobDastafki}</Title>
        )}
        {details?.sklad && <Title>Склад: {details.sklad}</Title>}
        {details?.kassa && <Title>Касса: {details.kassa}</Title>}
        {details?.voditel && <Title>Водитель: {details.voditel}</Title>}
        {details?.tipLitsaId && <Title>Тип лица: {details.tipLitsaId}</Title>}
      </Col>
    </Row>
  );
}

export default ReportInfoTitle;
