import { useAppDispatch, useAppSelector } from "app/hook";
import {
  addNewRow,
  clearCalculation,
  clearMaterialsCalculation,
  fetchCalculation,
  handleAddMaterials,
  handleCalculationFilter,
  handleCalculationFilterClear,
  removeRowMaterial,
} from "app/slices/handbookSlices/calculationSlices/calculationSlices";
import { CalculationDataType } from "app/slices/handbookSlices/calculationSlices/type";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import { fetchUnitsMeasurement } from "app/slices/handbookSlices/unitsMeasurementSlices/unitsMeasurementSlices";
import {
  SemiProductDataOptions,
  allCalculationData,
  allCalculationFilter,
  calculationMaterials,
} from "app/states/handbooks";
import {
  getMaterialDataForCol,
  getProductData,
  getTypeMaterialsData,
  getUnitsMeasurementData,
} from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { ReactSVG } from "react-svg";
import add_green from "assets/images/icons/add_green.svg";
import HandBookServices from "services/apiServices/spravochnik";
import { toast } from "react-toastify";
import remove_red from "assets/images/icons/remove_red.svg";
import { NumericFormat } from "react-number-format";
import { fetchTypeMaterial } from "app/slices/handbookSlices/typeMaterialSlices/typeMaterialSlices";
import { fetchSemiProductMaterial } from "app/slices/handbookSlices/semiProductSlice/semiProductSlice";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { calculationSchema } from "utilities/schema/spravochnikSchemas";
import HasAccess from "routers/HasAccess";
import { MESSAGES, PROTECTED_TYPE } from "utilities/constants";
import { removeValueOption } from "utilities/helpers";
//  Калькуляция
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const dispatch = useAppDispatch();
  const getProduct = useAppSelector(getProductData);
  const getUnitsMeasurement = useAppSelector(getUnitsMeasurementData);
  const materialsForm = useAppSelector(calculationMaterials);
  const [activeTip, setActiveTip] = useState(2);
  const getSemiMaterial = useAppSelector((store) =>
    SemiProductDataOptions(store, activeTip)
  );
  const onSubmit = () => {
    try {
      HandBookServices.AddNewCalculation({
        data: {
          tovarId: get(values, "tovarId", null),
          registerDate: get(values, "registerDate", null),
          kalkulyatsiyaDetailsList:
            get(
              materialsForm,
              "data.kalkulyatsiyaDetailsList[0].materialId",
              []
            ) === null
              ? null
              : get(materialsForm, "data.kalkulyatsiyaDetailsList", []),
        },
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        if (res.status == 200 && res.data) {
          handleCloseModal();
          afterSubmitAction();
          resetForm();
          toast.success(MESSAGES.ADDED);
        }
      });
      resetForm();
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      tovarId: null,
      registerDate: new Date(),
    },
    validationSchema: calculationSchema,
    onSubmit,
  });
  //  calculate price on changes
  const calculation = useCallback(
    (one: number | string, two: number | string, index: number | string) => {
      if (one || two || index) {
        dispatch(
          handleAddMaterials({
            name: "kol",
            value: Number(one) + Number(two),
            id: index,
          })
        );
      }
    },
    [dispatch]
  );
  const clearDetails = () => {
    handleCloseModal();

    resetForm();
    dispatch(clearMaterialsCalculation());
    dispatch(clearCalculation());
  };
  useEffect(() => {
    dispatch(fetchProduct({ all: true }));
    dispatch(fetchUnitsMeasurement({ all: true }));
    dispatch(fetchTypeMaterial({ all: true }));
    dispatch(fetchRawMaterial({ all: true, tip: "SM" }));
    dispatch(fetchSemiProductMaterial({ calc: true, all: true, tip: "PF" }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"} style={{ paddingLeft: 0 }}>
          <FlexBox gap="10px">
            <Col>
              <FlexBox>
                <Title style={{ paddingRight: "24px" }}>
                  Наименование товара<span className="required_point">*</span>
                </Title>
                <BaseSelect
                  id={"tovarId"}
                  width="200px"
                  isSearchable
                  value={get(values, "tovarId", "")}
                  options={getProduct}
                  // menuPosition={"static"}
                  handleChange={(e: any) => {
                    setFieldValue("tovarId", e);
                  }}
                />
              </FlexBox>
            </Col>
            <Col>
              <FlexBox width="100%">
                <Title className="px-24">
                  Дата регистрации<span className="required_point">*</span>
                </Title>

                <DatePicker
                  width="150px"
                  id={"registerDate"}
                  value={get(values, "registerDate", "")}
                  minDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                  handleDate={(e: string) => {
                    setFieldValue("registerDate", e);
                  }}
                />
              </FlexBox>
            </Col>
          </FlexBox>
          <Row className="mt-24">
            <BaseTable
              tableHeader={[
                "Тип *",
                "Материал *",
                "Ед.изм *",
                "Кол-во по норме на единицу продукции *",
                "Кол-во отходов на единицу продукции *",
                "Кол-во всего *",
                "Целое *",
              ]}
            >
              {!isEmpty(
                get(materialsForm, "data.kalkulyatsiyaDetailsList", [])
              ) &&
                get(materialsForm, "data.kalkulyatsiyaDetailsList", []).map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index + 1}>
                        <td style={{ width: "200px" }}>
                          <BaseSelect
                            isSearchable
                            width={"300px"}
                            id={"tip"}
                            value={get(item, "tip", null)}
                            options={[
                              { label: "Полуфабрикат", value: 1 },
                              { label: "Сырьё и материалы", value: 2 },
                            ]}
                            handleValueLabel={(e: any) => {
                              dispatch(
                                handleAddMaterials({
                                  name: "tip",
                                  value: get(e, "value", null),
                                  id: index,
                                })
                              );
                              dispatch(
                                handleAddMaterials({
                                  name: "materialId",
                                  value: null,
                                  id: index,
                                })
                              );
                              dispatch(
                                handleAddMaterials({
                                  name: "edIzmId",
                                  value: null,
                                  id: index,
                                })
                              );
                              setActiveTip(get(e, "value", null));
                            }}
                          />
                        </td>
                        <td style={{ width: "200px" }}>
                          <BaseSelect
                            width={"200px"}
                            isSearchable
                            defaultValue={{
                              label: get(item, "imyaMateriala", null),
                              value: get(item, "materialId", null),
                            }}
                            value={get(item, "materialId", null)}
                            s
                            options={removeValueOption({
                              currentOption: getSemiMaterial,
                              removeValueList: get(
                                materialsForm,
                                "data.kalkulyatsiyaDetailsList",
                                []
                              ),
                              valueName: "materialId",
                            })}
                            handleValueLabel={(e: any) => {
                              dispatch(
                                handleAddMaterials({
                                  name: "materialId",
                                  value: get(e, "value", null),
                                  id: index,
                                })
                              );
                              dispatch(
                                handleAddMaterials({
                                  name: "imyaMateriala",
                                  value: get(e, "label", null),
                                  id: index,
                                })
                              );
                              dispatch(
                                handleAddMaterials({
                                  name: "edIzmId",
                                  value: get(e, "edIzmId", null),
                                  id: index,
                                })
                              );
                            }}
                          />
                        </td>
                        <td style={{ width: "100px" }}>
                          <BaseSelect
                            width={"200px"}
                            id={"edIzmId"}
                            value={get(item, "edIzmId", null)}
                            options={getUnitsMeasurement}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <BaseMarkInput
                            value={get(item, "kolNorm", null) || ""}
                            maxLength={40}
                            handleInput={(e: string) => {
                              dispatch(
                                handleAddMaterials({
                                  name: "kolNorm",
                                  value: e,
                                  id: index,
                                })
                              );
                              calculation(
                                Number(e),
                                Number(get(item, "kolOtx", null)),
                                index
                              );
                            }}
                          />
                        </td>
                        <td>
                          <BaseMarkInput
                            value={get(item, "kolOtx", null) || ""}
                            handleInput={(e: string) => {
                              dispatch(
                                handleAddMaterials({
                                  name: "kolOtx",
                                  value: e,
                                  id: index,
                                })
                              );
                              calculation(
                                Number(get(item, "kolNorm", "")),
                                Number(e),
                                index
                              );
                            }}
                          />
                        </td>
                        <td>
                          <BaseMarkInput
                            value={
                              Number(get(item, "kolNorm", null)) +
                              Number(get(item, "kolOtx", null)) || ""
                            }
                          />
                        </td>
                        <td style={{ width: "130px" }}>
                          <BaseSelect
                            id={"seloe"}
                            width="400px"
                            isSearched
                            value={get(item, "seloe", 0)}
                            options={[
                              { label: "Нет", value: 0 },
                              { label: "Да", value: 1 },
                            ]}
                            handleChange={(e: any) => {
                              dispatch(
                                handleAddMaterials({
                                  name: "seloe",
                                  value: e,
                                  id: index,
                                })
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
            </BaseTable>
          </Row>
          <Row justify="end" className="px-16">
            <ReactSVG
              src={add_green}
              className="svg_icon px-14"
              onClick={() =>
                dispatch(
                  addNewRow({
                    tip: 2,
                    materialId: null,
                    edIzmId: null,
                    kolNorm: null,
                    kolOtx: null,
                    kol: null,
                    seloe: null,
                  })
                )
              }
            />
            {get(materialsForm, "data.kalkulyatsiyaDetailsList", []).length >=
              2 && (
                <ReactSVG
                  src={remove_red}
                  className="svg_icon"
                  onClick={() => dispatch(removeRowMaterial())}
                />
              )}
          </Row>
          <Col
            xs={12}
            className="text-right mt-24 mb-16"
            style={{ paddingRight: 0 }}
          >
            <Button className="mr-8" type="submit" green>
              Добавить
            </Button>
            <Button handleClick={clearDetails} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const CalculationContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(allCalculationData);
  const filter = useAppSelector(allCalculationFilter);
  const LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleCalculationFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchCalculation({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(fetchCalculation({ ...filter, search: "", InAscOrder: false }));
    dispatch(
      handleCalculationFilter({
        name: "search",
        value: "",
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    return () => {
      dispatch(handleCalculationFilterClear());
    };
  }, []);
  const fetchAllCalculationData = useCallback(() => {
    dispatch(fetchCalculation({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    dispatch(clearMaterialsCalculation());
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteCalculation(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        handleFilter();
      }
    });
  };
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "KALKULYATSIYA_TOV_CREATE",
                    page: "KALKULYATSIYA_TOV",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                value={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>

            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal} width={"1200px"}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllCalculationData()}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименования товара",
                    "Дата регистрации",
                    "Материал",
                    "Единица измерения",
                    "Кол-во по норме на единицу продукции",
                    "Кол-во отходов на единицу продукции",
                    "Кол-во всего",
                    "Целое",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "calculationData", [])) ? (
                        get(data, "calculationData", []).map(
                          (item: CalculationDataType, index: number) => (
                            <>
                              <tr key={item.id}>
                                <td className="td_action">
                                  <ActionBase
                                    isVertical={true}
                                    isShowUpdate={false}
                                    pagePermission={"KALKULYATSIYA_TOV"}
                                    handleDelete={() =>
                                      handleDelete(get(item, "id", null))
                                    }
                                  />
                                </td>
                                <td>{get(item, "id", 0)}</td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "tovarImya", "-")}</td>
                                <td>
                                  {moment(get(item, "registerDate")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                              {get(item, "kalkulyatsiyaDetailsList", []).map(
                                (material: any, index: number) => (
                                  <tr key={index + 1}>
                                    <td></td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {get(material, "imyaMateriala", "-")}
                                    </td>
                                    <td>{get(material, "imyaEdIzm", "-")}</td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(material, "kolNorm", "-")}
                                        thousandSeparator
                                        decimalScale={2}
                                      />
                                    </td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(material, "kolOtx", "-")}
                                        thousandSeparator
                                        decimalScale={2}
                                      />
                                    </td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(material, "kol", "-")}
                                        thousandSeparator
                                        decimalScale={2}
                                      />
                                    </td>
                                    <td>
                                      {get(material, "seloe", "-") === 1
                                        ? "да"
                                        : "нет"}
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          )
                        )
                      ) : (
                        <Nodata colLength={11} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={11}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default CalculationContainer;
