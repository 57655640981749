import FlexBox from "components/FlexBox/FlexBox";
import { Props } from "components/MenuItem/type";
import Text from "components/Text/Text";
import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { MenuItemWrapper } from "./style";
import { memo } from "react";

const MenuItem = ({ title, icon = "", url = "/", ...props }: Props) => {
  return (
    <MenuItemWrapper {...props}>
      <NavLink to={url} className={"menu-item"}>
        <FlexBox>
          <ReactSVG src={icon} className={"menu-icon "} />
          <Text className={"truncate"} norm>
            {title}
          </Text>
        </FlexBox>
      </NavLink>
    </MenuItemWrapper>
  );
};

export default memo(MenuItem);
