import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import ReportsServices from "services/apiServices/reports";
import moment from "moment";

const initialState: InitialState = {
  data: { loading: false, balanceList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  },
};

export const fetchBalanceSheet = createAsyncThunk(
  "balanceSheet/fetchBalanceSheet",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchBalanceSheetTable(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const blancaSheetSlices = createSlice({
  name: "balanceSheet",
  initialState,
  reducers: {
    filterBlancaSheet: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearBlancaSheet: (state) => initialState,
    changeBlanceSheet: (state, action) => {
      state.data = {
        loading: false,
        balanceList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBalanceSheet.pending, (state) => {
      state.data = {
        loading: true,
        balanceList: [],
        error: "",
      };
    });
    builder.addCase(fetchBalanceSheet.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        balanceList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchBalanceSheet.rejected, (state, payload) => {
      state.data = {
        loading: false,
        balanceList: [],
        error: payload.error.message,
      };
    });
  },
});
export const { filterBlancaSheet, clearBlancaSheet, changeBlanceSheet } = blancaSheetSlices.actions;
export default blancaSheetSlices.reducer;
