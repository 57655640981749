import { memo, useEffect, useState, useCallback } from "react";
import { ActionWrapper } from "./style";
import { Props } from "./type";
import { ReactSVG } from "react-svg";
// import Button from "components/Button/Button";
import pen_icon from "assets/images/icons/pen_icon.svg";
import delate from "assets/images/icons/delate.svg";
import FlexBox from "components/FlexBox/FlexBox";
import type { MenuProps } from "antd";
import action from "assets/images/icons/action_icon.svg";
import { Dropdown, message, Button } from "antd";
import HasAccess from "routers/HasAccess";
import { PROTECTED_TYPE } from "utilities/constants";
import { useAppSelector } from "app/hook";
import { dogPermissions } from "app/states/profile";
import { get, isEmpty } from "lodash";

enum ACTIONS {
  CHANGE = "CHANGE",
  DELETE = "DELETE",
}
const ActionBase = ({
  handleDelete,
  id,
  handleUpdate,
  disable,
  isShowDelete = true,
  isShowUpdate = true,
  pagePermission,
  isVertical,
}: Props) => {
  // for get permissions
  const permissionDocument = useAppSelector(dogPermissions);
  const dogs = permissionDocument.find(
    (item: any) => item.department == "SPRAVOCHNIKI"
  );
  const permissionList = get(dogs, "pages", []).find(
    (item: any) => get(item, "page", "") == pagePermission
  );
  const items = [
    {
      label: "Изменить",
      key: ACTIONS.CHANGE,
      icon: <ReactSVG src={pen_icon} className="mr-4 svg_icon" />,
      permission: `${pagePermission}_UPDATE`,
      style: {
        paddingLeft: "2px",
        alignItem: "center",
        margin: "5px",
        fontFamly: "Arial, Helvetica, sans-serif",
        background: "#00c838",
        fontSize: "15px",
        color: "#ffffff",
      },
    },
    {
      label: "Удалить",
      key: ACTIONS.DELETE,
      permission: `${pagePermission}_DELETE`,
      icon: (
        <ReactSVG
          className="mr-8 svg_icon"
          src={delate}
          style={{ fontSize: "10px" }}
        />
      ),
      style: {
        fontSize: "15px",
        margin: "5px",
        alignItem: "center",
        fontFamly: "Arial, Helvetica, sans-serif",
        color: "#ffffff",
        backgroundColor: "rgba(216, 0, 39, 1)",
      },
    },
  ];
  // for not show delete button
  if (!isShowDelete) {
    items.pop();
  }
  if (!isShowUpdate) {
    items.shift();
  }

  const checkPermissionAction = () => {
    if (pagePermission) {
      return items.filter((item: any) => {
        if (get(permissionList, "actions", []).includes(item.permission)) {
          return item;
        }
      });
    } else {
      return items;
    }
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case ACTIONS.CHANGE:
        return handleUpdate(id);
      case ACTIONS.DELETE:
        return handleDelete(id);
    }
  };

  // useEffect(() => {
  //   checkPermissionAction();
  // }, [checkPermissionAction]);
  const menuProps = {
    items: checkPermissionAction(),
    onClick: handleMenuClick,
    placement: "bottomRight",
  };

  return (
    <div style={disable ? { display: "none" } : { width: "100%" }}>
      {!isEmpty(checkPermissionAction()) && (
        <Dropdown menu={menuProps}>
          <Button type="link">
            <ReactSVG
              src={action}
              className={isVertical ? "svg_trans" : "svg_icon"}
              style={{ position: "relative" }}
            />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

export default memo(ActionBase);
