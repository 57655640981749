import {
  Content,
  FlexBox,
  Title,
  LoadingDetailInfo,
  DatePicker,
  Button,
  FormButton,
} from "components";
import { Col, Row } from "react-grid-system";
import LineChartBoard from "../recharts/LineChart";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchChartData,
  fetchHomeChartData,
} from "app/slices/homeSlices/homeSlices";
import { Sidebar } from "primereact/sidebar";
import { useAppDispatch, useAppSelector } from "app/hook";
import { get, isEmpty, isEqual, map } from "lodash";
import StatusChart from "../recharts/StatusChart";
import {
  chartLine,
  chartLoading,
  chartResult,
  statusChartData,
} from "app/states/home";

import Skeleton from "react-loading-skeleton";
import { HomeWrapper } from "./style";
import { useFormik } from "formik";
import HomeService from "services/apiServices/home";
import moment from "moment";
type DetailsType = {
  ostalos: number;
  otgruzka: number;
  prosent: number;
  zakaz: number;
  vidTovaraId: string;
  imya: string;
};
const HomeContainer = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(statusChartData);
  const chartDataInfo = useAppSelector(chartLine);
  const keyData = useAppSelector(chartResult);
  const IsLOADING = useAppSelector(chartLoading);
  let LOADING = useMemo(() => IsLOADING, [IsLOADING]);
  const [sidebar, setSideBar] = useState(false);
  const [checkedProduct, setCheckedProduct] = useState<DetailsType | {}>({});
  const [changeDetails, setChangeDetails] = useState<Array<DetailsType> | []>(
    []
  );
  const [arrayProduct, setProduct] = useState({
    keyData: "Бетон",
    color: "#3a78d0",
  });

  const fetchData = useCallback(() => {
    dispatch(fetchHomeChartData(changeDetails));
  }, [dispatch]);

  const globalColors = [
    "#3a78d0",
    "#42be6b",
    "#dbae1b",
    "#6720d8",
    "#1bdbb1",
    "#db1b2b",
    "#6560C6",
  ];

  const clickProduct = ({ id }: any) => {
    if (id) {
      const product = keyData?.find((item) => item.keyData == id);
      if (product) {
        setProduct(product);
      }
    }
  };
  //   second fetch
  const fetchDataByDispatch = useCallback(
    async (request: any) => {
      try {
        HomeService.ChartStatusData(request).then((res) => {
          if (res && res?.status == 200) {
            dispatch(fetchChartData(res.data));
            setSubmitting(false);
          }
        });
      } catch (error) {
        console.log(error);
        setSubmitting(false);
        return error;
      }
    },
    [changeDetails]
  );
  // on submit
  const onSubmit = (values: any) => {
    if (values.vidTovaraId) {
      const isDuplicate = changeDetails.some(
        (item) => item.vidTovaraId === values.vidTovaraId
      );
      //  update
      if (isDuplicate) {
        setChangeDetails(
          changeDetails.map((item) =>
            item.vidTovaraId === values.vidTovaraId
              ? {
                  ...item,
                  fromDate: get(values, "fromDate", null),
                  toDate: get(values, "toDate", null),
                }
              : item
          )
        );
      } else {
        //  new
        setChangeDetails([...changeDetails, values]);
      }
      setSideBar(false);
    }
  };
  const { values, handleSubmit, setFieldValue, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        fromDate: get(checkedProduct, "fromDate", null),
        vidTovaraId: get(checkedProduct, "vidTovaraId", ""),
        toDate: get(checkedProduct, "toDate", null),
      },
      onSubmit,
    });
  const handleReset = () => {
    setFieldValue("fromDate", null);
    setFieldValue("toDate", null);
    setChangeDetails(
      changeDetails.filter((item) => item.vidTovaraId !== values.vidTovaraId)
    );
  };
  // importion part fetch
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchDataByDispatch(changeDetails);
    const interval = setInterval(() => {
      fetchDataByDispatch(changeDetails);
    }, 9000); // 3 seconds
    return () => {
      clearInterval(interval); // clear
    };
  }, [changeDetails]);
  return (
    <HomeWrapper>
      <Row className="scroll_dashboard">
        <div>
          <Sidebar
            visible={sidebar}
            onHide={() => setSideBar(false)}
            position="right"
          >
            <h2>{get(checkedProduct, "imya", "")}</h2>
            <p className="mt-16">
              <p className="mb-8">Дата начало</p>
              <DatePicker
                // isClearable
                handleDate={(e: any) => {
                  setFieldValue("fromDate", moment(e).format("YYYY-MM-DD"));
                }}
                value={get(values, "fromDate", null)}
                dateFormat="dd.MM.yyyy"
              />
            </p>
            <p className="mt-8">
              <p className="mb-8">Дата конец</p>
              <DatePicker
                // isClearable
                handleDate={(e: any) => {
                  setFieldValue("toDate", moment(e).format("YYYY-MM-DD"));
                }}
                minDate={moment(get(values, "fromDate", null)).toDate()}
                value={get(values, "toDate", null)}
                dateFormat="dd.MM.yyyy"
              />
            </p>
            <p className="mt-16">
              <Row>
                <Col xs={6}>
                  <FormButton
                    loading={isSubmitting}
                    type={"submit"}
                    handleClick={handleSubmit}
                  >
                    Применять
                  </FormButton>
                </Col>
                <Col xs={6}>
                  <Button danger handleClick={() => handleReset()}>
                    Сброс
                  </Button>
                </Col>
              </Row>
            </p>
          </Sidebar>
        </div>
        <Col className="mt-24 ">
          <Row>
            <Col className="px-84" xs={12}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                }}
              >
                {!LOADING
                  ? !isEmpty(status) &&
                    status?.map((item: any, index: number) => (
                      <StatusChart
                        colors={globalColors}
                        details={item}
                        key={index}
                        index={index}
                        changeDetails={changeDetails}
                        setCheckedProduct={setCheckedProduct}
                        setSidebar={setSideBar}
                      />
                    ))
                  : map([...new Array(4)], (_, index) => <LoadingDetailInfo />)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="mt-24 px-84 mb-64">
          <Content className="br-20" height="50vh">
            <FlexBox
              justify={"space-between"}
              align="center"
              style={{ width: "100%" }}
            >
              <Title bold sm className="p-8">
                Реализованная продукция
              </Title>
              <div
                className="px-24"
                style={{
                  gap: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  float: "right",
                }}
              >
                {!LOADING
                  ? !isEmpty(keyData) &&
                    keyData?.map((item: any, index: number) => (
                      <div
                        key={index + 1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        className={
                          isEqual(
                            get(item, "keyData", ""),
                            arrayProduct.keyData
                          )
                            ? "clicked"
                            : "active_line"
                        }
                        onClick={() =>
                          clickProduct({ id: get(item, "keyData", "") })
                        }
                      >
                        <div
                          style={{
                            backgroundColor: `${get(item, "color", "")}`,
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <Title xs className="pl-8">
                          {get(item, "keyData", "")}
                        </Title>
                      </div>
                    ))
                  : map([...new Array(5)], (_, index) => (
                      <div
                        key={index + 1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Skeleton
                          height={"20px"}
                          width={"20px"}
                          className="ml-8"
                          borderRadius={"50%"}
                        />
                        <Skeleton
                          height={"20px"}
                          width={"100px"}
                          className="ml-4"
                        />
                      </div>
                    ))}
              </div>
            </FlexBox>
            <Row align="center" justify="center">
              <Col xs={12}>
                {!LOADING ? (
                  <LineChartBoard
                    data={chartDataInfo}
                    keyDataInfo={[arrayProduct]}
                  />
                ) : (
                  <Skeleton height={"350px"} width={"100%"} className="my-8" />
                )}
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>
    </HomeWrapper>
  );
};

export default HomeContainer;
