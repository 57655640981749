import styled, { css } from "styled-components";
import { StyleProps } from "./type";
const ShowPassword = styled.div`
font-size:14px;
position:absolute;
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
height: 36px;
right: 15px;
line-height: normal;
color: black;
border:solid 1px solid black;
outline-style: none;
cursor:pointer;
padding:0;
width: 50px;
background-color: #ffff;
  border: 1px solid hsl(0, 0%, 80%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
border-top-left-radius: 0px !important;
border-bottom-left-radius: 0px !important;

border-left: 0px;

`
const InputWrapper = styled.input<StyleProps>`
  background: ${({ disabled }: StyleProps) =>
    disabled ? "#ffffff" : "#ffffff"};
  border: 1px solid hsl(0, 0%, 80%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 8px 12px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  margin: ${({ margin }: StyleProps) => margin || "0px"};
  color: #1c1c1c;
  display: inline-block;
  min-height: 36px;
  width: ${({ width }: StyleProps) => width || "100%"};
  max-width: ${({ width }: StyleProps) => width || "320px"};
  ${({ password }: any) =>
    password &&
    css`
   font-size: 23px;

    `};      
  &::placeholder {
    color: #979797;
  }
  /* &:hover {
    border-color: hsl(0, 0%, 70%);
  } */
  /* &:focus {
    border-color: #2684ff;
  } */

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
export { InputWrapper, ShowPassword };
