import { useAppDispatch, useAppSelector } from "app/hook";
import {
  changeExpenseCashSubject,
  fetchAllRegisterBezNalich,
  fetchAllRegisterBezNalichStat,
  fetchAllRegisterBezNalichTemp,
  handleSubmitDetailExpenseCash,
} from "app/slices/documentSlices/registerBezNalichSlice/registerBezNalichSlice";
import {
  clearPurchaseProductsState,
  fetchPurchaseContracts,
} from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import { fetchSalesContracts } from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import { fetchIncomes } from "app/slices/enumerationsSlices/incomeSlices/incomeSlices";
import { fetchTypeFlow } from "app/slices/enumerationsSlices/typeFlowSlices/typeFlowSlices";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchExpanseItem } from "app/slices/handbookSlices/expenseItemSlices/expanseItemSlices";
import { fetchOwnKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import {
  regesBezNalichFilter,
  regisBezNalichDetails,
} from "app/states/documents";
import {
  contractorOptions,
  getOnlyContractorIdOptions,
  loadingConterpart,
  optionsStatyaZatrata,
  purchaseContractOptionsById,
} from "app/states/documents/selectStates";
import { tipRasxodaOptions } from "app/states/enumerations/selectStates";
import {
  getDriversData,
  getTerritoriesData,
  loadingDrivers,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  Input,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import _, { forEach, get, isEmpty, isEqual, isObject } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { checkDateYear, textFormatter } from "utilities/helpers";
import {
  expenseSchemaFive,
  expenseSchemaFour,
  expenseSchemaOne,
  expenseSchemaSix,
  expenseSchemaThere,
  expenseSchemaTwo,
  registerExpenseCashSchema,
} from "utilities/schema/document/documentSchemas";
import { NumberDocType } from "types/documentsType";

// Регистрация расхода безналичных денег −> Добавить
type OptionType = {
  label: string;
  value: number | string;
};

type ContractDetails = {
  nomerDoc: string;
  dataDoc: string;
  kontragentId: OptionType | {};
  dataIsp: string;
  sposobOplataId: OptionType | {};
  sposobDostavkaId: OptionType | {};
};

enum REKVIZET_NAMES {
  VODITEL = "VODITEL",
  KONTR_AGENT = "KONTR_AGENT",
  DOGOVOR = "DOGOVOR",
  TERRITORYA = "TERRITORYA",
  STATYA_RASXODA = "STATYA_RASXODA",
  DOG_PRODAJ = "DOG_PRODAJ",
}
/**
 *
 * @param kassaPrice =  the all limit in the document in calculat type prixoda limit
 * @param documentId = document id need to get type rasxoda limits
 */
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  id,
  documentId,
  kassaPrice,
  tableData,
  sumWithInitial,
}: {
  sumWithInitial: number;
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  id?: number | null;
  documentId: string | null;
  kassaPrice: number;
  tableData: Array<any>;
}) => {
  const dispatch = useAppDispatch();
  const typeRasxodaOptions = useAppSelector(tipRasxodaOptions);
  const kontragentOptions = useAppSelector(contractorOptions);
  let loadingKontragent = useAppSelector(loadingConterpart);
  let loadingDriversLoad = useAppSelector(loadingDrivers);
  const drivers = useAppSelector(getDriversData);
  const territory = useAppSelector(getTerritoriesData);
  const optionsStatyaZatrat = useAppSelector(optionsStatyaZatrata);
  // const [maxAmount, setMaxAmount] = useState<any>(null);
  const details = tableData?.find((_, index: number) => index === id);
  //  get Тип расхода limit and Kassa limit - check - and show min limit /** if update the update Тип расхода summa in kassa limit */
  let KassaSumma = kassaPrice - sumWithInitial + get(details, "summa", 0);
  const onSubmit = (values: any) => {
    if (isEmpty(details)) {
      dispatch(changeExpenseCashSubject({ type: ACTION.ADD, data: values }));
      // setMaxAmount(null);
      resetForm();
    } else {
      dispatch(
        changeExpenseCashSubject({
          type: ACTION.UPDATE,
          data: { ...values },
          id,
        })
      );
      // setMaxAmount(null);
    }
    resetForm();
    handleCloseModal();
  };

  const [schema, setSchema] = useState<any>();
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      tipRasxodaId: get(details, "tipRasxodaId", null),
      kontragentId: get(details, "kontragentId", null),
      dogPokupkaId: get(details, "dogPokupkaId", null),
      summa: get(details, "summa", ""),
      kommentariya: get(details, "kommentariya", ""),
      voditeliId: get(details, "voditeliId", ""),
      imyaVoditeli: get(details, "imyaVoditeli", null),
      imyaTipRasxoda: get(details, "imyaTipRasxoda", null),
      nomerDogPokupka: get(details, "nomerDogPokupka", null),
      territoriyaId: get(details, "territoriyaId", null),
      imyaTerritoriya: get(details, "imyaTerritoriya", null),
      imyaStatyaRasxoda: get(details, "imyaStatyaRasxoda", null),
      statyaRasxodaId: get(details, "statyaRasxodaId", null),
      imyaKontragent: get(details, "imyaKontragent", null),
      dogProdajaId: get(details, "dogProdajaId", null),
      nomerDogProdaja: get(details, "nomerDogProdaja", null),
    },
    onSubmit,
    validationSchema: schema,
    enableReinitialize: true,
  });
  const checkSchemaChange = useCallback(() => {
    if (get(values, "tipRasxodaId", null)) {
      switch (values.tipRasxodaId.toString()) {
        case "1":
          setSchema(expenseSchemaOne);
          break;
        case "2":
          setSchema(expenseSchemaTwo);
          break;
        case "3":
          setSchema(expenseSchemaThere);
          break;
        case "4":
          setSchema(expenseSchemaFour);
          break;
        case "5":
          setSchema(expenseSchemaFive);
          break;
        case "6":
          setSchema(expenseSchemaSix);
          break;
        default:
          setSchema(registerExpenseCashSchema);
      }
    } else {
      setSchema(registerExpenseCashSchema);
    }
  }, [values.tipRasxodaId]);

  const purchaseContractsOptions = useAppSelector((store) =>
    purchaseContractOptionsById(store, get(values, "kontragentId", ""))
  );
  const getSaleContracts = useAppSelector((store) =>
    getOnlyContractorIdOptions(store, get(values, "kontragentId", null))
  );

  const fetchOrders = useCallback(() => {
    if (get(values, "tipRasxodaId", "")) {
      switch (get(values, "tipRasxodaId", "").toString()) {
        case "1":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ type: 2, all: true }));

          break;
        case "2":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ type: 1, all: true }));
          break;
        case "3":
          dispatch(fetchDrivers({ filterPage: "RASXOD_D", all: true }));
          break;
        case "4":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ type: 4, all: true }));
          dispatch(clearPurchaseProductsState());
          dispatch(
            fetchPurchaseContracts({
              kontragentId: get(values, "kontragentId", ""),
              filterPage: "PRIXOD_D",
            })
          );
          break;
        case "5":
          dispatch(fetchExpanseItem({ all: true }));
          break;
        case "6":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ type: 3, all: true }));
          break;
        default:
          break;
      }
    }
  }, [values.tipRasxodaId, values.kontragentId]);
  const checkIsAvailable = ({ tipRasxodaId, rekvizetName }: any) => {
    switch (rekvizetName) {
      case REKVIZET_NAMES.VODITEL:
        if (tipRasxodaId == 3) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.KONTR_AGENT:
        if (
          tipRasxodaId == 1 ||
          tipRasxodaId == 2 ||
          tipRasxodaId == 4 ||
          tipRasxodaId == 6
        ) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.DOGOVOR:
        if (tipRasxodaId == 4) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.TERRITORYA:
        if (tipRasxodaId == 5) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.STATYA_RASXODA:
        if (tipRasxodaId == 5) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.DOG_PRODAJ:
        if (tipRasxodaId == 6) {
          return true;
        }
        return false;
    }
  };

  const fetchSelectors = useCallback(() => {
    dispatch(fetchIncomes({ all: true }));
  }, [dispatch]);
  const handleClose = () => {
    handleCloseModal();
    // setMaxAmount(null);
    resetForm();
  };
  //  get limit summa
  // const fetchPriceByCategorries = useCallback(() => {
  //   try {
  //     if (get(values, "tipRasxodaId", null)) {
  //       if ([4].includes(get(values, "tipRasxodaId", 0))) {
  //         // DocumentServices.CheckRegisterBezNalSumma({
  //         //   dogPokupkaId: get(values, "dogPokupkaId", null),
  //         //   tipRasxodaId: get(values, "tipRasxodaId", null),
  //         //   kontragentId: get(values, "kontragentId", null),
  //         //   rasxodBDId: documentId,
  //         // }).then(({ data }) => {
  //         //   setMaxAmount(get(data, "data", 0));
  //         // });
  //       } else if ([1, 2].includes(get(values, "tipRasxodaId", 0))) {
  //         DocumentServices.CheckRegisterBezNalSumma({
  //           tipRasxodaId: get(values, "tipRasxodaId", null),
  //           rasxodBDId: documentId,
  //         }).then(({ data }) => {
  //           setMaxAmount(get(data, "data", 0));
  //         });
  //       } else if ([6].includes(get(values, "tipRasxodaId", 0))) {
  //         DocumentServices.CheckRegisterBezNalSumma({
  //           tipRasxodaId: get(values, "tipRasxodaId", null),
  //           kontragentId: get(values, "kontragentId", null),
  //           dogProdajaId: get(values, "dogProdajaId", null),
  //           rasxodBDId: documentId,
  //         }).then(({ data }) => {
  //           setMaxAmount(get(data, "data", 0));
  //         });
  //       } else if ([3].includes(get(values, "tipRasxodaId", 0))) {
  //         if (
  //           get(values, "tipRasxodaId", null) &&
  //           get(values, "voditeliId", null)
  //         ) {
  //           DocumentServices.CheckRegisterBezNalSumma({
  //             voditeliId: get(values, "voditeliId", null),
  //             tipRasxodaId: get(values, "tipRasxodaId", null),
  //             rasxodBDId: documentId,
  //           }).then(({ data }) => {
  //             setMaxAmount(get(data, "data", 0));
  //           });
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [
  //   values.kontragentId,
  //   values.tipRasxodaId,
  //   values.dogPokupkaId,
  //   values.dogProdajaId,
  //   values.voditeliId,
  // ]);
  const fetchDogPrdaja = useCallback(() => {
    if (get(values, "kontragentId", null)) {
      dispatch(
        fetchSalesContracts({
          all: true,
          filterPage: "RASXOD_D",
          kontragentId: get(values, "kontragentId", null),
        })
      );
    }
  }, [values.kontragentId]);
  const ShowLimit = () => {
    return (
      <p>
        {get(values, "summa", 0) > KassaSumma && (
          <Text xs danger>
            ограничение до:
            {textFormatter(KassaSumma)}
          </Text>
        )}
      </p>
    );
  };
  useEffect(() => {
    checkSchemaChange();
  }, [checkSchemaChange]);

  useEffect(() => {
    fetchDogPrdaja();
  }, [fetchDogPrdaja]);

  useEffect(() => {
    fetchSelectors();
  }, [fetchSelectors]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  // useEffect(() => {
  //   fetchPriceByCategorries();
  // }, [fetchPriceByCategorries, documentId]);

  const fetchFormSelectorOptions = useCallback(() => {
    dispatch(fetchOwnKassa({}));
    dispatch(fetchTypeFlow({}));
    dispatch(
      fetchPurchaseContracts({
        all: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    fetchFormSelectorOptions();
    return () => {
      // setMaxAmount(null);
    };
  }, [fetchFormSelectorOptions]);

  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row>
            <Col xs={12}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Тип расхода
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tipRasxodaId"}
                    value={get(values, "tipRasxodaId", "")}
                    options={typeRasxodaOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("tipRasxodaId", e.value);
                      setFieldValue("imyaTipRasxoda", e.label);
                      if (
                        !isEqual(
                          get(values, "tipRasxodaId", null),
                          get(e, "value", "")
                        )
                      ) {
                        setFieldValue("kontragentId", null);
                        setFieldValue("imyaKontragent", "");
                        setFieldValue("dogPokupkaId", null);
                        setFieldValue("nomerDogPokupka", "");
                        setFieldValue("dogProdajaId", null);
                        setFieldValue("nomerDogProdaja", "");
                        setFieldValue("voditeliId", null);
                        setFieldValue("imyaVoditeli", null);
                        setFieldValue("imyaTerritoriya", null);
                        setFieldValue("territoriyaId", null);
                        setFieldValue("statyaRasxodaId", null);
                        setFieldValue("imyaStatyaRasxoda", null);
                        if (
                          !isEqual(1, values.tipRasxodaId) &&
                          !isEqual(2, values.tipRasxodaId)
                        ) {
                          setFieldValue("summa", "");
                        }
                        // setMaxAmount(null);
                      }
                    }}
                  />
                </Col>
              </Row>
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId", ""),
                rekvizetName: REKVIZET_NAMES.KONTR_AGENT,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Контрагент <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"kontragentId"}
                      isLoading={loadingKontragent}
                      value={get(values, "kontragentId", "")}
                      defaultValue={{
                        value: get(values, "kontragentId", null),
                        label: get(values, "imyaKontragent", null),
                      }}
                      options={kontragentOptions}
                      handleValueLabel={(e: any) => {
                        setFieldValue("kontragentId", e.value);
                        setFieldValue("imyaKontragent", e.label);
                        if (
                          !isEqual(
                            get(values, "kontragentId", null),
                            e.value
                          ) &&
                          !isEqual(get(values, "tipRasxodaId", null), 1) &&
                          !isEqual(get(values, "tipRasxodaId", null), 2)
                        ) {
                          setFieldValue("dogPokupkaId", null);
                          setFieldValue("nomerDogProdaja", null);
                          setFieldValue("dogProdajaId", null);
                          setFieldValue("nomerDogPokupka", null);
                          setFieldValue("summa", "");
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId", ""),
                rekvizetName: REKVIZET_NAMES.DOGOVOR,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Договор покупки
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"dogPokupkaId"}
                      value={get(values, "dogPokupkaId", "")}
                      defaultValue={{
                        value: get(values, "dogPokupkaId", null),
                        label: get(values, "nomerDogPokupka", null),
                      }}
                      options={purchaseContractsOptions}
                      handleValueLabel={(e: any) => {
                        setFieldValue("dogPokupkaId", e.value);
                        setFieldValue("nomerDogPokupka", e.label);
                        if (!isEqual(e.value, values.dogPokupkaId)) {
                          setFieldValue("summa", "");
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId", ""),
                rekvizetName: REKVIZET_NAMES.DOG_PRODAJ,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Договор продажа
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"dogProdajaId"}
                      value={get(values, "dogProdajaId", "")}
                      defaultValue={{
                        label: get(values, "dogProdajaId", null),
                        value: get(values, "nomerDogProdaja", null),
                      }}
                      options={getSaleContracts}
                      handleValueLabel={(e: any) => {
                        setFieldValue("dogProdajaId", e.value);
                        setFieldValue("nomerDogProdaja", e.label);
                        if (
                          !isEqual(get(values, "dogProdajaId", null), e.value)
                        ) {
                          setFieldValue("summa", "");
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId", ""),
                rekvizetName: REKVIZET_NAMES.VODITEL,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Водитель
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"voditeliId"}
                      value={get(values, "voditeliId", "")}
                      isLoading={loadingDriversLoad}
                      defaultValue={{
                        label: values.imyaVoditeli,
                        value: values.voditeliId,
                      }}
                      options={drivers}
                      handleValueLabel={(e: any) => {
                        setFieldValue("voditeliId", e.value);
                        setFieldValue("imyaVoditeli", e.label);
                        if (!isEqual(e.value, values.voditeliId)) {
                          setFieldValue("summa", "");
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId", ""),
                rekvizetName: REKVIZET_NAMES.TERRITORYA,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Территория
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"territoriyaId"}
                      value={get(values, "territoriyaId", "")}
                      options={territory}
                      handleValueLabel={(e: any) => {
                        setFieldValue("territoriyaId", e.value);
                        setFieldValue("imyaTerritoriya", e.label);
                      }}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId", ""),
                rekvizetName: REKVIZET_NAMES.STATYA_RASXODA,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Статья расхода
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      isLoading={optionsStatyaZatrat.loading}
                      id={"statyaRasxodaId"}
                      placeholder={get(values, "statyaRasxodaId")}
                      value={get(values, "statyaRasxodaId", "")}
                      defaultValue={{
                        label: get(values, "imyaStatyaRasxoda", ""),
                        value: get(values, "imyaStatyaRasxoda", ""),
                      }}
                      options={optionsStatyaZatrat.list}
                      handleValueLabel={(e: any) => {
                        setFieldValue("statyaRasxodaId", e.value);
                        setFieldValue("imyaStatyaRasxoda", e.label);
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Сумма
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMaskInput
                    id={"summa"}
                    value={get(values, "summa", "")}
                    placeholder={` до: ${textFormatter(KassaSumma)}`}
                    handleInput={(e: number) => {
                      setFieldValue("summa", e);
                    }}
                    fixedDecimalScale={true}
                    maxLength={20}
                  />
                  <ShowLimit />
                </Col>
              </Row>
              <Input
                label={"Коментарии"}
                id={"kommentariya"}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button
              className="mr-8"
              handleClick={handleSubmit}
              green
              inability={
                KassaSumma &&
                Number(get(values, "summa", 0)) > Number(KassaSumma)
              }
            >
              {get(details, "tipRasxodaId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button onClick={() => handleClose()} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const AddRegisterParishCashContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const filter = useAppSelector(regesBezNalichFilter);
  const detailsExpensCash = useAppSelector(regisBezNalichDetails);
  const [disable, setDisable] = useState(false);
  const [kassaPrice, setKassaPrice] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const [bezNalichniixDeneg, setBezNalichniixDeneg] = useState<any>([]);
  const [detailsId, setDetailsId] = useState<number | null>(null);
  const handleOpenModal = useCallback((id: number | null) => {
    setOpenModal(true);
    setDetailsId(id);
  }, []);
  const onSubmit = (values: ContractDetails) => {
    const params: any = get(detailsExpensCash, "data", []).map((item: any) => {
      const rslt: any = {};
      forEach(item, (value: any, key: any) => {
        if (isObject(value)) {
          rslt[key] = get(value, "value", "");
        } else {
          rslt[key] = value;
        }
      });
      return rslt;
    });

    try {
      // is Update doc
      if (get(state, "isUpdate", null)) {
        DocumentServices.UpdateRegisterBezNal({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...values,
            id: get(state, "id", null),
            details: params,
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            dispatch(fetchAllRegisterBezNalich(filter));
            dispatch(fetchAllRegisterBezNalichStat());
            navigate(-1);
            toast.success(MESSAGES.ADDED);
          } else {
            return setSubmitting(false);
          }
        });
        // is add new form
      } else {
        DocumentServices.AddRegisterBezNal({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...values,
            details: params,
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            dispatch(fetchAllRegisterBezNalich(filter));
            navigate(-1);
            toast.success(MESSAGES.ADDED);
            dispatch(fetchAllRegisterBezNalichStat());
            if (get(state, "id", false)) {
              DocumentServices.RemoveRegisterBezNalTemp(
                get(state, "id", "")
              ).then(() => {
                dispatch(fetchAllRegisterBezNalichTemp());
              });
            }
          } else {
            return setSubmitting(false);
          }
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  let initialValues: any = {
    nomerDoc: "",
    dataDoc: moment(new Date()).format("YYYY-MM-DD"),
    kommentariya: "",
  };

  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });
  const handleSetInitialValues = (data: any) => {
    if (data) {
      dispatch(handleSubmitDetailExpenseCash(get(data, "details", [])));
      setBezNalichniixDeneg(get(data, "details", []));
      setFieldValue("kommentariya", get(data, "kommentariya", ""));
      setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
      setFieldValue("dataDoc", get(data, "dataDoc", ""));
    }
  };
  // fetch kassa summa
  const fetchKassaSumma = useCallback(async () => {
    DocumentServices.FetchKassaSummaRegisterBezNal({
      rasxodBDId: get(state, "id", null),
    })
      .then((res) => {
        if (res.status === 200) {
          setKassaPrice(get(res, "data.data", 0));
        } else {
          navigate(-1);
        }
      })
      .catch(() => {
        return navigate(-1);
      });
  }, [state]);

  const getRegisterParishCashNumber = useCallback(
    (date: NumberDocType) => {
      DocumentServices.RegisterBezNalNumber(date)
        .then(({ data }) => {
          setFieldValue("nomerDoc", get(data, "data", ""));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [state]
  );

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        DocumentServices.FetchRegisterBezNalTempById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else if (get(state, "isUpdate", false)) {
        DocumentServices.FetchRegisterBezNalById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
              //  for get kassa prices
              fetchKassaSumma();
            } else {
              setLoading(false);
            }
          }
        );
      } else {
        DocumentServices.FetchRegisterBezNalById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
        setDisable(true);
      }
    } else {
      getRegisterParishCashNumber({});
      setLoading(false);
      fetchKassaSumma();
    }
  }, [dispatch, state]);

  //  calculate all price
  let sumWithInitial = get(detailsExpensCash, "data", []).reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.summa,
    0
  );

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  return (
    <Content className={"min_height"}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          id={detailsId}
          sumWithInitial={sumWithInitial}
          kassaPrice={kassaPrice}
          tableData={get(detailsExpensCash, "data", [])}
          documentId={get(state, "id", null)}
        />
      </ReactModal>
      {isEqual(loading, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                {/* {!get(state, "isUpdate", null) && (
                  <Button
                    green
                    handleClick={saveNotFullFilledForm}
                    disabled={disable}
                  >
                    Сохранить
                  </Button>
                )} */}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            if (
                              checkDateYear({
                                checkYear: moment(e).format("YYYY-MM-DD"),
                                currentYear: get(values, "dataDoc", null),
                              })
                            ) {
                              getRegisterParishCashNumber({
                                year: checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                }),
                              });
                            }
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          maxDate={new Date()}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormInput
                label={"Общая сумма"}
                id={"sebestTovar"}
                value={sumWithInitial}
                disabled={true}
              />
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              {isEqual(disable, false) && (
                <FlexBox justify={"space-between"} align={"center"}>
                  <Title bold sm>
                    Табличная часть
                    <span className="required_point">*</span>
                  </Title>
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal(null)}
                  />
                </FlexBox>
              )}
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Col className="responsive_overflow">
                <BaseTable
                  tableHeader={[
                    "№",
                    "Тип расхода",
                    "Контрагент",
                    "Договор покупки",
                    "Договор продажа",
                    "Водитель",
                    "Территория",
                    "Статья расхода",
                    "Сумма",
                    "Коментарии",
                    "",
                  ]}
                >
                  {!isEmpty(get(detailsExpensCash, "data", null)) ? (
                    get(detailsExpensCash, "data", []).map(
                      (item: any, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaTipRasxoda", "-")}</td>
                          <td>{get(item, "imyaKontragent", "-")}</td>
                          <td>{get(item, "nomerDogPokupka", "-")}</td>
                          <td>{get(item, "nomerDogProdaja", "")}</td>
                          <td>{get(item, "imyaVoditeli", "-")}</td>

                          <td>{get(item, "imyaTerritoriya", "-")}</td>
                          <td>{get(item, "imyaStatyaRasxoda", "-")}</td>

                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td width={"100px"}>
                            {get(item, "kommentariya", "-")}
                          </td>
                          <td>
                            <ActionBase
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  changeExpenseCashSubject({
                                    type: ACTION.DELETE,
                                    id: index,
                                  })
                                )
                              }
                              disable={disable}
                              id={index}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={11}> Нет Реквизиты</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className="mb-16 mt-32 text-end">
            <Col xs={12}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.RASXOD_BD}_CREATE`,
                    page: DOCUMENT_KEY.RASXOD_BD,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {get(state, "isUpdate", null) ? PAGE.CHANGE : PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>

              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddRegisterParishCashContainer;
