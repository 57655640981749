import { useCallback, useEffect } from "react";

import moment from "moment";
import { get, isEmpty, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "app/hook";
import { Button, Content, DatePicker, FlexBox, Title } from "components";
import {
  accountableReportFilter,
  accountableReportList,
} from "app/states/reports";
import {
  clearAccountableReport,
  fetchAccountableReport,
  filterAccountableReport,
  changeAccountableReport,
} from "app/slices/reportSlices/accountableReportSlices/accountableReportSlices";
import { Col, Row } from "react-grid-system";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import { AccountableReportTable } from "../components";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

// Отчет по учету подотчетных сумм
function AccountableReportContainer() {
  const dispatch = useAppDispatch();

  // data
  const data = useAppSelector(accountableReportList);
  const filter = useAppSelector(accountableReportFilter);
  const LOADING_TABLE = get(data, "loading", false);

  const fetchAllAccountableReport = useCallback(() => {
    dispatch(fetchAccountableReport(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearAccountableReport());
  };
  //excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Отчет по учету подотчетных сумм ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `I1`);
    sheet.getCell("A1").value = `Отчет по учету подотчетных сумм с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    sheet.getRow(3).height = 20; // C3 qatridagi balandlik
    sheet.getRow(4).height = 20; // C4 qatridagi balandlik
    sheet.getRow(1).height = 20;
    sheet.mergeCells("A3", `A4`);
    sheet.getCell("A3").value = "№";
    sheet.mergeCells("B3", "B4");
    sheet.getCell("B3").value = "Контрагент";
    sheet.mergeCells("C3", "D3");
    sheet.getCell("C3").value = "Начальный остаток";
    sheet.getCell("C4").value = "Дебет";
    sheet.getCell("D4").value = "Кредит";
    sheet.mergeCells("E3", "F3");
    sheet.getCell("E3").value = "Оборот";
    sheet.getCell("E4").value = "Дебет";
    sheet.getCell("F4").value = "Кредит";
    sheet.mergeCells("G3", "H3");
    sheet.getCell("G3").value = "Конечный остаток";
    sheet.getCell("G4").value = "Дебет";
    sheet.getCell("H4").value = "Кредит";
    let count = 1;
    sheet.getCell("A3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("B3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("D4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("F4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("H4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.columns = [
      { width: 5 },
      { width: 60 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const tableHeader = [
      {
        header: "№",
        key: "index",
        width: 5,
      },
      {
        header: "Наименованиесчета",
        key: "kontragentImya",
        width: 60,
      },
      {
        header: "Дебет",
        key: "nachalniyOstatokDebit",
        width: 30,
      },
      {
        header: "Kredit",
        key: "nachalniyOstatokKredit",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "oborotDebit",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "oborotKredit",
        width: 30,
      },
      {
        header: "konechniyOstatokDebit",
        key: "konechniyOstatokDebit",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "konechniyOstatokKredit",
        width: 30,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    // sheet.getRow(5).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(4);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList.list", [])?.forEach((item, index) => {
      const row = sheet.addRow({
        index: index + 1,
        kontragentImya: get(item, "kontragentImya", ""),
        nachalniyOstatokDebit: Number(get(item, "nachalniyOstatokDebit", 0)),
        nachalniyOstatokKredit: Number(get(item, "nachalniyOstatokKredit", 0)),
        oborotDebit: Number(get(item, "oborotDebit", 0)),
        oborotKredit: Number(get(item, "oborotKredit", 0)),
        konechniyOstatokDebit: Number(get(item, "konechniyOstatokDebit", 0)),
        konechniyOstatokKredit: Number(get(item, "konechniyOstatokKredit", 0)),
      });
      counterRow++;
      // row.height = 25;
      // row.alignment = { vertical: "middle", horizontal: "center" };
    });
    sheet.mergeCells(
      `A${counterRow + 3 + count}`,
      `B${counterRow + 3 + count}`
    );
    sheet.getCell(`A${counterRow + 3 + count}`).value =
      "Итого:  ㅤ ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ";
    sheet.getCell(`C${counterRow + 3 + count}`).value = Number(
      get(data, "dataList.sumOfNachalniyOstatokDebit", 0)
    );
    sheet.getCell(`D${counterRow + 3 + count}`).value = Number(
      get(data, "dataList.sumOfNachalniyOstatokKredit", 0)
    );
    sheet.getCell(`E${counterRow + 3 + count}`).value = Number(
      get(data, "dataList.sumOfOborotDebit", 0)
    );
    sheet.getCell(`F${counterRow + 3 + count}`).value = Number(
      get(data, "dataList.sumOfOborotKredit", 0)
    );
    sheet.getCell(`G${counterRow + 3 + count}`).value = Number(
      get(data, "dataList.sumOfKonechniyOstatokDebit", 0)
    );
    sheet.getCell(`H${counterRow + 3 + count}`).value = Number(
      get(data, "dataList.sumOfKonechniyOstatokKredit", 0)
    );
    // sheet.getRow(counterRow + 3 + count).height = 25;
    // sheet.getRow(counterRow + 3 + count).alignment = {
    //   vertical: "middle",
    //   horizontal: "center",
    // };
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),

      "A" + (counterRow + 3 + count),
      "B" + (counterRow + 3 + count),
      "C" + (counterRow + 3 + count),
      "D" + (counterRow + 3 + count),
      "E" + (counterRow + 3 + count),
      "F" + (counterRow + 3 + count),
      "G" + (counterRow + 3 + count),
      "H" + (counterRow + 3 + count),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`H${counterRow + count + 3}`);
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach((item: any) => {
      sheet.getCell(item).font = {
        size: 11,
        bold: true,
      };
    });
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeAccountableReport(null));
    }

    dispatch(filterAccountableReport({ ...filter, name, value }));
  };

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  placeholder="Дата начало"
                  value={get(filter, "fromDate", "")}
                  width="150px"
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  placeholder="Дата конец"
                  width="150px"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right px-16"} xs={6}>
              {!isEmpty(get(data, "dataList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllAccountableReport}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    titleTxt={"Отчет по учету подотчетных сумм"}
                  />

                  {!isEmpty(get(data, "dataList.list", [])) ? (
                    <AccountableReportTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default AccountableReportContainer;
