import { useAppDispatch, useAppSelector } from "app/hook";
import { costsInCashFilter } from "app/states/documents";
import {
  contractorOptions,
  getOnlyContractorIdOptions,
  optionsStatyaZatrata,
  purchaseContractOptionsById,
} from "app/states/documents/selectStates";
import {
  getDriversData,
  getKassaData,
  getTerritoriesData,
} from "app/states/handbooks/selectStates";
import {
  BaseInput,
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Text,
  Title,
  Form,
  BaseMaskInput,
  TextArea,
  LoadingAddPages,
  FormButton,
} from "components";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual, isObject } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DocumentServices from "services/apiServices/documents";
import moment from "moment";
import {
  fetchAllKassa,
  fetchOwnKassa,
} from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { tipRasxodaOptions } from "app/states/enumerations/selectStates";
import { fetchTypeFlow } from "app/slices/enumerationsSlices/typeFlowSlices/typeFlowSlices";
import {
  clearPurchaseProductsState,
  fetchPurchaseContracts,
} from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchExpanseItem } from "app/slices/handbookSlices/expenseItemSlices/expanseItemSlices";
import {
  fetchAllCostsInCash,
  fetchAllCostsInCashStat,
  fetchAllCostsInCashTemp,
} from "app/slices/documentSlices/costsInCashSlice/costsInCashSlice";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { checkDateYear, textFormatter } from "utilities/helpers";
import { fetchSalesContracts } from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import { NumberDocType } from "types/documentsType";

enum REKVIZET_NAMES {
  VODITEL = "VODITEL",
  KONTR_AGENT = "KONTR_AGENT",
  DOGOVOR = "DOGOVOR",
  DOG_PRODAJ = "DOG_PRODAJ",
  TERRITORYA = "TERRITORYA",
  STATYA_RASXODA = "STATYA_RASXODA",
}
// Расход наличный денег −> Добавить

const AddArrivalCashContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const kassaOptions = useAppSelector(getKassaData);
  const typeRasxodaOptions = useAppSelector(tipRasxodaOptions);
  const kontragentOptions = useAppSelector(contractorOptions);
  const territory = useAppSelector(getTerritoriesData);
  const optionsStatyaZatrat = useAppSelector(optionsStatyaZatrata);
  const [loading, setLoading] = useState(true);

  const onSubmit = (values: any) => {
    const params: any = {};
    forEach(values, (value, key) => {
      if (value) {
        if (key == "dataDoc" || !isObject(value)) {
          params[key] = value;
        } else {
          params[key] = get(value, "value", "");
        }
      }
    });
    try {
      // when update
      if (get(state, "isUpdate", false)) {
        DocumentServices.UpdateCostInCash({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...params,
            id: get(state, "id", null),
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchAllCostsInCash(filter));
            toast.success(MESSAGES.ADDED);
          }
        });
      } else {
        // when add new
        DocumentServices.AddCostInCash({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: params,
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchAllCostsInCash(filter));
            dispatch(fetchAllCostsInCashStat());
            toast.success(MESSAGES.ADDED);
            if (get(state, "id", false)) {
              DocumentServices.RemoveCostInCashTemp(get(state, "id", "")).then(
                (res) => {
                  if (get(res, "status", "") == 200) {
                    dispatch(fetchAllCostsInCashTemp());
                  }
                }
              );
            }
          }
          setSubmitting(false);
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const initialValues = {
    nomerDoc: "",
    dataDoc: "",
    kassaId: "",
    tipRasxodaId: "",
    kontragentId: "",
    dogPokupkaId: "",
    dogProdajaId: "",
    voditeliId: "",
    territoriyaId: "",
    statyaRasxodaId: "",
    summa: "",
    kommentariya: "",
  };

  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const purchaseContractsOptions = useAppSelector((store) =>
    purchaseContractOptionsById(store, get(values, "kontragentId", ""))
  );
  const getSaleContracts = useAppSelector((store) =>
    getOnlyContractorIdOptions(store, get(values, "kontragentId.value", null))
  );
  const filter = useAppSelector(costsInCashFilter);
  const drivers = useAppSelector(getDriversData);
  const [disable, setDisable] = useState(false);
  const [maxAmount, setMaxAmount] = useState<any>(null);

  // submit not fully filled form
  const saveNotFullFilledForm = useCallback(() => {
    const params: any = {};
    forEach(values, (value, key) => {
      if (key == "dataDoc" || !isObject(value)) {
        params[key] = value;
      } else {
        params[key] = get(value, "value", "");
      }
    });
    try {
      DocumentServices.SaveCostInCashTemp({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllCostsInCashTemp());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values]);

  const getOrderDocNumber = useCallback(
    (date: NumberDocType) => {
      DocumentServices.CostInCashNumber(date).then(({ data }) => {
        setFieldValue("nomerDoc", get(data, "data", ""));
        setFieldValue("dataDoc", moment(new Date()).format("YYYY-MM-DD"));
      });
    },
    [setFieldValue, state]
  );

  const handleSetInitialValues = (data: any) => {
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kassaId", {
      value: get(data, "kassaId", ""),
      label: get(data, "imyaKassa", ""),
    });
    setFieldValue("tipRasxodaId", {
      value: get(data, "tipRasxodaId", 0),
      label: get(data, "imyaTipRasxoda", ""),
    });
    setFieldValue("kontragentId", {
      value: get(data, "kontragentId", ""),
      label: get(data, "imyaKontragent", ""),
    });
    setFieldValue("dogPokupkaId", {
      value: get(data, "dogPokupkaId", ""),
      label: get(data, "nomerDogPokupka", ""),
    });
    setFieldValue("voditeliId", {
      value: get(data, "voditeliId", ""),
      label: get(data, "imyaVoditeli", ""),
    });
    setFieldValue("territoriyaId", {
      value: get(data, "territoriyaId", ""),
      label: get(data, "imyaTerritoriya", ""),
    });
    setFieldValue("statyaRasxodaId", {
      value: get(data, "statyaRasxodaId", ""),
      label: get(data, "imyaStatyaRasxoda", ""),
    });
    setFieldValue("summa", get(data, "summa", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
  };

  const fetchFormSelectorOptions = useCallback(() => {
    dispatch(fetchOwnKassa({}));
    dispatch(fetchTypeFlow({}));
    dispatch(
      fetchPurchaseContracts({
        all: true,
      })
    );
  }, []);

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        DocumentServices.FetchCostInCashTempById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              fetchFormSelectorOptions();
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else if (get(state, "isUpdate", false)) {
        DocumentServices.FetchCostInCashById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              fetchFormSelectorOptions();
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else {
        DocumentServices.FetchCostInCashById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
        setDisable(true);
      }
    } else {
      getOrderDocNumber({});
      fetchFormSelectorOptions();
      setLoading(false);
    }
  }, [dispatch, state]);

  const fetchOrders = useCallback(() => {
    if (
      (get(values, "tipRasxodaId.value", "") &&
        get(state, "isUpdate", false)) ||
      isEmpty(get(state, "id", null))
    ) {
      switch (get(values, "tipRasxodaId.value", "").toString()) {
        case "1":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ all: true, type: 2 }));
          break;
        case "2":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ all: true, type: 1 }));
          break;
        case "3":
          dispatch(fetchDrivers({ all: true, filterPage: "RASXOD_D" }));
          break;
        case "4":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ all: true, type: 4 }));
          dispatch(clearPurchaseProductsState());
          dispatch(
            fetchPurchaseContracts({
              all: true,
              kontragentId: get(values, "kontragentId.value", ""),
              filterPage: "PRIXOD_D",
            })
          );
          break;
        case "5":
          dispatch(fetchExpanseItem({ all: true }));
          break;
        case "6":
          dispatch(clearContractorsState());
          dispatch(fetchAllCounterpart({ all: true, type: 3 }));
          break;
        default:
          break;
      }
    }

    // call dog projaka options
    if (
      (get(values, "kontragentId.value", null) &&
        get(state, "isUpdate", false)) ||
      isEmpty(get(state, "id", null))
    ) {
      dispatch(
        fetchSalesContracts({
          all: true,
          filterPage: "RASXOD_D",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );
    }
  }, [values.tipRasxodaId, values.kontragentId]);

  const checkIsAvailable = ({ tipRasxodaId, rekvizetName }: any) => {
    switch (rekvizetName) {
      case REKVIZET_NAMES.VODITEL:
        if (tipRasxodaId == 3) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.KONTR_AGENT:
        if (
          tipRasxodaId == 1 ||
          tipRasxodaId == 2 ||
          tipRasxodaId == 4 ||
          tipRasxodaId == 6
        ) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.DOGOVOR:
        if (tipRasxodaId == 4) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.TERRITORYA:
        if (tipRasxodaId == 5) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.STATYA_RASXODA:
        if (tipRasxodaId == 5) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.DOG_PRODAJ:
        if (tipRasxodaId == 6) {
          return true;
        }
        return false;
    }
  };

  const fetchValidationOfAmount = useCallback(() => {
    try {
      if (
        (get(values, "kassaId.value", null) &&
          !isEqual(get(values, "tipRasxodaId.value", null), 3) &&
          get(values, "tipRasxodaId.value", null)) ||
        get(values, "dogPokupkaId.value", null) ||
        get(values, "kontragentId.value", null) ||
        get(values, "dogProdajaId", null)
      ) {
        DocumentServices.FetchMaxAmountOfCostInCash({
          kassaId: get(values, "kassaId.value", null),
          tipRasxodaId: get(values, "tipRasxodaId.value", null),
          dogPokupkaId: get(values, "dogPokupkaId.value", null),
          kontragentId: get(values, "kontragentId.value", null),
          dogProdajaId: get(values, "dogProdajaId.value", null),
          rkoId: get(state, "id", null),
        })
          .then(({ data }) => {
            setMaxAmount(data.data);
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    values.tipRasxodaId,
    values.dogPokupkaId,
    values.kontragentId,
    values.kassaId,
    values.dogProdajaId,
  ]);
  const getPriceWidthDrivers = useCallback(() => {
    if (
      isEqual(get(values, "tipRasxodaId.value", false), 3) &&
      get(values, "voditeliId.value", false)
    ) {
      const params = {
        voditeliId: get(values, "voditeliId.value", null),
        kassaId: get(values, "kassaId.value", null),
        tipRasxodaId: get(values, "tipRasxodaId.value", null),
        rkoId: get(state, "id", null),
      };
      DocumentServices.FetchMaxAmountOfCostInCash(params)
        .then(({ data }) => {
          if (data) setMaxAmount(data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [values.tipRasxodaId, values.voditeliId, state]);

  useEffect(() => {
    getPriceWidthDrivers();
  }, [getPriceWidthDrivers]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    fetchValidationOfAmount();
  }, [fetchValidationOfAmount]);

  return (
    <Content className={"min_height"}>
      {isEqual(loading, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты 11S
                </Title>
                {isEqual(get(state, "isUpdate", false), false) && (
                  <Button
                    green
                    handleClick={saveNotFullFilledForm}
                    disabled={disable}
                  >
                    Сохранить
                  </Button>
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        name={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", "")}
                          handleDate={(e: any) => {
                            getOrderDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          maxDate={new Date()}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Наименование кассы
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kassaId"}
                    value={get(values, "kassaId.value", "")}
                    defaultValue={get(values, "kassaId", "")}
                    options={kassaOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kassaId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Тип расхода
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tipRasxodaId"}
                    value={get(values, "tipRasxodaId.value", "")}
                    options={typeRasxodaOptions}
                    defaultValue={get(values, "tipRasxodaId")}
                    handleValueLabel={(e: any) => {
                      setFieldValue("tipRasxodaId", e);
                      if (
                        !isEqual(
                          get(values, "tipRasxodaId.value"),
                          get(e, "value", "")
                        )
                      ) {
                        setFieldValue("kontragentId", "");
                        setFieldValue("dogPokupkaId", "");
                        setFieldValue("voditeliId", "");
                        setFieldValue("territoriyaId", "");
                        setFieldValue("statyaRasxodaId", "");
                        if (
                          !isEqual(
                            1,
                            get(values, "tipRasxodaId.value", null)
                          ) &&
                          !isEqual(2, get(values, "tipRasxodaId.value", null))
                        ) {
                          setMaxAmount(null);
                          setFieldValue("summa", "");
                        }
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              {/* n.... */}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                rekvizetName: REKVIZET_NAMES.KONTR_AGENT,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Контрагент <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"kontragentId"}
                      value={get(values, "kontragentId.value", "")}
                      defaultValue={get(values, "kontragentId")}
                      options={kontragentOptions}
                      handleValueLabel={(e: any) => {
                        setFieldValue("kontragentId", e);
                        if (
                          !isEqual(
                            get(values, "kontragentId.value"),
                            get(e, "value", "")
                          ) &&
                          isEqual(get(values, "tipRasxodaId.value"), 4)
                        ) {
                          setFieldValue("dogPokupkaId", "");
                          setFieldValue("summa", "");
                        }
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                rekvizetName: REKVIZET_NAMES.DOGOVOR,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Договор покупки
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"dogPokupkaId"}
                      placeholder={get(values, "dogPokupkaId.label")}
                      defaultValue={get(values, "dogPokupkaId")}
                      value={get(values, "dogPokupkaId.value", "")}
                      options={purchaseContractsOptions}
                      handleValueLabel={(e: any) => {
                        setFieldValue("dogPokupkaId", e);
                        if (
                          isEqual(get(values, "tipRasxodaId.value", null), 4) &&
                          !isEqual(
                            get(values, "dogPokupkaId.value", null),
                            e.value
                          )
                        ) {
                          setFieldValue("summa", "");
                        }
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                rekvizetName: REKVIZET_NAMES.DOG_PRODAJ,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>Договор продажа</Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"dogProdajaId"}
                      value={get(values, "dogProdajaId.value", "")}
                      defaultValue={get(values, "dogProdajaId", "")}
                      options={getSaleContracts}
                      handleValueLabel={(e: any) => {
                        setFieldValue("dogProdajaId", e);
                        if (
                          !isEqual(
                            get(values, "dogProdajaId.value", null),
                            e.value
                          )
                        ) {
                          setFieldValue("summa", "");
                        }
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                rekvizetName: REKVIZET_NAMES.VODITEL,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Водитель
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"voditeliId"}
                      defaultValue={get(values, "voditeliId", "")}
                      value={get(values, "voditeliId.value", "")}
                      options={drivers}
                      handleValueLabel={(e: any) => {
                        setFieldValue("voditeliId", e);
                        if (!isEqual(e, get(values, "voditeliId", ""))) {
                          setFieldValue("summa", "");
                        }
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                rekvizetName: REKVIZET_NAMES.TERRITORYA,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Территория
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"territoriyaId"}
                      defaultValue={get(values, "territoriyaId", "")}
                      value={get(values, "territoriyaId.value", "")}
                      options={territory}
                      handleValueLabel={(e: any) => {
                        setFieldValue("territoriyaId", e);
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}
              {checkIsAvailable({
                tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                rekvizetName: REKVIZET_NAMES.STATYA_RASXODA,
              }) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Статья расхода
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"statyaRasxodaId"}
                      defaultValue={get(values, "statyaRasxodaId", "")}
                      value={get(values, "statyaRasxodaId.value", "")}
                      options={optionsStatyaZatrat}
                      handleValueLabel={(e: any) => {
                        setFieldValue("statyaRasxodaId", e);
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Сумма
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMaskInput
                    id={"summa"}
                    placeholder={
                      maxAmount !== null
                        ? `до: ${textFormatter(maxAmount)}`
                        : ""
                    }
                    value={get(values, "summa", "")}
                    handleInput={(e: any) => setFieldValue("summa", e)}
                    disabled={disable}
                  />
                  {get(values, "summa", 0) > maxAmount &&
                    maxAmount !== null &&
                    isEqual(disable, false) && (
                      <Text xs danger>
                        ограничение до:
                        {textFormatter(maxAmount)}
                      </Text>
                    )}
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>

          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                            Печать
                        </Button> */}

              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.RASXOD_ND}_CREATE`,
                    page: DOCUMENT_KEY.RASXOD_ND,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>

              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddArrivalCashContainer;
