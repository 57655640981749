import * as yup from "yup";
import { mixedSchema, selectSchema, strSchema } from "./document/details";
const counterpartsSchema = yup.object().shape({
  tipYurFizLitsaId: yup.string().required("*required"),
});
export { counterpartsSchema };

const counterPartSchema = yup.object().shape({
  bankSchetDTO: yup.object().shape({
    nomerScheta: yup.mixed().required(),
    bankId: yup.mixed().required(),
  }),
});

const bankSchema = yup.object().shape({
  imya: mixedSchema,
  mfo: mixedSchema,
  gorod: mixedSchema,
  address: mixedSchema,
});

const bankAccountsSchema = yup.object().shape({
  imya: strSchema,
  nomerScheta: strSchema,
  bankId: mixedSchema,
});

const checkOutSchema = yup.object().shape({
  imya: strSchema,
});

const physicalPersonSchema = yup.object().shape({
  imyaFizLitsa: strSchema,
  polnoeImyaFizLitsa: strSchema,
  tipiFizLitsaId: mixedSchema,
  telNomer: strSchema,
});

const largePersonSchema = yup.object().shape({
  imyaYurLitsa: strSchema,
  tipYurLitsaId: mixedSchema,
  inn: strSchema,
  nds: strSchema,
  polnoeImyaYurLitsa: strSchema,
  address: mixedSchema,
  telNomer: strSchema,
});

const bankCounterparts = yup.object().shape({
  kontragentId: mixedSchema,
  imyaBankScheta: strSchema,
  nomerScheta: strSchema,
  bankId: mixedSchema,
  osnovnoy: mixedSchema,
});

const deliveryAddress = yup.object().shape({
  polnoeImenaAddress: strSchema,
  kontragentId: mixedSchema,
});

const termsPaymentSchema = yup.object().shape({
  imyaUsloviyaOplati: strSchema,
  polnoyeImyaUsloviyaOplati: strSchema,
});

const wareHouseSchema = yup.object().shape({
  imyaSklada: strSchema,
  tipSklada: mixedSchema,
  fizLitsaId: mixedSchema,
});

const territorySchema = yup.object().shape({
  imyaTerritorii: strSchema,
  adresTerritorii: strSchema,
  skladSMId: mixedSchema,
  skladGPId: mixedSchema,
});

const unitsMeasurementSchema = yup.object().shape({
  imyaEdIzm: strSchema,
  polnoyeImyaEdIzm: strSchema,
  kodEdIzm: strSchema,
});

const discountSchema = yup.object().shape({
  imya: strSchema,
  prosent: strSchema,
  okrugleniyeId: mixedSchema,
});

const typeProductSchema = yup.object().shape({
  imya: strSchema,
});

const productSchema = yup.object().shape({
  imya: strSchema,
  polnoeImya: strSchema,
  vidTovaraId: mixedSchema,
  vesavoy: mixedSchema,
  edIzmId: mixedSchema,
});

const priceSchema = yup.object().shape({
  tovarId: mixedSchema,
  edIzmId: mixedSchema,
  sena: mixedSchema,
  data: mixedSchema,
});

const calculationSchema = yup.object().shape({
  tovarId: mixedSchema,
  registerDate: mixedSchema,
});

const typeMaterialSchema = yup.object().shape({
  imyaVidMateriala: strSchema,
});

const rowMaterialSchema = yup.object().shape({
  imyaMateriala: strSchema,
  polnoyeImyaMateriala: strSchema,
  edIzmId: mixedSchema,
  vesovoy: mixedSchema,
  vidMaterialId: mixedSchema,
});

const semiProductSchema = yup.object().shape({
  imyaMateriala: strSchema,
  polnoyeImyaMateriala: strSchema,
  edIzmId: mixedSchema,
  vesovoy: mixedSchema,
  tip: strSchema,
});

const semiCalculationSchema = yup.object().shape({
  polufabrikatId: mixedSchema,
  registerDate: mixedSchema,
});

const vatRatesSchema = yup.object().shape({
  stavkaNDSImya: strSchema,
  stavka: mixedSchema,
});

const expanseItemSchema = yup.object().shape({
  imyaStRasxoda: mixedSchema,
});

const typeTruckSchema = yup.object().shape({
  imya: strSchema,
});

const truckSchema = yup.object().shape({
  imya: strSchema,
  tipAvtoId: mixedSchema,
  gosNomer: strSchema,
});

const driverSchema = yup.object().shape({
  // avtoId: mixedSchema,
  // telNomer: strSchema,
  // tipiFizLitsaId: mixedSchema,
});

const userDTOSchema = yup.object().shape({
  imya: yup.string().required(),
  tipiFizLitsaId: yup.mixed().required(),
  telNomer: yup.mixed().required(),
  parol: yup
    .string()
    .required("")
    .min(
      6,
      "Пароль должен содержать от 6 до 16 символов, включая как минимум одну букву и одну цифру"
    )
    .max(
      16,
      "Пароль должен содержать от 6 до 16 символов, включая как минимум одну букву и одну цифру"
    )
    .matches(
      /[a-z A-Z]/,
      "Пароль должен содержать от 6 до 16 символов, включая как минимум одну букву и одну цифру"
    )
    .matches(
      /[0-9]/,
      "Пароль должен содержать от 6 до 16 символов, включая как минимум одну букву и одну цифру"
    ),
});

const detailsComponySchema = yup.object().shape({
  imya: strSchema,
  polnoeImya: strSchema,
  inn: strSchema,
  nds: strSchema,
  oked: strSchema,
  address: mixedSchema,
  telefonNomer: strSchema,
  email: strSchema,
  rukovoditel: strSchema,
  glavbuh: strSchema,
});
export {
  bankSchema,
  bankAccountsSchema,
  checkOutSchema,
  physicalPersonSchema,
  largePersonSchema,
  bankCounterparts,
  deliveryAddress,
  termsPaymentSchema,
  wareHouseSchema,
  territorySchema,
  unitsMeasurementSchema,
  discountSchema,
  typeProductSchema,
  counterPartSchema,
  productSchema,
  priceSchema,
  calculationSchema,
  typeMaterialSchema,
  rowMaterialSchema,
  semiProductSchema,
  semiCalculationSchema,
  vatRatesSchema,
  expanseItemSchema,
  typeTruckSchema,
  truckSchema,
  driverSchema,
  userDTOSchema,
  detailsComponySchema,
};
