import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { Col, Row } from "react-grid-system";
import { ProcurementPlanTable } from "../components";
import { useAppDispatch, useAppSelector } from "app/hook";
import { procurementPlanFilter, procurementPlanList } from "app/states/reports";
import { useCallback, useEffect, useState } from "react";
import {
  changeProcumerentTable,
  clearProcumerentTable,
  fetchProcurementPlan,
  filterProcurementTable,
} from "app/slices/reportSlices/procurementPlanSlices/procurementPlanSlices";
import moment from "moment";
import {
  getSkladCM,
  getTerritoriesData,
} from "app/states/handbooks/selectStates";
import {
  fetchOwnTerritories,
  fetchTerritories,
} from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import {
  fetchOwnSkladCM,
  fetchSkladCM,
} from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { get, isEmpty, isEqual } from "lodash";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

interface ParamsState {
  sklad?: string;
  territoriya?: string;
}

// План закупок ТМЦ (на основании объёма заказов)
const ProcurementPlanContainer = () => {
  const dispatch = useAppDispatch();

  // details
  const [details, setDetails] = useState<ParamsState>({
    sklad: "",
    territoriya: "",
  });

  // data
  const data = useAppSelector(procurementPlanList);
  const filter = useAppSelector(procurementPlanFilter);
  const getTerritory = useAppSelector(getTerritoriesData);
  const LOADING_TABLE = get(data, "loading", false);

  const fetchAllProcurementPlan = useCallback(() => {
    if (filter.territoriyaId) dispatch(fetchProcurementPlan(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearProcumerentTable());

    setDetails({
      sklad: "",
      territoriya: "",
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeProcumerentTable(null));
    }

    dispatch(filterProcurementTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnTerritories({ all: true }));
    dispatch(fetchOwnSkladCM({}));
    dispatch(handleClearFilter);

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `План закупок ТМЦ (на основании объёма заказов)${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `I1`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "sklad", ""),
        label: `Tерритория: ${get(details, "sklad", "")}`,
      },
      {
        value: get(details, "territoriya", ""),
        label: `Tерритория: ${get(details, "territoriya", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `План закупок ТМЦ (на основании объёма заказов) на ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} `;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(`A${1 + index}`, `I${1 + index}`);
        sheet.getCell(`A${1 + index}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Материал",
        key: "imyaMateriala",
        width: 30,
      },
      {
        header: "Ед.изм-я",
        key: "edIzm",
        width: 10,
      },
      {
        header: "Остаток на складе",
        key: "ostKol",
        width: 20,
      },

      {
        header: "Номер Заказ-наряда",
        key: "nomerZakazNaryad",
        width: 20,
      },
      {
        header: "Дата Заказ-наряда",
        key: "dataZakazNaryad",
        width: 30,
      },
      {
        header: "Товар",
        key: "imyaTovara",
        width: 30,
      },
      {
        header: "Количество",
        key: "kolTovara",
        width: 20,
      },
      {
        header: "Требуемое количество",
        key: "requiredKol",
        width: 30,
      },
      {
        header: "Количество для закупки",
        key: "kolDilyaZakupka",
        width: 30,
      },

      //123
    ];
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));

    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "planList.list", [])?.forEach((item, index) => {
      sheet.addRow({
        order: index + 1,
        imyaMateriala: get(item, "imyaMateriala", ""),
        edIzm: get(item, "edIzm", ""),
        ostKol: Number(get(item, "ostKol", 0)),
        nomerZakazNaryad: "ㅤ",
        dataZakazNaryad: "ㅤ",
        imyaTovara: "ㅤ",
        kolTovara: "ㅤ",
        requiredKol: Number(get(item, "requiredKol", "")),
        kolDilyaZakupka: Number(get(item, "kolDilyaZakupka", "")),
      });
      counterRow += 1;
      get(item, "details", []).forEach((detail) => {
        const row = sheet.addRow({
          order: "ㅤ",
          imyaMateriala: "ㅤ",
          edIzm: "ㅤ",
          ostKol: "ㅤ",
          nomerZakazNaryad: get(detail, "nomerZakazNaryad", ""),
          dataZakazNaryad: get(detail, "dataZakazNaryad", ""),
          imyaTovara: get(detail, "imyaTovara", ""),
          kolTovara: Number(get(detail, "kolTovara", 0)),
          requiredKol: Number(get(detail, "kolMateriala", 0)),
          kolDilyaZakupka: "ㅤ",
        });
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
          };
        });
        counterRow++;
      });
    });

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`J${counterRow + count + 1}`);

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  disabled={true}
                  placeholder="Дата"
                  width={"230px"}
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "fromDate", "")}
                  defaultDate={moment(new Date()).format("dd.MM.yyyy")}
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
                <BaseSelect
                  options={getTerritory}
                  placeholder="Территория"
                  width="200px"
                  value={get(filter, "territoriyaId", null)}
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "territoriyaId",
                      value: e.value,
                      clear: true,
                    });

                    setDetails({
                      ...details,
                      territoriya: get(e, "label", ""),
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5} md={6}>
              {!isEmpty(get(data, "planList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllProcurementPlan}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.planList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"План закупок ТМЦ (на основании объёма заказов)"}
                  />
                  {!isEmpty(get(data, "planList.list", [])) ? (
                    <ProcurementPlanTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default ProcurementPlanContainer;
