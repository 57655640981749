import { createSelector } from "@reduxjs/toolkit";
import {
  selectOrderRegisterFilter,
  selectOrderRegisterList,
  selectSaleContractFilter,
  selectSaleContractList,
  selectSaleContractForm,
  selectSaleTempsContractList,
  selectSaleCntrCountStat,
  selectPurchaseContractFilter,
  selectPurchaseContractList,
  selectPurchaseTempsContractList,
  selectPurchaseContractForm,
  selectPurchaseCntrCountStat,
  selectReceivedMaterialsForm,
  selectReceivedMaterialsFilter,
  selectReceivedMaterialsList,
  selectTempsReceivedMaterialsList,
  selectReceivedMaterialsStat,
  selectOrderRegisterForm,
  selectOrderRegisterStat,
  selectReleaseProductForm,
  selectReleaseProductFilter,
  selectReleaseProductList,
  selectTempsReleaseProductList,
  selectReleaseProductStat,
  selectShipmentProductFilter,
  selectShipmentProductList,
  selectTempsShipmentProductList,
  selectShipmentProductStat,
  selectShipmentProductForm,
  selectSalesProductsForm,
  selectSalesProductsFilter,
  selectSalesProductsList,
  selectTempsSalesProductsList,
  selectSalesProductsStat,
  selectTempsOrderRegisterList,
  selectSemiProductForm,
  selectSemiProductFilter,
  selectSemiProductList,
  selectTempsSemiProductList,
  selectSemiProductStat,
  selectReturnMaterialsForm,
  selectReturnMaterialsFilter,
  selectReturnMaterialsList,
  selectTempReturnMaterialsList,
  selectReturnMaterialsStat,
  selectMovementMaterialsForm,
  selectMovementMaterialsFilter,
  selectTempMovementMaterialsList,
  selectMovementMaterialsStat,
  selectReturnProductForm,
  selectReturnProductStat,
  selectTempsReturnProductList,
  selectReturnProductList,
  selectReturnProductFilter,
  selectOtherCostFilter,
  selectOtherCosts,
  selectOtherCostsTemps,
  selectReturnProductBerRealForm,
  selectReturnProductBerRealFilter,
  selectReturnProductBerRealList,
  selectReturnProductBerRealStat,
  selectTempsReturnProductBerRealList,
  selectRegisterParishCash,
  selectRegisterParishCashFilter,
  selectRegisterParishCashTemps,
  selectArrivedCashFilter,
  selectArrivedCash,
  selectArrivedCashTemps,
  selectCostsInCashFilter,
  selectCostsInCash,
  selectCostsInCashTemps,
  selectRegisterParishCashForm,
  selectMovemetMaterialsList,
  selectManualMaterialsForm,
  selectManualMaterialsFilter,
  selectManualMaterialsList,
  selectTempManualMaterialsList,
  selectManualMaterialsStat,
  selectRegesBezNalichFilter,
  selectRegesBezNalichCash,
  selectRegesBezNalichTemps,
  selectOffsetAdvanceFilter,
  selectOffsetAdvanceList,
  selectOffsetAdvanceStat,
  selectCalculateProductFilter,
  selectCalculateProductList,
  selectCalculateProductStat,
  selectCloseMonthFilter,
  selectCloseMonthList,
  selectCloseMonthStat,
  selectManualPostingFilter,
  selectManualPostingList,
  selectManualPostingStat,
  selectRtrnConcreteDriverFilter,
  selectRtrnConcreteDriverTemps,
  selectRtrnConcreteDriver,
  selectManualPostingDetails,
  selectOtherCostsDetails,
  selectRegisterBezNalichCashDetails,
  selectSubstandardProductList,
  selectSubstandardProductFilter,
  selectSubstandardProductForm,
  selectOtherCostsStatistic,
  selectRtrnConcreteDriverStat,
  selectArrivedStatistic,
  selectChangeProductFilter,
  selectChangeProductList,
  selectChangeProductStat,
  selectChangeProductForm,
  selectRegisterBezNalichStat,
  selectRegisterParishCashStat,
  selectCostsInCashStat,
  selectTempChangeProduct,
  selectChangeSemiProductFilter,
  selectChangeSemiProductList,
  selectChangeSemiProductForm,
  selectTempChangeSemiProduct,
  selectChangeSemiProductStat,
  selectMovingCashFilter,
  selectMovingCash,
  selectMovingCashTemps,
  selectMovingStatistic,
  selectSubstandardProductStatistics,
  selectArrivedRemainder,
  selectSemiProductDetails,
} from "app/selectors/documents";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { textFormatter } from "utilities/helpers";
const dateFormat = moment(new Date()).format("YYYY-MM-DD");
const lastYearDate = moment(new Date()).endOf("year").format("YYYY-MM-DD");
export const rgstSaleContractsForm = createSelector(
  [selectSaleContractForm],

  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kommentariya: get(formData, "data.kommentariya", ""),
      tipDogovoraId: {
        value: get(formData, "data.tipDogovoraId", 2),
        label: get(formData, "data.imyaTipDogovora", "Разовый"),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", null),
        label: get(formData, "data.imyaKontragent", null),
      },
      dataIsp: get(formData, "data.dataIsp", lastYearDate),
      sposobOplataId: {
        value: get(formData, "data.sposobOplataId", ""),
        label: get(formData, "data.sposobOplataName", ""),
      },
      sposobDostavkaId: {
        value: get(formData, "data.sposobDostavkaId", 1),
        label: get(
          formData,
          "data.sposobDostavkaName",
          "Доставка автотранспортом	"
        ),
      },
      usloviyaOplataId: {
        value: get(formData, "data.usloviyaOplataId", ""),
        label: get(formData, "data.imyaUsloviyaOplati", ""),
      },
      stavkaNDSId: {
        value: get(formData, "data.stavkaNDSId", 1),
        label: get(formData, "data.stavkaNDSImya", "Без ндс"),
      },
    };
    const res = {
      ...formData,
      data: {
        formIntVls,
        dogProdajaDetails: !isEmpty(get(formData, "data.dogProdajaDetails", []))
          ? get(formData, "data.dogProdajaDetails", [])
          : [],
      },
    };
    return res;
  }
);

export const saleContractFilter = createSelector(
  selectSaleContractFilter,
  (filter) => filter
);

export const saleContractList = createSelector(
  selectSaleContractList,
  (salesCnts) => salesCnts
);

export const saleTempsContractList = createSelector(
  selectSaleTempsContractList,
  (notFilledCntrList) => notFilledCntrList
);

export const saleContractStat = createSelector(
  selectSaleCntrCountStat,
  (saleContractStat) => saleContractStat
);

//  order register
export const orderRegisterForm = createSelector(
  selectOrderRegisterForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dateIsp: get(formData, "data.dateIsp", ""),
      tipDogovoraId: get(formData, "data.tipDogovoraId", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      dataTsen: get(formData, "data.dataTsen", dateFormat),
      otmVipolneniya: get(formData, "data.otmVipolneniya", 0),
      skladGPId: {
        value: get(formData, "data.skladGPId", ""),
        label: get(formData, "data.imyaSkladaGP", ""),
      },
      dogProdajaId: {
        value: get(formData, "data.dogProdajaId", ""),
        label: get(formData, "data.dogProdajaNomer", ""),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.kontragentImya", ""),
      },
      sposobDostavkaId: {
        value: get(formData, "data.sposobDostavkaId", ""),
        label: get(formData, "data.imyaSposobDostavka", ""),
      },
      addresaDostavkiId: {
        value: get(formData, "data.addresaDostavkiId", ""),
        label: get(formData, "data.imyaAddresaDostavki", ""),
      },
      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritoriya", ""),
      },
      skladId: {
        value: get(formData, "data.skladId", ""),
        label: get(formData, "data.imyaSklada", ""),
      },
    };
    const res = {
      ...formData,
      data: {
        formIntVls,
        zakazNaryadTovars: get(formData, "data.zakazNaryadTovars", []),
      },
    };
    return res;
  }
);

export const orderRegisterFilter = createSelector(
  selectOrderRegisterFilter,
  (filter) => filter
);

export const orderRegisterList = createSelector(
  selectOrderRegisterList,
  (orderRegisters) => orderRegisters
);

export const orderRegisterStat = createSelector(
  selectOrderRegisterStat,
  (state) => state
);

export const orderTempsRegisterList = createSelector(
  selectTempsOrderRegisterList,
  (notFilledCntrList) => notFilledCntrList
);
// purchase contract
export const purchaseContractsForm = createSelector(
  selectPurchaseContractForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      summaDog: get(formData, "data.summaDog", 0),
      dataIsp: get(formData, "data.dataIsp", lastYearDate),
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      sposobOplataId: {
        value: get(formData, "data.sposobOplataId", ""),
        label: get(formData, "data.sposobOplataName", ""),
      },
      sposobDostavkaId: {
        value: get(formData, "data.sposobDostavkaId", ""),
        label: get(formData, "data.sposobDostavkaName", ""),
      },
      usloviyaOplataId: {
        value: get(formData, "data.usloviyaOplataId", ""),
        label: get(formData, "data.imyaUsloviyaOplati", ""),
      },
      stavkaNDSId: {
        value: get(formData, "data.stavkaNDSId", 1),
        label: get(formData, "data.stavkaNDSImya", "Без ндс"),
      },
    };
    const res = {
      ...formData,
      data: {
        formIntVls,
        details: get(formData, "data.details", []),
      },
    };
    return res;
  }
);

export const purchaseContractFilter = createSelector(
  selectPurchaseContractFilter,
  (filter) => filter
);

export const purchaseContractList = createSelector(
  selectPurchaseContractList,
  (salesCnts) => salesCnts
);

export const purchaseTempsContractList = createSelector(
  selectPurchaseTempsContractList,
  (notFilledCntrList) => notFilledCntrList
);

export const purchaseContractStat = createSelector(
  selectPurchaseCntrCountStat,
  (purchaseContractStat) => purchaseContractStat
);
//  received materials
export const receivedMaterialsForm = createSelector(
  selectReceivedMaterialsForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      podotchet: {
        value: get(formData, "data.podotchet", 0),
        label: get(formData, "data.imyaPodotchet", "Официально"),
      },
      voditeliId: {
        value: get(formData, "data.voditeliId", null),
        label: get(formData, "data.imyaVoditeli", ""),
      },
      avtoId: {
        value: get(formData, "data.avtoId", ""),
        label: get(formData, "data.gosNomer", ""),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladId: {
        value: get(formData, "data.skladId", ""),
        label: get(formData, "data.imyaSklada", ""),
      },
      dogPokupkaId: {
        value: get(formData, "data.dogPokupkaId", ""),
        label: get(formData, "data.nomerDogPokupka", ""),
      },
    };
    const res = {
      ...formData,
      data: {
        formIntVls,
        prixodSMDetails: get(formData, "data.prixodSMDetails", []),
      },
    };
    return res;
  }
);

export const receivedMaterialsFilter = createSelector(
  selectReceivedMaterialsFilter,
  (filter) => filter
);

export const receivedMaterialsList = createSelector(
  selectReceivedMaterialsList,
  (salesCnts) => salesCnts
);

export const saleTempsReceivedMaterialsList = createSelector(
  selectTempsReceivedMaterialsList,
  (notFilledCntrList) => notFilledCntrList
);

export const saleContractStatMaterials = createSelector(
  selectReceivedMaterialsStat,
  (saleContractStat) => saleContractStat
);
// return materials
export const returnMaterialsForm = createSelector(
  selectReturnMaterialsForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladId: {
        value: get(formData, "data.skladId", ""),
        label: get(formData, "data.imyaSklada", ""),
      },
      dogPokupkaId: {
        value: get(formData, "data.dogPokupkaId", ""),
        label: get(formData, "data.nomerDogPokupka", ""),
      },
    };
    const res = {
      ...formData,
      data: {
        formIntVls,
        vozvratSMDetailsList: get(formData, "data.vozvratSMDetailsList", []),
      },
    };
    return res;
  }
);

export const returnMaterialsFilter = createSelector(
  selectReturnMaterialsFilter,
  (filter) => filter
);
export let loadingReturnMaterila = createSelector(
  selectReturnMaterialsList,
  (data) => get(data, "loading", false)
);
export const returnMaterialsList = createSelector(
  selectReturnMaterialsList,
  (salesCnts) => salesCnts
);

export const saleTempReturnMaterialsList = createSelector(
  selectTempReturnMaterialsList,
  (notFilledCntrList) => notFilledCntrList
);

export const saleContractStatReturnMaterials = createSelector(
  selectReturnMaterialsStat,
  (saleContractStat) => saleContractStat
);
// movement materials
export const movementMaterialsForm = createSelector(
  selectMovementMaterialsForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kommentariya: get(formData, "data.kommentariya", ""),
      litsoOtgruzka: get(formData, "data.litsoOtgruzka", ""),
      litsoPrixod: get(formData, "data.litsoPrixod", ""),
      skladPrixodId: {
        value: get(formData, "data.skladPrixodId", ""),
        label: get(formData, "data.imyaSkladPrixod", ""),
      },
      skladOtgruzkaId: {
        value: get(formData, "data.skladOtgruzkaId", ""),
        label: get(formData, "data.imyaSkladOtgruzka", ""),
      },
    };
    const res = {
      ...formData,
      data: {
        formIntVls,
        details: get(formData, "data.details", []),
      },
    };
    return res;
  }
);

export const movementMaterialsFilter = createSelector(
  selectMovementMaterialsFilter,
  (filter) => filter
);

export const movementMaterialsList = createSelector(
  selectMovemetMaterialsList,
  (salesCnts) => salesCnts
);

export const saleTempMovementMaterialsList = createSelector(
  selectTempMovementMaterialsList,
  (notFilledCntrList) => notFilledCntrList
);

export const saleContractStatMovementMaterials = createSelector(
  selectMovementMaterialsStat,
  (saleContractStat) => saleContractStat
);
// manual mterial
export const manualMaterialsForm = createSelector(
  selectManualMaterialsForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritorii", ""),
      },
      skladId: {
        value: get(formData, "data.skladId", ""),
        label: get(formData, "data.imyaSklad", ""),
      },
    };
    const res = {
      ...formData,
      data: {
        formIntVls,
        spisanieSMDetailsList: get(formData, "data.spisanieSMDetailsList", []),
      },
    };
    return res;
  }
);

export const manualMaterialsFilter = createSelector(
  selectManualMaterialsFilter,
  (filter) => filter
);

export const manualMaterialsList = createSelector(
  selectManualMaterialsList,
  (manualMaterials) => manualMaterials
);

export const tempManualMaterialsList = createSelector(
  selectTempManualMaterialsList,
  (notFilledList) => notFilledList
);

export const countstatManualMaterials = createSelector(
  selectManualMaterialsStat,
  (statusMaterials) => statusMaterials
);
// semi product
export const loadingSemiProduct = createSelector(
  selectSemiProductDetails,
  (data) => data.loading
);
export const semiProductDetails = createSelector(
  selectSemiProductDetails,
  (data) =>
    !isEmpty(get(data, "data", []))
      ? get(data, "data", []).map((item: any) => ({
          value: get(item, "polufabrikat.id", null),
          label: get(item, "polufabrikat.polnoyeImyaMateriala", null),
          maxKol: get(item, "kol", 0),
          edIzmId: get(item, "polufabrikat.edIzmId", null),
          imyaEdizm: get(item, "polufabrikat.imyaEdIzm", ""),
        }))
      : []
);
export const semiProductForm = createSelector(
  selectSemiProductForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kommentariya: get(formData, "data.kommentariya", ""),
      kolPolufabrikat: get(formData, "data.kolPolufabrikat", ""),
      dogProdajaId: {
        value: get(formData, "data.dogProdajaId", ""),
        label: get(formData, "data.nomerDogProdaja", ""),
      },
      zakazNaryadId: {
        value: get(formData, "data.zakazNaryadId", ""),
        label: get(formData, "data.nomerZakazNaryad", ""),
      },
      polufabrikatId: {
        value: get(formData, "data.polufabrikatId", ""),
        label: get(formData, "data.imyaPolufabrikat", ""),
      },
      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritorii", ""),
      },
      skladPFId: {
        value: get(formData, "data.skladPFId", ""),
        label: get(formData, "data.imyaSkladPF", ""),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladSpisId: {
        value: get(formData, "data.skladSpisId", ""),
        label: get(formData, "data.imyaSkladSpis", ""),
      },
      tipSpisaniyaId: {
        value: get(formData, "data.tipSpisaniyaId", 1),
        label: get(formData, "data.tipSpisaniyaId", "по норме"),
      },
      tovarId: {
        value: get(formData, "data.tovarId", ""),
        label: get(formData, "data.imyaTovar", ""),
      },
      edIzmId: {
        value: get(formData, "data.edIzmId", ""),
        label: get(formData, "data.imyaEdIzm", ""),
      },
    };
    console.log(get(formData, "data.vipuskPFDetailsList", []), "spier");
    const res = {
      ...formData,
      data: {
        formIntVls,
        vipuskPFDetailsList: get(formData, "data.vipuskPFDetailsList", []),
        poFaktuDetailsList: get(formData, "data.poFaktuDetailsList", []),
      },
    };
    return res;
  }
);

export const semiProductFilter = createSelector(
  selectSemiProductFilter,
  (filter) => filter
);

export const semiProductList = createSelector(
  selectSemiProductList,
  (data) => data
);

export const saleTempsSemiProductList = createSelector(
  selectTempsSemiProductList,
  (notFilledCntrList) => notFilledCntrList
);

export const semiProductStat = createSelector(
  selectSemiProductStat,
  (saleContractStat) => saleContractStat
);
// release product
export const releaseProductForm = createSelector(
  selectReleaseProductForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kommentariya: get(formData, "data.kommentariya", ""),
      kolTovara: get(formData, "data.kolTovara", ""),
      vidTovaraId: {
        value: get(formData, "data.vidTovaraId", ""),
        label: get(formData, "data.imyaVidTovara", ""),
      },
      dogProdajaId: {
        value: get(formData, "data.dogProdajaId", ""),
        label: get(formData, "data.nomerDogProdaja", ""),
      },
      zakazNaryadId: {
        value: get(formData, "data.zakazNaryadId", ""),
        label: get(formData, "data.nomerZakazNaryad", ""),
      },
      tipSpisaniyaId: {
        value: get(formData, "data.tipSpisaniyaId", 1),
        label: get(formData, "data.imyaTipSpisaniya", ""),
      },
      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritorii", ""),
      },
      skladGPId: {
        value: get(formData, "data.skladGPId", ""),
        label: get(formData, "data.imyaSkladGP", ""),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladSpisId: {
        value: get(formData, "data.skladSpisId", ""),
        label: get(formData, "data.imyaSkladSpis", ""),
      },
      tovarId: {
        value: get(formData, "data.tovarId", ""),
        label: get(formData, "data.imyaTovar", ""),
      },
      edIzmId: {
        value: get(formData, "data.edIzmId", ""),
        label: get(formData, "data.imyaEdIzm", ""),
      },
    };
    const res = {
      ...formData,

      data: {
        formIntVls,
        vipuskGPDetails: get(formData, "data.vipuskGPDetails", []),
        detailsPoFaktu: get(formData, "data.detailsPoFaktu", []),
      },
    };
    return res;
  }
);

export const releaseProductFilter = createSelector(
  selectReleaseProductFilter,
  (filter) => filter
);

export const releaseProductList = createSelector(
  selectReleaseProductList,
  (salesCnts) => salesCnts
);

export const saleTempsReleaseProductList = createSelector(
  selectTempsReleaseProductList,
  (notFilledCntrList) => notFilledCntrList
);

export const releaseProductStat = createSelector(
  selectReleaseProductStat,
  (saleContractStat) => saleContractStat
);
// shipment product
export const shipmentProductForm = createSelector(
  selectShipmentProductForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kommentariya: get(formData, "data.kommentariya", ""),
      sposobDostavkaId: get(formData, "data.sposobDostavkaId", ""),
      createdAt: get(formData, "data.createdAt", ""),
      gosNomer: get(formData, "data.gosNomer", ""),
      marka: get(formData, "data.marka", null),
      dogProdajaDataDog: get(formData, "data.dogProdajaDataDog", null),
      zakazNaryadId: {
        value: get(formData, "data.zakazNaryadId", ""),
        label: get(formData, "data.nomerZakazNaryad", ""),
      },
      dogProdajaId: {
        value: get(formData, "data.dogProdajaId", ""),
        label: get(formData, "data.nomerDogProdaja", ""),
      },
      addresaDostavkiId: {
        value: get(formData, "data.addresaDostavkiId", ""),
        label: get(formData, "data.imyaAddresaDostavki", ""),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladGPId: {
        value: get(formData, "data.skladGPId", ""),
        label: get(formData, "data.imyaSklada", ""),
      },
      voditeliId: {
        value: get(formData, "data.voditeliId", ""),
        label: get(formData, "data.imyaVoditeli", ""),
      },

      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritorii", ""),
      },
      avtoId: {
        value: get(formData, "data.avtoId", ""),
        label: get(formData, "data.gosNomer", ""),
      },
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        otgruzkaGPDetails: get(formData, "data.otgruzkaGPDetails", []),
      },
    };

    return res;
  }
);

export const shipmentProductFilter = createSelector(
  selectShipmentProductFilter,
  (filter) => filter
);

export const shipmentProductList = createSelector(
  selectShipmentProductList,
  (salesCnts) => {
    return salesCnts;
  }
);

export const saleTempsshipmentProductList = createSelector(
  selectTempsShipmentProductList,
  (notFilledCntrList) => notFilledCntrList
);

export const shipmentProductStat = createSelector(
  selectShipmentProductStat,
  (saleContractStat) => saleContractStat
);

// return product before realizes
export const returnProductBefRealForm = createSelector(
  selectReturnProductBerRealForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      zakazNaryadId: {
        value: get(formData, "data.zakazNaryadId", ""),
        label: get(formData, "data.nomerZakazNaryad", ""),
      },
      docOsnovanieId: {
        value: get(formData, "data.docOsnovanieId", ""),
        label: get(formData, "data.nomerDocOsnovanie", ""),
      },
      dogProdajaId: {
        value: get(formData, "data.dogProdajaId", ""),
        label: get(formData, "data.nomerDogProdaja", ""),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", null),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladGPId: {
        value: get(formData, "data.skladGPId", ""),
        label: get(formData, "data.imyaSkladaGP", ""),
      },
      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritoriya", ""),
      },
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        details: get(formData, "data.details", []),
      },
    };

    return res;
  }
);

export const returnProductBefRealList = createSelector(
  selectReturnProductBerRealList,
  (returnProduct) => returnProduct
);

export const returnProductBefRealFilter = createSelector(
  selectReturnProductBerRealFilter,
  (filter) => filter
);

export const saleTempsReturnProductBefRralList = createSelector(
  selectTempsReturnProductBerRealList,
  (notFilledCntrList) => notFilledCntrList
);

export const returnProductBefRealStat = createSelector(
  selectReturnProductBerRealStat,
  (saleContractStat) => saleContractStat
);

// sales Product
export const salesProductForm = createSelector(
  selectSalesProductsForm,
  (formData) => {
    console.log(formData, "data");
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kommentariya: get(formData, "data.kommentariya", ""),
      stavkaNDSId: get(formData, "data.stavkaNDSId", ""),
      zakazNaryadId: {
        value: get(formData, "data.zakazNaryadId", ""),
        label: get(formData, "data.nomerZakazNaryad", ""),
      },
      dogProdajaId: {
        value: get(formData, "data.dogProdajaId", ""),
        label: get(formData, "data.nomerDogProdaja", ""),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladGPId: {
        value: get(formData, "data.skladGPId", ""),
        label: get(formData, "data.imyaSkladaGP", ""),
      },
      sposobOplatiId: {
        value: get(formData, "data.sposobOplatiId", ""),
        label: get(formData, "data.imyaSposobOplati", ""),
      },

      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritoriya", ""),
      },
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        realizatsiyaGPDetails: get(formData, "data.realizatsiyaGPDetails", []),
      },
    };

    return res;
  }
);

export const salesProductFilter = createSelector(
  selectSalesProductsFilter,
  (filter) => filter
);

export const salesProductList = createSelector(
  selectSalesProductsList,
  (salesCnts) => salesCnts
);

export const saleTempsSalesProductList = createSelector(
  selectTempsSalesProductsList,
  (notFilledCntrList) => notFilledCntrList
);

export const salesProductStat = createSelector(
  selectSalesProductsStat,
  (saleContractStat) => saleContractStat
);
// return product
export const returnProductForm = createSelector(
  selectReturnProductForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      zakazNaryadId: {
        value: get(formData, "data.zakazNaryadId", ""),
        label: get(formData, "data.nomerZakazNaryad", ""),
      },
      dogProdajaId: {
        value: get(formData, "data.dogProdajaId", ""),
        label: get(formData, "data.nomerDogProdaja", ""),
      },
      docOsnovanieId: {
        value: get(formData, "data.docOsnovanieId", ""),
        label: get(formData, "data.nomerDocOsnovanie"),
      },
      kontragentId: {
        value: get(formData, "data.kontragentId", ""),
        label: get(formData, "data.imyaKontragent", ""),
      },
      skladGPId: {
        value: get(formData, "data.skladGPId", ""),
        label: get(formData, "data.imyaSkladaGP", ""),
      },
      sposobOplatiId: {
        value: get(formData, "data.sposobOplatiId", ""),
        label: get(formData, "data.imyaSposobOplati", ""),
      },
      stavkaNDSId: {
        value: get(formData, "data.stavkaNDSId", ""),
        label: get(formData, "data.stavkaNDSImya", ""),
      },
      territoriyaId: {
        value: get(formData, "data.territoriyaId", ""),
        label: get(formData, "data.imyaTerritoriya", ""),
      },
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        details: get(formData, "data.details", []),
      },
    };

    return res;
  }
);

export const returnProductFilter = createSelector(
  selectReturnProductFilter,
  (filter) => filter
);

export const returnProductList = createSelector(
  selectReturnProductList,
  (salesCnts) => salesCnts
);

export const saleTempsReturnProductList = createSelector(
  selectTempsReturnProductList,
  (notFilledCntrList) => notFilledCntrList
);

export const returnProductStat = createSelector(
  selectReturnProductStat,
  (saleContractStat) => saleContractStat
);
//  other const
export const otherCostFilter = createSelector(
  selectOtherCostFilter,
  (filter) => filter
);
export const otherProdCosts = createSelector(
  selectOtherCosts,
  (prodOtherCosts) => prodOtherCosts
);

export const otherCostsTemps = createSelector(
  selectOtherCostsTemps,
  (prodOtherCostsTemp) => prodOtherCostsTemp
);

export const otherCostDetails = createSelector(
  selectOtherCostsDetails,
  (details) => details
);

export const otherCostStatic = createSelector(
  selectOtherCostsStatistic,
  (statistic) => statistic
);
// register parsh cash
export const registerParishCashForm = createSelector(
  selectRegisterParishCashForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kommentariya: get(formData, "data.kommentariya", ""),
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        prixodBDDetailsList: get(formData, "data.prixodBDDetailsList", []),
      },
    };

    return res;
  }
);
export const registerParishCashFilter = createSelector(
  selectRegisterParishCashFilter,
  (filter) => filter
);
export const registerParishCashStat = createSelector(
  selectRegisterParishCashStat,
  (statistics) => statistics
);
export const registerParishCash = createSelector(
  selectRegisterParishCash,
  (cashList) => cashList
);

export const registerParishCashTemps = createSelector(
  selectRegisterParishCashTemps,
  (cashListTemp) => cashListTemp
);

// Приход наличных денег
export const arrivedCashFilter = createSelector(
  selectArrivedCashFilter,
  (filter) => filter
);
export const arrivedCash = createSelector(
  selectArrivedCash,
  (cashes) => cashes
);
export const arrivedCashTemps = createSelector(
  selectArrivedCashTemps,
  (cashesTemp) => cashesTemp
);
export const arrivedCashStatistic = createSelector(
  selectArrivedStatistic,
  (statistic) => statistic
);
export const remainderData = createSelector(
  selectArrivedRemainder,
  (remainder) => {
    let summaUZS = [];
    let summaUSD = [];
    let allSumm = [];
    const loading = get(remainder, "loading", false);
    const countUSD = {
      label: `${textFormatter(get(remainder, "data.summaInUSD.itogo", 0))} USD`,
      value: 0,
    };
    const countUZS = {
      label: `${textFormatter(
        get(remainder, "data.summaInUZS.itogo", 0)
      )}  UZS`,
      value: 0,
    };
    const allCount = {
      label: `${textFormatter(get(remainder, "data.totalSumma.itogo", 0))} UZS`,
      value: 0,
    };
    summaUSD = get(remainder, "data.summaInUSD.kassaSummaDTOS", []).map(
      (item: { id: number; imya: string; summa: number }) => ({
        label: `${get(item, "imya", "")}: ${textFormatter(
          get(item, "summa", 0)
        )} USD`,
        value: get(item, "id", null),
      })
    );
    summaUZS = get(remainder, "data.summaInUZS.kassaSummaDTOS", []).map(
      (item: { id: number; imya: string; summa: number }) => ({
        label: `${get(item, "imya", "")}: ${textFormatter(
          get(item, "summa", 0)
        )} UZS`,
        value: get(item, "id", null),
      })
    );
    allSumm = get(remainder, "data.totalSumma.kassaSummaDTOS", []).map(
      (item: { id: number; imya: string; summa: number }) => ({
        label: `${get(item, "imya", "")}: ${textFormatter(
          get(item, "summa", 0)
        )} UZS`,
        value: get(item, "id", null),
      })
    );
    return {
      // options
      summaUSD,
      summaUZS,
      allSumm,
      // counts
      countUSD,
      countUZS,
      allCount,
      loading,
    };
  }
);
// Расход наличный денег
export const costsInCashFilter = createSelector(
  selectCostsInCashFilter,
  (filter) => filter
);
export const costsInCash = createSelector(
  selectCostsInCash,
  (cashes) => cashes
);
export const costsInCashTemps = createSelector(
  selectCostsInCashTemps,
  (cashesTemp) => cashesTemp
);
export const costsInCashStat = createSelector(
  selectCostsInCashStat,
  (statistic) => statistic
);

// Регистрация расхода безналичных денег
export const regesBezNalichFilter = createSelector(
  selectRegesBezNalichFilter,
  (filter) => filter
);
export const regesBezNalichCash = createSelector(
  selectRegesBezNalichCash,
  (registerBezNalich) => registerBezNalich
);
export const regesBezNalichTemps = createSelector(
  selectRegesBezNalichTemps,
  (registerBezNalichTemp) => registerBezNalichTemp
);
export const registerBezNalichStat = createSelector(
  selectRegisterBezNalichStat,
  (statistics) => statistics
);
export const regisBezNalichDetails = createSelector(
  selectRegisterBezNalichCashDetails,
  (details) => details
);
// Зачет аванса покупателей
export const offsetAdvanceFilter = createSelector(
  selectOffsetAdvanceFilter,
  (filter) => filter
);
export const offsetAdvanceList = createSelector(
  selectOffsetAdvanceList,
  (data) => data
);
export const offsetAdvanceStat = createSelector(
  selectOffsetAdvanceStat,
  (statistic) => statistic
);
// Расчет себестоимости готовой продукции
export const calculateProductFilter = createSelector(
  selectCalculateProductFilter,
  (filter) => filter
);
export const calculateProductList = createSelector(
  selectCalculateProductList,
  (data) => data
);
export const calculateProductStat = createSelector(
  selectCalculateProductStat,
  (statistic) => statistic
);
// Закрытие месяца
export const closeMonthFilter = createSelector(
  selectCloseMonthFilter,
  (filter) => filter
);
export const closeMonthList = createSelector(
  selectCloseMonthList,
  (data) => data
);
export const closeMonthStat = createSelector(
  selectCloseMonthStat,
  (statistic) => statistic
);
// Ручные проводки
export const manualPostingFilter = createSelector(
  selectManualPostingFilter,
  (filter) => filter
);
export const manualPostingDetails = createSelector(
  selectManualPostingDetails,
  (details) => details
);
export const manualPostingList = createSelector(
  selectManualPostingList,
  (data) => data
);
export const manualPostingStat = createSelector(
  selectManualPostingStat,
  (statistic) => statistic
);

// Приобретение бетона у водителей
export const rtrnConcreteDriverFilter = createSelector(
  selectRtrnConcreteDriverFilter,
  (filter) => filter
);
export const rtrnConcreteDriver = createSelector(
  selectRtrnConcreteDriver,
  (returnedConcretes) => returnedConcretes
);
export const rtrnConcreteDriverTemps = createSelector(
  selectRtrnConcreteDriverTemps,
  (returnedConcretesTemp) => returnedConcretesTemp
);
export const rtrnConcreteDriverStat = createSelector(
  selectRtrnConcreteDriverStat,
  (statistic) => statistic
);
//  brak
export const substandardProductForm = createSelector(
  selectSubstandardProductForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      kommentariya: get(formData, "data.kommentariya", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      sebestTovar: get(formData, "data.sebestTovar", null),
      totalAmount: get(formData, "data.totalAmount", 0),
      kolTovara: get(formData, "data.kolTovara", null),
      territoriyaId: {
        label: get(formData, "data.imyaTerritorii", ""),
        value: get(formData, "data.territoriyaId", ""),
      },
      tovarId: {
        label: get(formData, "data.imyaTovara", ""),
        value: get(formData, "data.tovarId", ""),
      },
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        spisanieBrakDetails: get(formData, "data.spisanieBrakDetails", []),
      },
    };

    return res;
  }
);

export const substandardProductFilter = createSelector(
  selectSubstandardProductFilter,
  (filter) => filter
);
export const substandardProductStatistics = createSelector(
  selectSubstandardProductStatistics,
  (data) => data
);
export const substandardProductList = createSelector(
  selectSubstandardProductList,
  (data) => data
);

// change product
export const changeProductFilter = createSelector(
  selectChangeProductFilter,
  (filter) => filter
);
export const changeProductList = createSelector(
  selectChangeProductList,
  (data) => data
);

export const changeProductForm = createSelector(
  selectChangeProductForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kontragent1Id: {
        label: get(formData, "data.imyaKontragent1", ""),
        value: get(formData, "data.kontragent1Id", ""),
      },
      zakazNaryad1Id: {
        label: get(formData, "data.nomerZakazNaryad1", ""),
        value: get(formData, "data.zakazNaryad1Id", ""),
      },
      kontragent2Id: {
        label: get(formData, "data.imyaKontragent2", ""),
        value: get(formData, "data.kontragent2Id", ""),
      },
      zakazNaryad2Id: {
        label: get(formData, "data.nomerZakazNaryad2", ""),
        value: get(formData, "data.zakazNaryad2Id", ""),
      },
      dogProdaja1Id: {
        label: get(formData, "data.nomerDogProdaja1", ""),
        value: get(formData, "data.dogProdaja1Id"),
      },
      dogProdaja2Id: {
        label: get(formData, "data.nomerDogProdaja2", ""),
        value: get(formData, "data.dogProdaja2Id"),
      },
      territoriya1Id: {
        label: get(formData, "data.imyaTerritoriya1", ""),
        value: get(formData, "data.territoriya1Id"),
      },
      territoriya2Id: {
        label: get(formData, "data.imyaTerritoriya2", ""),
        value: get(formData, "data.territoriya2Id"),
      },
      skladGP1Id: {
        label: get(formData, "data.imyaSkladaGP1", ""),
        value: get(formData, "data.skladGP1Id"),
      },
      skladGP2Id: {
        label: get(formData, "data.imyaSkladaGP1", ""),
        value: get(formData, "data.skladGP2Id"),
      },
      kommentariya: get(formData, "data.kommentariya", ""),
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        details: get(formData, "data.details", []),
      },
    };

    return res;
  }
);
export const changeTempProduct = createSelector(
  selectTempChangeProduct,
  (notFilledList) => notFilledList
);
export const changeProductStat = createSelector(
  selectChangeProductStat,
  (statistic) => statistic
);
// change semi product
export const changeSemiProductFilter = createSelector(
  selectChangeSemiProductFilter,
  (filter) => filter
);
export const changeSemiProductList = createSelector(
  selectChangeSemiProductList,
  (data) => data
);

export const changeSemiProductForm = createSelector(
  selectChangeSemiProductForm,
  (formData) => {
    const formIntVls = {
      nomerDoc: get(formData, "data.nomerDoc", ""),
      dataDoc: get(formData, "data.dataDoc", dateFormat),
      kontragent1Id: {
        label: get(formData, "data.imyaKontragent1", ""),
        value: get(formData, "data.kontragent1Id", ""),
      },
      zakazNaryad1Id: {
        label: get(formData, "data.nomerZakazNaryad1", ""),
        value: get(formData, "data.zakazNaryad1Id", ""),
      },
      kontragent2Id: {
        label: get(formData, "data.imyaKontragent2", ""),
        value: get(formData, "data.kontragent2Id", ""),
      },
      zakazNaryad2Id: {
        label: get(formData, "data.nomerZakazNaryad2", ""),
        value: get(formData, "data.zakazNaryad2Id", ""),
      },
      dogProdaja1Id: {
        label: get(formData, "data.nomerDogProdaja1", ""),
        value: get(formData, "data.dogProdaja1Id"),
      },
      dogProdaja2Id: {
        label: get(formData, "data.nomerDogProdaja2", ""),
        value: get(formData, "data.dogProdaja2Id"),
      },
      territoriya1Id: {
        label: get(formData, "data.imyaTerritoriya1", ""),
        value: get(formData, "data.territoriya1Id"),
      },
      territoriya2Id: {
        label: get(formData, "data.imyaTerritoriya2", ""),
        value: get(formData, "data.territoriya2Id"),
      },
      skladPF1Id: {
        label: get(formData, "data.imyaSkladaPF1", ""),
        value: get(formData, "data.skladPF1Id"),
      },
      skladPF2Id: {
        label: get(formData, "data.imyaSkladaPF1", ""),
        value: get(formData, "data.skladPF2Id"),
      },
      kommentariya: get(formData, "data.kommentariya", ""),
    };

    const res = {
      ...formData,
      data: {
        formIntVls,
        details: get(formData, "data.details", []),
      },
    };

    return res;
  }
);
export const changeTempSemiProduct = createSelector(
  selectTempChangeSemiProduct,
  (notFilledList) => notFilledList
);
export const changeSemiProductStat = createSelector(
  selectChangeSemiProductStat,
  (statistic) => statistic
);
//  Перемещение наличных денег
export const movingCashFilter = createSelector(
  selectMovingCashFilter,
  (filter) => filter
);
export const movingCash = createSelector(selectMovingCash, (cashes) => cashes);
export const movingCashTemps = createSelector(
  selectMovingCashTemps,
  (cashesTemp) => cashesTemp
);
export const movingCashStatistic = createSelector(
  selectMovingStatistic,
  (statistic) => statistic
);
