import styled from "styled-components";

const ProfileContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
  
`;

const Avatar = styled.img`
    height: 32px;
    width: 32px;
    border-radius: 32px;
    border: 1px solid #e8e8e8;
`;

export { ProfileContainer, Avatar };
