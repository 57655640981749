import styled from "styled-components";

const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

const NameOrg = styled.span`
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1c1c1c;
`;

const Image = styled.img`
    width: 100px;
    object-fit: contain;
`;

export { LogoContainer, NameOrg, Image };
