import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";

const initialState: any = {
  statistic: {
    loading: false,
    data: {},
    pagination: {},
    error: "",
  },
  filter: {
    kontragentId: null,
    fromDate: null,
    prixodKassaId: null,
    rasxodKassaId: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  cashes: {
    loading: false,
    data: [],
    error: "",
  },
  cashesTemp: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchMovingCash = createAsyncThunk(
  "movingCash/fetchMovingCash",

  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.GetMovingCashes(params);
      const respond = await request.data;
      const { data, pagination } = await respond;
      return { data, pagination };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMovingCashTemp = createAsyncThunk(
  "movingCash/fetchMovingCashTemp",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetMovingCashesTemp();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMovingCashStat = createAsyncThunk(
  "movingCash/fetchMovingCashStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetMovingCashesStat();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const movingCash = createSlice({
  name: "movingCash",
  initialState,
  reducers: {
    handleFilterMovingCash: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearMovingCashes: () => initialState,
    clearFilterMovingCash: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMovingCash.pending, (state) => {
      state.cashes = {
        loading: true,
      };
    });
    builder.addCase(fetchMovingCash.fulfilled, (state, actions) => {
      state.cashes = {
        loading: false,
        data: get(actions, "payload.data", {}),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchMovingCash.rejected, (state, actions) => {
      state.cashes = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });

    builder.addCase(fetchMovingCashTemp.pending, (state) => {
      state.cashesTemp = {
        loading: true,
      };
    });
    builder.addCase(fetchMovingCashTemp.fulfilled, (state, actions) => {
      state.cashesTemp = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchMovingCashTemp.rejected, (state, actions) => {
      state.cashesTemp = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    // static
    builder.addCase(fetchMovingCashStat.pending, (state) => {
      state.statistic = {
        loading: true,
      };
    });
    builder.addCase(fetchMovingCashStat.fulfilled, (state, actions) => {
      state.statistic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchMovingCashStat.rejected, (state, actions) => {
      state.statistic = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
  },
});

export const {
  handleFilterMovingCash,
  clearMovingCashes,
  clearFilterMovingCash,
} = movingCash.actions;
export default movingCash.reducer;
