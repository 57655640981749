import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";

const initialState: any = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    period: "DAILY",
    territoriyaId: null,
    detalno: true,
  },
};

export const fetchReportSemiProduct = createAsyncThunk(
  "ReportSemiProduct/fetchReportSemiProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchReportSemiProductsTable(params);
      const respond = await request.data;
      return respond;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const reportSemiProductSlices = createSlice({
  name: "reportSemiProduct",
  initialState,
  reducers: {
    filterReportSemiProduct: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = {
        ...state.filter,
        [name]: value,
      };
    },
    clearReportSemiProduct: () => initialState,
    changeReportSemiProduct: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchReportSemiProduct.pending, (state: any) => {
      state.data = {
        loading: true,
        dataList: null,
        error: "",
      };
    });
    builder.addCase(fetchReportSemiProduct.fulfilled, (state: any, action: any) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchReportSemiProduct.rejected, (state: any, payload: any) => {
      state.data = {
        loading: false,
        dataList: null,
        error: payload.error.message,
      };
    });
  },
});

export const { filterReportSemiProduct, clearReportSemiProduct, changeReportSemiProduct } = reportSemiProductSlices.actions;
export default reportSemiProductSlices.reducer;
