import { customStyles, MenuButton, SelectWrapper } from "./style";
import { memo, Fragment } from "react";
import Select, { components } from "react-select";
import { get, isEqual } from "lodash";
import { ReactSVG } from "react-svg";
import arrowIcon from "assets/images/icons/arrow-down.svg";
import BaseInput from "components/BaseInput/BaseInput";

import Creatable from 'react-select/creatable';

const BaseSelect = ({
    options = [],
    defaultValue,
    value = "",
    isSearchable = false,
    onBlur,
    isMulti = false,
    isDisabled = false,
    placeholder = "",
    isLoading = false,
    isClearable = false,
    handleChange = (val: any) => val,
    autoFocus = false,
    onClick = () => { },
    sm,
    isCustomMenu,
    menuButtonTitle,
    handleAddNewOption = () => { },
    menuPortalTarget = null,
    handleValueLabel,
    readOnly,
    onChange,
    placement = "bottom",
    menuPosition = "absolute",
    ...rest
}: any) => {
    const handleChangeValue = (e: any) => {
        if (isMulti) {
            handleChange(e.map((item: any) => item.value));
        } else {
            handleChange(get(e, "value", null));
        }
        if (handleValueLabel) {
            handleValueLabel(e);
        }
    };

    const restProps = {
        onPressButton: handleAddNewOption,
        isCustomMenu,
        menuButtonTitle,
    };
    const _components: any = {
        DropdownIndicator: null,
        IndicatorSeparator: () => null,
    };
    return (
        <SelectWrapper {...rest}>
            <Creatable
                defaultValue={defaultValue}
                onMenuOpen={onClick}
                menuPlacement={placement}
                autoFocus={autoFocus}
                key={`unique_key__${value}`}
                options={options}
                styles={customStyles({ sm, isDisabled, menuPosition })}
                placeholder={""}
                onInputChange={onChange}
                onChange={(value) => {
                    handleChangeValue(value);
                }}
                value={
                    isMulti && value && value.toString.length > 0
                        ? options.filter((option: any) =>
                            isEqual(option.value, value)
                        )
                        : options.find((item: any) => item.value === value)
                }
                isSearchable={isSearchable}
                formatCreateLabel={userInput => `Создать:  ${userInput}`}
                blurInputOnSelect={true}
                onBlur={onBlur}
                isMulti={isMulti}
                isDisabled={isDisabled}
                menuPortalTarget={menuPortalTarget}
                isLoading={isLoading}
                isClearable
                components={_components}
                {...restProps}
            />

        </SelectWrapper>
    );
};

export default memo(BaseSelect);
