import request from "services/api";
import {
  AddOrderRegister,
  NumberDocType,
  ReceivedRowMaterials,
  RegisterOrderRegisterType,
  RegisterPurchases,
  RegisterPurchasesContracts,
  RegisterSaleContracts,
} from "types/documentsType";

class DocumentServices {
  static FetchProductPrice = (params: {
    tovarId: string | number;
    skidka?: number | string | null;
    okruglineyaMetod?: string | null;
    kol?: number | string | null;
    nds?: number | string | null;
    dataDoc?: any;
  }) => {
    return request.get(`/api/spravochniki/sena/tovar`, { params: params });
  };

  static FetchMaterialPrice = (materialId: string | number) => {
    return request.get(`/api/spravochniki/vid-material/${materialId}`);
  };

  static AddSalesContract = (params: RegisterSaleContracts) => {
    return request.post("/api/dokumenti/dog-prodaja", params);
  };
  static UpdateSalesContract = (params: RegisterSaleContracts) => {
    return request.put("/api/dokumenti/dog-prodaja", params);
  };
  static AddNotFullFilledSalesContract = (params: RegisterSaleContracts) => {
    return request.post("/api/dokumenti/dog-prodaja/temp", params);
  };

  static FetchSalesContracts = (params: any) => {
    return request.get("/api/dokumenti/dog-prodaja", {
      params: params,
      cancelToken: params.signal,
    });
  };

  static FetchSalesNotFilledContracts = () => {
    return request.get("/api/dokumenti/dog-prodaja/temp");
  };

  static FetchSalesContractById = (id: string) => {
    return request.get(`/api/dokumenti/dog-prodaja/${id}`);
  };

  static FetchSalesContractByTempId = (id: string) => {
    return request.get(`/api/dokumenti/dog-prodaja/temp/${id}`);
  };

  static RemoveSalesTempContractById = (id: string | null) => {
    return request.delete(`/api/dokumenti/dog-prodaja/temp/${id}`);
  };

  static DeleteSalesContractById = (id: string | null) => {
    return request.delete(`/api/dokumenti/dog-prodaja/${id}`);
  };
  static FetchSaleContractsStatistics = () => {
    return request.get(`/api/dokumenti/dog-prodaja/statistics`);
  };
  static FetchDetailsById = ({ id, znId }: any) => {
    if (znId) {
      return request.get(
        `/api/dokumenti/dog-prodaja/details/${id}?znId=${znId}`
      );
    } else {
      return request.get(`/api/dokumenti/dog-prodaja/details/${id}`);
    }
  };
  static FetchContractNumber = (params: any) => {
    return request.get("/api/dokumenti/dog-prodaja/nomer", { params });
  };
  // undo
  static DeleteRecordsSalesContractById = (id: string | null) => {
    return request.delete(`/api/dokumenti/dog-prodaja/records/${id}`);
  };
  static UndoRecordsSalesContractById = (id: string | null) => {
    return request.put(`/api/dokumenti/dog-prodaja/records/${id}`);
  };
  // zakaz-naryada
  static FetchOrderRegister = (params: any) => {
    return request.get("/api/dokumenti/zakaz-naryad", {
      params: params,
      cancelToken: params.cancelToken,
    });
  };

  static FetchOrderRegisterStat = () => {
    return request.get("/api/dokumenti/zakaz-naryad/statistics");
  };

  static FetchOrderRegisterByTempId = (id: string) => {
    return request.get(`/api/dokumenti/zakaz-naryad/temp/${id}`);
  };

  static AddOrderRegister = (params: RegisterOrderRegisterType) => {
    return request.post("/api/dokumenti/zakaz-naryad", params);
  };

  static AddNotFilledOrderRegister = (params: RegisterOrderRegisterType) => {
    return request.post("/api/dokumenti/zakaz-naryad/temp", params);
  };

  static UpdateOrderRegister = (params: RegisterOrderRegisterType) => {
    return request.put("/api/dokumenti/zakaz-naryad", params);
  };
  static FetchTempsOrderRegisterById = (id: string) => {
    return request.get(`/api/dokumenti/zakaz-naryad/temp/${id}`);
  };
  static FetchTempsOrderRegister = () => {
    return request.get(`/api/dokumenti/zakaz-naryad/temp`);
  };
  static RemoveOrderRegisterById = (id: string | null | number) => {
    return request.delete(`/api/dokumenti/zakaz-naryad/temp/${id}`);
  };

  static DeleteOrderRegisterById = (id: string | null | number) => {
    return request.delete(`/api/dokumenti/zakaz-naryad/${id}`);
  };

  static FetchOrderRegisterById = (id: string) => {
    return request.get(`/api/dokumenti/zakaz-naryad/${id}`);
  };

  static GetAdvance = (params: {
    kontragentId: number | string | null;
    dogProdajaId: number | string | null;
  }) => {
    return request.get("/api/dokumenti/zakaz-naryad/avans", {
      params: params,
    });
  };

  static GetOrderNumber = (params: any) => {
    return request.get("/api/dokumenti/zakaz-naryad/nomer", { params });
  };
  static CompletedOrder = (id: string | null) => {
    return request.put(`/api/dokumenti/zakaz-naryad/close-doc/${id}`);
  };
  static GetKontragentDebt = (params: any) => {
    return request.get("/api/dokumenti/zakaz-naryad/kontragent-debt", {
      params: params,
    });
  };
  static FetchPriceRegisterOrderId = (id: string | number | null) => {
    return request.get(`/api/dokumenti/zakaz-naryad/kontragent-debt/${id}`);
  };
  // dogovor-pakupku
  static FetchPurchases = (params: any) => {
    return request.get("/api/dokumenti/dog-pokupki", {
      params: params,
      cancelToken: params.cancelToken,
    });
  };

  static AddPurchases = (params: RegisterPurchasesContracts) => {
    return request.post("/api/dokumenti/dog-pokupki", params);
  };

  static AddNotFullFilledPurchases = (params: RegisterPurchasesContracts) => {
    return request.post("/api/dokumenti/dog-pokupki/temp", params);
  };
  static UpdateRegisterPurchases = (params: RegisterPurchasesContracts) => {
    return request.put("/api/dokumenti/dog-pokupki", params);
  };
  static FetchNotFilledPurchases = (params: any) => {
    return request.get("/api/dokumenti/dog-pokupki/temp", {
      params: params,
    });
  };
  static FetchPurchasesById = (id: string) => {
    return request.get(`/api/dokumenti/dog-pokupki/${id}`);
  };
  static FetchNotFilledPurchaseById = (id: string) => {
    return request.get(`/api/dokumenti/dog-pokupki/temp/${id}`);
  };
  static RemovePurchaseTempContractById = (id: string | null) => {
    return request.delete(`/api/dokumenti/dog-pokupki/temp/${id}`);
  };
  static DeletePurchaseContractById = (id: string | null) => {
    return request.delete(`/api/dokumenti/dog-pokupki/${id}`);
  };
  static FetchPurchaseContractsStatistics = () => {
    return request.get(`/api/dokumenti/dog-pokupki/statistics`);
  };
  static GetPurchaseNumber = () => {
    return request.get("/api/dokumenti/dog-pokupki/nomer");
  };

  static FetchMaterialPurchaseById = (id: string) => {
    return request.get(`/api/dokumenti/dog-pokupki/materials/${id}`);
  };
  //  receiving marerials
  static FetchReceivedMaterials = (params: any) => {
    return request.get("/api/dokumenti/prixod-sm", { params: params });
  };

  static FetchNotFilledReceivedMaterials = (params: any) => {
    return request.get("/api/dokumenti/prixod-sm/temp", { params: params });
  };

  static AddReceivedMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/prixod-sm", params);
  };

  static AddNotFilledReceivedMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/prixod-sm/temp", params);
  };

  static FetchNotFilledReceivedMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/prixod-sm/temp/${id}`);
  };

  static FetchReceivedMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/prixod-sm/${id}`);
  };

  static FetchReceivedMaterialsStatics = () => {
    return request.get("/api/dokumenti/prixod-sm/statistics");
  };
  static RemoveTempsReceivedMaterials = (id: any) => {
    return request.delete(`/api/dokumenti/prixod-sm/temp/${id}`);
  };
  static DeleteRemoveReceivedMaterials = (id: any) => {
    return request.delete(`/api/dokumenti/prixod-sm/${id}`);
  };
  static GetReceivedMaterialsNumber = () => {
    return request.get("/api/dokumenti/prixod-sm/nomer");
  };
  static DeleteRecordsRemoveReceivedMaterials = (id: any) => {
    return request.delete(`/api/dokumenti/prixod-sm/records/${id}`);
  };
  static UndoRecordsRemoveReceivedMaterials = (id: any) => {
    return request.put(`/api/dokumenti/prixod-sm/records/${id}`);
  };
  // return materials
  static FetchReturnMaterials = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-sm", { params: params });
  };

  static FetchNotFilledReturnMaterials = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-sm/temp", {
      params: params,
    });
  };

  static AddReturnMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/vozvrat-sm", params);
  };

  static AddNotFilledReturnMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/vozvrat-sm/temp", params);
  };

  static FetchNotFilledReturnMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/vozvrat-sm/temp/${id}`);
  };

  static FetchReturnMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/vozvrat-sm/${id}`);
  };

  static FetchReturnMaterialsStatics = () => {
    return request.get("/api/dokumenti/vozvrat-sm/statistics");
  };
  static RemoveTempsReturnMaterials = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-sm/temp/${id}`);
  };

  static GetReturnMaterialsNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/vozvrat-sm/nomer", { params });
  };

  static FetchReturnMaterialsKolMaterial = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-sm/material-kol", {
      params: params,
      cancelToken: params.cancel,
    });
  };

  static FetchReturnMaterialsDetails = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-sm/details", {
      params,
      cancelToken: params.cancel,
    });
  };
  static DeleteReturnMaterials = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-sm/${id}`);
  };

  static DeleteRecordsReturnMaterials = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-sm/records/${id}`);
  };
  static UndoRecordsReturnMaterials = (id: string | null) => {
    return request.put(`/api/dokumenti/vozvrat-sm/records/${id}`);
  };

  // movement material
  static FetchMovementMaterials = (params: any) => {
    return request.get("/api/dokumenti/peremesh-sm", { params: params });
  };

  static FetchNotFilledMovementMaterials = (params: any) => {
    return request.get("/api/dokumenti/peremesh-sm/temp", {
      params: params,
    });
  };

  static AddMovementMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/peremesh-sm", params);
  };

  static AddNotFilledMovementMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/peremesh-sm/temp", params);
  };

  static FetchNotFilledMovementMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/peremesh-sm/temp/${id}`);
  };

  static FetchMovementMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/peremesh-sm/${id}`);
  };

  static FetchMovementMaterialsStatics = () => {
    return request.get("/api/dokumenti/peremesh-sm/statistics");
  };
  static RemoveTempsMovementMaterials = (id: string | null) => {
    return request.delete(`/api/dokumenti/peremesh-sm/temp/${id}`);
  };
  static DelelteMovementMaterials = (id: string | null) => {
    return request.delete(`/api/dokumenti/peremesh-sm/${id}`);
  };
  static GetMovementMaterialsNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/peremesh-sm/nomer", { params });
  };

  static FetchMovementMaterialPrices = (params: { skladId: number | null }) => {
    return request.get("/api/dokumenti/peremesh-sm/details", { params });
  };
  static DelelteRecordsMovementMaterials = (id: string | null) => {
    return request.delete(`/api/dokumenti/peremesh-sm/records/${id}`);
  };
  static UndoRecordsMovementMaterials = (id: string | null) => {
    return request.put(`/api/dokumenti/peremesh-sm/records/${id}`);
  };
  // manual material
  static FetchManualMaterials = (params: any) => {
    return request.get("/api/dokumenti/spisanie-sm", { params: params });
  };

  static FetchNotFilledManualMaterials = (params: any) => {
    return request.get("/api/dokumenti/spisanie-sm/temp", {
      params: params,
    });
  };

  static AddManualMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/spisanie-sm", params);
  };

  static AddNotFilledManualMaterials = (params: ReceivedRowMaterials) => {
    return request.post("/api/dokumenti/spisanie-sm/temp", params);
  };

  static FetchNotFilledManualMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/spisanie-sm/temp/${id}`);
  };

  static FetchManualMaterialsById = (id: string) => {
    return request.get(`/api/dokumenti/spisanie-sm/${id}`);
  };

  static FetchManualMaterialsStatics = () => {
    return request.get("/api/dokumenti/spisanie-sm/statistics");
  };
  static RemoveTempsManualMaterials = (id: any) => {
    return request.delete(`/api/dokumenti/spisanie-sm/temp/${id}`);
  };
  static DeleteManualMaterials = (id: any) => {
    return request.delete(`/api/dokumenti/spisanie-sm/${id}`);
  };
  static GetManualMaterialsNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/spisanie-sm/nomer", { params });
  };

  static FetchManualMaterialPrices = (params: { skladId: number | null }) => {
    return request.get("/api/dokumenti/spisanie-sm/material-kol", {
      params,
    });
  };
  static DeleteRecordsManualMaterials = (id: any) => {
    return request.delete(`/api/dokumenti/spisanie-sm/records/${id}`);
  };
  static UndoManualMaterials = (id: any) => {
    return request.put(`/api/dokumenti/spisanie-sm/records/${id}`);
  };
  // output
  static FetchSemiProduct = (params: any) => {
    return request.get("/api/dokumenti/vipusk-pf", { params: params });
  };

  static FetchNotFilledSemiPutProduct = (params: any) => {
    return request.get("/api/dokumenti/vipusk-pf/temp", { params: params });
  };

  static AddSemiProduct = (params: any) => {
    return request.post("/api/dokumenti/vipusk-pf", params);
  };

  static AddNotFilledSemiProduct = (params: any) => {
    return request.post("/api/dokumenti/vipusk-pf/temp", params);
  };

  static FetchNotFilledSemiProductById = (id: string) => {
    return request.get(`/api/dokumenti/vipusk-pf/temp/${id}`);
  };

  static FetchSemiProductById = (id: string) => {
    return request.get(`/api/dokumenti/vipusk-pf/${id}`);
  };

  static FetchSemiProductStatics = () => {
    return request.get("/api/dokumenti/vipusk-pf/statistics");
  };

  static RemoveTempsSemiProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/vipusk-pf/temp/${id}`);
  };
  static DeleteSemiProductData = (id: string | null) => {
    return request.delete(`/api/dokumenti/vipusk-pf/${id}`);
  };
  static FetchSemiDetails = (params: {
    zakazNaryadId: string | null;
    kol: number | null;
    tovarId: number | null;
  }) => {
    return request.get("/api/dokumenti/vipusk-pf/details", { params });
  };

  static GetSemiNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/vipusk-pf/nomer", { params });
  };

  static GetSemiDetails = (params: any) => {
    return request.get("/api/dokumenti/vipusk-pf/kol-polufabrikat", {
      params: params,
      cancelToken: params.cancel,
    });
  };

  static FetchSemiProductDetails = (params: any) => {
    return request.get(`/api/dokumenti/vipusk-pf/details`, { params });
  };
  static DeleteRecordSemiProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/vipusk-pf/records/${id}`);
  };
  static UndoRecordsSemiProduct = (id: string | null) => {
    return request.put(`/api/dokumenti/vipusk-pf/records/${id}`);
  };
  // release
  static FetchReleaseProduct = (params: any) => {
    return request.get("/api/dokumenti/vipusk-gp", { params: params });
  };

  static FetchNotFilledReleaseProduct = (params: any) => {
    return request.get("/api/dokumenti/vipusk-gp/temp", { params: params });
  };

  static AddReleaseProduct = (params: any) => {
    return request.post("/api/dokumenti/vipusk-gp", params);
  };

  static AddNotFilledReleaseProduct = (params: any) => {
    return request.post("/api/dokumenti/vipusk-gp/temp", params);
  };

  static FetchNotFilledReleaseProductById = (id: string) => {
    return request.get(`/api/dokumenti/vipusk-gp/temp/${id}`);
  };

  static FetchReleaseProductById = (id: string) => {
    return request.get(`/api/dokumenti/vipusk-gp/${id}`);
  };

  static FetchReleaseProductStatics = () => {
    return request.get("/api/dokumenti/vipusk-gp/statistics");
  };

  static RemoveTempsReleaseProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/vipusk-gp/temp/${id}`);
  };

  static DeleteReleaseProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/vipusk-gp/${id}`);
  };
  static FetchReleaseDetails = (params: {
    zakazNaryadId: string | null;
    kol: number | null;
    tovarId: number | null;
    tipSpisaniyaId: string | number | null;

  }) => {
    return request.get("/api/dokumenti/vipusk-gp/details", { params });
  };

  static GetPriceMaterial = (params: {
    materialId: number | null;
    skladId: number | null;
    kol: string | number | null;
    zakazNaryadId: string | null;
  }) => {
    return request.get("/api/dokumenti/vipusk-gp/tsena-of-material", {
      params,
    });
  };

  static GetReleaseNumber = (params: any) => {
    return request.get("/api/dokumenti/vipusk-gp/nomer", { params });
  };

  static GetOrderProductsById = (params: any) => {
    return request.get("/api/dokumenti/vipusk-gp/tovars", {
      params: params,
      cancelToken: params.cancelToken,
    });
  };
  // undos
  static DeleteRecordsReleaseProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/vipusk-gp/records/${id}`);
  };

  static UndoRecordsReleaseProduct = (id: string | null) => {
    return request.put(`/api/dokumenti/vipusk-gp/records/${id}`);
  };
  // limit details
  static FetchLimitForTovar = (params: {
    tovarId: number | null;
    zakazNaryadId: string | null;
  }) => {
    return request.get("/api/dokumenti/vipusk-gp/tovar-kol", { params });
  };
  static FetchLimitForSemiMaterial = (params: {
    polufabrikatId: number | null;
    zakazNaryadId: string | null;
  }) => {
    return request.get("/api/dokumenti/vipusk-pf/pf-kol", { params });
  };
  //   shipment
  static FetchShipmentProduct = (params: any) => {
    return request.get("/api/dokumenti/otgruzka-gp", { params: params });
  };

  static FetchNotFilledShipmentProduct = (params: any) => {
    return request.get("/api/dokumenti/otgruzka-gp/temp", {
      params: params,
    });
  };

  static AddShipmentProduct = (params: any) => {
    return request.post("/api/dokumenti/otgruzka-gp", params);
  };

  static AddNotFilledShipmentProduct = (params: any) => {
    return request.post("/api/dokumenti/otgruzka-gp/temp", params);
  };

  static FetchNotFilledShipmentProductById = (id: string) => {
    return request.get(`/api/dokumenti/otgruzka-gp/temp/${id}`);
  };

  static FetchShipmentProductById = (id: string) => {
    return request.get(`/api/dokumenti/otgruzka-gp/${id}`);
  };

  static FetchShipmentProductStatics = () => {
    return request.get("/api/dokumenti/otgruzka-gp/statistics");
  };
  static RemoveTempsShipmentProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/otgruzka-gp/temp/${id}`);
  };
  static DeleteShipmentProductData = (id: string | null) => {
    return request.delete(`/api/dokumenti/otgruzka-gp/${id}`);
  };
  static GetShipmentNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/otgruzka-gp/nomer", { params });
  };

  static GetShipmentDetails = ({ id, signal }: { id: string; signal: any }) => {
    return request.get(`/api/dokumenti/otgruzka-gp/details/${id}`, {
      cancelToken: signal,
    });
  };
  static DeleteRecordShipmentProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/otgruzka-gp/records/${id}`);
  };
  static UndoRecordsShimpentProduct = (id: string | null) => {
    return request.put(`/api/dokumenti/otgruzka-gp/records/${id}`);
  };
  // return product before realize
  static FetchReturnProductBefReal = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-tov-otgruzka", {
      params: params,
    });
  };

  static FetchNotFilledReturnProductBefReal = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-tov-otgruzka/temp", {
      params: params,
    });
  };

  static AddReturnProductBefReal = (params: any) => {
    return request.post("/api/dokumenti/vozvrat-tov-otgruzka", params);
  };

  static AddNotFilledReturnProductBefReal = (params: any) => {
    return request.post("/api/dokumenti/vozvrat-tov-otgruzka/temp", params);
  };

  static FetchNotFilledReturnProductBefRealById = (id: string) => {
    return request.get(`/api/dokumenti/vozvrat-tov-otgruzka/temp/${id}`);
  };

  static FetchReturnProductBefRealById = (id: string) => {
    return request.get(`/api/dokumenti/vozvrat-tov-otgruzka/${id}`);
  };

  static FetchReturnProductBefRealStatics = () => {
    return request.get("/api/dokumenti/vozvrat-tov-otgruzka/statistics");
  };

  static RemoveTempsReturnProductBefReal = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-tov-otgruzka/temp/${id}`);
  };
  static DeleteReturnProductBefReal = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-tov-otgruzka/${id}`);
  };
  static FetchReturnProductBefRealDetails = (id: string | null) => {
    return request.get(`/api/dokumenti/otgruzka-gp/tovars/${id}`);
  };
  static CalculatetheSum = (params: any) => {
    return request.get(
      `/api/dokumenti/otgruzka-gp/tovar-sebest/${params.docOsnovanieId}?tovarId=${params?.tovarId}&kol=${params.kol}`
    );
  };
  static GetReturnProductBefRealNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/vozvrat-tov-otgruzka/nomer", { params });
  };
  // undo
  static DeleteRecordsReturnProductBefReal = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-tov-otgruzka/records/${id}`);
  };
  static UndoRecordsReturnProductBefReal = (id: string | null) => {
    return request.put(`/api/dokumenti/vozvrat-tov-otgruzka/records/${id}`);
  };

  // sales product
  static FetchSalesProduct = (params: any) => {
    return request.get("/api/dokumenti/realizatsiya-gp", {
      params: params,
      cancelToken: params.cancelReq,
    });
  };

  static FetchNotFilledSalesProduct = (params: any) => {
    return request.get("/api/dokumenti/realizatsiya-gp/temp", {
      params: params,
    });
  };

  static AddSalesProduct = (params: any) => {
    return request.post("/api/dokumenti/realizatsiya-gp", params);
  };

  static AddNotFilledSalesProduct = (params: any) => {
    return request.post("/api/dokumenti/realizatsiya-gp/temp", params);
  };

  static FetchNotFilledSalesProductById = (id: string) => {
    return request.get(`/api/dokumenti/realizatsiya-gp/temp/${id}`);
  };

  static FetchSalesProductById = (id: string) => {
    return request.get(`/api/dokumenti/realizatsiya-gp/${id}`);
  };

  static FetchSalesProductStatics = () => {
    return request.get("/api/dokumenti/realizatsiya-gp/statistics");
  };
  static RemoveTempsSalesProduct = (id: string) => {
    return request.delete(`/api/dokumenti/realizatsiya-gp/temp/${id}`);
  };

  static GetSalesProductDetails = (parmas: {
    zakazNaryadId: string;
    signal: any;
  }) => {
    return request.get(`/api/dokumenti/realizatsiya-gp/details`, {
      params: parmas,
      cancelToken: parmas.signal,
    });
  };

  static ChangeDiscountProduct = (params: any) => {
    return request.get(`/api/dokumenti/realizatsiya-gp/detail`, {
      params: params,
    });
  };
  static GetSalesNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/realizatsiya-gp/nomer", { params });
  };
  static DeleteSalesProductById = (id: string | null) => {
    return request.delete(`/api/dokumenti/realizatsiya-gp/${id}`);
  };
  static DeleteRecordsSalesProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/realizatsiya-gp/records/${id}`);
  };
  static UndoRecordsSalesProduct = (id: string | null) => {
    return request.put(`/api/dokumenti/realizatsiya-gp/records/${id}`);
  };
  // return product
  static FetchReturnProduct = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-tov", { params: params });
  };

  static FetchNotFilledReturnProduct = (params: any) => {
    return request.get("/api/dokumenti/vozvrat-tov/temp", {
      params: params,
    });
  };

  static AddReturnProduct = (params: any) => {
    return request.post("/api/dokumenti/vozvrat-tov", params);
  };

  static AddNotFilledReturnProduct = (params: any) => {
    return request.post("/api/dokumenti/vozvrat-tov/temp", params);
  };

  static FetchNotFilledReturnProductById = (id: string) => {
    return request.get(`/api/dokumenti/vozvrat-tov/temp/${id}`);
  };

  static FetchReturnProductById = (id: string) => {
    return request.get(`/api/dokumenti/vozvrat-tov/${id}`);
  };

  static FetchReturnProductStatics = () => {
    return request.get("/api/dokumenti/vozvrat-tov/statistics");
  };
  static RemoveTempsReturnProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-tov/temp/${id}`);
  };
  static DeleteReturnProductById = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-tov/${id}`);
  };
  static FetchReturnProductDetails = ({ id, cancelToken }: any) => {
    return request.get(`/api/dokumenti/realizatsiya-gp/details/${id}`);
  };
  static GetReturnProductNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/vozvrat-tov/nomer", { params });
  };
  static DeleteReducersReturnProductById = (id: string | null) => {
    return request.delete(`/api/dokumenti/vozvrat-tov/records/${id}`);
  };
  static UndoReducersReturnProductById = (id: string | null) => {
    return request.put(`/api/dokumenti/vozvrat-tov/records/${id}`);
  };
  // Ввод прочих производственных расходов
  static AddOtherProdCost = (params: any) => {
    return request.post(`/api/dokumenti/proch-rasxodi`, params);
  };
  static OtherProdCostNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/proch-rasxodi/nomer`, { params });
  };
  static FetchAllOtherProdCosts = (params: any) => {
    return request.get("/api/dokumenti/proch-rasxodi", { params });
  };
  static RemoveOtherCostTemp = (id: any) => {
    return request.delete(`/api/dokumenti/proch-rasxodi/temp/${id}`);
  };
  static RemoveDeleteCost = (id: any) => {
    return request.delete(`/api/dokumenti/proch-rasxodi/${id}`);
  };
  static FetchOtherProdCostById = (id: any) => {
    return request.get(`/api/dokumenti/proch-rasxodi/${id}`);
  };
  static FetchOtherProdCostTempById = (id: any) => {
    return request.get(`/api/dokumenti/proch-rasxodi/temp/${id}`);
  };
  static SaveOtherProdCostTemp = (params: any) => {
    return request.post(`/api/dokumenti/proch-rasxodi/temp`, params);
  };
  static FetchAllOtherProdCostTemp = () => {
    return request.get(`/api/dokumenti/proch-rasxodi/temp`);
  };
  static FetchOtherProdCostStat = () => {
    return request.get("/api/dokumenti/proch-rasxodi/statistics");
  };
  static DeleteRecordsOtherProdCostById = (id: string | null) => {
    return request.delete(`/api/dokumenti/proch-rasxodi/records/${id}`);
  };
  static UndoRecordsOtherProdCostById = (id: string | null) => {
    return request.put(`/api/dokumenti/proch-rasxodi/records/${id}`);
  };
  // regisetr parish cash
  static AddRegisterParishCash = (params: any) => {
    return request.post(`/api/dokumenti/prixod-bd`, params);
  };
  static RegisterParishCashNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/prixod-bd/nomer`, { params });
  };
  static FetchAllRegisterParishCash = (params: any) => {
    return request.get("/api/dokumenti/prixod-bd", { params });
  };
  static RemoveRegisterParishCashTemp = (id: any) => {
    return request.delete(`/api/dokumenti/prixod-bd/temp/${id}`);
  };
  static DeleteRegisterParishCash = (id: any) => {
    return request.delete(`/api/dokumenti/prixod-bd/${id}`);
  };
  static FetchRegisterParishCashById = (id: any) => {
    return request.get(`/api/dokumenti/prixod-bd/${id}`);
  };
  static FetchRegisterParishCashStat = () => {
    return request.get(`/api/dokumenti/prixod-bd/statistics`);
  };
  static FetchRegisterParishCashTempById = (id: any) => {
    return request.get(`/api/dokumenti/prixod-bd/temp/${id}`);
  };
  static SaveRegisterParishCashTemp = (params: any) => {
    return request.post(`/api/dokumenti/prixod-bd/temp`, params);
  };
  static UpdateRegisterParishCash = (params: any) => {
    return request.put(`/api/dokumenti/prixod-bd`, params);
  };
  static FetchAllRegisterParishCashTemp = () => {
    return request.get(`/api/dokumenti/prixod-bd/temp`);
  };
  static DeleteRecordsRegisterParishCash = (id: any) => {
    return request.delete(`/api/dokumenti/prixod-bd/records/${id}`);
  };
  static UndoRecordsRegisterParishCash = (id: any) => {
    return request.put(`/api/dokumenti/prixod-bd/records/${id}`);
  };
  // Приход наличных денег
  static ArrivalCashNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/pko/nomer`, { params });
  };
  static AddArrivalCash = (params: any) => {
    return request.post(`/api/dokumenti/pko`, params);
  };
  static UpdateAddArrivalCash = (params: any) => {
    return request.put(`/api/dokumenti/pko`, params);
  };
  static GetArrivalCashes = (params: any) => {
    return request.get(`/api/dokumenti/pko`, { params });
  };
  static GetArrivalCashesTemp = () => {
    return request.get(`/api/dokumenti/pko/temp`);
  };
  static GetArrivalCashesStat = () => {
    return request.get(`/api/dokumenti/pko/statistics`);
  };
  static RemoveArrivalCashesTemp = (id: any) => {
    return request.delete(`/api/dokumenti/pko/temp/${id}`);
  };
  static DeleteArrivalCashes = (id: any) => {
    return request.delete(`/api/dokumenti/pko/${id}`);
  };
  static SaveArrivalCashesTemp = (params: any) => {
    return request.post(`/api/dokumenti/pko/temp`, params);
  };
  static FetchArrivalCashesTempById = (id: any) => {
    return request.get(`/api/dokumenti/pko/temp/${id}`);
  };
  static FetchRemainder = () => {
    return request.get("/api/dokumenti/pko/ostatka");
  };

  static FetchArrivalCashesById = (id: any) => {
    return request.get(`/api/dokumenti/pko/${id}`);
  };
  static FetchPriceByArrivalCash = ({ id, pkoId, prixodBDId }: any) => {
    if (pkoId) {
      return request.get(
        `/api/dokumenti/pko/podotchet-debt/${id}?pkoId=${pkoId}`
      );
    } else if (prixodBDId) {
      return request.get(
        `/api/dokumenti/pko/podotchet-debt/${id}?prixodBDId=${prixodBDId}`
      );
    } else {
      return request.get(`/api/dokumenti/pko/podotchet-debt/${id}`);
    }
  };
  static FetchPriceByArrivalCashWidthDog = (params: any) => {
    return request.get(`/api/dokumenti/pko/dogovor`, { params: params });
  };
  static DelteRedordsArrivalCash = (id: string | null) => {
    return request.delete(`/api/dokumenti/pko/records/${id}`);
  };
  static UndoRecordsArrivalCash = (id: string | null) => {
    return request.put(`/api/dokumenti/pko/records/${id}`);
  };
  // Расход наличный денег
  static CostInCashNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/rko/nomer`, { params });
  };
  static AddCostInCash = (params: any) => {
    return request.post(`/api/dokumenti/rko`, params);
  };
  static UpdateCostInCash = (params: any) => {
    return request.put(`/api/dokumenti/rko`, params);
  };
  static GetCostsInCash = (params: any) => {
    return request.get(`/api/dokumenti/rko`, { params });
  };
  static GetCostsInCashTemp = () => {
    return request.get(`/api/dokumenti/rko/temp`);
  };
  static GetCostsInCashStat = () => {
    return request.get(`/api/dokumenti/rko/statistics`);
  };
  static RemoveCostInCashTemp = (id: any) => {
    return request.delete(`/api/dokumenti/rko/temp/${id}`);
  };
  static DeleteCostInCashData = (id: any) => {
    return request.delete(`/api/dokumenti/rko/${id}`);
  };
  static SaveCostInCashTemp = (params: any) => {
    return request.post(`/api/dokumenti/rko/temp`, params);
  };
  static FetchCostInCashTempById = (id: any) => {
    return request.get(`/api/dokumenti/rko/temp/${id}`);
  };
  static FetchCostInCashById = (id: any) => {
    return request.get(`/api/dokumenti/rko/${id}`);
  };
  static FetchMaxAmountOfCostInCash = (params: any) => {
    return request.get(`/api/dokumenti/rko/summa`, { params });
  };
  static DeleteRedordsCostInCash = (id: any) => {
    return request.delete(`/api/dokumenti/rko/records/${id}`);
  };
  static UndoRedordsCostInCash = (id: any) => {
    return request.put(`/api/dokumenti/rko/records/${id}`);
  };
  // Регистрация расхода безналичных денег
  static RegisterBezNalNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/rasxod-bd/nomer`, { params });
  };
  static AddRegisterBezNal = (params: any) => {
    return request.post(`/api/dokumenti/rasxod-bd`, params);
  };
  static UpdateRegisterBezNal = (params: any) => {
    return request.put(`/api/dokumenti/rasxod-bd`, params);
  };
  static GetRegisterBezNal = (params: any) => {
    return request.get(`/api/dokumenti/rasxod-bd`, { params });
  };
  static GetRegisterBezNalTemp = () => {
    return request.get(`/api/dokumenti/rasxod-bd/temp`);
  };
  static GetRegisterBezNalStat = () => {
    return request.get(`/api/dokumenti/rasxod-bd/statistics`);
  };
  static RemoveRegisterBezNalTemp = (id: any) => {
    return request.delete(`/api/dokumenti/rasxod-bd/temp/${id}`);
  };
  static DeleteRegisterBezNal = (id: any) => {
    return request.delete(`/api/dokumenti/rasxod-bd/${id}`);
  };
  static SaveRegisterBezNalTemp = (params: any) => {
    return request.post(`/api/dokumenti/rasxod-bd/temp`, params);
  };
  static FetchRegisterBezNalTempById = (id: any) => {
    return request.get(`/api/dokumenti/rasxod-bd/temp/${id}`);
  };
  static FetchRegisterBezNalById = (id: any) => {
    return request.get(`/api/dokumenti/rasxod-bd/${id}`);
  };
  static CheckRegisterBezNalSumma = (params: any) => {
    return request.get(`/api/dokumenti/rasxod-bd/summa`, { params });
  };
  static FetchKassaSummaRegisterBezNal = (params: any) => {
    return request.get(`/api/dokumenti/rasxod-bd/summa5110`, { params });
  };
  //  Зачет аванса покупателей
  static GetOffsetAdvanceNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/zachet/nomer`, { params });
  };
  static AddOffsetAdvance = (params: any) => {
    return request.post(`/api/dokumenti/zachet`, params);
  };
  static FetchAllOffsetAdvance = (params: any) => {
    return request.get(`/api/dokumenti/zachet`, { params });
  };
  static FetchOffsetAdvanceById = (id: any) => {
    return request.get(`/api/dokumenti/zachet/${id}`);
  };
  static FetchOffsetAdvanceDetails = () => {
    return request.get("/api/dokumenti/zachet/details");
  };
  static FetchOffsetAdvanceStat = () => {
    return request.get("/api/dokumenti/zachet/statistics");
  };
  static DeleteRedocrdsRegisterBezNal = (id: any) => {
    return request.delete(`/api/dokumenti/rasxod-bd/records/${id}`);
  };
  static UndoRecordsRegisterBezNal = (id: any) => {
    return request.put(`/api/dokumenti/rasxod-bd/records/${id}`);
  };
  // Приобретение бетона у водителей
  static PrioberBetonNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/priobr-bet/nomer`, { params });
  };
  static AddPrioberBeton = (params: any) => {
    return request.post(`/api/dokumenti/priobr-bet`, params);
  };
  static GetPrioberBeton = (params: any) => {
    return request.get(`/api/dokumenti/priobr-bet`, { params });
  };
  static GetPrioberBetonTemp = () => {
    return request.get(`/api/dokumenti/priobr-bet/temp`);
  };
  static GetPrioberBetonStatistic = () => {
    return request.get(`/api/dokumenti/priobr-bet/statistics`);
  };
  static RemovePrioberBetonTemp = (id: any) => {
    return request.delete(`/api/dokumenti/priobr-bet/temp/${id}`);
  };
  static SavePrioberBetonTemp = (params: any) => {
    return request.post(`/api/dokumenti/priobr-bet/temp`, params);
  };
  static FetchPrioberBetonTempById = (id: any) => {
    return request.get(`/api/dokumenti/priobr-bet/temp/${id}`);
  };
  static FetchPrioberBetonById = (id: any) => {
    return request.get(`/api/dokumenti/priobr-bet/${id}`);
  };
  static CheckPrioberBetonSumma = (params: any) => {
    return request.get(`/api/dokumenti/priobr-bet/summa`, { params });
  };
  static DeletePrioberBetonById = (id: any) => {
    return request.delete(`/api/dokumenti/priobr-bet/${id}`);
  };
  static UndoRecordsPrioberBetonById = (id: any) => {
    return request.put(`/api/dokumenti/priobr-bet/records/${id}`);
  };
  static DeleteRecordsPrioberBetonById = (id: any) => {
    return request.delete(`/api/dokumenti/priobr-bet/records/${id}`);
  };
  // Расчет себестоимости готовой продукции
  static GetCalculateProductNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/sebest/nomer`, { params });
  };
  static GetCalculateProductDate = (params: {
    territoriyaId: number | null;
  }) => {
    return request.get(`/api/dokumenti/sebest/date`, { params });
  };
  static AddCalculateProduct = (params: any) => {
    return request.post(`/api/dokumenti/sebest`, params);
  };
  static FetchAllCalculateProduct = (params: any) => {
    return request.get(`/api/dokumenti/sebest`, { params });
  };
  static FetchCalculateProductById = (id: string) => {
    return request.get(`/api/dokumenti/sebest/${id}`);
  };
  static FetchCalculateTempProductById = (id: string) => {
    return request.get(`/api/dokumenti/sebest/temp/${id}`);
  };
  static FetchCalculateProductDetails = (params: any) => {
    return request.get("/api/dokumenti/sebest/details", { params });
  };
  static FetchCalculateProductStat = () => {
    return request.get("/api/dokumenti/sebest/statistics");
  };
  static FetchCalculateProductDate = (params: {
    territoriyaId: number | null;
  }) => {
    return request.get("/api/dokumenti/sebest/date", { params });
  };
  static DeleteCalculateProductByID = (id: string | null) => {
    return request.delete(`/api/dokumenti/sebest/${id}`);
  };
  static DeleteRecordsCalculateProduct = (id: string | null) => {
    return request.delete(`/api/dokumenti/sebest/records/${id}`);
  };
  static UndoRecordsCalculateProduct = (id: string | null) => {
    return request.put(`/api/dokumenti/sebest/records/${id}`);
  };
  // Закрытие месяца
  static GetCloseMonthNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/zakr-mes/nomer`, { params });
  };
  static AddCloseMonth = (params: any) => {
    return request.post(`/api/dokumenti/zakr-mes`, params);
  };
  static FetchAllCloseMonth = (params: any) => {
    return request.get("/api/dokumenti/zakr-mes", { params });
  };
  static FetchCloseMonthById = (id: any) => {
    return request.get(`/api/dokumenti/zakr-mes/${id}`);
  };
  static FetchCloseMonthDetails = (dataDoc: any) => {
    return request.get(`/api/dokumenti/zakr-mes/details?dataDoc=${dataDoc}`);
  };
  static FetchCloseMonthStat = () => {
    return request.get("/api/dokumenti/zakr-mes/statistics");
  };
  static FetchCloseMonthDate = () => {
    return request.get("/api/dokumenti/zakr-mes/date");
  };
  static DeleteCloseMonthById = (id: string | null) => {
    return request.delete(`/api/dokumenti/zakr-mes/${id}`);
  };
  static DeleteRecordsCloseMonth = (id: string | null) => {
    return request.delete(`/api/dokumenti/zakr-mes/records/${id}`);
  };
  static UndoRecordsCloseMonth = (id: string | null) => {
    return request.put(`/api/dokumenti/zakr-mes/records/${id}`);
  };
  //  Ручные проводк
  static GetManualPostingNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/ruch-prov/nomer`, { params });
  };
  static AddManualPosting = (params: any) => {
    return request.post(`/api/dokumenti/ruch-prov`, params);
  };
  static FetchAllManualPosting = (params: any) => {
    return request.get("/api/dokumenti/ruch-prov", { params });
  };
  static FetchManualPostingById = (id: any) => {
    return request.get(`/api/dokumenti/ruch-prov/${id}`);
  };
  static FetchManualPostingSubconto = (params: any) => {
    return request.get("/api/dokumenti/ruch-prov/subkontos", { params });
  };
  static FetchManualPostingStat = () => {
    return request.get("/api/dokumenti/ruch-prov/statistics");
  };
  static DeleteManualPosting = (id: string | null) => {
    return request.delete(`/api/dokumenti/ruch-prov/${id}`);
  };
  static DeleteRecordsManualPosting = (id: string | null) => {
    return request.delete(`/api/dokumenti/ruch-prov/records/${id}`);
  };
  static UndoRecordsManualPosting = (id: string | null) => {
    return request.put(`/api/dokumenti/ruch-prov/records/${id}`);
  };
  // Списание некондиционного товара (брака)
  static FetchSubstandardNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/spisanie-brak/nomer", { params });
  };
  static FetchSubstandardStatistics = () => {
    return request.get("/api/dokumenti/spisanie-brak/statistics");
  };
  static AddSubstandardProduct = (params: any) => {
    return request.post("/api/dokumenti/spisanie-brak", params);
  };
  static FetchSubstandardProduct = (params: any) => {
    return request.get("/api/dokumenti/spisanie-brak", { params: params });
  };
  static FetchSubstandardProductById = (id: any) => {
    return request.get(`/api/dokumenti/spisanie-brak/${id}`);
  };
  static FetchTerritorySubstandard = () => {
    return request.get("/api/dokumenti/spisanie-brak/territories");
  };
  static FetchProductSubstandard = (params: {
    territoriyaId: number | null;
  }) => {
    return request.get("/api/dokumenti/spisanie-brak/tovar", { params });
  };
  static DeleteSubstandardProductById = (id: any) => {
    return request.delete(`/api/dokumenti/spisanie-brak/${id}`);
  };
  static DeleteRecordsSubstandardProduct = (id: any) => {
    return request.delete(`/api/dokumenti/spisanie-brak/records/${id}`);
  };
  static UndoSubstandardProduct = (id: any) => {
    return request.put(`/api/dokumenti/spisanie-brak/records/${id}`);
  };
  // Изменение параметров готовой прод. на складе
  static FetchChangeProductNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/izmenenie-gp/nomer", { params });
  };
  static AddChangeProduct = (params: any) => {
    return request.post("/api/dokumenti/izmenenie-gp", params);
  };
  static FetchChangeProduct = (params: any) => {
    return request.get("/api/dokumenti/izmenenie-gp", { params: params });
  };
  static FetchChangeProductById = (id: any) => {
    return request.get(`/api/dokumenti/izmenenie-gp/${id}`);
  };
  static FetchTovarChangeProduct = (params: any) => {
    return request.get(`/api/dokumenti/izmenenie-gp/tovars`, {
      params: params,
      cancelToken: params.cancel,
    });
  };
  static FetchChangeProductStat = () => {
    return request.get(`/api/dokumenti/izmenenie-gp/statistics`);
  };
  static FetchChangeProductKontragent = (params: any) => {
    return request.get(`/api/dokumenti/izmenenie-gp/kontragent`, {
      params: params,
      cancelToken: params.cancelToken,
    });
  };
  static FetchProductZakazNaryad = (params: any) => {
    return request.get(`/api/dokumenti/izmenenie-gp/zn`, {
      params: params,
      cancelToken: params.cancel,
    });
  };
  static FetchChangeProductTemp = (params: any) => {
    return request.get("/api/dokumenti/izmenenie-gp/temp", { params: params });
  };
  static AddChangeProductTemp = (params: any) => {
    return request.post("/api/dokumenti/izmenenie-gp/temp", params);
  };
  static RemoveChangeProductTemp = (id: any) => {
    return request.delete(`/api/dokumenti/izmenenie-gp/temp/${id}`);
  };
  static DeleteChangeProductById = (id: any) => {
    return request.delete(`/api/dokumenti/izmenenie-gp/${id}`);
  };
  static DeleteRecordsChangeProduct = (id: any) => {
    return request.delete(`/api/dokumenti/izmenenie-gp/records/${id}`);
  };
  static UndoRecordsChangeProduct = (id: any) => {
    return request.put(`/api/dokumenti/izmenenie-gp/records/${id}`);
  };
  static FetchChangeProductTempById = (id: any) => {
    return request.get(`/api/dokumenti/izmenenie-gp/temp/${id}`);
  };
  // Изменение параметров полуфабрикатов на складе
  static FetchChangeSemiProductNumber = (params: NumberDocType) => {
    return request.get("/api/dokumenti/izmenenie-pf/nomer", { params });
  };
  static AddChangeSemiProduct = (params: any) => {
    return request.post("/api/dokumenti/izmenenie-pf", params);
  };
  static FetchChangeSemiProduct = (params: any) => {
    return request.get("/api/dokumenti/izmenenie-pf", { params: params });
  };
  static FetchChangeSemiProductById = (id: any) => {
    return request.get(`/api/dokumenti/izmenenie-pf/${id}`);
  };
  static FetchPolifabrikatSemiChangeProduct = (params: any) => {
    return request.get(`/api/dokumenti/izmenenie-pf/pf`, { params: params });
  };
  static FetchChangeSemiProductStat = () => {
    return request.get(`/api/dokumenti/izmenenie-pf/statistics`);
  };
  static FetchChangeSemiProductKontragent = (params: any) => {
    return request.get(`/api/dokumenti/izmenenie-pf/kontragent`, {
      params: params,
      cancelToken: params.signal,
    });
  };
  static FetchSemiProductZakazNaryad = (params: any) => {
    return request.get(`/api/dokumenti/izmenenie-pf/zn`, {
      params: params,
      cancelToken: params.cancel,
    });
  };
  static FetchChangeSemiProductTemp = (params: any) => {
    return request.get("/api/dokumenti/izmenenie-pf/temp", { params: params });
  };
  static AddChangeSemiProductTemp = (params: any) => {
    return request.post("/api/dokumenti/izmenenie-pf/temp", params);
  };
  static RemoveChangeSemiProductTemp = (id: any) => {
    return request.delete(`/api/dokumenti/izmenenie-pf/temp/${id}`);
  };
  static DeleteChangeSemiProductById = (id: any) => {
    return request.delete(`/api/dokumenti/izmenenie-pf/${id}`);
  };
  static DeleteRecordsChangeSemiProduct = (id: any) => {
    return request.delete(`/api/dokumenti/izmenenie-pf/records/${id}`);
  };
  static UndoChangeSemiProduct = (id: any) => {
    return request.put(`/api/dokumenti/izmenenie-pf/records/${id}`);
  };
  static FetchChangeSemiProductTempById = (id: any) => {
    return request.get(`/api/dokumenti/izmenenie-pf/temp/${id}`);
  };
  // Перемещение наличных денег
  static MovingCashNumber = (params: NumberDocType) => {
    return request.get(`/api/dokumenti/peremesh-nd/nomer`, { params });
  };
  static AddNewMovingCash = (params: any) => {
    return request.post(`/api/dokumenti/peremesh-nd`, params);
  };
  static UpdateMovingCash = (params: any) => {
    return request.put(`/api/dokumenti/peremesh-nd`, params);
  };
  static GetMovingCashes = (params: any) => {
    return request.get(`/api/dokumenti/peremesh-nd`, { params });
  };
  static GetMovingCashesTemp = () => {
    return request.get(`/api/dokumenti/peremesh-nd/temp`);
  };
  static GetMovingCashesStat = () => {
    return request.get(`/api/dokumenti/peremesh-nd/statistics`);
  };
  static RemoveMovingCashesTemp = (id: any) => {
    return request.delete(`/api/dokumenti/peremesh-nd/temp/${id}`);
  };
  static DeleteMovingCashes = (id: any) => {
    return request.delete(`/api/dokumenti/peremesh-nd/${id}`);
  };
  static SaveMovingCashesTemp = (params: any) => {
    return request.post(`/api/dokumenti/peremesh-nd/temp`, params);
  };
  static FetchMovingCashesTempById = (id: any) => {
    return request.get(`/api/dokumenti/peremesh-nd/temp/${id}`);
  };

  static FetchMovingCashesById = (id: any) => {
    return request.get(`/api/dokumenti/peremesh-nd/${id}`);
  };
  static FetchPriceMovingCash = (params: any) => {
    return request.get(`/api/dokumenti/peremesh-nd/summa`, { params });
  };

  static DelteRedordsMovingCash = (id: string | null) => {
    return request.delete(`/api/dokumenti/peremesh-nd/records/${id}`);
  };
  static UndoRecordsMovingCash = (id: string | null) => {
    return request.put(`/api/dokumenti/peremesh-nd/records/${id}`);
  };
}

export default DocumentServices;
