import { useAppDispatch, useAppSelector } from "app/hook";
import {
  Breadcrumb,
  FlexBox,
  Text,
} from "components";
import { get, isEqual } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Kassa, Main } from "routers/paths";
import { Layout, Row, Select } from "antd";
import { remainderData } from "app/states/documents";
import { fetchRemainder } from "app/slices/documentSlices/arrivedCashesSlice/arrivedCashesSlice";
const KassaInnerLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const remainder = useAppSelector(remainderData);
  const { Content } = Layout;
  // redirect to the first section of side bar menu
  const navigateToFirstSidebar = useCallback(() => {
    if (isEqual(get(location, "pathname", ""), `/${Main.KASSA}`)) {
      navigate(Kassa.KASSA, { replace: true });
    }
  }, [location, navigate]);

  const getAllFetchRegister = useCallback(() => {
    dispatch(fetchRemainder());
  }, [dispatch]);

  useEffect(() => {
    navigateToFirstSidebar();
  }, [navigateToFirstSidebar]);
  useEffect(() => {
    getAllFetchRegister();
  }, [getAllFetchRegister]);
  const handleChange = (selectedOption: any) => {
    console.log(selectedOption);
  };
  return (
    <Fragment>
      <Layout
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Row justify={"space-between"} className="py-4">
          <Breadcrumb style={{ margin: "12px 0" }} only />
          <FlexBox gap={"20px"}>
            <FlexBox gap={"5px"} >
              <Text gray small>
                Общий остаток:
              </Text>
              <Select
                loading={get(remainder, "loading", false)}
                dropdownMatchSelectWidth={false}
                style={{ fontSize: "20px", minWidth: "10vh" }}
                value={get(remainder, "allCount", [])}
                options={get(remainder, "allSumm", [])}
                onChange={handleChange}
                defaultValue={get(remainder, "allCount", [])}
              />
            </FlexBox>
            <FlexBox gap={"5px"}>
              <Text gray small>
                Остаток в суммах:
              </Text>
              <Select
                dropdownMatchSelectWidth={false}
                loading={get(remainder, "loading", false)}
                style={{ fontSize: "20px" }}
                value={get(remainder, "countUZS", [])}
                options={get(remainder, "summaUZS", [])}
                onChange={handleChange}
                defaultValue={get(remainder, "countUZS", [])}
              />
            </FlexBox>
            <FlexBox gap={"5px"}>
              <Text gray small>
                Остаток в долларах:
              </Text>
              <Select
                loading={get(remainder, "loading", false)}
                dropdownMatchSelectWidth={false}
                style={{ fontSize: "20px" }}
                value={get(remainder, "countUSD", [])}
                options={get(remainder, "summaUSD", [])}
                onChange={handleChange}
                defaultValue={get(remainder, "countUSD", [])}
              />
            </FlexBox>
          </FlexBox>
        </Row>
        <Content
          style={{
            padding: " 0",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Fragment>
  );
};

export default KassaInnerLayout;
