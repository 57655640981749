import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";
import {
  changeDefectsTable,
  clearDefectsTable,
  fetchReportDefects,
  filterDefectsTable,
} from "app/slices/reportSlices/reportDefectsSlices/reportDefectsSlices";
import {
  getSkladGP,
  getTerritoriesData,
  getTypeProductData,
} from "app/states/handbooks/selectStates";
import { reportDefectsFilter, reportDefectsList } from "app/states/reports";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useState, useCallback, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import ReportInfoTitle from "../components/ReportInfoTitle";
import ReportOnDefectsTable from "../components/ReportOnDefectsTable";
import TableContentLoader from "../louders/TableContentLoader";
import {
  fetchOwnSkladCM,
  fetchOwnSkladGP,
} from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";

type ParamsType = {
  name?: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  tipTovara?: string;
  territoriya?: string;
  sklad?: string;
}

// Отчет по некондиционным товарам и браку
function ReportOnDefectsContainer() {
  const dispatch = useAppDispatch();

  const defectsDate = useAppSelector(reportDefectsList);
  const filter = useAppSelector(reportDefectsFilter);

  const productDropdowns = useAppSelector(getTypeProductData);
  const territoryDropdowns = useAppSelector(getTerritoriesData);
  const skladGPDropdowns = useAppSelector(getSkladGP);

  const [check, setCheck] = useState(false);
  const LOADING_TABLE = get(defectsDate, "loading", []);

  // details
  const [details, setDetails] = useState<ParamState>({
    tipTovara: "",
    territoriya: "",
    sklad: "",
  });

  //excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Отчет по некондиционным товарам и браку ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `${check ? "H1" : "D1"}`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "tipTovara", ""),
        label: `Тип готовой продукции:  ${get(details, "tipTovara", "")}`,
      },
      {
        value: get(details, "territoriya", ""),
        label: `Tерритория: ${get(details, "territoriya", "")}`,
      },
      {
        value: get(details, "sklad", ""),
        label: `Склад: ${get(details, "sklad", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `Отчет по некондиционным товарам и бракус ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(
          `A${1 + index + 1}`,
          `${check ? "H" + (1 + index + 1) : "D" + (1 + index + 1)}`
        );
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Товар",
        key: "imyaTovara",
        width: 30,
      },
      {
        header: "Количество",
        key: "kol",
        width: 25,
      },
      {
        header: "Сумма",
        key: "summa",
        width: 35,
      },
    ];
    const newTableHeader = check
      ? tableHeader.splice(
          2,
          0,
          check && {
            header: "Номер документа",
            key: "nomerDoc",
            width: 25,
          },
          check && {
            header: "Дата документа	",
            key: "dataDoc",
            width: 25,
          },
          check && {
            header: "Заказчик",
            key: "imyaKontragent",
            width: 25,
          },
          check && {
            header: "Договор",
            key: "nomerDogovor",
            width: 25,
          }
        )
      : tableHeader;
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(defectsDate, "defectsList", [])?.forEach((item: any, index: number) => {
      if (!check) {
        sheet.addRow({
          order: index + 1,
          imyaTovara: get(item, "imyaTovara", ""),

          kol: Number(get(item, "kol", "")),
          summa: Number(get(item, "summa", "")),
        });
        counterRow++;
      } else {
        sheet.addRow({
          order: index + 1,
          imyaTovara: get(item, "imyaTovara", ""),
          nomerDoc: get(item, "nomerDoc", ""),
          dataDoc: get(item, "dataDoc", ""),
          imyaKontragent: get(item, "imyaKontragent", ""),
          kol: Number(get(item, "kol", "")),
          summa: Number(get(item, "summa", "")),
          nomerDogovor: get(item, "nomerDogovor", ""),
        });
        counterRow += 1;
        get(item, "details", []).forEach((detail: any) => {
          const row = sheet.addRow({
            order: "ㅤ",
            imyaTovara: get(detail, "imyaTovara", ""),
            nomerDoc: get(detail, "nomerDoc", ""),
            dataDoc: get(detail, "dataDoc", ""),
            imyaKontragent: get(detail, "imyaKontragent", ""),
            kol: Number(get(detail, "kol", "")),
            summa: Number(get(detail, "summa", "")),
            nomerDogovor: get(detail, "nomerDogovor", ""),
          });
          row.eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
            };
          });
          counterRow++;
        });
      }
    });

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      `${check ? "H" : "D"}${counterRow + count + 1}`
    );
    // const startCell = worksheet.getCell('A1');
    // const endCell = worksheet.getCell('K17');

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const fetchReportDefectsData = useCallback(() => {
    dispatch(fetchReportDefects(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearDefectsTable());

    setDetails({
      tipTovara: "",
      territoriya: "",
      sklad: "",
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeDefectsTable(null));
    }

    dispatch(filterDefectsTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchTypeProduct({ all: true }));
    dispatch(fetchOwnSkladGP({}));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "fromDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "toDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <Title
                  xx
                  className="title_style"
                  mk
                  style={{ justifyContent: "left" }}
                >
                  Детально{" "}
                  <Checkbox
                    handleChange={() => {
                      handleChangeFilter({
                        name: "detalno",
                        value: !check,
                      });
                      setCheck(!check);
                    }}
                  />
                </Title>
              </FlexBox>
            </Col>

            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(defectsDate, "defectsList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchReportDefectsData}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  width={"200px"}
                  options={productDropdowns}
                  placeholder="Тип готовой продукции"
                  value={get(filter, "vidTovarId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.vidTovarId === e.value) return;

                    handleChangeFilter({
                      name: "vidTovarId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      tipTovara: get(e, "label", ""),
                    });

                    if (defectsDate.defectsList === null) return;
                    dispatch(
                      fetchReportDefects({
                        ...filter,
                        vidTovarId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width={"200px"}
                  options={territoryDropdowns}
                  placeholder="Tерритория"
                  value={get(filter, "territoriyaId", null)}
                  handleValueLabel={(e: any) => {
                    dispatch(
                      filterDefectsTable({
                        ...filter,
                        name: "skladId",
                        value: e.skladGPId,
                      })
                    );

                    if (filter.territoriyaId === e.value) return;

                    handleChangeFilter({
                      name: "territoriyaId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      territoriya: get(e, "label", ""),
                      sklad: e.imyaSkladGP.slice(5),
                    });

                    if (defectsDate.defectsList === null) return;
                    dispatch(
                      fetchReportDefects({
                        ...filter,
                        territoriyaId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width={"200px"}
                  isDisabled={true}
                  options={skladGPDropdowns}
                  placeholder="Склад"
                  disabled={true}
                  value={get(filter, "skladId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.skladId === e.value) return;

                    handleChangeFilter({
                      name: "skladId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      sklad: get(e, "label", ""),
                    });

                    if (defectsDate.defectsList === null) return;
                    dispatch(
                      fetchReportDefects({
                        ...filter,
                        skladId: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {defectsDate.defectsList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Отчет по некондиционным товарам и браку"}
                  />

                  {!isEmpty(get(defectsDate, "defectsList", [])) ? (
                    <ReportOnDefectsTable data={defectsDate} click={check} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default ReportOnDefectsContainer;
