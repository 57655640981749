import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, detailsMovement } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
    contractStatMaterials: {
        loading: false,
        data: {},
        error: "",
    },
    formData: {
        loading: false,
        error: "",
        isTempActive: false,
        data: {
            details: [],
        },
    },
    filter: {
        search: "",
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    movementMaterials: {
        loading: false,
        data: [],
        pagination: {},
        error: "",
    },
    notFilledCntrList: {
        loading: false,
        data: [],
        error: "",
    },
};

export const fetchMovementMaterials = createAsyncThunk(
    "movementMaterials/fetchMovementMaterials",
    async (params: any, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchMovementMaterials(params);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchNotFilledMovementMaterials = createAsyncThunk(
    "movementMaterials/fetchNotFilledMovementMaterials",
    async (params, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchNotFilledMovementMaterials(
                params
            );
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchMovementMaterialsByTempId = createAsyncThunk(
    "movementMaterials/fetchMovementMaterialsByTempId",
    async (id: string, thunkAPI) => {
        try {
            const request =
                await DocumentServices.FetchNotFilledMovementMaterialsById(id);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchMovementMaterialsById = createAsyncThunk(
    "movementMaterials/fetchMovementMaterialsById",
    async (id: string, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchMovementMaterialsById(id);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchMovementMaterialsStat = createAsyncThunk(
    "movementMaterials/fetchMovementMaterialsStat",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchMovementMaterialsStatics();
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const movementMaterialsSlice = createSlice({
    name: "movementMaterials",
    initialState,
    reducers: {
        handleAddSubjectsMovement: (state, actions) => {
            state.formData = {
                ...state.formData,
                data: {
                    ...state.formData.data,
                    details: !isEmpty(get(actions, "payload", {}))
                        ? [...state.formData.data.details, actions.payload]
                        : [],
                },
            };
        },
        movementMaterialSubject: (state = initialState, action) => {
            const id = get(action, "payload.id", null);
            if (action.payload.type === ACTION.ADD) {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        details: !isEmpty(get(action, "payload.data", []))
                            ? [
                                ...state.formData.data.details,
                                action.payload.data,
                            ]
                            : [],
                    },
                };
            } else if (action.payload.type === ACTION.UPDATE) {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        details: state.formData.data.details.map(
                            (item: detailsMovement) =>
                                item.materialId == id
                                    ?
                                    get(action, "payload.data", {})
                                    : item
                        ),
                    },
                };
            } else if (action.payload.type === ACTION.DELETE) {

                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        details: state.formData.data.details.filter(
                            (item: detailsMovement) => item.materialId !== id
                        ),
                    },
                };
            }
            else {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        details: [],
                    },
                };
            }
        },
        filterChangeMovementMaterials: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
        clearFormDataMovement: () => initialState,
        clearMovementMaterial: (state) => {
            state.formData = initialState.formData
        }

    },
    extraReducers: (builder) => {
        builder.addCase(fetchMovementMaterials.pending, (state) => {
            state.movementMaterials = {
                loading: true,
            };
        });
        builder.addCase(fetchMovementMaterials.fulfilled, (state, actions) => {
            state.movementMaterials = {
                loading: false,
                data: get(actions, "payload.data", []),
                pagination: get(actions, "payload.pagination", {}),
                error: "",
            };
        });
        builder.addCase(fetchMovementMaterials.rejected, (state, actions) => {
            state.movementMaterials = {
                loading: false,
                data: [],
                pagination: {},
                error: actions.error.message,
            };
        });

        builder.addCase(fetchNotFilledMovementMaterials.pending, (state) => {
            state.notFilledCntrList = {
                loading: true,
            };
        });
        builder.addCase(
            fetchNotFilledMovementMaterials.fulfilled,
            (state, actions) => {
                state.notFilledCntrList = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchNotFilledMovementMaterials.rejected,
            (state, actions) => {
                state.notFilledCntrList = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                };
            }
        );
        //  fetch temp
        builder.addCase(fetchMovementMaterialsByTempId.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(
            fetchMovementMaterialsByTempId.fulfilled,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                    isTempActive: true,
                };
            }
        );
        builder.addCase(
            fetchMovementMaterialsByTempId.rejected,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                    isTempActive: false,
                };
            }
        );
        // fetch id
        builder.addCase(fetchMovementMaterialsById.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(fetchMovementMaterialsById.fulfilled, (state, actions) => {
            state.formData = {
                loading: false,
                data: get(actions, "payload.data", []),
                error: "",
                isTempActive: true,
            };
        });
        builder.addCase(fetchMovementMaterialsById.rejected, (state, actions) => {
            state.formData = {
                loading: false,
                data: [],
                error: actions.error.message,
                isTempActive: false,
            };
        });

        // fetching sale contracts statists
        builder.addCase(fetchMovementMaterialsStat.pending, (state) => {
            state.contractStatMaterials = {
                loading: true,
            };
        });
        builder.addCase(fetchMovementMaterialsStat.fulfilled, (state, actions) => {
            state.contractStatMaterials = {
                loading: false,
                data: get(actions, "payload.data", {}),
                error: "",
            };
        });
        builder.addCase(fetchMovementMaterialsStat.rejected, (state, actions) => {
            state.contractStatMaterials = {
                loading: false,
                data: {},
                error: actions.error.message,
            };
        });
    },
});

export const {
    handleAddSubjectsMovement,
    filterChangeMovementMaterials,
    clearFormDataMovement,
    clearMovementMaterial,
    movementMaterialSubject,
} = movementMaterialsSlice.actions;
export default movementMaterialsSlice.reducer;
