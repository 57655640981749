import { get } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

const ReportOnFinishedTable = ({ data, click }: any) => {
    return (
        <Row className="mt-16">
            <Col>
                <Row>
                    <Col className="responsive_overflow">
                        <TableWrapper minWidth="1200px" className="mb-16">
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Дата документа отгрузки</th>
                                    <th>Номер Заказ-наяда</th>
                                    <th style={{ minWidth: "250px" }}>Контрагент</th>
                                    <th style={{ minWidth: "200px" }}>Товар</th>
                                    <th>Количество</th>
                                    <th>Ед. измерение</th>
                                    <td style={{ minWidth: "200px" }}>Адрес доставки</td>
                                    <td>ИНН</td>
                                    <td style={{ minWidth: "170px" }}>Телефоны</td>
                                    <td>Территория</td>
                                    <td style={{ minWidth: "200px" }}>Коммент</td>
                                </tr>
                            </thead>
                            <tbody>
                                {get(data, "finishedList", []) ? (
                                    get(data, "finishedList", []).map(
                                        (item: any, index: number) => (
                                            <tr key={index + 1}>
                                                <th>{get(item, 'idx', 0) ? get(item, 'idx', 0) : null}</th>
                                                <td >
                                                    {get(item, "dataDoc", "") ? moment(get(item, "dataDoc", "")).format("DD.MM.YYYY") : 'ㅤ'}
                                                </td>
                                                <td>
                                                    {get(item, 'zakazNaryad', '')}
                                                </td>
                                                <td>
                                                    {get(item, 'imyaKontragent', '')}
                                                </td>
                                                <td>
                                                    {get(item, 'imyaTovar', '')}
                                                </td>
                                                <td>
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={get(item, "kol", 0)}
                                                        thousandSeparator
                                                        decimalScale={2}
                                                    />
                                                </td>

                                                <td>
                                                    {get(item, 'edIzm', '')}
                                                </td>
                                                <td>{get(item, 'address', '')}</td>
                                                <td>
                                                    {get(item, 'innNomer', '')}
                                                </td>
                                                <td>
                                                    {get(item, 'phoneNumber', '')}
                                                </td>
                                                <td>{get(item, 'imyaTerritorii', '')}</td>
                                                <td>{get(item, 'comment', '')}</td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={7}>Виберите параметры</td>
                                    </tr>
                                )}
                            </tbody>
                        </TableWrapper>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ReportOnFinishedTable;
