import { createSelector } from "@reduxjs/toolkit";
import {
  chartsDetails,
  selectLoadingChart,
  statusChart,
} from "app/selectors/home/selectors";
import { get } from "lodash";
import moment from "moment";

export const chartLoading = createSelector(
  selectLoadingChart,
  (loading) => loading
);
export const statusChartData = createSelector(statusChart, (status) => {
  if (status) {
    return Object.values(status);
  }
});

moment.locale("ru");
export const chartLine = createSelector(chartsDetails, (status) => {
  if (status) {
    let line: Array<any> = [];
    const details: any = Object.values(status);

    if (details) {
      details.map((item: any) => {
        let result: { [key: string]: number | string } = {};
        item.forEach((el: any) => {
          result[el.imya] = el.amount;
          result["date"] = moment(el.yyyymm).format("MMMM");
        });
        line.push(result);
      });

      return line;
    }
  }
});

export const chartResult = createSelector(chartsDetails, (status) => {
  if (status) {
    const globalColors = [
      "#3a78d0",
      "#42be6b",
      "#dbae1b",
      "#6720d8",
      "#1bdbb1",
      "#db1b2b",
      "#6560C6",
    ];
    let resultData: Array<any> = [];
    const details: any = Object.values(status);
    if (details) {
      details.map((item: any, index: number) => {
        if (index === 0) {
          resultData = item.map((element: any, index: number) => ({
            keyData: get(element, "imya", ""),
            color: globalColors[index],
          }));
        }
      });
    }
    return resultData;
  }
});
