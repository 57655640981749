import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearManualPosting,
  clearManualPostingDetails,
  fetchAllManualPosting,
  fetchManualPostingStat,
  filterChangeManualPosting,
} from "app/slices/documentSlices/manualPostingSlice/manualPostingSlice";
import { fetchMenageAccount } from "app/slices/handbookSlices/menageAccountSlices/menageAccountSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  manualPostingFilter,
  manualPostingList,
  manualPostingStat,
} from "app/states/documents";
import { getDebutCreditData } from "app/states/handbooks/selectStates";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
// Ручные проводки
const ManualPostingsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_MANUAL_POSTING
  );
  const debutCreditOptions = useAppSelector(getDebutCreditData);
  const manualPosting = useAppSelector(manualPostingList);
  const filter = useAppSelector(manualPostingFilter);
  const statics = useAppSelector(manualPostingStat);
  const LOADING_TABLE = get(manualPosting, "loading", []);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchManualPostingFunction = useCallback(() => {
    dispatch(fetchAllManualPosting(filter));
    dispatch(fetchManualPostingStat());
    dispatch(fetchMenageAccount({ all: true }));
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(filterChangeManualPosting({ name: "", value: null }));
    dispatch(clearManualPosting());
  }, [dispatch, filter]);

  const navigateToForm = (params: { id: string | number }) => {
    navigate(Documents.ADD_MANUAL_POSTING, {
      state: params,
    });
  };

  const handleAddButton = () => {
    navigate(Documents.ADD_MANUAL_POSTING);
    dispatch(clearManualPostingDetails());
  };
  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeleteManualPosting(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchAllManualPosting(filter));
      }
    });
  };
  // status
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsManualPosting(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllManualPosting(filter));
      }
    });
  };
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsManualPosting(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllManualPosting(filter));
      }
    });
  };
  useEffect(() => {
    fetchManualPostingFunction();
  }, [filter.page, filter.size]);
  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, []);

  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={statics} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger className="mr-16" handleClick={handleClearFilter}>
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "RUCH_PROV_CREATE",
                    page: "RUCH_PROV",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="200px"
                  isClearable
                  value={get(filter, "debitSchet", "")}
                  isSearchable
                  options={debutCreditOptions}
                  placeholder="Дебет"
                  handleChange={(e: string) => {
                    dispatch(
                      filterChangeManualPosting({
                        name: "debitSchet",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width="200px"
                  isClearable
                  options={debutCreditOptions}
                  placeholder="Кредит"
                  isSearchable
                  value={get(filter, "kreditSchet", "")}
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeManualPosting({
                        name: "kreditSchet",
                        value: e,
                      })
                    );
                  }}
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeManualPosting({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeManualPosting({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className={"text-right"}>
              <Button primary handleClick={fetchManualPostingFunction}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateToForm}
                  deleteDocument={handleDeleteDocument}
                  deleteItem={true}
                  markItem={true}
                  uncheckItem={true}
                  userPermission={"RUCH_PROV"}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Время записи",
                    "Статус",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    !isEmpty(get(manualPosting, "data", [])) ? (
                      <>
                        {get(manualPosting, "data", []).map(
                          (item: any, index: number) => (
                            <tr
                              key={get(item, "id", "")}
                              onClick={() =>
                                navigateToForm({
                                  id: get(item, "id", ""),
                                })
                              }
                              onContextMenu={(e) =>
                                displayMenu({
                                  e,
                                  id: get(item, "id", ""),
                                  status: get(item, "status", 0),
                                })
                              }
                              className="cursor-pointer"
                            >
                              <td>
                                {indexNumber({
                                  currentPage: get(
                                    manualPosting,
                                    "pagination.currentPageNumber",
                                    0
                                  ),
                                  pageSize: get(
                                    manualPosting,
                                    "pagination.pageSize",
                                    10
                                  ),
                                  index: index + 1,
                                })}
                              </td>

                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDoc", "-")}</td>
                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>
                                {moment(get(item, "createdAt", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "status", "-")}</td>
                            </tr>
                          )
                        )}
                      </>
                    ) : (
                      <Nodata colLength={7} />
                    )
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={7}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(manualPosting, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    filterChangeManualPosting({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(manualPosting, "pagination.currentPageNumber", 0)}
                pageCount={get(manualPosting, "pagination.totalPages", 1)}
                onPageChange={({ selected }) =>
                  dispatch(
                    filterChangeManualPosting({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default ManualPostingsContainer;
