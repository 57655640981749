import { get, isEmpty } from "lodash";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
const LineChartBoard = ({ data, keyDataInfo }: any) => {
  return (
    <>
      {!isEmpty(data) && (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={data}
            height={400}
            barSize={850}
            margin={{ top: 10, right: 30, left: 5, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={"date"} />
            <YAxis />
            <Tooltip />
            {!isEmpty(keyDataInfo) &&
              keyDataInfo.map((line: any, index: any) => (
                <Line
                  type="monotone"
                  key={index + 1}
                  dataKey={get(line, "keyData", "")}
                  stroke={get(line, "color", "")}
                  dot={{ r: 6 }}
                  activeDot={{ r: 8 }}
                  strokeWidth={2.5}
                  startOffset={"left"}
                  isAnimationActive={false}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
export default LineChartBoard;
