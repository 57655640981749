import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { Col, Row } from "react-grid-system";
import { MaterialReportTable } from "../components";
import { useAppDispatch, useAppSelector } from "app/hook";
import { materialReportFilter, materialReportList } from "app/states/reports";
import { useCallback, useEffect, useState } from "react";
import {
  changeMaterialTable,
  clearMaterialTable,
  fetchMaterialReport,
  filterMaterialTable,
} from "app/slices/reportSlices/materialReportSlices/materialReportSlices";
import moment from "moment";
import { getSkladCM } from "app/states/handbooks/selectStates";
import { fetchOwnSkladCM } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { get, isEmpty, isEqual } from "lodash";
import { toast } from "react-toastify";
import TableContentLoader from "../louders/TableContentLoader";
import ReportInfoTitle from "../components/ReportInfoTitle";
import { fetchTypeMaterial } from "app/slices/handbookSlices/typeMaterialSlices/typeMaterialSlices";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

// Материальный отчет по Склад сырья и материалов
const MaterialReportContainer = () => {
  const dispatch = useAppDispatch();

  // data
  const materialData = useAppSelector(materialReportList);
  const filter = useAppSelector(materialReportFilter);
  const getWareHouse = useAppSelector(getSkladCM);
  const LOADING_TABLE = get(materialData, "loading", false);

  const fetchAllMaterialReport = useCallback(() => {
    if (filter.skladId) {
      dispatch(fetchMaterialReport(filter));
    }
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearMaterialTable());
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeMaterialTable(null));
    }

    dispatch(filterMaterialTable({ ...filter, name, value }));
  };
  // exel dwn
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Материальный отчет по Склад сырья и материалов ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `K1`);
    sheet.getCell(
      "A1"
    ).value = `Материальный отчет по Склад сырья и материалов с  ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    sheet.getRow(3).height = 20; // C3 qatridagi balandlik
    sheet.getRow(4).height = 20; // C4 qatridagi balandlik
    sheet.getRow(1).height = 20;
    sheet.mergeCells("A3", `A4`);
    sheet.getCell("A3").value = "№";
    sheet.mergeCells("B3", "B4");
    sheet.getCell("B3").value = "Материал";
    sheet.mergeCells("C3", "C4");
    sheet.getCell("C3").value = "Единица измерения";
    sheet.mergeCells("D3", "E3");
    sheet.getCell("D3").value = "Начальный остаток";
    sheet.getCell("D4").value = "Количество";
    sheet.getCell("E4").value = "Сумма";
    sheet.mergeCells("F3", "G3");
    sheet.getCell("F3").value = "Приход";
    sheet.getCell("F4").value = "Количество";
    sheet.getCell("G4").value = "Сумма";
    sheet.mergeCells("H3", "I3");
    sheet.getCell("H3").value = "Расход";
    sheet.getCell("H4").value = "Количество";
    sheet.getCell("I4").value = "Сумма";
    sheet.mergeCells("J3", "K3");
    sheet.getCell("J3").value = "Конечный остаток";
    sheet.getCell("J4").value = "Количество";
    sheet.getCell("K4").value = "Сумма";
    let count = 1;
    sheet.getCell("A3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("B3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("D4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("F4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("H4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.columns = [
      { width: 30 },
      { width: 60 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const tableHeader = [
      {
        header: "Код",
        key: "index",
        width: 5,
      },
      {
        header: "Наименованиесчета",
        key: "imyaMaterial",
        width: 60,
      },
      {
        header: "Дебет",
        key: "imyaEdIzm",
        width: 30,
      },
      {
        header: "Kredit",
        key: "nachalniyOstatok1",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "nachalniyOstatok2",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "prixod1",
        width: 30,
      },
      {
        header: "konechniyOstatokDebit",
        key: "prixod2",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "rasxod1",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "rasxod2",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "konechniyOstatok1",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "konechniyOstatok2",
        width: 30,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    // sheet.getRow(5).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(3);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(materialData, "dataList.list", [])?.forEach(
      (item: any, index: number) => {
        const headerRow1 = sheet.getRow(index + 4);
        // headerRow1.height = 25;
        // headerRow1.alignment = { vertical: "middle", horizontal: "center" };
        sheet.addRow({
          index: index + 1,
          imyaMaterial: get(item, "imyaMaterial", ""),
          imyaEdIzm: get(item, "imyaEdIzm", ""),
          nachalniyOstatok1: Number(
            get(item, "nachalniyOstatok.kolichestva", "")
          ),
          nachalniyOstatok2: Number(get(item, "nachalniyOstatok.summa", "")),
          prixod1: Number(get(item, "prixod.kolichestva", "")),
          prixod2: Number(get(item, "prixod.summa", "")),
          rasxod1: Number(get(item, "rasxod.kolichestva", "")),
          rasxod2: Number(get(item, "rasxod.summa", "")),
          konechniyOstatok1: Number(
            get(item, "konechniyOstatok.kolichestva", "")
          ),
          konechniyOstatok2: Number(get(item, "konechniyOstatok.summa", "")),
        });
        counterRow++;
      }
    );
    sheet.mergeCells(
      `A${counterRow + count + 3}`,
      `C${counterRow + count + 3}`
    );
    sheet.getCell(`A${counterRow + count + 3}`).value =
      "Итого:  ㅤ ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ";
    sheet.getCell(`D${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.NachalniyKol", "")
    );
    sheet.getCell(`E${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.NachalniySum", "")
    );
    sheet.getCell(`F${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.PrixodKol", "")
    );
    sheet.getCell(`G${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.PrixodSum", "")
    );
    sheet.getCell(`H${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.RasxodKol", "")
    );
    sheet.getCell(`I${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.RasxodSum", "")
    );
    sheet.getCell(`J${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.KonechniyKol", "")
    );
    sheet.getCell(`K${counterRow + count + 3}`).value = Number(
      get(materialData, "dataList.itogo.KonechniySum", "")
    );
    // sheet.getRow(counterRow + 3).height = 25;
    // sheet.getRow(counterRow + 3).alignment = {
    //   vertical: "middle",
    //   horizontal: "center",
    // };
    const fontBoldCells = [
      "A1",
      "A3",
      "A4",
      "B3",
      "B4",
      "C3",
      "C4",
      "D3",
      "D4",
      "E3",
      "E4",
      "F3",
      "F4",
      "G3",
      "G4",
      "H3",
      "H4",
      "I3",
      "I4",
      "J3",
      "J4",
      "K3",
      "K4",
      "A" + (count + 3),
      "B" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),
      "I" + (count + 3),
      "J" + (count + 3),
      "K" + (count + 3),
      "H" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),
      "I" + (count + 3),
      "J" + (count + 3),
      "K" + (count + 3),
      // "G" + (count + 3),
      // "H" + (count + 3),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`K${counterRow + count + 3}`);
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach((item: any) => {
      sheet.getCell(item).font = {
        size: 11,
        bold: true,
      };
    });
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    dispatch(fetchOwnSkladCM({}));
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  options={getWareHouse}
                  placeholder="Склад"
                  width="230px"
                  value={get(filter, "skladId", null)}
                  handleChange={(e: any) => {
                    handleChangeFilter({
                      name: "skladId",
                      value: e,
                      clear: true,
                    });
                  }}
                />

                <DatePicker
                  placeholder="Дата начало"
                  value={get(filter, "fromDate", "")}
                  width="150px"
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  placeholder="Дата конец"
                  width="150px"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>

            <Col className={"text-right px-16"} xs={6}>
              {!isEmpty(get(materialData, "dataList.list", [])) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllMaterialReport}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <BaseSelect
                width={"230px"}
                options={[
                  { label: "Сырье и материалы", value: "SM" },
                  { label: "Полуфабрикаты", value: "PF" },
                ]}
                placeholder="Тип"
                value={get(filter, "tipMaterial", null)}
                handleValueLabel={(e: any) => {
                  if (filter.tipMaterial === e.value) return;

                  handleChangeFilter({
                    name: "tipMaterial",
                    value: e.value,
                  });
                }}
              />
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {materialData.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    titleTxt={"Материальный отчет по  Склад сырья и материалов"}
                  />

                  {!isEmpty(get(materialData, "dataList.list", [])) ? (
                    <MaterialReportTable data={materialData} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default MaterialReportContainer;
