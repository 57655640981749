import { useAppDispatch, useAppSelector } from "app/hook";
import {
  changeOperationalCashTable,
  clearOperationalCashTable,
  fetchOperationalCash,
  filterOperationalCashTable,
} from "app/slices/reportSlices/operationalCashReportSlices/operationalCashReportSlices";
import { getKassaData } from "app/states/handbooks/selectStates";
import { operationalCashFilter, operationalCashList } from "app/states/reports";
import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import OperationalCashReportTable from "../components/OperationalCashReportTable";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  kassa?: string;
}

// Оперативный отчет по движению наличных денежных средств
function OperationalCashReportContainer() {
  const dispatch = useAppDispatch();

  // data
  const data = useAppSelector(operationalCashList);
  const filter = useAppSelector(operationalCashFilter);
  const [details, setDetails] = useState<ParamState>({ kassa: "" });

  const kassaDropdowns = useAppSelector(getKassaData);
  const LOADING_TABLE = get(data, "loading", false);

  const fetchOperationalCashData = useCallback(() => {
    if (filter.kassaId) dispatch(fetchOperationalCash(filter));
  }, [dispatch, filter]);

  const handleClearFilter = () => {
    dispatch(clearOperationalCashTable());

    setDetails({ kassa: "" });
  };
  //excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Оперативный отчет по движению наличных денежных средств ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `G1`);
    sheet.mergeCells("A2", `G2`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    let count = 2;
    sheet.getCell(
      "A1"
    ).value = `Оперативный отчет по движению наличных денежных средств с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    sheet.getCell("A2").value = `Касса: ${details.kassa}`;
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Дата",
        key: "dataDoc",
        width: 30,
      },
      {
        header: "Номер документа",
        key: "nomerDoc",
        width: 25,
      },
      {
        header: "Контрагент",
        key: "imyaKontragent",
        width: 35,
      },
      {
        header: "Приход",
        key: "prixodSumma",
        width: 30,
      },
      {
        header: "Расход",
        key: "rasxodSumma",
        width: 30,
      },
      {
        header: "Остаток",
        key: "ostKon",
        width: 30,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 2;
    const row1 = sheet.addRow({
      order: "ㅤ",
      dataDoc: "ㅤ",
      nomerDoc: "ㅤ",
      imyaKontragent: "ㅤ",
      prixodSumma: "ㅤ",
      rasxodSumma: "ㅤ",
      ostKon: Number(get(data, "dataList.itogo.ostNach", 0)),
    });
    // row1.height = 25;
    // row1.alignment = { vertical: "middle", horizontal: "center" };
    get(data, "dataList.list", [])?.forEach((item: any, index: number) => {
      const row = sheet.addRow({
        order: index + 1,
        dataDoc: get(item, "dataDoc", ""),
        nomerDoc: get(item, "nomerDoc", ""),
        imyaKontragent: get(item, "imyaKontragent", ""),
        prixodSumma: Number(get(item, "prixodSumma", 0)),
        rasxodSumma: Number(get(item, "rasxodSumma", 0)),
        ostKon: Number(get(item, "ostKon", 0)),
      });
      // row.height = 25;
      // row.alignment = { vertical: "middle", horizontal: "center" };
      counterRow += 1;
    });
    sheet.mergeCells(
      `A${counterRow + count + 2}`,
      `D${counterRow + count + 2}`
    );
    sheet.getCell(`A${counterRow + count + 2}`).value = "Итого:";
    const itogo = sheet.getCell(`A${counterRow + count + 2}`);
    itogo.alignment = { vertical: "middle" };
    const itogoRasxod = sheet.getCell(`F${counterRow + count + 2}`);
    itogoRasxod.value = Number(get(data, "dataList.itogo.itogoRasxod", 0));
    const itogoPrixod = sheet.getCell(`E${counterRow + count + 2}`);
    itogoPrixod.value = Number(get(data, "dataList.itogo.itogoRasxod", 0));
    const height = sheet.getRow(counterRow + count + 2);
    // height.height = 25;
    // itogoRasxod.alignment = { vertical: "middle", horizontal: "center" };
    // itogoPrixod.alignment = { vertical: "middle", horizontal: "center" };
    // totSumma.height = 25;
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `G${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`G${counterRow + count + 2}`);

    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeOperationalCashTable(null));
    }

    dispatch(filterOperationalCashTable({ ...filter, name, value }));
  };

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <BaseSelect
                  width="230px"
                  options={kassaDropdowns}
                  placeholder="Касса"
                  value={get(filter, "kassaId", "")}
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "kassaId",
                      value: e.value,
                      clear: true,
                    });

                    setDetails({ ...details, kassa: get(e, "label", "") });
                  }}
                />

                <DatePicker
                  width="150px"
                  placeholder="Дата"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchOperationalCashData}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>

          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={
                      "Оперативный отчет по движению наличных денежных средств"
                    }
                  />

                  {!isEmpty(get(data, "dataList.list", [])) ? (
                    <OperationalCashReportTable
                      data={data}
                      toDate={get(filter, "toDate", "")}
                    />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default OperationalCashReportContainer;
