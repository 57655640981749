import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get,} from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
  data: { loading: false, typeMaterialData: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchTypeMaterial = createAsyncThunk(
  "allTypeMaterial/fetchTypeMaterial",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
        const request = await HandBookServices.GetTypeMaterial(params);

        const respond = await request.data;
        return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const typeMaterialSlices = createSlice({
  name: "allTypeMaterial",
  initialState,
  reducers: {
    handleTypeMaterialFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    handleTypeMaterialFilterClear:(state)=>{
      state.filter=initialState.filter
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTypeMaterial.pending, (state) => {
      state.data = {
        loading: true,
        typeMaterialData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchTypeMaterial.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        typeMaterialData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),

        error: "",
      };
    });
    builder.addCase(fetchTypeMaterial.rejected, (state, payload) => {
      state.data = {
        loading: false,
        typeMaterialData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleTypeMaterialFilter,handleTypeMaterialFilterClear } = typeMaterialSlices.actions;
export default typeMaterialSlices.reducer;
