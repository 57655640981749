import React, { memo, useState, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { FormInputWrapper, InputWrapper } from "./style";
import Title from "components/Title/Title";
import Text from "components/Text/Text";

const FormInput = ({
    label,
    placeholder,
    handleOnChange,
    type,
    clear = "",
    handleOnBlur,
    error,
    name,
    handleInput = (e: any) => { },
    disabled = false,
    required = false,
    id,
    ...props
}: any) => {
    const [val, setVal] = useState<any>(clear);

    const handleChange = (e: any) => {
        setVal(e.target.value);
        handleInput(e.target.value);
    };

    useEffect(() => {
        if (clear.length == 0) {
            setVal(clear);
        }
    }, [clear]);
    return (
        <FormInputWrapper {...props}>
            <Row align={"center"} justify={"end"} className="mt-24">
                <Col xs={4}>
                    <Title>{label}
                        {required ?
                            <span className="required_point">*</span> : ""
                        }
                    </Title>
                </Col>
                <Col xs={8}>
                    <InputWrapper
                        onWheel={(e: any) => { e.currentTarget.blur() }}
                        value={val}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={handleChange}
                        {...props}
                    />
                    {error && <Col style={{ paddingLeft: 0 }}>
                        <Text xs danger>{error}</Text>
                    </Col>}
                </Col>

            </Row>
        </FormInputWrapper>
    );
};

export default memo(FormInput);
