import { RootState } from "app/store";

export const selectAllBanks = (store: RootState) => store.handbooks.banks.data;
export const selectAllBanksFilter = (store: RootState) =>
  store.handbooks.banks.filter;

export const selectAllBanksAccounts = (store: RootState) =>
  store.handbooks.bankAccounts.data;
export const selectAllBanksAccountsFilter = (store: RootState) =>
  store.handbooks.bankAccounts.filter;

export const selectAllKassa = (state: RootState) => state.handbooks.kassa.data;
export const selectAllKassaInfo = (state: RootState) =>
  state.handbooks.kassa.allKassa;
export const selectAllKassaFilter = (state: RootState) =>
  state.handbooks.kassa.filter;

export const selectAllFizList = (state: RootState) =>
  state.handbooks.fizLits.data;
export const selectAllFizListFilter = (state: RootState) =>
  state.handbooks.fizLits.filter;

export const selectAllYurList = (state: RootState) =>
  state.handbooks.yurLits.data;
export const selectAllYurListFilter = (state: RootState) =>
  state.handbooks.yurLits.filter;

export const selectPhysPerson = (state: RootState) =>
  state.handbooks.physPerson.data;
export const selectPhysPersonFilter = (state: RootState) =>
  state.handbooks.physPerson.filter;

export const selectLargePerson = (state: RootState) =>
  state.handbooks.largePerson.data;
export const selectLargePersonFilter = (state: RootState) =>
  state.handbooks.largePerson.filter;

export const selectCounterpart = (state: RootState) =>
  state.handbooks.counterpart.data;
export const selectCounterpartVipuskPf = (state: RootState) =>
  state.handbooks.counterpart.optionsVipsPf;
export const selectCounterpartFilter = (state: RootState) =>
  state.handbooks.counterpart.filter;
export const selectCounterpartById = (state: RootState) =>
  state.handbooks.counterpart.getCounterpartById;
export const selectCounterpartDto = (state: RootState) =>
  state.handbooks.counterpart.addressDto;
export const selectCounterpartOptions = (state: RootState) =>
  state.handbooks.counterpart.dataOptions;

export const selectBankCounterpart = (state: RootState) =>
  state.handbooks.bankCounterpart.data;
export const selectBankCounterpartFilter = (state: RootState) =>
  state.handbooks.bankCounterpart.filter;

export const selectDeliveryAddress = (state: RootState) =>
  state.handbooks.deliveryAddress.data;
export const selectDeliveryAddressFilter = (state: RootState) =>
  state.handbooks.deliveryAddress.filter;

export const selectTermsPayment = (state: RootState) =>
  state.handbooks.termsPayment.data;
export const selectTermsPaymentFilter = (state: RootState) =>
  state.handbooks.termsPayment.filter;

export const selectWarHouse = (state: RootState) =>
  state.handbooks.warHouse.data;
export const selectWarHouseFilter = (state: RootState) =>
  state.handbooks.warHouse.filter;

export const selectSkladGP = (state: RootState) =>
  state.handbooks.warHouse.skladGP;
export const seletSkladCM = (state: RootState) =>
  state.handbooks.warHouse.skladCM;

export const selectTerritories = (state: RootState) =>
  state.handbooks.territories.data;
export const selectTerritoriesFilter = (state: RootState) =>
  state.handbooks.territories.filter;

export const selectWorkshop = (state: RootState) =>
  state.handbooks.workshop.data;
export const selectWorkshopFilter = (state: RootState) =>
  state.handbooks.workshop.filter;

export const selectUnitsMeasurement = (state: RootState) =>
  state.handbooks.unitsMeasurement.data;
export const selectUnitsMeasurementFilter = (state: RootState) =>
  state.handbooks.unitsMeasurement.filter;

export const selectDiscount = (state: RootState) =>
  state.handbooks.discount.data;
export const selectDiscountFilter = (state: RootState) =>
  state.handbooks.discount.filter;

export const selectTypeProduct = (state: RootState) =>
  state.handbooks.typeProduct.data;
export const selectTypeProductFilter = (state: RootState) =>
  state.handbooks.typeProduct.filter;

export const selectProduct = (state: RootState) => state.handbooks.product.data;
export const selectProductFilter = (state: RootState) =>
  state.handbooks.product.filter;

export const selectPrice = (state: RootState) => state.handbooks.price.data;
export const selectPriceFilter = (state: RootState) =>
  state.handbooks.price.filter;

export const selectCalculation = (state: RootState) =>
  state.handbooks.calculation.data;
export const selectCalculationFilter = (state: RootState) =>
  state.handbooks.calculation.filter;
export const selectCalculationMaterials = (state: RootState) =>
  state.handbooks.calculation.formMaterials;

export const selectRawMaterial = (state: RootState) =>
  state.handbooks.rawMaterial.data;
export const selectRawMaterialFilter = (state: RootState) =>
  state.handbooks.rawMaterial.filter;

export const selectTypeMaterial = (state: RootState) =>
  state.handbooks.typeMaterial.data;
export const selectTypeMaterialFilter = (state: RootState) =>
  state.handbooks.typeMaterial.filter;

export const selectPriceMaterial = (state: RootState) =>
  state.handbooks.priceMaterial.data;
export const selectPriceMaterialFilter = (state: RootState) =>
  state.handbooks.priceMaterial.filter;

export const selectSemiProduct = (state: RootState) =>
  state.handbooks.semiProducts.data;
export const selectSemiProductFilter = (state: RootState) =>
  state.handbooks.semiProducts.filter;

export const selectSemiCalculation = (state: RootState) =>
  state.handbooks.semiCalculations.data;
export const selectSemiCalculationFilter = (state: RootState) =>
  state.handbooks.semiCalculations.filter;
export const selectSemiCalculationMaterials = (state: RootState) =>
  state.handbooks.semiCalculations.formMaterials;

export const selectRateVAT = (state: RootState) => state.handbooks.rateVTA.data;
export const selectRateVATFilter = (state: RootState) =>
  state.handbooks.rateVTA.filter;

export const selectExpanseItem = (state: RootState) =>
  state.handbooks.expanseItem.data;
export const selectExpanseItemFilter = (state: RootState) =>
  state.handbooks.expanseItem.filter;

export const selectTypeTruck = (state: RootState) =>
  state.handbooks.typeTruck.data;
export const selectTypeTruckFilter = (state: RootState) =>
  state.handbooks.typeTruck.filter;

export const selectTruck = (state: RootState) => state.handbooks.truck.data;
export const selectTruckFilter = (state: RootState) =>
  state.handbooks.truck.filter;

export const selectDrivers = (state: RootState) => state.handbooks.drivers.data;
export const selectDriversFilter = (state: RootState) =>
  state.handbooks.drivers.filter;

export const selectUsers = (state: RootState) => state.handbooks.users.data;
export const selectUsersFilter = (state: RootState) =>
  state.handbooks.users.filter;

export const selectDetailsCompany = (state: RootState) =>
  state.handbooks.detailsCompany.data;
export const selectDetailsCompanyFilter = (state: RootState) =>
  state.handbooks.detailsCompany.filter;

export const selectSubConto = (state: RootState) =>
  state.handbooks.subConto.data;
export const selectSubContoFilter = (state: RootState) =>
  state.handbooks.subConto.filter;

export const selectMenageAccounts = (state: RootState) =>
  state.handbooks.menageAccounts.data;
export const selectMenageAccountsFilter = (state: RootState) =>
  state.handbooks.menageAccounts.filter;
