import { createSelector } from "@reduxjs/toolkit";
import {
  selectLogFilter,
  selectLogUsers,
  selectTypeRoleFilter,
  selectTypeRoleList,
  selectTypeRolePermDetails,
  selectTypeRolePermissions,
  selectUserRoleFilter,
  selectUserRoleList,
  selectUserRolePermDetails,
  selectUserRolePermissions,
} from "app/selectors/users";
import { get } from "lodash";

// user role
export const userRoleList = createSelector(selectUserRoleList, (data) => data);
export const userRoleFilter = createSelector(
  selectUserRoleFilter,
  (filter) => filter
);
export const userRolePermissions = createSelector(
  selectUserRolePermissions,
  (permissions) => permissions
);
export const userRolePermDetails = createSelector(
  selectUserRolePermDetails,
  (details) => details
);

// type role
export const typeRoleList = createSelector(selectTypeRoleList, (data) => data
);
export const typeRoleFilter = createSelector(
  selectTypeRoleFilter,
  (filter) => filter
);
export const typeRolePermissions = createSelector(
  selectTypeRolePermissions,
  (permissions) => permissions
);
export const typeRoleParmDetails = createSelector(
  selectTypeRolePermDetails,
  (details) => details
);

export const typeRoleDataList = createSelector(selectTypeRoleList, (data) =>
  get(data, "data", []).map((item) => ({
    label: get(item, "roleName", ""),
    value: get(item, "id", null),
  }))
);

// log users
export const logUsers = createSelector(selectLogUsers, (data) => data);
export const filterLogUsers = createSelector(
  selectLogFilter,
  (filter) => filter
);

// select state
export const userListOptions = createSelector(selectUserRoleList, (data) => {
  return get(data, "data", []).map((item) => ({
    label: get(item, "imyaPolzovateli", ""),
    value: get(item, "id", ""),
  }));
});
