import { useEffect } from "react";

import {
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  BaseTable,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  FormButton,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
} from "components";
import { useAppDispatch } from "app/hook";
import { get, isEmpty, isEqual, map } from "lodash";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { checkDateYear, indexNumber, textFormatter } from "utilities/helpers";
import { useTipRasxodConnact } from ".";
import { handleFilterChange } from "app/slices/documentSlices/costsInCashSlice/costsInCashSlice";
import { Tag } from "antd";

enum REKVIZET_NAMES {
  VODITEL = "VODITEL",
  KONTR_AGENT = "KONTR_AGENT",
  DOGOVOR = "DOGOVOR",
  DOG_PRODAJ = "DOG_PRODAJ",
  TERRITORYA = "TERRITORYA",
  STATYA_RASXODA = "STATYA_RASXODA",
}
type PropsType = {
  setIsAdding: any;
  isAdding: boolean;
  documentInfo: any;
  displayMenu: any;
  userName: string;
};
function TipRasxodaTable({
  isAdding,
  setIsAdding,
  documentInfo,
  displayMenu,
  userName,
}: PropsType) {
  const dispatch = useAppDispatch();
  const {
    values,
    allCostsInCash,
    maxAmount,
    drivers,
    kassaOptions,
    typeRasxodaOptions,
    kontragentOptions,
    optionsStatyaZatrat,
    // handle
    handleDeleteDocument,
    handleUndoRecords,
    handleDeleteRecords,
    getOrderDocNumber,
    // sub
    isSubmitting,
    handleUpdateDocument,
    resetForm,
    handleSubmit,
    setFieldValue,
    checkIsAvailable,
    setMaxAmount,
    purchaseContractsOptions,
    getSaleContracts,
    territory,
    LOADING,
    state,
    setState,
    LOADING_KONTRAGENT,
  } = useTipRasxodConnact();

  const handleClose = () => {
    resetForm();
    setMaxAmount(null);
    setIsAdding(false);
  };
  const handleCancel = () => {
    resetForm();
    setMaxAmount(null);
    setState({ isEdit: false });
  };
  const navigateToForm = ({ id }: { id: string | number | null }) => {
    setState({ id, isEdit: true });

    setIsAdding(false);
  };
  useEffect(() => {
    if (isAdding == true) {
      resetForm();
      setMaxAmount(null);
      setState({ isEdit: false });
      // getOrderDocNumber({});
    }
  }, [isAdding]);
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row>
            <Col className="responsive_overflow" style={{ minHeight: "65vh" }}>
              <ContextMenu
                handleNavigate={navigateToForm}
                deleteDocument={handleDeleteDocument}
                handleDeleteRecords={handleDeleteRecords}
                handleUndoRecords={handleUndoRecords}
                openItem={true}
                changeItem={true}
                deleteItem={true}
                markItem={true}
                uncheckItem={true}
                userPermission={"KASSA_DOC"}
                department={"KASSA"}
                documentInfo={documentInfo}
              />
              <BaseTable
                tableHeader={[
                  "№",
                  "Пользователь",
                  "Номер",
                  "Дата",
                  "Касса",
                  "Тип прихода/расхода",
                  "Конрагент/Водитель",
                  "Договор продажи/покупки",
                  "Сумма",
                  "В сумах",
                  "В долларах США",
                  "Комментарии",
                  "Территория",
                  "Статья расхода",
                  "Время записи",
                  "Статус",
                ]}
                className="table_scroll"
              >
                {!LOADING ? (
                  <>
                    {isAdding && (
                      <tr>
                        <td>
                          <Tag color="green">новый</Tag>
                        </td>
                        <td>{userName}</td>
                        <td className="min-w-50">
                          <BaseInput
                            id={"nomerDoc"}
                            name={"nomerDoc"}
                            value={get(values, "nomerDoc", "")}
                          />
                        </td>
                        <td className="min-w-80">
                          <DatePicker
                            id={"dataDoc"}
                            value={get(values, "dataDoc", new Date())}
                            handleDate={(e: any) => {
                              if (
                                checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                })
                              ) {
                                getOrderDocNumber({
                                  year: checkDateYear({
                                    currentYear: get(values, "dataDoc", null),
                                    checkYear: moment(e).format("YYYY-MM-DD"),
                                  }),
                                });
                              }
                              setFieldValue(
                                "dataDoc",
                                moment(e).format("YYYY-MM-DD")
                              );
                            }}
                            maxDate={new Date()}
                            dateFormat="dd.MM.yyyy"
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseSelect
                            id={"kassaId"}
                            value={get(values, "kassaId.value", "")}
                            defaultValue={get(values, "kassaId", "")}
                            options={kassaOptions}
                            handleValueLabel={(e: any) => {
                              setFieldValue("kassaId", e);
                            }}
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseSelect
                            id={"tipRasxodaId"}
                            value={get(values, "tipRasxodaId.value", "")}
                            options={typeRasxodaOptions}
                            defaultValue={get(values, "tipRasxodaId", "")}
                            handleValueLabel={(e: any) => {
                              setFieldValue("tipRasxodaId", e);

                              if (
                                !isEqual(
                                  get(values, "tipRasxodaId.value"),
                                  get(e, "value", "")
                                )
                              ) {
                                setFieldValue("kontragentId", "");
                                setFieldValue("voditeliId", "");
                                setFieldValue("territoriyaId", "");
                                setFieldValue("statyaRasxodaId", "");
                                setFieldValue("dogProdajaId", null);
                                setFieldValue("dogPokupkaId", null);
                                if (
                                  !isEqual(
                                    1,
                                    get(values, "tipRasxodaId.value", null)
                                  ) &&
                                  !isEqual(
                                    2,
                                    get(values, "tipRasxodaId.value", null)
                                  )
                                ) {
                                  setMaxAmount(null);
                                  setFieldValue("summa", "");
                                }
                              }
                            }}
                          />
                        </td>
                        <td className="min-w-80">
                          {checkIsAvailable({
                            tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                            rekvizetName: REKVIZET_NAMES.KONTR_AGENT,
                          }) && (
                            <BaseSelect
                              id={"kontragentId"}
                              isLoading={LOADING_KONTRAGENT}
                              value={get(values, "kontragentId.value", "")}
                              defaultValue={get(values, "kontragentId")}
                              options={kontragentOptions}
                              handleValueLabel={(e: any) => {
                                setFieldValue("kontragentId", e);
                                if (!isEqual(get(values, "kontragentId"), e)) {
                                  setFieldValue("dogProdajaId", null);
                                  setFieldValue("dogPokupkaId", null);
                                  setFieldValue("summa", "");
                                }
                              }}
                            />
                          )}
                          {checkIsAvailable({
                            tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                            rekvizetName: REKVIZET_NAMES.VODITEL,
                          }) && (
                            <>
                              {" "}
                              <BaseSelect
                                id={"voditeliId"}
                                defaultValue={get(values, "voditeliId", "")}
                                value={get(values, "voditeliId.value", "")}
                                options={drivers}
                                handleValueLabel={(e: any) => {
                                  setFieldValue("voditeliId", e);
                                  if (
                                    !isEqual(e, get(values, "voditeliId", ""))
                                  ) {
                                    setFieldValue("summa", "");
                                    setFieldValue("dogProdajaId", null);
                                  }
                                }}
                              />
                            </>
                          )}
                        </td>
                        <td className="min-w-80">
                          {checkIsAvailable({
                            tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                            rekvizetName: REKVIZET_NAMES.DOGOVOR,
                          }) && (
                            <BaseSelect
                              id={"dogPokupkaId"}
                              defaultValue={get(values, "dogPokupkaId", {})}
                              value={get(values, "dogPokupkaId.value", "")}
                              options={purchaseContractsOptions}
                              handleValueLabel={(e: any) => {
                                setFieldValue("dogPokupkaId", e);
                                if (
                                  isEqual(
                                    get(values, "tipRasxodaId.value", null),
                                    4
                                  ) &&
                                  !isEqual(
                                    get(values, "dogPokupkaId.value", null),
                                    e.value
                                  )
                                ) {
                                  setFieldValue("summa", "");
                                }
                              }}
                            />
                          )}
                          {checkIsAvailable({
                            tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                            rekvizetName: REKVIZET_NAMES.DOG_PRODAJ,
                          }) && (
                            <BaseSelect
                              id={"dogProdajaId"}
                              value={get(values, "dogProdajaId.value", "")}
                              defaultValue={get(values, "dogProdajaId", "")}
                              options={getSaleContracts}
                              handleValueLabel={(e: any) => {
                                setFieldValue("dogProdajaId", e);
                                if (
                                  !isEqual(
                                    get(values, "dogProdajaId.value", null),
                                    e.value
                                  )
                                ) {
                                  setFieldValue("summa", "");
                                  setFieldValue("summaInUZS", "");
                                  setFieldValue("summaInUSD", "");
                                }
                              }}
                            />
                          )}
                        </td>
                        <td className="min-w-80">
                          <BaseMaskInput
                            id={"summa"}
                            placeholder={
                              maxAmount !== null
                                ? `до: ${textFormatter(maxAmount)}`
                                : ""
                            }
                            maxLimit={Number(maxAmount)}
                            value={get(values, "summa", "")}
                            handleInput={(e: any) => setFieldValue("summa", e)}
                          />

                          <p>
                            {get(values, "summa", "") > maxAmount &&
                            maxAmount !== null
                              ? `лимит до: ${textFormatter(maxAmount)}`
                              : ""}
                          </p>
                        </td>
                        <td className="min-w-80">
                          <BaseMaskInput
                            id={"summaInUZS"}
                            maxLimit={Number(maxAmount)}
                            value={get(values, "summaInUZS", "")}
                            handleInput={(e: any) =>
                              setFieldValue("summaInUZS", e)
                            }
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseMaskInput
                            id={"summaInUSD"}
                            maxLimit={Number(maxAmount)}
                            value={get(values, "summaInUSD", "")}
                            handleInput={(e: any) =>
                              setFieldValue("summaInUSD", e)
                            }
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseInput
                            id={"kommentariya"}
                            value={get(values, "kommentariya", "")}
                            handleInput={(e: any) =>
                              setFieldValue("kommentariya", e)
                            }
                          />
                        </td>
                        <td className="min-w-80">
                          {checkIsAvailable({
                            tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                            rekvizetName: REKVIZET_NAMES.TERRITORYA,
                          }) && (
                            <BaseSelect
                              id={"territoriyaId"}
                              defaultValue={get(values, "territoriyaId", "")}
                              value={get(values, "territoriyaId.value", "")}
                              options={territory}
                              handleValueLabel={(e: any) => {
                                setFieldValue("territoriyaId", e);
                              }}
                            />
                          )}
                        </td>
                        <td className="min-w-80">
                          {checkIsAvailable({
                            tipRasxodaId: get(values, "tipRasxodaId.value", ""),
                            rekvizetName: REKVIZET_NAMES.STATYA_RASXODA,
                          }) && (
                            <BaseSelect
                              id={"statyaRasxodaId"}
                              defaultValue={get(values, "statyaRasxodaId", "")}
                              value={get(values, "statyaRasxodaId.value", "")}
                              isLoading={optionsStatyaZatrat.loading}
                              options={optionsStatyaZatrat.list}
                              handleValueLabel={(e: any) => {
                                setFieldValue("statyaRasxodaId", e);
                              }}
                            />
                          )}
                        </td>

                        <td>
                          <FlexBox>
                            <FormButton
                              color={"#00c838"}
                              loading={isSubmitting}
                              handleClick={handleSubmit}
                            >
                              &#10004;
                            </FormButton>
                            <FormButton
                              color={"#d80027"}
                              handleClick={handleClose}
                            >
                              &#10006;
                            </FormButton>
                          </FlexBox>
                        </td>
                        <td></td>
                      </tr>
                    )}
                    {!isEmpty(get(allCostsInCash, "data.data", [])) ? (
                      get(allCostsInCash, "data.data", []).map(
                        (item: any, index: any) => {
                          if (
                            isEqual(
                              get(item, "id", null),
                              get(state, "id", null)
                            ) &&
                            get(state, "isEdit", false)
                          ) {
                            return (
                              <tr>
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      allCostsInCash,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      allCostsInCash,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(values, "nomerDoc", "")}</td>
                                <td className="min-w-80">
                                  <DatePicker
                                    id={"dataDoc"}
                                    value={get(values, "dataDoc", new Date())}
                                    handleDate={(e: any) => {
                                      if (
                                        checkDateYear({
                                          currentYear: get(
                                            values,
                                            "dataDoc",
                                            null
                                          ),
                                          checkYear:
                                            moment(e).format("YYYY-MM-DD"),
                                        })
                                      ) {
                                        getOrderDocNumber({
                                          year: checkDateYear({
                                            currentYear: get(
                                              values,
                                              "dataDoc",
                                              null
                                            ),
                                            checkYear:
                                              moment(e).format("YYYY-MM-DD"),
                                          }),
                                        });
                                      }
                                      setFieldValue(
                                        "dataDoc",
                                        moment(e).format("YYYY-MM-DD")
                                      );
                                    }}
                                    maxDate={new Date()}
                                    dateFormat="dd.MM.yyyy"
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseSelect
                                    id={"kassaId"}
                                    value={get(values, "kassaId.value", "")}
                                    defaultValue={get(values, "kassaId", "")}
                                    options={kassaOptions}
                                    handleValueLabel={(e: any) => {
                                      setFieldValue("kassaId", e);
                                    }}
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseSelect
                                    id={"tipRasxodaId"}
                                    value={get(
                                      values,
                                      "tipRasxodaId.value",
                                      ""
                                    )}
                                    options={typeRasxodaOptions}
                                    defaultValue={get(values, "tipRasxodaId")}
                                    handleValueLabel={(e: any) => {
                                      setFieldValue("tipRasxodaId", e);
                                      if (
                                        !isEqual(
                                          get(values, "tipRasxodaId.value"),
                                          get(e, "value", "")
                                        )
                                      ) {
                                        setFieldValue("kontragentId", "");
                                        setFieldValue("dogProdajaId", null);
                                        setFieldValue("dogPokupkaId", null);
                                        setFieldValue("voditeliId", "");
                                        setFieldValue("territoriyaId", "");
                                        setFieldValue("statyaRasxodaId", "");
                                        if (
                                          !isEqual(
                                            1,
                                            get(
                                              values,
                                              "tipRasxodaId.value",
                                              null
                                            )
                                          ) &&
                                          !isEqual(
                                            2,
                                            get(
                                              values,
                                              "tipRasxodaId.value",
                                              null
                                            )
                                          )
                                        ) {
                                          setMaxAmount(null);
                                          setFieldValue("summa", "");
                                        }
                                      }
                                    }}
                                  />
                                </td>
                                <td className="min-w-80">
                                  {checkIsAvailable({
                                    tipRasxodaId: get(
                                      values,
                                      "tipRasxodaId.value",
                                      ""
                                    ),
                                    rekvizetName: REKVIZET_NAMES.KONTR_AGENT,
                                  }) && (
                                    <BaseSelect
                                      id={"kontragentId"}
                                      isLoading={LOADING_KONTRAGENT}
                                      value={get(
                                        values,
                                        "kontragentId.value",
                                        ""
                                      )}
                                      defaultValue={get(values, "kontragentId")}
                                      options={kontragentOptions}
                                      handleValueLabel={(e: any) => {
                                        setFieldValue("kontragentId", e);
                                        if (
                                          !isEqual(
                                            get(values, "kontragentId.value"),
                                            get(e, "value", "")
                                          )
                                        ) {
                                          setFieldValue("dogProdajaId", null);
                                          setFieldValue("dogPokupkaId", null);
                                          setFieldValue("summa", "");
                                          setFieldValue("summaInUZS", "");
                                          setFieldValue("summaInUSD", "");
                                        }
                                      }}
                                    />
                                  )}
                                  {checkIsAvailable({
                                    tipRasxodaId: get(
                                      values,
                                      "tipRasxodaId.value",
                                      ""
                                    ),
                                    rekvizetName: REKVIZET_NAMES.VODITEL,
                                  }) && (
                                    <BaseSelect
                                      id={"voditeliId"}
                                      defaultValue={get(
                                        values,
                                        "voditeliId",
                                        ""
                                      )}
                                      value={get(
                                        values,
                                        "voditeliId.value",
                                        ""
                                      )}
                                      options={drivers}
                                      handleValueLabel={(e: any) => {
                                        setFieldValue("voditeliId", e);
                                        if (
                                          !isEqual(
                                            e,
                                            get(values, "voditeliId", "")
                                          )
                                        ) {
                                          setFieldValue("summa", "");
                                          setFieldValue("summaInUZS", "");
                                          setFieldValue("summaInUSD", "");
                                        }
                                      }}
                                    />
                                  )}
                                </td>
                                <td className="min-w-80">
                                  {checkIsAvailable({
                                    tipRasxodaId: get(
                                      values,
                                      "tipRasxodaId.value",
                                      ""
                                    ),
                                    rekvizetName: REKVIZET_NAMES.DOGOVOR,
                                  }) && (
                                    <>
                                      <BaseSelect
                                        defaultValue={get(
                                          values,
                                          "dogPokupkaId"
                                        )}
                                        value={get(
                                          values,
                                          "dogPokupkaId.value",
                                          ""
                                        )}
                                        options={purchaseContractsOptions}
                                        handleValueLabel={(e: any) => {
                                          setFieldValue("dogPokupkaId", e);
                                          if (
                                            isEqual(
                                              get(
                                                values,
                                                "tipRasxodaId.value",
                                                null
                                              ),
                                              4
                                            ) &&
                                            !isEqual(
                                              get(
                                                values,
                                                "dogPokupkaId.value",
                                                null
                                              ),
                                              e.value
                                            )
                                          ) {
                                            setFieldValue("summa", "");
                                            setFieldValue("summaInUZS", "");
                                            setFieldValue("summaInUSD", "");
                                          }
                                        }}
                                      />
                                    </>
                                  )}
                                  {checkIsAvailable({
                                    tipRasxodaId: get(
                                      values,
                                      "tipRasxodaId.value",
                                      ""
                                    ),
                                    rekvizetName: REKVIZET_NAMES.DOG_PRODAJ,
                                  }) && (
                                    <BaseSelect
                                      id={"dogProdajaId"}
                                      value={get(
                                        values,
                                        "dogProdajaId.value",
                                        ""
                                      )}
                                      defaultValue={get(
                                        values,
                                        "dogProdajaId",
                                        ""
                                      )}
                                      options={getSaleContracts}
                                      handleValueLabel={(e: any) => {
                                        setFieldValue("dogProdajaId", e);
                                        if (
                                          !isEqual(
                                            get(
                                              values,
                                              "dogProdajaId.value",
                                              null
                                            ),
                                            e.value
                                          )
                                        ) {
                                          setFieldValue("summa", "");
                                          setFieldValue("summaInUZS", "");
                                          setFieldValue("summaInUSD", "");
                                        }
                                      }}
                                    />
                                  )}
                                </td>
                                <td className="min-w-80">
                                  <BaseMaskInput
                                    id={"summa"}
                                    placeholder={
                                      maxAmount !== null
                                        ? `до: ${textFormatter(maxAmount)}`
                                        : ""
                                    }
                                    maxLimit={Number(maxAmount)}
                                    value={get(values, "summa", "")}
                                    handleInput={(e: any) =>
                                      setFieldValue("summa", e)
                                    }
                                  />
                                  <p>
                                    {get(values, "summa", "") > maxAmount &&
                                    maxAmount !== null
                                      ? `лимит до: ${textFormatter(maxAmount)}`
                                      : ""}
                                  </p>
                                </td>
                                <td className="min-w-80">
                                  <BaseMaskInput
                                    id={"summaInUZS"}
                                    maxLimit={Number(maxAmount)}
                                    value={get(values, "summaInUZS", "")}
                                    handleInput={(e: any) =>
                                      setFieldValue("summaInUZS", e)
                                    }
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseMaskInput
                                    id={"summaInUSD"}
                                    maxLimit={Number(maxAmount)}
                                    value={get(values, "summaInUSD", "")}
                                    handleInput={(e: any) =>
                                      setFieldValue("summaInUSD", e)
                                    }
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseInput
                                    value={get(values, "kommentariya", "")}
                                    handleInput={(e: any) =>
                                      setFieldValue("kommentariya", e)
                                    }
                                  />
                                </td>
                                <td className="min-w-80">
                                  {checkIsAvailable({
                                    tipRasxodaId: get(
                                      values,
                                      "tipRasxodaId.value",
                                      ""
                                    ),
                                    rekvizetName: REKVIZET_NAMES.TERRITORYA,
                                  }) && (
                                    <BaseSelect
                                      id={"territoriyaId"}
                                      defaultValue={get(
                                        values,
                                        "territoriyaId",
                                        ""
                                      )}
                                      value={get(
                                        values,
                                        "territoriyaId.value",
                                        ""
                                      )}
                                      options={territory}
                                      handleValueLabel={(e: any) => {
                                        setFieldValue("territoriyaId", e);
                                      }}
                                    />
                                  )}
                                </td>
                                <td className="min-w-80">
                                  {checkIsAvailable({
                                    tipRasxodaId: get(
                                      values,
                                      "tipRasxodaId.value",
                                      ""
                                    ),
                                    rekvizetName: REKVIZET_NAMES.STATYA_RASXODA,
                                  }) && (
                                    <BaseSelect
                                      id={"statyaRasxodaId"}
                                      defaultValue={get(
                                        values,
                                        "statyaRasxodaId",
                                        ""
                                      )}
                                      value={get(
                                        values,
                                        "statyaRasxodaId.value",
                                        ""
                                      )}
                                      isLoading={optionsStatyaZatrat.loading}
                                      options={optionsStatyaZatrat.list}
                                      handleValueLabel={(e: any) => {
                                        setFieldValue("statyaRasxodaId", e);
                                      }}
                                    />
                                  )}
                                </td>

                                <td>
                                  <FlexBox>
                                    <FormButton
                                      color={"#00c838"}
                                      loading={isSubmitting}
                                      handleClick={handleUpdateDocument}
                                    >
                                      &#10004;
                                    </FormButton>
                                    <FormButton
                                      color={"#d80027"}
                                      handleClick={handleCancel}
                                    >
                                      &#10006;
                                    </FormButton>
                                  </FlexBox>
                                </td>
                                <td></td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr
                                key={get(item, "nomerDoc", index + 1)}
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      allCostsInCash,
                                      "data.pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      allCostsInCash,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaKassa", "")}</td>
                                <td>{get(item, "imyaTipRasxoda", "")}</td>
                                <td
                                  title={
                                    (get(item, "kommentariya", ""),
                                    get(item, "imyaVoditeli", ""))
                                  }
                                  className={"max-w-20"}
                                >
                                  {get(item, "imyaKontragent", "")}
                                  {get(item, "imyaVoditeli", "")}
                                </td>
                                <td>
                                  {get(item, "nomerDogProdaja", "")}{" "}
                                  {get(item, "nomerDogPokupka", "")}
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summa", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summaInUZS", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summaInUSD", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td
                                  title={get(item, "kommentariya", "")}
                                  className={"max-w-20"}
                                >
                                  {get(item, "kommentariya", "")}
                                </td>
                                <td>{get(item, "imyaTerritoriya", "")}</td>
                                <td
                                  title={get(item, "imyaStatyaRasxoda", "")}
                                  className={"max-w-20"}
                                >
                                  {get(item, "imyaStatyaRasxoda", "")}
                                </td>

                                <td>
                                  {moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            );
                          }
                        }
                      )
                    ) : (
                      <Nodata colLength={16} />
                    )}
                  </>
                ) : (
                  map([...new Array(14)], (itme: any, index: number) => (
                    <tr key={index + 1}>
                      <LoadingTable
                        width={index % 2 == 0 ? "50px" : "70px"}
                        tblDTNumber={16}
                      />
                    </tr>
                  ))
                )}
              </BaseTable>
            </Col>
          </Row>
          {get(allCostsInCash, "data.pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(allCostsInCash, "data.pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChange({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    allCostsInCash,
                    "data.pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(
                    allCostsInCash,
                    "data.pagination.totalPages",
                    0
                  )}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChange({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default TipRasxodaTable;
