import styled from "styled-components";
import { StyleProps } from "./type";
export const FormInputWrapper = styled.div<StyleProps>`
    label {
        font-weight: 500;
        font-size: 14px;
        color: #1c1c1c;
        z-index: 50;
    }

`;
