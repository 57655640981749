import { useAppDispatch, useAppSelector } from "app/hook";
import { filterChangeReturnProduct } from "app/slices/documentSlices/returnProductsSlices/returnProductSlices";
import {
  returnProductBefRealFilter,
  returnProductBefRealList,
  returnProductBefRealStat,
  saleTempsReturnProductBefRralList,
} from "app/states/documents";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import delate from "assets/images/icons/delate.svg";
import {
  clearAllReturnProductBefReal,
  clearFilterBefReal,
  fetchNotFilledReturnProductBefReal,
  fetchReturnProductBefReal,
  fetchReturnProductBefRealStat,
  filterChangeReturnProductBefReal,
} from "app/slices/documentSlices/returnProductBefRealSlices/returnProductBefRealSlices";
import { getProductData } from "app/states/handbooks/selectStates";
import HasAccess from "routers/HasAccess";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";

//  Возврат некондиционного товара (до реал.)
const ReturnSubstandardProductBefRealContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_RETURN_PRODUCT_BEFORE_REAL
  );
  const returnProduct = useAppSelector(returnProductBefRealList);
  const tempReturnProduct = useAppSelector(saleTempsReturnProductBefRralList);
  const filter = useAppSelector(returnProductBefRealFilter);
  const countStat = useAppSelector(returnProductBefRealStat);
  const LOADING_TABLE = get(returnProduct, "loading", []);
  const tovariiOptions = useAppSelector(getProductData);
  const contractorDropdowns = useAppSelector(contractorOptions);
  let loadingKontragent = useAppSelector(loadingConterpart);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchReturnProductFunction = useCallback(() => {
    dispatch(fetchReturnProductBefReal(filter));
    dispatch(fetchReturnProductBefRealStat());
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchReturnProductBefReal({
        ...filter,
        tovarId: null,
        fromDate: null,
        toDate: null,
        kontragentId: null,
      })
    );
    dispatch(clearFilterBefReal());
  }, [dispatch, filter]);

  const fetchNotFilledReturnProductFun = useCallback(() => {
    dispatch(fetchNotFilledReturnProductBefReal());
    dispatch(fetchReturnProductBefRealStat());
  }, [dispatch]);

  const navigateForm = (params: { id: string | number; isTemp: boolean }) => {
    navigate(Documents.ADD_RETURN_PRODUCT_BEFORE_REAL, {
      state: params,
    });
  };

  const handleAddButton = () => {
    dispatch(clearAllReturnProductBefReal());
    navigate(Documents.ADD_RETURN_PRODUCT_BEFORE_REAL);
  };

  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteReturnProductBefReal(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchReturnProductFunction();
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsReturnProductBefReal(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchReturnProductFunction();
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsReturnProductBefReal(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchReturnProductFunction();
      }
    });
  };

  const handleTempButton = (id: string | null) => {
    DocumentServices.RemoveTempsReturnProductBefReal(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchReturnProductFunction();
        fetchNotFilledReturnProductFun();
      }
    });
  };

  useEffect(() => {
    fetchNotFilledReturnProductFun();
    dispatch(fetchAllCounterpart({ all: true }));
    return () => {
      handleClearFilter();
    };
  }, []);

  useEffect(() => {
    fetchReturnProductFunction();
  }, [filter.page, filter.size]);

  // for adding  new form
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={countStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger handleClick={handleClearFilter} className="mr-16">
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "VOZVRAT_TOV_OGP_CREATE",
                    page: "VOZVRAT_TOV_OGP",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  placeholder={"Контрагент"}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  isLoading={loadingKontragent}
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeReturnProductBefReal({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  isClearable
                  options={tovariiOptions}
                  placeholder="Товар"
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeReturnProductBefReal({
                        name: "tovarId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "tovarId", null)}
                />
                <DatePicker
                  isClearable
                  width="200px"
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeReturnProductBefReal({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  isClearable
                  width="200px"
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeReturnProductBefReal({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={2}>
              <Button primary handleClick={fetchReturnProductFunction}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateForm}
                  deleteDocument={handleDeleteButton}
                  deleteItem={true}
                  markItem={true}
                  uncheckItem={true}
                  userPermission={"VOZVRAT_TOV_OGP"}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Док. основания для возврата товара",
                    "Территория",
                    "Склад готовой продукции",
                    "Контрагент",
                    "Заказ-наряд",
                    "Договор",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      <>
                        {!isEmpty(get(tempReturnProduct, "data", [])) &&
                          get(tempReturnProduct, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", "")}
                                onClick={() =>
                                  navigateForm({
                                    isTemp: true,
                                    id: get(item, "id", ""),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  <div
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      handleTempButton(get(item, "id", null));
                                    }}
                                    className="delete_button"
                                  >
                                    <ReactSVG
                                      src={delate}
                                      className="delete_button"
                                    />
                                  </div>
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "-")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "nomerDocOsnovanie", "")}</td>
                                <td>{get(item, "imyaTerritoriya", "")}</td>
                                <td>{get(item, "imyaSkladaGP", "")}</td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "nomerZakazNaryad", "")}</td>
                                <td>{get(item, "nomerDogProdaja", "")}</td>
                                <td>-</td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )}
                      </>
                      {!isEmpty(get(returnProduct, "data", []))
                        ? get(returnProduct, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", "")}
                                onClick={() =>
                                  navigateForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      returnProduct,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      returnProduct,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "-")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "nomerDocOsnovanie", "")}</td>
                                <td>{get(item, "imyaTerritoriya", "")}</td>
                                <td>{get(item, "imyaSkladaGP", "")}</td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "nomerZakazNaryad", "")}</td>
                                <td>{get(item, "nomerDogProdaja", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(tempReturnProduct, "data", [])) && (
                            <Nodata colLength={13} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={13}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(returnProduct, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    filterChangeReturnProductBefReal({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(returnProduct, "pagination.currentPageNumber", 0)}
                pageCount={get(returnProduct, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatch(
                    filterChangeReturnProductBefReal({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default ReturnSubstandardProductBefRealContainer;
