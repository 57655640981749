import { get, isEqual } from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function ReportOnDriversTable({ data, click }: any) {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper className="mb-16">
              <thead>
                <tr>
                  <th style={{ maxWidth: "10px" }}>№</th>
                  <th style={{ width: "200px" }}>Водитель</th>
                  {click ? (
                    <>
                      <th>Территория</th>
                      <th>Номер документа</th>
                      <th>Дата документа</th>
                      <th style={{ width: "200px" }}>Заказчик</th>
                      <th>Заказ-наряд</th>
                      <th style={{ width: "200px" }}>Адрес доставки</th>
                      <th>Автомобиль</th>

                    </>
                  ) : null}
                  <th style={{ width: "100px" }}>Количество отгруженного бетона (м3)</th>
                  <th style={{ width: "100px" }}>Количество рейсов</th>

                </tr>
              </thead>
              <tbody>
                <>
                  {get(data, "dataList", []) ? (
                    get(data, "dataList", []).map(
                      (item: any, index: number) => (
                        <>
                          <tr key={index + 1}>
                            <th>{index + 1}</th>
                            <td>
                              {get(item, "imyaVoditeli", "-")}
                            </td>
                            {click ? (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            ) : null}
                            <td>
                              {
                                isEqual(get(item, "kolBetona", 0), 0) ? "-" :
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "kolBetona", 0)}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                              }
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "sumKols", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                          </tr>

                          {click && get(item, "details", [])
                            ? get(item, "details", []).map(
                              (detail: any, index: number) => (
                                <tr
                                  key={index + 1}
                                  style={{ backgroundColor: "#f4f4f4" }}
                                >
                                  <td></td>
                                  <td></td>
                                  <td>{get(detail, "territoriya", "")}</td>
                                  <td>{get(detail, "nomerDoc", "")} </td>
                                  <td>
                                    {moment(
                                      get(detail, "dataDoc", "")
                                    ).format("DD.MM.YYYY")}
                                  </td>
                                  <td>
                                    {get(detail, "zakazchik", "")}
                                  </td>
                                  <td>{get(detail, "zakazNaryad", "")}</td>
                                  <td>{get(detail, "addressDostavki", "")}</td>
                                  <td>{get(detail, "avtomobil", "")}</td>
                                  <td>
                                    {
                                      isEqual(get(item, "kolBetona", 0), 0) ? "-" :
                                        <NumericFormat
                                          displayType="text"
                                          value={get(detail, "kolBetona", 0)}
                                          thousandSeparator
                                          decimalScale={2}
                                        />
                                    }
                                  </td>
                                  <td>
                                    <NumericFormat
                                      displayType="text"
                                      value={get(detail, "kolReys", 0)}
                                      thousandSeparator
                                      decimalScale={2}
                                    />
                                  </td>
                                </tr>
                              )
                            )
                            : null}
                        </>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={10}>Виберите параметры</td>
                    </tr>
                  )}
                </>
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ReportOnDriversTable;
