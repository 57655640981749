import { Breadcrumb, FlexBox, Sidebar, SidebarItem } from "components";
import { get, isEqual } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Main, Registers, Users } from "routers/paths";
import { sideBarMenus } from "app/states/menu";
import { useAppDispatch, useAppSelector } from "app/hook";
import { ReactSVG } from "react-svg";
import closeMenu from "assets/images/icons/close_menu.svg";
import menuuIcon from "assets/images/icons/menu.svg";
import { SidebarItemWrapper } from "components/SidebarItem/style";
import { Layout } from 'antd';
const UsersInnerLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const sideBar = useAppSelector((store) =>
        sideBarMenus(store, Main.USERS)
    );
    //  antd layout
    const [collapsed, setCollapsed] = useState(false);
    const { Content, Sider } = Layout;

    const navigateToFirstSidebar = useCallback(() => {
        if (isEqual(get(location, "pathname", ""), `/${Main.USERS}`)) {
            navigate(Users.USER_ROLE, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        navigateToFirstSidebar();
    }, [navigateToFirstSidebar]);

    return (
        <Fragment>
            <Layout
                style={{
                    padding: 0,
                    fontFamily: "Arial, Helvetica, sans-serif",
                    zIndex: 1000,
                    left: 0,
                    width: '100%',
                    position: "fixed",
                }}
            >
                <Row align="center">
                    {
                        !collapsed &&
                        <Col xs={2.6} style={{ textAlign: "end", paddingRight: 0 }}>
                            <ReactSVG
                                src={closeMenu}
                                className="mt-8 ml-8"
                                onClick={() => setCollapsed(!collapsed)}
                            />
                        </Col>
                    }
                    <Col xs={9.4} >
                        <FlexBox>
                            {
                                collapsed &&
                                <ReactSVG
                                    src={menuuIcon}
                                    className="svg_icon mr-16 ml-16"
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                            }
                            <Breadcrumb style={{ margin: '12px 0' }} />
                        </FlexBox>
                    </Col>
                </Row>
            </Layout>
            <Layout style={{ marginTop: "50px" }}>
                <Sider collapsible
                    style={{
                        background: "inherit",
                        marginBottom: "10px",
                    }}
                    className='sidebar'
                    trigger={null}
                    width={"43vh"}
                    collapsedWidth={0}
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}>

                    <Sidebar className="component_animate" style={collapsed ? { display: "none" } : { display: "flex" }} >
                        {sideBar?.map((item: any) => (
                            <SidebarItemWrapper>
                                <SidebarItem
                                    key={item.id}
                                    title={get(item, "name", "")}
                                    url={get(item, "id", "")}
                                    icon={get(item, "icon", "")}

                                />
                            </SidebarItemWrapper>
                        ))}
                    </Sidebar>
                </Sider>
                <Content
                    style={{
                        padding: ' 0',
                        fontFamily: "Arial, Helvetica, sans-serif"
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Fragment>
    );
};

export default UsersInnerLayout;