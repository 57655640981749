import styled from "styled-components";
import { StyleProps } from "./type";
export const CheckBoxContainer = styled.div`
    position: relative;
    cursor: pointer;
    /* width: 100%; */
`;

export const CheckBoxInput = styled.input.attrs({
    type: "checkbox",
})`

    position: absolute;
    opacity: 0;
   background-color: ${({ value }: any) => value ? "#0085FF" : "#FFFFFF"};

    cursor: pointer;

`;

export const CheckBoxLabel = styled.label<StyleProps>`
    font-weight: 400;
    font-size: ${({ fontSize }: StyleProps) => fontSize || "16px"};
    cursor: pointer;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #ffff;
    &:hover input ~ span {
        background-color: rgb(235, 235, 235, 0.6);
    }
    
    input:checked ~ span {
        /* background-color: ${({ value }: any) => value || "#0085FF"}; */
        background-color: ${({ color }: StyleProps) => color || "#0085FF"};
        border:solid 2px #0085FF;
        border-radius: 3px;
    }
    input:checked ~ span::after {
        display: block;
    }

`;


export const CustomCheckBox = styled.span`
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 3px;
    border: solid 2px #585757;

    &:after {
        top: 1px;
        width: 5px;
        height: 10px;
        position: absolute;
        margin: 0;
        top: 9%;
        left: 33%;
        transform: translate(-50%, -50%);
        border: solid  1px ;
        border-width: 0 2px 2px 0 ;
        transform: rotate(45deg);
        content: "";
        position: absolute;
        display: none;
        color:"black";
    }
`;