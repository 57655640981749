import styled from "styled-components";
import { StyleProps } from "./type";

const FlexBoxContainer = styled.div`
  display: flex;
  align-items: ${({ align }: StyleProps) => align || "center"};
  justify-content: ${({ justify }: StyleProps) => justify || "flex-start"};
  flex-direction: ${({ column }: StyleProps) => (column ? "column" : "row")};
  gap: ${({ gap }: StyleProps) => gap || 0};
`;

export { FlexBoxContainer };
