import { Title } from "components";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function RegisterPurchasesMaterialsTable({ data }: any) {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper minWidth="1200px" className="mb-16">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Номер договора</th>
                  <th>Дата договора</th>
                  <th style={{ minWidth: "200px" }}>Заказчик</th>
                  <th>Дата испол.</th>
                  <th>Способ оплаты</th>
                  <th>Условия оплаты</th>
                  <th>Способ доставки</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                {get(data, "dataList.list", []) ? (
                  get(data, "dataList.list", []).map(
                    (item: any, index: number) => (
                      <tr key={index + 1}>
                        <th>{index + 1}</th>
                        <td>{get(item, "nomerDoc", "")}</td>
                        <td>
                          {moment(get(item, "dataDoc", "")).format(
                            "DD.MM.YYYY"
                          )}
                        </td>
                        <td>{get(item, "imyaKontragent", "")}</td>
                        <td>
                          {moment(get(item, "dataIsp", "")).format(
                            "DD.MM.YYYY"
                          )}
                        </td>
                        <td>{get(item, "sposobOplataName", "")}</td>
                        <td>{get(item, "imyaUsloviyaOplati", "")}</td>
                        <td>{get(item, "sposobDostavkaName", "")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summaDog", 0)}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={9}>Виберите параметры</td>
                  </tr>
                )}
                <tr>
                  <td colSpan={8} style={{ textAlign: "start" }}>
                    <Title bold>Итого:</Title>
                  </td>

                  <td>
                    <Title bold mk>
                      <NumericFormat
                        displayType="text"
                        value={get(data, "dataList.itogo.summa", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </Title>
                  </td>
                </tr>
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default RegisterPurchasesMaterialsTable;
