import React, { useState, useEffect } from "react";
import * as Diff from "jsondiffpatch";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";
interface JSONDiffViewerProps {
  original: string;
}

const JSONDiffViewer: React.FC<JSONDiffViewerProps> = ({ original }) => {
  const [diff, setDiff] = useState<any>(null);

  useEffect(() => {
    setDiff(JSON.parse(original));
  }, [original]);

  return (
    <div>
      {diff && (
        <SyntaxHighlighter language="json" style={coldarkDark}>
          {JSON.stringify(diff, null, 2)}
        </SyntaxHighlighter>
      )}
    </div>
  );
};

export default JSONDiffViewer;
