import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import ProfileServices from "services/apiServices/profile";
import authSlices from "../authSlices/authSlices";

const initialState: any = {
    loading: false,
    profileData: {},
    permissionList: [],
    error: "",
};

export const fetchProfileInfo = createAsyncThunk(
    "my-account/fetchProfileInfo",
    async (_, thunkAPI) => {
        const request = await ProfileServices.FetchProfile();
        if (get(request, "status", "") != 200) {
            return thunkAPI.rejectWithValue(get(request, "resultMsg", ""));
        }
        const respond = await request.data;
        const { data } = await respond;
        return { data };
    }
);

const profileSlices = createSlice({
    name: "profile",
    initialState,
    reducers: {
        updateDocumentList: (state, action) => {
            state.profileData = {
                ...state.profileData,
                documentList: action.payload
            }
        },
        clearProfile: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfileInfo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProfileInfo.fulfilled, (state, actions) => {
            state.loading = false;
            state.error = "";
            state.profileData = get(actions, "payload.data", "");

        });
        builder.addCase(fetchProfileInfo.rejected, (state, actions) => {
            state.loading = false;
            state.profileData = {};
            state.error = get(actions, "error.message", "");
        });

    },
});
export const { clearProfile, updateDocumentList } = profileSlices.actions;
export default profileSlices.reducer;
