import styled from "styled-components";
import { NumericFormat } from "react-number-format";
import { StyleProps } from "./type";
export const FormatInputWrapper = styled(NumericFormat)<StyleProps>`
  background-color: ${({ disabled }: StyleProps) => disabled || "#fff"};
  border: 1px solid
    ${({ maxValue }: any) => (maxValue ? "#e64353" : "hsl(0, 0%, 80%)")};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 8px 12px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  margin: ${({ margin }: StyleProps) => margin || "0px"};
  color: #1c1c1c;
  display: inline-block;

  /* min-width: ${({ width }: StyleProps) => width || "auto"}; */
  min-height: 36px;
  width: 100%;
  width: ${({ width }: StyleProps) => width || "100%"};
  max-width: ${({ width }: StyleProps) => width || "320px"};

  &::placeholder {
    color: #979797;
  }
`;
