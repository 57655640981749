import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import ReportsServices from "services/apiServices/reports";
import moment from "moment";

const initialState: InitialState = {
  data: { loading: false, operationalList: null, error: "" },
  filter: {
    tipMaterial:null,
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    skladId: null,
  },
};

export const fetchOperationalReport = createAsyncThunk(
  "operationalReport/fetchOperationalReport",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchOperationalReportTable(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const operationalReportSlices = createSlice({
  name: "operationalReport",
  initialState,
  reducers: {
    filterOperationalTable: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearOperationalTable: () => initialState,
    changeOperanationalTable: (state, action) => {
      state.data = {
        loading: false,
        operationalList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperationalReport.pending, (state) => {
      state.data = {
        loading: true,
        operationalList: [],
        error: "",
      };
    });
    builder.addCase(fetchOperationalReport.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        operationalList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchOperationalReport.rejected, (state, payload) => {
      state.data = {
        loading: false,
        operationalList: [],
        error: payload.error.message,
      };
    });
  },
});

export const { filterOperationalTable, clearOperationalTable, changeOperanationalTable } = operationalReportSlices.actions;
export default operationalReportSlices.reducer;
