import FlexBox from "components/FlexBox/FlexBox";
import Select from "components/BaseSelect/BaseSelect";
import styled from "styled-components";

const ShowPageWrapper = styled(FlexBox)`
    width: 300px;
    gap:5px;
`;

const PageSelector = styled(Select).attrs({
    width: "75px",
})``;

export { ShowPageWrapper, PageSelector };
