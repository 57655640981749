import { useAppDispatch, useAppSelector } from "app/hook";
import { useEffect, useCallback, useState, useMemo } from "react";
import {
  arrivedCash,
  arrivedCashFilter,
  costsInCash,
} from "app/states/documents";
import {
  fetchAllArrivedCashes,
  fetchAllArrivedCashesStat,
  fetchRemainder,
} from "app/slices/documentSlices/arrivedCashesSlice/arrivedCashesSlice";
import DocumentServices from "services/apiServices/documents";
import { forEach, get, isEmpty, isEqual, isObject } from "lodash";
import { toast } from "react-toastify";
import { CONTEXT, MESSAGES } from "utilities/constants";
import { fetchOwnKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { fetchIncomes } from "app/slices/enumerationsSlices/incomeSlices/incomeSlices";
import { useFormik } from "formik";
import { fetchSalesContracts } from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import {
  clearContractorsData,
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import moment from "moment";
import {
  getKassaData,
  getTerritoriesData,
} from "app/states/handbooks/selectStates";
import {
  tipPrixodaOptions,
  tipRasxodaOptions,
} from "app/states/enumerations/selectStates";
import {
  contractorOptions,
  getOnlyContractorIdOptions,
  loadingConterpart,
  purchaseContractOptionsById,
} from "app/states/documents/selectStates";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import { useContextMenu } from "react-contexify";
import { useKassaSystem } from "modules/kassa/connacts";
import { NumberDocType } from "types/documentsType";
const useConnect = () => {
  const arrivedCashes = useAppSelector(arrivedCash);
  const { hideAll } = useContextMenu();
  const dispatch = useAppDispatch();
  const [changeTipKassa, setChangeTipKassa] = useState(1);
  const [state, setState] = useState<{
    isEdit?: boolean;
    id?: string | number | null;
  }>({
    isEdit: false,
    id: null,
  });
  const kassaOptions = useAppSelector(getKassaData);
  const typeRasxodaOptions = useAppSelector(tipRasxodaOptions);
  const kontragentOptions = useAppSelector(contractorOptions);
  const typePrixodaOptions = useAppSelector(tipPrixodaOptions);
  const filter = useAppSelector(arrivedCashFilter);
  const territoryOption = useAppSelector(getTerritoriesData);
  const [maxAmount, setMaxAmount] = useState<any>(0);
  const { setIsAdding } = useKassaSystem();
  // loading
  let LOADING_PRIXOD = get(arrivedCashes, "loading", []);
  let LOADING_KONTRAGENTS = useAppSelector(loadingConterpart);
  // fetch number
  const getDocNumber = useCallback(
    (date: NumberDocType) => {
      DocumentServices.ArrivalCashNumber(date).then(({ data }) => {
        setFieldValue("nomerDoc", get(data, "data", ""));
      });
    },
    [state]
  );

  const onSubmit = (values: any) => {
    const params: any = {};
    forEach(values, (value, key) => {
      if (value) {
        if (key == "dataDoc" || !isObject(value)) {
          params[key] = value;
        } else {
          params[key] = get(value, "value", "");
        }
      }
    });
    try {
      DocumentServices.AddArrivalCash({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          dispatch(fetchAllArrivedCashes(filter));
          dispatch(fetchAllArrivedCashesStat());
          toast.success(MESSAGES.ADDED);
          setSubmitting(false);
          resetForm();
          setMaxAmount(null);
          dispatch(fetchRemainder());
          getDocNumber({});
          setIsAdding(false);
        }
      });
    } catch (err) {
      console.log("err", err);
      setSubmitting(false);
    }
  };

  const initialValues = {
    nomerDoc: "",
    dataDoc: moment(new Date()).format("YYYY-MM-DD"),
    kassaId: "",
    imyaKassa: "",
    tipPrixodaId: "",
    kontragentId: "",
    dogProdajaId: "",
    zakazNaryadId: "",
    imyaKontragent: "",
    summa: "",
    summaInUZS: "",
    summaInUSD: "",
    kommentariya: "",
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });
  const purchaseContractsOptions = useAppSelector((store) =>
    purchaseContractOptionsById(store, get(values, "kontragentId", ""))
  );
  const getSaleContracts = useAppSelector((store) =>
    getOnlyContractorIdOptions(store, get(values, "kontragentId.value", null))
  );
  // handle update
  const handleUpdateDocument = useCallback(() => {
    setSubmitting(true);
    const params: any = {};
    forEach(values, (value, key) => {
      if (value) {
        if (key == "dataDoc" || !isObject(value)) {
          params[key] = value;
        } else {
          params[key] = get(value, "value", "");
        }
      }
    });
    try {
      DocumentServices.UpdateAddArrivalCash({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...params,
          id: get(state, "id", null),
          kommentariya: get(values, "kommentariya", null),
        },
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          dispatch(fetchAllArrivedCashes(filter));
          toast.success(MESSAGES.ADDED);
          setSubmitting(false);
          setMaxAmount(null);
          dispatch(fetchRemainder());
          setState({ isEdit: false });
        }
      });
    } catch (err) {
      console.log("err", err);
      setSubmitting(false);
    }
  }, [dispatch, values]);
  // submit not fully filled form
  const handleSetInitialValues = (data: any) => {
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
    setFieldValue("kassaId", {
      value: get(data, "kassaId", ""),
      label: get(data, "imyaKassa", ""),
    });
    setFieldValue("tipPrixodaId", {
      value: get(data, "tipPrixodaId", ""),
      label: get(data, "imyaTipPrixoda", ""),
    });
    setFieldValue("kontragentId", {
      value: get(data, "kontragentId", ""),
      label: get(data, "imyaKontragent", ""),
    });
    setFieldValue("dogProdajaId", {
      value: get(data, "dogProdajaId", ""),
      label: get(data, "nomerDogProdaja", ""),
    });
    setFieldValue("summa", get(data, "summa", ""));
    setFieldValue("summaInUZS", get(data, "summaInUZS", ""));
    setFieldValue("summaInUSD", get(data, "summaInUSD", ""));

    setFieldValue("kommentariya", get(data, "kommentariya", ""));
  };
  // check details
  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isUpdate", false)) {
        DocumentServices.FetchArrivalCashesById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
            }
          }
        );
      } else {
        DocumentServices.FetchArrivalCashesById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
            }
          }
        );
      }
    } else {
      getDocNumber({});
    }
  }, [dispatch, state]);

  const fetchPriceByKontragent = useCallback(() => {
    try {
      if (
        get(values, "kontragentId.value", null) &&
        isEqual(get(values, "tipPrixodaId.value", ""), 3)
      ) {
        DocumentServices.FetchPriceByArrivalCash({
          id: get(values, "kontragentId.value", null),
          pkoId: get(state, "id", null),
        }).then(({ data }) => {
          if (data) {
            setMaxAmount(data.data);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [values.kontragentId]);
  const fetchPriceWidthDogovorId = useCallback(() => {
    try {
      if (
        get(values, "kontragentId.value", null) &&
        get(values, "dogProdajaId.value", null) &&
        isEqual(get(values, "tipPrixodaId.value", ""), 1)
      ) {
        const props = {
          kontragentId: get(values, "kontragentId.value"),
          dogProdajaId: get(values, "dogProdajaId.value", null),
          pkoId: get(state, "id", null),
        };
        DocumentServices.FetchPriceByArrivalCashWidthDog(props).then(
          ({ data }) => {
            if (data) {
              setMaxAmount(data.data);
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [values.kontragentId, values.dogProdajaId]);
  const fetchDogDetails = useCallback(() => {
    if (get(values, "tipPrixodaId.value", "")) {
      dispatch(clearContractorsData());
      switch (get(values, "tipPrixodaId.value", "").toString()) {
        case "1":
          dispatch(fetchAllCounterpart({ all: true, type: 5 }));
          dispatch(
            fetchSalesContracts({
              all: true,
              filterPage: "PRIXOD_D",
              kontragentId: get(values, "kontragentId.value", null),
            })
          );
          break;
        case "2":
          dispatch(fetchAllCounterpart({ all: true, type: 2 }));
          break;
        case "3":
          dispatch(
            fetchAllCounterpart({ all: true, type: 1, filterPage: "PRIXOD_D" })
          );
          break;
        default:
          break;
      }
    }
  }, [values.tipPrixodaId, values.kontragentId]);

  const fetchFormSelectorOptions = useCallback(() => {
    dispatch(fetchOwnKassa({ all: true }));
    dispatch(fetchIncomes({}));
  }, [dispatch]);
  // new

  // end start...
  useEffect(() => {
    fetchPriceWidthDogovorId();
  }, [fetchPriceWidthDogovorId]);
  useEffect(() => {
    // if (isEmpty(get(state, "id", null))) {
    fetchFormSelectorOptions();
    // }
  }, [fetchFormSelectorOptions]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    fetchDogDetails();
  }, [fetchDogDetails]);

  useEffect(() => {
    fetchPriceByKontragent();
  }, [fetchPriceByKontragent]);
  // other
  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeleteArrivalCashes(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchAllArrivedCashes(filter));
        dispatch(fetchAllArrivedCashesStat());
        dispatch(fetchRemainder());
      }
    });
  };
  // status
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DelteRedordsArrivalCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllArrivedCashes(filter));
        dispatch(fetchRemainder());
        resetForm();
        setMaxAmount(null);
        getDocNumber({});
      }
    });
  };
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsArrivalCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllArrivedCashes(filter));
        dispatch(fetchAllArrivedCashesStat());
        dispatch(fetchRemainder());
        resetForm();
        setMaxAmount(null);
        getDocNumber({});
      }
    });
  };

  return {
    arrivedCashes,
    changeTipKassa,
    setChangeTipKassa,
    // other
    handleDeleteDocument,
    handleDeleteRecords,
    handleUndoRecords,
    getDocNumber,
    values,
    setFieldValue,
    // options
    kassaOptions,
    typeRasxodaOptions,
    kontragentOptions,
    purchaseContractsOptions,
    typePrixodaOptions,
    getSaleContracts,
    territoryOption,
    // values
    maxAmount,
    setMaxAmount,
    // submit
    isSubmitting,
    handleUpdateDocument,
    handleSubmit,
    resetForm,
    // LOADING
    LOADING_PRIXOD,
    setState,
    state,
    LOADING_KONTRAGENTS,
  };
};

export default useConnect;
