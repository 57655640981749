import request from "services/api";

class UsersServices {
  static ReorderData = (params: any) => {
    return request.put("/api/auth/user/reorder", params);
  };
  static FetchPermissions = (params: any) => {
    return request.get(`/api/auth/permissions`, { params: params });
  };
  // user role
  static FetchUserRole = (params: any) => {
    return request.get("/api/auth/user", { params: params });
  };
  static AddUserRole = (params: any) => {
    return request.post("/api/auth/user", params);
  };
  static DeleteUserRole = (id: any) => {
    return request.delete(`/api/auth/user/${id}`);
  };
  // type role
  static FetchTypeRole = (params: any) => {
    return request.get("/api/auth/role", { params: params });
  };
  static AddTypeRole = (params: any) => {
    return request.post("/api/auth/role", params);
  };
  static DeleteTypeRole = (id: any) => {
    return request.delete(`/api/auth/role/${id}`);
  };
  // log users
  static FetchLogUsers = (params: any) => {
    return request.get("/api/auth/logs", { params: params });
  };
  static FetchLogUsersById = (id: string) => {
    return request.post(`/api/auth/logs/${id}`);
  };
}

export default UsersServices;
