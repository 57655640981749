import styled, { css } from "styled-components";
import { StyleProps } from "./type";
import { Button } from "antd";
const ButtonWrapper = styled(Button)<StyleProps>`
  border: none;
  background-color: #8a8d9d;
  outline: none;
  text-decoration: none;
  border-radius: 8px;
  align-items: center;
  color: ${({ color }: StyleProps) => color || "#fff"};
  /* padding: 8px 12px; */
  padding-bottom: 10px;
  min-height: 34px;
  cursor: pointer;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  font-size: ${({ fontSize }: StyleProps) => fontSize || "16px"};
  min-width: ${({ width }: StyleProps) => width || "100px"};
  .chevron {
    margin-left: 15px;
  }
  margin: ${({ margin }: StyleProps) => margin || "0px"};

  /* &[disabled] {
    opacity: 0;
  }
  &[inability] {
    pointer-events: none;
    opacity: 0.8;
  } */
  z-index: 1;

  ${({ primary }: StyleProps) =>
    primary &&
    css`
      background-color: #0085ff;
    `};

  ${({ light_primary }: StyleProps) =>
    light_primary &&
    css`
      background-color: #407dc7;
    `};

  ${({ flower }: StyleProps) =>
    flower &&
    css`
      background-color: #b2b8fb;
    `};

  ${({ light_flower }: StyleProps) =>
    light_flower &&
    css`
      background-color: #7391f8;
    `};

  ${({ success }: StyleProps) =>
    success &&
    css`
      background-color: #53ac92;
    `};

  ${({ tangerine }: StyleProps) =>
    tangerine &&
    css`
      background-color: #f79e1b;
    `};

  ${({ info }: StyleProps) =>
    info &&
    css`
      background-color: #9247b5;
    `};

  ${({ danger }: StyleProps) =>
    danger &&
    css`
      background-color: rgba(216, 0, 39, 1);
    `};

  ${({ light_danger }: StyleProps) =>
    light_danger &&
    css`
      background-color: #ec536a;
    `};

  ${({ green }: StyleProps) =>
    green &&
    css`
      background-color: #00c838;
    `};

  ${({ disabled }: StyleProps) =>
    disabled &&
    css`
      background-color: #bbbbbb;
      pointer-events: none;
    `};

  ${({ gray, inability }: StyleProps) =>
    (gray || inability) &&
    css`
      background-color: #bbbbbb;
    `};

  ${({ bordered }: StyleProps) =>
    bordered &&
    css`
      border: solid 1px #0085ff;
      color: #0085ff;
      background-color: #ffffff;
    `};

  ${({ medium }: StyleProps) =>
    medium &&
    css`
      font-weight: 500;
    `};

  ${({ outlined }: StyleProps) =>
    outlined &&
    css`
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      border: 1px solid #e8e8e8;
      background-color: transparent;
      display: inline-flex;
      color: #1c1c1c;
      font-weight: 500;
    `};

  /* &:enabled:active {
    opacity: 0.8;
  } */

  /* &:enabled:focus {
    opacity: 0.5;
  } */
`;

export { ButtonWrapper };
