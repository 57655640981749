import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";

const initialState: any = {
  filter: {
    tovarId: null,
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  data: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  statistic: {
    loading: false,
    data: {},
    error: "",
  },
};

export const fetchAllCloseMonth = createAsyncThunk(
  "closeMonth/fetchAllCloseMonth",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchAllCloseMonth(params);
      const respond = await request.data;

      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCloseMonthStat = createAsyncThunk(
  "closeMonth/fetchCloseMonthStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchCloseMonthStat();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const closeMonthSlices = createSlice({
  name: "closeMonth",
  initialState,
  reducers: {
    filterChangeCloseMonth: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFilterCloseMonth: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCloseMonth.pending, (state) => {
      state.data = {
        loading: true,
      };
    });
    builder.addCase(fetchAllCloseMonth.fulfilled, (state, actions) => {
      state.data = {
        loading: false,
        data: get(actions, "payload.data", {}),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllCloseMonth.rejected, (state, actions) => {
      state.data = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    builder.addCase(fetchCloseMonthStat.pending, (state) => {
      state.statistic = {
        loading: true,
      };
    });
    builder.addCase(fetchCloseMonthStat.fulfilled, (state, actions) => {
      state.statistic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchCloseMonthStat.rejected, (state, actions) => {
      state.statistic = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const { filterChangeCloseMonth, clearFilterCloseMonth } =
  closeMonthSlices.actions;
export default closeMonthSlices.reducer;
