import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LoadingDetailInfo = ({ width, tblDTNumber, ...props }: any) => {
    return (
        <>
            <SkeletonTheme
                baseColor="#e2e1e1" 
                highlightColor="#f6f6f6"
                style={{ width: "100%", gap:"10px" }}
                {...props}
            >
                <Skeleton height="280px" width={"90%"} style={{margin:'5vh'}}  className="my-8" borderRadius={"20px"}/>
            </SkeletonTheme>
        </>
    );
};

export default LoadingDetailInfo;
