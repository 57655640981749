import request from "services/api";
import {
  AddNewBankAccountsTypes,
  AddNewBankTypes,
  AddNewFizLitsTypes,
  AddNewKassaTypes,
  AllPaginationFilterType,
  AddNewYurLitsTypes,
  AddNewPhysPersonTypes,
  AddNewLargePersonTypes,
  AddNewCounterpartTypes,
  AddNewBankCounterpartTypes,
  AddNewDeliveryAddressTypes,
  AddNewTermsPaymentTypes,
  AddNewWarHouseTypes,
  AddNewTerritoriesTypes,
  AddNewWorkShopTypes,
  AddNewUnitsMeasurementTypes,
  AddNewDiscountsTypes,
  AddNewTypeProductTypes,
  AddNewProductTypes,
  AddNewPriceTypes,
  AddNewCalculationTypes,
  AddNewTypeMaterialTypes,
  AddNewRawMaterialTypes,
  AddNewItemsExpenditureTypes,
  AddNewPriceMaterialTypes,
  AddNewRateVATTypes,
  AddNewDriversTypes,
  AddNewTruckTypes,
  AddNewTypeTruckTypes,
  AddNewDetailsCompanyTypes,
  AddNewSubContoTypes,
  AddNewUsersTypes,
  AddNewtManagementAccountsTypes,
} from "types/spravochnikType";

class HandBookServices {
  //1. Банки
  static GetAllBank = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/bank", { params });
  };
  static DeleteBankById = (id: number | null) => {
    return request.delete(`/api/spravochniki/bank/${id}`);
  };
  static AddNewBank = (params: AddNewBankTypes) => {
    return request.post("/api/spravochniki/bank", { ...params });
  };
  static UpdatBankData = (params: any) => {
    return request.put("/api/spravochniki/bank", { ...params });
  };
  //2. Банковские счета
  static GetAllBankAccounts = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/bank-schet", { params });
  };
  static AddNewBankAccounts = (params: AddNewBankAccountsTypes) => {
    return request.post("/api/spravochniki/bank-schet", { ...params });
  };
  static DeleteBankAccounts = (id: number | null) => {
    return request.delete(`/api/spravochniki/bank-schet/${id}`);
  };
  static UpdateBankAccounts = (params: any) => {
    return request.put("/api/spravochniki/bank-schet", { ...params });
  };
  //3. Касса
  static GetOwnKassa = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/kassa/list", { params });
  };
  static GetAllKassa = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/kassa", { params });
  };
  static AddNewKassa = (params: AddNewKassaTypes) => {
    return request.post("/api/spravochniki/kassa", { ...params });
  };
  static UpdateKassa = (params: any) => {
    return request.put("/api/spravochniki/kassa", { ...params });
  };
  static DeleteKassa = (id: number | null) => {
    return request.delete(`/api/spravochniki/kassa/${id}`);
  };
  //4. Типы физических лиц
  static GetFizLits = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/tip-fiz-litsa", { params });
  };
  static AddNewFizLits = (params: AddNewFizLitsTypes) => {
    return request.post("/api/spravochniki/tip-fiz-litsa", { ...params });
  };
  //5. Типы юридических лиц
  static GetYurLits = (params: AllPaginationFilterType) => {

    return request.get("/api/spravochniki/tip-yur-litsa", { params });
  };
  static AddNewYurLits = (params: AddNewYurLitsTypes) => {
    return request.post("/api/spravochniki/tip-yur-litsa", { ...params });
  };
  //6. Физические лица
  static GetPhysPerson = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/fiz-litsa", { params });
  };
  static GetPhysPersonById = (id: any) => {
    return request.get(`/api/spravochniki/fiz-litsa/${id}`);
  };
  static AddNewPhysPerson = (params: AddNewPhysPersonTypes) => {
    return request.post("/api/spravochniki/fiz-litsa", { ...params });
  };
  static UpdatePhysPerson = (params: AddNewPhysPersonTypes) => {
    return request.put("/api/spravochniki/fiz-litsa", { ...params });
  };
  static DeletePhysPerson = (id: number | null) => {
    return request.delete(`/api/spravochniki/fiz-litsa/${id}`);
  };
  // 7. Юридические лица
  static GetLargePerson = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/yur-litsa", { params });
  };
  static AddNewLargePerson = (params: AddNewLargePersonTypes) => {
    return request.post("/api/spravochniki/yur-litsa", { ...params });
  };
  static UpdateLargePerson = (params: any) => {
    return request.put("/api/spravochniki/yur-litsa", { ...params });
  };
  static DeleteLargePerson = (id: number | null) => {
    return request.delete(`/api/spravochniki/yur-litsa/${id}`);
  };
  // 8. Контрагенты
  static GetCounterpart = (params: any) => {
    return request.get("/api/spravochniki/kontragent", { params: params, cancelToken: params.cancelToken });
  };
  static AddNewCounterpart = (params: any) => {
    return request.post("/api/spravochniki/kontragent/add", { ...params });
  };
  static GetCounterpartById = (id: any) => {
    return request.get(`/api/spravochniki/kontragent/get/${id}`);
  }
  static UpdateCounterpart = ({ params, id }: any) => {
    return request.put(`/api/spravochniki/kontragent/edit/${id}`, { ...params });
  };
  static DeleteCounterpart = (id: number | null) => {
    return request.delete(`/api/spravochniki/kontragent/${id}`);
  };
  // 9. Банковские счета контрагентов
  static GetBankCounterpart = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/bank-schet-kontragenta", { params });
  };
  static AddNewBankCounterpart = (params: AddNewBankCounterpartTypes) => {
    return request.post("/api/spravochniki/bank-schet-kontragenta", {
      ...params,
    });
  };
  static UpdateBankCounterpart = (params: any) => {
    return request.put(`/api/spravochniki/bank-schet-kontragenta`, {
      ...params,
    });
  };
  static DeleteBankCounterpart = (id: number | null) => {
    return request.delete(`/api/spravochniki/bank-schet-kontragenta/${id}`);
  };
  // 8.2. Адреса доставки
  static GetDeliveryAddress = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/addres-dostavki", { params });
  };
  static AddNewDeliveryAddress = (params: AddNewDeliveryAddressTypes) => {
    return request.post("/api/spravochniki/addres-dostavki", { ...params });
  };
  static UpdateDeliveryAddress = (params: AddNewDeliveryAddressTypes) => {
    return request.put("/api/spravochniki/addres-dostavki", { ...params });
  };
  static DeleteDeliveryAddress = (id: number | null) => {
    return request.delete(`/api/spravochniki/addres-dostavki/${id}`);
  };
  //9. Условия оплаты
  static GetTermsPayment = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/usloviya-oplati", { params });
  };
  static AddNewTermsPayment = (params: AddNewTermsPaymentTypes) => {
    return request.post("/api/spravochniki/usloviya-oplati", { ...params });
  };
  static UpdateTermsPayment = (params: any) => {
    return request.put("/api/spravochniki/usloviya-oplati", { ...params });
  };
  static DeleteTermsPayment = (id: number | null) => {
    return request.delete(`/api/spravochniki/usloviya-oplati/${id}`);
  };
  // 10. Склады
  static GetWareHouse = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/sklad", { params });
  };
  static GetOwnWareHouse = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/sklad/list", { params, cancelToken: params.cancelToken });
  };
  static AddNewWareHouse = (params: AddNewWarHouseTypes) => {
    return request.post("/api/spravochniki/sklad", { ...params });
  };
  static UpdateWareHouse = (params: any) => {
    return request.put("/api/spravochniki/sklad", { ...params });
  };
  static DeleteWareHouse = (id: number | null) => {
    return request.delete(`/api/spravochniki/sklad/${id}`);
  };
  // 11 Территори
  static GetTerritories = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/territoriya", { params });
  };
  static GetOwnTerritories = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/territoriya/list", { params });
  };
  static AddNewTerritories = (params: AddNewTerritoriesTypes) => {
    return request.post("/api/spravochniki/territoriya", { ...params });
  };
  static UpdateTerritories = (params: any) => {
    return request.put("/api/spravochniki/territoriya", { ...params });
  };
  static DeleteTerritories = (id: number | null) => {
    return request.delete(`/api/spravochniki/territoriya/${id}`);
  };
  // 12 Цех
  static GetWorkshop = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/sexa", { params });
  };
  static AddNewWorkshop = (params: AddNewWorkShopTypes) => {
    return request.post("/api/spravochniki/sexa", { ...params });
  };
  // Единицы измерения
  static GetUnitsMeasurement = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/ed-izm", { params });
  };
  static AddNewUnitsMeasurement = (params: AddNewUnitsMeasurementTypes) => {
    return request.post("/api/spravochniki/ed-izm", { ...params });
  };
  static UpdateUnitsMeasurement = (params: any) => {
    return request.put("/api/spravochniki/ed-izm", { ...params });
  };
  static DeleteUnitsMeasurement = (id: number | null) => {
    return request.delete(`/api/spravochniki/ed-izm/${id}`);
  };
  // Скидки
  static GetDiscounts = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/skidka", { params, cancelToken: params.signal });
  };
  static AddNewDiscounts = (params: AddNewDiscountsTypes) => {
    return request.post("/api/spravochniki/skidka", { ...params });
  };
  static UpdateDiscounts = (params: any) => {
    return request.put("/api/spravochniki/skidka", { ...params });
  };
  static DeleteDiscounts = (id: number | null) => {
    return request.delete(`/api/spravochniki/skidka/${id}`);
  };
  // Вид товара
  static GetTypeProduct = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/vid-tovara", { params });
  };
  static AddNewTypeProduct = (params: AddNewTypeProductTypes) => {
    return request.post("/api/spravochniki/vid-tovara", { ...params });
  };
  static UpdateTypeProduct = (params: any) => {
    return request.put("/api/spravochniki/vid-tovara", { ...params });
  };
  static DeleteTypeProduct = (id: number | null) => {
    return request.delete(`/api/spravochniki/vid-tovara/${id}`);
  };
  //  Товар
  static GetProduct = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/tovar", { params });
  };

  static AddNewProduct = (params: AddNewProductTypes) => {
    return request.post("/api/spravochniki/tovar", { ...params });
  };
  static UpdateProduct = (params: any) => {
    return request.put("/api/spravochniki/tovar", { ...params });
  };
  static DeleteProduct = (id: number | null) => {
    return request.delete(`/api/spravochniki/tovar/${id}`);
  };

  // Цены
  static GetPrice = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/sena", { params });
  };
  static AddNewPrice = (params: AddNewPriceTypes) => {
    return request.post("/api/spravochniki/sena", { ...params });
  };
  static UpdatePrice = (params: any) => {
    return request.put("/api/spravochniki/sena", { ...params });
  };
  static DeletePrice = (id: number | null) => {
    return request.delete(`/api/spravochniki/sena/${id}`);
  };
  // Калькуляция
  static GetCalculation = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/kalkulyatsiya", { params });
  };
  static DeleteCalculation = (id: number | null) => {
    return request.delete(`/api/spravochniki/kalkulyatsiya/${id}`);
  };
  static AddNewCalculation = (params: AddNewCalculationTypes) => {
    return request.post("/api/spravochniki/kalkulyatsiya", { ...params });
  };
  static GetProductMaterial = (params: {
    tovarId: number;
    kol: string;
    dataDoc: string | null;
    znId?: string | null;
  }) => {
    return request.get(`/api/spravochniki/kalkulyatsiya/tovar`, { params });
  };
  // Калькуляция PF
  static GetSemiCalculation = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/kalkulyatsiya-pf", { params });
  };
  static AddNewSemiCalculation = (params: any) => {
    return request.post("/api/spravochniki/kalkulyatsiya-pf", { ...params });
  };
  static GetSemiProductMaterial = (params: { tovar: number; kol: string }) => {
    return request.get(
      `/api/spravochniki/kalkulyatsiya-pf/tovar/${params.tovar}?kol=${params.kol}`
    );
  };
  static DeleteSemiCalculation = (id: number | null) => {
    return request.delete(`/api/spravochniki/kalkulyatsiya-pf/${id}`);
  };
  // Вид материала
  static GetTypeMaterial = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/vid-material", { params });
  };
  static AddNewTypeMaterial = (params: AddNewTypeMaterialTypes) => {
    return request.post("/api/spravochniki/vid-material", { ...params });
  };
  static UpdateTypeMaterial = (params: any) => {
    return request.put("/api/spravochniki/vid-material", { ...params });
  };
  static DeleteTypeMaterial = (id: number | null) => {
    return request.delete(`/api/spravochniki/vid-material/${id}`);
  };
  // Сырьё и материалы
  static GetRawMaterial = (params: any) => {
    return request.get("/api/spravochniki/material", { params: params });
  };
  static AddNewRawMaterial = (params: AddNewRawMaterialTypes) => {
    return request.post("/api/spravochniki/material", { ...params });
  };
  static UpdateRawMaterial = (params: any) => {
    return request.put("/api/spravochniki/material", { ...params });
  };
  static DeleteRawMaterial = (id: number | null) => {
    return request.delete(`/api/spravochniki/material/${id}`);
  };
  // Цены материалов
  static GetPriceMaterial = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/seni-materialov", { params });
  };
  static AddNewPriceMaterial = (params: AddNewPriceMaterialTypes) => {
    return request.post("/api/spravochniki/seni-materialov", { ...params });
  };
  // Ставки НДС
  static GetRateVAT = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/stavka-nds", { params });
  };
  static AddNewRateVAT = (params: AddNewRateVATTypes) => {
    return request.post("/api/spravochniki/stavka-nds", { ...params });
  };
  static UpdateRateVAT = (params: any) => {
    return request.put("/api/spravochniki/stavka-nds", { ...params });
  };
  static DeleteRateVAT = (id: number | null) => {
    return request.delete(`/api/spravochniki/stavka-nds/${id}`);
  };
  // Сырьё и материалы
  static GetExpenseItems = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/statya-rasxoda", { params });
  };
  static AddNewExpenseItems = (params: AddNewItemsExpenditureTypes) => {
    return request.post("/api/spravochniki/statya-rasxoda", { ...params });
  };
  static UpdateExpenseItems = (params: any) => {
    return request.put("/api/spravochniki/statya-rasxoda", { ...params });
  };
  static DeleteExpenseItems = (id: number | null) => {
    return request.delete(`/api/spravochniki/statya-rasxoda/${id}`);
  };
  // Тип автомобиля
  static GetTypeTruck = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/tip-avto", { params });
  };
  static AddNewTypeTruck = (params: AddNewTypeTruckTypes) => {
    return request.post("/api/spravochniki/tip-avto", { ...params });
  };
  static UpdateTypeTruck = (params: any) => {
    return request.put("/api/spravochniki/tip-avto", { ...params });
  };
  static DeleteTypeTruck = (id: number | null) => {
    return request.delete(`/api/spravochniki/tip-avto/${id}`);
  };
  // Автомобили
  static GetTruck = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/avto", { params, cancelToken: params.signal });
  };
  static AddNewTruck = (params: AddNewTruckTypes) => {
    return request.post("/api/spravochniki/avto", { ...params });
  };
  static UpdateTruck = (params: any) => {
    return request.put("/api/spravochniki/avto", { ...params });
  };
  static DeleteTruck = (id: number | null) => {
    return request.delete(`/api/spravochniki/avto/${id}`);
  };
  // Водители
  static GetDrivers = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/voditeli", { params, cancelToken: params.signal });
  };
  static AddNewDrivers = (params: AddNewDriversTypes) => {
    return request.post("/api/spravochniki/voditeli/add", { ...params });
  };
  static UpdateDrivers = (params: any) => {
    return request.put("/api/spravochniki/voditeli", { ...params });
  };
  static DeleteDrivers = (id: number | null) => {
    return request.delete(`/api/spravochniki/voditeli/${id}`);
  };
  // Пользователи
  static GetUsers = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/polzovateli", { params });
  };
  static AddNewUsers = (params: AddNewUsersTypes) => {
    return request.post("/api/spravochniki/polzovateli/add", { ...params });
  };
  static UpdateUsers = (params: any) => {
    return request.put("/api/spravochniki/polzovateli/change", { ...params });
  };
  static DelenteUsers = (id: number | null) => {
    return request.delete(`/api/spravochniki/polzovateli/${id}`);
  };
  // Реквизиты предприятия (компании)
  static GetDetailsCompany = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/kompaniya", { params });
  };
  static AddNewDetailsCompany = (params: AddNewDetailsCompanyTypes) => {
    return request.post("/api/spravochniki/kompaniya", { ...params });
  };
  static UpdateDetailsCompany = (params: any) => {
    return request.put("/api/spravochniki/kompaniya", { ...params });
  };
  static DeleteDetailsCompany = (id: number | null) => {
    return request.delete(`/api/spravochniki/kompaniya/${id}`);
  };
  // Субконто
  static GetSubConto = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/subkonto", { params });
  };
  static AddNewSubConto = (params: AddNewSubContoTypes) => {
    return request.post("/api/spravochniki/subkonto", { ...params });
  };
  // Счета управленческого учета
  static GetMenageAccounts = (params: AllPaginationFilterType) => {
    return request.get("/api/spravochniki/schete", { params });
  };
  static AddNewMenageAccounts = (params: AddNewtManagementAccountsTypes) => {
    return request.post("/api/spravochniki/schete", { ...params });
  };
  static FetchAccounts = () => {
    return request.get("/api/spravochniki/schete/schet");
  };
}

export default HandBookServices;
