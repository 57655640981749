import { useAppDispatch, useAppSelector } from "app/hook";
import { useCallback, useEffect } from "react";
import { filterLogUsers, logUsers, userListOptions } from "app/states/users";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Users } from "routers/paths";
import { TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import moment from "moment";
import {
  clearLogFilter,
  fetchLogUsers,
  filterChangeLogUsers,
} from "app/slices/usersSlices/logUsersSlices/logUsersSlices";
import { fetchUserRole } from "app/slices/usersSlices/userRoleSlices/userRoleSlices";
const categoryOptions = [
  {
    label: "Документ",
    value: "DOCUMENT",
  },
  {
    label: "Касса",
    value: "KASSA",
  },
  {
    label: "Справочник",
    value: "SPRAVOCHNIK",
  },
  {
    label: "Отчет",
    value: "OTCHET",
  },
  {
    label: "Рол и права",
    value: "ROLE_PERMISSIONS",
  },

  {
    label: "Система",
    value: "SYSTEM",
  },
];
const actionsOptions = [
  {
    label: "Чтение",
    value: "READ",
  },
  {
    label: "Ввод нового",
    value: "CREATE",
  },
  {
    label: "Измение",
    value: "UPDATE",
  },
  {
    label: "Пометка на удаление",
    value: "DELETE_RECORDS",
  },
  {
    label: "Отмена пометки на удаление",
    value: "UNDO_RECORDS",
  },
  {
    label: "Удаление",
    value: "DELETE",
  },
  {
    label: "Вход",
    value: "LOGIN",
  },
  {
    label: "Выход",
    value: "LOGOUT",
  },
];
const LogUsersContainer = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const logUserData = useAppSelector(logUsers);
  const filter = useAppSelector(filterLogUsers);
  const userDataOptions = useAppSelector(userListOptions);
  const LOADING_TABLE = get(logUserData, "loading", false);
  const isAddForm = includes(get(location, "pathname", ""), Users.SHOW_LOG);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        filterChangeLogUsers({
          name,
          value,
        })
      );
    },
    [dispatch]
  );
  const handleClearSearch = useCallback(() => {
    dispatch(
      fetchLogUsers({
        ...filter,
        action: null,
        category: null,
        userId: null,
        inAscOrder: false,
      })
    );
    dispatch(clearLogFilter());
  }, [filter]);

  const fetchLogUserData = useCallback(() => {
    dispatch(fetchLogUsers({ ...filter, inAscOrder: false }));
  }, [dispatch, filter]);

  // navigate
  const navigateToFormLog = (clickItem: any) => {
    navigate(Users.SHOW_LOG, {
      state: clickItem,
    });
  };
  useEffect(() => {
    dispatch(fetchUserRole({ all: true }));
    return () => {
      handleClearSearch();
    };
  }, []);
  useEffect(() => {
    fetchLogUserData();
  }, [filter.page, filter.size]);

  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row className={"py-16 "}>
            <Col xs={8}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="220px"
                  placeholder="Пользователь"
                  options={userDataOptions}
                  handleChange={(e: any) => {
                    dispatchFilter({
                      name: "userId",
                      value: e,
                    });
                  }}
                  isSearchable
                  value={get(filter, "userId", "")}
                />
                <BaseSelect
                  width="220px"
                  placeholder="Категория"
                  options={categoryOptions}
                  handleChange={(e: any) => {
                    dispatchFilter({
                      name: "category",
                      value: e,
                    });
                  }}
                  isSearchable
                  value={get(filter, "category", "")}
                />
                <BaseSelect
                  width="220px"
                  placeholder="Действие"
                  options={actionsOptions}
                  handleChange={(e: any) => {
                    dispatchFilter({
                      name: "action",
                      value: e,
                    });
                  }}
                  isSearchable
                  value={get(filter, "action", "")}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"}>
              <Button
                primary
                className={"mr-16"}
                handleClick={fetchLogUserData}
              >
                {TABLE.APPLY}
              </Button>
              <Button danger handleClick={handleClearSearch}>
                {TABLE.RESET}
              </Button>
            </Col>
          </Row>
          <Row className="py-16">
            <Col xs={8}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  width="220px"
                  placeholder="Дата начало"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "fromDate", "")}
                  handleDate={(e: any) => {
                    dispatchFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
                <DatePicker
                  width="220px"
                  placeholder="Дата конец"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: any) => {
                    dispatchFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </FlexBox>
            </Col>
          </Row>
          {/* table */}
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Пользователь (ID)",
                    "Роль",
                    "Категория",
                    "Наименование",
                    "Номер/ID",
                    "Действие",
                    "Дата и время",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      {!isEmpty(get(logUserData, "data", [])) ? (
                        get(logUserData, "data", []).map(
                          (item, index: number) => (
                            <tr
                              key={get(item, "id", null)}
                              onClick={() => navigateToFormLog(item)}
                              className="cursor-pointer"
                            >
                              <td>
                                {indexNumber({
                                  currentPage: get(
                                    logUserData,
                                    "pagination.currentPageNumber",
                                    0
                                  ),
                                  pageSize: get(
                                    logUserData,
                                    "pagination.pageSize",
                                    10
                                  ),
                                  index: index + 1,
                                })}
                              </td>
                              <td>
                                {get(item, "imyaPolzovateli", "")}
                                <span className="ml-2">
                                  ({get(item, "createdBy", "-")})
                                </span>
                              </td>
                              <td>{get(item, "roleName", "-")}</td>
                              <td>{get(item, "kategoriya", "")}</td>
                              <td>{get(item, "naimenovanie", "")}</td>
                              <td>{get(item, "nomerOrId", "")}</td>
                              <td>{get(item, "deystvie", "")}</td>
                              <td>
                                {" "}
                                {moment(get(item, "createdAt", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={8} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={8}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(logUserData, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    filterChangeLogUsers({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(logUserData, "pagination.currentPageNumber", 0)}
                pageCount={get(logUserData, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatch(
                    filterChangeLogUsers({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default LogUsersContainer;
