import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchPhysPerson } from "app/slices/handbookSlices/physPersonSlices/physPersonSlices";
import { WareHouseDataType } from "app/slices/handbookSlices/wareHouseSlices/type";
import {
  clearFilterWarHouse,
  fetchWarHouse,
  handleWarHouseFilter,
} from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { allWarHouseData, allWarHouseFilter } from "app/states/handbooks";
import { getPhysPersonData } from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  FormSelect,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { wareHouseSchema } from "utilities/schema/spravochnikSchemas";
// Склады
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: WareHouseDataType;
}) => {
  const dispatch = useAppDispatch();
  const optionPerson = useAppSelector(getPhysPersonData);
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateWareHouse({
          data: {
            ...values,
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewWareHouse({
          data: values,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
      resetForm();
    } catch (err) {
      console.log("Error", err);
    }
  };
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imyaSklada: get(details, "imyaSklada", ""),
      tipSklada: String(get(details, "tipSklada", "")),
      fizLitsaId: get(details, "fizLitsaId", null),
    },
    validationSchema: wareHouseSchema,
    onSubmit,
  });
  useEffect(() => {
    dispatch(fetchPhysPerson({ sotrudnikVsPodotchet: true, all: true }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Input
            label={"Наименование склада"}
            value={get(values, "imyaSklada", "")}
            handleInput={(e: string) => {
              setFieldValue("imyaSklada", e);
            }}
            required={true}
          />

          <FormSelect
            className="mt-24"
            label={"Тип склада"}
            id={"tipSklada"}
            isSearchable
            value={get(values, "tipSklada", "")}
            options={[
              {
                value: "0",
                label: "склад гот.продукции",
              },
              {
                value: "1",
                label: "склад материалов и полуфабрикатов",
              },
            ]}
            handleChange={(e: number) => {
              setFieldValue("tipSklada", e);
            }}
            required={true}
          />
          <FormSelect
            className="mt-24"
            label={"ФИО материально ответственного лица"}
            id={"fizLitsaId"}
            isSearchable
            value={get(values, "fizLitsaId", "")}
            options={optionPerson}
            handleChange={(e: any) => {
              setFieldValue("fizLitsaId", e);
            }}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const WarehousesContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<WareHouseDataType | any>({});

  const data = useAppSelector(allWarHouseData);
  const filter = useAppSelector(allWarHouseFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleWarHouseFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchWarHouse({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  const clearFilter = useCallback(() => {
    dispatch(fetchWarHouse({ ...filter, search: "", InAscOrder: false }));
    dispatch(clearFilterWarHouse());
  }, [dispatch, filter]);

  const fetchAllWarHouseData = useCallback(() => {
    dispatch(fetchWarHouse({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: WareHouseDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteWareHouse(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllWarHouseData();
      }
    });
  };

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  useEffect(() => {
    return () => {
      clearFilter();
    };
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "SKLAD_CREATE",
                    page: "SKLAD",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllWarHouseData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименования склада",
                    "Тип склада",
                    "ФИО материально ответственного лица",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "dataList", [])) ? (
                        get(data, "dataList", []).map(
                          (item: WareHouseDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"USLOVIYA_OPLATA"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imyaSklada", "-")}</td>
                              <td>
                                {Number(get(item, "tipSklada", "-")) === 1
                                  ? "склад материалов и полуфабрикатов"
                                  : "склад готовой продукции"}
                              </td>
                              <td>{get(item, "imyaFizLitsa", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={6} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={6}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default WarehousesContainer;
