import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: any = {
  filter: {
    territoriyaId: null,
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  statistics: {
    loading: false,
    data: {},
    error: "",
  },
  cashList: {
    loading: false,
    data: [],
    error: "",
  },
  cashListTemp: {
    loading: false,
    data: [],
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      prixodBDDetailsList: [],
    },
  },
};

export const fetchRegisterParishCash = createAsyncThunk(
  "registerParishCash/fetchRegisterParishCash",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchAllRegisterParishCash(params);
      const respond = await request.data;
      const { data, pagination } = await respond;
      return { data, pagination };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchRegisterParishCashTemp = createAsyncThunk(
  "registerParishCash/fetchRegisterParishCashTemp",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchAllRegisterParishCashTemp();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchRegisterParishCashTempById = createAsyncThunk(
  "registerParishCash/fetchRegisterParishCashTempById",
  async (id: string, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchRegisterParishCashTempById(id);
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchRegisterParishCashById = createAsyncThunk(
  "registerParishCash/fetchRegisterParishCashById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchRegisterParishCashById(id);
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchRegisterParishCashStat = createAsyncThunk(
  "registerParishCash/fetchRegisterParishCashStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchRegisterParishCashStat();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const registerParishCashSlice = createSlice({
  name: "registerParishCash",
  initialState,
  reducers: {
    handleFilterChangeCash: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    changeCashSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);

      if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            prixodBDDetailsList: state.formData.data.prixodBDDetailsList.map(
              (item: any, index: number) =>
                index == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            prixodBDDetailsList: state.formData.data.prixodBDDetailsList.filter(
              (item: any, index: number) => index !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            prixodBDDetailsList: [],
          },
        };
      }
    },
    handleFormChangeCash: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          prixodBDDetailsList: !isEmpty(get(actions, "payload", {}))
            ? [...state.formData.data.prixodBDDetailsList, actions.payload]
            : [],
        },
      };
    },
    clearFormDataCash: (state) => {
      state.formData = initialState.formData;
    },
    clearFilterParish: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegisterParishCash.pending, (state) => {
      state.cashList = {
        loading: true,
      };
    });
    builder.addCase(fetchRegisterParishCash.fulfilled, (state, actions) => {
      state.cashList = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchRegisterParishCash.rejected, (state, actions) => {
      state.cashList = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });

    builder.addCase(fetchRegisterParishCashTemp.pending, (state) => {
      state.cashListTemp = {
        loading: true,
      };
    });
    builder.addCase(fetchRegisterParishCashTemp.fulfilled, (state, actions) => {
      state.cashListTemp = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchRegisterParishCashTemp.rejected, (state, actions) => {
      state.cashListTemp = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    // by id
    builder.addCase(fetchRegisterParishCashById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchRegisterParishCashById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchRegisterParishCashById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });

    builder.addCase(fetchRegisterParishCashTempById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(
      fetchRegisterParishCashTempById.fulfilled,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
          isTempActive: true,
        };
      }
    );
    builder.addCase(
      fetchRegisterParishCashTempById.rejected,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: [],
          error: actions.error.message,
          isTempActive: false,
        };
      }
    );

    builder.addCase(fetchRegisterParishCashStat.pending, (state) => {
      state.statistics = {
        loading: true,
      };
    });
    builder.addCase(fetchRegisterParishCashStat.fulfilled, (state, actions) => {
      state.statistics = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchRegisterParishCashStat.rejected, (state, actions) => {
      state.statistics = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleFilterChangeCash,
  handleFormChangeCash,
  changeCashSubject,
  clearFormDataCash,
  clearFilterParish,
} = registerParishCashSlice.actions;
export default registerParishCashSlice.reducer;
