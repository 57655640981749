import { useAppDispatch, useAppSelector } from "app/hook";
import {
  typeRoleFilter,
  typeRoleParmDetails,
  typeRolePermissions,
} from "app/states/users";
import { useCallback, useEffect } from "react";
import {
  Button,
  Checkbox,
  Content,
  FlexBox,
  Form,
  Input,
  Title,
} from "components";
import { Col, Row } from "react-grid-system";

import { get, isEmpty } from "lodash";

import { useLocation, useNavigate } from "react-router-dom";
import JSONDiffViewer from "./components/JSONDiffViewer";
import moment from "moment";

//  show
const ShowLogsContainer = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const showJsons = useCallback(() => {
    const jsonString = get(state, "data1", "");
    const jsonString2 = get(state, "data2", "");
    const formattedJson = JSON.stringify(JSON.parse(jsonString), null, 2);
    const formattedJson2 = JSON.stringify(JSON.parse(jsonString2), null, 2);
  }, [state]);
  useEffect(() => {
    showJsons();
  }, [showJsons]);
  return (
    <Content height="70vh">
      <Form>
        <Row>
          <Col xs={12} className="mb-8">
            <FlexBox justify="space-between">
              <Title bold sm>
                Детали
              </Title>
              <Title bold mk>
                {moment(get(state, "createdAt", "")).format(
                  "DD.MM.YYYY  HH:mm"
                )}
              </Title>
            </FlexBox>
          </Col>
          <Col xs={12} style={{ padding: 0 }}>
            <hr />
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={6} className="mb-16">
                <Input
                  style={{ marginTop: 0 }}
                  label={"Пользователь (ID)"}
                  value={get(state, "createdBy", "-")}
                />
                <Input
                  style={{ marginTop: 0 }}
                  label={"Роль"}
                  value={get(state, "roleName", "-")}
                />
                <Input
                  style={{ marginTop: 0 }}
                  label={"Категория"}
                  value={get(state, "kategoriya", "-")}
                />
              </Col>
              <Col xs={6} className="mb-16">
                <Input
                  style={{ marginTop: 0 }}
                  label={"Наименование"}
                  value={get(state, "naimenovanie", "-")}
                />
                <Input
                  style={{ marginTop: 0 }}
                  label={"Номер/ID"}
                  value={get(state, "nomerOrId", "-")}
                />
                <Input
                  style={{ marginTop: 0 }}
                  label={"Действие"}
                  value={get(state, "deystvie", "-")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {/* <Col xs={6}>
            {!isEmpty(get(state, "data1", null)) && <pre>{formattedJson}</pre>}
          </Col>
          <Col xs={6}>
            {!isEmpty(get(state, "data2", null)) && <pre>{formattedJson2}</pre>}
          </Col> */}
          <Col xs={6}>
            {!isEmpty(get(state, "data1", null)) && (
              <JSONDiffViewer original={get(state, "data1", null)} />
            )}
          </Col>
          <Col xs={6}>
            {!isEmpty(get(state, "data2", null)) && (
              <JSONDiffViewer original={get(state, "data2", null)} />
            )}
          </Col>
        </Row>
        <Row className={" mt-32 text-end"}>
          <Col xs={12}>
            <Button danger handleClick={() => navigate(-1)}>
              Закрыть
            </Button>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default ShowLogsContainer;
