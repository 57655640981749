import { get, isEmpty } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function OperationalCashReportTable({ data }: any) {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth={"1200px"} className="mb-16">
          <thead>
            <tr>
              <th>№</th>
              <th>Дата</th>
              <th>
                Номер
                <br /> документа
              </th>
              <th style={{ width: "180px" }}>Контрагент</th>
              <th>Приход</th>
              <th>Расход</th>
              <th>Остаток</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.itogo.ostNach", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
            </tr>
            {/* the map */}
            {!isEmpty(get(data, "dataList.list", [])) ? (
              get(data, "dataList.list", []).map((item: any, index: number) => (
                <tr key={index + 1}>
                  <td>{index + 1}</td>
                  <td>
                    {moment(get(item, "dataDoc", "")).format("DD.MM.YYYY")}
                  </td>
                  <td>{get(item, "nomerDoc", "")}</td>
                  <td>{get(item, "imyaKontragent", "")}</td>
                  <td>
                    <NumericFormat
                      displayType="text"
                      value={get(item, "prixodSumma", 0)}
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td>
                  <td>
                    <NumericFormat
                      displayType="text"
                      value={get(item, "rasxodSumma", 0)}
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td>
                  <td>
                    <NumericFormat
                      displayType="text"
                      value={get(item, "ostKon", 0)}
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>Виберите параметры</td>
              </tr>
            )}
            {/* the end */}
            <tr className="black_td">
              <td colSpan={4} style={{ textAlign: "start" }}>
                Итого:{" "}
              </td>
              <th>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.itogo.itogoPrixod", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </th>
              <th>
                <NumericFormat
                  displayType="text"
                  value={get(data, "dataList.itogo.itogoRasxod", 0)}
                  thousandSeparator
                  decimalScale={2}
                />
              </th>
              <td></td>
            </tr>
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
}

export default OperationalCashReportTable;
