import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
  data: { loading: false, defectsList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    vidTovarId: null,
    territoriyaId: null,
    sposobOplataId: null,
    skladId: null,
    detalno: false,
  }
}

export const fetchReportDefects = createAsyncThunk(
  "reportDefectsTable/fetchReportDefects",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchReportDefectsTable(params);
      const respond = await request.data;
      return respond
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const reportDefectsTable = createSlice({
  name: "reportDefectsTable",
  initialState,
  reducers: {
    filterDefectsTable: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = { ...state.filter, [name]: value };
    },
    clearDefectsTable: () => initialState,
    changeDefectsTable: (state, action) => {
      state.data = {
        loading: false,
        defectsList: action.payload,
        error: "",
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportDefects.pending, (state) => {
      state.data = {
        loading: true,
        defectsList: [],
        error: "",
      };
    });
    builder.addCase(fetchReportDefects.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        defectsList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchReportDefects.rejected, (state, payload) => {
      state.data = {
        loading: false,
        defectsList: [],
        error: payload.error.message,
      };
    });
  },
});

export const {filterDefectsTable, clearDefectsTable, changeDefectsTable} = reportDefectsTable.actions;
export default reportDefectsTable.reducer;