import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearPurchaseProductsState,
  fetchNotFilledPurchaseContracts,
  fetchPurchaseContracts,
  fetchPurchaseContractsStatistics,
  handleClearFormData,
  handleFilterChangePurchases,
} from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import {
  purchaseContractFilter,
  purchaseContractList,
  purchaseContractStat,
  purchaseTempsContractList,
} from "app/states/documents";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Documents } from "routers/paths";
import delate from "assets/images/icons/delate.svg";
import DocumentServices from "services/apiServices/documents";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import HasAccess from "routers/HasAccess";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
// Регистрация договоров на покупку
const RegisterPurchasesContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_PURCHASE_CONTRACTS
  );
  const [documentInfo, setDocumentInfo] = useState<any>({ id: "" });
  const purchaseContracts = useAppSelector(purchaseContractList);
  const purchaseTempsContracts = useAppSelector(purchaseTempsContractList);
  const filter = useAppSelector(purchaseContractFilter);
  const cntrCountStat = useAppSelector(purchaseContractStat);
  const LOADING_TABLE = get(purchaseContracts, "loading", []);
  const contractorDropdowns = useAppSelector(contractorOptions);
  let loadingKontragent = useAppSelector(loadingConterpart);
  //  context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id });
  };
  // fetchs
  const fetchRigesterPurchaseContracts = useCallback(() => {
    dispatch(fetchPurchaseContracts(filter));
    dispatch(fetchAllCounterpart({ all: true }));
  }, [dispatch, filter]);

  const fetchPurchaseNotFilledContracts = useCallback(() => {
    dispatch(fetchNotFilledPurchaseContracts());
    dispatch(fetchPurchaseContractsStatistics());
  }, [dispatch]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchPurchaseContracts({
        ...filter,
        fromDate: null,
        toDate: null,
        kontragentId: null,
      })
    );
    dispatch(clearPurchaseProductsState());
  }, [filter]);
  // navigation
  const navigatePurchaseContractForm = (params: {
    id: string;
    isTemp: boolean;
  }) => {
    navigate(Documents.ADD_PURCHASE_CONTRACTS, {
      state: params,
    });
  };

  const handleAddButton = () => {
    dispatch(handleClearFormData());
    navigate(Documents.ADD_PURCHASE_CONTRACTS);
  };

  useEffect(() => {
    fetchRigesterPurchaseContracts();
  }, [filter.page, filter.size]);

  useEffect(() => {
    fetchPurchaseNotFilledContracts();
    return () => {
      handleClearFilter();
    };
  }, []);

  const handleTempButton = (id: string | null) => {
    DocumentServices.RemovePurchaseTempContractById(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchPurchaseNotFilledContracts();
      }
    });
  };
  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeletePurchaseContractById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchPurchaseContracts(filter));
        dispatch(fetchPurchaseContractsStatistics());
      }
    });
  };

  if (isAddForm) {
    return <Outlet />;
  }

  return (
    <Content className={"full_height"}>
      <Row align="center" className="mb-16">
        <Col xs={6}>
          <ShowStatistics statistic={cntrCountStat} />
        </Col>
        <Col xs={6} style={{ textAlign: "end" }}>
          <Button danger className="mr-16" handleClick={handleClearFilter}>
            {TABLE.RESET}
          </Button>
          <HasAccess>
            {({ userCan }: any) =>
              userCan({
                permission: "DOG_POKUPKA_CREATE",
                page: "DOG_POKUPKA",
                department: "DOKUMENTI",
                type: PROTECTED_TYPE.PERMISSIONS,
              }) && (
                <Button green handleClick={handleAddButton}>
                  {TABLE.ADD}
                </Button>
              )
            }
          </HasAccess>
        </Col>
      </Row>
      <Row className={"py-16 border"}>
        <Col xs={10}>
          <FlexBox gap={"10px"}>
            <BaseSelect
              isClearable
              placeholder={"Контрагент"}
              width="250px"
              isLoading={loadingKontragent}
              value={get(filter, "kontragentId", "")}
              options={contractorDropdowns}
              handleChange={(e: any) => {
                dispatch(
                  handleFilterChangePurchases({
                    name: "kontragentId",
                    value: e,
                  })
                );
              }}
            />
            <DatePicker
              isClearable
              width="200px"
              placeholder="Дата начало"
              handleDate={(e: any) => {
                dispatch(
                  handleFilterChangePurchases({
                    name: "fromDate",
                    value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                  })
                );
              }}
              value={get(filter, "fromDate", "")}
              dateFormat="dd.MM.yyyy"
            />

            <DatePicker
              isClearable
              width="200px"
              placeholder="Дата конец"
              handleDate={(e: any) => {
                dispatch(
                  handleFilterChangePurchases({
                    name: "toDate",
                    value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                  })
                );
              }}
              value={get(filter, "toDate", "")}
              dateFormat="dd.MM.yyyy"
            />
          </FlexBox>
        </Col>
        <Col xs={2} className="text-right">
          <Button primary handleClick={fetchRigesterPurchaseContracts}>
            {TABLE.APPLY}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <WindowHeight>
            {
              <ContextMenu
                handleNavigate={navigatePurchaseContractForm}
                changeItem={true}
                deleteItem={true}
                documentInfo={documentInfo}
                userPermission={"DOG_POKUPKA"}
                deleteDocument={handleDeleteDocument}
              />
            }
            <BaseTable
              tableHeader={[
                "№",
                "Пользователь",
                "Номер",
                "Дата",
                "Контрагент",
                "Сумма договора",
                "Способ оплаты",
                "Условия оплаты",
                "Способ доставки",
                "Дата действия договора",
                "Время записи",
              ]}
              className="table_scroll"
            >
              {!LOADING_TABLE ? (
                <>
                  <>
                    {!isEmpty(get(purchaseTempsContracts, "data", [])) &&
                      get(purchaseTempsContracts, "data", []).map(
                        (item, index: number) => (
                          <tr
                            key={get(item, "id", 0)}
                            onClick={() =>
                              navigatePurchaseContractForm({
                                isTemp: true,
                                id: get(item, "id", ""),
                              })
                            }
                            className="cursor-pointer"
                          >
                            <td>
                              <div
                                className="delete_button"
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  handleTempButton(get(item, "id", null));
                                }}
                              >
                                <ReactSVG
                                  src={delate}
                                  className="delete_button"
                                />
                              </div>
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "nomerDoc", "")}</td>
                            <td>
                              {!isEmpty(get(item, "dataDoc", ""))
                                ? moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>{get(item, "imyaKontragent", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summaDog", "")}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>{get(item, "sposobOplataName", "")}</td>
                            <td>{get(item, "imyaUsloviyaOplati", "")}</td>
                            <td>{get(item, "sposobDostavkaName", "")}</td>
                            <td>
                              {!isEmpty(get(item, "dataIsp", ""))
                                ? moment(get(item, "dataIsp", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {!isEmpty(get(item, "createdAt", ""))
                                ? moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                          </tr>
                        )
                      )}
                  </>

                  {!isEmpty(get(purchaseContracts, "data", []))
                    ? get(purchaseContracts, "data", []).map(
                        (item, index: number) => (
                          <tr
                            key={get(item, "id", 0)}
                            onClick={() =>
                              navigatePurchaseContractForm({
                                isTemp: false,
                                id: get(item, "id", ""),
                              })
                            }
                            onContextMenu={(e) =>
                              displayMenu({
                                e,
                                id: get(item, "id", ""),
                                status: get(item, "status", 0),
                              })
                            }
                            className="cursor-pointer"
                          >
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  purchaseContracts,
                                  "pagination.currentPageNumber",
                                  0
                                ),
                                pageSize: get(
                                  purchaseContracts,
                                  "pagination.pageSize",
                                  10
                                ),
                                index: index + 1,
                              })}
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "nomerDoc", "")}</td>
                            <td>
                              {!isEmpty(get(item, "dataDoc", ""))
                                ? moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>{get(item, "imyaKontragent", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summaDog", "")}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>{get(item, "sposobOplataName", "")}</td>
                            <td>{get(item, "imyaUsloviyaOplati", "")}</td>
                            <td>{get(item, "sposobDostavkaName", "")}</td>
                            <td>
                              {!isEmpty(get(item, "dataIsp", ""))
                                ? moment(get(item, "dataIsp", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {!isEmpty(get(item, "createdAt", ""))
                                ? moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                          </tr>
                        )
                      )
                    : isEmpty(get(purchaseTempsContracts, "data", [])) && (
                        <Nodata colLength={11} />
                      )}
                </>
              ) : (
                map([...new Array(10)], (item, index: number) => (
                  <tr key={index + 1}>
                    <LoadingTable
                      width={index % 2 == 0 ? "50px" : "70px"}
                      tblDTNumber={11}
                    />
                  </tr>
                ))
              )}
            </BaseTable>
          </WindowHeight>
        </Col>
      </Row>
      {get(purchaseContracts, "pagination.totalPages", 0) > 0 && (
        <Row className="mt-16">
          <Col xs={6}>
            <ShowOnPage
              pageSize={get(purchaseContracts, "pagination.pageSize", 10)}
              handlePageChange={(e) =>
                dispatch(
                  handleFilterChangePurchases({
                    name: "size",
                    value: e,
                  })
                )
              }
            />
          </Col>
          <Col xs={6}>
            <Pagination
              current={get(
                purchaseContracts,
                "pagination.currentPageNumber",
                0
              )}
              pageCount={get(purchaseContracts, "pagination.totalPages", 0)}
              onPageChange={({ selected }) =>
                dispatch(
                  handleFilterChangePurchases({
                    name: "page",
                    value: selected,
                  })
                )
              }
            />
          </Col>
        </Row>
      )}
    </Content>
  );
};

export default RegisterPurchasesContainer;
