import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";
import { fetchOwnSkladGP } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import {
  changeShipmentTable,
  clearShipmentTable,
  fetchReportShipment,
  filterShipmentTable,
} from "app/slices/reportSlices/reportShipmentSlices/reportShipmentSlices";
import { getPaymentMethodData } from "app/states/enumerations/selectStates";
import {
  getSkladGP,
  getTerritoriesData,
  getTypeProductData,
} from "app/states/handbooks/selectStates";
import { reportShipmentFilter, reportShipmentList } from "app/states/reports";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";

import { ReportOnShipmentsTable } from "../components";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
import { contractorOptions } from "app/states/documents/selectStates";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
type ParamsType = {
  name?: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  tipTovara?: string;
  sposobOplata?: string;
  territoriya?: string;
  // sklad?: string;
  kontragent?: string | number;
  tipLitsaId: string | number;
}

//  Отчет по отгрузке готовой продукции
const ReportOnShipmentContainer = () => {
  const dispatch = useAppDispatch();

  // data
  const shipmentDate = useAppSelector(reportShipmentList);
  const filter = useAppSelector(reportShipmentFilter);

  const productDropdowns = useAppSelector(getTypeProductData);
  const paymentDropdowns = useAppSelector(getPaymentMethodData);
  const territoryDropdowns = useAppSelector(getTerritoriesData);
  const skladGPDropdowns = useAppSelector(getSkladGP);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const [tipLitsaId, setTipLitsaId] = useState([
    {
      label: 'Юр. лицо',
      value: 1
    },
    {
      label: 'Физ. лицо',
      value: 2
    }
  ]);
  const [check, setCheck] = useState(false);
  const LOADING_TABLE = get(shipmentDate, "loading", []);

  // details
  const [details, setDetails] = useState<ParamState>({
    kontragent: "",
    tipTovara: "",
    sposobOplata: "",
    territoriya: "",
    tipLitsaId: '',
  });

  const fetchReportShipmentData = useCallback(() => {
    console.log("shipment")
    dispatch(fetchReportShipment(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearShipmentTable());
    setDetails({
      kontragent: "",
      tipTovara: "",
      sposobOplata: "",
      territoriya: "",
      tipLitsaId: '',
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeShipmentTable(null));
    }

    dispatch(filterShipmentTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnSkladGP({}));
    dispatch(fetchTypeProduct({ all: true }));
    dispatch(fetchAllCounterpart({ all: true }));
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Отчет по отгрузке готовой продукции-${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `${"K1"}`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    const detailsArray = [
      {
        value: get(details, "kontragent", ""),
        label: `Контрагент: ${get(details, "kontragent", "")}`,
      },
      {
        value: get(details, "tipTovara", ""),
        label: `Вид товара: ${get(details, "tipTovara", "")}`,
      },
      {
        value: get(details, "territoriya", ""),
        label: `Tерритория: ${get(details, "territoriya", "")}`,
      },
      {
        value: get(details, "sposobOplata", ""),
        label: `Способ оплаты: ${get(details, "sposobOplata", "")}`,
      },
      {
        value: get(details, "tipLitsaId", ""),
        label: `Тип лица: ${get(details, "tipLitsaId", "")}`,
      }
    ];
    // names of companies
    let count = 1;
    sheet.getCell("A1").value = `Отчет по отгрузке готовой продукции с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(
          `A${1 + count}`,
          `K${1 + count}`
        );
        sheet.getCell(`A${1 + count}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Дата документа",
        key: "dataDoc",
        width: 20,
      },
      {
        header: "Номер документа",
        key: "nomerDoc",
        width: 20,
      },
      {
        header: "Контрагент",
        key: "imyaKontragent",
        width: 30,
      },
      {
        header: "Адрес доставки",
        key: "address",
        width: 35,
      },
      {
        header: "Автомобиль",
        key: "gosNomer",
        width: 30,
      },
      {
        header: "Водитель",
        key: "imyaVoditeli",
        width: 30,
      },
      {
        header: "Товар",
        key: "imyaTovar",
        width: 30,
      },
      {
        header: "Количество",
        key: "kol",
        width: 20,
      },
      {
        header: "Ед. измерение",
        key: "edIzm",
        width: 20,
      },
      {
        header: "Территория",
        key: "imyaTerritori",
        width: 30,
      },
    ];
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 2;
    get(shipmentDate, "shipmentList", [])?.forEach((item, index) => {
      counterRow++;
      sheet.addRow({
        order: get(item, "idx", 0) ? get(item, "idx", 0) : null,
        dataDoc: get(item, "dataDoc", ""),
        nomerDoc: get(item, "nomerDoc", ""),
        imyaKontragent: get(item, "imyaKontragent", ""),
        address: get(item, "address", ""),
        gosNomer: get(item, "gosNomer", ""),
        imyaVoditeli: get(item, "imyaVoditeli", ""),
        imyaTovar: get(item, "imyaTovar", ""),
        kol: get(item, "kol", ""),
        edIzm: get(item, "edIzm", ""),
        imyaTerritori: get(item, "imyaTerritori", ""),



      });
    });
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      `K${counterRow + count}`
    );
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
      (sheet.getCell(item).font = {
        size: 11,
        bold: true,
      })
    );

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <DatePicker
                  width={"250px"}
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "fromDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  width={"250px"}
                  placeholder="Дата конец"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "toDate", null)}
                  dateFormat="dd.MM.yyyy"
                />


                {/* <Title
                  xx
                  className="title_style"
                  mk
                  style={{ justifyContent: "left" }}
                >
                  Детально{" "}
                  <Checkbox
                    handleChange={() => {
                      handleChangeFilter({
                        name: "detalno",
                        value: !check,
                      });
                      setCheck(!check);
                    }}
                  />
                </Title> */}
              </FlexBox>
            </Col>

            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(shipmentDate, "shipmentList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchReportShipmentData}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  width={"250px"}
                  placeholder={"Контрагент"}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  loading={contractorDropdowns}
                  handleValueLabel={(e: any) => {
                    if (filter.kontragentId === e.value) return;

                    handleChangeFilter({
                      name: "kontragentId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      kontragent: get(e, "label", ""),
                    });

                    if (shipmentDate.shipmentList === null) return;
                    dispatch(
                      fetchReportShipment({
                        ...filter,
                        kontragentId: e.value,
                      })
                    );
                  }}
                />

                <BaseSelect
                  width={"250px"}
                  options={productDropdowns}
                  placeholder="Вид товара"
                  value={get(filter, "vidTovaraId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.vidTovaraId === e.value) return;

                    handleChangeFilter({
                      name: "vidTovaraId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      tipTovara: get(e, "label", ""),
                    });

                    if (shipmentDate.shipmentList === null) return;
                    dispatch(
                      fetchReportShipment({
                        ...filter,
                        vidTovaraId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width={"200px"}
                  options={territoryDropdowns}
                  placeholder="Tерритория"
                  value={get(filter, "territoriyaId", null)}
                  handleValueLabel={(e: any) => {
                    // dispatch(
                    //   filterShipmentTable({
                    //     ...filter,
                    //     name: "skladId",
                    //     value: e.skladGPId,
                    //   })
                    // );

                    if (filter.territoriyaId === e.value) return;

                    handleChangeFilter({
                      name: "territoriyaId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      territoriya: get(e, "label", ""),
                      // sklad: e.imyaSkladGP.slice(5),
                    });

                    if (shipmentDate.shipmentList === null) return;
                    dispatch(
                      fetchReportShipment({
                        ...filter,
                        territoriyaId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width={"200px"}
                  options={paymentDropdowns}
                  placeholder="Способ оплаты"
                  value={get(filter, "sposobOplataId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.sposobOplataId === e.value) return;

                    handleChangeFilter({
                      name: "sposobOplataId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      sposobOplata: get(e, "label", ""),
                    });

                    if (shipmentDate.shipmentList === null) return;
                    dispatch(
                      fetchReportShipment({
                        ...filter,
                        sposobOplataId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  width={"200px"}
                  options={tipLitsaId}
                  placeholder="Тип лица"
                  value={get(filter, "tipLitsaId", null)}
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "tipLitsaId",
                      value: e.value,
                    });
                    setDetails({
                      ...details,
                      tipLitsaId: get(e, "label", ""),
                    });
                    if (shipmentDate.shipmentList === null) return;
                    dispatch(
                      fetchReportShipment({
                        ...filter,
                        tipLitsaId: e.value,
                      })
                    );
                  }}
                />
                {/* <BaseSelect
                  width={"200px"}
                  isDisabled={true}
                  options={skladGPDropdowns}
                  placeholder="Склад"
                  disabled={true}
                  value={get(filter, "skladId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.skladId === e.value) return;

                    handleChangeFilter({
                      name: "skladId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      sklad: get(e, "label", ""),
                    });

                    if (shipmentDate.shipmentList === null) return;
                    dispatch(
                      fetchReportShipment({
                        ...filter,
                        skladId: e.value,
                      })
                    );
                  }}
                /> */}
              </FlexBox>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {!shipmentDate.isInitialState ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Отчет по отгрузке готовой продукции"}
                  />

                  {!isEmpty(get(shipmentDate, "shipmentList", [])) ? (
                    <ReportOnShipmentsTable data={shipmentDate} click={check} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={11} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default ReportOnShipmentContainer;
