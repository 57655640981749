// PERMISSIONS
export const PROTECTED_TYPE = {
  PERMISSIONS: "PERMISSIONS",
  PAGES: "PAGES",
  DEPARTMENTS: "DEPARTMENTS",
  DOCUMENT_PERMISSIONS: "DOCUMENT_PERMISSIONS",
};
export const CONTEXT = {
  RECORD: "recordLoading",
  UNDO: "undoLoading",
  DELETE: "deleteLoading",
};
export const PRODUCT_STATS = {
  MS: "м³",
  SHT: "шт",
};

export const COMPONY_NAME = "OLMOS BLOK";
// MESSAGES
export const MESSAGES = {
  DELETED: "Удалено!",
  CHANGED: "Изменено!",
  ADDED: "Добавлено",
  SEVED: "Сохранено",
  DONE: "Выполнено",
};

// DOCUMENT
export const DOCUMENT = {
  NUM: "Номер",
  DOC: "от",
  KONTRAGENT: "Контрагент",
};
// ACTIONS
export const ACTION = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const PAGE = {
  REQ: "Реквизиты",
  SAVE: "Сохранить",
  SHIPMENT: "Отгрузить",
  CHANGE: "Изменить",
  NUMBER: "Номер",
  DONE: "Записать",
  ADD_AND_PRINT: "Записать и печать",
  CLOSE: "Закрыть",
  ADD: "Добавить",
  SEAL: "Печать",
  ADD_DATA: "Ввод данных",
  NO_DATA: "Нет данных",
  FILL: "Заполнить",
  TABLE_PART: "Табличная часть",
};

export const TABLE = {
  H_24: "Последние 24 часа:",
  ADD: "+ Добавить",
  ALL_DOCS: "Всего документов",
  APPLY: "Применять",
  RESET: "Сброс",
};
