import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";
import EnumerationsServices from "services/apiServices/perechislenya";

const initialState: InitialState = {
  data: { loading: false, dataList: [], error: "" },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchLegalPhysPerson = createAsyncThunk(
  "allLegalPhysPerson/fetchLegalPhysPerson",
  async (_, thunkAPI) => {
    try {
      const request = await EnumerationsServices.GetLegalPhysPerson();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const LegalPhysPersonSlices = createSlice({
  name: "allLegalPhysPerson",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLegalPhysPerson.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchLegalPhysPerson.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchLegalPhysPerson.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export default LegalPhysPersonSlices.reducer;
