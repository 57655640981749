import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: any = {
    filter: {
        tovarId: null,
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    data: {
        loading: false,
        data: [],
        pagination: {},
        error: "",
    },
    details: {
        data: []
    },
    statistic: {
        loading: false,
        data: {},
        error: "",
    }
};

export const fetchAllManualPosting = createAsyncThunk(
    "manualPosting/fetchAllManualPosting",
    async (params: any, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchAllManualPosting(params);
            const respond = await request.data;

            return respond
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchManualPostingStat = createAsyncThunk(
    "manualPosting/fetchManualPostingStat",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchManualPostingStat();
            const respond = await request.data;
            return respond
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const manualPostingSlices = createSlice({
    name: "manualPosting",
    initialState,
    reducers: {
        filterChangeManualPosting: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },

        changeDetailsManualPost: (state, action) => {
            const id = get(action, "payload.id", null);
            if (action.payload.type === ACTION.UPDATE) {
                state.details = {
                    ...state.details,
                    data: state.details.data.map(
                        (item: any, index: number) =>
                            index == id
                                ?
                                get(action, "payload.data", {})
                                : item
                    ),
                };
            } else if (action.payload.type === ACTION.DELETE) {

                state.details = {
                    ...state.details,
                    data: state.details.data.filter(
                        (item: any, index: number) => index !== id
                    ),
                };
            }
            else {
                state.details = {
                    ...state.details,
                    data: []
                };
            }
        },
        handleAddSubjectPosting: (state, actions) => {
            state.details = {
                ...state.details,
                data: [...state.details.data, actions.payload]
            }
        },
        subjectManualPosting: (state, actions) => {
            state.details = {
                ...state.details,
                data: actions.payload
            }
        },
        clearManualPostingDetails: (state) => { state.details = initialState.details },
        clearManualPosting: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllManualPosting.pending, (state) => {
            state.data = {
                loading: true,
            };
        });
        builder.addCase(fetchAllManualPosting.fulfilled, (state, actions) => {
            state.data = {
                loading: false,
                data: get(actions, "payload.data", {}),
                pagination: get(actions, "payload.pagination", {}),
                error: "",
            };
        });
        builder.addCase(fetchAllManualPosting.rejected, (state, actions) => {
            state.data = {
                loading: false,
                data: {},
                error: get(actions, "error.message", ""),
            };
        });
        builder.addCase(fetchManualPostingStat.pending, (state) => {
            state.statistic = {
                loading: true,
            };
        });
        builder.addCase(fetchManualPostingStat.fulfilled, (state, actions) => {
            state.statistic = {
                loading: false,
                data: get(actions, "payload.data", {}),
                error: "",
            };
        });
        builder.addCase(fetchManualPostingStat.rejected, (state, actions) => {
            state.statistic = {
                loading: false,
                data: {},
                error: actions.error.message,
            };
        });
    },

});

export const { filterChangeManualPosting,
    handleAddSubjectPosting,
    subjectManualPosting,
    clearManualPostingDetails,
    changeDetailsManualPost,
    clearManualPosting,
} = manualPostingSlices.actions;
export default manualPostingSlices.reducer;
