import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";
// Ввод прочих производственных расходов redux
const initialState: any = {
  filter: {
    territoriyaId: null,
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  prodOtherCosts: {
    loading: false,
    data: [],
    error: "",
  },
  prodOtherCostsTemp: {
    loading: false,
    data: [],
    error: "",
  },
  statistic: {
    loading: false,
    data: {},
    error: "",
  },
  details: {
    data: [],
  },
};

export const fetchAllOtherProdCosts = createAsyncThunk(
  "otherCosts/fetchAllOtherProdCosts",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchAllOtherProdCosts(params);
      const respond = await request.data;
      const { data, pagination } = await respond;
      return { data, pagination };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllOtherProdCostsTemp = createAsyncThunk(
  "otherCosts/fetchAllOtherProdCostsTemp",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchAllOtherProdCostTemp();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllOtherProdCostsStat = createAsyncThunk(
  "otherCosts/fetchAllOtherProdCostsStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchOtherProdCostStat();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const otherCostsSlice = createSlice({
  name: "otherCosts",
  initialState,
  reducers: {
    handleFilterChange: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    makeDetailsOtherCost: (state, action) => {
      state.details = {
        ...state.details,

        data: !isEmpty(get(action, "payload", [])) ? action.payload : [],
      };
    },
    clearFilterOtherCost: (state) => {
      state.filter = initialState.filter;
    },
    movementOtherCost: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.details = {
          ...state.details,

          data: !isEmpty(get(action, "payload.data", []))
            ? [...state.details.data, action.payload.data]
            : [],
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.details = {
          ...state.details,
          data: state.details.data.map((item: any, index: any) =>
            index == id ? get(action, "payload.data", {}) : item
          ),
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.details = {
          ...state.details,
          data: state.details.data.filter(
            (item: any, index: number) => index !== id
          ),
        };
      }
    },
    handleFormChange: (state, actions) => {
      state.addingForm = {
        ...state.addingForm,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearOtherExpenses: (state) => {
      state.details = initialState.details;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllOtherProdCosts.pending, (state) => {
      state.prodOtherCosts = {
        loading: true,
      };
    });
    builder.addCase(fetchAllOtherProdCosts.fulfilled, (state, actions) => {
      state.prodOtherCosts = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllOtherProdCosts.rejected, (state, actions) => {
      state.prodOtherCosts = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });

    builder.addCase(fetchAllOtherProdCostsTemp.pending, (state) => {
      state.prodOtherCostsTemp = {
        loading: true,
      };
    });
    builder.addCase(fetchAllOtherProdCostsTemp.fulfilled, (state, actions) => {
      state.prodOtherCostsTemp = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllOtherProdCostsTemp.rejected, (state, actions) => {
      state.prodOtherCostsTemp = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });

    builder.addCase(fetchAllOtherProdCostsStat.pending, (state) => {
      state.statistic = {
        loading: true,
      };
    });
    builder.addCase(fetchAllOtherProdCostsStat.fulfilled, (state, actions) => {
      state.statistic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllOtherProdCostsStat.rejected, (state, actions) => {
      state.statistic = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleFilterChange,
  handleFormChange,
  movementOtherCost,
  clearOtherExpenses,
  makeDetailsOtherCost,
  clearFilterOtherCost,
} = otherCostsSlice.actions;
export default otherCostsSlice.reducer;
