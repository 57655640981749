import { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { Col, Row } from "react-grid-system";
import { get, isEmpty, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "app/hook";
import { salesTableFilterDate, salesTableList } from "app/states/reports";
import { getPaymentMethodData } from "app/states/enumerations/selectStates";
import ExcelJS from "exceljs";
import {
  contractorOptions,
  deliveryMethodsOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  ReactModal,
  Title,
} from "components";
import {
  changeSalesTable,
  clearSalesTable,
  fetchRegisterSales,
  filterSalesTable,
} from "app/slices/reportSlices/registerSalesSlices/registerSalesSlices";

import ReportInfoTitle from "../components/ReportInfoTitle";

import TableContentLoader from "../louders/TableContentLoader";
import XLSX from "xlsx";
import { RegisterSlicesContractTable } from "../components";
import { NumericFormat } from "react-number-format";
import NotFound from "../components/NotFound";

type ParamsType = {
  name?: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  kontragent?: string;
  sposobOplata?: string;
  sposobDastafki?: string;
}

// Реестр договоров на продажу
const RegisterSlicesContractContainer = () => {
  const dispatch = useAppDispatch();
  const salesData = useAppSelector(salesTableList);
  const filter = useAppSelector(salesTableFilterDate);
  const contractorDropdowns = useAppSelector(contractorOptions);
  let loadingOrders = useAppSelector(loadingConterpart);
  const deliveryMethodsDropdowns = useAppSelector(deliveryMethodsOptions);
  const paymentMethod = useAppSelector(getPaymentMethodData);
  const [check, setCheck] = useState(false);
  const LOADING_TABLE = get(salesData, "loading", []);

  const [details, setDetails] = useState<ParamState>({
    kontragent: "",
    sposobOplata: "",
    sposobDastafki: "",
  });

  const fetchRegisterSalesData = useCallback(() => {
    dispatch(fetchRegisterSales(filter));
  }, [dispatch, filter]);

  // clear filter
  const handleClearFilter = () => {
    dispatch(clearSalesTable());

    setDetails({
      kontragent: "",
      sposobOplata: "",
      sposobDastafki: "",
    });
  };

  // change filter
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeSalesTable(null));
    }

    dispatch(filterSalesTable({ ...filter, name, value }));
  };

  // excel

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Реестр договоров на продажу-${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `${check ? "K1" : "I1"}`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "kontragent", ""),
        label: `Заказчик: ${get(details, "kontragent", "")}`,
      },
      {
        value: get(details, "sposobOplata", ""),
        label: `Способ оплаты: ${get(details, "sposobOplata", "")}`,
      },
      {
        value: get(details, "sposobDastafki", ""),
        label: `Способ доставки: ${get(details, "sposobDastafki", "")}`,
      },
      {
        value: get(details, "dateIsp", ""),
        label: `Дата исполнения договора: ${get(details, "dateIsp", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell("A1").value = `Реестр договоров на продажу с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(
          `A${1 + index + 1}`,
          `${check ? "K" + (1 + index + 1) : "I" + (1 + index + 1)}`
        );
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Номер договора",
        key: "nomerDoc",
        width: 30,
      },
      {
        header: "Дата договора",
        key: "dataDoc",
        width: 25,
      },
      {
        header: "Заказчик",
        key: "imyaKontragent",
        width: 35,
      },
      {
        header: "Дата испол",
        key: "dataIsp",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "sposobOplataName",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "imyaUsloviyaOplati",
        width: 20,
      },
      {
        header: "Способ доставки",
        key: "sposobDostavkaName",
        width: 30,
      },
      {
        header: "Сумма",
        key: "summa",
        width: 20,
      },
    ];
    const newTableHeader = check
      ? tableHeader.splice(
          8,
          0,
          check && {
            header: "Товар",
            key: "imyaTovar",
            width: 25,
          },
          check && {
            header: "Количество",
            key: "kol",
            width: 25,
          }
        )
      : tableHeader;
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(salesData, "dataList.list", [])?.forEach((item, index) => {
      if (!check) {
        sheet.addRow({
          order: index + 1,
          nomerDoc: get(item, "nomerDoc", ""),
          dataDoc: get(item, "dataDoc", ""),
          imyaKontragent: get(item, "imyaKontragent", ""),
          dataIsp: get(item, "dataIsp", ""),
          sposobOplataName: get(item, "sposobOplataName", ""),
          imyaUsloviyaOplati: get(item, "imyaUsloviyaOplati", ""),
          sposobDostavkaName: get(item, "sposobDostavkaName", ""),
          summa: get(item, "summa", 0) ? Number(get(item, "summa", 0)) : 0,
        });
        counterRow++;
      } else {
        sheet.addRow({
          order: index + 1,
          nomerDoc: get(item, "nomerDoc", ""),
          dataDoc: get(item, "dataDoc", ""),
          imyaKontragent: get(item, "imyaKontragent", ""),
          dataIsp: get(item, "dataIsp", ""),
          sposobOplataName: get(item, "sposobOplataName", ""),
          imyaUsloviyaOplati: get(item, "imyaUsloviyaOplati", ""),
          sposobDostavkaName: get(item, "sposobDostavkaName", ""),
          summa: get(item, "summa", 0) ? Number(get(item, "summa", 0)) : 0,
        });
        counterRow += 1;
        get(item, "details", []).forEach((detail) => {
          const row = sheet.addRow({
            order: "ㅤ",
            nomerDoc: "ㅤ",
            dataDoc: "ㅤ",
            imyaKontragent: "ㅤ",
            dataIsp: "ㅤ",
            sposobOplataName: "ㅤ",
            imyaUsloviyaOplati: "ㅤ",
            sposobDostavkaName: "ㅤ",
            imyaTovar: get(detail, "imyaTovar", ""),
            kol: Number(get(detail, "kol", "")),
            summa: Number(get(detail, "summa", "")),
          });
          row.eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
            };
          });
          counterRow++;
        });
      }
    });
    sheet.mergeCells(
      `A${counterRow + count + 2}`,
      `H${counterRow + count + 2}`
    );
    sheet.getCell(`A${counterRow + count + 2}`).value = "Итого:";
    sheet.getCell(`${check ? "K" : "I"}${counterRow + count + 2}`).value =
      Number(get(salesData, "dataList.itogo.summa", 0));

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(
      `${check ? "K" : "I"}${counterRow + count + 2}`
    );
    // const startCell = worksheet.getCell('A1');
    // const endCell = worksheet.getCell('K17');

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "fromDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  value={get(filter, "toDate", null)}
                  dateFormat="dd.MM.yyyy"
                />
                <Title
                  xx
                  className="title_style"
                  mk
                  style={{ justifyContent: "left" }}
                >
                  Детально{" "}
                  <Checkbox
                    handleChange={() => {
                      handleChangeFilter({
                        name: "withDetails",
                        value: !check,
                      });
                      setCheck(!check);
                    }}
                  />
                </Title>
              </FlexBox>
            </Col>

            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(salesData, "dataList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchRegisterSalesData}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={9} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  options={contractorDropdowns}
                  value={get(filter, "kontragentId", "")}
                  placeholder="Заказчик"
                  isLoading={loadingOrders}
                  handleValueLabel={(e: any) => {
                    if (filter.kontragentId === e.value) return;

                    handleChangeFilter({
                      name: "kontragentId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      kontragent: get(e, "label", ""),
                    });

                    if (salesData.dataList === null) return;
                    dispatch(
                      fetchRegisterSales({
                        ...filter,
                        kontragentId: e.value,
                      })
                    );
                  }}
                />
                <DatePicker
                  placeholder="Дата исполнения"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "dataIsp", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "dataIsp",
                      value: moment(e).format("YYYY-MM-DD"),
                    });

                    if (salesData.dataList === null) return;
                    dispatch(
                      fetchRegisterSales({
                        ...filter,
                        dataIsp: moment(e).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                />

                <BaseSelect
                  options={paymentMethod}
                  placeholder="Способ оплаты"
                  value={get(filter, "sposobOplataId", "")}
                  handleValueLabel={(e: any) => {
                    if (filter.sposobOplataId === e.value) return;

                    handleChangeFilter({
                      name: "sposobOplataId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      sposobOplata: get(e, "label", ""),
                    });

                    if (salesData.dataList === null) return;
                    dispatch(
                      fetchRegisterSales({
                        ...filter,
                        sposobOplataId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  options={deliveryMethodsDropdowns}
                  value={get(filter, "sposobDostavkaId", "")}
                  placeholder="Способ доставки"
                  handleValueLabel={(e: any) => {
                    if (filter.sposobDostavkaId === e.value) return;

                    handleChangeFilter({
                      name: "sposobDostavkaId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      sposobDastafki: get(e, "label", ""),
                    });

                    if (salesData.dataList === null) return;
                    dispatch(
                      fetchRegisterSales({
                        ...filter,
                        sposobDostavkaId: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {salesData.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Реестр договоров на продажу"}
                  />

                  {!isEmpty(get(salesData, "dataList.list", [])) ? (
                    <RegisterSlicesContractTable
                      dataList={salesData}
                      click={check}
                    />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default RegisterSlicesContractContainer;
