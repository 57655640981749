import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { InitialState } from "./type"
import UsersServices from "services/apiServices/users"
import { get, isEmpty, isEqual } from "lodash"

const initialState: InitialState = {
    data: {
        loading: false,
        data: [],
        error: "",
        pagination: {},
    },
    filter: {
        search: "",
        page: 0,
        size: 10,
    },
    permissions: {
        loading: false,
        permissionsData: [],
    },
    details: {
        permissionsIds: []
    }

}

export const fetchUserRole = createAsyncThunk(
    "userRole/fetchUserRole",
    async (params: any, thunkAPI) => {
        try {
            const respond = await UsersServices.FetchUserRole(params)
            return respond.data
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
)

export const fetchUserRolePermissions = createAsyncThunk(
    "userRole/fetchUserRolePermissions",
    async (params: any, thunkAPI) => {
        try {
            const respond = await UsersServices.FetchPermissions(params)
            return respond.data
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
)
export const fetchCheckedByTypeRolePerm = createAsyncThunk(
    "userRole/fetchCheckedByTypeRolePerm",
    async (params: any, thunkAPI) => {
        try {
            const respond = await UsersServices.FetchPermissions(params)
            return respond.data
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
)
const userRoleSlices = createSlice({
    name: "userRole",
    initialState,
    reducers: {
        clearFilterUserPerm: (state) => { state.filter = initialState.filter },
        filterChangeUserRole: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
        handleAddUserRoleDetails: (state, actions) => {
            state.details = {
                ...state.details,
                permissionsIds: !state.details.permissionsIds.includes(actions.payload.id) ?
                    [...state.details.permissionsIds, actions.payload.id] : state.details.permissionsIds
            }
        },
        pushUserRoleArray: (state, action) => {
            state.details = {
                ...state.details,
                permissionsIds: action.payload

            }
        },
        removeUserRoleDetails: (state, actions) => {
            state.details = {
                ...state.details,
                permissionsIds: [...state.details.permissionsIds.filter((item: any) => item !== actions.payload.id)]
            }
        },
        clearUserRoleDetails: (state) => {
            state.details.permissionsIds = []
        },
        clearUserRoleData: (state) => {
            state.permissions = initialState.permissions
            state.details = initialState.details
        },
        handleCheckUserDetails: (state, action) => {
            const { outIndex1, middleIndex1, id, check } = action.payload

            state.permissions = {
                ...state.permissions,
                permissionsData: state?.permissions?.permissionsData?.map((outItem: any, outIndex) =>
                    outIndex === outIndex1 ?
                        {
                            ...outItem,
                            pages: outItem?.pages?.map((pageItem: any, middleIndex: number) =>

                                middleIndex === middleIndex1 ?
                                    {
                                        ...pageItem,
                                        actions: pageItem.actions.map((item: any, index: number) =>

                                            item.permissionId === id ? {
                                                ...item,
                                                checked: check
                                            } : item
                                        )
                                    } :
                                    pageItem
                            )
                        } :
                        outItem

                )
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserRole.pending, (state) => {
            state.data = {
                ...state.data,
                loading: true,
            };
        });
        builder.addCase(fetchUserRole.fulfilled, (state, action) => {
            state.data = {
                loading: false,
                data: get(action, "payload.data", []),
                pagination: get(action, "payload.pagination", {})
            }
        });
        builder.addCase(fetchUserRole.rejected, (state, action) => {
            state.data = {
                ...state.data,
                loading: false,
                error: action.error.message
            }
        });
        // permission check
        builder.addCase(fetchUserRolePermissions.pending, (state) => {
            state.permissions = {
                ...state.permissions,
                loading: true,
            }
        }
        )
        builder.addCase(fetchUserRolePermissions.fulfilled, (state, actions) => {
            state.permissions = {
                loading: false,
                permissionsData: get(actions, "payload.data", []),
            }
        })
        builder.addCase(fetchUserRolePermissions.rejected, (state, actions) => {
            state.permissions = {
                loading: false,
                permissionsData: [],
                error: actions.error.message
            }
        })
        // permission check
        builder.addCase(fetchCheckedByTypeRolePerm.pending, (state) => {
            state.permissions = {
                ...state.permissions,
                loading: true,
            }
        }
        )
        builder.addCase(fetchCheckedByTypeRolePerm.fulfilled, (state, actions) => {
            state.permissions = {
                loading: false,
                permissionsData: get(actions, "payload.data", []),
            }
        })
        builder.addCase(fetchCheckedByTypeRolePerm.rejected, (state, actions) => {
            state.permissions = {
                loading: false,
                permissionsData: [],
                error: actions.error.message
            }
        })
    }
})
export const {
    filterChangeUserRole,
    handleCheckUserDetails,
    handleAddUserRoleDetails,
    removeUserRoleDetails,
    pushUserRoleArray,
    clearUserRoleData,
    clearUserRoleDetails,
    clearFilterUserPerm
} = userRoleSlices.actions;
export default userRoleSlices.reducer;