import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
  data: { loading: false, shipmentList: [], isInitialState: true, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    vidTovaraId: null,
    territoriyaId: null,
    sposobOplataId: null,
    kontragentId: null,
    tipLitsaId: null,
    // skladId: null,
    // detalno: true,
  },
};

export const fetchReportShipment = createAsyncThunk(
  "reportShipmentTable/fetchReportShipment",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchReportShipmentTable(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const reportShipmentTable = createSlice({
  name: "reportShipmentTable",
  initialState,
  reducers: {
    filterShipmentTable: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = { ...state.filter, [name]: value };
    },
    clearShipmentTable: () => initialState,
    changeShipmentTable: (state, action) => {
      state.data = {
        loading: false,
        shipmentList: action.payload,
        isInitialState: true,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportShipment.pending, (state) => {
      state.data = {
        loading: true,
        shipmentList: [],
        isInitialState: true,
        error: "",
      };
    });
    builder.addCase(fetchReportShipment.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        shipmentList: get(action, "payload.data", []),
        isInitialState: false,
        error: "",
      };
    });
    builder.addCase(fetchReportShipment.rejected, (state, payload) => {
      state.data = {
        loading: false,
        shipmentList: [],
        isInitialState: true,
        error: payload.error.message,
      };
    });
  },
});

export const { filterShipmentTable, clearShipmentTable, changeShipmentTable } =
  reportShipmentTable.actions;
export default reportShipmentTable.reducer;
