import { createSelector } from "@reduxjs/toolkit";
import { contextMenu, menus, others } from "app/selectors/menu/selectors";
import { selectProfileData } from "app/selectors/profile";
import { ChartDataType } from "app/slices/homeSlices/type";
import { RootState } from "app/store";
import { get } from "lodash";
export const contextLoading = createSelector(contextMenu, (loads) => loads);
export const breadcrumbsURLs = createSelector(
  [others, menus],
  (others, menus) => {
    const urls: any = [...others];
    menus.forEach((item: any) => {
      urls.push({ name: item.name, id: item.id });
      get(item, "pages", []).forEach((page: any) => urls.push(page));
    });
    return urls;
  }
);

export const navBarMenus = createSelector(menus, (menus) =>
  menus.map(
    ({
      name,
      id,
      department,
    }: {
      name: string | number | null;
      id: string | number | null;
      department: string;
    }) => ({ name, id, department })
  )
);
export const sideBarMenus = createSelector(
  [menus, (store: RootState, navBar: string) => navBar],
  (menus, navBar) => menus.find((item: any) => item.id == navBar)?.pages
);
export const sideBarSearchByDep = createSelector(
  [menus, (store: RootState, navBar: string) => navBar],
  (menus, navBar) => menus.find((item: any) => item.department == navBar)?.pages
);
export const FilterDocumentList = createSelector(
  [menus, selectProfileData, (store: RootState, navBar: string) => navBar],
  (menus, dataProfile, navBar) => {
    const documentList = get(dataProfile, "profileData.documentList", []);
    const currentSidebarList = menus.find((item: any) => item.id == navBar)
      ?.pages;
    if (documentList && currentSidebarList) {
      const document = currentSidebarList
        .slice()
        .sort((item1: any, item2: any) => {
          const indexA = documentList.indexOf(item1.page);
          const indexB = documentList.indexOf(item2.page);
          return indexA - indexB;
        });

      return document;
    }
  }
);
