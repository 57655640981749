import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";

const initialState: any = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    kassaId: null,
  },
};

export const fetchCashExpense = createAsyncThunk(
  "registerCashExpense/fetchCashExpense",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchCashExpense(params);
      const respond = await request.data;
      return respond;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const registerCashExpenseSlices = createSlice({
  name: "registerCashExpense",
  initialState,
  reducers: {
    filterCashExpense: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearCashExpense: () => initialState,
    changeCashExpense: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCashExpense.pending, (state: any) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchCashExpense.fulfilled, (state: any, action: any) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchCashExpense.rejected, (state: any, payload: any) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const { filterCashExpense, clearCashExpense, changeCashExpense } = registerCashExpenseSlices.actions;
export default registerCashExpenseSlices.reducer;
