import styled, { css } from "styled-components";

const StyledContent = styled.section`
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
        0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 0px 0px;
    padding: 20px 20px 20px 20px;
    width: 100%;
    max-height: 100%;
    min-height: ${({ height }: { height: string }) => height || "max-content"};
    ${({ rounded }: { rounded: boolean }) =>
        rounded &&
        css`
            border-radius: 8px;
        `};
`;

export { StyledContent };
