import styled, { css } from "styled-components";
import { StyleProps } from "./type";
export const PickerWrapper = styled.div<StyleProps>`
  position: relative;
  background: #ffffff;
  border: 1px solid hsl(0, 0%, 80%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 8px 12px;
  height: 35px;
  border-radius: 8px;
  width: 100%;
  margin: ${({ margin }: StyleProps) => margin || "0px"};
  max-width: ${({ width }: StyleProps) => width || "320px"};
  cursor: pointer;
  .calendar-icon {
    position: absolute;
    top: 5px;
    left: 10px;
  }
  .arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    right: 10px;
  }

  .react-datepicker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .react-datepicker__input-container {
      input {
        width: 100%;
        background: transparent;
        font-weight: 500;
        font-size: 14px;
        color: #1c1c1c;
        border: none;
        outline: none;
        padding: 8px 15px 8px 40px;
        cursor: pointer;

        &::placeholder {
          color: #1c1c1c;
        }
      }
    }
  }
  /*  clear buton */
  .react-datepicker__close-icon::before,
  .react-datepicker__close-icon::after {
    background: none;
    color: #c4bfbe;
    font-size: 25px;
    margin-right: 30%;
  }

  .react-datepicker__close-icon {
    padding-right: 10%;
    float: right;
  }
  .react-datepicker__close-icon:hover {
    color: red;
  }
  /* end */
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &:focus-within {
    border-color: #2684ff;
  }
`;
