import { TableWrapper } from "./styles/style";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import moment from "moment";

const WorkOrderRegisterTable = ({ data }: any) => {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth="1600px" className="mb-16">
          <thead>
            <tr>
              <th>№</th>
              <th>
                Номер
                <br /> Заказ-наряда
              </th>
              <th>
                Дата
                <br /> Заказ-наряда
              </th>
              <th>Tерритория</th>
              <th style={{ width: "180px" }}>Заказчик</th>
              <th>Дата испол.</th>
              <th style={{ width: "160px" }}>Адрес доставки</th>
              <th style={{ width: "160px" }}>Товар</th>
              <th>Количество</th>
              <th>Выполнение </th>
            </tr>
          </thead>
          <tbody>
            {/* the map */}
            {get(data, "dataList.list", []).map((item: any, index: number) => (
              <>
                <tr key={index + 1}>
                  <td>{index + 1}</td>
                  <td>{get(item, "nomerDoc", "")}</td>
                  <td>
                    {moment(get(item, "dataDoc", "")).format("DD.MM.YYYY")}
                  </td>
                  <td>{get(item, "imyaTerritoriya", "")}</td>
                  <td>{get(item, "kontragentImya", "")}</td>
                  <td>
                    {moment(get(item, "dateIsp", "")).format("DD.MM.YYYY")}
                  </td>
                  <td>{get(item, "imyaAddresaDostavki", "")}</td>

                  <td></td>
                  <td></td>
                  <td>{!get(item, "otmVipolneniya", "") ? "Нет" : "Да"}</td>
                </tr>
                {!isEmpty(get(item, "details", []))
                  ? get(item, "details", []).map(
                      (product: any, index: number) => (
                        <tr
                          style={{ backgroundColor: "#f8f8f8" }}
                          key={index + 1}
                        >
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{get(product, "imyaTovara", "")}</td>
                          <td>{get(product, "kol", "")}</td>
                          <td></td>
                        </tr>
                      )
                    )
                  : null}
              </>
            ))}
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
};

export default WorkOrderRegisterTable;
