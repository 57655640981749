import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
  data: { loading: false, priceData: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
    inAscOrder: false
  },
};

export const fetchPrice = createAsyncThunk(
  "allPrice/fetchPrice",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetPrice(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const priceSlices = createSlice({
  name: "allPrice",
  initialState,
  reducers: {
    handlePriceFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    handlePriceFilterClear:(state)=>{
      state.filter=initialState.filter
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrice.pending, (state) => {
      state.data = {
        loading: true,
        priceData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchPrice.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        priceData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchPrice.rejected, (state, payload) => {
      state.data = {
        loading: false,
        priceData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handlePriceFilter,handlePriceFilterClear } = priceSlices.actions;
export default priceSlices.reducer;
