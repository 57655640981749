import request from "services/api";
class HomeService {
  static ChartStatusData = (params: any) => {
    return request.put("/api/dashboard", { list: params });
  };

  static FetchProductByDate = (params: {
    vidTovaraId: string;
    fromDate?: string | Date;
    toDate?: string | Date;
  }) => {
    return request.get("/api/dashboard/vid-tovara", { params: params });
  };
}

export default HomeService;
