import { Space, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchAllArrivedCashes,
  fetchAllArrivedCashesStat,
  fetchAllArrivedCashesTemp,
} from "app/slices/documentSlices/arrivedCashesSlice/arrivedCashesSlice";
import { fetchSalesContracts } from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import { fetchIncomes } from "app/slices/enumerationsSlices/incomeSlices/incomeSlices";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchOwnKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { arrivedCashFilter } from "app/states/documents";
import {
  contractorOptions,
  getOnlyContractorIdOptions,
} from "app/states/documents/selectStates";
import { tipPrixodaOptions } from "app/states/enumerations/selectStates";
import { getKassaData } from "app/states/handbooks/selectStates";
import {
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  Button,
  Content,
  DatePicker,
  DisappearedBox,
  FlexBox,
  Form,
  FormButton,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual, isObject } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { isElementAccessExpression } from "typescript";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { checkDateYear, textFormatter } from "utilities/helpers";
import { NumberDocType } from "types/documentsType";

// Приход наличных денег−> Добавить

const AddArrivalCashContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const onSubmit = (values: any) => {
    const params: any = {};
    forEach(values, (value, key) => {
      if (value) {
        if (key == "dataDoc" || !isObject(value)) {
          params[key] = value;
        } else {
          params[key] = get(value, "value", "");
        }
      }
    });
    try {
      DocumentServices.AddArrivalCash({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllArrivedCashes(filter));
          dispatch(fetchAllArrivedCashesStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveArrivalCashesTemp(get(state, "id", "")).then(
              (res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchAllArrivedCashesTemp());
                }
              }
            );
          }
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  const initialValues = {
    nomerDoc: "",
    dataDoc: null,
    kassaId: "",
    imyaKassa: "",
    tipPrixodaId: "",
    kontragentId: "",
    zakazNaryadId: "",
    imyaKontragent: "",
    dogProdajaId: "",
    summa: "",
    kommentariya: "",
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const kassaOptions = useAppSelector(getKassaData);
  const typePrixodaOptions = useAppSelector(tipPrixodaOptions);
  const kontragentOptions = useAppSelector(contractorOptions);
  const [disable, setDisable] = useState(false);
  const filter = useAppSelector(arrivedCashFilter);
  const [maxAmount, setMaxAmount] = useState<any>(0);
  const getSaleContracts = useAppSelector((store) =>
    getOnlyContractorIdOptions(store, get(values, "kontragentId.value", null))
  );
  // handle update
  const handleUpdateDocument = useCallback(() => {
    const params: any = {};
    forEach(values, (value, key) => {
      if (value) {
        if (key == "dataDoc" || !isObject(value)) {
          params[key] = value;
        } else {
          params[key] = get(value, "value", "");
        }
      }
    });
    try {
      DocumentServices.UpdateAddArrivalCash({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...params,
          id: get(state, "id", null),
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllArrivedCashes(filter));
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveArrivalCashesTemp(get(state, "id", "")).then(
              (res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchAllArrivedCashesTemp());
                }
              }
            );
          }
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values]);
  // submit not fully filled form
  const saveNotFullFilledForm = useCallback(() => {
    const params: any = {
      imyaKassa: get(values, "kassaId.label", ""),
      imyaTipPrixoda: get(values, "tipPrixodaId.label", ""),
      imyaKontragent: get(values, "kontragentId.label", ""),
      nomerZakazNaryad: get(values, "zakazNaryadId.label", ""),
      nomerDogProdaja: get(values, "dogProdajaId.label", ""),
    };
    forEach(values, (value, key) => {
      if (key == "dataDoc" || !isObject(value)) {
        params[key] = value;
      } else {
        params[key] = get(value, "value", "");
      }
    });
    try {
      DocumentServices.SaveArrivalCashesTemp({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllArrivedCashesTemp());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values]);

  const getDocNumber = useCallback(
    (date: NumberDocType) => {
      if (get(state, "isTemp", true)) {
        DocumentServices.ArrivalCashNumber(date).then(({ data }) => {
          setFieldValue("nomerDoc", get(data, "data", ""));
          setFieldValue("dataDoc", moment(new Date()).format("YYYY-MM-DD"));
        });
      }
    },
    [setFieldValue, state]
  );

  const handleSetInitialValues = (data: any) => {
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
    setFieldValue("kassaId", {
      value: get(data, "kassaId", ""),
      label: get(data, "imyaKassa", ""),
    });
    setFieldValue("tipPrixodaId", {
      value: get(data, "tipPrixodaId", ""),
      label: get(data, "imyaTipPrixoda", ""),
    });
    setFieldValue("kontragentId", {
      value: get(data, "kontragentId", ""),
      label: get(data, "imyaKontragent", ""),
    });
    setFieldValue("dogProdajaId", {
      value: get(data, "dogProdajaId", ""),
      label: get(data, "nomerDogProdaja", ""),
    });
    setFieldValue("summa", get(data, "summa", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
  };
  // check details
  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      setLoading(true);
      if (get(state, "isTemp", false)) {
        DocumentServices.FetchArrivalCashesTempById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else if (get(state, "isUpdate", false)) {
        DocumentServices.FetchArrivalCashesById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else {
        DocumentServices.FetchArrivalCashesById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
        setDisable(true);
      }
    } else {
      getDocNumber({});
      setLoading(false);
    }
  }, [dispatch, state]);

  const fetchPriceByKontragent = useCallback(() => {
    try {
      if (
        get(values, "kontragentId.value", null) &&
        isEqual(get(values, "tipPrixodaId.value", ""), 3)
      ) {
        DocumentServices.FetchPriceByArrivalCash({
          id: get(values, "kontragentId.value", null),
          pkoId: get(state, "id", null),
        }).then(({ data }) => {
          if (data) {
            setMaxAmount(data.data);
          } //
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [values.kontragentId]);
  const fetchPriceWidthDogovorId = useCallback(() => {
    try {
      if (
        get(values, "kontragentId.value", null) &&
        get(values, "dogProdajaId.value", null) &&
        isEqual(get(values, "tipPrixodaId.value", ""), 1)
      ) {
        const props = {
          kontragentId: get(values, "kontragentId.value"),
          dogProdajaId: get(values, "dogProdajaId.value", null),
          pkoId: get(state, "id", null),
        };
        DocumentServices.FetchPriceByArrivalCashWidthDog(props).then(
          ({ data }) => {
            if (data) {
              setMaxAmount(data.data);
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [values.kontragentId, values.dogProdajaId]);
  const fetchDogDetails = useCallback(() => {
    if (
      (get(values, "tipPrixodaId.value", "") &&
        get(state, "isUpdate", false)) ||
      isEmpty(get(state, "id", null))
    ) {
      dispatch(clearContractorsState());
      switch (get(values, "tipPrixodaId.value", "").toString()) {
        case "1":
          dispatch(fetchAllCounterpart({ all: true, type: 5 }));
          dispatch(
            fetchSalesContracts({
              all: true,
              filterPage: "PRIXOD_D",
              kontragentId: get(values, "kontragentId.value", null),
            })
          );
          break;
        case "2":
          dispatch(fetchAllCounterpart({ all: true, type: 2 }));
          break;
        case "3":
          dispatch(
            fetchAllCounterpart({ all: true, type: 1, filterPage: "PRIXOD_D" })
          );
          break;
        default:
          break;
      }
    }
  }, [values.tipPrixodaId, values.kontragentId]);

  const fetchFormSelectorOptions = useCallback(() => {
    if (get(state, "isUpdate", false) || isEmpty(get(state, "id", null))) {
      dispatch(fetchOwnKassa({ all: true }));
      dispatch(fetchIncomes({}));
    }
  }, [dispatch]);
  useEffect(() => {
    fetchPriceWidthDogovorId();
  }, [fetchPriceWidthDogovorId]);
  useEffect(() => {
    // if (isEmpty(get(state, "id", null))) {
    fetchFormSelectorOptions();
    // }
  }, [fetchFormSelectorOptions]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    fetchDogDetails();
  }, [fetchDogDetails]);

  useEffect(() => {
    fetchPriceByKontragent();
  }, [fetchPriceByKontragent]);

  return (
    <Content className={"min_height"}>
      {isEqual(loading, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  {PAGE.REQ}
                </Title>
                {isEqual(get(state, "isUpdate", false), false) && (
                  <Button
                    green
                    handleClick={saveNotFullFilledForm}
                    disabled={disable}
                  >
                    {PAGE.SAVE}
                  </Button>
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        name={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          maxDate={new Date()}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Наименование кассы
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kassaId"}
                    defaultValue={get(values, "kassaId", {})}
                    value={get(values, "kassaId.value", "")}
                    options={kassaOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kassaId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Тип прихода
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tipPrixodaId"}
                    defaultValue={get(values, "tipPrixodaId", null)}
                    value={get(values, "tipPrixodaId.value", "")}
                    options={typePrixodaOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("tipPrixodaId", e);
                      if (!isEqual(e, get(values, "tipPrixodaId", false))) {
                        setFieldValue("kontragentId", "");
                        setFieldValue("summa", "");
                        setMaxAmount(null);
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    placeholder={get(values, "kontragentId.label")}
                    value={get(values, "kontragentId.value", "")}
                    defaultValue={get(values, "kontragentId", {})}
                    options={kontragentOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);
                      // for clear when changes value
                      if (
                        get(values, "kontragentId.value", "") !=
                        get(e, "value", "")
                      ) {
                        setFieldValue("dogProdajaId", null);
                        setFieldValue("summa", "");
                        setMaxAmount(null);
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              {isEqual(get(values, "tipPrixodaId.value", ""), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Договор продажа
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"dogProdajaId"}
                      value={get(values, "dogProdajaId.value", "")}
                      defaultValue={get(values, "dogProdajaId", {})}
                      options={getSaleContracts}
                      handleValueLabel={(e: any) => {
                        setFieldValue("dogProdajaId", e);
                        if (!isEqual(e, get(values, "dogProdajaId", false))) {
                          setFieldValue("summa", "");
                        }
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Сумма
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMaskInput
                    id={"summa"}
                    placeholder={
                      maxAmount !== null &&
                      [1, 3].includes(
                        Number(get(values, "tipPrixodaId.value", ""))
                      ) //  limit show only 'Тип прихода' id 1 or 3
                        ? `до: ${textFormatter(maxAmount)}`
                        : ""
                    }
                    value={get(values, "summa", "")}
                    handleInput={(e: any) => setFieldValue("summa", e)}
                    disabled={disable}
                  />
                  <p>
                    {maxAmount !== null &&
                      get(values, "summa", 0) > maxAmount &&
                      [1, 3].includes(
                        Number(get(values, "tipPrixodaId.value", ""))
                      ) &&
                      isEqual(disable, false) && (
                        <Text xs danger>
                          ограничение до:
                          {textFormatter(maxAmount)}
                        </Text>
                      )}
                  </p>
                </Col>
              </Row>
              <TextArea
                disabled={disable}
                id={"kommentariya"}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>

          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                            Печать
                        </Button> */}
              {get(state, "isUpdate", false) ? (
                <Button
                  primary
                  disabled={disable}
                  className={"mr-16"}
                  disable
                  handleClick={handleUpdateDocument}
                >
                  {PAGE.CHANGE}
                </Button>
              ) : (
                <HasAccess>
                  {({ userCan }: any) =>
                    userCan({
                      permission: `${DOCUMENT_KEY.PRIXOD_ND}_CREATE`,
                      page: DOCUMENT_KEY.PRIXOD_ND,
                      department: MAIN_DEPARTMENT.DOCUMENTS,
                      type: PROTECTED_TYPE.PERMISSIONS,
                    }) && (
                      <FormButton
                        disabled={disable}
                        loading={isSubmitting}
                        handleClick={handleSubmit}
                      >
                        {PAGE.DONE}
                      </FormButton>
                    )
                  }
                </HasAccess>
              )}
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Space className="centrality">
          <Spin size="large" />
        </Space>
      )}
    </Content>
  );
};

export default AddArrivalCashContainer;
