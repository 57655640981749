import styled from "styled-components";
import { StyleProps } from "./type";

const SidebarItemWrapper = styled.div<StyleProps>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 55px;
  padding-right: 10px;

  .menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    /* padding: 20px; */
    padding: ${({ padding }: StyleProps) => padding || "20px"};
    padding-left: 15px;
    &.active {
      .menu-icon {
        svg {
          path {
            fill: #0085ff !important;
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
            /* stroke: #0085ff; */
          }
        }
      }

      .menu-stock-icon {
        svg {
          path {
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
            stroke: #0085ff;
          }
        }
      }

      span {
        color: #0085ff;
      }
      background: linear-gradient(
          0deg,
          rgba(0, 133, 255, 0.1),
          rgba(0, 133, 255, 0.1)
        ),
        #ffffff;
      border-radius: 8px;
      font-size: 700;
    }

    &:hover {
      .menu-icon {
        svg {
          path {
            fill: #0085ff !important;
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
        }
      }
      .menu-stock-icon {
        svg {
          path {
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
            stroke: #0085ff;
          }
        }
      }

      span {
        color: #0085ff;
      }

      background: linear-gradient(
          0deg,
          rgba(0, 133, 255, 0.1),
          rgba(0, 133, 255, 0.1)
        ),
        #ffffff;
      border-radius: 8px;
      font-size: 700;
    }
    &:active {
      .menu-icon {
        svg {
          path {
            fill: #0085ff !important;
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
          }
        }
      }
      .menu-stock-icon {
        svg {
          path {
            stroke-dashoffset: 0;
            stroke-dasharray: 700;
            stroke: #0085ff;
          }
        }
      }
      span {
        color: #0085ff;
      }
      background: linear-gradient(
          0deg,
          rgba(0, 133, 255, 0.1),
          rgba(0, 133, 255, 0.1)
        ),
        #ffffff;
      border-radius: 8px;
      font-size: 700;
    }
  }
`;

export { SidebarItemWrapper };
