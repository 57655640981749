import { memo, useEffect, useState } from "react";

const Wrapper = ({ children }: any) => {
    useEffect(() => {
        // Update the window height state when the window is resized
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    return <div className="scroll_style" style={{ height: `calc(${windowHeight}px - 22rem)` }}>{children}</div>;
};

export default memo(Wrapper);
