import { memo, useState } from 'react';
import { TabListWrapper, TabTitle, TabWrapper } from './style';


const BaseTab = (
    {
        children,
        tableHeader = [],
        active = 0,
        setActive = () => { }
    }: any) => {
    return (
        <>
            <TabWrapper>
                <TabListWrapper>
                    {tableHeader &&
                        tableHeader.map((item: string, index: number) => {
                            return <TabTitle key={index} className={active === index ? "tab_color" : ""} onClick={() => setActive(index)}>{item}</TabTitle>;
                        })}
                </TabListWrapper>
                {children}
            </TabWrapper>
        </>
    )
};

export default memo(BaseTab)