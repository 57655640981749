import { useEffect } from "react";
import {
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  BaseTable,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  FormButton,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
} from "components";
import { useAppDispatch } from "app/hook";
import { get, isEmpty, isEqual, map } from "lodash";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { checkDateYear, indexNumber, textFormatter } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import { tipPrixodConnact } from ".";
import { Tag } from "antd";
import { handleFilterARrived } from "app/slices/documentSlices/arrivedCashesSlice/arrivedCashesSlice";
type PropsType = {
  isAdding: boolean;
  setIsAdding: any;
  handleFilterARrived: any;
  documentInfo: any;
  displayMenu: any;
  userName: string;
  handleAdd: () => void;
};
function TipPrixodaTable({
  isAdding,
  setIsAdding,
  displayMenu,
  documentInfo,
  userName,
}: PropsType) {
  const dispatch = useAppDispatch();
  const {
    arrivedCashes,
    // other
    handleUndoRecords,
    handleDeleteRecords,
    handleDeleteDocument,
    getDocNumber,
    values,
    setFieldValue,
    // options
    kassaOptions,
    kontragentOptions,
    typePrixodaOptions,
    getSaleContracts,
    // new
    maxAmount,
    setMaxAmount,
    // submit
    isSubmitting,
    handleUpdateDocument,
    handleSubmit,
    resetForm,
    LOADING_KONTRAGENTS,
    // LOADING
    LOADING_PRIXOD,
    setState,
    state,
  } = tipPrixodConnact();

  const handleClose = () => {
    resetForm();
    setMaxAmount(null);
    setIsAdding(false);
  };
  const handleCancel = () => {
    resetForm();
    setMaxAmount(null);
    setState({ isEdit: false });
  };
  const navigateToForm = ({ id }: { id: string | number | null }) => {
    setState({ id, isEdit: true });
    setIsAdding(false);
  };
  useEffect(() => {
    if (isAdding == true) {
      resetForm();
      setState({ isEdit: false });
      setMaxAmount(null);
    }
  }, [isAdding]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row>
            <Col className="responsive_overflow" style={{ minHeight: "65vh" }}>
              <ContextMenu
                handleNavigate={navigateToForm}
                deleteDocument={handleDeleteDocument}
                handleDeleteRecords={handleDeleteRecords}
                handleUndoRecords={handleUndoRecords}
                openItem={true}
                changeItem={true}
                deleteItem={true}
                markItem={true}
                uncheckItem={true}
                department={"KASSA"}
                userPermission={"KASSA_DOC"}
                documentInfo={documentInfo}
              />
              <BaseTable
                padding="0px"
                tableHeader={[
                  "№",
                  "Пользователь",
                  "Номер",
                  "Дата",
                  "Наименование кассы",
                  "Тип прихода",
                  "Контрагент",
                  "Договор продажа",
                  "Сумма",
                  "В сумах",
                  "В долларах США",
                  "Комментарии",
                  "Время записи",
                  "Статус",
                ]}
                className="table_scroll"
              >
                {!LOADING_PRIXOD ? (
                  <>
                    {isAdding && (
                      <tr>
                        <td>
                          <Tag color="green">новый</Tag>
                        </td>
                        <td>{userName}</td>
                        <td className="min-w-50">
                          <BaseInput
                            id={"nomerDoc"}
                            name={"nomerDoc"}
                            value={get(values, "nomerDoc", "")}
                          />
                        </td>
                        <td className="min-w-80">
                          <DatePicker
                            id={"dataDoc"}
                            value={get(values, "dataDoc", new Date())}
                            handleDate={(e: any) => {
                              if (
                                checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                })
                              ) {
                                getDocNumber({
                                  year: checkDateYear({
                                    currentYear: get(values, "dataDoc", null),
                                    checkYear: moment(e).format("YYYY-MM-DD"),
                                  }),
                                });
                              }
                              setFieldValue(
                                "dataDoc",
                                moment(e).format("YYYY-MM-DD")
                              );
                            }}
                            maxDate={new Date()}
                            dateFormat="dd.MM.yyyy"
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseSelect
                            className="min-w-80"
                            id={"kassaId"}
                            value={get(values, "kassaId.value", "")}
                            defaultValue={get(values, "kassaId", "")}
                            options={kassaOptions}
                            handleValueLabel={(e: any) => {
                              setFieldValue("kassaId", e);
                            }}
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseSelect
                            className="min-w-80"
                            id={"tipPrixodaId"}
                            defaultValue={get(values, "tipPrixodaId", null)}
                            value={get(values, "tipPrixodaId.value", "")}
                            options={typePrixodaOptions}
                            handleValueLabel={(e: any) => {
                              setFieldValue("tipPrixodaId", e);
                              if (
                                !isEqual(e, get(values, "tipPrixodaId", false))
                              ) {
                                setFieldValue("kontragentId", "");
                                setFieldValue("summa", "");
                                setFieldValue("summaInUZS", "");
                                setFieldValue("summaInUSD", "");
                                setMaxAmount(null);
                              }
                            }}
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseSelect
                            id={"kontragentId"}
                            value={get(values, "kontragentId.value", "")}
                            defaultValue={get(values, "kontragentId")}
                            isLoading={LOADING_KONTRAGENTS}
                            options={kontragentOptions}
                            handleValueLabel={(e: any) => {
                              setFieldValue("kontragentId", e);
                              // for clear when changes value
                              if (
                                get(values, "kontragentId.value", "") !=
                                get(e, "value", "")
                              ) {
                                setFieldValue("dogProdajaId", null);
                                setFieldValue("summa", "");
                                setFieldValue("summaInUZS", "");
                                setFieldValue("summaInUSD", "");
                                setMaxAmount(null);
                              }
                            }}
                          />
                        </td>
                        <td className="min-w-80">
                          {isEqual(
                            get(values, "tipPrixodaId.value", ""),
                            1
                          ) && (
                            <BaseSelect
                              id={"dogProdajaId"}
                              value={get(values, "dogProdajaId.value", "")}
                              defaultValue={get(values, "dogProdajaId", {})}
                              options={getSaleContracts}
                              handleValueLabel={(e: any) => {
                                setFieldValue("dogProdajaId", e);
                                if (
                                  !isEqual(
                                    e,
                                    get(values, "dogProdajaId", false)
                                  )
                                ) {
                                  setFieldValue("summa", "");
                                  setFieldValue("summaInUZS", "");
                                  setFieldValue("summaInUSD", "");
                                }
                              }}
                              isDisalbed={
                                !isEqual(
                                  get(values, "tipPrixodaId.value", ""),
                                  1
                                )
                              }
                            />
                          )}
                        </td>
                        <td className="min-w-80">
                          <BaseMaskInput
                            id={"summa"}
                            placeholder={
                              maxAmount !== null &&
                              [1, 3].includes(
                                Number(get(values, "tipPrixodaId.value", ""))
                              ) //  limit show only 'Тип прихода' id 1 or 3
                                ? `до: ${textFormatter(maxAmount)}`
                                : ""
                            }
                            maxLimit={Number(maxAmount)}
                            value={get(values, "summa", "")}
                            handleInput={(e: any) => setFieldValue("summa", e)}
                          />

                          <p>
                            {get(values, "summa", "") > maxAmount &&
                              maxAmount !== null &&
                              [1, 3].includes(
                                Number(get(values, "tipPrixodaId.value", ""))
                              ) &&
                              `лимит до: ${textFormatter(maxAmount)}`}
                          </p>
                        </td>
                        {/* new */}
                        <td className="min-w-80">
                          <BaseMaskInput
                            id={"summaInUZS"}
                            value={get(values, "summaInUZS", "")}
                            handleInput={(e: any) =>
                              setFieldValue("summaInUZS", e)
                            }
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseMaskInput
                            id={"summaInUSD"}
                            value={get(values, "summaInUSD", "")}
                            handleInput={(e: any) =>
                              setFieldValue("summaInUSD", e)
                            }
                          />
                        </td>
                        <td className="min-w-80">
                          <BaseInput
                            value={get(values, "kommentariya", "")}
                            handleInput={(e: any) => {
                              setFieldValue("kommentariya", e);
                            }}
                          />
                        </td>
                        <td>
                          <FlexBox>
                            <FormButton
                              color={"#00c838"}
                              loading={isSubmitting}
                              handleClick={handleSubmit}
                            >
                              &#10004;
                            </FormButton>
                            <FormButton
                              color={"#d80027"}
                              handleClick={handleClose}
                            >
                              &#10006;
                            </FormButton>
                          </FlexBox>
                        </td>
                        <td></td>
                      </tr>
                    )}
                    {!isEmpty(get(arrivedCashes, "data.data", [])) ? (
                      get(arrivedCashes, "data.data", []).map(
                        (item: any, index: any) => {
                          if (
                            isEqual(
                              get(item, "id", null),
                              get(state, "id", "")
                            ) &&
                            get(state, "isEdit", false)
                          ) {
                            return (
                              <tr key={get(item, "id", null)}>
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      arrivedCashes,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      arrivedCashes,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(values, "nomerDoc", "")}</td>
                                <td className="min-w-80">
                                  <DatePicker
                                    id={"dataDoc"}
                                    value={get(values, "dataDoc", new Date())}
                                    handleDate={(e: any) => {
                                      if (
                                        checkDateYear({
                                          currentYear: get(
                                            values,
                                            "dataDoc",
                                            null
                                          ),
                                          checkYear:
                                            moment(e).format("YYYY-MM-DD"),
                                        })
                                      ) {
                                        getDocNumber({
                                          year: checkDateYear({
                                            currentYear: get(
                                              values,
                                              "dataDoc",
                                              null
                                            ),
                                            checkYear:
                                              moment(e).format("YYYY-MM-DD"),
                                          }),
                                        });
                                      }
                                      setFieldValue(
                                        "dataDoc",
                                        moment(e).format("YYYY-MM-DD")
                                      );
                                    }}
                                    maxDate={new Date()}
                                    dateFormat="dd.MM.yyyy"
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseSelect
                                    className="min-w-80"
                                    id={"kassaId"}
                                    value={get(values, "kassaId.value", "")}
                                    defaultValue={get(values, "kassaId", "")}
                                    options={kassaOptions}
                                    handleValueLabel={(e: any) => {
                                      setFieldValue("kassaId", e);
                                    }}
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseSelect
                                    className="min-w-80"
                                    id={"tipPrixodaId"}
                                    defaultValue={get(
                                      values,
                                      "tipPrixodaId",
                                      null
                                    )}
                                    value={get(
                                      values,
                                      "tipPrixodaId.value",
                                      ""
                                    )}
                                    options={typePrixodaOptions}
                                    handleValueLabel={(e: any) => {
                                      setFieldValue("tipPrixodaId", e);
                                      if (
                                        !isEqual(
                                          e,
                                          get(values, "tipPrixodaId", false)
                                        )
                                      ) {
                                        setFieldValue("kontragentId", "");
                                        setFieldValue("summa", "");
                                      }
                                    }}
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseSelect
                                    id={"kontragentId"}
                                    value={get(
                                      values,
                                      "kontragentId.value",
                                      ""
                                    )}
                                    isLoading={LOADING_KONTRAGENTS}
                                    defaultValue={get(values, "kontragentId")}
                                    options={kontragentOptions}
                                    handleValueLabel={(e: any) => {
                                      setFieldValue("kontragentId", e);
                                      // for clear when changes value
                                      if (
                                        get(values, "kontragentId.value", "") !=
                                        get(e, "value", "")
                                      ) {
                                        setFieldValue("dogProdajaId", null);
                                        setFieldValue("summa", "");
                                        setFieldValue("summaInUZS", "");
                                        setFieldValue("summaInUSD", "");
                                        setMaxAmount(null);
                                      }
                                    }}
                                  />
                                </td>
                                <td className="min-w-80">
                                  {isEqual(
                                    get(values, "tipPrixodaId.value", ""),
                                    1
                                  ) && (
                                    <BaseSelect
                                      id={"dogProdajaId"}
                                      value={get(
                                        values,
                                        "dogProdajaId.value",
                                        ""
                                      )}
                                      defaultValue={get(
                                        values,
                                        "dogProdajaId",
                                        {}
                                      )}
                                      options={getSaleContracts}
                                      handleValueLabel={(e: any) => {
                                        setFieldValue("dogProdajaId", e);
                                        if (
                                          !isEqual(
                                            e,
                                            get(values, "dogProdajaId", false)
                                          )
                                        ) {
                                          setFieldValue("summa", "");
                                          setFieldValue("summaInUZS", "");
                                          setFieldValue("summaInUSD", "");
                                        }
                                      }}
                                      isDisalbed={
                                        !isEqual(
                                          get(values, "tipPrixodaId.value", ""),
                                          1
                                        )
                                      }
                                    />
                                  )}
                                </td>
                                <td className="min-w-80">
                                  <BaseMaskInput
                                    id={"summa"}
                                    placeholder={
                                      maxAmount !== null &&
                                      [1, 3].includes(
                                        Number(
                                          get(values, "tipPrixodaId.value", "")
                                        )
                                      ) //  limit show only 'Тип прихода' id 1 or 3
                                        ? `до: ${textFormatter(maxAmount)}`
                                        : ""
                                    }
                                    maxLimit={Number(maxAmount)}
                                    value={get(values, "summa", "")}
                                    handleInput={(e: any) =>
                                      setFieldValue("summa", e)
                                    }
                                  />

                                  <p>
                                    {get(values, "summa", "") > maxAmount &&
                                      maxAmount !== null &&
                                      [1, 3].includes(
                                        Number(
                                          get(values, "tipPrixodaId.value", "")
                                        )
                                      ) &&
                                      `лимит до: ${textFormatter(maxAmount)}`}
                                  </p>
                                </td>
                                <td className="min-w-80">
                                  <BaseMaskInput
                                    id={"summaInUZS"}
                                    value={get(values, "summaInUZS", "")}
                                    handleInput={(e: any) =>
                                      setFieldValue("summaInUZS", e)
                                    }
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseMaskInput
                                    id={"summaInUSD"}
                                    value={get(values, "summaInUSD", "")}
                                    handleInput={(e: any) =>
                                      setFieldValue("summaInUSD", e)
                                    }
                                  />
                                </td>
                                <td className="min-w-80">
                                  <BaseInput
                                    value={get(values, "kommentariya", "")}
                                    handleInput={(e: any) => {
                                      setFieldValue("kommentariya", e);
                                    }}
                                  />
                                </td>
                                <td>
                                  <FlexBox>
                                    <FormButton
                                      color={"#00c838"}
                                      loading={isSubmitting}
                                      handleClick={handleUpdateDocument}
                                    >
                                      &#10004;
                                    </FormButton>
                                    <FormButton
                                      color={"#d80027"}
                                      handleClick={handleCancel}
                                    >
                                      &#10006;
                                    </FormButton>
                                  </FlexBox>
                                </td>
                                <td></td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr
                                key={get(item, "nomerDoc", index + 1)}
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                style={{ padding: "0px" }}
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      arrivedCashes,
                                      "data.pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      arrivedCashes,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaKassa", "")}</td>
                                <td>{get(item, "imyaTipPrixoda", "")}</td>
                                <td
                                  title={get(item, "imyaKontragent", "")}
                                  className={"max-w-20"}
                                >
                                  {get(item, "imyaKontragent", "")}
                                </td>
                                <td>{get(item, "nomerDogProdaja", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summa", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summaInUZS", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>{" "}
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summaInUSD", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td
                                  title={get(item, "kommentariya", "")}
                                  className={"max-w-20"}
                                >
                                  {get(item, "kommentariya", "")}
                                </td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td style={{ padding: "0px" }}>
                                  {get(item, "status", "-")}
                                </td>
                              </tr>
                            );
                          }
                        }
                      )
                    ) : (
                      <Nodata colLength={15} />
                    )}
                  </>
                ) : (
                  map([...new Array(10)], (_, index: number) => (
                    <tr key={index + 1}>
                      <LoadingTable
                        width={index % 2 == 0 ? "50px" : "70px"}
                        tblDTNumber={14}
                      />
                    </tr>
                  ))
                )}
              </BaseTable>
            </Col>
          </Row>

          {get(arrivedCashes, "data.pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(arrivedCashes, "data.pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterARrived({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    arrivedCashes,
                    "data.pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(
                    arrivedCashes,
                    "data.pagination.totalPages",
                    0
                  )}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterARrived({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default TipPrixodaTable;
