import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";

const initialState: any = {
    filter: {
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    data: {
        loading: false,
        data: [],
        error: "",
    },
    statistic: {
        loading: false,
        data: {},
        error: "",
    }
};

export const fetchAllOffsetAdvances = createAsyncThunk(
    "offsetAdvances/fetchAllOffsetAdvances",
    async (params: any, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchAllOffsetAdvance(params);
            const respond = await request.data;

            return respond
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchOffsetAdvancesStat = createAsyncThunk(
    "offsetAdvances/fetchOffsetAdvancesStat",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchOffsetAdvanceStat();
            const respond = await request.data;
            return respond
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const offsetAdvanceSlices = createSlice({
    name: "offsetAdvances",
    initialState,
    reducers: {
        handleFilterChangeOffset: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
        clearFormDetailsOffset:()=> initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllOffsetAdvances.pending, (state) => {
            state.data = {
                loading: true,
            };
        });
        builder.addCase(fetchAllOffsetAdvances.fulfilled, (state, actions) => {
            state.data = {
                loading: false,
                data: get(actions, "payload.data", {}),
                error: "",
            };
        });
        builder.addCase(fetchAllOffsetAdvances.rejected, (state, actions) => {
            state.data = {
                loading: false,
                data: {},
                error: get(actions, "error.message", ""),
            };
        });
        builder.addCase(fetchOffsetAdvancesStat.pending, (state) => {
            state.statistic = {
                loading: true,
            };
        });
        builder.addCase(fetchOffsetAdvancesStat.fulfilled, (state, actions) => {
            state.statistic = {
                loading: false,
                data: get(actions, "payload.data", {}),
                error: "",
            };
        });
        builder.addCase(fetchOffsetAdvancesStat.rejected, (state, actions) => {
            state.statistic = {
                loading: false,
                data: {},
                error: actions.error.message,
            };
        });
    },

});

export const { handleFilterChangeOffset ,clearFormDetailsOffset} = offsetAdvanceSlices.actions;
export default offsetAdvanceSlices.reducer;
