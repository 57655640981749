import { get, isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function CashReportTable({ data }: any) {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth={"1200px"} className="mb-16">
          <thead>
            <tr>
              <th>№</th>
              <th>Дата</th>
              <th>
                Номер
                <br /> документа
              </th>
              <th style={{ width: "180px" }}>Контрагент</th>
              <th>Приход</th>
              <th>Расход</th>
              <th>Остаток</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={6}>
                {
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.ostNach", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                }
              </td>
            </tr>
            {/* the map */}
            {!isEmpty(get(data, "dataList.list", [])) ? (
              get(data, "dataList.list", []).map((item: any, index: number) => (
                <>
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>
                      {moment(get(item, "dataC", "")).format("DD.MM.YYYY")}
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "prixodSummaOfDataC", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "rasxodSummaOfDataC", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={get(item, "ostSummaOfDataC", 0)}
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  {!isEmpty(get(item, "detailsList", []))
                    ? get(item, "detailsList", []).map(
                      (detail: any, index: number) => (
                        <tr
                          key={index + 1}
                          style={{ backgroundColor: "#f4f4f4" }}
                        >
                          <td></td>
                          <td></td>
                          <td>{get(detail, "nomerDoc", "")}</td>
                          <td>{get(detail, "imyaKontragent", "")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(detail, "prixodSumma", 0)}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(detail, "rasxodSumma", 0)}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td></td>
                        </tr>
                      )
                    )
                    : null}
                </>
              ))
            ) : (
              <tr>
                <td colSpan={7}>Виберите параметры</td>
              </tr>
            )}
            {/* the end */}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={6}>
                {
                  <NumericFormat
                    displayType="text"
                    value={get(data, "dataList.itogo.OstKon", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                }
              </td>
            </tr>
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
}

export default CashReportTable;
