import { memo } from "react";
import { TableWrapper } from "./style";
import { Props } from "./type";

const BaseTable: React.FC<Props> = ({
  children,
  tableHeader = [],
  overflow,
  padding,
  ...props
}) => {
  return (
    <div>
      <TableWrapper {...props}>
        <thead>
          <tr>
            {tableHeader &&
              tableHeader.map((item: string, index: number) => {
                return <th key={item}>{item}</th>;
              })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </TableWrapper>
    </div>
  );
};

export default memo(BaseTable);
