import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchAllCalculateProduct,
  fetchCalculateProductStat,
} from "app/slices/documentSlices/calculateProductSlices/calculateProductSlices";
import { fetchOwnTerritories } from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import { calculateProductFilter } from "app/states/documents";
import {
  getTerritoriesData,
  loadingTerritroy,
} from "app/states/handbooks/selectStates";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  LoadingAddPages,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { NumberDocType } from "types/documentsType";

type CalculateProduct = {
  numberDoc: string;
  dataDoc: string;
};
// Расчет себестоимости готовой продукции −> Добавить
const AddCalculateProductContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [LOADING, setLoading] = useState(true);
  const [loadingFull, setLoadingFull] = useState(false);
  const filter = useAppSelector(calculateProductFilter);
  const [disable, setDisable] = useState(false);
  const territoryOptions = useAppSelector(getTerritoriesData);
  let loadingTerritory = useAppSelector(loadingTerritroy);
  const [details, setDetails] = useState<any[]>([]);
  const onSubmit = (values: CalculateProduct) => {
    try {
      DocumentServices.AddCalculateProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          nomerDoc: get(values, "nomerDoc", ""),
          dataDoc: get(values, "dataDoc", ""),
          territoriyaId: get(values, "territoriyaId.value", ""),
          skladGPId: get(values, "skladGPId.value", ""),
          kommentariya: get(values, "kommentariya", ""),
        },
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllCalculateProduct(filter));
          dispatch(fetchCalculateProductStat());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
      setSubmitting(false);
    }
  };
  let initialValues: any = {
    nomerDoc: "",
    dataDoc: null,
    territoriyaId: 1,
    skladGPId: 2,
    kommentariya: "",
  };
  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });
  const handleFetchDetails = useCallback(() => {
    setLoadingFull(true);
    try {
      DocumentServices.FetchCalculateProductDetails({
        dataDoc: get(values, "dataDoc", ""),
        territoriyaId: get(values, "territoriyaId.value", ""),
      }).then(({ data }: any) => {
        if (data) {
          setLoadingFull(false);
          setDetails(data.data);
        }
      });
    } catch (error) {
      setLoadingFull(false);
      console.log(error);
    }
  }, [values]);

  const handleSetInitialValues = (data: any) => {
    setDetails(get(data, "details", []));
    setFieldValue("territoriyaId", {
      value: get(data, "territoriyaId", ""),
    });
    setFieldValue("skladGPId", {
      label: get(data, "imyaSkladGP", ""),
    });
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
  };

  const getDocNumber = useCallback(
    (date: NumberDocType) => {
      try {
        if (get(state, "isTemp", true)) {
          DocumentServices.GetCalculateProductNumber(date).then(({ data }) => {
            setFieldValue("nomerDoc", get(data, "data", ""));
          });
        }

        dispatch(fetchOwnTerritories({ all: true }));
      } catch (err) {
        console.log(err);
      }
    },
    [get(values, "territoriyaId.value", null)]
  );

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      DocumentServices.FetchCalculateProductById(get(state, "id", "")).then(
        (res) => {
          if (get(res, "status", "") == 200) {
            let respond = get(res, "data.data", {});
            setLoading(false);
            handleSetInitialValues(respond);
          } else {
            setLoading(false);
          }
        }
      );
      setDisable(true);
    } else {
      getDocNumber({});
      setLoading(false);
    }
  }, [dispatch, state]);

  const FetchDateDoc = useCallback(() => {
    if (get(values, "territoriyaId.value", null) && !get(state, "id", false)) {
      DocumentServices.FetchCalculateProductDate({
        territoriyaId: get(values, "territoriyaId.value", null),
      }).then(({ data }) => {
        setFieldValue("dataDoc", get(data, "data", ""));
      });
    }
  }, [get(values, "territoriyaId", null), setFieldValue]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);
  useEffect(() => {
    FetchDateDoc();
  }, [FetchDateDoc]);
  return (
    <Content className={"min_height"}>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox>
                <Col xs={6}>
                  <Title bold sm>
                    {PAGE.REQ}
                  </Title>
                </Col>
                <Col
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    green
                    loading={loadingFull}
                    handleClick={handleFetchDetails}
                    disabled={disable}
                  >
                    {PAGE.FILL}
                  </Button>
                </Col>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row align="center">
                <Col xs={6}>
                  <Row>
                    <Col xs={4}>
                      <Title>
                        Номер
                        <span className="required_point">*</span>
                      </Title>
                    </Col>
                    <Col xs={8} className="form-input-date_picker">
                      <Row>
                        <Col xs={6}>
                          <BaseInput
                            id={"nomerDoc"}
                            value={get(values, "nomerDoc", "")}
                          />
                        </Col>
                        <Col
                          xs={6}
                          style={{
                            paddingLeft: 0,
                          }}
                        >
                          <FlexBox>
                            <Text>от:</Text>
                            <DatePicker
                              id={"dataDoc"}
                              value={get(values, "dataDoc", "")}
                              disabled={true}
                              dateFormat="dd.MM.yyyy"
                            />
                          </FlexBox>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Территория
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"territoriyaId"}
                    isSearchable
                    isLoading={loadingTerritory}
                    value={get(values, "territoriyaId.value", "")}
                    options={territoryOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("territoriyaId", e);
                      setFieldValue("skladGPId", {
                        value: get(e, "skladGPId", ""),
                        label: get(e, "imyaSkladGP", ""),
                      });

                      if (!isEqual(e, get(values, "territoriyaId", null))) {
                        setDetails([]);
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Склад готовой продукции
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    disabled={true}
                    value={get(values, "skladGPId.label", "")}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>

          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть
                  <span className="required_point">*</span>
                </Title>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Товар",
                    "Ед.изм.",
                    "Количество",
                    "Коэф-т распред. затрат",
                    "Сумма спис.матер",
                    "Сумма проч.затрат",
                    "Общая сумма себест-ти",
                    "Себест-ть за ед.прод.",
                  ]}
                >
                  {!isEmpty(details) ? (
                    details.map((item: any, index: number) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{get(item, "imyaTovara", "")}</td>
                        <td>{get(item, "imyaEdIzm", "")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "kol", 0)}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          {
                            <NumericFormat
                              displayType="text"
                              value={get(item, "koef", 0)}
                              thousandSeparator
                              decimalScale={2}
                            />
                          }
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summaSebest", 0)}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "prochZatr", 0)}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "obshSummaSebest", 0)}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "sebestEdin", 0)}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        {/* sebestEdin */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>Нет Данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                          Печать
                      </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.SEBEST}_CREATE`,
                    page: DOCUMENT_KEY.SEBEST,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {get(state, "isUpdate", false) ? PAGE.CHANGE : PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                Закрыть
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddCalculateProductContainer;
