import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterBankConterpart,
  fetchBankCounterpart,
  handleBankCounterpartFilter,
} from "app/slices/handbookSlices/bankCounterpartSlices/bankCounterpartSlices";
import { bankCounterpartsDataType } from "app/slices/handbookSlices/bankCounterpartSlices/type";
import { fetchAllBanks } from "app/slices/handbookSlices/bankSlices/bankSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { contractorOptions } from "app/states/documents/selectStates";
import {
  allBankCounterpartData,
  allBankCounterpartFilter,
} from "app/states/handbooks";
import {
  getallBanks,
  getCounterpartData,
} from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  FormSelect,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { bankCounterparts } from "utilities/schema/spravochnikSchemas";
//  Банковские счета

const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: bankCounterpartsDataType;
}) => {
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateBankCounterpart({
          data: {
            ...values,
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          setSubmitting(false);
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewBankCounterpart({
          data: values,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          setSubmitting(false);
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
    } catch (err) {
      setSubmitting(false);
      console.log("Error", err);
    }
  };
  const dispatch = useAppDispatch();
  const getCounterparts = useAppSelector(getCounterpartData);
  const allBanks = useAppSelector(getallBanks);

  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      kontragentId: get(details, "kontragentId", null),
      imyaBankScheta: get(details, "imyaBankScheta", ""),
      nomerScheta: get(details, "nomerScheta", ""),
      bankId: get(details, "bankId", null),
      bankImya: get(details, "imyaBanka", ""),
      osnovnoy: get(details, "osnovnoy", null),
    },
    validationSchema: bankCounterparts,
    onSubmit,
  });
  useEffect(() => {
    dispatch(fetchAllBanks({ all: true }));
  }, [dispatch]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            label={"Контрагент"}
            id={"kontragentId"}
            isSearchable
            value={get(values, "kontragentId", "")}
            options={getCounterparts}
            handleChange={(e: any) => {
              setFieldValue("kontragentId", e);
            }}
            required={true}
          />
          <Input
            label={"Наименование счета"}
            value={get(values, "imyaBankScheta", "")}
            handleInput={(e: string) => {
              setFieldValue("imyaBankScheta", e);
            }}
            required={true}
          />
          <Input
            label={"Номер счета"}
            value={get(values, "nomerScheta", "")}
            handleInput={(e: string) => {
              setFieldValue("nomerScheta", e);
            }}
            required={true}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                МФО<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"bankId"}
                isSearchable
                value={get(values, "bankId", "")}
                options={allBanks}
                handleValueLabel={(e: any) => {
                  setFieldValue("bankId", get(e, "value", null));
                  setFieldValue("bankImya", get(e, "imya", ""));
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Наименование банка<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                value={get(values, "bankImya", "")}
                handleInput={(e: string) => {
                  setFieldValue("bankImya", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Основной счет<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"osnovnoy"}
                isSearchable
                value={get(values, "osnovnoy", null)}
                options={[
                  { label: "нет", value: false },
                  { label: "да", value: true },
                ]}
                handleValueLabel={(e: any) => {
                  setFieldValue("osnovnoy", get(e, "value", null));
                }}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green loading={isSubmitting}>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const BankCounterpartsContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<bankCounterpartsDataType | any>({});
  const contractorDropdowns = useAppSelector(contractorOptions);
  const data = useAppSelector(allBankCounterpartData);
  const filter = useAppSelector(allBankCounterpartFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleBankCounterpartFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchBankCounterpart({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(
      fetchBankCounterpart({ ...filter, kontragentId: null, InAscOrder: false })
    );
    dispatch(clearFilterBankConterpart());
  }, [dispatch, filter]);

  const fetchAllBankCounterpartData = useCallback(() => {
    dispatch(fetchBankCounterpart({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: bankCounterpartsDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteBankCounterpart(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllBankCounterpartData();
      }
    });
  };
  useEffect(() => {
    dispatch(fetchAllCounterpart({ all: true }));
    return () => clearFilter();
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "BANK_SCHET_KONTRAGENT_CREATE",
                    page: "BANK_SCHET_KONTRAGENT",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={6}>
              <BaseSelect
                placeholder={"Поиск по Контрагент"}
                width={"250px"}
                value={get(filter, "kontragentId", "")}
                options={contractorDropdowns}
                handleChange={(e: any) => {
                  dispatchFilter({
                    name: "kontragentId",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"} xs={6}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllBankCounterpartData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Контрагент",
                    "Наименование счета",
                    "Номер счета",
                    "Наименование банка",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "dataList", [])) ? (
                        get(data, "dataList", []).map(
                          (item: bankCounterpartsDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"BANK_SCHET_KONTRAGENT"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imyaKontragent", "-")}</td>
                              <td>{get(item, "imyaBankScheta", "-")}</td>
                              <td>
                                {get(item, "nomerScheta", "-").replace(
                                  /(\d{4})/g,
                                  "$1 "
                                )}
                              </td>
                              <td>{get(item, "imyaBanka", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={7} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={7}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default BankCounterpartsContainer;
