import { RootState } from "app/store";

export const selectUserRoleList = (store: RootState) =>
  store.users.userRole.data;

export const selectUserRoleFilter = (store: RootState) =>
  store.users.userRole.filter;
export const selectUserRolePermissions = (store: RootState) =>
  store.users.userRole.permissions;
export const selectUserRolePermDetails = (store: RootState) =>
  store.users.userRole.details;

// type role
export const selectTypeRoleList = (store: RootState) =>
  store.users.typeRole.data;
export const selectTypeRoleFilter = (store: RootState) =>
  store.users.typeRole.filter;
export const selectTypeRolePermissions = (store: RootState) =>
  store.users.typeRole.permissions;
export const selectTypeRolePermDetails = (store: RootState) =>
  store.users.typeRole.details;

// log users
export const selectLogUsers = (store: RootState) => store.users.logUsers.data;
export const selectLogFilter = (store: RootState) =>
  store.users.logUsers.filter;

