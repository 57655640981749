import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, detailsReturnMaterial } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
    contractStatMaterials: {
        loading: false,
        data: {},
        error: "",
    },
    formData: {
        loading: false,
        error: "",
        isTempActive: false,
        data: {
            vozvratSMDetailsList: [],
        },
    },
    filter: {
        search: "",
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    returnMaterials: {
        loading: false,
        data: [],
        pagination: {},
        error: "",
    },
    notFilledCntrList: {
        loading: false,
        data: [],
        error: "",
    },
};

export const fetchReturnMaterials = createAsyncThunk(
    "returnMaterials/fetchReturnMaterials",
    async (params: any, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchReturnMaterials(params);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchNotFilledReturnMaterials = createAsyncThunk(
    "returnMaterials/fetchNotFilledReturnMaterials",
    async (params, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchNotFilledReturnMaterials(
                params
            );
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchReturnMaterialsByTempId = createAsyncThunk(
    "returnMaterials/fetchReturnMaterialsByTempId",
    async (id: string, thunkAPI) => {
        try {
            const request =
                await DocumentServices.FetchNotFilledReturnMaterialsById(id);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchReturnMaterialsById = createAsyncThunk(
    "returnMaterials/fetchReturnMaterialsById",
    async (id: string, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchReturnMaterialsById(id);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchReturnMaterialsStat = createAsyncThunk(
    "returnMaterials/fetchReturnMaterialsStat",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchReturnMaterialsStatics();
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const returnMaterialsSlice = createSlice({
    name: "returnMaterials",
    initialState,
    reducers: {
        addSubjectsReturnMaterials: (state, actions) => {
            state.formData = {
                ...state.formData,
                data: {
                    ...state.formData.data,
                    vozvratSMDetailsList: !isEmpty(get(actions, "payload", {}))
                        ? [...state.formData.data.vozvratSMDetailsList, actions.payload]
                        : [],
                },
            };
        },
        movementReturnMaterialSub: (state, action) => {
            const id = get(action, "payload.id", null);
            if (action.payload.type === ACTION.ADD) {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        vozvratSMDetailsList: !isEmpty(get(action, "payload.data", []))
                            ? [
                                ...state.formData.data.vozvratSMDetailsList,
                                action.payload.data,
                            ]
                            : [],
                    },
                };
            } else if (action.payload.type === ACTION.UPDATE) {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        vozvratSMDetailsList: state.formData.data.vozvratSMDetailsList.map(
                            (item: detailsReturnMaterial) =>
                                item.materialId == id
                                    ?
                                    get(action, "payload.data", {})
                                    : item
                        ),
                    },
                };
            } else if (action.payload.type === ACTION.DELETE) {

                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        vozvratSMDetailsList: state.formData.data.vozvratSMDetailsList.filter(
                            (item: detailsReturnMaterial) => item.materialId !== id
                        ),
                    },
                };
            }
            else {
                state.formData = {
                    ...state.formData,
                    data: {
                        ...state.formData.data,
                        vozvratSMDetailsList: [],
                    },
                };
            }
        },
        filterChangeReturnMaterials: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
        clearFormDataReturnMaterials: (state) => {
            state.formData = initialState.formData;
        },
        clearFilterDetails:() => initialState
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReturnMaterials.pending, (state) => {
            state.returnMaterials = {
                loading: true,
            };
        });
        builder.addCase(fetchReturnMaterials.fulfilled, (state, actions) => {
            state.returnMaterials = {
                loading: false,
                data: get(actions, "payload.data", []),
                pagination: get(actions, "payload.pagination", {}),
                error: "",
            };
        });
        builder.addCase(fetchReturnMaterials.rejected, (state, actions) => {
            state.returnMaterials = {
                loading: false,
                data: [],
                pagination: {},
                error: actions.error.message,
            };
        });

        builder.addCase(fetchNotFilledReturnMaterials.pending, (state) => {
            state.notFilledCntrList = {
                loading: true,
            };
        });
        builder.addCase(
            fetchNotFilledReturnMaterials.fulfilled,
            (state, actions) => {
                state.notFilledCntrList = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchNotFilledReturnMaterials.rejected,
            (state, actions) => {
                state.notFilledCntrList = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                };
            }
        );
        //  fetch temp
        builder.addCase(fetchReturnMaterialsByTempId.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(
            fetchReturnMaterialsByTempId.fulfilled,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: get(actions, "payload.data", []),
                    error: "",
                    isTempActive: true,
                };
            }
        );
        builder.addCase(
            fetchReturnMaterialsByTempId.rejected,
            (state, actions) => {
                state.formData = {
                    loading: false,
                    data: [],
                    error: actions.error.message,
                    isTempActive: false,
                };
            }
        );
        // fetch id
        builder.addCase(fetchReturnMaterialsById.pending, (state) => {
            state.formData = {
                loading: true,
                isTempActive: false,
            };
        });
        builder.addCase(fetchReturnMaterialsById.fulfilled, (state, actions) => {
            state.formData = {
                loading: false,
                data: get(actions, "payload.data", []),
                error: "",
                isTempActive: true,
            };
        });
        builder.addCase(fetchReturnMaterialsById.rejected, (state, actions) => {
            state.formData = {
                loading: false,
                data: [],
                error: actions.error.message,
                isTempActive: false,
            };
        });

        // fetching sale contracts statists
        builder.addCase(fetchReturnMaterialsStat.pending, (state) => {
            state.contractStatMaterials = {
                loading: true,
            };
        });
        builder.addCase(fetchReturnMaterialsStat.fulfilled, (state, actions) => {
            state.contractStatMaterials = {
                loading: false,
                data: get(actions, "payload.data", {}),
                error: "",
            };
        });
        builder.addCase(fetchReturnMaterialsStat.rejected, (state, actions) => {
            state.contractStatMaterials = {
                loading: false,
                data: {},
                error: actions.error.message,
            };
        });
    },
});

export const {
    addSubjectsReturnMaterials,
    filterChangeReturnMaterials,
    clearFormDataReturnMaterials,
    movementReturnMaterialSub,
    clearFilterDetails,
} = returnMaterialsSlice.actions;
export default returnMaterialsSlice.reducer;
