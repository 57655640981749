import { get, isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

function ReportOnPurchaseTable({ data }: any) {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper minWidth="1600px" className="mb-16">
              <thead>
                <tr>
                  <th>№</th>
                  <th style={{ width: "200px" }}>Водитель</th>
                  <th style={{ width: "200px" }}>Товар</th>
                  <th>Ед.изм-я</th>
                  <th>Номер документа</th>
                  <th>Дата документа</th>
                  <th>Территория</th>
                  <th>Склад ГП</th>
                  <th style={{ width: "200px" }}>Автомобиль</th>
                  <th>Количество</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {get(data, "dataList", []) ? (
                    get(data, "dataList", []).map(
                      (item: any, index: number) => (
                        <>
                          <tr key={index + 1}>
                            <th>{index + 1}</th>
                            <td>{get(item, "imyaVoditeli", "")}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "kol", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summa", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                          </tr>

                          {get(item, "tovars", [])
                            ? get(item, "tovars", []).map(
                              (tovar: any, index: number) => (
                                <>
                                  <tr
                                    key={index + 1}
                                    style={{ backgroundColor: "#f4f4f4" }}
                                  >
                                    <td></td>
                                    <td></td>
                                    <td>{get(tovar, "imyaTovar", "")}</td>
                                    <td>{get(tovar, "imyaEdIzm", "")} </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(tovar, "kol", 0)}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                      />
                                    </td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={get(tovar, "summa", 0)}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                      />
                                    </td>
                                  </tr>
                                  {!isEmpty(get(tovar, "details", []))
                                    ? get(tovar, "details", []).map(
                                      (detail: any, index: number) => (
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {get(detail, "nomerDoc", "")}
                                          </td>
                                          <td>
                                            {get(detail, "dataDoc", "")}
                                          </td>
                                          <td>
                                            {get(
                                              detail,
                                              "imyaTerritoriya",
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {get(detail, "imyaSkladGP", "")}
                                          </td>
                                          <td>
                                            {get(detail, "avtomobil", "")}
                                          </td>
                                          <td>
                                            <NumericFormat
                                              displayType="text"
                                              value={get(detail, "totalKol", 0)}
                                              thousandSeparator={true}
                                              decimalScale={2}
                                            />
                                          </td>
                                          <td>
                                            <NumericFormat
                                              displayType="text"
                                              value={get(detail, "totalSumma", 0)}
                                              thousandSeparator={true}
                                              decimalScale={2}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )
                                    : null}
                                </>
                              )
                            )
                            : null}
                        </>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={10}>Виберите параметры</td>
                    </tr>
                  )}
                </>
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ReportOnPurchaseTable;
