import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchPrice,
  handlePriceFilter,
  handlePriceFilterClear,
} from "app/slices/handbookSlices/priceSlices/priceSlices";
import { PriceDataType } from "app/slices/handbookSlices/priceSlices/type";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchUnitsMeasurement } from "app/slices/handbookSlices/unitsMeasurementSlices/unitsMeasurementSlices";
import { allPriceData, allPriceFilter } from "app/states/handbooks";
import {
  getProductData,
  getUnitsMeasurementData,
} from "app/states/handbooks/selectStates";
import {
  ActionBase,
  BaseInput,
  BaseTable,
  Button,
  Content,
  DatePicker,
  Form,
  FormSelect,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
//  Цены
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: PriceDataType;
}) => {
  console.log(details, "details in data");
  const dispatch = useAppDispatch();
  const getProduct = useAppSelector(getProductData);
  const getUnitsMeasurement = useAppSelector(getUnitsMeasurementData);
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdatePrice({
          data: {
            ...values,
            data: moment(get(values, "data", null)).format("YYYY-MM-DD"),
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewPrice({
          data: {
            ...values,
            data: moment(get(values, "data", null)).format("YYYY-MM-DD"),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
      resetForm();
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      tovarId: get(details, "tovarId", null),
      edIzmId: get(details, "edIzmId", null),
      sena: Number(get(details, "sena", null)),
      data: get(details, "data", null),
    },
    onSubmit,
    // validationSchema: priceSchema,
  });

  const getEdIzmId = useCallback(() => {
    if (isEmpty(details.id)) {
      let findProduct = getProduct.find(
        (item) => item.value === get(values, "tovarId", 0)
      );
      setFieldValue("edIzmId", get(findProduct, "edIzmId", null));
    }
  }, [values.tovarId, details]);

  useEffect(() => {
    dispatch(fetchUnitsMeasurement({ all: true }));
    dispatch(fetchProduct({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    getEdIzmId();
  }, [getEdIzmId, details]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            label="Наименование товара"
            id={"tovarId"}
            isSearchable
            value={get(values, "tovarId", "")}
            options={getProduct}
            handleChange={(e: any) => {
              setFieldValue("tovarId", e);
            }}
            required={true}
          />

          <FormSelect
            className="mt-24"
            label={"Ед.изм"}
            id={"edIzmId"}
            defaultValue={{
              label: get(details, "imyaEdIzm", ""),
              value: get(details, "edIzmId", ""),
            }}
            value={get(values, "edIzmId", "")}
            options={getUnitsMeasurement}
            required={true}
            isDisabled={true}
          />
          <Row align="center" className="mt-24">
            <Col xs={4}>
              <Title>
                Цена<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"sena"}
                value={get(values, "sena", "") || ""}
                handleInput={(e: number) => {
                  setFieldValue("sena", e);
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4}>
              <Title>
                Дата регистрации цены <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <DatePicker
                id={"data"}
                value={get(values, "data", null)}
                dateFormat="dd.MM.yyyy"
                handleDate={(e) => {
                  setFieldValue("data", e);
                }}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const PricesContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<PriceDataType | any>({});
  const data = useAppSelector(allPriceData);
  const filter = useAppSelector(allPriceFilter);
  let LOADING = get(data, "loading", false);

  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handlePriceFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchPrice({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(fetchPrice({ ...filter, search: "", InAscOrder: false }));
    dispatch(
      handlePriceFilter({
        name: "search",
        value: "",
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    dispatch(handlePriceFilterClear());
  }, []);
  const fetchAllPriceData = useCallback(() => {
    dispatch(fetchPrice({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: PriceDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeletePrice(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllPriceData();
      }
    });
  };
  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "TOVAR_SENI_CREATE",
                    page: "TOVAR_SENI",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllPriceData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименования товара",
                    "Единица измерения",
                    "Цена",
                    "Дата регистрации цены",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "priceData", [])) ? (
                        get(data, "priceData", []).map(
                          (item: PriceDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"TOVAR_SENI"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "tovarImya", "-")}</td>
                              <td>{get(item, "imyaEdIzm", "-")}</td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "sena", "")}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                {moment(get(item, "data", "-")).format(
                                  "DD.MM.yyyy"
                                )}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={7} />
                      )}{" "}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={7}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default PricesContainer;
