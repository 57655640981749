import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import axios from "axios";

const initialState: InitialState = {
  contractStatProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      vipuskGPDetails: [],
      detailsPoFaktu: [],
    },
  },
  filter: {
    search: "",
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  releaseProduct: {
    loading: false,
    productList: [],
    pagination: {},
    error: "",
  },
  productOptions: {
    // only use into document release for tovar options
    loading: false,
    data: [],
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchReleaseProduct = createAsyncThunk(
  "releaseProduct/fetchReleaseProduct",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReleaseProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchNotFilledreleaseProduct = createAsyncThunk(
  "releaseProduct/fetchNotFilledReleaseProduct",
  async (params, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledReleaseProduct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReleaseProductsTempById = createAsyncThunk(
  "releaseProduct/fetchReleaseProductsTempById",
  async (id: string, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledReleaseProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReleaseProductsById = createAsyncThunk(
  "releaseProduct/fetchReleaseProductsById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReleaseProductById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReleaseProductStat = createAsyncThunk(
  "releaseProduct/fetchReleaseProductStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReleaseProductStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchProductsReleaseOptions = createAsyncThunk(
  "releaseProduct/fetchProductsReleaseOptions",
  async (params: any, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        soure.cancel();
      });
      const request = await DocumentServices.GetOrderProductsById({
        ...params,
        cancelToken: soure.token,
      });
      const respond = await request.data;
      return respond;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
const releaseProductSlices = createSlice({
  name: "releaseProduct",
  initialState,
  reducers: {
    handleAddSubjectsRelease: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          vipuskGPDetails:
            get(actions, "payload.tipSpisaniyaId.value", null) === 1
              ? get(actions, "payload.data", [])
              : [],
        },
      };
    },
    handleAddSubjectsReleasePoFackt: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          detailsPoFaktu: !isEmpty(get(actions, "payload.data", []))
            ? get(actions, "payload.data", [])
            : state.formData.data.detailsPoFaktu,
        },
      };
    },
    handleAddSubjectsOnesRelease: (state, actions) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          detailsPoFaktu: !isEmpty(get(actions, "payload", {}))
            ? [
                ...state.formData.data.detailsPoFaktu,
                get(actions, "payload", {}),
              ]
            : [],
        },
      };
    },
    handleChangeItemSubjectsRelease: (state, action) => {
      const id = get(action, "payload.id", 0);
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          detailsPoFaktu: state.formData.data.detailsPoFaktu.map(
            (item: any, index: number) =>
              index == id
                ? {
                    ...item,
                    [get(action, "payload.name", "")]: get(
                      action,
                      "payload.value",
                      0
                    ),
                  }
                : item
          ),
        },
      };
    },
    removeSubject: (state, action) => {
      const id = get(action, "payload.id", 0);
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          detailsPoFaktu: state.formData.data.detailsPoFaktu.filter(
            (item: any, index: any) => index !== id
          ),
        },
      };
    },
    handleFilterChangeRelease: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },

    handleClearAllMaterials: (state) => {
      state.filter = initialState.filter;
    },
    handleClearFormDataMaterials: (state) => {
      state.formData = initialState.formData;
    },
    clearReleaseOptions: (state) => {
      state.productOptions = initialState.productOptions;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchReleaseProduct.pending, (state) => {
      state.releaseProduct = {
        loading: true,
      };
    });

    builder.addCase(fetchReleaseProduct.fulfilled, (state, actions) => {
      state.releaseProduct = {
        loading: false,
        productList: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchReleaseProduct.rejected, (state, actions) => {
      state.releaseProduct = {
        loading: false,
        productList: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    builder.addCase(fetchNotFilledreleaseProduct.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(
      fetchNotFilledreleaseProduct.fulfilled,
      (state, actions) => {
        state.notFilledCntrList = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
        };
      }
    );
    builder.addCase(fetchNotFilledreleaseProduct.rejected, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    //  fetch temp
    builder.addCase(fetchReleaseProductsTempById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(
      fetchReleaseProductsTempById.fulfilled,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
          isTempActive: true,
        };
      }
    );
    builder.addCase(fetchReleaseProductsTempById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    //  fetch order id
    builder.addCase(fetchReleaseProductsById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchReleaseProductsById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchReleaseProductsById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    // fetching sale contracts statists
    builder.addCase(fetchReleaseProductStat.pending, (state) => {
      state.contractStatProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchReleaseProductStat.fulfilled, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchReleaseProductStat.rejected, (state, actions) => {
      state.contractStatProduct = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
    // produt relses
    builder.addCase(fetchProductsReleaseOptions.pending, (state) => {
      state.productOptions = {
        loading: true,
      };
    });
    builder.addCase(fetchProductsReleaseOptions.fulfilled, (state, actions) => {
      state.productOptions = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchProductsReleaseOptions.rejected, (state, actions) => {
      state.productOptions = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleAddSubjectsRelease,
  handleAddSubjectsReleasePoFackt,
  handleAddSubjectsOnesRelease,
  handleChangeItemSubjectsRelease,
  handleFilterChangeRelease,
  removeSubject,
  handleClearAllMaterials,
  handleClearFormDataMaterials,
  clearReleaseOptions,
} = releaseProductSlices.actions;
export default releaseProductSlices.reducer;
