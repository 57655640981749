import { FormatInputWrapper } from "./style";
import { memo, useEffect, useState } from "react";
import { Props } from "./type";

const BaseMaskInput: React.FC<Props> = ({
  clear = "",
  placeholder = "",
  disabled = false,
  handleInput = () => {},
  decimalScale,
  width,
  thousandSeparator = true,
  fixedDecimalScale = true,
  maxLength,
  maxLimit,
  min,
  max,
  ...props
}) => {
  const [val, setVal] = useState(clear);
  const handleChange = (e: any) => {
    setVal(e);
    handleInput(e);
  };

  const checkDecimal = (event: string) => {
    if (event.includes(".")) {
    }
  };

  useEffect(() => {
    if (clear.length == 0) {
      setVal(clear);
    }
  }, [clear]);
  return (
    <FormatInputWrapper
      type="text"
      width={width}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={2}
      value={val}
      placeholder={placeholder}
      disabled={disabled}
      onValueChange={(e: any) => {
        const { floatValue } = e;
        const { formattedValue } = e;
        checkDecimal(formattedValue);
        handleChange(floatValue);
      }}
      min={min}
      max={max}
      {...props}
    />
  );
};

export default memo(BaseMaskInput);
