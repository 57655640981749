import { get, isEmpty } from "lodash";
import moment from "moment";

type Amount = {
  amount?: number | string | null;
  quantity?: number | string | null;
  VAT?: number | string | undefined;
  kol?: number | string | undefined | null;
  sena?: number | string | undefined;
};

type CalculateVAT = {
  amount: number | string | null;
  VAT: number | string | undefined | null;
  kol: number | string | undefined | null;
  sena: number | string | undefined;
};
type DiscountPrice = {
  pro?: number | string | undefined;
  price?: number | string | undefined;
};

type TotalPrice = {
  nds?: number | string | undefined | null;
  totalAmount?: number | string | undefined;
};

type RemoveValueType = {
  removeValueList?: any;
  currentOption?: any;
  valueName: string | number;
  isUpdate?: any;
};
type ValueType = {
  label: string;
  value: number | string;
};
export const checkDateYear = ({
  currentYear,
  checkYear,
}: {
  checkYear: string;
  currentYear: string | null;
}) => {
  let year = String(
    isEmpty(currentYear) ? moment(new Date()).format("YYYY-MM-DD") : currentYear
  ).substring(0, 4);
  let check = checkYear.substring(0, 4);
  return year !== check ? check : null;
};
export const textFormatter = (number: number) => {
  if (number || number === 0) {
    return Number(number)
      ?.toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
};

export const calcAmountWithVAT = ({ amount, VAT, kol, sena }: CalculateVAT) => {
  amount = Number(amount);
  VAT = Number(VAT);

  if (amount && kol && sena && VAT !== 0 && VAT) {
    const amountWithVAT = amount * (VAT / 100);
    return amountWithVAT.toFixed(2);
  } else {
    return 0;
  }
};
// new cal
export const calcTotalPrice = ({ nds, totalAmount }: TotalPrice) => {
  nds = Number(nds);
  totalAmount = Number(totalAmount);
  if (nds && nds !== 0 && totalAmount) {
    const total = totalAmount * (1 + nds / 100);
    return total.toFixed(2);
  } else {
    return totalAmount.toFixed(2);
  }
};
//  calc  index
export const calcIndexNum = ({ index, size, page }: any) => {
  if (index && size && page) {
    return index * size + page;
  }
};
export const calcTotalAmount = ({ amount, quantity }: Amount) => {
  amount = Number(amount);
  quantity = Number(quantity);

  if (amount && quantity) {
    const amountWithVAT = amount * quantity;

    return amountWithVAT.toFixed(2);
  } else {
    return 0;
  }
};

export const calcDiscountPrice = ({ price, pro }: DiscountPrice) => {
  price = Number(price);
  pro = Number(pro);
  if (price && pro !== 0 && pro) {
    const discount = price * (1 - pro / 100);

    return discount.toFixed(2);
  } else {
    return price;
  }
};
//  
export const differentValues = (value: string | number, arr: string[], data: any) => {
  const list = arr?.map((item: any, index: number) => ({ ...item, idx: index + 1 }))
  let newArray = [...list];

  for (let i = 0; i < newArray?.length - 1; i++) {
    if (newArray[i].value != newArray[i + 1].value) {
      newArray?.splice(i + 1, 0, data)
      i++;
    }
  }
  return arr
}
// {
//   "idx": 0,
//   "comment": "",
//   "nomerZakazNaryad": "",
//   "dataDoc": "",
//   "kol": null,
//   "imyaKontragent": "",
//   "kontragentId": null,
//   "imyaTerritorii": "",
//   "imyaTovar": "",
//   "edIzm": "",
//   "phoneNumber": "",
//   "vsegoSumma": ""
// }
// remove value
export const removeValueOption = ({
  removeValueList,
  currentOption,
  valueName,
  isUpdate,
}: RemoveValueType) => {
  console.log(isUpdate);
  if (!isEmpty(removeValueList) && currentOption && valueName) {
    let tempList: Array<number | string> = [];
    removeValueList.map((item: any) => {
      tempList.push(item[valueName]);
    });
    const option = currentOption?.filter(
      (item: ValueType) => !tempList.includes(item.value)
    );
    if (isUpdate) {
      option.unshift(isUpdate);
    }

    return option;
  } else if (currentOption) {
    return currentOption;
  } else {
    return [];
  }
};
// index numeration
export const indexNumber = ({
  pageSize,
  currentPage,
  index,
}: {
  pageSize: number;
  currentPage: number;
  index: number;
}) => {
  if (pageSize && currentPage > 0 && index) {
    return pageSize * currentPage + index;
  } else {
    return index;
  }
};

// check in zero && -1
export const formatValueReport = (number: number | string) => {
  if (number && Number(number) < 0 && Number(number) > -1) {
    return 0.0;
  } else {
    return number;
  }
};
