import { RootState } from "app/store";

export const selectSaleContractForm = (store: RootState) =>
  store.documents.registerSalesContracts.formData;
export const selectSaleContractFilter = (store: RootState) =>
  store.documents.registerSalesContracts.filter;
export const selectSaleContractList = (store: RootState) =>
  store.documents.registerSalesContracts.salesCnts;
export const selectSaleTempsContractList = (store: RootState) =>
  store.documents.registerSalesContracts.notFilledCntrList;
export const selectSaleCntrCountStat = (store: RootState) =>
  store.documents.registerSalesContracts.saleContractStat;
export const selectSaleDetailsProduct = (store: RootState) =>
  store.documents.registerSalesContracts.detailsProduct;
// order Register
export const selectOrderRegisterFilter = (store: RootState) =>
  store.documents.orderRegister.filter;
export const selectOrderRegisterList = (store: RootState) =>
  store.documents.orderRegister.orders;
export const selectOrderRegisterForm = (store: RootState) =>
  store.documents.orderRegister.formData;
export const selectOrderRegisterStat = (store: RootState) =>
  store.documents.orderRegister.orderRegisterStat;
export const selectTempsOrderRegisterList = (store: RootState) =>
  store.documents.orderRegister.notFilledCntrList;

//  register purchase
export const selectPurchaseContractForm = (store: RootState) =>
  store.documents.purchaseContracts.formData;
export const selectPurchaseContractFilter = (store: RootState) =>
  store.documents.purchaseContracts.filter;
export const selectPurchaseContractList = (store: RootState) =>
  store.documents.purchaseContracts.purchaseCnts;
export const selectPurchaseTempsContractList = (store: RootState) =>
  store.documents.purchaseContracts.notFilledPurchaseList;
export const selectPurchaseCntrCountStat = (store: RootState) =>
  store.documents.purchaseContracts.purchaseContractStat;
//  received Materials
export const selectReceivedMaterialsList = (state: RootState) =>
  state.documents.receivedMaterials.receivedMaterials;
export const selectReceivedMaterialsForm = (state: RootState) =>
  state.documents.receivedMaterials.formData;
export const selectReceivedMaterialsFilter = (state: RootState) =>
  state.documents.receivedMaterials.filter;
export const selectTempsReceivedMaterialsList = (state: RootState) =>
  state.documents.receivedMaterials.notFilledCntrList;
export const selectReceivedMaterialsStat = (state: RootState) =>
  state.documents.receivedMaterials.contractStatMaterials;
// return material
export const selectReturnMaterialsList = (state: RootState) =>
  state.documents.returnMaterials.returnMaterials;
export const selectReturnMaterialsForm = (state: RootState) =>
  state.documents.returnMaterials.formData;
export const selectReturnMaterialsFilter = (state: RootState) =>
  state.documents.returnMaterials.filter;
export const selectTempReturnMaterialsList = (state: RootState) =>
  state.documents.returnMaterials.notFilledCntrList;
export const selectReturnMaterialsStat = (state: RootState) =>
  state.documents.returnMaterials.contractStatMaterials;
// movement material
export const selectMovemetMaterialsList = (state: RootState) =>
  state.documents.movementMaterials.movementMaterials;
export const selectMovementMaterialsForm = (state: RootState) =>
  state.documents.movementMaterials.formData;
export const selectMovementMaterialsFilter = (state: RootState) =>
  state.documents.movementMaterials.filter;
export const selectTempMovementMaterialsList = (state: RootState) =>
  state.documents.movementMaterials.notFilledCntrList;
export const selectMovementMaterialsStat = (state: RootState) =>
  state.documents.movementMaterials.contractStatMaterials;
// manual material
export const selectManualMaterialsList = (state: RootState) =>
  state.documents.manualMaterials.manualMaterials;
export const selectManualMaterialsForm = (state: RootState) =>
  state.documents.manualMaterials.formData;
export const selectManualMaterialsFilter = (state: RootState) =>
  state.documents.manualMaterials.filter;
export const selectTempManualMaterialsList = (state: RootState) =>
  state.documents.manualMaterials.notFilledList;
export const selectManualMaterialsStat = (state: RootState) =>
  state.documents.manualMaterials.statusMaterials;
// semi product
export const selectSemiProductList = (state: RootState) =>
  state.documents.semiProducts.semiProduct;
export const selectSemiProductForm = (state: RootState) =>
  state.documents.semiProducts.formData;
export const selectSemiProductFilter = (state: RootState) =>
  state.documents.semiProducts.filter;
export const selectTempsSemiProductList = (state: RootState) =>
  state.documents.semiProducts.notFilledCntrList;
export const selectSemiProductStat = (state: RootState) =>
  state.documents.semiProducts.contractStatProduct;
export const selectSemiProductDetails = (state: RootState) =>
  state.documents.semiProducts.details;
//  release product
export const selectReleaseProductList = (state: RootState) =>
  state.documents.releaseProducts.releaseProduct;
export const selectReleaseProductForm = (state: RootState) =>
  state.documents.releaseProducts.formData;
export const selectReleaseProductFilter = (state: RootState) =>
  state.documents.releaseProducts.filter;
export const selectTempsReleaseProductList = (state: RootState) =>
  state.documents.releaseProducts.notFilledCntrList;
export const selectReleaseProductStat = (state: RootState) =>
  state.documents.releaseProducts.contractStatProduct;
export const selectReleaseProductOptions = (state: RootState) =>
  state.documents.releaseProducts.productOptions;
// shipment product
export const selectShipmentProductList = (state: RootState) =>
  state.documents.shipmentProducts.shipmentProduct;
export const selectShipmentProductForm = (state: RootState) =>
  state.documents.shipmentProducts.formData;
export const selectShipmentProductFilter = (state: RootState) =>
  state.documents.shipmentProducts.filter;
export const selectTempsShipmentProductList = (state: RootState) =>
  state.documents.shipmentProducts.notFilledCntrList;
export const selectShipmentProductStat = (state: RootState) =>
  state.documents.shipmentProducts.contractStatProduct;
// return product before realizes
export const selectReturnProductBerRealList = (state: RootState) =>
  state.documents.returnProductBefReal.returnProduct;
export const selectReturnProductBerRealForm = (state: RootState) =>
  state.documents.returnProductBefReal.formData;
export const selectReturnProductBerRealFilter = (state: RootState) =>
  state.documents.returnProductBefReal.filter;
export const selectTempsReturnProductBerRealList = (state: RootState) =>
  state.documents.returnProductBefReal.notFilledList;
export const selectReturnProductBerRealStat = (state: RootState) =>
  state.documents.returnProductBefReal.countStatProduct;
//  sales product
export const selectSalesProductsList = (state: RootState) =>
  state.documents.salesProducts.salesProduct;
export const selectSalesProductsForm = (state: RootState) =>
  state.documents.salesProducts.formData;
export const selectSalesProductsFilter = (state: RootState) =>
  state.documents.salesProducts.filter;
export const selectTempsSalesProductsList = (state: RootState) =>
  state.documents.salesProducts.notFilledCntrList;
export const selectSalesProductsStat = (state: RootState) =>
  state.documents.salesProducts.contractStatProduct;
// return products
export const selectReturnProductList = (state: RootState) =>
  state.documents.returnProducts.returnProduct;
export const selectReturnProductForm = (state: RootState) =>
  state.documents.returnProducts.formData;
export const selectReturnProductFilter = (state: RootState) =>
  state.documents.returnProducts.filter;
export const selectTempsReturnProductList = (state: RootState) =>
  state.documents.returnProducts.notFilledCntrList;
export const selectReturnProductStat = (state: RootState) =>
  state.documents.returnProducts.countStatProduct;

// Ввод прочих производственных расходов
export const selectOtherCostFilter = (state: RootState) =>
  state.documents.otherProdCosts.filter;
export const selectOtherCosts = (state: RootState) =>
  state.documents.otherProdCosts.prodOtherCosts;
export const selectOtherCostsTemps = (state: RootState) =>
  state.documents.otherProdCosts.prodOtherCostsTemp;
export const selectOtherCostsDetails = (state: RootState) =>
  state.documents.otherProdCosts.details;
export const selectOtherCostsStatistic = (state: RootState) =>
  state.documents.otherProdCosts.statistic;
// Регистрация прихода безналичных денег
export const selectRegisterParishCashFilter = (state: RootState) =>
  state.documents.registerParishCash.filter;
export const selectRegisterParishCash = (state: RootState) =>
  state.documents.registerParishCash.cashList;
export const selectRegisterParishCashTemps = (state: RootState) =>
  state.documents.registerParishCash.cashListTemp;
export const selectRegisterParishCashForm = (state: RootState) =>
  state.documents.registerParishCash.formData;
export const selectRegisterParishCashStat = (state: RootState) =>
  state.documents.registerParishCash.statistics;
// Приход наличных денег
export const selectArrivedCashFilter = (state: RootState) =>
  state.documents.arrivedCashes.filter;
export const selectArrivedCash = (state: RootState) =>
  state.documents.arrivedCashes.cashes;
export const selectArrivedCashTemps = (state: RootState) =>
  state.documents.arrivedCashes.cashesTemp;
export const selectArrivedStatistic = (state: RootState) =>
  state.documents.arrivedCashes.statistic;
export const selectArrivedRemainder = (state: RootState) =>
  state.documents.arrivedCashes.remainder;
// Расход наличный денег
export const selectCostsInCashFilter = (state: RootState) =>
  state.documents.costsInCash.filter;
export const selectCostsInCash = (state: RootState) =>
  state.documents.costsInCash.costsInCashes;
export const selectCostsInCashTemps = (state: RootState) =>
  state.documents.costsInCash.costsInCashesTemp;
export const selectCostsInCashStat = (state: RootState) =>
  state.documents.costsInCash.statistic;
// Регистрация расхода безналичных денег
export const selectRegesBezNalichFilter = (state: RootState) =>
  state.documents.registerBezNalich.filter;
export const selectRegesBezNalichCash = (state: RootState) =>
  state.documents.registerBezNalich.registerBezNalich;
export const selectRegesBezNalichTemps = (state: RootState) =>
  state.documents.registerBezNalich.registerBezNalichTemp;
export const selectRegisterBezNalichCashDetails = (state: RootState) =>
  state.documents.registerBezNalich.details;
export const selectRegisterBezNalichStat = (state: RootState) =>
  state.documents.registerBezNalich.statistics;
// Зачет аванса покупателей
export const selectOffsetAdvanceFilter = (state: RootState) =>
  state.documents.offsetAdvance.filter;
export const selectOffsetAdvanceList = (state: RootState) =>
  state.documents.offsetAdvance.data;
export const selectOffsetAdvanceStat = (state: RootState) =>
  state.documents.offsetAdvance.statistic;

//  Расчет себестоимости готовой продукции
export const selectCalculateProductFilter = (state: RootState) =>
  state.documents.calculateProduct.filter;
export const selectCalculateProductList = (state: RootState) =>
  state.documents.calculateProduct.data;
export const selectCalculateProductStat = (state: RootState) =>
  state.documents.calculateProduct.statistic;
//  Закрытие месяца
export const selectCloseMonthFilter = (state: RootState) =>
  state.documents.closeMonth.filter;
export const selectCloseMonthList = (state: RootState) =>
  state.documents.closeMonth.data;
export const selectCloseMonthStat = (state: RootState) =>
  state.documents.closeMonth.statistic;
//  Ручные проводки
export const selectManualPostingFilter = (state: RootState) =>
  state.documents.manualPosting.filter;
export const selectManualPostingDetails = (state: RootState) =>
  state.documents.manualPosting.details;
export const selectManualPostingList = (state: RootState) =>
  state.documents.manualPosting.data;
export const selectManualPostingStat = (state: RootState) =>
  state.documents.manualPosting.statistic;

// Приобретение бетона у водителей
export const selectRtrnConcreteDriverFilter = (state: RootState) =>
  state.documents.returnConcreteDriver.filter;
export const selectRtrnConcreteDriver = (state: RootState) =>
  state.documents.returnConcreteDriver.returnedConcretes;
export const selectRtrnConcreteDriverTemps = (state: RootState) =>
  state.documents.returnConcreteDriver.returnedConcretesTemp;
export const selectRtrnConcreteDriverStat = (state: RootState) =>
  state.documents.returnConcreteDriver.statistic;

//  brak
export const selectSubstandardProductFilter = (state: RootState) =>
  state.documents.substandardProduct.filter;
export const selectSubstandardProductList = (state: RootState) =>
  state.documents.substandardProduct.substandard;
export const selectSubstandardProductStatistics = (state: RootState) =>
  state.documents.substandardProduct.statistics;
export const selectSubstandardProductForm = (state: RootState) =>
  state.documents.substandardProduct.formData;
export const selectSubProductData = (state: RootState) =>
  state.documents.substandardProduct.subProduct;
export const selectSubTerritory = (state: RootState) =>
  state.documents.substandardProduct.subTerritory;
// change product
export const selectChangeProductFilter = (state: RootState) =>
  state.documents.changeProduct.filter;
export const selectChangeProductList = (state: RootState) =>
  state.documents.changeProduct.data;
export const selectChangeProductForm = (state: RootState) =>
  state.documents.changeProduct.formData;
export const selectChangeProductStat = (state: RootState) =>
  state.documents.changeProduct.statistic;
export const selectTempChangeProduct = (state: RootState) =>
  state.documents.changeProduct.notFilledList;
// change semi product
export const selectChangeSemiProductFilter = (state: RootState) =>
  state.documents.changeSemiProduct.filter;
export const selectChangeSemiProductList = (state: RootState) =>
  state.documents.changeSemiProduct.data;
export const selectChangeSemiProductForm = (state: RootState) =>
  state.documents.changeSemiProduct.formData;
export const selectChangeSemiProductStat = (state: RootState) =>
  state.documents.changeSemiProduct.statistic;
export const selectTempChangeSemiProduct = (state: RootState) =>
  state.documents.changeSemiProduct.notFilledList;
// moving cash
export const selectMovingCashFilter = (state: RootState) =>
  state.documents.movingCash.filter;
export const selectMovingCash = (state: RootState) =>
  state.documents.movingCash.cashes;
export const selectMovingCashTemps = (state: RootState) =>
  state.documents.movingCash.cashesTemp;
export const selectMovingStatistic = (state: RootState) =>
  state.documents.movingCash.statistic;
