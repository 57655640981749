import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import ReportsServices from "services/apiServices/reports";
import moment from "moment";

const initialState: InitialState = {
  data: { loading: false, planList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    territoriyaId: null,
    skladId: null
  },
};

export const fetchProcurementPlan = createAsyncThunk(
  "procurementPlan/fetchProcurementPlan",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchProcurementPlanTable(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const procurementPlanSlices = createSlice({
  name: "procurementPlan",
  initialState,
  reducers: {
    filterProcurementTable: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearProcumerentTable: () => initialState,
    changeProcumerentTable: (state, action) => {
      state.data = {
        loading: false,
        planList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProcurementPlan.pending, (state) => {
      state.data = {
        loading: true,
        planList: [],
        error: "",
      };
    });
    builder.addCase(fetchProcurementPlan.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        planList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchProcurementPlan.rejected, (state, payload) => {
      state.data = {
        loading: false,
        planList: [],
        error: payload.error.message,
      };
    });
  },
});
export const { filterProcurementTable, clearProcumerentTable, changeProcumerentTable } = procurementPlanSlices.actions;
export default procurementPlanSlices.reducer;
