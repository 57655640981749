import { useAppDispatch, useAppSelector } from "app/hook";
import { TypeTruckDataType } from "app/slices/handbookSlices/typeTruckSlices/type";
import {
  fetchTypeTruck,
  handleClearFilter,
  handleTypeTruckFilter,
} from "app/slices/handbookSlices/typeTruckSlices/typeTruckSlices";
import { allTypeTruckData, allTypeTruckFilter } from "app/states/handbooks";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  Input,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import HandBookServices from "services/apiServices/spravochnik";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { typeTruckSchema } from "utilities/schema/spravochnikSchemas";
// Тип автомобиля
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
  details,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
  details: TypeTruckDataType;
}) => {
  const onSubmit = () => {
    try {
      if (get(details, "id", null)) {
        HandBookServices.UpdateTypeTruck({
          data: {
            ...values,
            id: get(details, "id", null),
          },
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      } else {
        HandBookServices.AddNewTypeTruck({
          data: values,
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
        }).then((res) => {
          if (res && res.data) {
            handleCloseModal();
            afterSubmitAction();
          }
        });
      }
      resetForm()
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imya: get(details, "imya", ""),
    },
    onSubmit,
    validationSchema: typeTruckSchema,
  });
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Input
            label={"Наименование типа автомобиля"}
            value={get(values, "imya", "")}
            handleInput={(e: string) => {
              setFieldValue("imya", e);
            }}
            required={true}
          />

          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              {get(details, "id", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const TruckTypeContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<TypeTruckDataType | any>({});
  const data = useAppSelector(allTypeTruckData);
  const filter = useAppSelector(allTypeTruckFilter);
  let LOADING = get(data, "loading", false);
  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleTypeTruckFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchTypeTruck({ ...filter, InAscOrder: false }));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const clearFilter = useCallback(() => {
    dispatch(fetchTypeTruck({ ...filter, search: "", InAscOrder: false }));
    dispatch(
      handleTypeTruckFilter({
        name: "search",
        value: "",
        InAscOrder: false,
      })
    );
  }, [dispatch, filter]);
  useEffect(()=>{
    return ()=>{
      dispatch(handleClearFilter())
    }
  },[])
  const fetchAllTypeTruckData = useCallback(() => {
    dispatch(fetchTypeTruck({ ...filter, InAscOrder: false }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((data: TypeTruckDataType) => {
    setOpenModal(true);
    setDetails(data);
  }, []);
  const handleDelete = (id: number | null) => {
    HandBookServices.DeleteTypeTruck(id).then((res) => {
      if (res.status === 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllTypeTruckData();
      }
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <Content>
          <Row>
            <Col className={"text-right"}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "TIP_AVTO_CREATE",
                    page: "TIP_AVTO",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green className={"mb-16"} onClick={handleOpenModal}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllTypeTruckData()}
              details={details}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "",
                    "ID",
                    "Пользователь",
                    "Наименования типа автомобиля",
                  ]}
                >
                  {isEqual(LOADING, false) ? (
                    <>
                      {!isEmpty(get(data, "typeTruckData", [])) ? (
                        get(data, "typeTruckData", []).map(
                          (item: TypeTruckDataType, index: number) => (
                            <tr key={item.id}>
                              <td className="td_action">
                                <ActionBase
                                  isVertical={true}
                                  pagePermission={"TIP_AVTO"}
                                  handleUpdate={() => handleOpenModal(item)}
                                  handleDelete={() =>
                                    handleDelete(get(item, "id", null))
                                  }
                                />
                              </td>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "imya", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={4}/>
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={4}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default TruckTypeContainer;
