import { motion } from "framer-motion";
import styled from "styled-components";

const AnimatedBox = styled(motion.div)`
    width: max-content;
    height: max-content;
    width: 100%;
`;

export { AnimatedBox };
