import { get, isEmpty } from "lodash";
import { TableWrapper } from "./styles/style";
import { Col, Row } from "react-grid-system";
import { RegisterSalesType } from "app/slices/reportSlices/registerSalesSlices/type";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { Title } from "components";

const RegisterSlicesContractTable = ({ dataList, click }: any) => {
  return (
    <Row className="mt-16">
      <Col className="responsive_overflow">
        <TableWrapper minWidth="1400px" className="mb-16">
          <thead>
            <tr>
              <th>№</th>
              <th>Номер договора</th>
              <th>Дата договора</th>
              <th style={{ width: "180px" }}>Заказчик</th>
              <th>Дата испол</th>
              <th>Способ оплаты</th>
              <th>Условия оплаты</th>
              <th>Способ доставки</th>
              {click ? (
                <>
                  <th>Товар</th>
                  <th>Количество</th>
                </>
              ) : null}
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            <>
              {get(dataList, "dataList.list", []).map(
                (item: RegisterSalesType, index: number) => (
                  <>
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{get(item, "nomerDoc", "")}</td>
                      <td>
                        {!isEmpty(get(item, "dataDoc", ""))
                          ? moment(get(item, "dataDoc", "")).format(
                            "DD.MM.YYYY"
                          )
                          : "-"}
                      </td>
                      <td>{get(item, "imyaKontragent", "")}</td>
                      <td>
                        {moment(get(item, "dataIsp", "")).format("DD.MM.YYYY")}
                      </td>
                      <td>{get(item, "sposobOplataName", "")}</td>
                      <td>{get(item, "imyaUsloviyaOplati", "")}</td>
                      <td>{get(item, "sposobDostavkaName", "")}</td>
                      {click ? (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      ) : null}
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={get(item, "summa", 0)}
                          thousandSeparator
                          decimalScale={2}
                        />
                      </td>
                    </tr>
                    {click &&
                      get(item, "details", []).map(
                        (product: any, index: number) => (
                          <tr
                            style={{ backgroundColor: "#f4f4f4" }}
                            key={index + 1}
                          >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{get(product, "imyaTovar", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(product, "kol", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              {" "}
                              <NumericFormat
                                displayType="text"
                                value={get(product, "summa", 0)}
                                thousandSeparator
                                decimalScale={2}
                              />
                            </td>
                          </tr>
                        )
                      )}
                  </>
                )
              )}
            </>

            <tr>
              <td colSpan={click ? 10 : 8} style={{ textAlign: "start" }}>
                <Title bold>Итого:</Title>
              </td>

              <td>
                <Title bold mk>
                  <NumericFormat
                    displayType="text"
                    value={get(dataList, "dataList.itogo.summa", 0)}
                    thousandSeparator
                    decimalScale={2}
                  />
                </Title>
              </td>
            </tr>
          </tbody>
        </TableWrapper>
      </Col>
    </Row>
  );
};

export default RegisterSlicesContractTable;
