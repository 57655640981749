import React from "react";
import ReactDOM from "react-dom/client";
import MainRouter from "routers";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persister } from "app/store";
import Theme from "theme";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { injectStore } from "services/api";
import { PrimeReactProvider } from "primereact/api";
import { ConfigProvider } from "antd";
injectStore(store);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.Suspense fallback={"loading"}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <ConfigProvider>
          <Theme>
            <PrimeReactProvider>
              <MainRouter />
            </PrimeReactProvider>
          </Theme>
        </ConfigProvider>
      </PersistGate>
    </Provider>
    <ToastContainer />
  </React.Suspense>
);
