import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
  data: { loading: false, territoriesData: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,

  },
};

export const fetchTerritories = createAsyncThunk(
  "allTerritories/fetchTerritories",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetTerritories(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);


export const fetchOwnTerritories = createAsyncThunk(
  "allTerritories/fetchOwnTerritories",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetOwnTerritories(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const territoriesSlices = createSlice({
  name: "allTerritories",
  initialState,
  reducers: {
    handleTerritoriesFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearFilterTerritories:(state)=>{
      state.filter = initialState.filter
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTerritories.pending, (state) => {
      state.data = {
        loading: true,
        territoriesData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchTerritories.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        territoriesData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchTerritories.rejected, (state, payload) => {
      state.data = {
        loading: false,
        territoriesData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
    // own
    builder.addCase(fetchOwnTerritories.pending, (state) => {
      state.data = {
        loading: true,
        territoriesData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchOwnTerritories.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        territoriesData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchOwnTerritories.rejected, (state, payload) => {
      state.data = {
        loading: false,
        territoriesData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleTerritoriesFilter ,clearFilterTerritories} = territoriesSlices.actions;
export default territoriesSlices.reducer;
