import { useAppDispatch, useAppSelector } from "app/hook";
import {
  changeActTable,
  clearActTable,
  fetchReconciliationAct,
  fetchReconciliationActDetails,
  filterActTable,
} from "app/slices/reportSlices/reconciliationActSlices/reconciliationActSlices";
import { contractorOptions } from "app/states/documents/selectStates";
import {
  reconciliationActFilter,
  reconciliationActList,
  reportActPokupki,
  reportActProjaka,
} from "app/states/reports";
import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { ReportInfoTitle } from "../components";
import ReconciliationActTable from "../components/ReconciliationActTable";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  kontragent?: string;
  dogProdaji?: string;
  dogPokupki?: string;
}

// Акт-сверки взаиморасчетов
function ReconciliationActContainer() {
  const dispatch = useAppDispatch();

  const data = useAppSelector(reconciliationActList);
  const filter = useAppSelector(reconciliationActFilter);
  const LOADING_TABLE = get(data, "loading", false);
  const prodajaOptions = useAppSelector(reportActProjaka);
  const pokupkiOptions = useAppSelector(reportActPokupki);
  const [details, setDetails] = useState<ParamState>({
    kontragent: "",
    dogPokupki: "",
    dogProdaji: "",
  });
  const contractorDropdowns = useAppSelector(contractorOptions);
  const fetchAllreconciliationAct = useCallback(() => {
    if (filter.kontragentId) dispatch(fetchReconciliationAct(filter));
  }, [dispatch, filter]);
  //excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Акт-сверки взаиморасчетов ${moment(new Date()).format(
      "YYYY-MM-DD"
    )}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `F1`);
    sheet.getCell("A1").value = `Акт-сверки взаиморасчетов с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    sheet.getRow(3).height = 20; // C3 qatridagi balandlik
    sheet.getRow(4).height = 20; // C4 qatridagi balandlik
    sheet.getRow(1).height = 20;
    sheet.mergeCells("A3", `A4`);
    sheet.getCell("A3").value = "Дата";
    sheet.mergeCells("B3", "B4");
    sheet.getCell("B3").value = "Операции";
    sheet.mergeCells("C3", "D3");
    sheet.getCell("C3").value = "OOO “OLMOS BLOK”";
    sheet.getCell("C4").value = "Дебет";
    sheet.getCell("D4").value = "Кредит";
    sheet.mergeCells("E3", "F3");
    sheet.getCell("E3").value = String(get(filter, "imyaKontragent", ""));
    sheet.getCell("E4").value = "Дебет";
    sheet.getCell("F4").value = "Кредит";
    let count = 1;
    sheet.getCell("A3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("B3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("D4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("F4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("H4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.columns = [
      { width: 30 },
      { width: 60 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const tableHeader = [
      {
        header: "Дата",
        key: "date",
        width: 30,
      },
      {
        header: "Наименованиесчета",
        key: "description",
        width: 60,
      },
      {
        header: "Дебет",
        key: "firmDebit",
        width: 30,
      },
      {
        header: "Kredit",
        key: "firmKredit",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "kontragentDebit",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "kontragentKredit",
        width: 30,
      },
    ];

    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(3);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;

    // body.height = 25;
    // body.alignment = { vertical: "middle", horizontal: "center" };
    get(data, "dataList.details", [])?.forEach((item, index) => {
      let row = sheet.addRow({
        date: get(item, "date", ""),
        description: get(item, "description", ""),
        firmDebit: Number(get(item, "firmDebit", 0)),
        firmKredit: Number(get(item, "firmKredit", 0)),
        kontragentDebit: Number(get(item, "kontragentDebit", 0)),
        kontragentKredit: Number(get(item, "kontragentKredit", 0)),
      });
      // row.height = 25;
      // row.alignment = { vertical: "middle", horizontal: "center" };
      row.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
        };
      });
      counterRow++;
    });
    sheet.addRow({
      date: "ㅤ",
      description: "Обороты за период:",
      firmDebit: Number(get(data, "dataList.oborotZaPeriodDebit", "")),
      firmKredit: Number(get(data, "dataList.oborotZaPeriodKredit", 0)),
      kontragentDebit: Number(get(data, "dataList.oborotZaPeriodKredit", 0)),
      kontragentKredit: Number(get(data, "dataList.oborotZaPeriodDebit", 0)),
    });
    sheet.addRow({
      date: "ㅤ",
      description: `Сальдо на ${filter.toDate}`,
      firmDebit: Number(get(data, "dataList.saldoNaDataKonetsDebit", 0)),
      firmKredit: Number(get(data, "dataList.saldoNaDataKonetsKredit", 0)),
      kontragentDebit: Number(get(data, "dataList.saldoNaDataKonetsKredit", 0)),
      kontragentKredit: Number(get(data, "dataList.saldoNaDataKonetsDebit", 0)),
    });
    // for (let i = 0; i < 3; i++) {
    //   const headerRow1 = sheet.getRow(counterRow + 4 + i);
    //   headerRow1.height = 25;
    //   headerRow1.alignment = { vertical: "middle", horizontal: "center" };
    // }
    // sheet.getRow(counterRow + 3).height = 25;
    // sheet.getRow(counterRow + 3).alignment = {
    //   vertical: "middle",
    //   horizontal: "center",
    // };
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`F${counterRow + count + 5}`);
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach((item: any) => {
      sheet.getCell(item).font = {
        size: 11,
        bold: true,
      };
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  // clear
  const handleClearFilter = () => {
    dispatch(clearActTable());

    setDetails({
      kontragent: "",
      dogPokupki: "",
      dogProdaji: "",
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeActTable(null));
    }

    dispatch(filterActTable({ ...filter, name, value }));
  };
  const fetchSelectOptions = useCallback(() => {
    if (filter.fromDate && filter.toDate && filter.kontragentId) {
      const params: any = {
        kontragentId: filter.kontragentId,
      };

      dispatch(fetchReconciliationActDetails(params));
    }
  }, [filter.fromDate, filter.toDate, filter.kontragentId]);

  useEffect(() => {
    fetchSelectOptions();
  }, [fetchSelectOptions]);

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  placeholder="Дата начало"
                  value={get(filter, "fromDate", "")}
                  width="150px"
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  placeholder="Дата конец"
                  width="150px"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />

                <BaseSelect
                  options={contractorDropdowns}
                  value={get(filter, "kontragentId", "")}
                  placeholder="Контрагент"
                  width="250px"
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "kontragentId",
                      value: e.value,
                      clear: true,
                    });
                    handleChangeFilter({
                      name: "imyaKontragent",
                      value: e.label,
                      clear: true,
                    });
                    if (!isEqual(e.value, get(filter, "kontragentId", null))) {
                      handleChangeFilter({
                        name: "dogProdaji",
                        value: null,
                        clear: true,
                      });
                      handleChangeFilter({
                        name: "dogPokupki",
                        value: null,
                        clear: true,
                      });
                    }
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right px-16"} xs={5}>
              {!isEmpty(get(data, "dataList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllreconciliationAct}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            {/* no */}
            <Col xs={12} className="mt-16">
              <Row className="mb-16">
                <Col xs={12}>
                  <Title sm bold>
                    Необязательные параметры
                  </Title>
                </Col>
              </Row>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  options={prodajaOptions}
                  value={get(filter, "dogProdaji", "")}
                  placeholder="Договор продажи"
                  width="200px"
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "dogProdaji",
                      value: e.value,
                      clear: true,
                    });
                    setDetails({
                      ...details,
                      dogProdaji: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      filterActTable({
                        ...filter,
                        dogProdaji: e.value,
                      })
                    );
                  }}
                />

                <BaseSelect
                  options={pokupkiOptions}
                  value={get(filter, "dogPokupki", "")}
                  placeholder="Договор покупки"
                  width="200px"
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "dogPokupki",
                      value: e.value,
                      clear: true,
                    });
                    setDetails({
                      ...details,
                      dogProdaji: get(e, "label", ""),
                    });
                    if (data.dataList === null) return;
                    dispatch(
                      filterActTable({
                        ...filter,
                        dogPokupki: e.value,
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Акт-сверки взаиморасчетов"}
                  />

                  {!isEmpty(get(data, "dataList.details", [])) ? (
                    <ReconciliationActTable data={data} filter={filter} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default ReconciliationActContainer;
