import { memo } from "react";
import { textFormatter } from "utilities/helpers";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
type Props = {
  kol?: number | string | null;
  handleOnChange: (e: Event) => void;
  maxKol?: number | string | null;
  disabled?: boolean;
};
const TableInput = ({ kol, handleOnChange, maxKol, disabled }: Props) => {
  console.log(kol, "kol data");
  return (
    <div style={{ width: "100%", position: "relative" }}>
      {maxKol ? (
        <BaseMarkInput
          width={"110px"}
          placeholder={`до: ${textFormatter(Number(maxKol))}`}
          value={kol}
          disabled={disabled}
          handleInput={(e: any) => {
            handleOnChange(e);
          }}
          max={maxKol}
        />
      ) : (
        <BaseMarkInput
          width={"110px"}
          value={kol}
          disabled={disabled}
          handleInput={(e: any) => {
            handleOnChange(e);
          }}
        />
      )}
    </div>
  );
};

export default memo(TableInput);
