import { createSelector } from "@reduxjs/toolkit";
import { selectSemiProductFilter } from "app/selectors/documents";
import {
  selectAllBanks,
  selectAllBanksFilter,
  selectAllBanksAccounts,
  selectAllBanksAccountsFilter,
  selectAllKassa,
  selectAllKassaFilter,
  selectAllFizList,
  selectAllFizListFilter,
  selectAllYurList,
  selectAllYurListFilter,
  selectPhysPerson,
  selectPhysPersonFilter,
  selectLargePerson,
  selectLargePersonFilter,
  selectCounterpart,
  selectCounterpartFilter,
  selectBankCounterpart,
  selectBankCounterpartFilter,
  selectDeliveryAddressFilter,
  selectDeliveryAddress,
  selectTermsPaymentFilter,
  selectTermsPayment,
  selectWarHouseFilter,
  selectWarHouse,
  selectTerritories,
  selectTerritoriesFilter,
  selectWorkshop,
  selectWorkshopFilter,
  selectUnitsMeasurementFilter,
  selectUnitsMeasurement,
  selectDiscount,
  selectDiscountFilter,
  selectTypeProduct,
  selectTypeProductFilter,
  selectProduct,
  selectProductFilter,
  selectPrice,
  selectPriceFilter,
  selectCalculation,
  selectCalculationFilter,
  selectRawMaterial,
  selectRawMaterialFilter,
  selectTypeMaterial,
  selectTypeMaterialFilter,
  selectPriceMaterial,
  selectPriceMaterialFilter,
  selectRateVAT,
  selectRateVATFilter,
  selectExpanseItem,
  selectExpanseItemFilter,
  selectTypeTruck,
  selectTypeTruckFilter,
  selectTruck,
  selectTruckFilter,
  selectDrivers,
  selectDriversFilter,
  selectUsers,
  selectUsersFilter,
  selectDetailsCompany,
  selectDetailsCompanyFilter,
  selectSubConto,
  selectSubContoFilter,
  selectMenageAccountsFilter,
  selectMenageAccounts,
  selectCalculationMaterials,
  selectCounterpartDto,
  selectSemiProduct,
  selectSemiCalculation,
  selectSemiCalculationFilter,
  selectSemiCalculationMaterials,
} from "app/selectors/handbooks/handbookSelectors";
import { RawMaterialDataType } from "app/slices/handbookSlices/rawMaterialSlices/type";
import { get } from "lodash";
// banks states
export const allBanksData = createSelector(selectAllBanks, (data) => data);
export const allBanksFilter = createSelector(
  selectAllBanksFilter,
  (filter) => filter
);

// bank accounts state
export const allBanksAccountsData = createSelector(
  selectAllBanksAccounts,
  (data) => data
);

export const allBanksAccountsFilter = createSelector(
  selectAllBanksAccountsFilter,
  (filter) => filter
);

// all kassa state
export const allKassaData = createSelector(selectAllKassa, (data) => data);
export const allKassaFilter = createSelector(
  selectAllKassaFilter,
  (filter) => filter
);
// all fiz state
export const allFizListData = createSelector(selectAllFizList, (data) => data);
export const allFizListFilter = createSelector(
  selectAllFizListFilter,
  (filter) => filter
);
// all yur state
export const allYurListData = createSelector(selectAllYurList, (data) => data);
export const allYurListFilter = createSelector(
  selectAllYurListFilter,
  (filter) => filter
);
// all phys Person state
export const allPhysPersonData = createSelector(
  selectPhysPerson,
  (data) => data
);
export const allPhysPersonFilter = createSelector(
  selectPhysPersonFilter,
  (filter) => filter
);
// all large Person state
export const allLargePersonData = createSelector(
  selectLargePerson,
  (data) => data
);
export const allLargePersonFilter = createSelector(
  selectLargePersonFilter,
  (filter) => filter
);

export const allCounterpartData = createSelector(
  selectCounterpart,
  (data) => data
);
export const allCounterpartFilter = createSelector(
  selectCounterpartFilter,
  (filter) => filter
);
export const counterpartDto = createSelector(
  selectCounterpartDto,
  (addressDto) => addressDto
);
// all bank counterpart state
export const allBankCounterpartData = createSelector(
  selectBankCounterpart,
  (data) => data
);
export const allBankCounterpartFilter = createSelector(
  selectBankCounterpartFilter,
  (filter) => filter
);

// all delivery address state
export const allDeliveryAddressData = createSelector(
  selectDeliveryAddress,
  (data) => data
);
export const allDeliveryAddressFilter = createSelector(
  selectDeliveryAddressFilter,
  (filter) => filter
);

// all terms payment state
export const allTermsPaymentData = createSelector(
  selectTermsPayment,
  (data) => data
);

export const allTermsPaymentFilter = createSelector(
  selectTermsPaymentFilter,
  (filter) => filter
);

// all warHouse state
export const allWarHouseData = createSelector(selectWarHouse, (data) => data);
export const allWarHouseFilter = createSelector(
  selectWarHouseFilter,
  (filter) => filter
);

// all territories state
export const allTerritoriesData = createSelector(
  selectTerritories,
  (territoriesData) => territoriesData
);
export const allTerritoriesFilter = createSelector(
  selectTerritoriesFilter,
  (filter) => filter
);

// all workshop state
export const allWorkshopData = createSelector(
  selectWorkshop,
  (workshopData) => workshopData
);
export const allWorkshopFilter = createSelector(
  selectWorkshopFilter,
  (filter) => filter
);

// all Units Measurement state
export const allUnitsMeasurementData = createSelector(
  selectUnitsMeasurement,
  (unitsMeasurementData) => unitsMeasurementData
);
export const allUnitsMeasurementFilter = createSelector(
  selectUnitsMeasurementFilter,
  (filter) => filter
);

// all discounts state
export const allDiscountsData = createSelector(
  selectDiscount,
  (discountData) => discountData
);
export const allDiscountsFilter = createSelector(
  selectDiscountFilter,
  (filter) => filter
);

// all type product state
export const allTypeProductData = createSelector(
  selectTypeProduct,
  (typeProductData) => typeProductData
);
export const allTypeProductFilter = createSelector(
  selectTypeProductFilter,
  (filter) => filter
);

// all product state
export const allProductData = createSelector(
  selectProduct,
  (productData) => productData
);
export const allProductFilter = createSelector(
  selectProductFilter,
  (filter) => filter
);

// all price state
export const allPriceData = createSelector(
  selectPrice,
  (priceData) => priceData
);
export const allPriceFilter = createSelector(
  selectPriceFilter,
  (filter) => filter
);

// all calculation state
export const allCalculationData = createSelector(
  selectCalculation,
  (calculationData) => calculationData
);

export const allCalculationFilter = createSelector(
  selectCalculationFilter,
  (filter) => filter
);
export const calculationMaterials = createSelector(
  selectCalculationMaterials,
  (formMaterial) => formMaterial
);
export const allCalculationMaterials = createSelector(
  selectCalculationMaterials,
  (formMaterials) => {
    const formMaterial = {
      tovarId: get(formMaterials, "data.tovarId", ""),
      registerDate: get(formMaterials, "data.registerDate", null),
    };
    const res = {
      ...formMaterial,
      data: {
        kalkulyatsiyaDetailsList: get(
          formMaterials,
          "data.kalkulyatsiyaDetailsList",
          []
        ),
      },
    };
    return res;
  }
);

// all raw material state
export const allRawMaterialData = createSelector(
  selectRawMaterial,
  (materialData) => materialData
);
export const allRawMaterialFilter = createSelector(
  selectRawMaterialFilter,
  (filter) => filter
);

// all type material state
export const allTypeMaterial = createSelector(
  selectTypeMaterial,
  (typeMaterialData) => typeMaterialData
);
export const allTypeMaterialFilter = createSelector(
  selectTypeMaterialFilter,
  (filter) => filter
);

// all price Material state
export const allPriceMaterialData = createSelector(
  selectPriceMaterial,
  (priceMaterialData) => priceMaterialData
);
export const allPriceMaterialFilter = createSelector(
  selectPriceMaterialFilter,
  (filter) => filter
);
// SEMI products
export const allSemiProductData = createSelector(
  selectSemiProduct,
  (productData) => productData
);
export const allSemiProductFilter = createSelector(
  selectSemiProductFilter,
  (filter) => filter
);
export let loadingSemiDetails = createSelector(
  selectSemiProduct,
  (data) => data.loading
);
export const SemiProductDataOptions = createSelector(
  [
    selectSemiProduct,
    selectRawMaterial,
    selectCalculationMaterials,
    (store, id) => id,
  ],
  (productData, materialData, material, id) => {
    let removeMaterialList: any = [];
    get(material, "data.kalkulyatsiyaDetailsList", []).map((item: any) => {
      if (item) {
        removeMaterialList.push(item.materialId);
      }
    });

    switch (id) {
      case 1:
        const materialSemi = get(productData, "productData", []).map(
          (item: any) => ({
            label: get(item, "polnoyeImyaMateriala", ""),
            value: get(item, "id", ""),
            edIzmId: get(item, "edIzmId", ""),
            imyaEdIzm: get(item, "imyaEdIzm", ""),
          })
        );
        return materialSemi;

      case 2:
        const materialRaw = get(materialData, "materialData", []).map(
          (data: RawMaterialDataType) => ({
            label: get(data, "imyaMateriala", ""),
            value: get(data, "id", ""),
            edIzmId: get(data, "edIzmId", ""),
            imyaEdIzm: get(data, "imyaEdIzm", ""),
          })
        );
        return materialRaw;
    }
  }
);

// semi calculation state
export const semiCalculationData = createSelector(
  selectSemiCalculation,
  (calculationData) => calculationData
);

export const semiCalculationFilter = createSelector(
  selectSemiCalculationFilter,
  (filter) => filter
);
export const semiCalculationMaterialsData = createSelector(
  selectSemiCalculationMaterials,
  (formMaterial) => formMaterial
);
export const semiCalculationMaterials = createSelector(
  selectSemiCalculationMaterials,
  (formMaterials) => {
    const formMaterial = {
      tovarId: get(formMaterials, "data.tovarId", ""),
      registerDate: get(formMaterials, "data.registerDate", null),
    };
    const res = {
      ...formMaterial,
      data: {
        kalkulyatsiyaDetailsList: get(
          formMaterials,
          "data.kalkulyatsiyaDetailsList",
          []
        ),
      },
    };
    return res;
  }
);

// all rate VAT state
export const allRateVATData = createSelector(
  selectRateVAT,
  (rateData) => rateData
);
export const allRateVATFilter = createSelector(
  selectRateVATFilter,
  (filter) => filter
);

// all Expanse Items state
export const allExpanseItem = createSelector(
  selectExpanseItem,
  (expanseItemData) => expanseItemData
);
export const allExpanseItemFilter = createSelector(
  selectExpanseItemFilter,
  (filter) => filter
);

// all type truck state
export const allTypeTruckData = createSelector(
  selectTypeTruck,
  (typeTruckData) => typeTruckData
);
export const allTypeTruckFilter = createSelector(
  selectTypeTruckFilter,
  (filter) => filter
);

// all truck state
export const allTruckData = createSelector(
  selectTruck,
  (truckData) => truckData
);
export const allTruckFilter = createSelector(
  selectTruckFilter,
  (filter) => filter
);

// all drivers state
export const allDrivers = createSelector(
  selectDrivers,
  (driversData) => driversData
);
export const allDriversFilter = createSelector(
  selectDriversFilter,
  (filter) => filter
);

// all Users state
export const allUsersData = createSelector(
  selectUsers,
  (usersData) => usersData
);
export const allUsersFilter = createSelector(
  selectUsersFilter,
  (filter) => filter
);

// all  details company state
export const allDetailsCompanyData = createSelector(
  selectDetailsCompany,
  (companyData) => companyData
);
export const allDetailsCompanyFilter = createSelector(
  selectDetailsCompanyFilter,
  (filter) => filter
);

// all sub conto state
export const allSubConto = createSelector(
  selectSubConto,
  (subContoData) => subContoData
);
export const allSubContoFilter = createSelector(
  selectSubContoFilter,
  (filter) => filter
);

// all manage account state
export const allMenageAccountData = createSelector(
  selectMenageAccounts,
  (menageAccountsData) => menageAccountsData
);
export const allMenageAccountFilter = createSelector(
  selectMenageAccountsFilter,
  (filter) => filter
);
