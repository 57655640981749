import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import { Col, Row } from "react-grid-system";
import { CommodityReportsTable } from "../components";
import { useAppDispatch, useAppSelector } from "app/hook";
import { commodityReportFilter, commodityReportList } from "app/states/reports";
import {
  changeCommodityReportTable,
  clearCommodityReportTable,
  fetchCommodityReport,
  filterCommodityReportTable,
} from "app/slices/reportSlices/commodityReportSlices/commodityReportSlices";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  getSkladGP,
  getTypeProductData,
  getWarHouseData,
} from "app/states/handbooks/selectStates";
import { fetchOwnSkladGP } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { get, isEmpty, isEqual } from "lodash";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

// Товарный отчет по Склад готовой продукции
const CommodityReportsContainer = () => {
  const dispatch = useAppDispatch();

  // data
  const data = useAppSelector(commodityReportList);
  const filter = useAppSelector(commodityReportFilter);
  const wareHouse = useAppSelector(getSkladGP);
  const LOADING_TABLE = get(data, "loading", false);
  const tovariiOptions = useAppSelector(getTypeProductData);
  const fetchAllCommodityReport = useCallback(() => {
    if (filter.skladId) dispatch(fetchCommodityReport(filter));
  }, [dispatch, filter]);

  const handleClearFilter = () => {
    dispatch(clearCommodityReportTable());
    setDetails({
      sklad: "",
      vidTovaraId: null,
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeCommodityReportTable(null));
    }

    dispatch(filterCommodityReportTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnSkladGP({}));
    dispatch(fetchTypeProduct({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Товарный отчет по Склад готовой продукции ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const detailsArray = [
      {
        value: get(filter, "skladId", ""),
        label: `Склад:  ${
          wareHouse.find((val: any) => val.value == filter.skladId).label
        }`,
      },
      {
        value: get(details, "vidTovaraId", ""),
        label: `Вид товар:  ${get(details, "vidTovaraId", "")}`,
      },
    ];
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `K1`);
    sheet.getCell(
      "A1"
    ).value = `Товарный отчет по Склад готовой продукции с  ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    let count = 1;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(`A${1 + index + 1}`, `K${1 + index + 1}`);
        sheet.getCell(`A${1 + index + 1}`).value = val.label;

        count++;
      }
    });
    sheet.getRow(count + 2).height = 20; // C3 qatridagi balandlik
    sheet.getRow(count + 3).height = 20; // C4 qatridagi balandlik
    sheet.getRow(count).height = 20;
    sheet.mergeCells(`A${count + 2}`, `A${count + 3}`);
    sheet.getCell(`A${count + 2}`).value = "№";
    sheet.mergeCells(`B${count + 2}`, `B${count + 3}`);
    sheet.getCell(`B${count + 2}`).value = "Материал";
    sheet.mergeCells(`C${count + 2}`, `C${count + 3}`);
    sheet.getCell(`C${count + 2}`).value = "Единица измерения";
    sheet.mergeCells(`D${count + 2}`, `E${count + 2}`);
    sheet.getCell(`D${count + 2}`).value = "Начальный остаток";
    sheet.getCell(`D${count + 3}`).value = "Количество";
    sheet.getCell(`E${count + 3}`).value = "Сумма";
    sheet.mergeCells(`F${count + 2}`, `G${count + 2}`);
    sheet.getCell(`F${count + 2}`).value = "Приход";
    sheet.getCell(`F${count + 3}`).value = "Количество";
    sheet.getCell(`G${count + 3}`).value = "Сумма";
    sheet.mergeCells(`H${count + 2}`, `I${count + 2}`);
    sheet.getCell(`H${count + 2}`).value = "Расход";
    sheet.getCell(`H${count + 3}`).value = "Количество";
    sheet.getCell(`I${count + 3}`).value = "Сумма";
    sheet.mergeCells(`J${count + 2}`, `K${count + 2}`);
    sheet.getCell(`J${count + 2}`).value = "Конечный остаток";
    sheet.getCell(`J${count + 3}`).value = "Количество";
    sheet.getCell(`K${count + 3}`).value = "Сумма";

    sheet.getCell(`A${count + 2}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`B${count + 2}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`C${count + 2}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`C${count + 3}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`D${count + 3}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`E${count + 2}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`E${count + 3}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`F${count + 3}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`G${count + 2}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`G${count + 3}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell(`H${count + 3}`).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.columns = [
      { width: 30 },
      { width: 60 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const tableHeader = [
      {
        header: "Код",
        key: "index",
        width: 5,
      },
      {
        header: "Наименованиесчета",
        key: "imyaTovara",
        width: 60,
      },
      {
        header: "Дебет",
        key: "imyaEdIzm",
        width: 30,
      },
      {
        header: "Kredit",
        key: "nachalniyOstatok1",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "nachalniyOstatok2",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "prixod1",
        width: 30,
      },
      {
        header: "konechniyOstatokDebit",
        key: "prixod2",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "rasxod1",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "rasxod2",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "konechniyOstatok1",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "konechniyOstatok2",
        width: 30,
      },
    ];

    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    // sheet.getRow(5).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 3);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "commodityList.list", [])?.forEach((item: any, index: number) => {
      sheet.addRow({
        index: index + 1,
        imyaTovara: get(item, "imyaTovara", ""),
        imyaEdIzm: get(item, "imyaEdIzm", ""),
        nachalniyOstatok1: Number(get(item, "nachalniyOstatok.kolichestva", 0)),
        nachalniyOstatok2: Number(get(item, "nachalniyOstatok.summa", 0)),
        prixod1: Number(get(item, "prixod.kolichestva", 0)),
        prixod2: Number(get(item, "prixod.summa", 0)),
        rasxod1: Number(get(item, "rasxod.kolichestva", 0)),
        rasxod2: Number(get(item, "rasxod.summa", 0)),
        konechniyOstatok1: Number(get(item, "konechniyOstatok.kolichestva", 0)),
        konechniyOstatok2: Number(get(item, "konechniyOstatok.summa", 0)),
      });
      const headerRow1 = sheet.getRow(index + 5);
      // headerRow1.height = 25;
      // headerRow1.alignment = { vertical: "middle", horizontal: "center" };
      counterRow++;
    });

    sheet.mergeCells(
      `A${counterRow + count + 3}`,
      `C${counterRow + count + 3}`
    );
    sheet.getCell(`A${counterRow + count + 3}`).value =
      "Итого:  ㅤ ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ";
    sheet.getCell(`D${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.NachalniyKol", 0)
    );
    sheet.getCell(`E${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.NachalniySum", 0)
    );
    sheet.getCell(`F${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.PrixodKol", 0)
    );
    sheet.getCell(`G${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.PrixodSum", 0)
    );
    sheet.getCell(`H${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.RasxodKol", 0)
    );
    sheet.getCell(`I${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.RasxodSum", 0)
    );
    sheet.getCell(`J${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.KonechniyKol", 0)
    );
    sheet.getCell(`K${counterRow + count + 3}`).value = Number(
      get(data, "commodityList.itogo.KonechniySum", 0)
    );
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      "H" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),
      "I" + (count + 3),
      "J" + (count + 3),
      "K" + (count + 3),

      "A" + (count + counterRow + 3),
      "B" + (count + counterRow + 3),
      "C" + (count + counterRow + 3),
      "D" + (count + counterRow + 3),
      "E" + (count + counterRow + 3),
      "F" + (count + counterRow + 3),
      "G" + (count + counterRow + 3),
      "H" + (count + counterRow + 3),
      "I" + (count + counterRow + 3),
      "J" + (count + counterRow + 3),
      "K" + (count + counterRow + 3),
      // "G" + (count + 3),
      // "H" + (count + 3),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`K${counterRow + count + 3}`);
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach((item: any) => {
      sheet.getCell(item).font = {
        size: 11,
        bold: true,
      };
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const [details, setDetails] = useState<{
    vidTovaraId: number | string | null;
    sklad: string | null;
  }>({
    vidTovaraId: "",
    sklad: "",
  });
  useEffect(() => {
    get(filter, "skladId", "") &&
      setDetails({
        ...details,
        sklad: wareHouse.find(
          (item: any) => item.value == get(filter, "skladId", "")
        ),
      });
  }, [wareHouse]);
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  options={wareHouse}
                  placeholder="Склад"
                  value={get(filter, "skladId", "")}
                  handleValueLabel={(e: any) => {
                    setDetails({ ...details, sklad: e.label });
                    handleChangeFilter({
                      name: "skladId",
                      value: e.value,
                      clear: true,
                    });
                  }}
                />

                <DatePicker
                  placeholder="Дата начало"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "fromDate", "")}
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />

                <DatePicker
                  placeholder="Дата конец"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "commodityList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllCommodityReport}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={12} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  options={tovariiOptions}
                  placeholder="Вид товар"
                  width={"200px"}
                  handleValueLabel={(e: any) => {
                    if (filter.vidTovaraId === e.value) return;
                    handleChangeFilter({
                      name: "vidTovaraId",
                      value: e.value,
                      clear: true,
                    });
                    setDetails({
                      ...details,
                      vidTovaraId: get(e, "label", ""),
                    });
                    if (data.commodityList === null) return;
                    dispatch(
                      fetchCommodityReport({ ...filter, vidTovaraId: e.value })
                    );
                  }}
                  isSearchable
                  value={get(filter, "vidTovaraId", null)}
                />
              </FlexBox>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.commodityList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Товарный отчет по Склад готовой продукции"}
                  />
                  {!isEmpty(get(data, "commodityList.list", [])) ? (
                    <CommodityReportsTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default CommodityReportsContainer;
