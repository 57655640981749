import { Col, Container, Row } from "react-grid-system";
import { NavBarContainer } from "components/Menu/style";
import Logo from "components/Logo/Logo";
import MenuItem from "components/MenuItem/MenuItem";
import { get } from "lodash";
import { FlexBox } from "components";
import Profile from "components/Profile/Profile";
import { useAppSelector } from "app/hook";
import { navBarMenus } from "app/states/menu";
import { memo } from "react";
import HasAccess from "routers/HasAccess";
import { PROTECTED_TYPE } from "utilities/constants";

const OrgName = "OLMOS BLOK";

const Menu = () => {
  const menus = useAppSelector(navBarMenus);
  return (
    <NavBarContainer>
      <Container fluid>
        <Row align="center">
          <Col xs={2.6}>
            <Logo name={OrgName} />
          </Col>
          <Col xs={7}>
            <FlexBox gap={"3%"}>
              {menus.map((title: any, index: number) => (
                <HasAccess key={index + 1}>
                  {({ userCan }: any) =>
                    userCan({
                      department: get(title, "department", ""),
                      type: PROTECTED_TYPE.DEPARTMENTS,
                      page: "KASSA_DOC",
                    }) && (
                      <MenuItem
                        key={index + 1}
                        title={get(title, "name", "")}
                        url={get(title, "id", "")}
                      />
                    )
                  }
                </HasAccess>
              ))}
            </FlexBox>
          </Col>
          <Col xs={2.4}>
            <Profile />
          </Col>
        </Row>
      </Container>
    </NavBarContainer>
  );
};

export default memo(Menu);
