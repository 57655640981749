import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchAllCostsInCash,
  fetchAllCostsInCashStat,
  fetchAllCostsInCashTemp,
  handleFilterChange,
} from "app/slices/documentSlices/costsInCashSlice/costsInCashSlice";
import {
  costsInCash,
  costsInCashFilter,
  costsInCashStat,
  costsInCashTemps,
} from "app/states/documents";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import delate from "assets/images/icons/delate.svg";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { tipRasxodaOptions } from "app/states/enumerations/selectStates";
import { fetchTypeFlow } from "app/slices/enumerationsSlices/typeFlowSlices/typeFlowSlices";
import HasAccess from "routers/HasAccess";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
// Расход наличный денег
const ExpenseCashContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const filter = useAppSelector(costsInCashFilter);
  const allCostsInCash = useAppSelector(costsInCash);
  const allCostsInCashTemps = useAppSelector(costsInCashTemps);
  const LOADING_TABLE = get(allCostsInCash, "loading", []);
  const tipRasxoda = useAppSelector(tipRasxodaOptions);
  const statistics = useAppSelector(costsInCashStat);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_COSTS_IN_CASH
  );
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchCostsInCash = useCallback(() => {
    dispatch(fetchAllCostsInCash(filter));
    dispatch(fetchAllCostsInCashStat());
  }, [dispatch, filter]);

  const fetchCostsInCashTemp = useCallback(() => {
    dispatch(fetchAllCostsInCashTemp());
  }, [dispatch]);

  const clearFilter = useCallback(() => {
    dispatch(
      handleFilterChange({
        name: "tipRasxodaId",
        value: null,
      })
    );

    dispatch(
      handleFilterChange({
        name: "fromDate",
        value: null,
      })
    );

    dispatch(
      handleFilterChange({
        name: "toDate",
        value: null,
      })
    );

    dispatch(
      fetchAllCostsInCash({
        ...filter,
        tipRasxodaId: null,
        fromDate: null,
        toDate: null,
      })
    );
  }, [dispatch, filter]);

  const handleTempRemove = (id: string | null) => {
    DocumentServices.RemoveCostInCashTemp(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchCostsInCashTemp();
      }
    });
  };
  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeleteCostInCashData(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchAllCostsInCash(filter));
        dispatch(fetchAllCostsInCashStat());
      }
    });
  };
  // status
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRedordsCostInCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllCostsInCash(filter));
      }
    });
  };
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRedordsCostInCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        dispatch(fetchAllCostsInCash(filter));
      }
    });
  };
  const navigateExpenseCash = (param: { id: string; isTemp: boolean }) => {
    navigate(Documents.ADD_COSTS_IN_CASH, {
      state: param,
    });
  };
  const handleAddButton = () => {
    navigate(Documents.ADD_COSTS_IN_CASH);
  };
  useEffect(() => {
    fetchCostsInCash();
  }, [filter.page, filter.size]);

  useEffect(() => {
    fetchCostsInCashTemp();
    dispatch(fetchTypeFlow({}));
  }, []);

  // for adding  new form
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={10}>
              <ShowStatistics statistic={statistics} />
            </Col>
            <Col xs={2} style={{ textAlign: "end" }}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "RASXOD_ND_CREATE",
                    page: "RASXOD_ND",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      + Добавить
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={8}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  options={tipRasxoda}
                  placeholder="Поиск по типу расхода"
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "tipRasxodaId",
                        value: e,
                      })
                    );
                  }}
                  value={get(filter, "tipRasxodaId", null)}
                />
                <DatePicker
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"}>
              <Button
                primary
                className={"mr-16"}
                handleClick={fetchCostsInCash}
              >
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Наименование кассы",
                    "Тип расхода",
                    "Водитель",
                    "Контрагент",
                    "Договор покупки",
                    "Территория",
                    "Статья расхода",
                    "Сумма",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      <ContextMenu
                        handleNavigate={navigateExpenseCash}
                        deleteDocument={handleDeleteDocument}
                        handleDeleteRecords={handleDeleteRecords}
                        handleUndoRecords={handleUndoRecords}
                        changeItem={true}
                        deleteItem={true}
                        markItem={true}
                        uncheckItem={true}
                        userPermission={"PRIXOD_ND"}
                        documentInfo={documentInfo}
                      />

                      {!isEmpty(get(allCostsInCashTemps, "data.data", [])) &&
                        get(allCostsInCashTemps, "data.data", []).map(
                          (item: any, index: any) => (
                            <tr
                              onClick={() =>
                                navigateExpenseCash({
                                  isTemp: true,
                                  id: get(item, "id", ""),
                                })
                              }
                              key={get(item, "id", index + 1)}
                              className="cursor-pointer"
                            >
                              <td>
                                <div
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    handleTempRemove(get(item, "id", null));
                                  }}
                                >
                                  <ReactSVG
                                    src={delate}
                                    className={"delete_button svg_icon"}
                                  />
                                </div>
                              </td>
                              <td>{get(item, "user", "")}</td>
                              <td>{get(item, "nomerDoc", "")}</td>
                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "imyaKassa", "")}</td>
                              <td>{get(item, "imyaTipRasxoda", "")}</td>
                              <td>{get(item, "imyaVoditeli", "")}</td>
                              <td>{get(item, "imyaKontragent", "")}</td>
                              <td>{get(item, "nomerDogPokupka", "")}</td>
                              <td>{get(item, "imyaTerritoriya", "")}</td>
                              <td>{get(item, "imyaStatyaRasxoda", "")}</td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "summa", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>

                              <td>-</td>
                              <td>{get(item, "status", "-")}</td>
                            </tr>
                          )
                        )}
                      {!isEmpty(get(allCostsInCash, "data.data", []))
                        ? get(allCostsInCash, "data.data", []).map(
                            (item: any, index: any) => (
                              <tr
                                onClick={() =>
                                  navigateExpenseCash({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                key={get(item, "nomerDoc", index + 1)}
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      allCostsInCash,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      allCostsInCash,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaKassa", "")}</td>
                                <td>{get(item, "imyaTipRasxoda", "")}</td>
                                <td>{get(item, "imyaVoditeli", "")}</td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "nomerDogPokupka", "")}</td>
                                <td>{get(item, "imyaTerritoriya", "")}</td>
                                <td>{get(item, "imyaStatyaRasxoda", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summa", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>

                                <td>
                                  {moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(
                            get(allCostsInCashTemps, "data.data", [])
                          ) && <Nodata colLength={13} />}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={13}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(allCostsInCash, "data.pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(allCostsInCash, "data.pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChange({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    allCostsInCash,
                    "data.pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(
                    allCostsInCash,
                    "data.pagination.totalPages",
                    0
                  )}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChange({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default ExpenseCashContainer;
