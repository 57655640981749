import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchDeliveryMethod } from "app/slices/enumerationsSlices/deliveryMethodSlices/deliveryMethodSlices";
import { fetchPaymentMethod } from "app/slices/enumerationsSlices/paymentMethodSlices/paymentMethodSlices";
import { fetchTypeContract } from "app/slices/enumerationsSlices/typeContractSlices/typeContractSlices";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchRateVAT } from "app/slices/handbookSlices/rateVATSlices/rateVATSlices";
import { fetchTermsPayment } from "app/slices/handbookSlices/temsPaymentSlices/termsPaymentSlices";
import { fetchOwnTerritories } from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import { fetchUnitsMeasurement } from "app/slices/handbookSlices/unitsMeasurementSlices/unitsMeasurementSlices";
import { fetchOwnWarHouse } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import {
  fetchProfileInfo,
  updateDocumentList,
} from "app/slices/profileSlices/profileSlices";
import { FilterDocumentList } from "app/states/menu";
import { profileData } from "app/states/profile";
import closeMenu from "assets/images/icons/close_menu.svg";
import menuuIcon from "assets/images/icons/menu.svg";
import { Breadcrumb, FlexBox, Sidebar, SidebarItem } from "components";
import { SidebarItemWrapper } from "components/SidebarItem/style";
import { get, isEqual } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Main } from "routers/paths";
import UsersServices from "services/apiServices/users";
import { Layout } from "antd";
import { Col, Row } from "react-grid-system";
import { MAIN_DEPARTMENT } from "routers/constants";

const DocumentsInnerLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sideBar = useAppSelector((store) =>
    FilterDocumentList(store, Main.DOCUMENTS)
  );
  const pageListData = useAppSelector(profileData);
  // sidebar
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;
  // sidebar sorter drop detilas
  const sidebarSortData = useMemo(
    () => get(pageListData, "profileData.documentList", []),
    [pageListData]
  );
  // details sidear permission
  const pageData = useMemo(
    () => get(pageListData, "profileData.permissionList", []),
    [pageListData]
  );
  const data = pageData.find(
    (item: any) => item.department === MAIN_DEPARTMENT.DOCUMENTS
  );
  //  checking only actions read
  const permissionList = get(data, "pages", []).map((item: any) => {
    if (get(item, "actions", []).includes(`${item.page}_READ`)) {
      return get(item, "page", "");
    }
  });
  //  navigate
  const navigateToFirstSidebar = useCallback(() => {
    if (isEqual(get(location, "pathname", ""), `/${Main.DOCUMENTS}`)) {
      const frstAllowedUrl = sideBar.find(
        (item: any) => sidebarSortData[0] == get(item, "page", null)
      )?.id;
      if (frstAllowedUrl) {
        navigate(frstAllowedUrl, { replace: true });
      } else {
        navigate("/login", { replace: true });
      }
    }
  }, [location, navigate]);
  // sort info
  const sortedData = sidebarSortData?.map((order: any) =>
    sideBar.find((item: any) => get(item, "page", null) == order)
  );
  //  drop down documents state
  const [droppableList, updateDroppableList] = useState<any>(sortedData);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(droppableList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateDroppableList(items);
  };

  const onChangeDetails = useCallback(() => {
    if (droppableList) {
      const array = droppableList.map((item: any) => item.page);
      if (array) {
        dispatch(updateDocumentList(array));
        UsersServices.ReorderData(array).catch((err) => console.log(err));
      }
    }
  }, [droppableList]);
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === "b") {
      setCollapsed((collapsed) => !collapsed);
    }
  };
  const handleFetchSelectorsOptions = useCallback(() => {
    // usloviyaOplatas
    dispatch(fetchTermsPayment({}));
    // sposobOplatas
    dispatch(fetchPaymentMethod({}));
    // tipDogovora
    dispatch(fetchTypeContract({}));
    // sposobDostavka
    dispatch(fetchDeliveryMethod({}));
    // edizimer
    dispatch(fetchUnitsMeasurement({}));
    // territory
    dispatch(fetchOwnTerritories({ all: true }));
    // sklad
    dispatch(fetchOwnWarHouse({ tip: 1 }));
    dispatch(fetchOwnWarHouse({ tip: 0 }));
    // Ставка НДС
    dispatch(fetchRateVAT({ all: true }));
    // tovar id
    dispatch(fetchProduct({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    navigateToFirstSidebar();
  }, [navigateToFirstSidebar]);

  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, [dispatch]);
  useEffect(() => {
    onChangeDetails();
  }, [onChangeDetails]);

  useEffect(() => {
    handleFetchSelectorsOptions();
  }, [handleFetchSelectorsOptions]);
  return (
    <Fragment>
      <Layout
        style={{
          padding: 0,
          fontFamily: "Arial, Helvetica, sans-serif",
          zIndex: 100,
          left: 0,
          width: "100%",
          position: "fixed",
        }}
      >
        <Row align="center">
          {!collapsed && (
            <Col xs={2.6} style={{ textAlign: "end", paddingRight: 0 }}>
              <button
                title="Ctrl + B"
                style={{ border: "none", backgroundColor: " #F7F7F7" }}
                onClick={() => setCollapsed(!collapsed)}
              >
                <ReactSVG
                  src={closeMenu}
                  className="mt-8 svg_icon ml-8 cursor-pointer"
                />
              </button>
            </Col>
          )}
          <Col xs={9.4}>
            <FlexBox>
              {collapsed && (
                <button
                  title="Ctrl + B"
                  style={{ border: "none", backgroundColor: " #F7F7F7" }}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <ReactSVG src={menuuIcon} className="svg_icon mr-16 ml-16" />
                </button>
              )}
              <Breadcrumb
                style={{ margin: "12px 0" }}
                permission={permissionList}
              />
            </FlexBox>
          </Col>
        </Row>
      </Layout>
      <Layout className="mt-50">
        <Sider
          collapsible
          style={{
            background: "inherit",
            marginBottom: "10px",
          }}
          className="sidebar"
          trigger={null}
          width={"43vh"}
          collapsedWidth={0}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Sidebar
            className="component_animate"
            style={collapsed ? { display: "none" } : { display: "flex" }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list-container">
                {(provided: any) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={"list-container"}
                  >
                    {droppableList
                      .filter((item: any) => {
                        // if (permissionList.includes(item.page)) {
                        return item;
                        // }
                      })
                      .map((item: any, index: number) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <SidebarItemWrapper
                                className={"mb-8"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SidebarItem
                                  key={index + 1}
                                  title={get(item, "name", "")}
                                  url={get(item, "id", "")}
                                  icon={get(item, "icon", "")}
                                  stroke={get(item, "stroke", false)}
                                />
                              </SidebarItemWrapper>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Sidebar>
        </Sider>
        <Content
          style={{
            padding: "0",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Fragment>
  );
};

export default DocumentsInnerLayout;
