import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchIncomes } from "app/slices/enumerationsSlices/incomeSlices/incomeSlices";
import { IncomeDataType } from "app/slices/enumerationsSlices/incomeSlices/type";
import {
  incomeData,
  incomeFilter,
  typeWriteSubstandard,
  typeWriteSubstandardFilter,
} from "app/states/enumerations";
import {
  BaseInput,
  BaseTable,
  Button,
  Content,
  Form,
  LoadingTable,
  Nodata,
  Title,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, isEqual, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import EnumerationsServices from "services/apiServices/perechislenya";
import icon from "assets/images/icons/action_icon.svg";
import { fetchTypeWriteSubstandard } from "app/slices/enumerationsSlices/typeWriteSubstandard/typeWriteSubstandard";
import { SubstandardDataType } from "app/slices/enumerationsSlices/typeWriteSubstandard/type";
import { indexNumber } from "utilities/helpers";

//  Тип прихода
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const onSubmit = () => {
    try {
      EnumerationsServices.AddNewIncome({
        data: values,
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        if (res && res.data) {
          handleCloseModal();
          afterSubmitAction();
        }
      });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      tipPrixoda: "",
    },
    onSubmit,
  });
  return (
    <>
      <Col xs={12} className="mt-16 text-left mb-24">
        <Title sm bold>
          Тип прихода
        </Title>
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <BaseInput
            width={"100%"}
            clear={get(values, "tipPrixoda", "")}
            handleInput={(e: Event) => {
              setFieldValue("tipPrixoda", e);
            }}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              Добавить
            </Button>
            <Button onClick={handleCloseModal} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const TypeSubstandardContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(typeWriteSubstandard);
  const filter = useAppSelector(typeWriteSubstandardFilter);
  let LOADING = get(data, "loading", false);
  const handleFilter = useCallback(() => {
    dispatch(fetchTypeWriteSubstandard(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);

  const fetchAllIncomesData = useCallback(() => {
    dispatch(fetchTypeWriteSubstandard(filter));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  return (
    <Row style={{ minHeight: "100%" }}>
      <Col xs={12}>
        <Content className={"full_height"}>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              afterSubmitAction={() => fetchAllIncomesData()}
              handleCloseModal={handleCloseModal}
            />
          </ReactModal>
          <Row>
            <Col>
              <BaseTable
                dark
                lg
                tableHeader={["№", "Пользователь", "Тип прихода"]}
              >
                {isEqual(LOADING, false) ? (
                  <>
                    {!isEmpty(get(data, "data", [])) ? (
                      get(data, "data", []).map(
                        (item: SubstandardDataType, index: number) => (
                          <tr key={get(item, "id", 0)}>
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  data,
                                  "pagination.currentPageNumber",
                                  1
                                ),
                                pageSize: get(data, "pagination.pageSize", 10),
                                index: index + 1,
                              })}
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "naimenovaniya", "-")}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <Nodata colLength={3} />
                    )}
                  </>
                ) : (
                  map([...new Array(5)], (_, index: number) => (
                    <tr key={index + 1}>
                      <LoadingTable
                        width={index % 2 == 0 ? "50px" : "70px"}
                        tblDTNumber={3}
                      />
                    </tr>
                  ))
                )}
              </BaseTable>
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default TypeSubstandardContainer;
