import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchAllCalculateProduct,
  fetchCalculateProductStat,
  filterChangeCalculateProd,
} from "app/slices/documentSlices/calculateProductSlices/calculateProductSlices";
import { fetchOwnSkladGP } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  calculateProductList,
  calculateProductFilter,
  calculateProductStat,
} from "app/states/documents";
import { getSkladGP } from "app/states/handbooks/selectStates";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
// Расчет себестоимости готовой продукции
const CalculateFinishedProductsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_CALCULATE_PRODUCT
  );
  const data = useAppSelector(calculateProductList);
  const filter = useAppSelector(calculateProductFilter);
  const statCount = useAppSelector(calculateProductStat);
  let LOADING_TABLE = get(data, "loading", []);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  const skladOptions = useAppSelector(getSkladGP);
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  // other fetches
  const fetchCalculateProdFunction = useCallback(() => {
    dispatch(fetchAllCalculateProduct(filter));
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(filterChangeCalculateProd({ name: "skladGPId", value: null }));
    dispatch(filterChangeCalculateProd({ name: "fromDate", value: null }));
    dispatch(filterChangeCalculateProd({ name: "toDate", value: null }));
    dispatch(
      fetchAllCalculateProduct({
        ...filter,
        skladGPId: null,
        fromDate: null,
        toDate: null,
      })
    );
  }, [dispatch, filter]);

  const navigateToForm = (parmas: any) => {
    navigate(Documents.ADD_CALCULATE_PRODUCT, {
      state: { id: get(parmas, "id", null) },
    });
  };

  const handleDeleteDocument = (id: string | null) => {
    DocumentServices.DeleteCalculateProductByID(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchCalculateProdFunction();
        dispatch(fetchCalculateProductStat());
      }
    });
  };

  // status undo
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsCalculateProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchCalculateProdFunction();
        dispatch(fetchCalculateProductStat());
      }
    });
  };
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsCalculateProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchCalculateProdFunction();
      }
    });
  };
  useLayoutEffect(() => {
    dispatch(fetchOwnSkladGP({}));
    dispatch(fetchCalculateProductStat());
    return () => {
      handleClearFilter();
    };
  }, []);

  useEffect(() => {
    fetchCalculateProdFunction();
  }, [filter.size, filter.page]);

  const handleAddButton = () => {
    navigate(Documents.ADD_CALCULATE_PRODUCT);
  };
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={statCount} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button className="mr-16" danger handleClick={handleClearFilter}>
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "SEBEST_CREATE",
                    page: "SEBEST",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  options={skladOptions}
                  placeholder="Склад"
                  width="200px"
                  value={get(filter, "skladGPId", "")}
                  handleChange={(value: any) => {
                    dispatch(
                      filterChangeCalculateProd({
                        name: "skladGPId",
                        value,
                      })
                    );
                  }}
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeCalculateProd({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeCalculateProd({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className={"text-right"}>
              <Button primary handleClick={fetchCalculateProdFunction}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateToForm}
                  deleteDocument={handleDeleteDocument}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  deleteItem={true}
                  userPermission={"SEBEST"}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Территория",
                    "Склад готовой продукции",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    !isEmpty(get(data, "data", [])) ? (
                      get(data, "data", []).map((item: any, index: number) => (
                        <tr
                          key={get(item, "id", "")}
                          onClick={() =>
                            navigateToForm({
                              id: get(item, "id", ""),
                              isTemp: false,
                            })
                          }
                          className="cursor-pointer"
                          onContextMenu={(e) =>
                            displayMenu({
                              e,
                              id: get(item, "id", ""),
                              status: get(item, "status", 0),
                            })
                          }
                        >
                          <td>
                            {indexNumber({
                              currentPage: get(
                                data,
                                "pagination.currentPageNumber",
                                0
                              ),
                              pageSize: get(data, "pagination.pageSize", 10),
                              index: index + 1,
                            })}
                          </td>

                          <td>{get(item, "createdBy", "-")}</td>
                          <td>{get(item, "nomerDoc", "-")}</td>
                          <td>
                            {moment(get(item, "dataDoc", "")).format(
                              "DD.MM.YYYY"
                            )}
                          </td>
                          <td>{get(item, "imyaTerritoriya", "-")}</td>

                          <td>{get(item, "imyaSkladGP", "-")}</td>
                          <td>
                            {moment(get(item, "createdAt", "")).format(
                              "DD.MM.YYYY"
                            )}
                          </td>
                          <td>{get(item, "status", "-")}</td>
                        </tr>
                      ))
                    ) : (
                      <Nodata colLength={10} />
                    )
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={8}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(data, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(data, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      filterChangeCalculateProd({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(data, "pagination.currentPageNumber", 0)}
                  pageCount={get(data, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      filterChangeCalculateProd({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default CalculateFinishedProductsContainer;
