import { Col, Row } from "react-grid-system";
import { TableWrapper } from "./styles/style";
import { get, isEmpty } from "lodash";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { formatValueReport } from "utilities/helpers";

const OperationalReportsTable = ({
  data,
  toDate,
  click = false,
  filter,
}: any) => {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper>
              <thead>
                <tr>
                  <th rowSpan={2}>№</th>
                  <th rowSpan={2} style={{ width: "30vh" }}>
                    Материал
                  </th>
                  <th rowSpan={2}>Единица измерения</th>
                  {click && get(filter, "tipMaterial", null) == "PF" ? (
                    <>
                      <th rowSpan={2}>Заказчик</th>
                      <th rowSpan={2}>Заказ-наряд</th>
                      <th rowSpan={2}>Договор</th>
                    </>
                  ) : (
                    ""
                  )}
                  <th colSpan={2}>
                    Остаток на{" "}
                    {!isEmpty(toDate)
                      ? moment(toDate).format("DD.MM.YYYY")
                      : "Дата"}
                  </th>
                </tr>
                <tr>
                  <th>Количество</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(get(data, "operationalList.list", [])) ? (
                  get(data, "operationalList.list", []).map(
                    (item: any, index: number) => (
                      <>
                        {" "}
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaMaterial", "")}</td>
                          <td>{get(item, "imyaEdIzm", "")}</td>
                          {click && get(filter, "tipMaterial", null) == "PF" ? (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                            </>
                          ) : null}
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "ostatok.kolichestva", 0)}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={formatValueReport(
                                get(item, "ostatok.summa", 0)
                              )}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                        </tr>
                        {click == true && get(item, "details", [])
                          ? get(item, "details", []).map(
                              (details: any, index: number) => (
                                <tr
                                  key={index + 1}
                                  style={{ backgroundColor: "#f4f4f4" }}
                                >
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{get(details, "kontragentImya", "")}</td>
                                  <td>
                                    {get(details, "nomerZakazNaryad", "")}
                                  </td>
                                  <td>{get(details, "nomerDogProdaja", "")}</td>
                                  <td>
                                    <NumericFormat
                                      displayType="text"
                                      value={get(details, "kolichestva", 0)}
                                      thousandSeparator
                                      decimalScale={2}
                                    />
                                  </td>
                                  <td>
                                    <NumericFormat
                                      displayType="text"
                                      value={formatValueReport(
                                        get(details, "summa", 0)
                                      )}
                                      thousandSeparator
                                      decimalScale={2}
                                    />
                                  </td>
                                </tr>
                              )
                            )
                          : ""}
                      </>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={5}>Виберите параметры</td>
                  </tr>
                )}
                <tr className="black_td">
                  <td colSpan={3} style={{ textAlign: "start" }}>
                    Итого:{" "}
                  </td>
                  {click && (
                    <>
                      <td></td>
                      <td></td>
                      <td></td>
                    </>
                  )}
                  <td>
                    <NumericFormat
                      displayType="text"
                      value={get(data, "operationalList.itogo.OstatokKol", 0)}
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td>
                  <td>
                    <NumericFormat
                      displayType="text"
                      value={get(data, "operationalList.itogo.OstatokSum", 0)}
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td>
                </tr>
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OperationalReportsTable;
