import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import rootPersistConfig from "app/persistConfig";
import {
  authSlices,
  chartHomeSlices,
  enumerationSlices,
  handbookSlices,
  menuSlices,
  profileSlices,
  registerSalesCntSlice,
  registerSlices,
  reportSlices,
  userSlices,
} from "app/slices";
import contextMenuSlice from "./slices/menuSlices/contextMenuSlice";

const reducers = combineReducers({
  auth: authSlices,
  menu: menuSlices,
  contextMenu: contextMenuSlice,
  chart: chartHomeSlices,
  handbooks: handbookSlices,
  enumeration: enumerationSlices,
  documents: registerSalesCntSlice,
  registers: registerSlices,
  reports: reportSlices,
  users: userSlices,
  profile: profileSlices,
});

const persistedReducers = persistReducer(rootPersistConfig, reducers);

export default persistedReducers;
