import React, { memo } from "react";
import { Col, Row } from "react-grid-system";
import { FormInputWrapper } from "./style";
import Select from "components/BaseSelect/BaseSelect";
import Title from "components/Title/Title";
import { PropsFormSelectType } from "./type";
const FormSelect = ({
  options = [],
  value = "",
  isSearchable = false,
  onBlur,

  isMulti = false,
  isDisabled = false,
  placeholder = "",
  isLoading = false,
  isClearable = false,
  handleChange = (val: any) => val,
  autoFocus = false,
  sm,
  required = false,
  isCustomMenu,
  menuButtonTitle,
  handleAddNewOption = () => {},
  handleValueLabel,
  menuPlacement = "bottom",
  readOnly,
  label,
  left = 4,
  right = 8,
  name,
  error,
  defaultValue,
  ...props
}: PropsFormSelectType) => {
  return (
    <FormInputWrapper {...props}>
      <Row align={"center"} justify={"end"}>
        <Col xs={4}>
          <Title>
            {label}
            {required ? <span className="required_point">*</span> : ""}
          </Title>
        </Col>
        <Col xs={8}>
          <Select
            name={name}
            autoFocus={autoFocus}
            key={`unique_key__${value}`}
            options={options}
            handleChange={handleChange}
            value={value}
            isSearchable={isSearchable}
            placeholder={placeholder}
            onBlur={onBlur}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isLoading={isLoading}
            handleValueLabel={handleValueLabel}
            isClearable={isClearable}
            defaultValue={defaultValue}
            placement={menuPlacement}
          />
        </Col>
        <Col xs={6}>
          <span className="required">{error && "необходимый"}</span>
        </Col>
      </Row>
    </FormInputWrapper>
  );
};

export default memo(FormSelect);
