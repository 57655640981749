import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  ReactModal,
  ShowStatistics,
} from "components";
import HasAccess from "routers/HasAccess";
import { HANDBOOK_KEY, KASSA_KEY, MAIN_DEPARTMENT } from "routers/constants";
import { PROTECTED_TYPE, TABLE } from "utilities/constants";
import { useAppDispatch } from "app/hook";
import { get, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import moment from "moment";
import useConnect from "../connacts/connacts";
import TipPrixodaTable from "../components/connactsPrixod/tipPrixodaTable";
import TipRasxodaTable from "../components/connactRasxod/tipRasxodaTable";
import { handleFilterChangeRko } from "app/slices/documentSlices/costsInCashSlice/costsInCashSlice";
import { handleFilterARrived } from "app/slices/documentSlices/arrivedCashesSlice/arrivedCashesSlice";
import { AddNewCounterpartModal } from "modules/statics/spravochnik/containers/CounterpartsContainer";
import { fetchOptionsCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { useState } from "react";
import { useContextMenu } from "react-contexify";
const optionsTip = [
  {
    label: "Приход",
    value: 1,
  },
  {
    label: "Расход",
    value: 2,
  },
];

function KassaContainer() {
  const dispatch = useAppDispatch();
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  const { show } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  const {
    setChangeTipKassa,
    changeTipKassa,
    isAdding,

    handleFilterPrixod,
    handleFilterRasxod,

    handleAdd,
    setIsAdding,
    clearFilterPrixod,
    clearFilterRasxod,

    filterPrixod,
    filterRasxod,
    incomeOption,
    tipRasxoda,
    statPrixod,
    statRasxod,
    contractorDropdowns,
    setOpenCounterpart,
    openCounterpart,
    drivers,
    loadingKontragent,
    isLoadingDrivers,
    userName,
  } = useConnect();
  return (
    <Row>
      <Col xs={12}>
        <ReactModal isOpen={openCounterpart}>
          <AddNewCounterpartModal
            handleCloseModal={() => setOpenCounterpart(false)}
            afterSubmitAction={() =>
              dispatch(fetchOptionsCounterpart({ all: true }))
            }
          />
        </ReactModal>
        <Content className={"min_height"}>
          <Row align="center" className="mb-16">
            <Col xs={10}>
              {isEqual(changeTipKassa, 2) ? (
                <ShowStatistics statistic={statRasxod} />
              ) : (
                <ShowStatistics statistic={statPrixod} />
              )}{" "}
            </Col>
            <Col xs={2} style={{ textAlign: "end" }}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${KASSA_KEY.KASSA_DOC}_CREATE`,
                    page: KASSA_KEY.KASSA_DOC,
                    department: MAIN_DEPARTMENT.KASSA,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={() => handleAdd()}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={9}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  placeholder={"Вид"}
                  value={changeTipKassa}
                  options={optionsTip}
                  handleChange={(e: any) => {
                    setChangeTipKassa(e);
                  }}
                />
                {isEqual(changeTipKassa, 2) ? (
                  <>
                    <HasAccess>
                      {({ userCan }: any) =>
                        userCan({
                          permission: `${HANDBOOK_KEY.KONTRAGENT}_CREATE`,
                          page: HANDBOOK_KEY.KONTRAGENT,
                          department: MAIN_DEPARTMENT.HANDBOOK,
                          type: PROTECTED_TYPE.PERMISSIONS,
                        }) ? (
                          <BaseSelect
                            isClearable
                            placeholder={"Kонтрагент"}
                            value={get(filterRasxod, "kontragentId", "")}
                            options={contractorDropdowns}
                            isLoading={loadingKontragent}
                            isCustomMenu={true}
                            menuButtonTitle={"+ Добавить"}
                            handleAddNewOption={() => setOpenCounterpart(true)}
                            handleChange={(e: any) => {
                              dispatch(
                                handleFilterChangeRko({
                                  name: "kontragentId",
                                  value: e,
                                })
                              );
                            }}
                          />
                        ) : (
                          <BaseSelect
                            isClearable
                            isLoading={loadingKontragent}
                            placeholder={"Kонтрагент"}
                            value={get(filterRasxod, "kontragentId", "")}
                            options={contractorDropdowns}
                            handleChange={(e: any) => {
                              dispatch(
                                handleFilterChangeRko({
                                  name: "kontragentId",
                                  value: e,
                                })
                              );
                            }}
                          />
                        )
                      }
                    </HasAccess>
                    <BaseSelect
                      isClearable
                      options={drivers}
                      placeholder="Водитель"
                      isLoading={isLoadingDrivers}
                      handleChange={(e: any) => {
                        dispatch(
                          handleFilterChangeRko({
                            name: "voditeliId",
                            value: e,
                          })
                        );
                      }}
                      value={get(filterRasxod, "voditeliId", null)}
                    />
                    <BaseSelect
                      isClearable
                      options={tipRasxoda}
                      placeholder="Тип расхода"
                      handleChange={(e: any) => {
                        dispatch(
                          handleFilterChangeRko({
                            name: "tipRasxodaId",
                            value: e,
                          })
                        );
                      }}
                      value={get(filterRasxod, "tipRasxodaId", null)}
                    />
                    <DatePicker
                      isClearable
                      placeholder="Дата начало"
                      handleDate={(e: any) => {
                        dispatch(
                          handleFilterChangeRko({
                            name: "fromDate",
                            value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                          })
                        );
                      }}
                      value={get(filterRasxod, "fromDate", "")}
                      dateFormat="dd.MM.yyyy"
                    />
                    <DatePicker
                      isClearable
                      placeholder="Дата конец"
                      handleDate={(e: any) => {
                        dispatch(
                          handleFilterChangeRko({
                            name: "toDate",
                            value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                          })
                        );
                      }}
                      value={get(filterRasxod, "toDate", "")}
                      dateFormat="dd.MM.yyyy"
                    />
                  </>
                ) : (
                  <>
                    <HasAccess>
                      {({ userCan }: any) =>
                        userCan({
                          permission: `${HANDBOOK_KEY.KONTRAGENT}_CREATE`,
                          page: HANDBOOK_KEY.KONTRAGENT,
                          department: MAIN_DEPARTMENT.HANDBOOK,
                          type: PROTECTED_TYPE.PERMISSIONS,
                        }) ? (
                          <BaseSelect
                            isClearable
                            placeholder={"Kонтрагент"}
                            value={get(filterPrixod, "kontragentId", "")}
                            options={contractorDropdowns}
                            isLoading={loadingKontragent}
                            isCustomMenu={true}
                            menuButtonTitle={"+ Добавить"}
                            handleAddNewOption={() => setOpenCounterpart(true)}
                            handleChange={(e: any) => {
                              dispatch(
                                handleFilterARrived({
                                  name: "kontragentId",
                                  value: e,
                                })
                              );
                            }}
                          />
                        ) : (
                          <BaseSelect
                            isClearable
                            placeholder={"Kонтрагент"}
                            value={get(filterPrixod, "kontragentId", "")}
                            options={contractorDropdowns}
                            isLoading={loadingKontragent}
                            handleChange={(e: any) => {
                              dispatch(
                                handleFilterARrived({
                                  name: "kontragentId",
                                  value: e,
                                })
                              );
                            }}
                          />
                        )
                      }
                    </HasAccess>

                    <BaseSelect
                      isClearable
                      options={incomeOption}
                      placeholder={"Тип прихода"}
                      handleChange={(e: any) => {
                        dispatch(
                          handleFilterARrived({
                            name: "tipPrixodaId",
                            value: e,
                          })
                        );
                      }}
                      value={get(filterPrixod, "tipPrixodaId", null)}
                    />
                    <DatePicker
                      isClearable
                      placeholder="Дата начало"
                      handleDate={(e: any) => {
                        dispatch(
                          handleFilterARrived({
                            name: "fromDate",
                            value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                          })
                        );
                      }}
                      value={get(filterPrixod, "fromDate", "")}
                      dateFormat="dd.MM.yyyy"
                    />

                    <DatePicker
                      isClearable
                      placeholder="Дата конец"
                      handleDate={(e: any) => {
                        dispatch(
                          handleFilterARrived({
                            name: "toDate",
                            value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                          })
                        );
                      }}
                      value={get(filterPrixod, "toDate", "")}
                      dateFormat="dd.MM.yyyy"
                    />
                  </>
                )}
              </FlexBox>
            </Col>
            {isEqual(changeTipKassa, 2) ? (
              // rasxod
              <Col className={"text-right"} xs={3}>
                <Button
                  primary
                  className={"mr-16"}
                  handleClick={() => handleFilterRasxod()}
                >
                  {TABLE.APPLY}
                </Button>

                <Button danger handleClick={() => clearFilterRasxod()}>
                  {TABLE.RESET}
                </Button>
              </Col>
            ) : (
              // prixod
              <Col className={"text-right"} xs={3}>
                <Button
                  primary
                  className={"mr-16"}
                  handleClick={() => handleFilterPrixod()}
                >
                  {TABLE.APPLY}
                </Button>

                <Button danger handleClick={() => clearFilterPrixod()}>
                  {TABLE.RESET}
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="responsive_overflow">
              {isEqual(changeTipKassa, 2) ? (
                <TipRasxodaTable
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  documentInfo={documentInfo}
                  displayMenu={displayMenu}
                  userName={userName}
                />
              ) : (
                <TipPrixodaTable
                  handleFilterARrived={handleFilterARrived}
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  handleAdd={handleAdd}
                  documentInfo={documentInfo}
                  displayMenu={displayMenu}
                  userName={userName}
                />
              )}
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
}

export default KassaContainer;
