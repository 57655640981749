import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import ReportsServices from "services/apiServices/reports";
import moment from "moment";

const initialState: InitialState = {
  data: { loading: false, dataList: null, error: "" },
  filter: {

    tipMaterial: null,
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    skladId: null,
  },
};

export const fetchMaterialReport = createAsyncThunk(
  "materialReport/fetchMaterialReport",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchMaterialReportTable(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const materialReportSlices = createSlice({
  name: "materialReport",
  initialState,
  reducers: {
    filterMaterialTable: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = {
        ...state.filter,
        [name]: value,
      };
    },
    clearMaterialTable: () => initialState,
    changeMaterialTable: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaterialReport.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchMaterialReport.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchMaterialReport.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const { filterMaterialTable, clearMaterialTable, changeMaterialTable } =
  materialReportSlices.actions;
export default materialReportSlices.reducer;
