import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import axios from "axios";
const CONSTANT = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};
const initialState: InitialState = {
  saleContractStat: {
    loading: false,
    data: {},
    error: "",
  },
  detailsProduct: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      dogProdajaDetails: [],
    },
  },
  filter: {
    search: "",
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  salesCnts: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchSalesContracts = createAsyncThunk(
  "registerSalesCnt/fetchSalesContracts",
  async (params: any, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source()
      signal.addEventListener('abort', () => {
        soure.cancel()
      })
      const request = await DocumentServices.FetchSalesContracts({ ...params, signal: soure.token });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const fetchNotFilledSalesContracts = createAsyncThunk(
  "registerSalesCnt/fetchNotFilledSalesContracts",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSalesNotFilledContracts();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSalesContractTempById = createAsyncThunk(
  "registerSalesCnt/fetchSalesContractTempById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSalesContractByTempId(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSalesContractById = createAsyncThunk(
  "registerSalesCnt/fetchSalesContractById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSalesContractById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchDetailsProductById = createAsyncThunk(
  "registerSalesCnt/fetchDetailsProductById",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchDetailsById(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSaleContractsStat = createAsyncThunk(
  "registerSalesCnt/fetchSaleContractsStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchSaleContractsStatistics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const registerSalesCntSlice = createSlice({
  name: "registerSalesCnt",
  initialState,
  reducers: {
    movementSalesCntSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === CONSTANT.ADD) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            dogProdajaDetails: !isEmpty(get(action, "payload.data", [])) // when payload is empty, array will be cleared
              ? [...state.formData.data.dogProdajaDetails, action.payload.data]
              : [],
          },
        };
      } else if (action.payload.type === CONSTANT.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            dogProdajaDetails: state.formData.data.dogProdajaDetails.map(
              (item: any, index: number) =>
                item.tovarId == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === CONSTANT.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            dogProdajaDetails: state.formData.data.dogProdajaDetails.filter(
              (item: any, index: any) => item.tovarId !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            dogProdajaDetails: [],
          },
        };
      }
    },
    handleFilterChange: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },

    changeVATPriceForSales: (state, action) => {
      state.formData = {
        ...state.formData,
        data: {
          ...state.formData.data,
          dogProdajaDetails: state.formData.data.dogProdajaDetails.map(
            (item: any, index: number) =>
              index === action.payload.id
                ? {
                  ...item,
                  summaNDS: get(action, "payload.value", 0),
                  nds: get(action, "payload.nds", 0),
                  vsego: get(action, "payload.vsego", 0),
                }
                : item
          ),
        },
      };
    },
    handleClearFormData: (state) => {
      state.formData = initialState.formData;
    },
    handleClearDetailsFilter: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalesContracts.pending, (state) => {
      state.salesCnts = {
        loading: true,
      };
    });
    builder.addCase(fetchSalesContracts.fulfilled, (state, actions) => {
      state.salesCnts = {
        loading: false,
        data: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchSalesContracts.rejected, (state, actions) => {
      state.salesCnts = {
        loading: false,
        data: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    // fetching not full filled contracts
    builder.addCase(fetchNotFilledSalesContracts.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(
      fetchNotFilledSalesContracts.fulfilled,
      (state, actions) => {
        state.notFilledCntrList = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
        };
      }
    );
    builder.addCase(fetchNotFilledSalesContracts.rejected, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });

    // fetching not full filled contract by id
    builder.addCase(fetchSalesContractTempById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchSalesContractTempById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchSalesContractTempById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    //  fetch details
    builder.addCase(fetchSalesContractById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchSalesContractById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchSalesContractById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });
    // fetching details product by id
    builder.addCase(fetchDetailsProductById.pending, (state) => {
      state.detailsProduct = {
        loading: true,
      };
    });
    builder.addCase(fetchDetailsProductById.fulfilled, (state, actions) => {
      state.detailsProduct = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchDetailsProductById.rejected, (state, actions) => {
      state.detailsProduct = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });

    // fetching sale contracts statists
    builder.addCase(fetchSaleContractsStat.pending, (state) => {
      state.saleContractStat = {
        loading: true,
      };
    });
    builder.addCase(fetchSaleContractsStat.fulfilled, (state, actions) => {
      state.saleContractStat = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchSaleContractsStat.rejected, (state, actions) => {
      state.saleContractStat = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleFilterChange,
  handleClearFormData,
  movementSalesCntSubject,
  changeVATPriceForSales,
  handleClearDetailsFilter,
} = registerSalesCntSlice.actions;
export default registerSalesCntSlice.reducer;
