import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchNotFilledreleaseProduct,
  fetchReleaseProduct,
  fetchReleaseProductStat,
  handleClearAllMaterials,
  handleClearFormDataMaterials,
  handleFilterChangeRelease,
} from "app/slices/documentSlices/releaseProductSlice/releaseProductSlices";
import {
  releaseProductFilter,
  releaseProductList,
  releaseProductStat,
  saleTempsReleaseProductList,
} from "app/states/documents";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Documents } from "routers/paths";
import delate from "assets/images/icons/delate.svg";
import DocumentServices from "services/apiServices/documents";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import {
  getProductData,
  getTypeProductData,
} from "app/states/handbooks/selectStates";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import HasAccess from "routers/HasAccess";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";
import { clearRegisteredOrders } from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
// Выпуск готовой продукции
const ReleaseProductsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_RELEASE_PRODUCT
  );
  const dispatch = useAppDispatch();
  const releaseProducts = useAppSelector(releaseProductList);
  const filter = useAppSelector(releaseProductFilter);
  const releaseTempsContract = useAppSelector(saleTempsReleaseProductList);
  const releaseStat = useAppSelector(releaseProductStat);
  const LOADING_TABLE = get(releaseProducts, "loading", []);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const tovariiOptions = useAppSelector(getTypeProductData);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  let loadingKontragent = useAppSelector(loadingConterpart);
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchAllReleaseProducts = useCallback(() => {
    dispatch(fetchReleaseProduct(filter));
    dispatch(fetchReleaseProductStat());
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchReleaseProduct({
        ...filter,
        fromDate: null,
        toDate: null,
        tovarId: null,
        kontragentId: null,
      })
    );
    dispatch(handleClearAllMaterials());
  }, [dispatch, filter]);

  const fetchSalesNotFilledContracts = useCallback(() => {
    dispatch(fetchNotFilledreleaseProduct());
    dispatch(fetchReleaseProductStat());
    dispatch(fetchTypeProduct({ all: true }));
    dispatch(fetchAllCounterpart({ all: true }));
  }, [dispatch]);

  const navigateSaleContractForm = (params: {
    id: string | number;
    isTemp: boolean;
  }) => {
    navigate(Documents.ADD_RELEASE_PRODUCT, {
      state: params,
    });
  };

  const handleAddButton = () => {
    dispatch(handleClearFormDataMaterials());
    dispatch(clearContractorsState());
    dispatch(clearRegisteredOrders());
    navigate(Documents.ADD_RELEASE_PRODUCT);
  };

  const handleTempButton = (id: string) => {
    DocumentServices.RemoveTempsReleaseProduct(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchSalesNotFilledContracts();
      }
    });
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteReleaseProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllReleaseProducts();
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsReleaseProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllReleaseProducts();
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsReleaseProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllReleaseProducts();
      }
    });
  };

  useEffect(() => {
    fetchAllReleaseProducts();
  }, [filter.page, filter.size]);

  useEffect(() => {
    fetchSalesNotFilledContracts();
    return () => {
      dispatch(handleClearAllMaterials());
    };
  }, []);

  // for adding  new form
  if (isAddForm) {
    return <Outlet />;
  }
  // main content
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={releaseStat} />
            </Col>
            <Col xs={6} className={"text-right"}>
              <Button
                className={"mr-16"}
                danger
                handleClick={handleClearFilter}
              >
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "VIPUSK_GP_CREATE",
                    page: "VIPUSK_GP",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  isLoading={loadingKontragent}
                  placeholder={"Контрагент"}
                  width="250px"
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeRelease({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  isClearable
                  options={tovariiOptions}
                  placeholder="Вид товар"
                  width={"200px"}
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeRelease({
                        name: "vidTovaraId",
                        value: e,
                      })
                    );
                  }}
                  value={get(filter, "vidTovaraId", null)}
                />
                <DatePicker
                  isClearable
                  width={"200px"}
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeRelease({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  isClearable
                  width={"200px"}
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeRelease({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className="text-right">
              <Button primary handleClick={fetchAllReleaseProducts}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateSaleContractForm}
                  deleteDocument={handleDeleteButton}
                  deleteItem={true}
                  markItem={true}
                  uncheckItem={true}
                  userPermission={"VIPUSK_GP"}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Дата",
                    "Контрагент",
                    "Тип готовой продукции",
                    "Товар",
                    "Единица измерения",
                    "Количество",
                    "Себестоимость товара",
                    "Пользователь",
                    "Номер",
                    "Территория",
                    "Склад товара",
                    "Заказ-наряд",
                    "Договор",
                    "Склад для списания материалов",
                    "Тип списания",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      {!isEmpty(get(releaseTempsContract, "data", [])) &&
                        get(releaseTempsContract, "data", []).map(
                          (item: any) => (
                            <tr
                              key={get(item, "id", 0)}
                              onClick={() =>
                                navigateSaleContractForm({
                                  isTemp: true,
                                  id: get(item, "id", ""),
                                })
                              }
                              className="cursor-pointer"
                            >
                              <td>
                                <div
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    handleTempButton(get(item, "id", null));
                                  }}
                                  className="delete_button"
                                >
                                  <ReactSVG
                                    src={delate}
                                    className="delete_button"
                                  />
                                </div>
                              </td>
                              <td>
                                {!isEmpty(get(item, "dataDoc", ""))
                                  ? moment(get(item, "dataDoc", "")).format(
                                      "DD.MM.YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td>{get(item, "imyaKontragent", "-")}</td>
                              <td>{get(item, "imyaVidTovara", "")}</td>
                              <td>{get(item, "imyaTovar", "")}</td>
                              <td>{get(item, "imyaEdIzm", "")}</td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "kolTovara", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "sebestTovar", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDoc", "")}</td>
                              <td>{get(item, "imyaTerritorii", "")}</td>
                              <td>{get(item, "imyaSkladGP", "")}</td>
                              <td>{get(item, "nomerZakazNaryad", "")}</td>
                              <td>{get(item, "nomerDogProdaja", "")}</td>
                              <td>{get(item, "imyaSkladSpis", "")}</td>
                              <td>{get(item, "imyaTipSpisaniya", "")}</td>

                              <td>
                                {!isEmpty(get(item, "createdAt", ""))
                                  ? moment(get(item, "createdAt", "")).format(
                                      "DD.MM.YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td>{get(item, "status", "-")}</td>
                            </tr>
                          )
                        )}

                      {!isEmpty(get(releaseProducts, "productList", []))
                        ? get(releaseProducts, "productList", []).map(
                            (item, index: number) => (
                              <tr
                                key={index + 1}
                                onClick={() =>
                                  navigateSaleContractForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      releaseProducts,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      releaseProducts,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>
                                  {!isEmpty(get(item, "dataDoc", ""))
                                    ? moment(get(item, "dataDoc", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "imyaKontragent", "-")}</td>
                                <td>{get(item, "imyaVidTovara", "")}</td>
                                <td>{get(item, "imyaTovar", "")}</td>
                                <td>{get(item, "imyaEdIzm", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "kolTovara", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "sebestTovar", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>

                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>

                                <td>{get(item, "imyaTerritorii", "")}</td>
                                <td>{get(item, "imyaSkladGP", "")}</td>
                                <td>{get(item, "nomerZakazNaryad", "")}</td>
                                <td>{get(item, "nomerDogProdaja", "")}</td>
                                <td>{get(item, "imyaSkladSpis", "")}</td>
                                <td>{get(item, "imyaTipSpisaniya", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(releaseTempsContract, "data", [])) && (
                            <Nodata colLength={19} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={19}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(releaseProducts, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(releaseProducts, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChangeRelease({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    releaseProducts,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(releaseProducts, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChangeRelease({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default ReleaseProductsContainer;
