import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearRegisteredOrders,
  fetchOrderRegister,
} from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import {
  clearReleaseOptions,
  fetchNotFilledreleaseProduct,
  fetchProductsReleaseOptions,
  fetchReleaseProduct,
  fetchReleaseProductsById,
  fetchReleaseProductStat,
  fetchReleaseProductsTempById,
  handleAddSubjectsOnesRelease,
  handleAddSubjectsRelease,
  handleAddSubjectsReleasePoFackt,
  handleChangeItemSubjectsRelease,
  removeSubject,
} from "app/slices/documentSlices/releaseProductSlice/releaseProductSlices";
import { clearFormSemiData } from "app/slices/documentSlices/semiProductSlice/semiProductSlices";
import { fetchTypeWriteOff } from "app/slices/enumerationsSlices/typeWriteOffSlices/typeWriteOffSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import { releaseProductFilter, releaseProductForm } from "app/states/documents";
import {
  contractorOptions,
  getSaleContractsSubjectData,
  loadingConterpart,
  loadingOrderData,
  optionsReleseProduct,
  orderRegisterOptions,
  releseProductLoad,
  unitsMeasurementOptions,
} from "app/states/documents/selectStates";
import { getTypeWriteData } from "app/states/enumerations/selectStates";
import {
  getMaterialData,
  getSkladCM,
  getSkladGP,
  getTerritoriesData,
  getTypeProductData,
  loadingRawMaterial,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import remove_red from "assets/images/icons/remove_red.svg";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import TableInput from "components/TableInput/TableInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import {
  manualMaterialSchema,
  releaseProductSchema,
} from "utilities/schema/document/documentSchemas";
import { ProgressBar } from "primereact/progressbar";
import { useDebounce } from "use-debounce";

// Добавить  Выпуск готовой продукции
export const AddContractSubjects = ({
  handleOpenModal,
  handleCloseModal,
  skladId,
  zakazNaryadId,
  dataTable,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  skladId: number | null;
  zakazNaryadId: string | null;
  dataTable: Array<any>;
}) => {
  const getMaterialOptions = useAppSelector(getMaterialData);
  const loadingMaterial = useAppSelector(loadingRawMaterial);
  const dispatch = useAppDispatch();
  const onSubmit = (values: any) => {
    let res = {
      ...values,
      materialId: get(values, "materialId.value", ""),
      imyaMaterial: get(values, "materialId.label", null),
      imyaEdIzm: get(values, "materialId.imyaEdIzm", null),
    };
    dispatch(handleAddSubjectsOnesRelease(res));
    resetForm();
    handleCloseModal();
  };
  const { values, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues: {
      materialId: null,
      kol: "",
      imyaMaterial: "",
      imyaEdIzm: "",
      tsena: 0,
      summa: 0,
    },
    onSubmit,
    validationSchema: manualMaterialSchema,
  });

  const getPrice = useCallback(() => {
    if (get(values, "materialId", "") && values.kol) {
      try {
        DocumentServices.GetPriceMaterial({
          materialId: get(values, "materialId.value", null),
          skladId: skladId,
          kol: get(values, "kol", null),
          zakazNaryadId: zakazNaryadId,
        }).then((res) => {
          if (res.status == 200) {
            setFieldValue("tsena", get(res, "data.data.tsena", 0));
            setFieldValue("summa", get(res, "data.data.totalTsena", 0));
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [values.kol, zakazNaryadId, values.materialId]);

  useEffect(() => {
    getPrice();
  }, [getPrice]);
  useEffect(() => {
    dispatch(fetchRawMaterial({ all: true, schetMateriala: "SCHET_1010" }));
  }, [dispatch]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            Ввод данных
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            id={"materialId"}
            label={"Сырьё и материал"}
            isLoading={loadingMaterial}
            required={true}
            isSearchable
            value={get(values, "materialId.value", "")}
            options={removeValueOption({
              currentOption: getMaterialOptions,
              removeValueList: dataTable,
              valueName: "materialId",
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("materialId", e);
              setFieldValue("edIzmId", get(e, "edIzmId", null));
            }}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                value={get(values, "kol", "")}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                min={1}
                maxLength={40}
              />
            </Col>
          </Row>
          <FormInput
            label={"Цена"}
            value={get(values, "tsena", "")}
            disabled={true}
            required={true}
          />
          <FormInput
            label={"Сумма"}
            value={get(values, "summa", "")}
            thousandSeparator={true}
            fixedDecimalScale={true}
            disabled={true}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" green type="submit">
              {PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddReleaseProductContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const productType = useAppSelector(getTypeProductData);
  const territory = useAppSelector(getTerritoriesData);
  const skladOptions = useAppSelector(getSkladGP);
  const skladSMOptions = useAppSelector(getSkladCM);
  const orderRegister = useAppSelector(orderRegisterOptions);
  const getSaleContracts = useAppSelector(getSaleContractsSubjectData);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const spisanyaOptions = useAppSelector(getTypeWriteData);
  const { state } = useLocation();
  const releaseFormData = useAppSelector(releaseProductForm);
  const [loadingButton, setLoadingButton] = useState(false);
  const filter = useAppSelector(releaseProductFilter);
  const [disable, setDisable] = useState(true);

  const tovarOpitons = useAppSelector(optionsReleseProduct);
  // loadings
  let LOADING = get(releaseFormData, "loading", false);
  let loadingConter = useAppSelector(loadingConterpart);
  let loadingOrder = useAppSelector(loadingOrderData);
  let tovarLoad = useAppSelector(releseProductLoad);
  const [loadingDetails, setLoadingDetails] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  // tipSpisaniyaId
  const onSubmit = (values: any) => {
    const cntrcSubjects =
      get(values, "tipSpisaniy aId.value", null) === 1
        ? get(releaseFormData, "data.vipuskGPDetails", [])
        : get(releaseFormData, "data.detailsPoFaktu", []).map((item: any) => ({
            materialId: get(item, "materialId", ""),
            kol: get(item, "kol", ""),
          }));

    let valuesToSbm: any = {};
    forEach(
      values,
      (value: any, key: any) =>
        (valuesToSbm[key] = ["nomerDoc", "kolTovara", "kommentariya"].includes(
          key
        )
          ? value
          : get(value, "value", ""))
    );
    if (!isEmpty(cntrcSubjects)) {
      try {
        DocumentServices.AddReleaseProduct({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...valuesToSbm,
            dataDoc: !isEqual(get(values, "dataDoc", null), null)
              ? get(values, "dataDoc", "")
              : moment(new Date()).format("YYYY-MM-DD"),
            vipuskGPDetails: cntrcSubjects,
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchReleaseProduct(filter));
            dispatch(fetchReleaseProductStat());
            toast.success(MESSAGES.ADDED);
            if (get(state, "id", false)) {
              DocumentServices.RemoveTempsReleaseProduct(
                get(state, "id", "")
              ).then((res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchNotFilledreleaseProduct());
                }
              });
            }
          }
          setSubmitting(false);
        });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      setSubmitting(false);
    }
  };
  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: get(releaseFormData, "data.formIntVls", {}),
      onSubmit,
      validationSchema: releaseProductSchema,
    });
  const [debouncedText] = useDebounce(get(values, "kolTovara", ""), 500);
  // tipSpisaniyaId
  const handleNavigate = (params: {
    kontragentIdProps: string;
    zakazNaryadIdProps: string;
  }) => {
    setLoadingButton(true);
    const cntrcSubjects =
      get(values, "tipSpisaniy aId.value", null) === 1
        ? get(releaseFormData, "data.vipuskGPDetails", [])
        : get(releaseFormData, "data.detailsPoFaktu", []).map((item: any) => ({
            materialId: get(item, "materialId", ""),
            kol: get(item, "kol", ""),
          }));

    let valuesToSbm: any = {};

    forEach(
      values,
      (value: any, key: any) =>
        (valuesToSbm[key] = ["nomerDoc", "kolTovara", "kommentariya"].includes(
          key
        )
          ? value
          : get(value, "value", ""))
    );
    if (!isEmpty(cntrcSubjects)) {
      try {
        DocumentServices.AddReleaseProduct({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...valuesToSbm,
            dataDoc: !isEqual(get(values, "dataDoc", null), null)
              ? get(values, "dataDoc", "")
              : moment(new Date()).format("YYYY-MM-DD"),
            vipuskGPDetails: cntrcSubjects,
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            dispatch(fetchReleaseProduct(filter));
            toast.success(MESSAGES.ADDED);
            setLoadingButton(false);
            navigate("/");
            navigate("/documents/shipment-products/add-shipment-product", {
              replace: true,
              state: params,
            });
            if (get(state, "id", false)) {
              DocumentServices.RemoveTempsReleaseProduct(
                get(state, "id", "")
              ).then((res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchNotFilledreleaseProduct());
                }
              });
            }
          } else {
            setLoadingButton(false);
          }
          setLoadingButton(false);
          setSubmitting(false);
        });
      } catch (err) {
        console.log("err", err);
        return setLoadingButton(false);
      }
    } else {
      setLoadingButton(false);
    }
  };

  const saveNotFullFilledForm = useCallback(() => {
    const cntrcSubjects =
      get(values, "tipSpisaniy aId.value", null) === 1
        ? get(releaseFormData, "data.vipuskGPDetails", [])
        : get(releaseFormData, "data.detailsPoFaktu", []).map((item: any) => ({
            materialId: get(item, "materialId", ""),
            kol: get(item, "kol", ""),
          }));

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["nomerDoc", "kolTovara"].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.AddNotFilledReleaseProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          id: get(state, "id", null),
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          imyaVidTovara: get(values, "vidTovaraId.label", ""),
          kontragentId: get(values, "kontragentId.value", ""),
          nomerZakazNaryad: get(values, "zakazNaryadId.label", ""),
          nomerDogProdaja: get(values, "dogProdajaId.label", ""),
          imyaKontragent: get(values, "kontragentId.label", ""),
          imyaUsloviyaOplati: get(values, "usloviyaOplataId.label", ""),
          imyaTerritorii: get(values, "territoriyaId.label", ""),
          imyaSkladGP: get(values, "skladGPId.label", ""),
          imyaSkladSpis: get(values, "skladSpisId.label", ""),
          imyaTipSpisaniya: get(values, "tipSpisaniyaId.label", ""),
          imyaTovar: get(values, "tovarId.label", ""),
          imyaEdIzm: get(values, "edIzmId.label", ""),
          kolTovara: get(values, "kolTovara", ""),
          vipuskGPDetails: cntrcSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledreleaseProduct());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, releaseFormData, values]);

  const getDocNumber = useCallback((year?: string | null) => {
    DocumentServices.GetReleaseNumber({ year }).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
    });
  }, []);

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchReleaseProductsTempById(get(state, "id", "")));
        setDisable(false);
      } else {
        dispatch(fetchReleaseProductsById(get(state, "id", "")));
      }
    } else {
      setDisable(false);
    }
  }, [dispatch, state]);
  //   details - get materials from product id
  const getDetails = useCallback(() => {
    if (
      get(values, "zakazNaryadId", "") &&
      get(values, "kolTovara", null) &&
      get(values, "tipSpisaniyaId", null)
    ) {
      // this api only works when enter by temp id or add
      try {
        if (
          isEqual(disable, false) &&
          values.tovarId.value &&
          values.kolTovara &&
          values.zakazNaryadId
        ) {
          if (
            Number(get(values, "kolTovara", 0)) <=
            Number(get(values, "tovarId.maxKol", 0))
          ) {
            setLoadingDetails(true);
            DocumentServices.FetchReleaseDetails({
              zakazNaryadId: get(values, "zakazNaryadId.value", ""),
              kol: get(values, "kolTovara", ""),
              tovarId: get(values, "tovarId.value", ""),
              tipSpisaniyaId: get(values, "tipSpisaniyaId.value", null),
            }).then((res) => {
              setLoadingDetails(false);
              if (res.status == 200) {
                dispatch(
                  handleAddSubjectsRelease({
                    data: get(res, "data.data", []),
                    tipSpisaniyaId: get(values, "tipSpisaniyaId", 1), // for pushing tipSpisaniyaId checking
                  })
                );
                dispatch(
                  handleAddSubjectsReleasePoFackt({
                    data: get(res, "data.data", []),
                    tipSpisaniyaId: get(values, "tipSpisaniyaId", 2), // for pushing tipSpisaniyaId checking
                  })
                );
              }
            });
          }
        }
      } catch (error) {
        setLoadingDetails(false);
        console.log(error);
      }
    }
    if (isEmpty(values.kolTovara) && isEqual(disable, false)) {
      dispatch(handleAddSubjectsRelease(null));
      dispatch(handleAddSubjectsReleasePoFackt(null));
    }
  }, [
    values.zakazNaryadId,
    debouncedText,
    values.tovarId,
    disable,
    values.tipSpisaniyaId,
  ]);
  //  get sklad by territory Id
  const getSklads = useCallback(() => {
    if (
      get(values, "territoriyaId.value", null) &&
      get(values, "zakazNaryadId.value", null)
    ) {
      const findTerritory = territory.find(
        (item: any) =>
          get(item, "value", 0) === get(values, "territoriyaId.value", 0)
      );
      setFieldValue("skladGPId.value", get(findTerritory, "skladGPId", null));
      setFieldValue("skladGPId.label", get(findTerritory, "imyaSkladGP", null));
      setFieldValue("skladSpisId.value", get(findTerritory, "skladCMId", null));
      setFieldValue(
        "skladSpisId.label",
        get(findTerritory, "imyaSkladSM", null)
      );
    }
  }, [values.zakazNaryadId]);

  //  function get all material Prices
  const sumWithInitial = get(
    releaseFormData,
    "data.vipuskGPDetails",
    []
  ).reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.summa,
    0
  );
  const sumWithInitialPoFakt = get(
    releaseFormData,
    "data.detailsPoFaktu",
    []
  ).reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.summa,
    0
  );

  // check temp
  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  //  set sklad values
  useEffect(() => {
    getSklads();
  }, [values.zakazNaryadId]);

  // detilas
  useEffect(() => {
    getDetails();
  }, [getDetails]);

  // fetch tovar options
  useEffect(() => {
    if (get(values, "zakazNaryadId.value", null) && !disable) {
      const cancle = dispatch(
        fetchProductsReleaseOptions({
          zakazNaryadId: get(values, "zakazNaryadId.value", ""),
          vidTovarId: get(values, "vidTovaraId.value", null),
        })
      );
      return () => {
        cancle.abort();
        dispatch(clearReleaseOptions());
      };
    }
  }, [values.zakazNaryadId]);

  // fetch zakaz naryad - bay id kirgana chaqarmaslgi uchun qilingan edi
  useEffect(() => {
    if (get(values, "kontragentId.value", null) && !disable) {
      const cancel = dispatch(
        fetchOrderRegister({
          all: true,
          otmVipolneniya: 0,
          vidTovarId: get(values, "vidTovaraId.value", null),
          filterPage: "VIPUSK_GP",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );
      return () => {
        cancel.abort();
        // dispatch(clearRegisteredOrders());
      };
    }
  }, [values.kontragentId]);
  // fetch kontragent opitons
  useEffect(() => {
    if (get(values, "vidTovaraId.value", null) && !disable) {
      const cancelReq = dispatch(
        fetchAllCounterpart({
          all: true,
          filterPage: "VIPUSK_GP",
          vidTovarId: get(values, "vidTovaraId.value", null),
        })
      );
      return () => {
        cancelReq.abort();
      };
    }
  }, [values.vidTovaraId]);

  useEffect(() => {
    if (!get(state, "id", null)) {
      getDocNumber();
      dispatch(fetchTypeWriteOff({ all: true }));
    }
    return () => {
      dispatch(clearFormSemiData());
    };
  }, [dispatch]);
  return (
    <Content className={"min_height"}>
      <ReactModal isOpen={openModal}>
        <AddContractSubjects
          handleCloseModal={handleCloseModal}
          skladId={get(values, "skladSpisId.value", "")}
          zakazNaryadId={get(values, "zakazNaryadId.value", null)}
          dataTable={get(releaseFormData, "data.detailsPoFaktu", [])}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                {/* <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button> */}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FlexBox>
                <Col xs={4} style={{ paddingLeft: 0 }}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", "")}
                          handleDate={(e: any) => {
                            getDocNumber(
                              checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              })
                            );
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          disabled={disable}
                          maxDate={new Date()}
                          dateFormat="dd.MM.yyyy"
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </FlexBox>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Тип готовой продукции
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"vidTovaraId"}
                    isSearchable
                    value={get(values, "vidTovaraId.value", "")}
                    defaultValue={get(values, "vidTovaraId", "")}
                    options={productType}
                    handleValueLabel={(e: any) => {
                      setFieldValue("vidTovaraId", e);
                      if (!isEqual(e, get(values, "vidTovaraId", false))) {
                        setFieldValue("kontragentId.label", null);
                        setFieldValue("kontragentId.value", null);
                        setFieldValue("zakazNaryadId.value", null);
                        setFieldValue("zakazNaryadId.label", null);
                        setFieldValue("dogProdajaId.value", null);
                        setFieldValue("dogProdajaId.label", null);
                        setFieldValue("territoriyaId.value", null);
                        setFieldValue("territoriyaId.label", null);
                        setFieldValue("skladSpisId.label", null);
                        setFieldValue("skladGPId.label", null);
                        setFieldValue("skladSpisId.value", null);
                        setFieldValue("skladGPId.value", null);
                        setFieldValue("tovarId.value", null);
                        setFieldValue("tovarId.label", null);
                        setFieldValue("edIzmId", null);
                        dispatch(handleAddSubjectsRelease(null));
                        dispatch(handleAddSubjectsReleasePoFackt(null));
                        setFieldValue("kolTovara", "");
                      }
                    }}
                    isDisabled={
                      disable || loadingConter || tovarLoad || loadingOrder
                    }
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    isSearchable
                    defaultValue={get(values, "kontragentId", {})}
                    value={get(values, "kontragentId.value", {})}
                    options={
                      get(values, "vidTovaraId.value", false)
                        ? contractorDropdowns
                        : []
                    }
                    isLoading={loadingConter}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);
                      if (!isEqual(e, get(values, "kontragentId", false))) {
                        setFieldValue("zakazNaryadId.value", null);
                        setFieldValue("zakazNaryadId.label", null);
                        setFieldValue("dogProdajaId.value", null);
                        setFieldValue("dogProdajaId.label", null);
                        setFieldValue("territoriyaId.value", null);
                        setFieldValue("territoriyaId.label", null);
                        setFieldValue("skladSpisId.label", null);
                        setFieldValue("skladGPId.label", null);
                        setFieldValue("skladSpisId.value", null);
                        setFieldValue("skladGPId.value", null);
                        setFieldValue("tovarId.value", null);
                        setFieldValue("tovarId.label", null);
                        setFieldValue("edIzmId", null);
                        dispatch(handleAddSubjectsRelease(null));
                        dispatch(handleAddSubjectsReleasePoFackt(null));
                        setFieldValue("kolTovara", "");
                      }
                    }}
                    isDisabled={disable || loadingOrder || tovarLoad}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Заказ-наряд<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"zakazNaryadId"}
                    isSearchable
                    isLoading={loadingOrder}
                    defaultValue={get(values, "zakazNaryadId", {
                      label: null,
                      value: null,
                    })}
                    value={get(values, "zakazNaryadId.value", null)}
                    options={
                      get(values, "kontragentId.value", null)
                        ? orderRegister
                        : []
                    }
                    handleValueLabel={(e: any) => {
                      setFieldValue("zakazNaryadId", e);
                      setFieldValue(
                        "dogProdajaId.value",
                        get(e, "dogProdajaId", null)
                      );
                      setFieldValue(
                        "territoriyaId.value",
                        get(e, "territory", null)
                      );
                      setFieldValue(
                        "territoriyaId.label",
                        get(e, "imyaTerritory", null)
                      );
                      setFieldValue(
                        "dogProdajaId.label",
                        get(e, "imyaDogProdaja", null)
                      );
                      if (!isEqual(e, get(values, "zakazNaryadId", false))) {
                        setFieldValue("tovarId.value", null);
                        setFieldValue("tovarId.label", null);
                        setFieldValue("edIzmId", null);
                        dispatch(handleAddSubjectsRelease(null));
                        dispatch(handleAddSubjectsReleasePoFackt(null));
                        setFieldValue("kolTovara", "");
                      }
                    }}
                    isDisabled={disable || tovarLoad}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Договор <span className="required_point">*</span>{" "}
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"dogProdajaId"}
                    defaultValue={get(values, "dogProdajaId", "")}
                    isSearchable
                    value={get(values, "dogProdajaId.value", "")}
                    options={getSaleContracts}
                    isDisabled={true}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Территория<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"territoriyaId"}
                    isSearchable
                    value={get(values, "territoriyaId.value", "")}
                    options={territory}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад готовой продукции
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladGPId"}
                    isSearchable
                    defaultValue={get(values, "skladGPId", {})}
                    value={get(values, "skladGPId.value", "")}
                    options={skladOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladGPId", e);
                    }}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            {/* right */}
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад для списания материалов
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladSpisId"}
                    isSearchable
                    defaultValue={get(values, "skladSpisId", {})}
                    value={get(values, "skladSpisId.value", "")}
                    options={skladSMOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladSpisId", e);
                    }}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Тип списания <span className="required_point">*</span>{" "}
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tipSpisaniyaId"}
                    isSearchable
                    defaultValue={get(values, "tipSpisaniyaId", "")}
                    value={get(values, "tipSpisaniyaId.value", "")}
                    options={spisanyaOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("tipSpisaniyaId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Товар<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tovarId"}
                    isSearchable
                    defaultValue={get(values, "tovarId", {})}
                    placeholder={get(values, "tovarId.label", "")}
                    value={get(values, "tovarId.value", "")}
                    isLoading={tovarLoad}
                    options={tovarOpitons}
                    handleValueLabel={(e: any) => {
                      setFieldValue("tovarId", e);
                      setFieldValue("edIzmId.value", get(e, "edIzmId", null));
                      setFieldValue("edIzmId.label", get(e, "imyaEdizm", ""));
                      setFieldValue("maxKol", get(e, "maxKol", 0));
                      if (!isEqual(e, get(values, "tovarId"))) {
                        dispatch(handleAddSubjectsRelease(null));
                        dispatch(handleAddSubjectsReleasePoFackt(null));
                        setFieldValue("kolTovara", "");
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Количество <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      {isEqual(get(state, "isTemp", false), false) ? (
                        <>
                          <BaseMarkInput
                            id={"kolTovara"}
                            placeholder={
                              get(values, "tovarId.value", false)
                                ? `до: ${textFormatter(
                                    get(values, "tovarId.maxKol", 0)
                                  )}`
                                : ""
                            }
                            value={get(values, "kolTovara", "")}
                            handleInput={(e: number) => {
                              setFieldValue("kolTovara", e);
                            }}
                            min={1}
                            max={get(values, "tovarId.maxKol", 0)}
                            disabled={disable}
                          />
                          <p>
                            {Number(get(values, "kolTovara", 0)) >
                              Number(get(values, "tovarId.maxKol", 0)) &&
                            isEqual(disable, false) ? (
                              <Text xs danger>
                                ограничение до:
                                {textFormatter(
                                  Number(get(values, "tovarId.maxKol", 0))
                                )}
                              </Text>
                            ) : (
                              ""
                            )}
                          </p>
                        </>
                      ) : (
                        <BaseMarkInput
                          id={"kolTovara"}
                          value={get(values, "kolTovara", "")}
                          handleInput={(e: number) => {
                            setFieldValue("kolTovara", e);
                          }}
                          min={1}
                          disabled={disable}
                        />
                      )}
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text sm>Ед.изм:</Text>
                        <BaseSelect
                          id={"edIzmId"}
                          value={get(values, "edIzmId.value", "")}
                          options={unitsMeasurementDropdowns}
                          isDisabled={true}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormInput
                label={"Себестоимость  товара"}
                required={true}
                id={"sebestTovar"}
                value={
                  get(values, "tipSpisaniyaId.value", null) === 1 || disable
                    ? sumWithInitial.toFixed(2)
                    : sumWithInitialPoFakt.toFixed(2)
                }
                disabled={true}
              />
            </Col>
          </Row>

          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {isEqual(get(values, "tipSpisaniyaId.value", 1), 2) &&
                  isEqual(disable, false) && (
                    <ReactSVG
                      src={add_green}
                      className="svg_icon"
                      onClick={handleOpenModal}
                    />
                  )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              {loadingDetails ? (
                <ProgressBar
                  color="#5899f5"
                  mode="indeterminate"
                  style={{ height: "2px" }}
                ></ProgressBar>
              ) : (
                <hr />
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Сырьё и материал",
                    "Единица измерения",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "",
                  ]}
                >
                  {!loadingDetails ? (
                    <>
                      {get(values, "tipSpisaniyaId.value", null) === 1 ||
                      disable ? (
                        !isEmpty(
                          get(releaseFormData, "data.vipuskGPDetails", [])
                        ) ? (
                          get(releaseFormData, "data.vipuskGPDetails", []).map(
                            (item: any, index: number) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{get(item, "imyaMaterial", "")}</td>
                                <td>{get(item, "imyaEdIzm", "")}</td>
                                <td>
                                  {isEqual(
                                    get(values, "tipSpisaniyaId.value", 1),
                                    2
                                  ) && isEqual(disable, false) ? (
                                    <TableInput
                                      kol={get(item, "kol", 0)}
                                      maxKol={get(item, "maxKol", 0)}
                                      handleOnChange={(value: any) => {
                                        dispatch(
                                          handleChangeItemSubjectsRelease({
                                            id: index,
                                            name: "kol",
                                            value,
                                          })
                                        );
                                        dispatch(
                                          handleChangeItemSubjectsRelease({
                                            id: index,
                                            name: "summa",
                                            value:
                                              value *
                                              Number(get(item, "tsena", 0)),
                                          })
                                        );
                                      }}
                                    />
                                  ) : (
                                    <NumericFormat
                                      displayType="text"
                                      value={get(item, "kol", 0)}
                                      thousandSeparator
                                      decimalScale={2}
                                    />
                                  )}
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "tsena", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summa", 0)}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  {isEqual(
                                    get(values, "tipSpisaniyaId.value", 1),
                                    2
                                  ) &&
                                    isEqual(disable, false) && (
                                      <span>
                                        <ReactSVG
                                          src={remove_red}
                                          className="svg_icon"
                                          style={{ fontSize: 10 }}
                                          onClick={() =>
                                            dispatch(
                                              removeSubject({ id: index })
                                            )
                                          }
                                        />
                                      </span>
                                    )}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>Нет Данных</td>
                          </tr>
                        )
                      ) : !isEmpty(
                          get(releaseFormData, "data.detailsPoFaktu", [])
                        ) ? (
                        get(releaseFormData, "data.detailsPoFaktu", []).map(
                          (item: any, index: number) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{get(item, "imyaMaterial", "")}</td>
                              <td>{get(item, "imyaEdIzm", "")}</td>
                              <td>
                                {isEqual(
                                  get(values, "tipSpisaniyaId.value", 1),
                                  2
                                ) && isEqual(disable, false) ? (
                                  <TableInput
                                    kol={get(item, "kol", 0)}
                                    maxKol={get(item, "maxKol", 0)}
                                    handleOnChange={(value: any) => {
                                      dispatch(
                                        handleChangeItemSubjectsRelease({
                                          id: index,
                                          name: "kol",
                                          value,
                                        })
                                      );
                                      dispatch(
                                        handleChangeItemSubjectsRelease({
                                          id: index,
                                          name: "summa",
                                          value:
                                            value *
                                            Number(get(item, "tsena", 0)),
                                        })
                                      );
                                    }}
                                  />
                                ) : (
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "kol", 0)}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                )}
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "tsena", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "summa", 0)}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                {isEqual(
                                  get(values, "tipSpisaniyaId.value", 1),
                                  2
                                ) &&
                                  isEqual(disable, false) && (
                                    <span>
                                      <ReactSVG
                                        src={remove_red}
                                        className="svg_icon"
                                        style={{ fontSize: 10 }}
                                        onClick={() =>
                                          dispatch(removeSubject({ id: index }))
                                        }
                                      />
                                    </span>
                                  )}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>Нет Данных</td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={7}>Загрузка....</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              <FormButton
                disabled={disable}
                loading={loadingButton}
                type={"submit"}
                color={"#00c838"}
                handleClick={() =>
                  handleNavigate({
                    zakazNaryadIdProps: get(values, "zakazNaryadId", ""),
                    kontragentIdProps: get(values, "kontragentId"),
                  })
                }
              >
                {PAGE.SHIPMENT}
              </FormButton>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.VIPUSK_GP}_CREATE`,
                    page: DOCUMENT_KEY.VIPUSK_GP,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddReleaseProductContainer;
