import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    kassaId: null,
  }
};

export const fetchOperationalCash = createAsyncThunk(
  "operationalCash/fetchOperationalCash",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchOperationalCashTable(params);
      const respond = await request.data;
      return respond;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const operationalCashSlices = createSlice({
  name: "operationalCash",
  initialState,
  reducers: {
    filterOperationalCashTable: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearOperationalCashTable: () => initialState,
    changeOperationalCashTable: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperationalCash.pending, (state: any) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchOperationalCash.fulfilled, (state: any, action: any) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchOperationalCash.rejected, (state: any, payload: any) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const { filterOperationalCashTable, clearOperationalCashTable, changeOperationalCashTable } = operationalCashSlices.actions;
export default operationalCashSlices.reducer;