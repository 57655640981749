import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";
import axios from "axios";

const initialState: InitialState = {
  skladGP: {
    loading: false,
    data: [],
    error: "",
  },
  skladCM: {
    loading: false,
    data: [],
    error: "",
  },
  data: {
    loading: false,
    dataList: [],
    pagination: {},
    error: "",
  },
  filter: {
    page: 0,
    size: 10,
  },
};

export const fetchWarHouse = createAsyncThunk(
  "allWarHouse/fetchWarHouse",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetWareHouse(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
//  faqatgina o'ziga tegishli bo'lgan skladlarni chaqirish  --territory va skladda xam bor  - "own"
export const fetchOwnWarHouse = createAsyncThunk(
  "allWarHouse/fetchOwnWarHouse",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetOwnWareHouse(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSkladGP = createAsyncThunk(
  "allWarHouse/fetchSkladGP",
  async (params: any, thunkAPI) => {
    try {
      const request = await HandBookServices.GetWareHouse({
        ...params,
        tipSklada: 0,
        all: true,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSkladCM = createAsyncThunk(
  "allWarHouse/fetchSkladCM",
  async (params: any, thunkAPI) => {
    try {
      const request = await HandBookServices.GetWareHouse({
        ...params,
        tipSklada: 1,
        all: true,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
// own
export const fetchOwnSkladGP = createAsyncThunk(
  "allWarHouse/fetchOwnSkladGP",
  async (params: any, thunkAPI) => {
    try {
      const request = await HandBookServices.GetOwnWareHouse({
        ...params,
        tip: 0,
        all: true,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchOwnSkladCM = createAsyncThunk(
  "allWarHouse/fetchOwnSkladCM",
  async (params: any, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source()
      signal.addEventListener('abort', () => {
        soure.cancel()
      })
      const request = await HandBookServices.GetOwnWareHouse({
        ...params,
        tip: 1,
        cancelToken: soure.token,
        all: true,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
const warHouseSlices = createSlice({
  name: "allWarHouse",
  initialState,
  reducers: {
    handleWarHouseFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearFilterWarHouse: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWarHouse.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchWarHouse.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchWarHouse.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        pagination: {},
        error: payload.error.message,
      };
    });
    // own
    builder.addCase(fetchOwnWarHouse.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchOwnWarHouse.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchOwnWarHouse.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        pagination: {},
        error: payload.error.message,
      };
    });

    // гп
    builder.addCase(fetchSkladGP.pending, (state) => {
      state.skladGP = {
        loading: true,
        data: [],
        error: "",
      };
    });
    builder.addCase(fetchSkladGP.fulfilled, (state, action) => {
      state.skladGP = {
        loading: false,
        data: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchSkladGP.rejected, (state, payload) => {
      state.skladGP = {
        loading: false,
        data: [],
        error: payload.error.message,
      };
    });
    // см
    builder.addCase(fetchSkladCM.pending, (state) => {
      state.skladCM = {
        loading: true,
        data: [],
        error: "",
      };
    });
    builder.addCase(fetchSkladCM.fulfilled, (state, action) => {
      state.skladCM = {
        loading: false,
        data: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchSkladCM.rejected, (state, payload) => {
      state.skladCM = {
        loading: false,
        data: [],
        error: payload.error.message,
      };
    });
    // own
    builder.addCase(fetchOwnSkladGP.pending, (state) => {
      state.skladGP = {
        loading: true,
        data: [],
        error: "",
      };
    });
    builder.addCase(fetchOwnSkladGP.fulfilled, (state, action) => {
      state.skladGP = {
        loading: false,
        data: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchOwnSkladGP.rejected, (state, payload) => {
      state.skladGP = {
        loading: false,
        data: [],
        error: payload.error.message,
      };
    });
    // см
    builder.addCase(fetchOwnSkladCM.pending, (state) => {
      state.skladCM = {
        loading: true,
        data: [],
        error: "",
      };
    });
    builder.addCase(fetchOwnSkladCM.fulfilled, (state, action) => {
      state.skladCM = {
        loading: false,
        data: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchOwnSkladCM.rejected, (state, payload) => {
      state.skladCM = {
        loading: false,
        data: [],
        error: payload.error.message,
      };
    });
  },
});

export const { handleWarHouseFilter, clearFilterWarHouse } =
  warHouseSlices.actions;
export default warHouseSlices.reducer;
