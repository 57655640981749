import { useAppDispatch, useAppSelector } from "app/hook";
import { typeRoleFilter, typeRoleParmDetails, typeRolePermissions } from "app/states/users";
import { useCallback, useEffect, useMemo, useState } from "react"
import { BaseTable, Button, Checkbox, Content, FlexBox, Form, Input, LoadingTable, Title } from "components"
import { Col, Row } from "react-grid-system";
import { clearTypeDetails, fetchTypeRole, fetchTypeRolePermissions, handleDetails, handlePermissionDetails, handleRemoveDetails } from "app/slices/usersSlices/typeRoleSlices/typeRoleSlices";
import { get, isEmpty, isEqual, map } from "lodash";
import UsersServices from "services/apiServices/users";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import col_action from 'assets/images/icons/colaction.svg';
import row_action from 'assets/images/icons/rowaction.svg';
import { MESSAGES } from "utilities/constants";
type ElementType = {
    action: string,
    checked: boolean,
    exists: boolean,
    permissionId: number,
}

const AddTypeRoleContainer = () => {
    const dispatch = useAppDispatch()
    const permission = useAppSelector(typeRolePermissions)
    const LOADING_DATA = get(permission, "loading", false)
    const [nameTip, setNameTip] = useState<string>('')
    const DATA = useMemo(() => get(permission, "permissionsData", []), [permission])
    const filter = useAppSelector(typeRoleFilter)
    const { state } = useLocation()
    const detailsParm = useAppSelector(typeRoleParmDetails)
    const navigate = useNavigate()
    const [action, setAction] = useState<number | null>(null)
    const handleSubmit = () => {

        const details = get(detailsParm, "permissionIds", [])

        const req = {
            roleName: nameTip,
            id: get(state, "id", null),
            permissionIds: details,
        }
        UsersServices.AddTypeRole({
            transactionTime: new Date(),
            resultCode: null,
            resultMsg: null,
            data: req
        }).then((res) => {
            if (res.status === 200) {
                toast.success(MESSAGES.SEVED)
                dispatch(fetchTypeRole(filter))
                dispatch(clearTypeDetails())
                navigate(-1);
            }
        }).catch((err) => console.log(err))
    };
    const fetchPermissionCall = useCallback(() => {
        if (get(state, "id", false)) {
            dispatch(fetchTypeRolePermissions({ roleId: get(state, "id", null) }))
            setNameTip(get(state, "name", ""))
        } else {
            dispatch(fetchTypeRolePermissions({}))
        }
    }, [dispatch, state])

    // checkd
    const pushCheckedId = useCallback(() => {
        if (get(state, "id", false) && get(permission, "permissionsData", [])) {
            get(permission, "permissionsData", []).map((outItem: any) => {
                get(outItem, "pages", []).map((middleItem: any) => {
                    get(middleItem, "actions", []).map((action: any) => {
                        if (get(action, "checked", false)) {
                            dispatch(handlePermissionDetails(
                                {
                                    id: get(action, "permissionId", null)
                                }
                            ))
                        }
                    })
                })
            })
        }
    }, [state, get(permission, "permissionsData", [])])


    useEffect(() => {
        fetchPermissionCall()
        return () => {
            dispatch(clearTypeDetails())
        }
    }, [fetchPermissionCall])
    useEffect(() => { pushCheckedId() }, [pushCheckedId])
    return (
        <Content height={"60vh"} style={{ position: "relative" }} className="responsive_overflow">
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} className="mb-8">
                        <Title bold sm>
                            Ввод данных
                        </Title>
                    </Col>
                    <Col xs={12} style={{ padding: 0 }}>
                        <hr />
                    </Col>
                    <Col xs={6} className="mb-16">
                        <Input
                            style={{ marginTop: 0 }}
                            label={"Наименование"}
                            value={nameTip}
                            required={true}
                            handleInput={(e: string) => {
                                setNameTip(e)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Title bold sm>
                            Права
                        </Title>
                    </Col>
                    <Col xs={12} className={"my-8"}>
                        <hr />
                    </Col>
                    <Col xs={12}
                    //  className="table_scroll" style={{ overflow: "auto", maxHeight: "50vh", minHeight: "50vh" }}
                    >
                        <BaseTable
                            tableHeader={[
                                "№",
                                "Наименование",
                                "Чтение (Испол-е)",
                                "Ввод нового",
                                "Изменение",
                                "Пометка на удаление",
                                "Отмена пометки удаление",
                                "Удаление"
                            ]}
                        >
                            {!LOADING_DATA ?
                                <>
                                    {
                                        !isEmpty(get(permission, "permissionsData", [])) ? (
                                            DATA.map((item: any, outIndex: number) =>
                                            (
                                                <>
                                                    <tr key={outIndex + 1} onClick={() => setAction(action !== get(item, "department", null) ? get(item, "department", null) : null)} >

                                                        <td colSpan={8} style={{ textAlign: "start" }} >
                                                            <FlexBox gap={"15px"}>
                                                                {
                                                                    isEqual(action, get(item, "department", null)) ?
                                                                        <ReactSVG src={row_action} />
                                                                        :
                                                                        <ReactSVG src={col_action} />
                                                                }
                                                                <Title>
                                                                    {get(item, "title", "")}
                                                                </Title>
                                                            </FlexBox>
                                                        </td>
                                                    </tr>

                                                    {
                                                        isEqual(action, get(item, "department", null)) &&
                                                        // pages map
                                                        !isEmpty(get(item, "pages", [])) &&
                                                        get(item, "pages", []).map((value: any, middleIndex: number) => (
                                                            // action map
                                                            <tr  >
                                                                <td>{middleIndex + 1}</td>
                                                                <td>{get(value, "title", "")}</td>
                                                                {
                                                                    get(value, "actions", []).map((element: ElementType, actionIndex: number) => (
                                                                        <td >
                                                                            {/* {get(element, "action", "")} */}
                                                                            {
                                                                                isEqual(get(element, "exists", false), true) ?
                                                                                    <Checkbox
                                                                                        key={actionIndex + 1}
                                                                                        value={get(element, "checked", false)}
                                                                                        handleChange={(e) => {
                                                                                            dispatch(
                                                                                                handleDetails({
                                                                                                    outIndex1: outIndex,
                                                                                                    middleIndex1: middleIndex,
                                                                                                    id: get(element, "permissionId", null),
                                                                                                    check: e.target.checked,
                                                                                                })

                                                                                            )
                                                                                            if (e.target.checked) {
                                                                                                dispatch(handlePermissionDetails(
                                                                                                    {
                                                                                                        id: get(element, "permissionId", null)
                                                                                                    }
                                                                                                ))
                                                                                            } else {
                                                                                                dispatch(handleRemoveDetails(
                                                                                                    {
                                                                                                        id: get(element, "permissionId", null)
                                                                                                    }
                                                                                                ))
                                                                                            }

                                                                                        }}
                                                                                    />
                                                                                    : ""
                                                                            }
                                                                        </td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        ))
                                                        // :
                                                        // <tr><td></td></tr>
                                                    }

                                                </>
                                            ))
                                        )
                                            :
                                            (
                                                <tr>
                                                    <td colSpan={8}>Нет данных</td>
                                                </tr>
                                            )
                                    }
                                </>
                                :
                                map([...new Array(10)], (item, index) => (
                                    <tr key={index + 1}>
                                        <LoadingTable
                                            width={
                                                index % 2 == 0 ? "50px" : "70px"
                                            }
                                            tblDTNumber={8}
                                        />
                                    </tr>
                                ))
                            }
                        </BaseTable>

                    </Col>
                </Row>
                <Row className={"mb-16 mt-32 text-end"}>
                    <Col xs={12}>

                        <Button primary className={"mr-16"} handleClick={handleSubmit}>
                            Сохранить
                        </Button>
                        <Button danger handleClick={() => navigate(-1)}>
                            Закрыть
                        </Button>
                    </Col>
                </Row>
            </Form >
        </Content >
    )
}

export default AddTypeRoleContainer