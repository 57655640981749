import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, ZakazNaryadDetails } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";
import axios from "axios";

const initialState: InitialState = {
  orderRegisterStat: [],
  filter: {
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      zakazNaryadTovars: [],
    },
  },
  orders: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};
export const fetchOrderRegister = createAsyncThunk(
  "orderRegister/fetchOrderRegister",
  async (params: any, { rejectWithValue, signal }) => {
    try {
      const soure = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        soure.cancel();
      });
      const request = await DocumentServices.FetchOrderRegister({
        ...params,
        cancelToken: soure.token,
      });
      const respond = await request.data;
      return respond;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const fetchNotFilledOrderRegisterById = createAsyncThunk(
  "registerSalesCnt/fetchNotFilledOrderRegisterById",
  async (id: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchTempsOrderRegisterById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchOrderRegisterByTempId = createAsyncThunk(
  "registerSalesCnt/fetchOrderRegisterByTempId",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchOrderRegisterByTempId(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchOrderRegisterStat = createAsyncThunk(
  "orderRegister/fetchOrderRegisterStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchOrderRegisterStat();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchTempsOrderRegisterOrder = createAsyncThunk(
  "orderRegister/fetchTempsOrderRegisterOrder",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchTempsOrderRegister();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchOrderRegisterById = createAsyncThunk(
  "orderRegister/fetchOrderRegisterById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchOrderRegisterById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const orderRegisterSlice = createSlice({
  name: "orderRegister",
  initialState,
  reducers: {
    handleFilterChangeOrder: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },

    movementOrderSubject: (state = initialState, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            zakazNaryadTovars: !isEmpty(get(action, "payload.data", []))
              ? [...state.formData.data.zakazNaryadTovars, action.payload.data]
              : [],
          },
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            zakazNaryadTovars: state.formData.data.zakazNaryadTovars.map(
              (item: ZakazNaryadDetails) =>
                item.tovarId == id ? get(action, "payload.data", {}) : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            zakazNaryadTovars: state.formData.data.zakazNaryadTovars.filter(
              (item: ZakazNaryadDetails) => item.tovarId !== id
            ),
          },
        };
      } else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            zakazNaryadTovars: [],
          },
        };
      }
    },
    handleClearOrderFormData: (state) => {
      state.formData = initialState.formData;
    },

    clearRegisteredOrders: () => initialState,
    clearFilterData: (state) => {
      state.filter = initialState.filter;
    },
    clearRegisterOrderForm: (state) => {
      // state.formData = initialState.formData
      state.orders = initialState.orders;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderRegister.pending, (state) => {
      state.orders = {
        ...state.orders,
        loading: true,
      };
    });
    builder.addCase(fetchOrderRegister.fulfilled, (state, actions) => {
      state.orders = {
        loading: false,
        data: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchOrderRegister.rejected, (state, actions) => {
      state.orders = {
        ...state.orders,
        loading: false,
        error: "",
      };
    });
    builder.addCase(fetchTempsOrderRegisterOrder.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(
      fetchTempsOrderRegisterOrder.fulfilled,
      (state, actions) => {
        state.notFilledCntrList = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
        };
      }
    );
    builder.addCase(fetchTempsOrderRegisterOrder.rejected, (state, actions) => {
      state.notFilledCntrList = {
        loading: false,
        data: [],
        error: actions.error.message,
      };
    });
    // fetching not full filled contract by id
    builder.addCase(fetchOrderRegisterByTempId.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchOrderRegisterByTempId.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchOrderRegisterByTempId.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: {},
        error: "",
        isTempActive: false,
      };
    });
    //  fetching by id
    builder.addCase(fetchOrderRegisterById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchOrderRegisterById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchOrderRegisterById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: {},
        error: "",
        isTempActive: false,
      };
    });
    // fetching sale contracts statists
    builder.addCase(fetchOrderRegisterStat.pending, (state) => {
      state.orderRegisterStat = {
        loading: true,
      };
    });
    builder.addCase(fetchOrderRegisterStat.fulfilled, (state, actions) => {
      state.orderRegisterStat = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchOrderRegisterStat.rejected, (state, actions) => {
      state.orderRegisterStat = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleFilterChangeOrder,
  handleClearOrderFormData,
  clearRegisteredOrders,
  clearFilterData,
  clearRegisterOrderForm,
  movementOrderSubject,
} = orderRegisterSlice.actions;
export default orderRegisterSlice.reducer;
