import { Wrapper } from "components";
import { Children } from "types/commonTypes";
import GlobalStyle from "theme/GlobalStyle";

const Theme = ({ children }: Children) => {
    return (
        <Wrapper>
            <GlobalStyle />
            {children}
        </Wrapper>
    );
};

export default Theme;
