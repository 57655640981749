import styled from "styled-components";
import { StyleProps } from "./type";

export const TableWrapper = styled.table<StyleProps>`
  width: 100%;
  min-width: ${({ minWidth }: StyleProps) => minWidth || "max-width"};
  border: 0.5px solid black;
  border-collapse: collapse;
  /* overflow: auto; */
  th,
  td {
    text-align: center;
    border-collapse: collapse;
    font-weight: 500;
    line-height: 24px;
    border: 0.5px solid black;
    font-family: "Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #1c1c1c;
  }
  td {
    padding: 0.3em;
    font-size: 15px;
    width: ${({ width }: StyleProps) => width || "0px"};
  }

  th {
    padding: 0.5em;
    font-size: 16px;
    width: ${({ width }: StyleProps) => width || "0px"};
  }
`;
