import { LogoContainer, NameOrg, Image } from "components/Logo/style";
import { Props } from "components/Logo/type";
import { memo } from "react";

const Logo = ({ img = "", name, ...props }: Props) => {
    return (
        <LogoContainer>
            <NameOrg>{name}</NameOrg>
            <Image src={img} />
        </LogoContainer>
    );
};

export default memo(Logo);
