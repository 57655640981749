import { get } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { TableWrapper } from "./styles/style";

const ReportOnShipmentTable = ({ data, click }: any) => {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper minWidth="1200px" className="mb-16">
              <thead>
                <tr>
                  <th>№</th>
                  <th >Дата документа</th>
                  <th>Номер документа</th>
                  <th style={{ minWidth: "250px" }}>Контрагент</th>
                  <th style={{ minWidth: "200px" }}>Адрес доставки</th>
                  <th>Автомобиль</th>
                  <th style={{ minWidth: "200px" }}>Водитель</th>
                  <td style={{ minWidth: "200px" }}>Товар</td>
                  <td>Количество</td>
                  <td>Ед. измерение</td>
                  <td>Территория</td>
                </tr>
              </thead>
              <tbody>
                {get(data, "shipmentList", []) ? (
                  get(data, "shipmentList", []).map(
                    (item: any, index: number) => (
                      <tr key={index + 1}>
                        <th>{get(item, 'idx', 0) ? get(item, 'idx', 0) : null}</th>
                        <td >
                          {get(item, "dataDoc", "") ? moment(get(item, "dataDoc", "")).format("DD.MM.YYYY") : '︎ㅤ'}
                        </td>
                        <td>
                          {get(item, 'nomerDoc', '')}
                        </td>
                        <td>
                          {get(item, 'imyaKontragent', '')}
                        </td>
                        <td>
                          {get(item, 'address', '')}
                        </td>
                        <td>
                          {get(item, 'gosNomer', '')}
                        </td>
                        <td>
                          {get(item, 'imyaVoditeli', '')}
                        </td>
                        <td>
                          {get(item, 'imyaTovar', '')}
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "kol", 0)}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          {get(item, 'edIzm', '')}
                        </td>
                        <td>{get(item, 'imyaTerritori', '')}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={11}>Виберите параметры</td>
                  </tr>
                )}
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReportOnShipmentTable;
