import { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { get, isEmpty, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import { useAppDispatch, useAppSelector } from "app/hook";
import { workOrderList, workOrderFilter } from "app/states/reports";
import { contractorOptions } from "app/states/documents/selectStates";
import { getTerritoriesData } from "app/states/handbooks/selectStates";
import {
  fetchOwnTerritories,
  fetchTerritories,
} from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  BaseSelect,
  Button,
  Checkbox,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import ExcelJS from "exceljs";
import {
  changeWorkOrder,
  clearWorkOrder,
  fetchWorkOrder,
  filterWorkOrder,
} from "app/slices/reportSlices/workOrderRegisterSlices/workOrderRegisterSlices";

import { WorkOrderRegisterTable } from "../components";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import NotFound from "../components/NotFound";

type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  territoriya?: string;
  kontragent?: string;
}

// Реестр Заказ-нарядов
const WorkOrderRegisterContainer = () => {
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<ParamState>({
    territoriya: "",
    kontragent: "",
  });

  // data
  const filter = useAppSelector(workOrderFilter);
  const data = useAppSelector(workOrderList);
  const LOADING_TABLE = get(data, "loading", false);

  const contractorData = useAppSelector(contractorOptions);
  const territoryData = useAppSelector(getTerritoriesData);

  //download Excel
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Реестр Заказ-нарядов ${moment(new Date()).format(
      "YYYY-MM-DD"
    )}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `I1`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "territoriya", ""),
        label: `Tерритория: ${get(details, "territoriya", "")}`,
      },
      {
        value: get(details, "kontragent", ""),
        label: `Заказчик: ${get(details, "kontragent", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell("A1").value = `Реестр Заказ-нарядов с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(`A${1 + index + 1}`, `${"I" + (1 + index + 1)}`);
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Номер Заказ-наряда",
        key: "nomerDoc",
        width: 30,
      },
      {
        header: "Дата Заказ-наряда",
        key: "dataDoc",
        width: 25,
      },
      {
        header: "Tерритория",
        key: "imyaTerritoriya",
        width: 35,
      },
      {
        header: "Дата испол.",
        key: "dateIsp",
        width: 30,
      },
      {
        header: "Адрес доставки",
        key: "kontragentImya",
        width: 30,
      },
      {
        header: "Товар",
        key: "imyaTovara",
        width: 30,
      },
      {
        header: "Количество",
        key: "kol",
        width: 30,
      },
      {
        header: "Выполнение",
        key: "otmVipolneniya",
        width: 20,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList.list", [])?.forEach((item: any, index: number) => {
      sheet.addRow({
        order: index + 1,
        nomerDoc: get(item, "nomerDoc", ""),
        dataDoc: get(item, "dataDoc", ""),
        imyaTerritoriya: get(item, "imyaTerritoriya", ""),
        dateIsp: get(item, "dateIsp", ""),
        kontragentImya: get(item, "kontragentImya", ""),
        imyaTovara: "",
        kol: "",
        otmVipolneniya: get(item, "otmVipolneniya", "") ? "Да" : "Нет",
      });
      counterRow += 1;
      get(item, "details", []).forEach((detail: any) => {
        const row = sheet.addRow({
          order: "ㅤ",
          nomerDoc: "ㅤ",
          dataDoc: "ㅤ",
          imyaTerritoriya: "ㅤ",
          dateIsp: "ㅤ",
          kontragentImya: "ㅤ",
          imyaTovara: get(detail, "imyaTovara", ""),
          kol: Number(get(detail, "kol", "")),
          otmVipolneniya: "",
        });
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
          };
        });
        counterRow++;
      });
    });

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `I${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`I${counterRow + count + 1}`);
    // const startCell = worksheet.getCell('A1');
    // const endCell = worksheet.getCell('K17');

    // Add colored borders to each cell in the range
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
        (sheet.getCell(item).font = {
          size: 11,
          bold: true,
        })
    );
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  // filter data
  const fetchWorkOrderDate = useCallback(() => {
    dispatch(fetchWorkOrder(filter));
  }, [dispatch, filter]);

  // clear filter
  const handleClearFilter = useCallback(() => {
    dispatch(clearWorkOrder());

    setDetails({
      territoriya: "",
      kontragent: "",
    });
  }, []);

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeWorkOrder(null));
    }

    dispatch(filterWorkOrder({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnTerritories({ all: true }));
    dispatch(fetchAllCounterpart({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  placeholder="Дата начало"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "fromDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
                <DatePicker
                  placeholder="Дата конец"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchWorkOrderDate}>
                Сформировать
              </Button>
              <Button danger className="ml-8" handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className="mt-16">
              <Title sm bold>
                Необязательные параметры
              </Title>
            </Col>
            <Col xs={9} className="mt-16">
              <FlexBox gap={"12px"}>
                <BaseSelect
                  options={territoryData}
                  placeholder="Tерритория"
                  value={get(filter, "territoriyaId", null)}
                  handleValueLabel={(e: any) => {
                    if (filter.territoriyaId === e.value) return;

                    handleChangeFilter({
                      name: "territoriyaId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      territoriya: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchWorkOrder({
                        ...filter,
                        territoriyaId: e.value,
                      })
                    );
                  }}
                />
                <BaseSelect
                  options={contractorData}
                  value={get(filter, "kontragentId", null)}
                  placeholder="Заказчик"
                  handleValueLabel={(e: any) => {
                    if (filter.kontragentId === e.value) return;

                    handleChangeFilter({
                      name: "kontragentId",
                      value: e.value,
                    });

                    setDetails({
                      ...details,
                      kontragent: get(e, "label", ""),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchWorkOrder({
                        ...filter,
                        kontragentId: e.value,
                      })
                    );
                  }}
                />
                <DatePicker
                  placeholder="Дата исполнения"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "dataIsp", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "dataIsp",
                      value: moment(e).format("YYYY-MM-DD"),
                    });

                    if (data.dataList === null) return;
                    dispatch(
                      fetchWorkOrder({
                        ...filter,
                        dataIsp: moment(e).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                />
              </FlexBox>
            </Col>

            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    details={details}
                    titleTxt={"Реестр Заказ-нарядов"}
                  />

                  {!isEmpty(get(data, "dataList.list", [])) ? (
                    <WorkOrderRegisterTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default WorkOrderRegisterContainer;
