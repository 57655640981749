import { useState, useRef } from "react";
import { default as ReactSelect, components, InputAction } from "react-select";
import { SelectWrapper, customStyles } from "./style";
import { FormInputWrapper } from "components/FormSelect/style";
import { Col, Row } from "react-grid-system";
import Title from "components/Title/Title";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import arrowIcon from "assets/images/icons/arrow-down.svg";
import { ReactSVG } from "react-svg";
export type Option = {
    value: number | string;
    label: string;
};
const DropdownIndicator = () => {
    return <ReactSVG className={"select-arrow"} src={arrowIcon} />;
};

const MultiSelectBase = ({
    required,
    labelSelect,
    isDisabled,
    handleValueLabel,
    menuPosition = "absolute",
    options,
    handleChange,
    error,
    value,
    ...props
}: any) => {


    const dropdownIcon = <ReactSVG src={arrowIcon} />
    const all: string = "select all"
    const [selectedCities, setSelectedCities] = useState(null);
    const onShow = () => {
        let selectAllCheckbox: any = document.querySelector(
            ".p-multiselect-header > .p-checkbox"
        );
        selectAllCheckbox.after("    Выбрать все");
    }
    const handleChangeFun = (event: any) => {
        setSelectedCities(event)
        handleChange(event)
    }
    return (
        <FormInputWrapper {...props}>
            <Row align={"center"} justify={"end"} >
                <Col xs={4} >
                    <Title>{labelSelect}
                        {required ?
                            <span className="required_point">*</span> : ""
                        }
                    </Title>
                </Col>
                <Col xs={8} >
                    <MultiSelect
                        value={value}
                        onChange={(e: MultiSelectChangeEvent) => handleChangeFun(e.value)}
                        dropdownIcon={dropdownIcon}
                        options={options}
                        display="chip"
                        optionLabel="label"
                        optionValue="value"
                        placeholder=""
                        onShow={onShow}
                        maxSelectedLabels={3}
                        style={{
                            maxWidth: "390px",
                            maxHeight: "36px",
                            paddingTop: "0px",
                            borderRadius: "8px",
                            width: "100%",
                            color: "#1c1c1c",
                            fontWeight: "500",
                            fontSize: "12px",
                            minWidth: "300px",
                            alignItems: "center",
                            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.06)",
                        }}
                        className="w-full md:w-20rem p-inputwrapper"
                    />
                </Col>

            </Row>
        </FormInputWrapper >
    );

};

export default MultiSelectBase;
