import { useAppSelector } from "app/hook";
import {
  departmentList,
  dogPermissions,
  pagesList,
  profileData,
} from "app/states/profile";
import { get, isEmpty, isEqual } from "lodash";
import { useMemo } from "react";
import { UserCanType } from "types/commonTypes";
import { PROTECTED_TYPE } from "utilities/constants";

const HasAccess = ({ children }: any) => {
  const profile = useAppSelector(profileData);
  const isAdmin = useMemo(
    () => get(profile, "profileData.roleId", []),
    [profile]
  );
  const departmentData = useAppSelector(departmentList);
  const permissionDocument = useAppSelector(dogPermissions);

  // doucment
  const documentListListData = useAppSelector(pagesList);
  const documentList = useMemo(
    () => get(documentListListData, "documentList", []),
    [documentListListData]
  );
  const userCan = ({
    page,
    type,
    department,
    permission,
    documentLine,
  }: UserCanType) => {
    const foundDepartment = permissionDocument.find(
      (item: any) => item.department == department
    );
    const permissionList = get(foundDepartment, "pages", []).find(
      (item: any) => get(item, "page", "") == page
    );
    switch (type) {
      case PROTECTED_TYPE.DEPARTMENTS:
        if (isAdmin === 1 && isEqual(department, "POLZAVATELI")) {
          return true;
        }

        if (
          isEqual(department, "KASSA") &&
          !get(permissionList, "actions", []).includes("KASSA_DOC_READ")
        ) {
          return false;
        }
        return departmentData.includes(department); //boolen

      case PROTECTED_TYPE.DOCUMENT_PERMISSIONS:
        return documentList.includes(documentLine);

      case PROTECTED_TYPE.PERMISSIONS:
        if (permissionList) {
          return get(permissionList, "actions", []).includes(permission);
        }
    }
  };

  return <>{children({ userCan })}</>;
};

export default HasAccess;
