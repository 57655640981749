import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchAllCloseMonth,
  filterChangeCloseMonth,
} from "app/slices/documentSlices/closeMonthSlices/closeMonthSlices";
import {
  clearFilterSubProduct,
  clearFormDataSubstandard,
  fetchSubstandardProduct,
  fetchSubstandardProductStatistics,
  filterChangeSubstandard,
} from "app/slices/documentSlices/substandardProductSlices/substandardProductSlices";
import { handleChangeFilter } from "app/slices/handbookSlices/bankSlices/bankSlices";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  closeMonthFilter,
  closeMonthList,
  closeMonthStat,
  substandardProductFilter,
  substandardProductList,
  substandardProductStatistics,
} from "app/states/documents";
import {
  getProductData,
  loadingProductionData,
} from "app/states/handbooks/selectStates";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";

// Списание некондиционного товара (брака)

const SubstandardProductContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_SUBSTANDARD_PRODUCT
  );
  const subStandards = useAppSelector(substandardProductList);
  const statistics = useAppSelector(substandardProductStatistics);
  const filter = useAppSelector(substandardProductFilter);
  const LOADING_TABLE = get(subStandards, "loading", []);
  const tovariiOptions = useAppSelector(getProductData);
  let loadingTovar = useAppSelector(loadingProductionData);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const handelFilterDetails = useCallback(() => {
    dispatch(fetchSubstandardProduct(filter));
    dispatch(fetchSubstandardProductStatistics());
    dispatch(fetchProduct({ all: true }));
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(clearFilterSubProduct());
    dispatch(
      fetchSubstandardProduct({
        ...filter,
        fromDate: null,
        toDate: null,
        tovarId: null,
      })
    );
  }, [dispatch, filter]);

  const navigateToForm = (params: { id: string | number }) => {
    navigate(Documents.ADD_SUBSTANDARD_PRODUCT, {
      state: params,
    });
  };
  useEffect(() => {
    handelFilterDetails();
  }, [filter.page, filter.size]);

  const handleAddButton = () => {
    dispatch(clearFormDataSubstandard());
    navigate(Documents.ADD_SUBSTANDARD_PRODUCT);
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteSubstandardProductById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        handelFilterDetails();
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoSubstandardProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        handelFilterDetails();
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsSubstandardProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        handelFilterDetails();
      }
    });
  };
  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, []);
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={statistics} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button className="mr-16" danger handleClick={handleClearFilter}>
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "SPISANIYA_BRAK_CREATE",
                    page: "SPISANIYA_BRAK",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  isLoading={loadingTovar}
                  options={tovariiOptions}
                  placeholder="Товар"
                  width="200px"
                  handleChange={(e: any) => {
                    dispatch(
                      filterChangeSubstandard({
                        name: "tovarId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "tovarId", null)}
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeSubstandard({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeSubstandard({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className={"text-right"}>
              <Button primary handleClick={handelFilterDetails}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateToForm}
                  deleteDocument={handleDeleteButton}
                  deleteItem={true}
                  markItem={true}
                  uncheckItem={true}
                  userPermission={"SPISANIYA_BRAK"}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Территория",
                    "Товар",
                    "Количество",
                    "Себестоимость",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    !isEmpty(get(subStandards, "data", [])) ? (
                      get(subStandards, "data", []).map(
                        (item: any, index: number) => (
                          <tr
                            key={get(item, "id", "")}
                            onClick={() =>
                              navigateToForm({
                                id: get(item, "id", ""),
                              })
                            }
                            onContextMenu={(e) =>
                              displayMenu({
                                e,
                                id: get(item, "id", ""),
                                status: get(item, "status", 0),
                              })
                            }
                            className="cursor-pointer"
                          >
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  subStandards,
                                  "pagination.currentPageNumber",
                                  0
                                ),
                                pageSize: get(
                                  subStandards,
                                  "pagination.pageSize",
                                  10
                                ),
                                index: index + 1,
                              })}
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "nomerDoc", "-")}</td>
                            <td>
                              {moment(get(item, "dataDoc", "")).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>{get(item, "imyaTerritorii", "")}</td>
                            <td>{get(item, "imyaTovara", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "kolTovara", "")}
                                thousandSeparator
                                fixedDecimalScale={false}
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "sebestTovar", "")}
                                thousandSeparator
                                fixedDecimalScale={false}
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              {moment(get(item, "createdAt", "")).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>{get(item, "status", "-")}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <Nodata colLength={10} />
                    )
                  ) : (
                    map([...new Array(10)], (_, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={10}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(subStandards, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(subStandards, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      filterChangeSubstandard({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(subStandards, "pagination.currentPageNumber", 0)}
                  pageCount={get(subStandards, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      filterChangeSubstandard({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default SubstandardProductContainer;
