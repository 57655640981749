export enum Main {
  HOME = "/",
  DOCUMENTS = "documents",
  REGISTERS = "registers",
  REPORTS = "reports",
  HANDBOOK = "spravochnik",
  USERS = "users",
  KASSA = "kassa",
  ENUMERATIONS = "perechislenya",
  LOGIN = "login",
}

export enum Documents {
  SALES_CONTRACTS = "sales-contracts",
  REGISTER_ORDERS = "register-orders",
  REGISTER_PURCHASES = "register-purchases",
  RECEIVING_MATERIAL = "receiving-material",
  RETURN_MATERIAL = "returning-material",
  MOVEMENT_MATERIAL = "movement-material",
  MANUAL_MATERIAL = "manual-material",
  OUTPUT_SEMI = "output-semi-products",
  OUTPUT_PRODUCTS = "output-products",
  OTHER_EXPENSES = "other-expenses",
  SHIPMENT_PRODUCTS = "shipment-products",
  SALES_PRODUCTS = "sales-products",
  RETURN_PRODUCTS = "return-products",
  RETURN_CONCRETE_DRIVERS = "return-concrete-drivers",
  ARRIVAL_CASH = "arrival-cash",
  EXPENSE_CASH = "expense-cash",
  REGISTER_PARISH_CASH = "register-parish-cash",
  REGISTER_EXPENSE_CASH = "register-expense-cash",
  OFFSET_ADVANCE = "offset-advance",
  CALCULATE_PRODUCTS = "calculate-products",
  CLOSE_MONTH = "close-month",
  MOVING_CASH = "moving-cash",
  MANUAL_POSTINGS = "manual-postings",
  CHANGE_SEMI_PRODUCT = "change-semi-product",
  CHANGE_PRODUCT = "change-product",
  SUBSTANDARD_PRODUCT = "substitute-products",
  ADD_PURCHASE_CONTRACTS = "add-purchase-contract",
  ADD_REGIST_SALES = "add-regist-sales",
  ADD_ORDER_REGISTER = "add-order-register",
  ADD_RECEIVED_ROWMAT = "add-received-rowmat",
  ADD_RETURN_MATERIAL = "add-return-material",
  ADD_MOVEMENT_MATERIAL = "add-movement-material",
  ADD_MANUAL_MATERIAL = "add-manual-material",
  ADD_SEMI_PRODUCT = "add-semi-product",
  ADD_RELEASE_PRODUCT = "add-release-product",
  ADD_SHIPMENT_PRODUCT = "add-shipment-product",
  ADD_SALES_PRODUCT = "add-sales-product",
  ADD_OTHER_EXPENSES = "add-other-expenses",
  ADD_RETURN_PRODUCTS = "add-return-products",
  ADD_ARRIVAL_CASH = "add-arrival-cash",
  ADD_REGISTER_PARISH_CASH = "add-register-parish-cash",
  RETURN_PRODUCT_BEFORE_REAL = "return-product-before-real",
  ADD_RETURN_PRODUCT_BEFORE_REAL = "add-return-before-real",
  ADD_COSTS_IN_CASH = "add-costs-in-cash",
  ADD_RETURN_CONCRETE_DRIVERS = "add_return-concrete-drivers",
  ADD_REGISTER_EXPENSE_CASH = "add_register-expense-cash",
  ADD_OFFSET_ADVANCE = "add-offset-advance",
  ADD_CALCULATE_PRODUCT = "add-calculate-product",
  ADD_CLOSE_MONTH = "add-close-month",
  ADD_MANUAL_POSTING = "add-manual-posting",
  ADD_SUBSTANDARD_PRODUCT = "add-substandard-product",
  ADD_SEMI_CHANGE_PRODUCT = "add-semi-change-product",
  ADD_CHANGE_PRODUCT = "add-change-product",
  ADD_MOVING_CASH = "add-moving-cash",
}
export enum Kassa {
  KASSA = "kassa-table",
}

export enum Registers {
  PIVOT_TABLE = "pivot-table",
  REMAINS = "remains",
}

export enum Reports {
  REGISTER_SLICES_CONTRACT = "register-slices-constraint",
  REGISTER_CASH_RECEIPTS = "register-cash-receipts",
  REGISTER_CASH_EXPENSE = "register-cash-expense",
  OPERATIONAL_REPORT_CASH = "operational-cash-report",
  WORK_ORDER_REGISTER = "work-order-register",
  REPORT_DEFECTS = "report-defects",
  REGISTER_PURCHASES_REPORT = "register-purchases-material",
  REGISTER_INVOICE = "register-invoice",
  MATERIAL_REPORT = "material-report",
  OPERATIONAL_REPORT = "operational-report",
  CASH_REPORT = "cash-report",
  BUYERS_REPORT = "buyers-report",
  ACCOUNTABLE_REPORT = "accountable-report",
  SUPPLIERS_REPORT = "suppliers-report",
  REPORT_RELEASE = "release-report",
  REPORT_SHIPMENT = "shipment-report",
  REPORT_FINISHED = "report-finished",
  REPORT_SOLD = "report-sold",
  ANALYSIS_EXECUTION = "analysis-execution",
  REPORT_DRIVERS = "report_drivers",
  REPORT_PURCHASE = "concrete-purchase-from-drivers",
  COMMODITY_REPORT = "community-report",
  RECONCILIATION_ACT = "reconciliation_act",
  BALANCE_SHEET = "balance-sheet",
  PROCUREMENT_PLAN = "procurement-plan",
  REPORT_SEMI_PRODUCTS = "report-semi-products",
  REPORT_BALANCE_SHEET_ITEM = "report-balance-sheet-item",
  DRIVERS_SHIPMENT_MATERIAL = "drivers-shipment-material",
  OPERATIONAL_REPORT_BALANCE = "operational-report-balance",
}

export enum Handbooks {
  BANKS = "banks",
  BANK_ACCOUNTS = "account-accounts",
  CHECKOUT = "check-out",
  TYPES_PHYSICAL_PERSONS = "types-physical-persons",
  TYPES_LEGAL_PERSONS = "types-legal-persons",
  PHYSICAL_PERSONS = "physical-persons",
  LEGAL_PERSONS = "legal-persons",
  COUNTERPARTS = "counterparts",
  BANK_COUNTERPARTS = "bank-counterparts",
  DELIVERY_ADDRESSES = "delivery-addresses",
  TERMS_PAYMENT = "terms-payment",
  WAREHOUSES = "warehouses",
  TERRITORIES = "territories",
  UNITS_MEASUREMENT = "units-measurement",
  DISCOUNTS = "discounts",
  TYPE_PRODUCT = "type-product",
  PRODUCT = "product",
  PRICE = "price",
  CALCULATION = "calculation",
  TYPE_MATERIAL = "type-material",
  SEMI_PRODUCTS = "semi-products",
  SEMI_CALCULATION = "semi-calculation",
  RAW_MATERIALS = "raw-materials",
  MATERIAL_PRICE = "material-price",
  VAT_RATES = "vat-rates",
  ITEMS_EXPENDITURE = "items-expenditure",
  TRUCK_TYPE = "trick-type",
  TRUCK = "trick",
  DRIVERS = "drivers",
  USERS = "users",
  DETAILS_COMPANY = "details-company",
  SUB_ACCOUNT = "sub-account",
  MANAGEMENT_ACCOUNTS = "management-accounts",
}
export enum Auth {
  LOG_IN = "login",
  // to be continue...
}

export enum Enumerations {
  PAYMENT_METHOD = "payment-methods",
  DELIVER_METHOD = "deliver-methods",
  TYPE_INCOME = "type-income",
  TYPE_FLOW = "type-flow",
  WRITEOFF_TYPE = "writeoff-type",
  LEGAL_INDIVIDUAL = "legal-individual",
  TYPE_CONTRACT = "type-contract",
  ROUND_ORDER = "round-order",
  TYPE_SUBSTANDARD = "type-standard",
}

export enum Users {
  USER_ROLE = "user-role",
  TYPE_ROLE = "type-role",
  LOG_USERS = "log-users",
  ADD_USER_ROLE = "add-user-role",
  ADD_TYPE_ROLE = "add-type-role",
  SHOW_LOG = "show-log",
}
// to be continue...
