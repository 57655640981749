import React, { memo, useState, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { FormInputWrapper, InputWrapper } from "./style";
import Title from "components/Title/Title";
import Text from "components/Text/Text";

const TextArea = ({
  label,
  placeholder,
  handleOnChange,
  type,
  clear = "",
  handleOnBlur,
  error,
  name,
  handleInput = (e: any) => {},
  disabled = false,
  required = false,
  id,
  ...props
}: any) => {
  const [val, setVal] = useState<any>(clear);

  const handleChange = (e: any) => {
    setVal(e.target.value);
    handleInput(e.target.value);
  };

  useEffect(() => {
    if (clear.length == 0) {
      setVal(clear);
    }
  }, [clear]);
  return (
    <FormInputWrapper {...props}>
      <Row align={"center"} justify={"end"} className="mt-24">
        <Col xs={4}>
          <Title>{label}</Title>
        </Col>
        <Col xs={8}>
          <InputWrapper
            disabled={disabled}
            onWheel={(e: any) => {
              e.currentTarget.blur();
            }}
            value={val || ""}
            placeholder={placeholder}
            onChange={handleChange}
            {...props}
          />
        </Col>
      </Row>
    </FormInputWrapper>
  );
};

export default memo(TextArea);
