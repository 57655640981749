import styled, { css } from "styled-components";
import { StyleProps } from "./type";

const TextWrapper = styled.span<StyleProps>`
  display: inline-block;
  color: #585757;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  padding: ${({ padding }: StyleProps) => padding || "0px"};
  margin: ${({ margin }: StyleProps) => margin || "0px"};
  min-width: ${({ minWidth }: StyleProps) => minWidth || "0px"};
  text-overflow: ellipsis;
  ${({ danger }: StyleProps) =>
    danger &&
    css`
      color: #e92c2c;
    `};
  ${({ tangerine }: StyleProps) =>
    tangerine &&
    css`
      color: #f79e1b;
    `};
  ${({ dark }: StyleProps) =>
    dark &&
    css`
      color: #1e1e1e;
    `};
  ${({ light }: StyleProps) =>
    light &&
    css`
      color: #fff;
    `};

  ${({ gray }: StyleProps) =>
    gray &&
    css`
      color: #969696;
    `};

  ${({ success }: StyleProps) =>
    success &&
    css`
      color: #53ac92;
    `};

  ${({ primary }: StyleProps) =>
    primary &&
    css`
      color: #0052b4;
    `};

  ${({ green }: StyleProps) =>
    green &&
    css`
      color: #00a241;
    `};

  ${({ medium }: StyleProps) =>
    medium &&
    css`
      font-weight: 500;
    `};

  ${({ bold }: StyleProps) =>
    bold &&
    css`
      font-weight: 700;
    `};

  ${({ large }: StyleProps) =>
    large &&
    css`
      font-size: 18px;
    `};

  ${({ norm }: StyleProps) =>
    norm &&
    css`
      font-size: 15px;
    `};

  ${({ xl }: StyleProps) =>
    xl &&
    css`
      font-size: 20px;
    `};

  ${({ xxl }: StyleProps) =>
    xxl &&
    css`
      font-size: 24px;
    `};

  ${({ small }: StyleProps) =>
    small &&
    css`
      font-size: 14px;
    `};

  ${({ xs }: StyleProps) =>
    xs &&
    css`
      font-size: 12px;
    `};
`;

export { TextWrapper };
