import { createSlice } from "@reduxjs/toolkit";
import {
  Documents,
  Enumerations,
  Handbooks,
  Main,
  Registers,
  Reports,
  Users,
} from "routers/paths";
import registerIcon from "assets/images/icons/register.svg";
import receiving from "assets/images/icons/receiving.svg";
import outProducts from "assets/images/icons/out_product.svg";
import shipping from "assets/images/icons/shipping.svg";
import sales from "assets/images/icons/sale_product.svg";
import pivotTable from "assets/images/icons/pivot_table.svg";
import remains from "assets/images/icons/remains.svg";
import salesContracts from "assets/images/icons/sales_contracts.svg";
import editIcon from "assets/images/icons/edit_icon.svg";
import return_material from "assets/images/icons/return_material.svg";
import movement from "assets/images/icons/movement.svg";
import manual from "assets/images/icons/manual.svg";
import semi_products from "assets/images/icons/semi_product.svg";
import costs from "assets/images/icons/costs.svg";
import return_substandard from "assets/images/icons/return_substandard.svg";
import drivers from "assets/images/icons/drivers.svg";
import arrival_cash from "assets/images/icons/arrival_cash.svg";
import expense from "assets/images/icons/expense.svg";
import import_cash from "assets/images/icons/import_cash.svg";
import export_cash from "assets/images/icons/export_cash.svg";
import offset from "assets/images/icons/offset.svg";
import calculate from "assets/images/icons/calculate.svg";
import closing from "assets/images/icons/closing.svg";
import manual_export from "assets/images/icons/manual_export.svg";
import reports from "assets/images/icons/reports.svg";
import operational from "assets/images/icons/operational.svg";
import analyses_reports from "assets/images/icons/analysis_reports.svg";
import commodity from "assets/images/icons/commodity.svg";
import register_table from "assets/images/icons/register_slices_table.svg";
import work_order from "assets/images/icons/work_order.svg";
import plan from "assets/images/icons/plan.svg";
import register_cash_table from "assets/images/icons/register_cash_table.svg";
import register_cash_expense from "assets/images/icons/register_cash_expense.svg";
import report_defects from "assets/images/icons/report_defects.svg";
import register_puchases from "assets/images/icons/register_purchases_materials.svg";
import report_drivers from "assets/images/icons/report_drivers.svg";
import report_purchase from "assets/images/icons/report_purchase.svg";
import operational_cash from "assets/images/icons/operational_cash.svg";
import cash_report from "assets/images/icons/cash_report.svg";
import buyers_report from "assets/images/icons/buyers_report.svg";
import suppliers_report from "assets/images/icons/suppliers_report.svg";
import accountable_report from "assets/images/icons/accountable_report.svg";
import reconciliation_act from "assets/images/icons/reconciliation_act.svg";
import userRole from "assets/images/icons/user_role.svg";
import typeRole from "assets/images/icons/type_role.svg";
import change_semi from "assets/images/icons/change_semi.svg";
import change_prod from "assets/images/icons/change_product.svg";
import log_users from "assets/images/icons/log_users.svg";
import moving_cash from "assets/images/icons/moving_cash.svg";
import drivers_material from "assets/images/icons/drivers_material.svg";
import operational_balance from "assets/images/icons/operational_balance.svg";
import otchet_not_real from "assets/images/icons/Finished.svg";
import otchet_real from "assets/images/icons/soldFinished.svg";

const initialState: any = {
  menus: [
    {
      name: "Главная",
      department: "GLAVNAYA",
      id: Main.HOME,
      pages: [
        {
          page: "GLAVNAYA",
          id: Main.HOME,
        },
      ],
    },
    {
      name: "Документы",
      department: "DOKUMENTI",
      id: Main.DOCUMENTS,
      pages: [
        {
          name: "Регистрация договоров на продажу",
          page: "DOG_PRODAJA",
          id: Documents.SALES_CONTRACTS,
          icon: salesContracts,
        },
        {
          name: "Оформление Заказ-Наряда",
          page: "ZAKAZ_NARYAD",
          id: Documents.REGISTER_ORDERS,
          icon: registerIcon,
          stroke: true,
        },
        {
          name: "Регистрация договоров на покупку",
          page: "DOG_POKUPKA",
          id: Documents.REGISTER_PURCHASES,
          icon: editIcon,
        },
        {
          name: "Поступление сырья и материалов",
          page: "PRIXOD_SM",
          id: Documents.RECEIVING_MATERIAL,
          icon: receiving,
        },
        {
          name: "Возврат сырья и материалов",
          page: "VOZVRAT_SM",
          id: Documents.RETURN_MATERIAL,
          icon: return_material,
          stroke: true,
        },
        {
          name: "Перемещение сырья и материалов",
          page: "PEREMESH_SM",
          id: Documents.MOVEMENT_MATERIAL,
          icon: movement,
        },
        {
          name: "Ручное списание сырья и материалов",
          page: "RUCHNOY_SPISANIYA",
          id: Documents.MANUAL_MATERIAL,
          icon: manual_export,
        },
        {
          name: "Выпуск полуфабрикатов",
          page: "VIPUSK_PF",
          id: Documents.OUTPUT_SEMI,
          icon: semi_products,
        },
        {
          name: "Изменение параметров полуфабрикатов на складе",
          page: "IZMENENIE_PF",
          id: Documents.CHANGE_SEMI_PRODUCT,
          icon: change_semi,
        },
        {
          name: "Выпуск готовой продукции",
          page: "VIPUSK_GP",
          id: Documents.OUTPUT_PRODUCTS,
          icon: outProducts,
        },
        {
          name: "Изменение параметров готовой прод. на складе",
          page: "IZMENENIE_GP",
          id: Documents.CHANGE_PRODUCT,
          icon: change_prod,
        },
        {
          name: "Ввод прочих производственных расходов",
          page: "PROCHI_RASXOD",
          id: Documents.OTHER_EXPENSES,
          icon: costs,
        },
        {
          name: "Отгрузка готовой продукции",
          page: "OTGRUZKA_GP",
          id: Documents.SHIPMENT_PRODUCTS,
          icon: shipping,
        },
        {
          name: "Возврат некондиционного товара (до реал.)",
          page: "VOZVRAT_TOV_OGP",
          id: Documents.RETURN_PRODUCT_BEFORE_REAL,
          icon: return_substandard,
          stroke: true,
        },
        {
          name: "Реализация готовой продукции",
          page: "REALIZATSIYA_GP",
          id: Documents.SALES_PRODUCTS,
          icon: sales,
          stroke: true,
        },
        {
          name: "Возврат некондиционного товара (после реал.)",
          page: "VOZVRAT_TOV_RGP",
          id: Documents.RETURN_PRODUCTS,
          icon: return_substandard,
          stroke: true,
        },
        {
          name: "Приобретение бетона у водителей",
          page: "PRIOBR_BET",
          id: Documents.RETURN_CONCRETE_DRIVERS,
          icon: drivers,
        },
        {
          name: "Приход наличных денег",
          page: "KASSA_DOC",
          id: Documents.ARRIVAL_CASH,
          icon: arrival_cash,
          stroke: true,
        },
        {
          name: "Расход наличный денег",
          page: "KASSA_DOC",
          id: Documents.EXPENSE_CASH,
          icon: expense,
          stroke: true,
        },
        {
          name: "Регистрация прихода безналичных денег",
          page: "PRIXOD_BD",
          id: Documents.REGISTER_PARISH_CASH,
          icon: import_cash,
        },
        {
          name: "Регистрация расхода безналичных денег",
          page: "RASXOD_BD",
          id: Documents.REGISTER_EXPENSE_CASH,
          icon: export_cash,
        },
        {
          name: "Зачет аванса покупателей",
          page: "ZACHET_AVANS",
          id: Documents.OFFSET_ADVANCE,
          icon: offset,
        },
        {
          name: "Расчет себестоимости готовой продукции",
          page: "SEBEST",
          id: Documents.CALCULATE_PRODUCTS,
          icon: calculate,
        },
        {
          name: "Закрытие месяца",
          page: "ZAKR_MES",
          id: Documents.CLOSE_MONTH,
          icon: closing,
          stroke: true,
        },
        {
          name: "Ручные проводки",
          page: "RUCH_PROV",
          id: Documents.MANUAL_POSTINGS,
          icon: manual,
        },
        {
          name: "Списание некондиционного товара (брака)",
          page: "SPISANIYA_BRAK",
          id: Documents.SUBSTANDARD_PRODUCT,
          icon: report_defects,
          stroke: true,
        },
        {
          name: "Перемещение наличных денег",
          page: "PEREMESH_ND",
          id: Documents.MOVING_CASH,
          icon: moving_cash,
        },
      ],
    },
    {
      name: "Касса",
      department: "KASSA",
      id: Main.KASSA,
    },
    {
      name: "Регистры",
      department: "REGISTERI",
      id: Main.REGISTERS,
      pages: [
        {
          name: "Сводная таблица",
          id: Registers.PIVOT_TABLE,
          page: "REGISTERI",
          icon: pivotTable,
        },
        {
          name: "Остатки",
          id: Registers.REMAINS,
          page: "REGISTERI",
          icon: remains,
        },
      ],
    },
    {
      name: "Отчеты",
      department: "OTCHETI",
      id: Main.REPORTS,
      pages: [
        {
          name: "Реестр договоров на продажу",
          id: Reports.REGISTER_SLICES_CONTRACT,
          page: "REESTR_DOG_PRODAJA",
          icon: register_table,
        },
        {
          name: "Реестр Заказ-нарядов",
          id: Reports.WORK_ORDER_REGISTER,
          page: "REESTR_ZAKAZ_NARYAD",
          icon: work_order,
          stroke: true,
        },
        {
          name: "Реестр счетов фактур",
          id: Reports.REGISTER_INVOICE,
          page: "REESTR_SCHET_FAKTUR",
          icon: work_order,
          stroke: true,
        },
        {
          name: "Отчет по некондиционным товарам и браку",
          page: "OTCHET_BRAK_TOVAR",
          id: Reports.REPORT_DEFECTS,
          icon: report_defects,
          stroke: true,
        },
        {
          name: "Реестр договоров на покупку сырья и материалов",
          page: "REESTR_DOG_POKUPKA",
          id: Reports.REGISTER_PURCHASES_REPORT,
          icon: register_puchases,
        },
        {
          name: "Материальный отчет по Склад сырья и материалов",
          page: "MATERIAL_PO_SKLAD",
          id: Reports.MATERIAL_REPORT,
          icon: reports,
        },
        {
          name: "Оперативный отчет по остаткам сырья и материалов",
          page: "MATERIAL_PO_OSTATKA",
          id: Reports.OPERATIONAL_REPORT,
          icon: operational,
        },
        {
          name: "План закупок ТМЦ (на основании объёма заказов)",
          page: "PLAN_ZAKUPOK",
          id: Reports.PROCUREMENT_PLAN,
          icon: plan,
        },
        {
          name: "Отчет по выпуску полуфабрикатов",
          page: "OTCHET_VIPUSK_PF",
          id: Reports.REPORT_SEMI_PRODUCTS,
          icon: semi_products,
        },
        {
          name: "Отчет по выпуску готовой продукции",
          page: "OTCHET_VIPUSK_GP",
          id: Reports.REPORT_RELEASE,
          icon: outProducts,
        },
        {
          name: "Отчет по отгрузке готовой продукции",
          page: "OTCHET_OTGRUZKA_GP",
          id: Reports.REPORT_SHIPMENT,
          icon: movement,
        },
        {
          name: "Отчет о нереализованной готовой продукции",
          page: "OTCHET_NOT_REALIZATSIYA_GP",
          id: Reports.REPORT_FINISHED,
          icon: otchet_not_real,
          stroke: true,
        },
        {
          name: " Отчет о реализованной готовой продукции",
          page: "OTCHET_REALIZATSIYA_GP",
          id: Reports.REPORT_SOLD,
          icon: otchet_real,
        },
        {
          name: "Оперативный отчет по анализу выполнения Заказ-наряда",
          page: "ZN_ANALIZ",
          id: Reports.ANALYSIS_EXECUTION,
          icon: analyses_reports,
        },
        {
          name: "Отчет по выработке водителей при отгрузке готовой продукции",
          page: "VODITELI_PRI_OTRGUZKA",
          id: Reports.REPORT_DRIVERS,
          icon: report_drivers,
        },
        {
          name: "Отчет по выработке водителей при отгрузке сырья и материалов",
          page: "REESTR_PRIXOD_SM",
          id: Reports.DRIVERS_SHIPMENT_MATERIAL,
          icon: drivers_material,
        },
        {
          name: "Отчет по приобретенному у водителей товарному бетону",
          page: "VODITELI_PRIOBR_BET",
          id: Reports.REPORT_PURCHASE,
          icon: report_purchase,
        },
        {
          name: "Товарный отчет по Склад готовой продукции",
          page: "TOVAR_PO_SKLAD",
          id: Reports.COMMODITY_REPORT,
          icon: commodity,
        },
        {
          name: "Оперативный отчет по остаткам готовой продукции",
          page: "TOVAR_PO_OSTATKA",
          id: Reports.OPERATIONAL_REPORT_BALANCE,
          icon: operational_balance,
        },
        {
          name: "Реестр документов по приходу наличных денежных средств",
          page: "REESTR_PRIXOD_ND",
          id: Reports.REGISTER_CASH_RECEIPTS,
          icon: register_cash_table,
          stroke: true,
        },
        {
          name: "Реестр документов по расходу наличных денежных средств",
          page: "REESTR_RASXOD_ND",
          id: Reports.REGISTER_CASH_EXPENSE,
          icon: register_cash_expense,
          stroke: true,
        },
        {
          name: "Оперативный отчет по движению наличных денежных средств",
          page: "DVIJENIYU_ND",
          id: Reports.OPERATIONAL_REPORT_CASH,
          icon: operational_cash,
          stroke: true,
        },
        {
          name: "Кассовый отчет",
          page: "KASSOVIY_OTCHET",
          id: Reports.CASH_REPORT,
          icon: cash_report,
          stroke: true,
        },
        {
          name: "Отчет по задолженности покупателей",
          page: "ZADOLJENNOST_POKUPATELEY",
          id: Reports.BUYERS_REPORT,
          icon: buyers_report,
          stroke: true,
        },
        {
          name: "Отчет по учету подотчетных сумм",
          page: "PODOTCHETNIX_SUM",
          id: Reports.ACCOUNTABLE_REPORT,
          icon: accountable_report,
          stroke: true,
        },
        {
          name: "Отчет по задолженности поставщикам",
          page: "ZADOLJENNOST_POSTAVSHIKAM",
          id: Reports.SUPPLIERS_REPORT,
          icon: suppliers_report,
          stroke: true,
        },
        {
          name: "Акт-сверки взаиморасчетов",
          page: "AKT_SVERKI",
          id: Reports.RECONCILIATION_ACT,
          icon: reconciliation_act,
        },
        {
          name: "Оборотно-сальдовая ведомость",
          page: "SALDO_VEDOMOST",
          id: Reports.BALANCE_SHEET,
          icon: registerIcon,
          stroke: true,
        },
      ],
    },
    {
      name: "Справочники",
      id: Main.HANDBOOK,
      department: "SPRAVOCHNIKI",
      pages: [
        {
          name: "Банки",
          page: "BANKI",
          id: Handbooks.BANKS,
        },
        {
          name: "Банковские счета",
          page: "BANK_SCHET",
          id: Handbooks.BANK_ACCOUNTS,
        },
        {
          name: "Касса",
          page: "KASSA",
          id: Handbooks.CHECKOUT,
        },
        {
          name: "Типы физических лиц",
          page: "TIP_FIZ_LITSA",
          id: Handbooks.TYPES_PHYSICAL_PERSONS,
        },
        {
          name: "Типы юридических лиц",
          page: "TIP_YUR_LITSA",
          id: Handbooks.TYPES_LEGAL_PERSONS,
        },
        {
          name: "Физические лица",
          page: "FIZ_LITSA",
          id: Handbooks.PHYSICAL_PERSONS,
        },
        {
          name: "Юридические лица",
          page: "YUR_LITSA",
          id: Handbooks.LEGAL_PERSONS,
        },
        {
          name: "Контрагенты",
          page: "KONTRAGENT",
          id: Handbooks.COUNTERPARTS,
        },
        {
          name: "Банковские счета контрагентов",
          page: "BANK_SCHET_KONTRAGENT",
          id: Handbooks.BANK_COUNTERPARTS,
        },
        {
          name: "Адреса доставки",
          page: "ADDRESS_DOSTAVKI",
          id: Handbooks.DELIVERY_ADDRESSES,
        },
        {
          name: "Условия оплаты",
          page: "USLOVIYA_OPLATA",
          id: Handbooks.TERMS_PAYMENT,
        },
        {
          name: "Склады",
          page: "SKLAD",
          id: Handbooks.WAREHOUSES,
        },
        {
          name: "Территория",
          page: "TERRITORIYA",
          id: Handbooks.TERRITORIES,
        },
        {
          name: "Единицы измерения",
          page: "ED_IZM",
          id: Handbooks.UNITS_MEASUREMENT,
        },
        {
          name: "Скидки",
          page: "SKIDKA",
          id: Handbooks.DISCOUNTS,
        },
        {
          name: "Вид товара",
          page: "VID_TOVARA",
          id: Handbooks.TYPE_PRODUCT,
        },
        {
          name: "Товар",
          page: "TOVAR",
          id: Handbooks.PRODUCT,
        },
        {
          name: "Цены",
          page: "TOVAR_SENI",
          id: Handbooks.PRICE,
        },
        {
          name: "Калькуляция товара",
          page: "KALKULYATSIYA_TOV",
          id: Handbooks.CALCULATION,
        },
        {
          name: "Вид материала",
          page: "VID_MATERIALA",
          id: Handbooks.TYPE_MATERIAL,
        },
        {
          name: "Сырьё и материалы",
          page: "SM",
          id: Handbooks.RAW_MATERIALS,
        },
        {
          name: "Цены материалов",
          page: "MATERIAL_SENI",
          id: Handbooks.MATERIAL_PRICE,
        },
        {
          name: "Полуфабрикаты",
          page: "PF",
          id: Handbooks.SEMI_PRODUCTS,
        },
        {
          name: "Калькуляция полуфабриката",
          page: "KALKULYATSIYA_PF",
          id: Handbooks.SEMI_CALCULATION,
        },
        {
          name: "Ставки НДС",
          page: "STAVKA_NDS",
          id: Handbooks.VAT_RATES,
        },
        {
          name: "Статьи расхода",
          page: "STATYA_RASXODA",
          id: Handbooks.ITEMS_EXPENDITURE,
        },
        {
          name: "Тип автомобиля",
          page: "TIP_AVTO",
          id: Handbooks.TRUCK_TYPE,
        },
        {
          name: "Автомобили",
          page: "AVTO",
          id: Handbooks.TRUCK,
        },
        {
          name: "Водители",
          page: "VODITELI",
          id: Handbooks.DRIVERS,
        },
        {
          name: "Пользователи",
          page: "POLZOVATELI",
          id: Handbooks.USERS,
        },
        {
          name: "Реквизиты предприятия (компании)",
          page: "KOMPANIYA",
          id: Handbooks.DETAILS_COMPANY,
        },
        {
          name: "Субконто",
          page: "SUBKONTO",
          id: Handbooks.SUB_ACCOUNT,
        },
        {
          name: "Счета управленческого учета",
          page: "SCHET_SUBKONTO",
          id: Handbooks.MANAGEMENT_ACCOUNTS,
        },
      ],
    },
    {
      name: "Перечисления",
      department: "PERECHISLENIYA",
      id: Main.ENUMERATIONS,
      pages: [
        {
          name: "Способ оплаты",
          id: Enumerations.PAYMENT_METHOD,
          page: "SPOSOB_OPLATI",
        },
        {
          name: "Способ доставки",
          id: Enumerations.DELIVER_METHOD,
          page: "SPOSOB_DOSTAVKI",
        },
        {
          name: "Тип прихода",
          id: Enumerations.TYPE_INCOME,
          page: "TIP_PRIXODA",
        },
        {
          name: "Тип расхода",
          id: Enumerations.TYPE_FLOW,
          page: "TIP_RASXODA",
        },
        {
          name: "Тип списания",
          id: Enumerations.WRITEOFF_TYPE,
          page: "TIP_SPISANIYA_SM",
        },
        {
          name: "Тип списания \n некондиционного товара",
          id: Enumerations.TYPE_SUBSTANDARD,
          page: "TIP_SPISANIYA_BRAK",
        },
        {
          name: "Юр/Физ лицо",
          id: Enumerations.LEGAL_INDIVIDUAL,
          page: "TIP_YUR_FIZ_LITSA",
        },
        {
          name: "Тип договора",
          id: Enumerations.TYPE_CONTRACT,
          page: "TIP_DOGOVORA",
        },
        {
          name: "Порядок округления",
          id: Enumerations.ROUND_ORDER,
          page: "PORYADOK_OKRUGULENIYA",
        },
      ],
    },
    {
      name: "Роли и права",
      department: "POLZAVATELI",
      id: Main.USERS,
      pages: [
        {
          name: "Роль пользователя",
          id: Users.USER_ROLE,
          icon: userRole,
        },
        {
          name: "Тип роли",
          id: Users.TYPE_ROLE,

          icon: typeRole,
        },
        {
          name: "Логи",
          id: Users.LOG_USERS,
          icon: log_users,
        },
      ],
    },
  ],
  // not getting access directly
  others: [
    {
      name: "Добавить",
      id: Documents.ADD_REGIST_SALES,
    },
    {
      name: "Добавить",
      id: Documents.ADD_ORDER_REGISTER,
    },
    {
      name: "Добавить",
      id: Documents.ADD_PURCHASE_CONTRACTS,
    },
    {
      name: "Добавить",
      id: Documents.ADD_RECEIVED_ROWMAT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_RETURN_MATERIAL,
    },
    {
      name: "Добавить",
      id: Documents.ADD_MOVEMENT_MATERIAL,
    },
    {
      name: "Добавить",
      id: Documents.ADD_MANUAL_MATERIAL,
    },
    {
      name: "Добавить",
      id: Documents.ADD_SEMI_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_RELEASE_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_SHIPMENT_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_SALES_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_OTHER_EXPENSES,
    },
    {
      name: "Добавить",
      id: Documents.ADD_RETURN_PRODUCTS,
    },
    {
      name: "Добавить",
      id: Documents.ADD_ARRIVAL_CASH,
    },
    {
      name: "Добавить",
      id: Documents.ADD_COSTS_IN_CASH,
    },
    {
      name: "Добавить",
      id: Documents.ADD_RETURN_PRODUCT_BEFORE_REAL,
    },
    {
      name: "Добавить",
      id: Documents.ADD_REGISTER_PARISH_CASH,
    },
    {
      name: "Добавить",
      id: Documents.ADD_RETURN_CONCRETE_DRIVERS,
    },
    {
      name: "Добавить",
      id: Documents.ADD_REGISTER_EXPENSE_CASH,
    },
    {
      name: "Добавить",
      id: Documents.ADD_CALCULATE_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_OFFSET_ADVANCE,
    },
    {
      name: "Добавить",
      id: Documents.ADD_CALCULATE_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_CLOSE_MONTH,
    },
    {
      name: "Добавить",
      id: Documents.ADD_MANUAL_POSTING,
    },
    {
      name: "Добавить",
      id: Documents.ADD_SUBSTANDARD_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_CHANGE_PRODUCT,
    },
    {
      name: "Добавить",
      id: Documents.ADD_SEMI_CHANGE_PRODUCT,
    },
    // users
    {
      name: "Добавить",
      id: Users.ADD_USER_ROLE,
    },
    {
      name: "Добавить",
      id: Users.ADD_TYPE_ROLE,
    },
    {
      name: "",
      id: Reports.REPORT_BALANCE_SHEET_ITEM,
    },
    {
      name: "",
      id: Documents.ADD_MOVING_CASH,
    },
  ],
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
});

export default menuSlice.reducer;
