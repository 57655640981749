import { createSelector } from "@reduxjs/toolkit";
import {
  selectPivotTable,
  selectPivotTableFilter,
  selectPivotTableStatic,
  selectRemainTable,
  selectRemainTableFilter,
  selectRemainTableStatic,
} from "app/selectors/registers/index";

export const pivotTableData = createSelector(
  selectPivotTable,
  (pivotData) => pivotData
);
export const pivotTableFilter = createSelector(
  selectPivotTableFilter,
  (filter) => filter
);

export const pivotTableStatic = createSelector(
  selectPivotTableStatic,
  (dataList) => dataList
);

export const remainTableData = createSelector(
  selectRemainTable,
  (data) => data
);
export const remainTableFilter = createSelector(
  selectRemainTableFilter,
  (filter) => filter
);
export const remainTableStatisticData = createSelector(
  selectRemainTableStatic,
  (data) => data
);
