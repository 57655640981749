import { createSelector } from "@reduxjs/toolkit";
import {
  selectAllBanks,
  selectAllFizList,
  selectAllKassa,
  selectAllKassaInfo,
  selectAllYurList,
  selectCounterpart,
  selectCounterpartById,
  selectCounterpartOptions,
  selectCounterpartVipuskPf,
  selectDeliveryAddress,
  selectDiscount,
  selectDrivers,
  selectLargePerson,
  selectMenageAccounts,
  selectPhysPerson,
  selectProduct,
  selectRateVAT,
  selectRawMaterial,
  selectSemiCalculationMaterials,
  selectSkladGP,
  selectTermsPayment,
  selectTerritories,
  selectTruck,
  selectTypeMaterial,
  selectTypeProduct,
  selectTypeTruck,
  selectUnitsMeasurement,
  selectUsers,
  selectWarHouse,
  selectWorkshop,
  seletSkladCM,
} from "app/selectors/handbooks/handbookSelectors";
import { BankDataType } from "app/slices/handbookSlices/bankSlices/type";
import { counterpartsDataType } from "app/slices/handbookSlices/counterpartSlices/type";
import { DeliveryAddressDataType } from "app/slices/handbookSlices/deleveryAddressSlices/type";
import { discountDataType } from "app/slices/handbookSlices/discountsSlices/type";
import { FizLitsDataType } from "app/slices/handbookSlices/FizLitsSlices/type";
import { KassaDataType } from "app/slices/handbookSlices/kassaSlice/type";
import { LargePersonDataType } from "app/slices/handbookSlices/largePersonSlices/type";
import { PhysPersonDataType } from "app/slices/handbookSlices/physPersonSlices/type";
import { ProductDataType } from "app/slices/handbookSlices/productSlices/type";
import { RawMaterialDataType } from "app/slices/handbookSlices/rawMaterialSlices/type";
import { TermsPaymentDataType } from "app/slices/handbookSlices/temsPaymentSlices/type";
import { TerritoriesDataType } from "app/slices/handbookSlices/territoriesSlices/type";
import { TruckDataType } from "app/slices/handbookSlices/truckSlices/type";
import { TypeMaterialDataType } from "app/slices/handbookSlices/typeMaterialSlices/type";
import { TypeProductDataType } from "app/slices/handbookSlices/typeProductSlices/type";
import { TypeTruckDataType } from "app/slices/handbookSlices/typeTruckSlices/type";
import { UnitsMeasurementType } from "app/slices/handbookSlices/unitsMeasurementSlices/type";
import {
  SkladItemType,
  WareHouseDataType,
} from "app/slices/handbookSlices/wareHouseSlices/type";
import { YurLitsDataType } from "app/slices/handbookSlices/yurLitsSlices/type";
import { get, isEmpty } from "lodash";

// get app banks
export const getallBanks = createSelector(selectAllBanks, (data) =>
  get(data, "banksList", []).map((item: BankDataType) => {
    return {
      label: get(item, "mfo", ""),
      imya: get(item, "imya", ""),
      value: get(item, "id", 0),
    };
  })
);
//  get type fiz  lits
export const getTypeFizLits = createSelector(selectAllFizList, (data) =>
  get(data, "dataList", []).map((item: FizLitsDataType) => {
    return {
      label: get(item, "tipFizLitsa", ""),
      value: get(item, "id", 0),
    };
  })
);
//  get type Yur list
export const getTypeYurList = createSelector(selectAllYurList, (dataList) =>
  get(dataList, "yurListData", []).map((item: YurLitsDataType) => {
    return {
      label: get(item, "tipYurLitsa", ""),
      value: get(item, "id", 0),
    };
  })
);
// get phys person
export const getPhysPersonData = createSelector(selectPhysPerson, (dataList) =>
  get(dataList, "dataList", []).map((item: PhysPersonDataType) => {
    return {
      label: get(item, "imyaFizLitsa", ""),
      value: get(item, "id", 0),
    };
  })
);
//  terms payemnt
export const getTermsPaymentData = createSelector(
  selectTermsPayment,
  (dataList) =>
    get(dataList, "dataList", []).map((item: TermsPaymentDataType) => ({
      label: get(item, "imyaUsloviyaOplati", ""),
      value: get(item, "id", 0),
    }))
);
// get Large Phys Person data

export const yurFizPersonData = createSelector(
  [selectPhysPerson, selectLargePerson, (store, id) => id],
  (phyList, largeList, id) => {
    switch (id) {
      case 1:
        return get(largeList, "dataList", []).map(
          (item: LargePersonDataType) => ({
            label: get(item, "imyaYurLitsa", ""),
            value: get(item, "id", ""),
            fullName: get(item, "polnoeImyaYurLitsa", ""),
            inn: get(item, "inn", null),
            nds: get(item, "nds", null),
            oked: get(item, "oked", null),
            telNomer: get(item, "telNomer", null),
            dir: get(item, "dir", null),
            glavbuh: get(item, "glavbuh", null),
            kommentariya: get(item, "kommentariya", null),
            address: get(item, "address", null),
          })
        );
      case 2:
        return get(phyList, "dataList", []).map((item: PhysPersonDataType) => ({
          label: get(item, "imyaFizLitsa", ""),
          value: get(item, "id", ""),
          createdBy: get(item, "createdBy", ""),
          polnoeImyaFizLitsa: get(item, "polnoeImyaFizLitsa", null),
          telNomer: get(item, "telNomer", null),
          tipiFizLitsaId: get(item, "tipiFizLitsaId", 1),
        }));
      default:
        return [];
    }
  }
);
// get counterpart data
export let loadingConterpartVipuskPF = createSelector(
  selectCounterpartVipuskPf,
  (dataList) => dataList.loading
);
export const getCounterpartDataById = createSelector(
  selectCounterpartById,
  (data) => data
)
export const getCounterpartVipuskPF = createSelector(
  selectCounterpartVipuskPf,
  (dataList) =>
    get(dataList, "data", []).map((item: counterpartsDataType) => {
      return {
        label: get(item, "imyaKontragent", ""),
        value: get(item, "id", 0),
      };
    })
);
export const getCounterpartData = createSelector(
  selectCounterpart,
  (dataList) =>
    get(dataList, "dataList", []).map((item: counterpartsDataType) => {
      return {
        label: get(item, "imyaKontragent", ""),
        value: get(item, "id", 0),
      };
    })
);
export const getCounterpartOptions = createSelector(
  selectCounterpartOptions,
  (dataOptions) =>
    get(dataOptions, "data", []).map((item: counterpartsDataType) => {
      return {
        label: get(item, "imyaKontragent", ""),
        value: get(item, "id", 0),
      };
    })
);
export let loadingAllSklad = createSelector(
  selectWarHouse,
  (data) => data.loading
);
//  get wereHouse data - sklad api
export const getWarHouseData = createSelector(selectWarHouse, (dataList) =>
  get(dataList, "dataList", []).map((item: WareHouseDataType) => {
    return {
      label: get(item, "imyaSklada", ""),
      value: get(item, "id", 0),
      imyaFizLitsa: get(item, "imyaFizLitsa", ""),
    };
  })
);

export const getSkladGP = createSelector(selectSkladGP, (data) =>
  get(data, "data", []).map((item: SkladItemType) => ({
    label: get(item, "imyaSklada", ""),
    value: get(item, "id", 0),
    imyafizList: get(item, "imyaFizLitsa", ""),
    fizListsId: get(item, "fizLitsaId", null),
  }))
);
export let loadingSklad = createSelector(seletSkladCM, (data) =>
  get(data, "loading", false)
);
export const getSkladCM = createSelector(seletSkladCM, (data) =>
  get(data, "data", []).map((item: SkladItemType) => ({
    label: get(item, "imyaSklada", ""),
    value: get(item, "id", 0),
    imyafizList: get(item, "imyaFizLitsa", ""),
    fizListsId: get(item, "fizLitsaId", null),
  }))
);
export let loadingTerritroy = createSelector(selectTerritories, (data) =>
  get(data, "loading", false)
);
// get territory data
export const getTerritoriesData = createSelector(
  selectTerritories,
  (territoriesData) =>
    get(territoriesData, "territoriesData", []).map(
      (item: TerritoriesDataType) => {
        return {
          label: get(item, "imyaTerritorii", ""),
          value: get(item, "id", 0),
          skladCMId: get(item, "skladSMId", null),
          imyaSkladSM: get(item, "imyaSkladSM", null),
          imyaSkladGP: get(item, "imyaSkladGP", null),
          skladGPId: get(item, "skladGPId", null),
        };
      }
    )
);
//  get type product
export const getTypeProductData = createSelector(
  selectTypeProduct,
  (typeProductData) =>
    get(typeProductData, "typeProductData", []).map(
      (item: TypeProductDataType) => {
        return {
          label: get(item, "imya", ""),
          value: get(item, "id", 0),
        };
      }
    )
);
//   units measurement
export const getUnitsMeasurementData = createSelector(
  selectUnitsMeasurement,
  (unitsMeasurementData) =>
    get(unitsMeasurementData, "unitsMeasurementData", []).map(
      (item: UnitsMeasurementType) => {
        return {
          label: get(item, "imyaEdIzm", ""),
          value: get(item, "id", 0),
        };
      }
    )
);
export let loadingProductionData = createSelector(
  selectProduct,
  (productData) => productData.loading
);
// get product  fetchProduct
export const getProductData = createSelector(selectProduct, (productData) =>
  get(productData, "productData", []).map((item: ProductDataType) => {
    return {
      label: get(item, "polnoeImya", ""),
      value: get(item, "id", null),
      edIzmId: get(item, "edIzmId", null),
      imyaEdIzm: get(item, "imyaEdIzm", null),
    };
  })
);

export const getTypeMaterialsData = createSelector(
  selectTypeMaterial,

  (typeMaterialData) =>
    get(typeMaterialData, "typeMaterialData", []).map(
      (item: TypeMaterialDataType) => {
        return {
          label: get(item, "imyaVidMateriala", 0),
          value: get(item, "id", 0),
        };
      }
    )
);
// get  Materials

// delete the list of materials that are currently selected in the calculation -
// полуфабрикат
export let loadingRawMaterial = createSelector(selectRawMaterial, (data) =>
  get(data, "loading", false)
);
export const getMaterialDataForCol = createSelector(
  [selectRawMaterial, selectSemiCalculationMaterials],
  (materialData, material) => {
    let removeMaterialList: any = [];
    get(material, "data.kalkulyatsiyaDetailsList", []).map((item: any) => {
      if (item) {
        removeMaterialList.push(item.materialId);
      }
    });
    const data = get(materialData, "materialData", []).map(
      (item: RawMaterialDataType) => ({
        label: get(item, "polnoyeImyaMateriala", ""),
        value: get(item, "id", null),
        imyaEdIzm: get(item, "imyaEdIzm", null),
        edIzmId: get(item, "edIzmId", null),
        vidMaterialId: get(item, "vidMaterialId", null),
      })
    );
    return data.filter((item: any) => !removeMaterialList.includes(item.value));
  }
);

export const getMaterialData = createSelector(
  selectRawMaterial,
  (materialData) =>
    get(materialData, "materialData", []).map((item: RawMaterialDataType) => {
      return {
        label: get(item, "polnoyeImyaMateriala", ""),
        value: get(item, "id", null),
        imyaEdIzm: get(item, "imyaEdIzm", null),
        edIzmId: get(item, "edIzmId", null),
        vidMaterialId: get(item, "vidMaterialId", null),
      };
    })
);

// get type Trucks
export const getTypeTruckData = createSelector(selectTypeTruck, (dataTruck) =>
  get(dataTruck, "typeTruckData", []).map((item: TypeTruckDataType) => {
    return {
      label: get(item, "imya", ""),
      value: get(item, "id", null),
    };
  })
);

// get trucks
export const loadingTruck = createSelector(selectTruck, (data) => data.loading);
export const getTruckData = createSelector(selectTruck, (dataTruck) =>
  get(dataTruck, "truckData", []).map((item: TruckDataType) => {
    return {
      label: get(item, "gosNomer", ""),
      value: get(item, "id", null),
      imya: get(item, "imya", ""),
    };
  })
);

export const getKassaData = createSelector(selectAllKassa, (data) =>
  get(data, "kassaList", []).map((item: KassaDataType) => {
    return {
      label: get(item, "imya", ""),
      value: get(item, "id", null),
    };
  })
);

export const getAllKassa = createSelector(selectAllKassaInfo, (data) =>
  get(data, "data", []).map((item: KassaDataType) => {
    return {
      label: get(item, "imya", ""),
      value: get(item, "id", null),
    };
  })
);
export const loadingDeliverAddress = createSelector(
  selectDeliveryAddress,
  (data) => data.loading
);
export const getDeliveryData = createSelector(
  selectDeliveryAddress,
  (delivery) =>
    get(delivery, "dataList", []).map((item: DeliveryAddressDataType) => {
      return {
        label: get(item, "polnoeImenaAddress", ""),
        value: get(item, "id", null),
      };
    })
);
//  get sexa
export const getWorkShopData = createSelector(selectWorkshop, (workshopData) =>
  get(workshopData, "workshopData", []).map((item) => {
    return {
      label: get(item, "imyaSexa", ""),
      value: get(item, "id", null),
      skladGP: get(item, "skladGPId", null),
      skladSP: get(item, "skladSexaId", null),
    };
  })
);

// get menage account
export const getDebutCreditData = createSelector(selectMenageAccounts, (data) =>
  get(data, "menageAccountData", []).map((item) => {
    return {
      label: get(item, "nomerScheta", ""),
      value: get(item, "nomerScheta", ""),
      subkonto1: get(item, "subkonto1Id", null),
      subkonto2: get(item, "subkonto2Id", null),
      subkonto3: get(item, "subkonto3Id", null),
      kol: get(item, "kol", null),
    };
  })
);
export let loadingDiscount = createSelector(
  selectDiscount,
  (data) => data.loading
);
// get discount
export const getDiscountData = createSelector(selectDiscount, (data) =>
  get(data, "discountData", []).map((item: discountDataType) => {
    return {
      label: `${get(item, "imya", "")}`,
      value: get(item, "id", null),
      prosent: get(item, "prosent", null),
      okruglineyaMetod: get(item, "okruglineyaMetod", ""),
    };
  })
);

// drivers
export let loadingDrivers = createSelector(
  selectDrivers,
  (data) => data.loading
);
export const getDriversData = createSelector(selectDrivers, (data) =>
  get(data, "driversData", []).map((item) => ({
    label: get(item, "imya", ""),
    value: get(item, "id", null),
    avto: get(item, "avtoId", null),
    gosNomer: get(item, "gosNomer", null),
    gosNomerId: get(item, "avtoId", null),
  }))
);

export const getVatRateData = createSelector(selectRateVAT, (data) =>
  get(data, "rateData", []).map((item) => ({
    label: get(item, "stavkaNDSImya", ""),
    value: get(item, "id", null),
    percentage: get(item, "stavka", 0),
  }))
);

// users
export const getUsersDataList = createSelector(selectUsers, (data) =>
  get(data, "usersData", []).map((item) => ({
    label: get(item, "imya", ""),
    value: get(item, "id", null),
    avto: get(item, "avtoId", null),
    gosNomer: get(item, "gosNomer", null),
    gosNomerId: get(item, "avtoId", null),
  }))
);
