import { useAppDispatch, useAppSelector } from "app/hook";
import {
  changeSupplierReport,
  clearSupplierReport,
  fetchSupplierReport,
  filterSupplierReport,
} from "app/slices/reportSlices/supplierReportSlices/supplierReportSlices";
import { supplierReportFilter, supplierReportList } from "app/states/reports";
import { Button, Content, DatePicker, FlexBox, Title } from "components";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import ReportInfoTitle from "../components/ReportInfoTitle";
import SuppliersReportTable from "../components/SuppliersReportTable";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

// Отчет по задолженности поставщикам
function SuppliersReportContainer() {
  const dispatch = useAppDispatch();

  // data
  const data = useAppSelector(supplierReportList);
  const filter = useAppSelector(supplierReportFilter);
  const LOADING_TABLE = get(data, "loading", false);
  const fetchAllSupplierReport = useCallback(() => {
    dispatch(fetchSupplierReport(filter));
  }, [dispatch, filter]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearSupplierReport());
  };

  //excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Отчет по задолженности поставщикам ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `I1`);
    sheet.getCell("A1").value = `Отчет по задолженности поставщикам с  ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    sheet.getRow(3).height = 20; // C3 qatridagi balandlik
    sheet.getRow(4).height = 20; // C4 qatridagi balandlik
    sheet.getRow(1).height = 20;
    sheet.mergeCells("A3", `A4`);
    sheet.getCell("A3").value = "№";
    sheet.mergeCells("B3", "B4");
    sheet.getCell("B3").value = "Контрагент";
    sheet.mergeCells("C3", "C4");
    sheet.getCell("C3").value = "Договор";
    sheet.mergeCells("D3", "E3");
    sheet.getCell("D3").value = "Начальный остаток";
    sheet.getCell("D4").value = "Дебет";
    sheet.getCell("E4").value = "Кредит";
    sheet.mergeCells("F3", "G3");
    sheet.getCell("F3").value = "Оборот";
    sheet.getCell("F4").value = "Дебет";
    sheet.getCell("G4").value = "Кредит";
    sheet.mergeCells("H3", "I3");
    sheet.getCell("H3").value = "Конечный остаток";
    sheet.getCell("H4").value = "Дебет";
    sheet.getCell("I4").value = "Кредит";
    //123
    // sheet.mergeCells('J3', 'K3');
    // sheet.getCell('J3').value = 'Конечный остаток'
    // sheet.getCell('J4').value = 'Количество'
    // sheet.getCell('K4').value = 'Сумма'
    let count = 1;
    sheet.getCell("A3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("B3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("D4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("F4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("H4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.columns = [
      { width: 30 },
      { width: 60 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const tableHeader = [
      {
        header: "Код",
        key: "index",
        width: 5,
      },
      {
        header: "Наименованиесчета",
        key: "imyaTovara",
        width: 60,
      },
      {
        header: "Дебет",
        key: "imyaEdIzm",
        width: 30,
      },
      {
        header: "Kredit",
        key: "nachalniyOstatok1",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "nachalniyOstatok2",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "prixod1",
        width: 30,
      },
      {
        header: "konechniyOstatokDebit",
        key: "prixod2",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "rasxod1",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "rasxod2",
        width: 30,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    // sheet.getRow(5).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(4);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList.list", [])?.forEach((item: any, index: number) => {
      const rowde = sheet.addRow({
        index: index + 1,
        imyaTovara: get(item, "imyaKontragent", ""),
        imyaEdIzm: "ㅤ",
        nachalniyOstatok1: Number(get(item, "totalNachalniyOstatok.debit", "")),
        nachalniyOstatok2: Number(get(item, "totalNachalniyOstatok.kredit", 0)),
        prixod1: Number(get(item, "totalOborot.debit", 0)),
        prixod2: Number(get(item, "totalOborot.kredit", 0)),
        rasxod1: Number(get(item, "totalKonechniyOstatok.debit", 0)),
        rasxod2: Number(get(item, "totalKonechniyOstatok.kredit", 0)),
        // konechniyOstatok1: get(item, 'konechniyOstatok.kolichestva', '').toLocaleString(),
        // konechniyOstatok2: get(item, 'konechniyOstatok.summa', '').toLocaleString()
      });
      counterRow += 1;
      get(item, "details", []).forEach((detail: any, id: number) => {
        const row = sheet.addRow({
          index: "ㅤ",
          imyaTovara: "ㅤ",
          imyaEdIzm: get(detail, "nomerDogPokupka", ""),
          nachalniyOstatok1: Number(get(detail, "nachalniyOstatok.debit", 0)),
          nachalniyOstatok2: Number(get(detail, "nachalniyOstatok.kredit", 0)),
          prixod1: Number(get(detail, "oborot.debit", 0)),
          prixod2: Number(get(detail, "oborot.kredit", 0)),
          rasxod1: Number(get(detail, "konechniyOstatok.debit", 0)),
          rasxod2: Number(get(detail, "konechniyOstatok.kredit", 0)),
        });
        counterRow++;
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f4f4f4" }, // Replace 'FFFF0000' with your desired color
          };
        });
        const headerRow1 = sheet.getRow(index + id + 5);
        // row.height = 25;
        // row.alignment = { vertical: "middle", horizontal: "center" };
      });

      // rowde.height = 25;
      // rowde.alignment = { vertical: "middle", horizontal: "center" };
      counterRow++;
    });

    sheet.mergeCells(
      `A${counterRow + count - 1}`,
      `C${counterRow + count - 1}`
    );
    sheet.getCell(`A${counterRow + count - 1}`).value = "Итого: ";
    sheet.getCell(`D${counterRow + count - 1}`).value = Number(
      get(data, "dataList.itogo.NachalniyDebit", "")
    );
    sheet.getCell(`E${counterRow + count - 1}`).value = Number(
      get(data, "dataList.itogo.NachalniyKredit", 0)
    );
    sheet.getCell(`F${counterRow + count - 1}`).value = Number(
      get(data, "dataList.itogo.OborotDebit", 0)
    );
    sheet.getCell(`G${counterRow + count - 1}`).value = Number(
      get(data, "dataList.itogo.OborotKredit", 0)
    );
    sheet.getCell(`H${counterRow + count - 1}`).value = Number(
      get(data, "dataList.itogo.KonechniyDebit", 0)
    );
    sheet.getCell(`I${counterRow + count - 1}`).value = Number(
      get(data, "dataList.itogo.KonechniyKredit", 0)
    );
    // sheet.getRow(counterRow + count - 1).height = 25;
    sheet.getRow(counterRow + count - 1).alignment = {
      vertical: "middle",
      // horizontal: "center",
    };
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      "H" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),
      "I" + (count + 3),
      "J" + (count + 3),
      "K" + (count + 3),

      "A" + (count + counterRow),
      "B" + (count + counterRow),
      "C" + (count + counterRow),
      "D" + (count + counterRow),
      "E" + (count + counterRow),
      "F" + (count + counterRow),
      "G" + (count + counterRow),
      "H" + (count + counterRow),
      "I" + (count + counterRow),
      "J" + (count + counterRow),
      "K" + (count + counterRow),

      "A" + (counterRow + count - 1),
      "B" + (counterRow + count - 1),
      "C" + (counterRow + count - 1),
      "D" + (counterRow + count - 1),
      "E" + (counterRow + count - 1),
      "F" + (counterRow + count - 1),
      "G" + (counterRow + count - 1),
      "H" + (counterRow + count - 1),
      "I" + (counterRow + count - 1),
      // "G" + (count + 3),
      // "H" + (count + 3),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`I${counterRow + count - 1}`);
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach((item: any) => {
      sheet.getCell(item).font = {
        size: 11,
        bold: true,
      };
    });
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeSupplierReport(null));
    }

    dispatch(filterSupplierReport({ ...filter, name, value }));
  };

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  placeholder="Дата начало"
                  value={get(filter, "fromDate", "")}
                  width="150px"
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  placeholder="Дата конец"
                  width="150px"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: string) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right px-16"} xs={6}>
              {!isEmpty(get(data, "dataList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllSupplierReport}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    titleTxt={"Отчет по задолженности поставщикам"}
                  />

                  {!isEmpty(get(data, "dataList.list", [])) ? (
                    <SuppliersReportTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
}

export default SuppliersReportContainer;
