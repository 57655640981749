import { memo } from "react";
import { Modal } from 'antd';
// import { ModalContent, ModalWrapper } from "./style";
import { Props } from "./type";

const ReactModal = ({ children, isOpen, width, ...props }: Props) => {

    return (
        <>
            <Modal
                width={width ? `${width}` : "600px"}
                open={isOpen}
                centered
                footer={null}
                closable={false}
                bodyStyle={{ padding: 0 }}
            >
                <div className="p-8">
                    {children}
                </div>
            </Modal>
        </>
    );
};

export default memo(ReactModal);
