import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";

const initialState: any = {
  statistic: {
    loading: false,
    data: [],
    error: "",
  },
  filter: {
    tipRasxodaId: null,
    fromDate: null,
    toDate: null,
    tipKassa: null,
    voditeliId: null,
    page: 0,
    size: 10,
  },
  costsInCashes: {
    loading: false,
    data: [],
    error: "",
  },
  costsInCashesTemp: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchAllCostsInCash = createAsyncThunk(
  "costsInCash/fetchAllCostsInCash",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.GetCostsInCash(params);
      const respond = await request.data;
      const { data, pagination } = await respond;
      return { data, pagination };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllCostsInCashTemp = createAsyncThunk(
  "costsInCash/fetchAllCostsInCashTemp",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetCostsInCashTemp();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllCostsInCashStat = createAsyncThunk(
  "costsInCash/fetchAllCostsInCashStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.GetCostsInCashStat();
      const respond = await request.data;
      const { data } = await respond;
      return { data };
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const costsInCashSlice = createSlice({
  name: "costsInCash",
  initialState,
  reducers: {
    handleFilterChangeRko: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    handleFilterChange: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFilterCash: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCostsInCash.pending, (state) => {
      state.costsInCashes = {
        loading: true,
      };
    });
    builder.addCase(fetchAllCostsInCash.fulfilled, (state, actions) => {
      state.costsInCashes = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllCostsInCash.rejected, (state, actions) => {
      state.costsInCashes = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });

    builder.addCase(fetchAllCostsInCashTemp.pending, (state) => {
      state.costsInCashesTemp = {
        loading: true,
      };
    });
    builder.addCase(fetchAllCostsInCashTemp.fulfilled, (state, actions) => {
      state.costsInCashesTemp = {
        loading: false,
        data: get(actions, "payload", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllCostsInCashTemp.rejected, (state, actions) => {
      state.costsInCashesTemp = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
    // statistic
    builder.addCase(fetchAllCostsInCashStat.pending, (state) => {
      state.statistic = {
        loading: true,
      };
    });
    builder.addCase(fetchAllCostsInCashStat.fulfilled, (state, actions) => {
      state.statistic = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchAllCostsInCashStat.rejected, (state, actions) => {
      state.statistic = {
        loading: false,
        data: {},
        error: get(actions, "error.message", ""),
      };
    });
  },
});

export const { handleFilterChangeRko, handleFilterChange, clearFilterCash } = costsInCashSlice.actions;
export default costsInCashSlice.reducer;
