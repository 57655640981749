import React, { useEffect, useCallback, useState } from "react";

import moment from "moment";
import { get, isEmpty, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import { useAppDispatch, useAppSelector } from "app/hook";
import { getKassaData } from "app/states/handbooks/selectStates";
import { reportCashFilter, reportCashList } from "app/states/reports";
import {
  fetchAllKassa,
  fetchOwnKassa,
} from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import {
  changeCashReportTable,
  clearCashReportTable,
  fetRegisterchCashReport,
  filterCashReportTable,
} from "app/slices/reportSlices/registerCashReceiptsSlices/registerCashReceiptsSlices";

import { RegisterCashReceiptsTable } from "../components";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";

type ParamsType = {
  name?: string;
  clear?: boolean;
  value: any;
};

interface ParamState {
  kassa?: string;
}

// Реестр документов по приходу наличных денежных средств
const RegisterCashReceiptsContainer = () => {
  const dispatch = useAppDispatch();
  // data
  const allKassa = useAppSelector(getKassaData);
  const filter = useAppSelector(reportCashFilter);
  const data = useAppSelector(reportCashList);
  const LOADING_TABLE = get(data, "loading", []);

  const [details, setDetails] = useState<ParamState>({
    kassa: "",
  });
  // excel download
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Реестр документов по приходу наличных денежных средств ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `H1`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    // rowCompany.alignment = { vertical: "middle", horizontal: "center" };

    const detailsArray = [
      {
        value: get(details, "kassa", ""),
        label: `Касса: ${get(details, "kassa", "")}`,
      },
    ];
    // names of companies
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `Реестр документов по приходу наличных денежных средств с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    detailsArray.forEach((val, index) => {
      if (val.value != "") {
        sheet.mergeCells(`A${1 + index + 1}`, `H${1 + index + 1}`);
        sheet.getCell(`A${1 + index + 1}`).value = val.label;
        count++;
      }
    });
    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Номер документа",
        key: "nomerDoc",
        width: 30,
      },
      {
        header: "Дата документа",
        key: "dataDoc",
        width: 25,
      },
      {
        header: "Контрагент",
        key: "imyaKontragent",
        width: 35,
      },
      {
        header: "Договор",
        key: "nomerDoc1",
        width: 30,
      },
      {
        header: "Тип прихода",
        key: "imyaTipPrixoda",
        width: 30,
      },
      {
        header: "Сумма",
        key: "summa",
        width: 20,
      },
      {
        header: "Комментарий",
        key: "kommentariya",
        width: 50,
      },
    ];
    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList.pkoDTOList", [])?.forEach(
      (item: any, index: number) => {
        sheet.addRow({
          order: index + 1,
          nomerDoc: get(item, "nomerDoc", ""),
          dataDoc: get(item, "dataDoc", ""),
          imyaKontragent: get(item, "imyaKontragent", ""),
          nomerDoc1: get(item, "nomerDogProdaja", "")
            ? get(item, "nomerDogProdaja", "")
            : " ",
          imyaTipPrixoda: get(item, "imyaTipPrixoda", ""),
          summa: Number(get(item, "summa", 0)),
          kommentariya: get(item, "kommentariya", ""),
        });
        counterRow++;
      }
    );
    sheet.mergeCells(
      `A${counterRow + count + 2}`,
      `F${counterRow + count + 2}`
    );
    sheet.getCell(`A${counterRow + count + 2}`).value = "Итого:";
    sheet.getCell(`G${counterRow + count + 2}`).value = Number(
      get(data, "dataList.itogoSumma", 0)
    );

    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `G${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`H${counterRow + count + 2}`);

    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
      (sheet.getCell(item).font = {
        size: 11,
        bold: true,
      })
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const fetchAllCashReport = useCallback(() => {
    if (filter.kassaId) dispatch(fetRegisterchCashReport(filter));
  }, [dispatch, filter]);

  // handle filter
  const handleClearFilter = () => {
    dispatch(clearCashReportTable());

    setDetails({
      kassa: "",
    });
  };

  // change filter
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeCashReportTable(null));
    }

    dispatch(filterCashReportTable({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnKassa({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"12px"}>
                <DatePicker
                  placeholder="Дата начало"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "fromDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />

                <DatePicker
                  placeholder="Дата конец"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e: any) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />

                <BaseSelect
                  options={allKassa}
                  placeholder="Касса"
                  value={get(filter, "kassaId", "")}
                  handleValueLabel={(e: any) => {
                    handleChangeFilter({
                      name: "kassaId",
                      value: e.value,
                      clear: true,
                    });

                    setDetails({
                      kassa: get(e, "label", ""),
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "dataList.pkoDTOList", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button
                green
                className={"mr-16"}
                handleClick={fetchAllCashReport}
              >
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.dataList !== null ? (
                <>
                  <ReportInfoTitle
                    details={details}
                    titleTxt={
                      "Реестр документов по приходу наличных денежных средств"
                    }
                  />
                  {!isEmpty(get(data, "dataList.pkoDTOList", [])) ? (
                    <RegisterCashReceiptsTable data={data} />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default RegisterCashReceiptsContainer;
