import { useAppDispatch, useAppSelector } from "app/hook";
import { YurLitsDataType } from "app/slices/handbookSlices/yurLitsSlices/type";
import {
  clearFilterYurLits,
  fetchAllYurList,
  handleYurLitsFilter,
} from "app/slices/handbookSlices/yurLitsSlices/yurLitsSlices";
import { allYurListData, allYurListFilter } from "app/states/handbooks";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  Form,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Title,
  WindowHeight,
} from "components";
import ReactModal from "components/Modal/Modal";
import { useFormik } from "formik";
import { get, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import HandBookServices from "services/apiServices/spravochnik";
import { indexNumber } from "utilities/helpers";
// Типы юридических лиц
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  afterSubmitAction,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  afterSubmitAction: () => void;
}) => {
  const onSubmit = () => {
    try {
      HandBookServices.AddNewYurLits({
        data: values,
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
      }).then((res) => {
        if (res && res.data) {
          handleCloseModal();
          afterSubmitAction();
          resetForm();
        }
      });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      tipYurLitsa: "",
    },
    onSubmit,
  });
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Реквизиты
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center">
            <Col xs={4}>
              <Title>Наименование типа</Title>
            </Col>
            <Col xs={8}>
              <BaseInput
                clear={get(values, "tipYurLitsa", "")}
                handleInput={(e: string) => {
                  setFieldValue("tipYurLitsa", e);
                }}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type="submit" green>
              Добавить
            </Button>
            <Button onClick={handleCloseModal} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const TypesLegalPersonsContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const data = useAppSelector(allYurListData);
  let LOADING = get(data, "loading", false);
  const filter = useAppSelector(allYurListFilter);

  const dispatchFilter = useCallback(
    ({ name, value }: { name: string; value: string | number }) => {
      dispatch(
        handleYurLitsFilter({
          name,
          value,
        })
      );
    },
    [dispatch]
  );

  const handleFilter = useCallback(() => {
    dispatch(fetchAllYurList(filter));
  }, [dispatch, filter]);

  const clearFilter = useCallback(() => {
    dispatch(fetchAllYurList({ ...filter, search: "" }));
    dispatch(clearFilterYurLits()
    
    );
  }, [dispatch, filter]);

  const fetchAllYurListData = useCallback(() => {
    dispatch(fetchAllYurList(filter));
  }, [dispatch]);

  useEffect(() => {
    handleFilter();
  }, [filter.page, filter.size]);
  useEffect(() => {
    return () => clearFilter();
  }, []);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row>
            {/* <Col className={"text-right"}> */}
            {/* <Button green className={"mb-16"} onClick={handleOpenModal}>
                + Добавить
              </Button> */}
            {/* </Col> */}
          </Row>
          <Row className={"py-16"}>
            <Col style={{ display: "flex", gap: "10px" }} xs={3}>
              <BaseInput
                clear={get(filter, "search", "")}
                placeholder="Поиск"
                handleInput={(e: string) => {
                  dispatchFilter({
                    name: "search",
                    value: e,
                  });
                }}
              />
            </Col>
            <Col className={"text-right"}>
              <Button primary className={"mr-16"} handleClick={handleFilter}>
                Применять
              </Button>
              <Button danger handleClick={clearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <ReactModal isOpen={openModal}>
            <AddNewDocToTable
              handleCloseModal={handleCloseModal}
              afterSubmitAction={() => fetchAllYurListData()}
            />
          </ReactModal>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "ID",
                    "Пользователь",
                    "Наименования типов юр.лиц",
                  ]}
                >
                  {!LOADING ? (
                    <>
                      {!isEmpty(get(data, "yurListData", [])) ? (
                        get(data, "yurListData", []).map(
                          (item: YurLitsDataType, index: number) => (
                            <tr key={item.id}>
                              <td>{get(item, "id", 0)}</td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "tipYurLitsa", "-")}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={3} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={3}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(data, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatchFilter({
                    name: "size",
                    value: e,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(data, "pagination.currentPageNumber", 0)}
                pageCount={get(data, "pagination.totalPages", 0)}
                onPageChange={({ selected }) =>
                  dispatchFilter({
                    name: "page",
                    value: selected,
                  })
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default TypesLegalPersonsContainer;
