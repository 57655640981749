import { Col, Row } from "react-grid-system";
import { TableWrapper } from "./styles/style";
import { get, isEmpty } from "lodash";
import { NumericFormat } from "react-number-format";
import { Title } from "components";

const BalanceSheetTable = ({ data, onRowClick, filter }: any) => {
  return (
    <Row className="mt-16">
      <Col>
        <Row>
          <Col className="responsive_overflow">
            <TableWrapper minWidth="1600px" className="mb-16">
              <thead>
                <tr>
                  <th rowSpan={2}>Код</th>
                  <th rowSpan={2} style={{ width: "30vh" }}>
                    Наименование счета
                  </th>
                  <th colSpan={2}>Сальдо на начало периода</th>
                  <th colSpan={2}>Обороты периода</th>
                  <th colSpan={2}>Сальдо на конец периода</th>
                </tr>
                <tr>
                  <th>Дебет</th>
                  <th>Кредит</th>
                  <th>Дебет</th>
                  <th>Кредит</th>
                  <th>Дебет</th>
                  <th>Кредит</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "start" }}>
                {!isEmpty(get(data, "balanceList.list", [])) ? (
                  get(data, "balanceList.list", []).map(
                    (item: any, index: number) => (
                      <tr key={index + 1} onClick={() => onRowClick(item)}>
                        <td>{get(item, "nomerScheta", "")}</td>
                        <td>{get(item, "imyaScheta", "")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "nachalniyOstatok.debit", 0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "nachalniyOstatok.kredit", 0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "oborot.debit", 0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "oborot.kredit", 0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "konechniyOstatok.debit", 0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "konechniyOstatok.kredit", 0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={8}>Виберите параметры</td>
                  </tr>
                )}
                {get(data, "balanceList.list", []).length !== 1 && (
                  <tr>
                    <td colSpan={2} style={{ textAlign: "start" }}>
                      <Title bold>Итого:</Title>
                    </td>
                    <td>
                      <Title bold>
                        <NumericFormat
                          displayType="text"
                          value={get(
                            data,
                            "balanceList.itogo.NachalniyDebit",
                            ""
                          )}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          decimalScale={2}
                        />
                      </Title>
                    </td>
                    <td>
                      <Title bold>
                        <NumericFormat
                          displayType="text"
                          value={get(
                            data,
                            "balanceList.itogo.NachalniyKredit",
                            ""
                          )}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          decimalScale={2}
                        />
                      </Title>
                    </td>
                    <td>
                      <Title bold>
                        <NumericFormat
                          displayType="text"
                          value={get(data, "balanceList.itogo.OborotDebit", "")}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          decimalScale={2}
                        />

                        {}
                      </Title>
                    </td>
                    <td>
                      <Title bold>
                        <NumericFormat
                          displayType="text"
                          value={get(
                            data,
                            "balanceList.itogo.OborotKredit",
                            ""
                          )}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          decimalScale={2}
                        />
                      </Title>
                    </td>
                    <td>
                      <Title bold>
                        <NumericFormat
                          displayType="text"
                          value={get(
                            data,
                            "balanceList.itogo.KonechniyDebit",
                            ""
                          )}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          decimalScale={2}
                        />
                      </Title>
                    </td>
                    <td>
                      <Title bold>
                        <NumericFormat
                          displayType="text"
                          value={get(
                            data,
                            "balanceList.itogo.KonechniyKredit",
                            ""
                          )}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          decimalScale={2}
                        />
                      </Title>
                    </td>
                  </tr>
                )}
              </tbody>
            </TableWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BalanceSheetTable;
