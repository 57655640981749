import { useAppDispatch, useAppSelector } from "app/hook";
import { filterChangeProduct } from "app/slices/documentSlices/changeProductSlices/changeProductSlice";
import {
  clearFilterSemiProduct,
  fetchChangeSemiProduct,
  fetchChangeSemiProductStat,
  fetchTempChangeSemiProduct,
  filterChangeSemiProduct,
} from "app/slices/documentSlices/changeSemiProductSlices/changeSemiProductSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  changeSemiProductFilter,
  changeSemiProductList,
  changeSemiProductStat,
  changeTempSemiProduct,
} from "app/states/documents";
import delate from "assets/images/icons/delate.svg";
import {
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";

//  Изменение параметров полуфабрикатов на складе

const ChangeSemiProductsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_SEMI_CHANGE_PRODUCT
  );
  const dispatch = useAppDispatch();
  const changeSemiProductData = useAppSelector(changeSemiProductList);
  const filter = useAppSelector(changeSemiProductFilter);
  const temp = useAppSelector(changeTempSemiProduct);
  const statistics = useAppSelector(changeSemiProductStat);
  const LOADING_TABLE = get(changeSemiProductData, "loading", []);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchAllChangeProducts = useCallback(() => {
    dispatch(fetchChangeSemiProduct(filter));
    dispatch(fetchChangeSemiProductStat());
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(clearFilterSemiProduct());
    dispatch(
      fetchChangeSemiProduct({
        ...filter,
        fromDate: null,
        toDate: null,
      })
    );
  }, [dispatch, filter]);

  const fetchAllTempsChangeProduct = useCallback(() => {
    dispatch(fetchTempChangeSemiProduct(filter));
  }, []);

  const navigateSaleContractForm = (params: {
    id: string;
    isTemp: boolean;
  }) => {
    navigate(Documents.ADD_SEMI_CHANGE_PRODUCT, {
      state: params,
    });
  };
  const handleAddButton = () => {
    navigate(Documents.ADD_SEMI_CHANGE_PRODUCT);
  };

  const handleDeleteTempButton = (id: string | null) => {
    DocumentServices.RemoveChangeSemiProductTemp(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        dispatch(fetchTempChangeSemiProduct({}));
      }
    });
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteChangeSemiProductById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllChangeProducts();
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoChangeSemiProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllChangeProducts();
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsChangeSemiProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllChangeProducts();
      }
    });
  };
  useEffect(() => {
    fetchAllChangeProducts();
  }, [filter.page, filter.size]);

  useEffect(() => {
    fetchAllTempsChangeProduct();
    return () => {
      handleClearFilter();
    };
  }, []);

  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row style={{ minHeight: "100%" }}>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={statistics} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger handleClick={handleClearFilter} className="mr-16">
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "IZMENENIE_PF_CREATE",
                    page: "IZMENENIE_PF",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={8}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  isClearable
                  width="200px"
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeSemiProduct({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  isClearable
                  width="200px"
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      filterChangeSemiProduct({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"}>
              <Button primary handleClick={fetchAllChangeProducts}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateSaleContractForm}
                  deleteDocument={handleDeleteButton}
                  deleteItem={true}
                  markItem={true}
                  uncheckItem={true}
                  userPermission={"IZMENENIE_PF"}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Контрагент_1",
                    "Заказ-наряд_1",
                    "Договор_1",
                    "Территория_1",
                    "Склад полуфабрикатов_1",
                    "Контрагент_2",
                    "Заказ-наряд_2",
                    "Договор_2",
                    "Территория_2",
                    "Склад полуфабрикатов_2",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      <>
                        {!isEmpty(get(temp, "data", [])) &&
                          get(temp, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", 0)}
                                onClick={() =>
                                  navigateSaleContractForm({
                                    isTemp: true,
                                    id: get(item, "id", ""),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  <div
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      handleDeleteTempButton(
                                        get(item, "id", null)
                                      );
                                    }}
                                    className="delete_button"
                                  >
                                    <ReactSVG
                                      src={delate}
                                      className="delete_button"
                                    />
                                  </div>
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "dataDoc", ""))
                                    ? moment(get(item, "dataDoc", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "imyaKontragent1", "")}</td>
                                <td>{get(item, "nomerZakazNaryad1", "")}</td>

                                <td>{get(item, "nomerDogProdaja1", "")}</td>
                                <td>{get(item, "imyaTerritoriya1", "")}</td>
                                <td>{get(item, "imyaSkladaPF1", "-")}</td>
                                <td>{get(item, "imyaKontragent2", "")}</td>
                                <td>{get(item, "nomerZakazNaryad2", "")}</td>

                                <td>{get(item, "nomerDogProdaja2", "")}</td>
                                <td>{get(item, "imyaTerritoriya2", "")}</td>
                                <td>{get(item, "imyaSkladaPF2", "-")}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "status", "")}</td>
                              </tr>
                            )
                          )}
                      </>
                      {!isEmpty(get(changeSemiProductData, "data", []))
                        ? get(changeSemiProductData, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={index + 1}
                                onClick={() =>
                                  navigateSaleContractForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      changeSemiProductData,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      changeSemiProductData,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>
                                  {!isEmpty(get(item, "dataDoc", ""))
                                    ? moment(get(item, "dataDoc", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "imyaKontragent1", "")}</td>
                                <td>{get(item, "nomerZakazNaryad1", "")}</td>
                                <td>{get(item, "nomerDogProdaja1", "")}</td>
                                <td>{get(item, "imyaTerritoriya1", "")}</td>
                                <td>{get(item, "imyaSkladaPF1", "-")}</td>
                                <td>{get(item, "imyaKontragent2", "")}</td>
                                <td>{get(item, "nomerZakazNaryad2", "")}</td>

                                <td>{get(item, "nomerDogProdaja2", "")}</td>
                                <td>{get(item, "imyaTerritoriya2", "")}</td>
                                <td>{get(item, "imyaSkladaPF2", "-")}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "status", "")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(temp, "data", [])) && (
                            <Nodata colLength={14} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={14}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(changeSemiProductData, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    filterChangeSemiProduct({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(
                  changeSemiProductData,
                  "pagination.currentPageNumber",
                  0
                )}
                pageCount={get(
                  changeSemiProductData,
                  "pagination.totalPages",
                  1
                )}
                onPageChange={({ selected }) =>
                  dispatch(
                    filterChangeSemiProduct({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default ChangeSemiProductsContainer;
