import { useAppSelector } from "app/hook";
import { navBarMenus } from "app/states/menu";
import {
  departmentList,
  dogPermissions,
  profileData,
} from "app/states/profile";
import { get, isEqual } from "lodash";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "./paths";

const ProtectedRoute = ({
  children,
  department,
  page,
}: {
  children?: any;
  department: string;
  page?: string;
}) => {
  const menus = useAppSelector(navBarMenus);
  const departmentData = useAppSelector(departmentList);
  const permissionDocument = useAppSelector(dogPermissions);
  const profile = useAppSelector(profileData);
  // const isAdmin = get(profile, "profileData.id", []);
  // const profile = useAppSelector(profileData);
  const isAdmin = useMemo(
    () => get(profile, "profileData.roleId", []),
    [profile]
  );
  const foundDepartment = permissionDocument.find(
    (item: any) => item.department == department
  );
  const permissionList = get(foundDepartment, "pages", []).find(
    (item: any) => get(item, "page", "") == page
  );
  let url: string = "/";
  // PUSH - check allow department page
  url = menus.find((item: any) => departmentData.includes(item.department))?.id;
  // check is admin
  if (isEqual(department, "POLZAVATELI") && isEqual(isAdmin, 1)) {
    return children;
  }
  // else {
  //   return <Navigate to={url} replace />;
  // }
  // check permission
  if (get(permissionList, "actions", []).includes(`${page}_READ`)) {
    return children;
  } else if (url) {
    // push first allow page or not found
    return <Navigate to={url} replace />;
  }
};

export default ProtectedRoute;
