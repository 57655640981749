import { useAppDispatch, useAppSelector } from "app/hook";
import {
  typeRoleDataList,
  userRoleFilter,
  userRolePermDetails,
  userRolePermissions,
} from "app/states/users";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BaseTable,
  Button,
  Checkbox,
  Content,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  Input,
  LoadingTable,
  MultipleSelect,
  Title,
} from "components";
import { Col, Row } from "react-grid-system";
import { fetchTypeRole } from "app/slices/usersSlices/typeRoleSlices/typeRoleSlices";
import { forEach, get, isEmpty, isEqual, map } from "lodash";
import { useStore } from "react-redux";
import UsersServices from "services/apiServices/users";
import { toast } from "react-toastify";
import { MESSAGES, PAGE } from "utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import col_action from "assets/images/icons/colaction.svg";
import row_action from "assets/images/icons/rowaction.svg";
import {
  clearUserRoleData,
  clearUserRoleDetails,
  fetchUserRole,
  fetchUserRolePermissions,
  handleAddUserRoleDetails,
  handleCheckUserDetails,
  pushUserRoleArray,
  removeUserRoleDetails,
} from "app/slices/usersSlices/userRoleSlices/userRoleSlices";
import { useFormik } from "formik";
import {
  getAllKassa,
  getKassaData,
  getTerritoriesData,
  getUsersDataList,
} from "app/states/handbooks/selectStates";
import { fetchTerritories } from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import { fetchAllKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { fetchUsers } from "app/slices/handbookSlices/usersSlices/usersSlices";
import { profileData } from "app/states/profile";
import { Spin } from "antd";

type ElementType = {
  action: string;
  checked: boolean;
  exists: boolean;
  permissionId: number;
};

type Object = {
  value: string | number;
  label: string;
};
const AddUserRoleContainer = () => {
  const dispatch = useAppDispatch();
  const permission = useAppSelector(userRolePermissions);
  const [action, setAction] = useState<number | null>(null);
  const filter = useAppSelector(userRoleFilter);
  const [roleTypeId, setRoleTypeId] = useState<string | number | null>("");
  const navigate = useNavigate();

  const LOADING_DATA = get(permission, "loading", false);
  const territory = useAppSelector(getTerritoriesData);
  const { state } = useLocation();
  const profile = useAppSelector(profileData);
  const usersOptions = useAppSelector(getUsersDataList);
  const detailsParm = useAppSelector(userRolePermDetails);
  const kassaOptions = useAppSelector(getAllKassa);
  const typeRoleOptions = useAppSelector(typeRoleDataList);
  const [territoryState, setTerritoryState] = useState([]);
  const [kassaState, setKassaState] = useState([]);
  const DATA = useMemo(
    () => get(permission, "permissionsData", []),
    [permission]
  );
  const isAdmin = get(profile, "profileData.id", []);

  const onSubmit = (values: any) => {
    UsersServices.AddUserRole({
      transactionTime: new Date(),
      resultCode: null,
      resultMsg: null,
      data: {
        ...values,
        id: get(state, "id", null),
        permissionIds: get(detailsParm, "permissionsIds", []),
      },
    })
      .then((res) => {
        setSubmitting(false);
        if (res.status === 200) {
          dispatch(clearUserRoleData());
          toast.success(MESSAGES.SEVED);
          dispatch(fetchUserRole(filter));
          if (isEqual(get(state, "id", null), isAdmin)) {
            window.location.reload();
          } else {
            navigate(-1);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const { values, handleSubmit, setFieldValue, isSubmitting, setSubmitting } =
    useFormik({
      initialValues: {
        roleId: get(state, "roleId", ""),
        kassaIds: kassaState,
        territoriyaIds: territoryState,
        polzovateliId: get(state, "polzovateliId", null),
      },
      enableReinitialize: true,
      onSubmit,
    });

  const filterState = useCallback(() => {
    const formatTerritory: Object | any = get(state, "territoriyaList", []).map(
      (item: any) => get(item, "id", null)
    );
    const formatKassa: Object | any = get(state, "kassaList", []).map(
      (item: any) => get(item, "id", null)
    );

    setKassaState(formatKassa);
    setTerritoryState(formatTerritory);
  }, [state]);
  const fetchById = useCallback(() => {
    // new add
    if (roleTypeId) {
      dispatch(fetchUserRolePermissions({ roleId: roleTypeId }));
    } else if (roleTypeId && isEmpty(get(state, "id", null))) {
      dispatch(fetchUserRolePermissions({ roleId: roleTypeId }));
    }
    // by update
    else if (get(state, "id", null)) {
      dispatch(fetchUserRolePermissions({ userId: get(state, "id", null) }));
    }
  }, [values.roleId, roleTypeId, state]);

  const fetchDetails = useCallback(() => {
    if (
      get(permission, "permissionsData", []) &&
      roleTypeId &&
      isEmpty(get(state, "id", false))
    ) {
      dispatch(clearUserRoleDetails());
      get(permission, "permissionsData", []).map((outItem: any) => {
        get(outItem, "pages", []).map((middleItem: any) => {
          get(middleItem, "actions", []).map((action: any) => {
            if (get(action, "checked", false)) {
              dispatch(
                handleAddUserRoleDetails({
                  id: get(action, "permissionId", null),
                })
              );
            }
          });
        });
      });
    } else if (
      get(permission, "permissionsData", []) &&
      get(state, "id", false)
    ) {
      get(permission, "permissionsData", []).map((outItem: any) => {
        get(outItem, "pages", []).map((middleItem: any) => {
          get(middleItem, "actions", []).map((action: any) => {
            if (get(action, "checked", false)) {
              dispatch(
                handleAddUserRoleDetails({
                  id: get(action, "permissionId", null),
                })
              );
            }
          });
        });
      });
    }
  }, [get(permission, "permissionsData", []), state, roleTypeId]);

  const pushArrayDetails = useCallback((id: any) => {
    const array = [];
    if (id) {
      array.push(id);
      dispatch(pushUserRoleArray(array));
    }
  }, []);
  const fetchPermissionCall = useCallback(() => {
    dispatch(fetchUserRolePermissions({}));
    dispatch(fetchTerritories({ all: true }));
    dispatch(fetchAllKassa({ all: true }));
    dispatch(fetchUsers({ all: true, user: true }));
    dispatch(fetchTypeRole({ all: true }));
  }, [dispatch, state]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  useEffect(() => {
    filterState();
  }, [filterState]);
  useEffect(() => {
    fetchPermissionCall();
    return () => {
      dispatch(clearUserRoleDetails());
      dispatch(clearUserRoleData());
    };
  }, [fetchPermissionCall]);
  console.log(usersOptions, "users");
  return (
    <Content
      height={"60vh"}
      style={{ position: "relative" }}
      className="responsive_overflow"
    >
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} className="mb-8">
            <Title bold sm>
              Ввод данных
            </Title>
          </Col>
          <Col xs={12} style={{ padding: 0 }}>
            <hr />
          </Col>
          <Col xs={12}>
            <Form onSubmit={handleSubmit}>
              <Row justify="around">
                <Col xs={6} style={{ zIndex: 80 }}>
                  <FormSelect
                    className="mt-24"
                    defaultValue={{
                      label: get(state, "imyaPolzovateli", ""),
                      value: get(state, "polzovateliId", ""),
                    }}
                    value={get(values, "polzovateliId", "")}
                    label={"Пользователь"}
                    options={usersOptions}
                    required={true}
                    handleValueLabel={(e: string) => {
                      setFieldValue("polzovateliId", get(e, "value", null));
                    }}
                  />
                  <FormSelect
                    className="mt-24"
                    id={"roleId"}
                    value={get(values, "roleId", "")}
                    label={"Тип роли"}
                    options={typeRoleOptions}
                    required={true}
                    handleValueLabel={(e: string) => {
                      setRoleTypeId(get(e, "value", null));

                      setFieldValue("roleId", get(e, "value", null));
                    }}
                  />
                </Col>

                <Col xs={6} style={{ zIndex: 80 }}>
                  <MultipleSelect
                    className="mt-24"
                    value={
                      !isEmpty(territory)
                        ? get(values, "territoriyaIds", null)
                        : []
                    }
                    labelSelect={"Территория"}
                    options={territory}
                    required={true}
                    isSelectAll={true}
                    handleChange={(e: any) => {
                      setFieldValue("territoriyaIds", e);
                    }}
                  />
                  <MultipleSelect
                    value={
                      !isEmpty(kassaOptions) ? get(values, "kassaIds", "") : []
                    }
                    labelSelect={"Касса"}
                    className="mt-24"
                    options={kassaOptions}
                    required={true}
                    handleChange={(e: any) => {
                      setFieldValue("kassaIds", e);
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className="mt-24">
          <Col xs={12}>
            <Title bold sm>
              Права
            </Title>
          </Col>
          <Col xs={12} className={"my-8"}>
            <hr />
          </Col>
          <Col xs={12} style={{ maxHeight: "60vh", overflow: "auto" }}>
            <BaseTable
              tableHeader={[
                "№",
                "Наименование",
                "Чтение (Испол-е)",
                "Ввод нового",
                "Изменение",
                "Пометка на удаление",
                "Отмена пометки удаление",
                "Удаление",
              ]}
              className="table_scroll"
            >
              {!LOADING_DATA ? (
                <>
                  {!isEmpty(get(permission, "permissionsData", [])) ? (
                    DATA.map((item: any, outIndex: number) => (
                      <>
                        <tr
                          key={outIndex + 1}
                          onClick={() =>
                            setAction(
                              action !== get(item, "department", null)
                                ? get(item, "department", null)
                                : null
                            )
                          }
                        >
                          <td
                            colSpan={8}
                            style={{ textAlign: "start" }}
                            key={outIndex + 1}
                          >
                            <FlexBox gap={"15px"}>
                              {isEqual(
                                action,
                                get(item, "department", null)
                              ) ? (
                                <ReactSVG src={row_action} />
                              ) : (
                                <ReactSVG src={col_action} />
                              )}
                              <Title>{get(item, "title", "")}</Title>
                            </FlexBox>
                          </td>
                        </tr>

                        {isEqual(action, get(item, "department", null)) &&
                          // pages ma
                          !isEmpty(get(item, "pages", [])) &&
                          get(item, "pages", []).map(
                            (value: any, middleIndex: number) => (
                              // action map
                              <tr key={middleIndex + 1}>
                                <td>{middleIndex + 1}</td>
                                <td>{get(value, "title", "")}</td>
                                {get(value, "actions", []).map(
                                  (
                                    element: ElementType,
                                    actionIndex: number
                                  ) => (
                                    <td key={actionIndex + 1}>
                                      {isEqual(
                                        get(element, "exists", false),
                                        true
                                      ) ? (
                                        <Checkbox
                                          key={actionIndex + 1}
                                          value={get(element, "checked", false)}
                                          handleChange={(e) => {
                                            dispatch(
                                              handleCheckUserDetails({
                                                outIndex1: outIndex,
                                                middleIndex1: middleIndex,
                                                id: get(
                                                  element,
                                                  "permissionId",
                                                  false
                                                ),
                                                check: e.target.checked,
                                              })
                                            );
                                            if (e.target.checked === true) {
                                              pushArrayDetails(
                                                get(
                                                  element,
                                                  "permissionId",
                                                  null
                                                )
                                              );
                                            } else {
                                              dispatch(
                                                removeUserRoleDetails({
                                                  index: actionIndex,
                                                  id: get(
                                                    element,
                                                    "permissionId",
                                                    null
                                                  ),
                                                })
                                              );
                                            }
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  )
                                )}
                              </tr>
                            )
                          )}
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <Spin />
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                map([...new Array(10)], (_, index) => (
                  <tr key={index + 1}>
                    <LoadingTable
                      width={index % 2 == 0 ? "50px" : "70px"}
                      tblDTNumber={8}
                    />
                  </tr>
                ))
              )}
            </BaseTable>
          </Col>
        </Row>
        <Row className={"mb-16 mt-32 text-end"}>
          <Col xs={12}>
            <FormButton loading={isSubmitting} handleClick={handleSubmit}>
              Сохранить
            </FormButton>
            <Button danger handleClick={() => navigate(-1)}>
              Закрыть
            </Button>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default AddUserRoleContainer;
