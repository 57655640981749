import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearReturnDriver,
  fetchAllReturnedConcreteDrivers,
  fetchAllReturnedConcreteDriversTemp,
  fetchReturnConcreteDriversStat,
  handleFilterChange,
  handleFilterReturnCD,
} from "app/slices/documentSlices/returnConcreteDriverSlice/returnConcreteDriverSlice";
import {
  rtrnConcreteDriver,
  rtrnConcreteDriverFilter,
  rtrnConcreteDriverStat,
  rtrnConcreteDriverTemps,
} from "app/states/documents";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import delate from "assets/images/icons/delate.svg";
import { NumericFormat } from "react-number-format";
import { getProductData } from "app/states/handbooks/selectStates";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import HasAccess from "routers/HasAccess";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
// Приобретение бетона у водителей
const ReturnConcreteDriversContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const rtrnConcreteDriversData = useAppSelector(rtrnConcreteDriver);
  const statistic = useAppSelector(rtrnConcreteDriverStat);
  const rtrnConcreteDriverTemp = useAppSelector(rtrnConcreteDriverTemps);
  const filter = useAppSelector(rtrnConcreteDriverFilter);
  const tovariiOptions = useAppSelector(getProductData);
  const LOADING_TABLE = get(rtrnConcreteDriversData, "loading", []);

  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_RETURN_CONCRETE_DRIVERS
  );
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchAllRtrnedConcreteDrivers = useCallback(() => {
    dispatch(fetchAllReturnedConcreteDrivers(filter));
    dispatch(fetchReturnConcreteDriversStat());
  }, [dispatch, filter]);

  const fetchAllRtrnedConcreteDriversTemp = useCallback(() => {
    dispatch(fetchAllReturnedConcreteDriversTemp());
  }, [dispatch]);

  const clearFilter = useCallback(() => {
    dispatch(
      handleFilterChange({
        name: "tovarId",
        value: null,
      })
    );

    dispatch(
      handleFilterChange({
        name: "fromDate",
        value: null,
      })
    );

    dispatch(
      handleFilterChange({
        name: "toDate",
        value: null,
      })
    );

    dispatch(
      fetchAllReturnedConcreteDrivers({
        ...filter,
        tovarId: null,
        fromDate: null,
        toDate: null,
      })
    );
  }, [dispatch, filter]);

  const handleTempRemove = (id: string | null) => {
    DocumentServices.RemovePrioberBetonTemp(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllRtrnedConcreteDriversTemp();
      }
    });
  };

  const navigateToForm = (param: { id: string; isTemp: boolean }) => {
    navigate(Documents.ADD_RETURN_CONCRETE_DRIVERS, {
      state: param,
    });
  };
  const handleAddButton = () => {
    navigate(Documents.ADD_RETURN_CONCRETE_DRIVERS);
  };

  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeletePrioberBetonById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllRtrnedConcreteDrivers();
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsPrioberBetonById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllRtrnedConcreteDrivers();
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordsPrioberBetonById(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllRtrnedConcreteDrivers();
      }
    });
  };

  useEffect(() => {
    fetchAllRtrnedConcreteDrivers();
  }, [filter.page, filter.size]);

  useEffect(() => {
    dispatch(fetchProduct({ all: true, vidTovaraId: 1 }));
    fetchAllRtrnedConcreteDriversTemp();
    return () => {
      dispatch(clearReturnDriver());
    };
  }, []);
  // for adding  new form
  if (isAddForm) {
    return <Outlet />;
  }
  // main content
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={statistic} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger className={"mr-16"} handleClick={clearFilter}>
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "PRIOBR_BET_CREATE",
                    page: "PRIOBR_BET",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  options={tovariiOptions}
                  placeholder="Товар"
                  width="250px"
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "tovarId",
                        value: e,
                      })
                    );
                  }}
                  value={get(filter, "tovarId", null)}
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  width={"200px"}
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChange({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className="text-right">
              <Button primary handleClick={fetchAllRtrnedConcreteDrivers}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <ContextMenu
                  handleNavigate={navigateToForm}
                  deleteDocument={handleDeleteButton}
                  deleteItem={true}
                  markItem={true}
                  uncheckItem={true}
                  userPermission={"PRIOBR_BET"}
                  handleDeleteRecords={handleDeleteRecords}
                  handleUndoRecords={handleUndoRecords}
                  documentInfo={documentInfo}
                />
                <BaseTable
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Водитель",
                    "Автомобиль",
                    "Контрагент",
                    "Заказ-наряд",
                    "Территория",
                    "Склад готовой продукции",
                    "Товар",
                    "Ед. изм.",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "Время вставки записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LOADING_TABLE ? (
                    <>
                      {/* otherCostsTemp */}
                      {!isEmpty(get(rtrnConcreteDriverTemp, "data.data", [])) &&
                        get(rtrnConcreteDriverTemp, "data.data", []).map(
                          (item: any, index: any) => (
                            <tr
                              key={get(item, "id", "")}
                              onClick={() =>
                                navigateToForm({
                                  isTemp: true,
                                  id: get(item, "id", ""),
                                })
                              }
                              className="cursor-pointer"
                            >
                              <td>
                                <div
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    handleTempRemove(get(item, "id", null));
                                  }}
                                >
                                  <ReactSVG
                                    src={delate}
                                    className={"delete_button svg_icon"}
                                  />
                                </div>
                              </td>
                              <td>{get(item, "createdBy", "-")}</td>
                              <td>{get(item, "nomerDoc", "")}</td>

                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "imyaVoditeli", "")}</td>
                              <td>{get(item, "gosNomer", "")}</td>
                              <td>{get(item, "imyaKontragent", "")}</td>
                              <td>{get(item, "nomerZakazNaryad", "")}</td>

                              <td>{get(item, "imyaTerritoriya", "")}</td>
                              <td>{get(item, "imyaSkladGP", "")}</td>
                              <td>{get(item, "imyaTovar", "")}</td>

                              <td>{get(item, "imyaEdIzm", "")}</td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "kol", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "tsena", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "summa", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>

                              <td>
                                {moment(get(item, "createdAt", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "status", "")}</td>
                            </tr>
                          )
                        )}
                      {!isEmpty(get(rtrnConcreteDriversData, "data.data", []))
                        ? get(rtrnConcreteDriversData, "data.data", []).map(
                            (item: any, index: any) => (
                              <tr
                                onClick={() =>
                                  navigateToForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                key={index + 1}
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      rtrnConcreteDriversData,
                                      "data.pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      rtrnConcreteDriversData,
                                      "data.pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>

                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaVoditeli", "")}</td>
                                <td>{get(item, "gosNomer", "")}</td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "nomerZakazNaryad", "")}</td>

                                <td>{get(item, "imyaTerritoriya", "")}</td>
                                <td>{get(item, "imyaSkladGP", "")}</td>
                                <td>{get(item, "imyaTovar", "")}</td>

                                <td>{get(item, "imyaEdIzm", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "kol", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "tsena", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summa", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>

                                <td>
                                  {moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(
                            get(rtrnConcreteDriverTemp, "data.data", [])
                          ) && <Nodata colLength={16} />}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={16}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(
                  rtrnConcreteDriversData,
                  "data.pagination.pageSize",
                  10
                )}
                handlePageChange={(e) =>
                  dispatch(
                    handleFilterReturnCD({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(
                  rtrnConcreteDriversData,
                  "data.pagination.currentPageNumber",
                  0
                )}
                pageCount={get(
                  rtrnConcreteDriversData,
                  "data.pagination.totalPages",
                  0
                )}
                onPageChange={({ selected }) =>
                  dispatch(
                    handleFilterReturnCD({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default ReturnConcreteDriversContainer;
