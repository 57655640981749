import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  },
};

export const fetchSupplierReport = createAsyncThunk(
  "supplierReport/fetchSupplierReport",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchSupplierReport(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const SupplierReportSlices = createSlice({
  name: "SupplierReport",
  initialState,
  reducers: {
    filterSupplierReport: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearSupplierReport: () => initialState,
    changeSupplierReport: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierReport.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchSupplierReport.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchSupplierReport.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});
export const { filterSupplierReport, clearSupplierReport, changeSupplierReport } = SupplierReportSlices.actions;
export default SupplierReportSlices.reducer;