import styled from "styled-components";
import { StyleProps } from "./type";

const SideBarContainer = styled.div`
    width: 100%;
    background: inherit;
    display: flex;
       position: fixed;
       max-width: 42vh;
    max-height: calc(110vh - 9rem);
    overflow-y: auto;
    z-index:100;
    align-items: ${({ align }: StyleProps) => align || "center"};
    justify-content: ${({ justify }: StyleProps) => justify || "flex-start"};
    flex-direction: ${({ row }: StyleProps) => (row ? "row" : "column")};
    min-width: 5px;
    padding-bottom: 15vh;
    margin-top:  ${({ marginTop }: any) => marginTop || "0vh"};
    gap: 10px;
    ::-webkit-scrollbar {
        left: 16px;
        width: 0.3em;
        margin: 0.1em;
        border-radius: 50px;
        padding-right: 60px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 15px;
        padding: 30px;
        padding-right: 30px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.05);
        outline: 1px solid rgba(0, 0, 0, 0.05);
    }
    @media (max-width: 1250px) {
        margin: 0 auto;
        position: relative;
        z-index: 5;
    } 
   
`;

export { SideBarContainer };
