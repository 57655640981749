import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
  data: { loading: false, dataList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    kontragentId: null,
    imyaKontragent:""
  },
  options: {}
}

export const fetchReconciliationAct = createAsyncThunk(
  "reconciliationActTable/fetchReconciliationAct",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchReconciliationAct(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);


export const fetchReconciliationActDetails = createAsyncThunk(
  "reconciliationActTable/fetchReconciliationActDetails",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchReconciliationActDetails(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
const reconciliationActSlices = createSlice({
  name: "reconciliationActTable",
  initialState,
  reducers: {
    filterActTable: (state, actions) => {
      const name = actions.payload.name;
      const value = actions.payload.value;

      state.filter = {
        ...state.filter,
        [name]: value,
      };
    },
    clearActTable: () => initialState,
    changeActTable: (state, action) => {
      state.data = {
        loading: false,
        dataList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReconciliationAct.pending, (state) => {
      state.data = {
        loading: true,
        dataList: [],
        error: "",
      };
    });
    builder.addCase(fetchReconciliationAct.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        dataList: get(action, "payload.data", []),
        error: "",
      };
    });

    builder.addCase(fetchReconciliationAct.rejected, (state, payload) => {
      state.data = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
    // options
    builder.addCase(fetchReconciliationActDetails.pending, (state) => {
      state.options = {
        loading: true,
      };
    });
    builder.addCase(fetchReconciliationActDetails.fulfilled, (state, action) => {
      state.options = {
        loading: false,
        data: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchReconciliationActDetails.rejected, (state, payload) => {
      state.options = {
        loading: false,
        dataList: [],
        error: payload.error.message,
      };
    });
  },
});

export const { filterActTable, changeActTable, clearActTable } = reconciliationActSlices.actions;
export default reconciliationActSlices.reducer;