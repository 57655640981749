import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFilterMovingCash,
  fetchMovingCash,
  fetchMovingCashStat,
  fetchMovingCashTemp,
  handleFilterMovingCash,
} from "app/slices/documentSlices/movingCashSlices/movingCashSlices";
import { fetchOwnKassa } from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import {
  movingCash,
  movingCashFilter,
  movingCashStatistic,
  movingCashTemps,
} from "app/states/documents";
import { getKassaData } from "app/states/handbooks/selectStates";
import delate from "assets/images/icons/delate.svg";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { Documents } from "routers/paths";
import DocumentServices from "services/apiServices/documents";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";

//  Перемещение наличных денег
const MovingCashContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_MOVING_CASH
  );

  const movingCashData = useAppSelector(movingCash);
  const tempCashData = useAppSelector(movingCashTemps);
  const filter = useAppSelector(movingCashFilter);
  const countStat = useAppSelector(movingCashStatistic);
  const LOADING_TABLE = get(movingCashData, "loading", []);
  const kassaOptions = useAppSelector(getKassaData);
  const fetchMovingCashsFunction = useCallback(() => {
    dispatch(fetchMovingCash(filter));
    dispatch(fetchMovingCashStat());
  }, [dispatch, filter]);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const handleClearFilter = useCallback(() => {
    dispatch(clearFilterMovingCash());
    dispatch(
      fetchMovingCash({
        ...filter,
        fromDate: null,
        toDate: null,
        prixodKassaId: null,
        rasxodKassaId: null,
      })
    );
  }, [filter]);

  const fetchNotFillMovingCashFunction = useCallback(() => {
    dispatch(fetchMovingCashTemp());
    dispatch(fetchMovingCashStat());
    dispatch(fetchOwnKassa({ all: true }));
  }, [dispatch]);

  const navigateForm = (params: { id: string | number; isTemp: boolean }) => {
    navigate(Documents.ADD_MOVING_CASH, {
      state: params,
    });
  };

  const handleAddButton = () => {
    navigate(Documents.ADD_MOVING_CASH);
  };

  useEffect(() => {
    fetchNotFillMovingCashFunction();
    return () => {
      handleClearFilter();
    };
  }, []);

  useEffect(() => {
    fetchMovingCashsFunction();
  }, [filter.page, filter.size]);

  const handleTempButton = (id: string | null) => {
    DocumentServices.RemoveMovingCashesTemp(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchNotFillMovingCashFunction();
      }
    });
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteMovingCashes(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchMovingCashsFunction();
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsMovingCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchMovingCashsFunction();
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DelteRedordsMovingCash(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchMovingCashsFunction();
      }
    });
  };
  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={countStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button danger className="mr-16" handleClick={handleClearFilter}>
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "PEREMESH_ND_CREATE",
                    page: "PEREMESH_ND",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="200px"
                  isClearable
                  options={kassaOptions}
                  placeholder="Расходная касса"
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterMovingCash({
                        name: "rasxodKassaId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "rasxodKassaId", null)}
                />
                <BaseSelect
                  width="200px"
                  isClearable
                  options={kassaOptions}
                  placeholder="Приходная касса"
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterMovingCash({
                        name: "prixodKassaId",
                        value: e,
                      })
                    );
                  }}
                  isSearchable
                  value={get(filter, "prixodKassaId", null)}
                />
                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterMovingCash({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  width="200px"
                  isClearable
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterMovingCash({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className={"text-right"}>
              <Button primary handleClick={fetchMovingCashsFunction}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  className="table_scroll"
                  tableHeader={[
                    "№",
                    "Пользователь",
                    "Номер",
                    "Дата",
                    "Расходная касса",
                    "Приходная касса",
                    "Сумма",
                    "В сумах",
                    "В долларах США",
                    "Время записи",
                    "Статус",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      <ContextMenu
                        handleNavigate={navigateForm}
                        deleteDocument={handleDeleteButton}
                        deleteItem={true}
                        changeItem={true}
                        markItem={true}
                        uncheckItem={true}
                        userPermission={"PEREMESH_ND"}
                        handleDeleteRecords={handleDeleteRecords}
                        handleUndoRecords={handleUndoRecords}
                        documentInfo={documentInfo}
                      />

                      {!isEmpty(get(tempCashData, "data", [])) &&
                        get(tempCashData, "data", []).map((item: any) => (
                          <tr
                            key={get(item, "id", "")}
                            onClick={() =>
                              navigateForm({
                                isTemp: true,
                                id: get(item, "id", ""),
                              })
                            }
                            className="cursor-pointer"
                          >
                            <td>
                              <div
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  handleTempButton(get(item, "id", null));
                                }}
                                className="delete_button"
                              >
                                <ReactSVG
                                  src={delate}
                                  className="delete_button"
                                />
                              </div>
                            </td>
                            <td>{get(item, "createdBy", "-")}</td>
                            <td>{get(item, "nomerDoc", "-")}</td>
                            <td>
                              {moment(get(item, "dataDoc", "")).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>{get(item, "imyaRasxodKassa", "")}</td>
                            <td>{get(item, "imyaPrixodKassa", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summa", "")}
                                thousandSeparator
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summaInUZS", "")}
                                thousandSeparator
                              />
                            </td>
                            <td>
                              {" "}
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summaInUSD", "")}
                                thousandSeparator
                              />
                            </td>
                            <td>
                              {!isEmpty(get(item, "createdAt", ""))
                                ? moment(get(item, "createdAt", "")).format(
                                    "DD.MM.YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>{get(item, "status", "-")}</td>
                          </tr>
                        ))}

                      {!isEmpty(get(movingCashData, "data", []))
                        ? get(movingCashData, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", "")}
                                onClick={() =>
                                  navigateForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      movingCashData,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      movingCashData,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>

                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "-")}</td>
                                <td>
                                  {moment(get(item, "dataDoc", "")).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>{get(item, "imyaRasxodKassa", "")}</td>
                                <td>{get(item, "imyaPrixodKassa", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summa", "")}
                                    thousandSeparator
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summaInUZS", "")}
                                    thousandSeparator
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summaInUSD", "")}
                                    thousandSeparator
                                  />
                                </td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : ""}
                                </td>
                                <td>{get(item, "status", "-")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(tempCashData, "data", null)) && (
                            <Nodata colLength={11} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index: number) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={11}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col xs={6}>
              <ShowOnPage
                pageSize={get(movingCashData, "pagination.pageSize", 10)}
                handlePageChange={(e) =>
                  dispatch(
                    handleFilterMovingCash({
                      name: "size",
                      value: e,
                    })
                  )
                }
              />
            </Col>
            <Col xs={6}>
              <Pagination
                current={get(movingCashData, "pagination.currentPageNumber", 0)}
                pageCount={get(movingCashData, "pagination.totalPages", 1)}
                onPageChange={({ selected }) =>
                  dispatch(
                    handleFilterMovingCash({
                      name: "page",
                      value: selected,
                    })
                  )
                }
              />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default MovingCashContainer;
