import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";

const initialState: any = {
    filter: {
        tovarId: null,
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    data: {
        loading: false,
        data: [],
        error: "",
    },
    statistic: {
        loading: false,
        data: {},
        error: "",
    },
};

export const fetchAllCalculateProduct = createAsyncThunk(
    "calculateProduct/fetchAllCalculateProduct",
    async (params: any, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchAllCalculateProduct(
                params
            );
            const respond = await request.data;

            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchCalculateProductStat = createAsyncThunk(
    "calculateProduct/fetchCalculateProduct",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.FetchCalculateProductStat();
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const calculateProductSlices = createSlice({
    name: "calculateProduct",
    initialState,
    reducers: {
        filterChangeCalculateProd: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCalculateProduct.pending, (state) => {
            state.data = {
                loading: true,
            };
        });
        builder.addCase(
            fetchAllCalculateProduct.fulfilled,
            (state, actions) => {
                state.data = {
                    loading: false,
                    data: get(actions, "payload.data", {}),
                    pagination: get(actions, "payload.pagination", {}),
                    error: "",
                };
            }
        );
        builder.addCase(fetchAllCalculateProduct.rejected, (state, actions) => {
            state.data = {
                loading: false,
                data: {},
                error: get(actions, "error.message", ""),
                pagination: {},
            };
        });
        builder.addCase(fetchCalculateProductStat.pending, (state) => {
            state.statistic = {
                loading: true,
            };
        });
        builder.addCase(
            fetchCalculateProductStat.fulfilled,
            (state, actions) => {
                state.statistic = {
                    loading: false,
                    data: get(actions, "payload.data", {}),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchCalculateProductStat.rejected,
            (state, actions) => {
                state.statistic = {
                    loading: false,
                    data: {},
                    error: actions.error.message,
                };
            }
        );
    },
});

export const { filterChangeCalculateProd } = calculateProductSlices.actions;
export default calculateProductSlices.reducer;
