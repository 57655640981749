import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InitialState } from "./type";
import { get } from "lodash";
import ReportsServices from "services/apiServices/reports";
import moment from "moment";

const initialState: InitialState = {
  data: { loading: false, reportList: null, error: "" },
  filter: {
    fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    territoriyaId: null,
    period: "DAILY",
    detalno: true,
  },
};

export const fetchReportReleases = createAsyncThunk(
  "reportReleasesTable/fetchReportReleases",
  async (params: any, thunkAPI) => {
    try {
      const request = await ReportsServices.FetchReportReleasesTable(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const reportReleasesSlices = createSlice({
  name: "reportReleasesTable",
  initialState,
  reducers: {
    filterReportReleases: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearReportReleases: () => initialState,
    changeReportReleases: (state, action) => {
      state.data = {
        loading: false,
        reportList: action.payload,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportReleases.pending, (state) => {
      state.data = {
        loading: true,
        reportList: [],
        error: "",
      };
    });
    builder.addCase(fetchReportReleases.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        reportList: get(action, "payload.data", []),
        error: "",
      };
    });
    builder.addCase(fetchReportReleases.rejected, (state, payload) => {
      state.data = {
        loading: false,
        reportList: [],
        error: payload.error.message,
      };
    });
  },
});
export const { filterReportReleases, clearReportReleases, changeReportReleases } = reportReleasesSlices.actions;
export default reportReleasesSlices.reducer;
