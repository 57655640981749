import styled, { css } from "styled-components";
// import bgImg from "assets/images/Frame.png"
interface StyledDivProps {
   imageUrl: string;
}
const LoginContainerWrapper = styled.div`
   /* background-image: url(props.img); */
   background-image:  url(${(props: StyledDivProps) => props.imageUrl || "none"});
   background-color: ${({ bgColor }: any) => bgColor || "#ffff"};
   background-size: cover;
   background-position: center;
   width: 100%;
   display: flex;
   flex-direction: column;
   text-align: center;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
  
`;
const LoginFormWrapper = styled.div`
   width: 100%;
  max-width: 80vh;
  min-width: 70vh;
  padding:5vh 6vh;
  display: block;
  border: solid 0.5px #D0D5DD;
  border-radius: 24px;
  background-color:#ffff;
  text-align: start;
  
`;
export const StyledDiv = styled.div<StyledDivProps>`

/* Add other styles as needed */
`;


export { LoginContainerWrapper, LoginFormWrapper };
