import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get } from "lodash";

const initialState: InitialState = {
  data: { loading: false, materialData: [], pagination: {}, error: "" },
  filter: {
    page: 0,
    size: 10,
    tip: "SM",
  },
};

export const fetchRawMaterial = createAsyncThunk(
  "allMaterial/fetchRawMaterial",
  async (params: any, thunkAPI) => {
    try {
      const request = await HandBookServices.GetRawMaterial(params);

      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const rawMaterialSlices = createSlice({
  name: "allMaterial",
  initialState,
  reducers: {
    handleRawMaterialFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    handleMaterialSearchClear: (state) => {
      state.filter = initialState.filter
    },
    clearRowMaterialData: (state) => { state.data = initialState.data }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRawMaterial.pending, (state) => {
      state.data = {
        loading: true,
        materialData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchRawMaterial.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        materialData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),

        error: "",
      };
    });
    builder.addCase(fetchRawMaterial.rejected, (state, payload) => {
      state.data = {
        loading: false,
        materialData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const { handleRawMaterialFilter, handleMaterialSearchClear, clearRowMaterialData } = rawMaterialSlices.actions;
export default rawMaterialSlices.reducer;
