import { memo } from "react";
import { ButtonWrapper } from "./style";

const Button = ({
  outlined = false,
  disabled = false,
  loading = false,
  type = "button",
  inability = false,
  label = "",
  unMove = false,
  handleClick = () => {},
  green = false,
  dark = false,
  danger = false,
  primary = false,
  icon = "",
  ...props
}) => {
  let iconType = () => {
    switch (icon) {
      case "search":
        return "";
      default:
        return "";
    }
  };
  return (
    <ButtonWrapper
      htmlType={type}
      // icon={iconType}
      loading={loading}
      onClick={handleClick}
      outlined={outlined ? 1 : undefined}
      type={type}
      disabled={disabled || inability}
      inability={inability ? 1 : undefined}
      green={green ? 1 : undefined}
      dark={dark ? 1 : undefined}
      danger={danger ? 1 : undefined}
      primary={primary ? 1 : undefined}
      whileHover={!unMove && { scale: 1.1 }}
      whileTap={!unMove && { scale: 0.9 }}
      {...props}
    ></ButtonWrapper>
  );
};

export default memo(Button);
