import { useAppDispatch, useAppSelector } from "app/hook";
import { useEffect, useCallback, useState, useMemo } from "react";
import {
  fetchAllCounterpart,
  fetchOptionsCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  getIncomeOptionsData,
  tipRasxodaOptions,
} from "app/states/enumerations/selectStates";
import {
  arrivedCashFilter,
  arrivedCashStatistic,
  costsInCashFilter,
  costsInCashStat,
} from "app/states/documents";
import {
  clearFilterArrvid,
  fetchAllArrivedCashes,
  fetchAllArrivedCashesStat,
} from "app/slices/documentSlices/arrivedCashesSlice/arrivedCashesSlice";
import {
  clearFilterCash,
  fetchAllCostsInCash,
  fetchAllCostsInCashStat,
} from "app/slices/documentSlices/costsInCashSlice/costsInCashSlice";
import { fetchOwnTerritories } from "app/slices/handbookSlices/territoriesSlices/territoriesSlices";
import {
  getCounterpartOptions,
  getDriversData,
  loadingDrivers,
} from "app/states/handbooks/selectStates";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchLargePerson } from "app/slices/handbookSlices/largePersonSlices/largePersonSlices";
import { loadingConterpart } from "app/states/documents/selectStates";
import { profileData } from "app/states/profile";
import { get } from "lodash";
//  update info test
const useConnect = () => {
  const dispatch = useAppDispatch();
  const [changeTipKassa, setChangeTipKassa] = useState(1);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const contractorDropdowns = useAppSelector(getCounterpartOptions);
  const [openCounterpart, setOpenCounterpart] = useState(false);
  const filterPrixod = useAppSelector(arrivedCashFilter);
  const filterRasxod = useAppSelector(costsInCashFilter);

  const incomeOption = useAppSelector(getIncomeOptionsData);
  const tipRasxoda = useAppSelector(tipRasxodaOptions);
  const drivers = useAppSelector(getDriversData);
  const statRasxod = useAppSelector(costsInCashStat);
  const statPrixod = useAppSelector(arrivedCashStatistic);
  // loading
  let loadingKontragent = useAppSelector(loadingConterpart);
  let isLoadingDrivers = useAppSelector(loadingDrivers);
  const profile = useAppSelector(profileData);

  const userName = useMemo(
    () => get(profile, "profileData.imyaPolzovateli", []),
    [profile]
  );
  const handleAdd = useCallback(() => {
    setIsAdding(!isAdding);
  }, [isAdding]);
  const handleFilterPrixod = () => {
    dispatch(fetchAllArrivedCashes(filterPrixod));
  };

  const clearFilterPrixod = () => {
    dispatch(clearFilterArrvid());
    dispatch(
      fetchAllArrivedCashes({
        kontragentId: null,
        fromDate: null,
        toDate: null,
        tipPrixodaId: null,
      })
    );
  };

  const handleFilterRasxod = () => {
    dispatch(fetchAllCostsInCash(filterRasxod));
  };

  const clearFilterRasxod = () => {
    dispatch(clearFilterCash());
    dispatch(
      fetchAllCostsInCash({
        tipRasxodaId: null,
        fromDate: null,
        toDate: null,
        tipKassa: null,
        voditeliId: null,
      })
    );
  };

  const handleClose = useCallback(() => {
    setIsAdding(false);
  }, [isAdding]);
  const fetchArrivedCashes = useCallback(() => {
    dispatch(fetchAllArrivedCashes(filterPrixod));
  }, [dispatch, filterPrixod.size, filterPrixod.page]);

  const fetchCostsInCash = useCallback(() => {
    dispatch(fetchAllCostsInCash(filterRasxod));
    dispatch(fetchAllCostsInCashStat());
  }, [dispatch, filterRasxod.page, filterRasxod.size]);

  useEffect(() => {
    fetchArrivedCashes();
  }, [filterPrixod.page, filterPrixod.size]);

  useEffect(() => {
    fetchCostsInCash();
  }, [filterRasxod.page, filterRasxod.size]);

  useEffect(() => {
    dispatch(fetchAllArrivedCashesStat());
    // dispatch(fetchLargePerson({ all: true }));
    dispatch(fetchDrivers({ all: true }));
    dispatch(fetchOwnTerritories({ all: true }));
    dispatch(fetchAllCounterpart({ all: true }));
    dispatch(fetchOptionsCounterpart({ all: true }));
    return () => {
      clearFilterPrixod();
      clearFilterRasxod();
    };
  }, []);
  return {
    setChangeTipKassa,
    isAdding,
    changeTipKassa,
    fetchArrivedCashes,
    handleAdd,
    handleFilterPrixod,
    handleFilterRasxod,

    setIsAdding,
    handleClose,

    clearFilterPrixod,
    clearFilterRasxod,

    filterPrixod,
    filterRasxod,
    incomeOption,
    tipRasxoda,
    statPrixod,
    statRasxod,
    contractorDropdowns,
    fetchCostsInCash,
    openCounterpart,
    setOpenCounterpart,
    drivers,
    loadingKontragent,
    isLoadingDrivers,
    userName,
  };
};

export default useConnect;
