import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandBookServices from "services/apiServices/spravochnik";
import { InitialState } from "./type";
import { get, isEmpty } from "lodash";
import { AllPaginationFilterType } from "types/spravochnikType";

const initialState: InitialState = {
  data: { loading: false, calculationData: [], pagination: {}, error: "" },

  formMaterials: {
    data: {
      kalkulyatsiyaDetailsList: [
        {
          tip: 2,
          materialId: null,
          imyaMateriala: null,
          edIzmId: null,
          kolNorm: 0,
          kolOtx: 0,
          kol: 0,
          seloe: 0,
        },
      ],
    },
  },
  filter: {
    page: 0,
    size: 10,
    inAscOrder: false,
  },
};

export const fetchCalculation = createAsyncThunk(
  "allCalculation/fetchCalculation",
  async (params: AllPaginationFilterType, thunkAPI) => {
    try {
      const request = await HandBookServices.GetCalculation(params);

      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const calculationSlices = createSlice({
  name: "allCalculation",
  initialState,
  reducers: {
    addNewRow: (state, action) => {
      state.formMaterials = {
        ...state.formMaterials,
        data: {
          ...state.formMaterials.data,
          kalkulyatsiyaDetailsList: [
            ...state.formMaterials.data.kalkulyatsiyaDetailsList,
            { ...action.payload },
          ],
        },
      };
    },
    handleCalculationFilterClear: (state) => {
      state.filter = initialState.filter;
    },
    removeRowMaterial: (state) => {
      state.formMaterials = {
        ...state.formMaterials,
        data: {
          ...state.formMaterials.data,
          kalkulyatsiyaDetailsList: [
            ...state.formMaterials.data.kalkulyatsiyaDetailsList.slice(
              0,
              state.formMaterials.data.kalkulyatsiyaDetailsList.length - 1
            ),
          ],
        },
      };
    },
    handleAddMaterials: (state, actions) => {
      state.formMaterials = {
        ...state.formMaterials,
        data: {
          ...state.formMaterials.data,
          kalkulyatsiyaDetailsList: !isEmpty(get(actions, "payload", {}))
            ? [
                ...state.formMaterials.data.kalkulyatsiyaDetailsList.map(
                  (item: any, index: number) =>
                    index == actions.payload.id
                      ? {
                          ...item,
                          [get(actions, "payload.name", null)]: get(
                            actions,
                            "payload.value",
                            null
                          ),
                        }
                      : item
                ),
              ]
            : [{}],
        },
      };
    },
    handleCalculationFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
    clearMaterialsCalculation: (state) => {
      state.formMaterials.data.kalkulyatsiyaDetailsList =
        initialState.formMaterials.data.kalkulyatsiyaDetailsList;
    },
    clearCalculation: (state) => {
      state.formMaterials = initialState.formMaterials;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCalculation.pending, (state) => {
      state.data = {
        loading: true,
        calculationData: [],
        pagination: {},
        error: "",
      };
    });
    builder.addCase(fetchCalculation.fulfilled, (state, action) => {
      state.data = {
        loading: false,
        calculationData: get(action, "payload.data", []),
        pagination: get(action, "payload.pagination", {}),

        error: "",
      };
    });
    builder.addCase(fetchCalculation.rejected, (state, payload) => {
      state.data = {
        loading: false,
        calculationData: [],
        pagination: {},
        error: payload.error.message,
      };
    });
  },
});

export const {
  handleCalculationFilter,
  handleAddMaterials,
  addNewRow,
  removeRowMaterial,
  clearMaterialsCalculation,
  clearCalculation,
  handleCalculationFilterClear,
} = calculationSlices.actions;
export default calculationSlices.reducer;
