import {
  BaseInput,
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Text,
  Title,
  Form,
  BaseTable,
  TextArea,
  LoadingAddPages,
  FormButton,
} from "components";
import { useFormik } from "formik";
import { get, isEmpty, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hook";
import { offsetAdvanceFilter } from "app/states/documents";
import DocumentServices from "services/apiServices/documents";
import { toast } from "react-toastify";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import {
  fetchAllOffsetAdvances,
  fetchOffsetAdvancesStat,
} from "app/slices/documentSlices/offsetAdvanceSlices/offsetAdvanceSlices";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import { NumberDocType } from "types/documentsType";
import { checkDateYear } from "utilities/helpers";
type OffsetAdvances = {
  numberDoc: string;
  dataDoc: string;
};
// Зачет аванса покупателей −> Добавить
const AddOffsetAdvanceContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const filter = useAppSelector(offsetAdvanceFilter);
  const [disable, setDisable] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const [LOADING, setLoading] = useState(true);
  const onSubmit = (values: OffsetAdvances) => {
    try {
      DocumentServices.AddOffsetAdvance({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: values,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllOffsetAdvances(filter));
          dispatch(fetchOffsetAdvancesStat());
          toast.success(MESSAGES.ADDED);
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  let initialValues: any = {
    nomerDoc: "",
    dataDoc: moment(new Date()).format("YYYY-MM-DD"),
    kommentariya: "",
  };
  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const handleFetchDetails = useCallback(() => {
    try {
      DocumentServices.FetchOffsetAdvanceDetails().then(({ data }) => {
        setDetails(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSetInitialValues = (data: any) => {
    setDetails(get(data, "zachetDetailsList", []));
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
  };
  const getDocNumber = useCallback((date: NumberDocType) => {
    try {
      DocumentServices.GetOffsetAdvanceNumber(date).then(({ data }) => {
        setFieldValue("nomerDoc", get(data, "data", ""));
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      //  temp
      DocumentServices.FetchOffsetAdvanceById(get(state, "id", "")).then(
        (res) => {
          if (get(res, "status", "") == 200) {
            let respond = get(res, "data.data", {});
            handleSetInitialValues(respond);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
      // enter by id
      setDisable(true);
    } else {
      // create new
      getDocNumber({});
      setLoading(false);
    }
  }, [dispatch, state]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  return (
    <Content className={"min_height"} style={{ position: "relative" }}>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={handleFetchDetails}
                  disabled={disable}
                >
                  Заполнить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", "")}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          maxDate={new Date()}
                          disabled={disable}
                          dateFormat="dd.MM.yyyy"
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Дебет счет",
                    "Субконто1",
                    "Субконто2",
                    "Аванс",
                    "Кредит счет",
                    "Субконто1",
                    "Субконто2",
                    "Субконто3",
                    "Сумма",
                  ]}
                >
                  {!isEmpty(details) ? (
                    details.map((item: any, index: number) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{get(item, "debitSchet", "")}</td>
                        <td>{get(item, "debitSubkonto1", "")}</td>
                        <td>{get(item, "debitSubkonto2", "")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "avans", 0)}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </td>
                        <td>{get(item, "kreditSchet", 0)}</td>
                        <td>{get(item, "kreditSubkonto1", 0)}</td>
                        <td>{get(item, "kreditSubkonto2", 0)}</td>
                        <td>{get(item, "kreditSubkonto3", 0)}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summa", 0)}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>Нет Данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                          Печать
                      </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.ZACHET_AVANS}_CREATE`,
                    page: DOCUMENT_KEY.ZACHET_AVANS,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>

              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddOffsetAdvanceContainer;
