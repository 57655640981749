import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearAllDataShipment,
  clearFormDataShipment,
  fetchNotFilledShipmentProduct,
  fetchShipmentProduct,
  fetchShipmentProductStat,
  handleFilterChangeShipment,
} from "app/slices/documentSlices/shipmentProductSlices/shipmentProductSlices";
import {
  saleTempsshipmentProductList,
  shipmentProductFilter,
  shipmentProductList,
  shipmentProductStat,
} from "app/states/documents";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  ContextMenu,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  Text,
  WindowHeight,
} from "components";
import { get, includes, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Documents } from "routers/paths";
import delate from "assets/images/icons/delate.svg";
import { ReactSVG } from "react-svg";
import DocumentServices from "services/apiServices/documents";
import { toast } from "react-toastify";
import { getTypeProductData } from "app/states/handbooks/selectStates";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import HasAccess from "routers/HasAccess";
import { CONTEXT, MESSAGES, PROTECTED_TYPE, TABLE } from "utilities/constants";
import { indexNumber } from "utilities/helpers";
import { useContextMenu } from "react-contexify";
import {
  contractorOptions,
  loadingConterpart,
} from "app/states/documents/selectStates";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";

// Отгрузка готовой продукции
const ShipmentProductsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAddForm = includes(
    get(location, "pathname", ""),
    Documents.ADD_SHIPMENT_PRODUCT
  );
  const dispatch = useAppDispatch();
  const shipmentProducts = useAppSelector(shipmentProductList);
  const filter = useAppSelector(shipmentProductFilter);
  const shipmentTempsContract = useAppSelector(saleTempsshipmentProductList);
  const shipmentStat = useAppSelector(shipmentProductStat);
  console.log(shipmentProducts, "secod");
  let LOADING_TABLE = get(shipmentProducts, "loading", false);
  const tovariiOptions = useAppSelector(getTypeProductData);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const [documentInfo, setDocumentInfo] = useState({ id: "", status: 0 });
  const [LoadingTableState, setLoadingTable] = useState(true);
  let loadingKontragent = useAppSelector(loadingConterpart);
  console.log(LOADING_TABLE, "git data");
  // context
  const { show, hideAll } = useContextMenu({
    id: "click",
  });
  const displayMenu = ({ e, id, status }: any) => {
    show({
      event: e,
    });
    setDocumentInfo({ id, status });
  };
  //  other fetches
  const fetchAllShipmentProducts = useCallback(() => {
    setLoadingTable(true);
    dispatch(fetchShipmentProduct(filter))
      .then((res) => {
        if (res) {
          setLoadingTable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingTable(false);
      });
  }, [dispatch, filter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      fetchShipmentProduct({
        ...filter,
        fromDate: null,
        toDate: null,
        tovarId: null,
        kontragentId: null,
        vidTovaraId: null,
      })
    );
    dispatch(clearAllDataShipment());
  }, [dispatch, filter]);

  useEffect(() => {
    fetchAllShipmentProducts();
  }, [filter.page, filter.size]);

  const fetchSalesNotFilledContracts = useCallback(() => {
    dispatch(fetchNotFilledShipmentProduct());
    dispatch(fetchShipmentProductStat());
    dispatch(fetchProduct({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllCounterpart({ all: true }));
    fetchSalesNotFilledContracts();
    dispatch(fetchTypeProduct({ all: true }));
  }, [fetchSalesNotFilledContracts]);

  const navigateSaleContractForm = (params: {
    id: string;
    isTemp: boolean;
  }) => {
    navigate(Documents.ADD_SHIPMENT_PRODUCT, {
      state: params,
    });
  };
  const handleAddButton = () => {
    dispatch(clearFormDataShipment());
    dispatch(clearContractorsState());
    navigate(Documents.ADD_SHIPMENT_PRODUCT);
  };

  const handleTempButton = (id: string | null) => {
    DocumentServices.RemoveTempsShipmentProduct(id).then((res) => {
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchSalesNotFilledContracts();
      }
    });
  };
  const handleDeleteButton = (id: string | null) => {
    DocumentServices.DeleteShipmentProductData(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.DELETE, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.DELETED);
        fetchAllShipmentProducts();
        dispatch(fetchShipmentProductStat());
      }
    });
  };
  // status
  const handleUndoRecords = (id: string | null) => {
    DocumentServices.UndoRecordsShimpentProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.UNDO, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllShipmentProducts();
        dispatch(fetchShipmentProductStat());
      }
    });
  };
  const handleDeleteRecords = (id: string | null) => {
    DocumentServices.DeleteRecordShipmentProduct(id).then((res) => {
      dispatch(handleContextLoading({ type: CONTEXT.RECORD, value: false }));
      hideAll();
      if (get(res, "status", "") == 200) {
        toast.success(MESSAGES.CHANGED);
        fetchAllShipmentProducts();
        dispatch(fetchShipmentProductStat());
      }
    });
  };

  useEffect(() => {
    dispatch(clearAllDataShipment());
  }, []);
  if (isAddForm) {
    return <Outlet />;
  }

  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={6}>
              <ShowStatistics statistic={shipmentStat} />
            </Col>
            <Col xs={6} style={{ textAlign: "end" }}>
              <Button
                className={"mr-16"}
                danger
                handleClick={handleClearFilter}
              >
                {TABLE.RESET}
              </Button>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "OTGRUZKA_GP_CREATE",
                    page: "OTGRUZKA_GP",
                    department: "DOKUMENTI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <Button green handleClick={handleAddButton}>
                      {TABLE.ADD}
                    </Button>
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={10}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  isClearable
                  width="250px"
                  placeholder={"Контрагент"}
                  isLoading={loadingKontragent}
                  value={get(filter, "kontragentId", "")}
                  options={contractorDropdowns}
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeShipment({
                        name: "kontragentId",
                        value: e,
                      })
                    );
                  }}
                />
                <BaseSelect
                  isClearable
                  options={tovariiOptions}
                  placeholder="Вид товар"
                  width={"200px"}
                  handleChange={(e: any) => {
                    dispatch(
                      handleFilterChangeShipment({
                        name: "vidTovaraId",
                        value: e,
                      })
                    );
                  }}
                  value={get(filter, "vidTovaraId", null)}
                />
                <DatePicker
                  isClearable
                  width="200px"
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeShipment({
                        name: "fromDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  isClearable
                  width="200px"
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleFilterChangeShipment({
                        name: "toDate",
                        value: !!e ? moment(e).format("YYYY-MM-DD") : null,
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col xs={2} className="text-right">
              <Button primary handleClick={fetchAllShipmentProducts}>
                {TABLE.APPLY}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Дата",
                    "Контрагент",
                    "Товар",
                    "Количество",
                    "Водитель",
                    "Автомобиль",
                    "Пользователь",
                    "Номер",
                    "Заказ-наряд",
                    "Территория",
                    "Склад готовой продукции",
                    "Договор",
                    "Возможность изменения Avto",
                    "Отметка о софрмировании счет-фактуры",
                    "Время записи",
                    "Статус",
                  ]}
                  className="table_scroll"
                >
                  {!LoadingTableState ? (
                    <>
                      <ContextMenu
                        handleNavigate={navigateSaleContractForm}
                        deleteDocument={handleDeleteButton}
                        deleteItem={true}
                        markItem={true}
                        uncheckItem={true}
                        userPermission={"OTGRUZKA_GP"}
                        handleDeleteRecords={handleDeleteRecords}
                        handleUndoRecords={handleUndoRecords}
                        documentInfo={documentInfo}
                      />
                      <>
                        {!isEmpty(get(shipmentTempsContract, "data", [])) &&
                          get(shipmentTempsContract, "data", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={get(item, "id", 0)}
                                onClick={() =>
                                  navigateSaleContractForm({
                                    isTemp: true,
                                    id: get(item, "id", ""),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  <div
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      handleTempButton(get(item, "id", null));
                                    }}
                                    className="delete_button"
                                  >
                                    <ReactSVG
                                      src={delate}
                                      className="delete_button"
                                    />
                                  </div>
                                </td>
                                <td>
                                  {!isEmpty(get(item, "dataDoc", ""))
                                    ? moment(get(item, "dataDoc", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "imyaTovara", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "kol", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>{get(item, "imyaVoditeli", "")}</td>
                                <td>{get(item, "gosNomer", "")}</td>

                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>{get(item, "nomerZakazNaryad", "")}</td>
                                <td>{get(item, "imyaTerritorii", "")}</td>
                                <td>{get(item, "imyaSklada", "")}</td>
                                <td>{get(item, "nomerDogProdaja", "-")}</td>
                                <td>
                                  {get(item, "izmAvto", false) ? "есть" : "нет"}
                                </td>
                                <td>{get(item, "sf", false) ? "да" : "нет"}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "status", "")}</td>
                              </tr>
                            )
                          )}
                      </>
                      {!isEmpty(get(shipmentProducts, "productList", []))
                        ? get(shipmentProducts, "productList", []).map(
                            (item: any, index: number) => (
                              <tr
                                key={index + 1}
                                onClick={() =>
                                  navigateSaleContractForm({
                                    isTemp: false,
                                    id: get(item, "id", ""),
                                  })
                                }
                                onContextMenu={(e) =>
                                  displayMenu({
                                    e,
                                    id: get(item, "id", ""),
                                    status: get(item, "status", 0),
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <td>
                                  {indexNumber({
                                    currentPage: get(
                                      shipmentProducts,
                                      "pagination.currentPageNumber",
                                      0
                                    ),
                                    pageSize: get(
                                      shipmentProducts,
                                      "pagination.pageSize",
                                      10
                                    ),
                                    index: index + 1,
                                  })}
                                </td>
                                <td>
                                  {!isEmpty(get(item, "dataDoc", ""))
                                    ? moment(get(item, "dataDoc", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "imyaKontragent", "")}</td>
                                <td>{get(item, "imyaTovara", "")}</td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "kol", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>{get(item, "imyaVoditeli", "")}</td>
                                <td>{get(item, "gosNomer", "")}</td>

                                <td>{get(item, "createdBy", "-")}</td>
                                <td>{get(item, "nomerDoc", "")}</td>
                                <td>{get(item, "nomerZakazNaryad", "")}</td>
                                <td>{get(item, "imyaTerritorii", "")}</td>
                                <td>{get(item, "imyaSklada", "")}</td>
                                <td>{get(item, "nomerDogProdaja", "-")}</td>
                                <td>
                                  {get(item, "izmAvto", false) ? "есть" : "нет"}
                                </td>
                                <td>{get(item, "sf", false) ? "да" : "нет"}</td>
                                <td>
                                  {!isEmpty(get(item, "createdAt", ""))
                                    ? moment(get(item, "createdAt", "")).format(
                                        "DD.MM.YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{get(item, "status", "")}</td>
                              </tr>
                            )
                          )
                        : isEmpty(get(shipmentTempsContract, "data", [])) && (
                            <Nodata colLength={15} />
                          )}
                    </>
                  ) : (
                    map([...new Array(10)], (item, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "50px" : "70px"}
                          tblDTNumber={15}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(shipmentProducts, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-16">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(shipmentProducts, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleFilterChangeShipment({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    shipmentProducts,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(shipmentProducts, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleFilterChangeShipment({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default ShipmentProductsContainer;
