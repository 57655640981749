import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialState, prixodDetails } from "./type";
import { get, isEmpty } from "lodash";
import DocumentServices from "services/apiServices/documents";
import { ACTION } from "utilities/constants";

const initialState: InitialState = {
  contractStatMaterials: {
    loading: false,
    data: {},
    error: "",
  },
  formData: {
    loading: false,
    error: "",
    isTempActive: false,
    data: {
      prixodSMDetails: [],
    },
  },
  filter: {
    search: "",
    fromDate: null,
    toDate: null,
    page: 0,
    size: 10,
  },
  receivedMaterials: {
    loading: false,
    data: [],
    pagination: {},
    error: "",
  },
  notFilledCntrList: {
    loading: false,
    data: [],
    error: "",
  },
};

export const fetchReceivedMaterials = createAsyncThunk(
  "receivedMaterials/fetchReceivedMaterials",
  async (params: any, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReceivedMaterials(params);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchNotFilledReceivedMaterials = createAsyncThunk(
  "receivedMaterials/fetchNotFilledReceivedMaterials",
  async (params, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchNotFilledReceivedMaterials(
        params
      );
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReceivedMaterialsByTempId = createAsyncThunk(
  "receivedMaterials/fetchReceivedMaterialsByTempId",
  async (id: string, thunkAPI) => {
    try {
      const request =
        await DocumentServices.FetchNotFilledReceivedMaterialsById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReceivedMaterialsById = createAsyncThunk(
  "receivedMaterials/fetchReceivedMaterialsById",
  async (id: string, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReceivedMaterialsById(id);
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchReceivedMaterialsStat = createAsyncThunk(
  "receivedMaterials/fetchReceivedMaterialsStat",
  async (_, thunkAPI) => {
    try {
      const request = await DocumentServices.FetchReceivedMaterialsStatics();
      const respond = await request.data;
      return respond;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

const receivedMaterialsSlice = createSlice({
  name: "receivedMaterials",
  initialState,
  reducers: {
    movementReceivedMaterialSub: (state, action) => {
      const id = get(action, "payload.id", null);
      if (action.payload.type === ACTION.ADD) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            prixodSMDetails: !isEmpty(get(action, "payload.data", []))
              ? [
                ...state.formData.data.prixodSMDetails,
                action.payload.data,
              ]
              : [],
          },
        };
      } else if (action.payload.type === ACTION.UPDATE) {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            prixodSMDetails: state.formData.data.prixodSMDetails.map(
              (item: prixodDetails) =>
                item.materialId == id
                  ?
                  get(action, "payload.data", {})
                  : item
            ),
          },
        };
      } else if (action.payload.type === ACTION.DELETE) {

        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            prixodSMDetails: state.formData.data.prixodSMDetails.filter(
              (item: prixodDetails) => item.materialId !== id
            ),
          },
        };
      }
      else {
        state.formData = {
          ...state.formData,
          data: {
            ...state.formData.data,
            prixodSMDetails: [],
          },
        };
      }
    },
    handleFilterChangeReceivedMaterials: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.name]: actions.payload.value,
      };
    },
    clearFormDataMaterials: (state) => {
      state.formData = initialState.formData
    },
    handleClearFormDataMaterials: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReceivedMaterials.pending, (state) => {
      state.receivedMaterials = {
        loading: true,
      };
    });
    builder.addCase(fetchReceivedMaterials.fulfilled, (state, actions) => {
      state.receivedMaterials = {
        loading: false,
        data: get(actions, "payload.data", []),
        pagination: get(actions, "payload.pagination", {}),
        error: "",
      };
    });
    builder.addCase(fetchReceivedMaterials.rejected, (state, actions) => {
      state.receivedMaterials = {
        loading: false,
        data: [],
        pagination: {},
        error: actions.error.message,
      };
    });

    builder.addCase(fetchNotFilledReceivedMaterials.pending, (state) => {
      state.notFilledCntrList = {
        loading: true,
      };
    });
    builder.addCase(
      fetchNotFilledReceivedMaterials.fulfilled,
      (state, actions) => {
        state.notFilledCntrList = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
        };
      }
    );
    builder.addCase(
      fetchNotFilledReceivedMaterials.rejected,
      (state, actions) => {
        state.notFilledCntrList = {
          loading: false,
          data: [],
          error: actions.error.message,
        };
      }
    );
    //  fetch temp
    builder.addCase(fetchReceivedMaterialsByTempId.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(
      fetchReceivedMaterialsByTempId.fulfilled,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: get(actions, "payload.data", []),
          error: "",
          isTempActive: true,
        };
      }
    );
    builder.addCase(
      fetchReceivedMaterialsByTempId.rejected,
      (state, actions) => {
        state.formData = {
          loading: false,
          data: [],
          error: actions.error.message,
          isTempActive: false,
        };
      }
    );
    // fetch id
    builder.addCase(fetchReceivedMaterialsById.pending, (state) => {
      state.formData = {
        loading: true,
        isTempActive: false,
      };
    });
    builder.addCase(fetchReceivedMaterialsById.fulfilled, (state, actions) => {
      state.formData = {
        loading: false,
        data: get(actions, "payload.data", []),
        error: "",
        isTempActive: true,
      };
    });
    builder.addCase(fetchReceivedMaterialsById.rejected, (state, actions) => {
      state.formData = {
        loading: false,
        data: [],
        error: actions.error.message,
        isTempActive: false,
      };
    });

    // fetching sale contracts statists
    builder.addCase(fetchReceivedMaterialsStat.pending, (state) => {
      state.contractStatMaterials = {
        loading: true,
      };
    });
    builder.addCase(fetchReceivedMaterialsStat.fulfilled, (state, actions) => {
      state.contractStatMaterials = {
        loading: false,
        data: get(actions, "payload.data", {}),
        error: "",
      };
    });
    builder.addCase(fetchReceivedMaterialsStat.rejected, (state, actions) => {
      state.contractStatMaterials = {
        loading: false,
        data: {},
        error: actions.error.message,
      };
    });
  },
});

export const {
  handleFilterChangeReceivedMaterials,
  clearFormDataMaterials,
  handleClearFormDataMaterials,
  movementReceivedMaterialSub,
} = receivedMaterialsSlice.actions;
export default receivedMaterialsSlice.reducer;
