import change from "assets/images/icons/context_change.svg";
import delate from "assets/images/icons/context_delete.svg";
import edit_finish from "assets/images/icons/context_finish.svg";
import mark from "assets/images/icons/context_mark_delete.svg";
import open from "assets/images/icons/context_open.svg";
import uncheck from "assets/images/icons/context_uncheck.svg";
import FlexBox from "components/FlexBox/FlexBox";
import { get, isEqual } from "lodash";
import { memo, useCallback, useEffect, useRef } from "react";
import { Item, Menu, Separator, Submenu } from "react-contexify";
import { ReactSVG } from "react-svg";
import HasAccess from "routers/HasAccess";
import { CONTEXT, PROTECTED_TYPE } from "utilities/constants";
import { Props } from "./type";
import { Spin } from "antd";
import { useAppDispatch, useAppSelector } from "app/hook";
import { contextLoading } from "app/states/menu";
import { handleContextLoading } from "app/slices/menuSlices/contextMenuSlice";
const ContextMenu = ({
  handleNavigate = () => {},
  openItem = false,
  changeItem = false,
  deleteItem = false,
  uncheckItem = false,
  markItem = false,
  orderItem = false,
  documentInfo,
  department = "DOKUMENTI",
  deleteDocument = () => {},
  comptedOrderFunction = () => {},
  handleDeleteRecords = () => {},
  handleUndoRecords = () => {},
  userPermission = "",
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const LOADING_ITEMS = useAppSelector(contextLoading);

  const checkDisable = () => {
    if (["DOG_PRODAJA", "ZAKAZ_NARYAD", "DOG_POKUPKA"].includes(userPermission))
      return false;
    else if (documentInfo.status === 0) return true;
  };
  const checkDeleteDisable = useCallback(() => {
    if (
      get(documentInfo, "status", 0) === 0 &&
      ["SEBEST", "ZAKR_MES"].includes(userPermission)
    ) {
      return true;
    } else if (
      get(documentInfo, "status", 0) === 1 &&
      !["SEBEST", "ZAKR_MES"].includes(userPermission)
    ) {
      return true;
    } else {
      return false;
    }
    // else if (
    //   documentInfo.status === 1 ||
    //   get(LOADING_ITEMS, "undoLoading", false) ||
    //   get(LOADING_ITEMS, "recordLoading", false) ||
    //   get(LOADING_ITEMS, "deleteLoading", false)
    // ) {
    //   return true;
    // }

    // else {
    //   return false;
    // }
  }, [documentInfo.status]);
  // compted order
  const SubItemLabel = () => {
    return (
      <FlexBox align={"center"} gap={"5px"}>
        <ReactSVG
          src={edit_finish}
          className="svg_icon"
          width={"10px"}
          loading={() => <Spin />}
        />
        <span>Изменение выполнении</span>
      </FlexBox>
    );
  };

  // loading
  const LoadingItem = ({ title }: { title: string }) => {
    return (
      <Item disabled className="change_icon_color_stroke">
        <FlexBox align={"center"} gap={"10px"}>
          <Spin />
          <span>{title}</span>
        </FlexBox>
      </Item>
    );
  };
  const checkOverly = (e: any) => {
    if (
      get(LOADING_ITEMS, "undoLoading", false) ||
      get(LOADING_ITEMS, "recordLoading", false) ||
      get(LOADING_ITEMS, "deleteLoading", false)
    ) {
      return "none";
    } else {
      return "auto";
    }
  };
  useEffect(() => {
    checkOverly("");
  }, [LOADING_ITEMS]);

  return (
    <div
      onClick={checkOverly}
      className={checkOverly("") === "none" ? "overly" : ""}
    >
      <Menu id={"click"}>
        <Item
          onClick={() => {
            handleNavigate({
              isTemp: false,
              id: get(documentInfo, "id", ""),
            });
          }}
          className="change_icon_color_stroke"
          hidden={openItem}
        >
          <FlexBox align={"center"} gap={"10px"}>
            <ReactSVG
              className="svg_icon_context"
              src={open}
              loading={() => <Spin />}
            />
            <span>Открыть</span>
          </FlexBox>
        </Item>
        <HasAccess>
          {({ userCan }: any) =>
            userCan({
              permission: `${userPermission}_UPDATE`,
              page: userPermission,
              department: department,
              type: PROTECTED_TYPE.PERMISSIONS,
            }) && (
              <Item
                disabled={checkDisable()}
                onClick={() => {
                  handleNavigate({
                    isTemp: false,
                    isUpdate: true,
                    id: get(documentInfo, "id", ""),
                  });
                }}
                hidden={!changeItem}
                className={checkDisable() ? "" : "change_icon_color_stroke"}
              >
                <FlexBox align={"center"} gap={"10px"}>
                  <ReactSVG
                    src={change}
                    className="svg_icon_context"
                    loading={() => <Spin />}
                  />
                  <span>Изменить</span>
                </FlexBox>
              </Item>
            )
          }
        </HasAccess>
        <HasAccess>
          {({ userCan }: any) =>
            userCan({
              permission: `${userPermission}_UPDATE`,
              page: userPermission,
              department: department,
              type: PROTECTED_TYPE.PERMISSIONS,
            }) && (
              <Submenu
                hidden={!orderItem}
                label={<SubItemLabel />}
                className="change_icon_color_stroke"
              >
                <Item
                  onClick={() =>
                    comptedOrderFunction(get(documentInfo, "id", ""))
                  }
                  disabled={
                    isEqual(get(documentInfo, "otmVipolneniya", null), 1)
                      ? true
                      : false
                  }
                >
                  <span className="p-4">Выполнен</span>
                </Item>
                <Item
                  onClick={() =>
                    comptedOrderFunction(get(documentInfo, "id", ""))
                  }
                  disabled={
                    isEqual(get(documentInfo, "otmVipolneniya", null), 0)
                      ? true
                      : false
                  }
                >
                  <span className="p-4">Не выполнен</span>
                </Item>
              </Submenu>
            )
          }
        </HasAccess>
        {/* Separator */}
        <HasAccess>
          {({ userCan }: any) =>
            userCan({
              permission: `${userPermission}_DELETE_RECORDS`,
              page: userPermission,
              department: department,
              type: PROTECTED_TYPE.PERMISSIONS,
            }) && (
              <>
                <Separator hidden={!markItem} />
                {!get(LOADING_ITEMS, "recordLoading", false) ? (
                  <Item
                    closeOnClick={false}
                    onClick={() => {
                      handleDeleteRecords(get(documentInfo, "id", null));
                      dispatch(
                        handleContextLoading({
                          type: CONTEXT.RECORD,
                          value: true,
                        })
                      );
                    }}
                    hidden={!markItem}
                    disabled={isEqual(get(documentInfo, "status", null), 0)}
                    className={
                      isEqual(get(documentInfo, "status", 0), 0)
                        ? ""
                        : "change_icon_color_fill"
                    }
                  >
                    {/* when disabled the icon not show fix the: when disable style stoped */}
                    <FlexBox align={"center"} gap={"10px"}>
                      <ReactSVG
                        className="svg_icon_context "
                        src={mark}
                        loading={() => <Spin />}
                      />
                      <span>Пометить на удаление</span>
                    </FlexBox>
                  </Item>
                ) : (
                  <LoadingItem title={"Пометить на удаление"} />
                )}
              </>
            )
          }
        </HasAccess>
        {/* if status 0 the should bye ablee */}
        <HasAccess>
          {({ userCan }: any) =>
            userCan({
              permission: `${userPermission}_RECOVER_RECORDS`,
              page: userPermission,
              department: department,
              type: PROTECTED_TYPE.PERMISSIONS,
            }) && (
              <>
                {!get(LOADING_ITEMS, "undoLoading", false) ? (
                  <Item
                    closeOnClick={false}
                    onClick={() => {
                      handleUndoRecords(get(documentInfo, "id", null));
                      dispatch(
                        handleContextLoading({
                          type: CONTEXT.UNDO,
                          value: true,
                        })
                      );
                    }}
                    hidden={!uncheckItem}
                    disabled={isEqual(get(documentInfo, "status", 0), 1)}
                    className={
                      isEqual(get(documentInfo, "status", null), 1)
                        ? ""
                        : "change_icon_color_fill"
                    }
                  >
                    <FlexBox align={"center"} gap={"10px"}>
                      <ReactSVG
                        src={uncheck}
                        className="svg_icon_context"
                        loading={() => <Spin />}
                      />
                      <span>Снять пометку</span>
                    </FlexBox>
                  </Item>
                ) : (
                  <LoadingItem title={"Снять пометку"} />
                )}
              </>
            )
          }
        </HasAccess>
        {/* end */}

        <HasAccess>
          {
            ({ userCan }: any) =>
              userCan({
                permission: `${userPermission}_DELETE`,
                page: userPermission,
                department: department,
                type: PROTECTED_TYPE.PERMISSIONS,
              }) && (
                //    item
                <>
                  <Separator hidden={!deleteItem} />
                  {!get(LOADING_ITEMS, "deleteLoading", false) ? (
                    <Item
                      closeOnClick={false}
                      hidden={!deleteItem}
                      onClick={() => {
                        deleteDocument(get(documentInfo, "id", ""));
                        dispatch(
                          handleContextLoading({
                            type: CONTEXT.DELETE,
                            value: true,
                          })
                        );
                      }}
                      disabled={checkDeleteDisable()}
                      className={
                        checkDeleteDisable() ? "" : "change_icon_color_fill"
                      }
                    >
                      <FlexBox align={"center"} gap={"10px"}>
                        <ReactSVG
                          className="svg_18 pl-8"
                          src={delate}
                          loading={() => <Spin />}
                        />
                        <span>Удалить</span>
                      </FlexBox>
                    </Item>
                  ) : (
                    <LoadingItem title={"Удалить"} />
                  )}
                </>
              )
            // end item
          }
        </HasAccess>
      </Menu>
    </div>
  );
};

export default memo(ContextMenu);
