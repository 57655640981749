import { useAppDispatch, useAppSelector } from "app/hook";
import { handleFilterChangeOrder } from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import { fetchMenageAccount } from "app/slices/handbookSlices/menageAccountSlices/menageAccountSlices";
import {
  clearRemainTable,
  fetchRemainTable,
  fetchRemainTableStatistic,
  handleChangeFilterRemainTable,
} from "app/slices/registerSlices/remaonTableSlices/remainTableSlices";
import { getDebutCreditData } from "app/states/handbooks/selectStates";
import {
  remainTableData,
  remainTableFilter,
  remainTableStatisticData,
} from "app/states/registers";
import {
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  ShowStatistics,
  WindowHeight,
} from "components";
import { get, isEmpty, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { indexNumber } from "utilities/helpers";
// Остатки
const RemainsContainer = () => {
  const dispatch = useAppDispatch();
  const remainDataTable = useAppSelector(remainTableData);
  const filter = useAppSelector(remainTableFilter);
  const cntrCountStat = useAppSelector(remainTableStatisticData);
  const debutCreditOptions = useAppSelector(getDebutCreditData);
  const LOADING_TABLE = get(remainDataTable, "loading", []);

  const fetchRemainTableData = useCallback(() => {
    dispatch(fetchRemainTable({ ...filter, page: 0 }));
  }, [dispatch, filter]);
  const handleClearFilter = useCallback(() => {
    dispatch(clearRemainTable());
    dispatch(
      fetchRemainTable({
        ...filter,
        fromDate: null,
        toDate: null,
      })
    );
  }, [dispatch, filter]);
  useEffect(() => {
    dispatch(fetchRemainTableStatistic());
    dispatch(fetchMenageAccount({ all: true }));
    dispatch(fetchRemainTable(filter));
  }, [filter.page, filter.size]);
  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content className={"full_height"}>
          <Row align="center" className="mb-16">
            <Col xs={10}>
              <ShowStatistics statistic={cntrCountStat} />
            </Col>
          </Row>
          <Row className={"py-16 border"}>
            <Col xs={8}>
              <FlexBox gap={"10px"}>
                <BaseSelect
                  width="200px"
                  isSearchable
                  value={get(filter, "search", "")}
                  options={debutCreditOptions}
                  placeholder="Cчёт"
                  handleChange={(e: string) => {
                    dispatch(
                      handleChangeFilterRemainTable({
                        name: "search",
                        value: e,
                      })
                    );
                  }}
                />
                <DatePicker
                  placeholder="Дата начало"
                  handleDate={(e: any) => {
                    dispatch(
                      handleChangeFilterRemainTable({
                        name: "fromDate",
                        value: moment(e).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                />

                <DatePicker
                  placeholder="Дата конец"
                  handleDate={(e: any) => {
                    dispatch(
                      handleChangeFilterRemainTable({
                        name: "toDate",
                        value: moment(e).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  value={get(filter, "toDate", "")}
                  dateFormat="dd.MM.yyyy"
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"}>
              <Button
                primary
                className={"mr-16"}
                handleClick={fetchRemainTableData}
              >
                Применять
              </Button>
              <Button danger handleClick={handleClearFilter}>
                Сброс
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WindowHeight>
                <BaseTable
                  minWidth={"150px"}
                  className={"table_scroll"}
                  tableHeader={[
                    "№",
                    "Дата",
                    "Счет",
                    "Субконто1",
                    "Субконто2",
                    "Субконто3",

                    "Количест. оборот по дебету на текущую дату",
                    "Суммарный оборот по дебету на текущую дату",
                    "Количест. оборот по кредиту на текущую дату",
                    "Суммарный оборот по кредиту на текущую дату",

                    "Дебетовый количественный остаток",
                    "Дебетовый суммарный остаток",
                    "Кредитовый количественный остаток",
                    "Кредитовый суммарный остаток",
                  ]}
                >
                  {!LOADING_TABLE ? (
                    <>
                      {!isEmpty(get(remainDataTable, "remainderData", [])) ? (
                        get(remainDataTable, "remainderData", []).map(
                          (item, index) => (
                            <tr key={index + 1} className="hover_line">
                              <td>
                                {indexNumber({
                                  currentPage: get(
                                    remainDataTable,
                                    "pagination.currentPageNumber",
                                    0
                                  ),
                                  pageSize: get(
                                    remainDataTable,
                                    "pagination.pageSize",
                                    10
                                  ),
                                  index: index + 1,
                                })}
                              </td>
                              <td>
                                {moment(get(item, "dataDoc", "")).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{get(item, "schet", "")}</td>

                              <td>{get(item, "subkonto1", "")}</td>
                              <td>{get(item, "subkonto2", "")}</td>
                              <td>{get(item, "subkonto3", "")}</td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "oborotDebitKol", 0)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "oborotDebitSumma", 0)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "oborotKreditKol", 0)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "oborotKreditSumma", 0)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "ostatokDebitKol", 0)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "ostatokDebitSumma", 0)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "ostatokKreditKol", 0)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "ostatokKreditSumma", 0)}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <Nodata colLength={14} />
                      )}
                    </>
                  ) : (
                    map([...new Array(10)], (_, index) => (
                      <tr key={index + 1}>
                        <LoadingTable
                          width={index % 2 == 0 ? "70px" : "70px"}
                          tblDTNumber={14}
                        />
                      </tr>
                    ))
                  )}
                </BaseTable>
              </WindowHeight>
            </Col>
          </Row>
          {get(remainDataTable, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-24">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(remainDataTable, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleChangeFilterRemainTable({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    remainDataTable,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(remainDataTable, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleChangeFilterRemainTable({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default RemainsContainer;
