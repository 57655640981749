import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styled, { css } from "styled-components";

export const TabWrapper = styled(Tabs)`
  font-family: "Segoe UI", sans-serif;
  font-size: 12px;
  width: 100%;
`;

export const TabListWrapper = styled(TabList)`
  list-style-type: none;
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;

  border-bottom: 2px solid #e8e8e8;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const TabTitle = styled(Tab)`
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;

  &:focus {
    outline: none;
    color: #0085ff;
    border-bottom: 2px solid #0085ff;
  }
`;

export const TabPanelWrapper = styled(TabPanel)`
  &.is-selected {
    display: block;
  }
`;
