import { combineReducers } from "@reduxjs/toolkit";
import bankSlices from "app/slices/handbookSlices/bankSlices/bankSlices";
import bankAccountsSlices from "app/slices/handbookSlices/bankAccountsSlice/bankAccountsSlice";
import kassaSlice from "./kassaSlice/kassaSlice";
import fizLitsSlices from "./FizLitsSlices/fizLitsSlices";
import yurLitsSlices from "./yurLitsSlices/yurLitsSlices";
import physPersonSlices from "./physPersonSlices/physPersonSlices";
import largePersonSlices from "./largePersonSlices/largePersonSlices";
import counterpartSlices from "./counterpartSlices/counterpartSlices";
import bankCounterpartSlices from "./bankCounterpartSlices/bankCounterpartSlices";
import deliveryAddressSlices from "./deleveryAddressSlices/deliveryAddressSlices";
import termsPaymentSlices from "./temsPaymentSlices/termsPaymentSlices";
import wareHouseSlices from "./wareHouseSlices/wareHouseSlices";
import territoriesSlices from "./territoriesSlices/territoriesSlices";
import workShopSlices from "./workshopSlices/workshopSlices";
import unitsMeasurementSlices from "./unitsMeasurementSlices/unitsMeasurementSlices";
import discountSlices from "./discountsSlices/discountSlices";
import productSlices from "./productSlices/productSlices";
import priceSlices from "./priceSlices/priceSlices";
import typeProductSlices from "./typeProductSlices/typeProdcutSlices";
import calculationSlices from "./calculationSlices/calculationSlices";
import typeMaterialSlices from "./typeMaterialSlices/typeMaterialSlices";
import rawMaterialSlices from "./rawMaterialSlices/rawMaterialSlices";
import priceMaterialSlices from "./priceMaterialSlices/priceMaterialSlices";
import rateVATSlices from "./rateVATSlices/rateVATSlices";
import expanseItemSlices from "./expenseItemSlices/expanseItemSlices";
import typeTruckSlices from "./typeTruckSlices/typeTruckSlices";
import truckSlices from "./truckSlices/truckSlices";
import driversSlices from "./driversSlices/driversSlices";
import usersSlices from "./usersSlices/usersSlices";
import detailsCompanySlices from "./detailsCompanySlices/detailsCompanySlices";
import subContoSlices from "./subContoSlices/subContoSlices";
import menageAccountSlices from "./menageAccountSlices/menageAccountSlices";
import semiProductSlice from "./semiProductSlice/semiProductSlice";
import semiCalculationSlice from "./semiCalculationSlice/semiCalculationSlice";
const reducers = combineReducers({
    banks: bankSlices,
    bankAccounts: bankAccountsSlices,
    kassa: kassaSlice,
    fizLits: fizLitsSlices,
    yurLits: yurLitsSlices,
    physPerson: physPersonSlices,
    largePerson: largePersonSlices,
    counterpart: counterpartSlices,
    bankCounterpart: bankCounterpartSlices,
    deliveryAddress: deliveryAddressSlices,
    termsPayment: termsPaymentSlices,
    warHouse: wareHouseSlices,
    territories: territoriesSlices,
    workshop: workShopSlices,
    unitsMeasurement: unitsMeasurementSlices,
    discount: discountSlices,
    typeProduct: typeProductSlices,
    product: productSlices,
    price: priceSlices,
    calculation: calculationSlices,
    typeMaterial: typeMaterialSlices,
    rawMaterial: rawMaterialSlices,
    priceMaterial: priceMaterialSlices,
    rateVTA: rateVATSlices,
    expanseItem: expanseItemSlices,
    typeTruck: typeTruckSlices,
    truck: truckSlices,
    drivers: driversSlices,
    users: usersSlices,
    detailsCompany: detailsCompanySlices,
    subConto: subContoSlices,
    menageAccounts: menageAccountSlices,
    semiProducts: semiProductSlice,
    semiCalculations: semiCalculationSlice,
});

export default reducers;
