import {
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  LoadingTable,
  Nodata,
  Pagination,
  ShowOnPage,
  Text,
  Title,
  WindowHeight,
} from "components";
import { Col, Row } from "react-grid-system";
import { BalanceSheetTable } from "../components";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";

import { balanceSheetFilter, balanceSheetList } from "app/states/reports";
import { get, isEmpty, isEqual, map } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterServices from "services/apiServices/registers";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { remainTableData, remainTableFilter } from "app/states/registers";
import {
  clearRemainTable,
  fetchRemainTable,
  handleChangeFilterRemainTable,
} from "app/slices/registerSlices/remaonTableSlices/remainTableSlices";
import { indexNumber } from "utilities/helpers";

// Оборотно-сальдовая ведомость item
const BalanceSheetItem = () => {
  const { state } = useLocation();
  const remainDataTable = useAppSelector(remainTableData);
  const dispatch = useAppDispatch();
  const filter = useAppSelector(remainTableFilter);
  const LOADING_TABLE = get(remainDataTable, "loading", []);
  // const filter = useAppSelector(balanceSheetFilter);
  const [balanceItemData, setBalanceItemData] = useState<any>([]);
  const navigate = useNavigate();
  const balanceItem = useMemo(() => {
    const result = {
      balanceList: {
        list: [state],
      },
    };
    return result;
  }, [state]);
  const dateTitle = useMemo(() => {
    const result = ` Оборотно-сальдовая ведомость на период с ${moment(
      get(state, "fromDate", "")
    ).format("DD.MM.YYYY")} по ${moment(get(state, "toDate", "")).format(
      "DD.MM.YYYY"
    )}.`;
    return result;
  }, [state]);

  const fetchBalanceItem = useCallback(() => {
    dispatch(
      fetchRemainTable({
        ...filter,
        fromDate: get(state, "fromDate", null),
        toDate: get(state, "toDate", null),
        search: get(state, "nomerScheta", null),
      })
    );
  }, [filter, state, dispatch]);

  useLayoutEffect(() => {
    fetchBalanceItem();
  }, [fetchBalanceItem, state]);

  useEffect(() => {
    return () => {
      dispatch(clearRemainTable());
      dispatch(
        fetchRemainTable({
          ...filter,
          fromDate: null,
          toDate: null,
        })
      );
    };
  }, []);
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Деталы
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-8">
              <Title dark bold norm>
                {dateTitle}
              </Title>
            </Col>
            <Col xs={12}>
              <BalanceSheetTable onRowClick={() => {}} data={balanceItem} />
            </Col>
          </Row>
          <Row className="mt-30">
            <Col xs={12}>
              <FlexBox justify="space-between">
                <Title dark bold norm>
                  Обороты периода
                </Title>
                <div
                  className="mr-4 cursor-pointer link_size-24"
                  onClick={() => navigate(-1)}
                >
                  &#10006;
                </div>
              </FlexBox>
            </Col>
            <Col xs={12} className="mt-8">
              <hr />
            </Col>
            <Col className="responsive_overflow">
              <BaseTable
                tableHeader={[
                  "№",
                  "Дата",
                  "Счет",
                  "Субконто1",
                  "Субконто2",
                  "Субконто3",
                  "Количест. оборот по дебету на текущую дату",
                  "Суммарный оборот по дебету на текущую дату",
                  "Количест. оборот по кредиту на текущую дату",
                  "Суммарный оборот по кредиту на текущую дату",
                ]}
                className="table_scroll"
              >
                {isEqual(LOADING_TABLE, false) ? (
                  <>
                    {!isEmpty(get(remainDataTable, "remainderData", [])) ? (
                      get(remainDataTable, "remainderData", []).map(
                        (elem: any, index: number) => (
                          <tr key={`item-${index + 1}`}>
                            <td>
                              {indexNumber({
                                currentPage: get(
                                  remainDataTable,
                                  "pagination.currentPageNumber",
                                  0
                                ),
                                pageSize: get(
                                  remainDataTable,
                                  "pagination.pageSize",
                                  10
                                ),
                                index: index + 1,
                              })}
                            </td>
                            <td>
                              {moment(get(elem, "dataDoc", null)).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td>{get(elem, "schet", "")}</td>
                            <td style={{ maxWidth: "20%" }}>
                              {get(elem, "subkonto1", "")}
                            </td>
                            <td>{get(elem, "subkonto2", "")}</td>
                            <td>{get(elem, "subkonto3", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(elem, "oborotDebitKol", "")}
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(elem, "oborotDebitSumma", "")}
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                                decimalScale={2}
                              />
                            </td>
                            {/* ostatokKreditKol */}
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(elem, "oborotKreditKol", "")}
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(elem, "oborotKreditSumma", "")}
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                                decimalScale={2}
                              />
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <Nodata colLength={10} />
                    )}
                  </>
                ) : (
                  map([...new Array(11)], (_, index: number) => (
                    <tr key={index + 1}>
                      <LoadingTable
                        width={index % 2 == 0 ? "50px" : "70px"}
                        tblDTNumber={11}
                      />
                    </tr>
                  ))
                )}
              </BaseTable>
            </Col>
          </Row>
          {get(remainDataTable, "pagination.totalPages", 0) > 0 && (
            <Row className="mt-24">
              <Col xs={6}>
                <ShowOnPage
                  pageSize={get(remainDataTable, "pagination.pageSize", 10)}
                  handlePageChange={(e) =>
                    dispatch(
                      handleChangeFilterRemainTable({
                        name: "size",
                        value: e,
                      })
                    )
                  }
                />
              </Col>
              <Col xs={6}>
                <Pagination
                  current={get(
                    remainDataTable,
                    "pagination.currentPageNumber",
                    0
                  )}
                  pageCount={get(remainDataTable, "pagination.totalPages", 0)}
                  onPageChange={({ selected }) =>
                    dispatch(
                      handleChangeFilterRemainTable({
                        name: "page",
                        value: selected,
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default BalanceSheetItem;
