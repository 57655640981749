import { Col, Row } from "react-grid-system"
import { TableWrapper } from "./styles/style"
import { get, isEmpty } from "lodash"
import moment from "moment"
import { NumericFormat } from "react-number-format"

const ProcurementPlanTable = ({ data }: any) => {
    return (
        <Row className="mt-16">
            <Col>
                <Row >
                    <Col className="responsive_overflow">
                        <TableWrapper className="mb-16">
                            <thead>
                                <tr>
                                    <th >№</th>
                                    <th style={{ width: "180px" }}>Материал</th>
                                    <th >Ед.изм-я</th>
                                    <th >Остаток на складе</th>
                                    <th >Номер<br />  Заказ-наряда</th>
                                    <th style={{ width: "140px" }}>Дата<br /> Заказ-наряда</th>
                                    <th style={{ width: "160px" }}>Товар</th>
                                    <td>Количество</td>
                                    <td>Требуемое количество</td>
                                    <td>Количество для закупки</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !isEmpty(get(data, "planList.list", [])) ? (
                                        get(data, "planList.list", []).map((item: any, index: number) => (
                                            <>
                                                <tr key={index + 1}>
                                                    <td>{index + 1}</td>
                                                    <td>{get(item, "imyaMateriala", "")}</td>
                                                    <td>{get(item, "edIzm", "")}</td>
                                                    <td>
                                                        <NumericFormat
                                                            displayType="text"
                                                            value={get(item, "ostKol", 0)}
                                                            fixedDecimalScale
                                                            thousandSeparator
                                                            decimalScale={2}
                                                        />
                                                    </td>
                                                    <td>{get(item, "", "")}</td>
                                                    <td>{get(item, "", "")}</td>
                                                    <td>{get(item, "", "")}</td>
                                                    <td>{get(item, "", "")}</td>
                                                    <td>
                                                        <NumericFormat
                                                            displayType="text"
                                                            value={get(item, "requiredKol", 0)}
                                                            fixedDecimalScale
                                                            thousandSeparator
                                                            decimalScale={2}
                                                        />
                                                    </td>
                                                    <td>
                                                        <NumericFormat
                                                            displayType="text"
                                                            value={get(item, "kolDilyaZakupka", 0)}
                                                            fixedDecimalScale
                                                            thousandSeparator
                                                            decimalScale={2}
                                                        />
                                                    </td>
                                                </tr>
                                                {
                                                    !isEmpty(get(item, "details", [])) &&
                                                    get(item, "details", []).map((product: any, index: number) => (
                                                        <tr style={{ backgroundColor: "#f8f8f8" }} key={index + 1} >
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{get(product, "nomerZakazNaryad", "")}</td>
                                                            <td>{moment(get(product, "dataZakazNaryad", "")).format("DD.MM.yyyy")}</td>
                                                            <td>{get(product, "imyaTovara", "")}</td>
                                                            <td >
                                                                <NumericFormat
                                                                    displayType="text"
                                                                    value={get(product, "kolTovara", 0)}
                                                                    fixedDecimalScale
                                                                    thousandSeparator
                                                                    decimalScale={2}
                                                                />
                                                            </td>
                                                            <td >
                                                                <NumericFormat
                                                                    displayType="text"
                                                                    value={get(product, "kolMateriala", 0)}
                                                                    fixedDecimalScale
                                                                    thousandSeparator
                                                                    decimalScale={2}
                                                                />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                }
                                            </>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={11}>Виберите параметры</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </TableWrapper>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ProcurementPlanTable